import { Link, useParams } from "react-router-dom";
import React from "react";
import { add, listSegments,filter,list } from "services/constant/routes";
import Wrapper from "components/Wrapper";



const FilterList = () => {  
  const {id} = useParams();
  const listId = parseInt(id || '');
  
  return (
    <div className="md:w-6/12 pl-3 pr-6">
      <Wrapper>
      <div className="p-4 ">
        <h3 className="md:text-base text-sm font-semibold leading-6 text-black-300 dark:text-white">
          From a Filter
        </h3>
        <p className="sm:text-sm text-13 min-h-[40px] font-normal leading-5 text-black-300 dark:text-white mt-0.5">
          Use a subscriber filter to add members to your list.
        </p>
        <div className="mt-6">
          <Link
          to={`${listSegments}/${list}/${add}/${listId}/${filter}`}
            className="text-13 font-medium leading-4 text-white py-2.5 px-4 w-28 h-11 rounded-md bg-primary ease-in-in duration-300 hover:bg-primary hover:scale-105"
          >
            Select Filter
          </Link>
        </div>
      </div>
      </Wrapper>
    </div>
  );
};
export default FilterList;
