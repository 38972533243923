import React, { FC } from 'react';
import { FilterData } from './Types';
import { filterOptions } from './Segments';

interface Props {
  filtersData: FilterData | undefined,
}

const ConditionBlocks: FC<Props> = ({ filtersData }) => {


  return (
    <div>
      {Array.isArray(filtersData?.filter.blocks.block) ?
        (filtersData?.filter.blocks.block?.map((segment, index) => (
          <div key={segment.id}>
            <div className="">
              <h3 className="md:text-base text-sm font-gilroyBold leading-6 text-424242">
                {segment.field_name}
              </h3>
              {Array.isArray(segment?.rules?.rule) ?
                (segment?.rules?.rule.map((rule, ruleIndex) => (

                  < div key={rule.id} className="flex items-center mt-1" >
                    <p className="sm:text-sm text-[13px] font-normal leading-5 text-424242 mr-2">
                      {filterOptions[rule.comparison_operators.selected]}{' '}{rule.value}
                    </p>
                    {Array.isArray(segment?.rules?.rule) &&
                      segment.rules.rule.length - 1 !== ruleIndex && (
                        <p className="sm:text-base text-sm font-normal leading-4 text-616161">
                          {segment.logic_operator}
                        </p>
                      )
                    }
                  </div>
                ))
                ) : (
                  <div className="flex items-center mt-1">
                    <p className="sm:text-sm text-[13px] font-normal leading-5 text-424242 mr-2">
                      {filterOptions[segment.rules.rule.comparison_operators.selected]}{' '}{segment.rules.rule.value}
                    </p>
                  </div>
                )}
            </div>

            {Array.isArray(filtersData?.filter.blocks.block) &&
              filtersData.filter.blocks.block.length - 1 !== index && (
                <h2 className="sm:text-xl text-base sm:leading-5 leading-3 text-ryzeoBlue text-center my-4">
                  {filtersData.filter.logic_operator}
                </h2>
              )}
          </div>
        ))) : (
          <div>
            <div className="">
              <h3 className="md:text-base text-sm font-gilroyBold leading-6 text-424242">
                {filtersData?.filter.blocks.block.field_name}
              </h3>
              {Array.isArray(filtersData?.filter.blocks.block.rules?.rule) ?
                (filtersData?.filter.blocks.block.rules?.rule.map((rule, ruleIndex) => (
                  <div key={rule.id} className="flex items-center mt-1" >
                    <p className="sm:text-sm text-[13px] font-normal leading-5 text-424242 mr-2">
                      {filterOptions[rule.comparison_operators.selected]}{' '}{rule.value}
                    </p>
                    {!Array.isArray(filtersData.filter.blocks.block) && 
                      Array.isArray(filtersData?.filter.blocks.block.rules?.rule) &&
                      filtersData?.filter.blocks.block.rules.rule.length - 1 !== ruleIndex && (
                        <p className="sm:text-base text-sm font-normal leading-4 text-616161">
                          {filtersData?.filter.blocks.block.logic_operator}
                        </p>
                      )
                    }
                  </div>
                ))
                ) : (
                  <div className="flex items-center mt-1">
                    <p className="sm:text-sm text-[13px] font-normal leading-5 text-424242 mr-2">
                      {filterOptions[filtersData?.filter.blocks.block.rules.rule.comparison_operators.selected || '']}{' '}{filtersData?.filter.blocks.block.rules.rule.value}
                    </p>
                  </div>
                )}
            </div>

            {/* {Array.isArray(filtersData?.filter.blocks.block) &&
              filtersData.filter.blocks.block.length - 1 !== index && (
                <h2 className="sm:text-xl text-base sm:leading-5 leading-3 text-ryzeoBlue text-center my-4">
                  {filtersData.filter.logic_operator}
                </h2>
              )} */}
          </div>
        )}
    </div>
  );
};
export default ConditionBlocks;
