import { useOutsideClick } from 'app/hooks/useOutsideClick';
import Close from 'assets/Images/Workflow/close-Icon.png';
import { FC, useRef } from 'react';
import React from 'react';
interface Props {
  onClose: () => void;
  children: JSX.Element;
  disabled?: boolean;
  onSave?: () => void;
  buttonTxt?: string;
  cancelButtonTxt?: string;
  customStyles?: string;
  footerBorder?: string;
}
const ModalWrapper: FC<Props> = ({
  onClose,
  children,
  disabled,
  onSave,
  buttonTxt,
  cancelButtonTxt,
  customStyles,
  footerBorder,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useOutsideClick(modalRef, onClose);

  return (
    <div className="relative mt-[8%] max-h-[400px]  mx-auto bg-white dark:bg-[#41464E] scrollbar-hide  max-w-xl  rounded-lg overflow-auto">
      <div
        ref={modalRef}
        className="w-full flex flex-col items-center justify-center"
      >
        <div className="relative">
          <img
            src={Close}
            onClick={() => onClose()}
            className="absolute top-0 right-0 mt-2 mr-2 border-none cursor-pointer dark:invert dark:brightness-0"
          />
        </div>
        <div
          className={` ${customStyles}  no-scrollbar  rounded-lg w-full mx-5 `}
        >
          <div className=" p-5  dark:border-[#fff]">{children}</div>
        </div>
        <div
          className={` ${footerBorder} border-t w-full border-[#ced4da] dark:border-[#fff] `}
        >
          <div className="rounded-b-lg py-3 px-5 ">
            <div className="flex justify-end items-center">
              {
                <button
                  onClick={() => onClose()}
                  className="sm:text-13 text-center text-xs py-2.5 px-4 h-11 flex justify-center items-center w-28 font-medium leading-5 mr-2  rounded-md bg-[#f3f6f9] hover:bg-[#cfd1d4] transition-bg text-[#212529]"
                >
                  {cancelButtonTxt || 'Cancel'}
                </button>
              }
              <button
                type="submit"
                disabled={disabled}
                onClick={() => onSave && onSave()}
                className={`text-sm font-medium leading-5 rounded-md px-4 py-2.5 w-28 h-11 ${
                  disabled
                    ? 'border border-[#ced4da] bg-gray-200 text-[#495057] dark:text-[#000000] '
                    : 'bg-primary hover:bg-primary text-white hover:scale-105 ease-in duration-300'
                }`}
              >
                {buttonTxt || 'Save'}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ModalWrapper;
