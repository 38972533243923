import DeliveryOptions from '../SendEmail/DeliveryOptions';
import Mailer from '../SendEmail/Mailer';
import Sender from '../SendEmail/Sender';
import EmailContent from '../SendEmail/EmailContent';
import Tabs from '../SendEmail/Tabs';
import { SendEmailData } from '../index.type';
import { FC, useEffect, useRef, useState } from 'react';
import { z } from 'zod';
import uuid from 'react-uuid';
import ModalWrapper from 'components/Workflow/ModalWrapper';

import React from 'react';
interface Props {
  emailData: SendEmailData | undefined;
  onClose: () => void;
  onSave: (data: SendEmailData) => void;
}

const senderDataSchema = z.object({
  fromEmailName: z.string(),
  fromEmailAddress: z.string(),
  replyToEmailAddress: z.string(),
});
type SenderData = z.infer<typeof senderDataSchema>;

const providerDataSchema = z.object({
  emailId: z.number().nullable(),
  subject: z.string(),
});
type ProviderData = z.infer<typeof providerDataSchema>;

const SendEmail: FC<Props> = ({ emailData, onClose, onSave }) => {
  const [activeTab, setActiveTab] = useState('Mailer');
  const [validForm, setValidForm] = useState({
    senderIsValid: false,
    contentIsValid: false,
  });
  const [ignoreStatus, setIgnoreStatus] = useState<boolean>(
    emailData?.ignoreOptStatus || false
  );
  const [sendEmailData, setSendEmailData] = useState<SendEmailData>({
    fromEmailName: '',
    fromEmailAddress: '',
    replyToEmailAddress: '',
    emailProviderId: uuid(),
    subject: '',
    senderId: '',
    ignoreOptStatus: emailData?.ignoreOptStatus || false,
    _type: 'SendEmailAction',
    emailId: null,
  });
  const [formsTouched, setFormsTouched] = useState<boolean>(false);
  const [data, setData] = useState<SendEmailData | undefined>();
  const [formIsValid, setFormIsValid] = useState<boolean>(true);
  const [showPreview, setShowPreview] = useState<boolean>(false);


  const divRefs = {
    mailerRef: useRef<HTMLDivElement>(null),
    senderRef: useRef<HTMLDivElement>(null),
    emailRef: useRef<HTMLDivElement>(null),
    deliveryRef: useRef<HTMLDivElement>(null),
  };

  const tabClickHandler = (tab: string) => {
    setActiveTab(tab);
    /* eslint-disable */
    if (tab === 'Mailer') {
      divRefs.mailerRef.current!.scrollIntoView({ behavior: 'smooth' });
    }
    if (tab === 'Sender') {
      divRefs.senderRef.current!.scrollIntoView({ behavior: 'smooth' });
    }
    if (tab === 'Content') {
      divRefs.emailRef.current!.scrollIntoView({ behavior: 'smooth' });
    }
    if (tab === 'Options') {
      divRefs.deliveryRef.current!.scrollIntoView({ behavior: 'smooth' });
    }
    /* eslint-enable */
  };

  const saveDataHandler = () => {
    if (
      !formsTouched ||
      !validForm.contentIsValid ||
      !validForm.senderIsValid
    ) {
      setFormIsValid(false);
    } else {
      if (sendEmailData.senderId === '') {
        delete sendEmailData.senderId;
      }
      onSave(sendEmailData);
      onClose();
    }
  };

  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, 
    };
    const handleIntersection = (entries: IntersectionObserverEntry[]) => {
      entries.forEach((entry: IntersectionObserverEntry) => {
        if (entry.isIntersecting) {
          if (entry.target.id === 'mailerRef') {
            setActiveTab('Mailer');
          } else if (entry.target.id === 'senderRef') {
            setActiveTab('Sender');
          } else if (entry.target.id === 'emailRef') {
            setActiveTab('Content');
          } else if (entry.target.id === 'deliveryRef') {
            setActiveTab('Options');
          }
        }
      });
    };

    const observer = new IntersectionObserver(
      handleIntersection,
      observerOptions
    );

    Object.values(divRefs).forEach((ref) => {
      if (ref.current) {
        observer.observe(ref.current);
      }
    });

    return () => {
      Object.values(divRefs).forEach((ref) => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      });
    };
  }, []);


  useEffect(() => {
    setData(emailData);
  }, [emailData]);

  useEffect(() => {
    if (validForm.contentIsValid && validForm.senderIsValid && formsTouched) {
      setFormIsValid(true);
    }
  }, [validForm]);

  return (
    <div>
      <ModalWrapper
        onClose={onClose}
        disabled={!formIsValid}
        onSave={saveDataHandler}
      >
        <>
          <div className="bg-white dark:bg-[#41464E]">
            <h2 className="sm:text-xl text-base sm:leading-5 leading-3 text-black-700 font-medium mb-8 dark:text-white">
              Action
            </h2>
            <Tabs
              onTabClick={(tab) => tabClickHandler(tab)}
              activeTab={activeTab}
            />
            <div className=" my-auto  pr-5">
              <div>
                <div ref={divRefs.mailerRef} id="mailerRef">
                  <Mailer mailRef={divRefs.mailerRef} />
                </div>
                <div ref={divRefs.senderRef} id="senderRef">
                  <Sender
                    senderRef={divRefs.senderRef}
                    emailData={data}
                    formIsValid={(valid) => {
                      if (valid) {
                        setValidForm({ ...validForm, senderIsValid: valid });
                      }
                      setShowPreview(valid);
                    }}
                    onSenderDataChanged={(data: SenderData) => {
                      setSendEmailData({
                        ...sendEmailData,
                        ...data,
                      });
                    }}
                    onFormTouch={() => setFormsTouched(true)}
                  />
                </div>
                <div ref={divRefs.emailRef} id="emailRef">
                  <EmailContent
                    emailRef={divRefs.emailRef}
                    emailData={data}
                    formIsValid={(valid) => {
                      if (valid) {
                        setValidForm({
                          ...validForm,
                          contentIsValid: valid,
                        });
                      }
                    }}
                    onDataChange={(data: ProviderData) =>
                      setSendEmailData({
                        ...sendEmailData,
                        ...data,
                      })
                    }
                    senderIsValid={showPreview}
                    onFormTouch={() => setFormsTouched(true)}
                  />
                </div>
                <div ref={divRefs.deliveryRef} id="deliveryRef">
                  <DeliveryOptions
                    deliveryRef={divRefs.deliveryRef}
                    status={ignoreStatus}
                    onChangeStatus={(status) => {
                      setSendEmailData({
                        ...sendEmailData,
                        ignoreOptStatus: status,
                      });
                      setIgnoreStatus(status);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          {!formIsValid && (
            <div className="px-6 py-3 bg-[#f7e8e7] rounded mt-4">
              <p className="text-sm font-normal leading-4 text-[#CB5A5A]">
                Correct the errors above
              </p>
            </div>
          )}
        </>
      </ModalWrapper>
    </div>
  );
};
export default SendEmail;
