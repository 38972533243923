import { useState, createContext, useEffect } from 'react';
import { z } from 'zod';
import React from 'react';
import {
  WorkflowType,
  WorkflowSchema,
} from 'components/Workflow/Body/Workflows/index.type';
import { getConfiguration } from 'services/apiHandlers/Workflows/Workflow';
import { getCurrentUser } from 'services/apiHandlers/Campaigns/Emails';
import { getAllWorkflows } from 'services/apiHandlers/Dashboard/Analytics';

const configurationDataSchema = z.object({
  accountId: z.number(),
  isSupportUser: z.boolean(),
  loggedIn: z.boolean(),
  streamsendHostname: z.string(),
  timeZone: z.string(),
});

const configurationResponseSchema = z.object({
  status: z.number(),
  data: configurationDataSchema,
});

const getCurrentUserDataResponseSchema = z.object({
  account_id: z.number(),
  created_at: z.string(),
  email: z.string(),
  first_name: z.string(),
  id: z.number(),
  last_name: z.string(),
  title: z.string(),
  updated_at: z.string(),
  franchise_enabled: z.boolean(),
  show_subscriber_events: z.boolean(),
  readonly_mode: z.boolean(),
  lockdown_mode: z.boolean(),
  is_admin: z.boolean(),
  account_settings: z.boolean(),
  share_only: z.boolean()
});

const getCurrentUserResponseSchema = z.object({
  data: getCurrentUserDataResponseSchema,
  status: z.number(),
});

const AllWorkflowResponseSchema = z.object({
  data: z.object({ triggers: z.array(WorkflowSchema) }),
  status: z.number(),
});

export type WorkflowResponse = z.infer<typeof AllWorkflowResponseSchema>;
type CurrentUserData = z.infer<typeof getCurrentUserDataResponseSchema>;
type CurrentUserResponse = z.infer<typeof getCurrentUserResponseSchema>;
type ConfigurationData = z.infer<typeof configurationDataSchema>;
type ConfigurationResponse = z.infer<typeof configurationResponseSchema>;

interface ContextType {
  usersData: ConfigurationData | undefined;
  currentUserData: CurrentUserData | undefined;
  workflows: WorkflowType[] | undefined;
  workflowsLoading: boolean;
  getWorkflows: (prevent?: boolean) => void;
  filteredValue: string;
  filterValueHandler: (filter: string) => void;
  showAuthError : boolean;
  setShowAuthError: (auth:boolean) => void;
  allowedToEdit: (user_id: number) => boolean;
}

export const UserContext = createContext<ContextType | null>(null);
type Props = {
  children: JSX.Element;
};

export const UserContextProvider = ({ children }: Props) => {
  const [usersData, setUsersData] = useState<ConfigurationData>();
  const [currentUserData, setCurrentUserData] = useState<CurrentUserData>();
  const [workflows, setWorkflows] = useState<Array<WorkflowType>>([]);
  const [workflowsLoading, setWorkflowsLoading] = useState<boolean>(true);
  const [filteredValue, setFilteredValue] = useState<string>('');
  const [showAuthError,setShowAuthError] = useState<boolean>(true);

  const getWorkflows = async (prevent?: boolean) => {
    !prevent && setWorkflowsLoading(true);
    try {
      const getWorkflowResponse = (await getAllWorkflows()) as WorkflowResponse;
      if (getWorkflowResponse.status === 200) {
        setWorkflows(getWorkflowResponse.data.triggers);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
    setWorkflowsLoading(false);
  };

  const getUsersData = async () => {
    try {
      const getConfigurationResponse =
        (await getConfiguration()) as ConfigurationResponse;
      if (getConfigurationResponse.status === 200) {
        setUsersData(getConfigurationResponse.data);
      }

      const getCurrentUserResponse =
        (await getCurrentUser()) as CurrentUserResponse;
      if (getCurrentUserResponse.status === 200) {
        setCurrentUserData(getCurrentUserResponse.data);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
  };


  const allowedToEdit = (user_id: number): boolean => {
    const disable_account_edit = (): boolean => (currentUserData?.readonly_mode || currentUserData?.lockdown_mode) as boolean;
    const allowedToCreate = (): boolean => (currentUserData?.is_admin && !currentUserData.readonly_mode && !currentUserData.lockdown_mode) as boolean;
    
    return (
      !disable_account_edit() &&
      (currentUserData?.id === user_id || allowedToCreate())
    ) as boolean;
  }


  
  const filterValueHandler = (value: string) => {
    setFilteredValue(value);
  };

  useEffect(() => {
    getUsersData();
    getWorkflows();
  }, []);

  return (
    <UserContext.Provider
      value={{
        usersData,
        currentUserData,
        workflows,
        workflowsLoading,
        getWorkflows,
        filteredValue,
        filterValueHandler,
        showAuthError,
        setShowAuthError,
        allowedToEdit
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
