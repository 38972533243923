import httpService from 'services/core/HttpService';
import { GET_USER } from 'services/constant/apiRoutes';
import { UserEditRequestType } from 'components/Account/AccountsTypes';

const xmlConfig = {
    headers: { 'Content-Type': 'text/xml' },
  };
//Update type
export const editUser = async (id: string | undefined, data: UserEditRequestType) => {
    const updateData = `
                        <user>
                            <first-name>${data.firstName}</first-name>
                            <last-name>${data.lastName}</last-name>
                            <email-address>${data.email}</email-address>
                            <company-name>${data.companyName}</company-name>
                            <title>${data.title}</title>
                        </user>
                    `;


    const updateUserUrl = GET_USER.replace('{{ID}}', id || '');
    try {
      const response = await httpService.put(
        updateUserUrl,
        updateData,
        xmlConfig
      );
  
      return Promise.resolve(response);
    } catch (error) {
      return Promise.reject(error);
    }
};