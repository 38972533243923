import Skeleton from 'components/Skeleton/Skeleton';
import React , { useContext } from 'react';
import DataTable, { TableProps } from 'react-data-table-component';
import { ThemeContext } from 'store/ThemeContext';
import Pagination from './Pagination';
import ErrorBoundary from 'components/ErrorBoundary';
import ArrowDown from 'assets/Images/Common/arrowDown.svg'

const selectProps = {
    indeterminate: (isIndeterminate: boolean) => isIndeterminate,
};


function DataTableBase<T>(props: TableProps<T>): JSX.Element {
    const themeCtx = useContext(ThemeContext);
    
    
    const customStyles = {
        table: {
            style: {
                background: 'unset !important'
            }
        },

        rows: {
            style: {
                color: '#212529',
                fontSize: '14px',
                borderBottom: 'unset !important',
                backgroundColor: 'dark:',
                height: '52px',
                '&:hover': {
                    backgroundColor:
                        themeCtx?.theme === 'dark'
                            ? 'rgba(255, 255, 255, 0.07) !important'
                            : 'rgb(243,246,249) !important',
                },
            
                 
            },
        },
        pagination: {
            style: {
                minHeight: '61px',
            },
        },
        cells: {
            style: {
                display: 'flex',
                paddingRight: '4px',
                paddingLeft: '4px',
            },
        },
        headRow: {
            style: {
                borderColor: themeCtx?.theme === 'dark' ? '#41464E' : '#e9ebec',
                background:
                    themeCtx?.theme === 'dark'
                        ? '#41464E'
                        : '#FAFBFF',
            },
        },

        headCells: {
            style: {
                color: '#878A99',
                fontSize: '14px',
                height: '44px',
                fontWeight: 600,
                display: 'flex',
                paddingRight: '4px',
                paddingLeft: '4px',
            },
        },
    };

    const options = {
        noRowsPerPage: true,
        rowsPerPageText: '50',
        rangeSeparatorText: '0',
        selectAllRowsItem: true,
        selectAllRowsItemText: 'true',
        
    };
  

    return (
        <ErrorBoundary>
            <div className="z-40 rounded-md overflow-x-hidden">
                <DataTable 
                    pagination
                    selectableRowsComponentProps={selectProps}
                    dense
                    customStyles={customStyles}
                    progressComponent={
                        <Skeleton columns={props.columns.length} />
                    }
                    
                    {...props}
                    
                    
                    paginationComponent={Pagination}
                    paginationComponentOptions={options}
                    defaultSortAsc={false}
                    persistTableHead={true}
                    fixedHeader={true}
                    expandableIcon={{
                        collapsed: (
                            <img className="h-5 w-5 ml-3.5 dark:invert dark:brightness-0" src={ArrowDown} alt="Logo" />
                        ),
                        expanded: (
                            <img className="h-5 w-5 dark:invert dark:brightness-0 rotate-90 ml-3.5" src={ArrowDown} alt="Logo" />
                        ),
                    }}
                />   
            </div>
        </ErrorBoundary>
    );
}

export default DataTableBase;
