import { useFormik } from 'formik';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { Form } from './SubscribersForm';
import { editField } from 'services/apiHandlers/List-Segments/Field';
import { Toastify } from 'App';
import { z } from 'zod';
import React from 'react';

const responseStatusSchema = z.object({ status: z.number() });
export type ResponseStatus = z.infer<typeof responseStatusSchema>;

interface Props{
  fieldData: Form|undefined;
}

const FieldForm: React.FC<Props> = ({fieldData}) => {
  const formik = useFormik({
    initialValues: {
      field: '',
    },
    validationSchema: Yup.object({
      field: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      const data = {
        name: values.field,
        id: fieldData?.id || 0,
      };

      try {
        const editFieldResponse = (await editField(data)) as ResponseStatus;
        if (editFieldResponse.status === 200) {
          Toastify('Field Edited Successfully', 'success', 'editfield1');
         
        }
      } catch (error) {
        Toastify('Field Editing Failed', 'error', 'editfield2');
      }
    },
  });

  useEffect(() => {
    formik.setFieldValue('field', fieldData?.name);
  }, [fieldData]);

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="mt-4">
        <div className="w-full md:pr-2.5">
          <div className="flex justify-between items-center mb-4">
            <h2 className="sm:text-xl text-base font-medium leading-5 text-black-300 dark:text-white">
              Edit Field
            </h2>
            <button
              type="submit"
              className="sm:py-2.5 py-1.5 sm:px-4 px-2 sm:text-13 text-xs font-medium leading-4 text-white dark:hover:text-[#495057] dark:text-[#CED4DA]  dark:text-white rounded bg-primary dark:hover:bg-white"
            >
              Save Changes
            </button>
          </div>
          <div>
            <div className="sm:px-6 px-4 sm:py-4 py-2.5 border dark:border-[#fff] rounded-lg shadow-lg shadow-gray-200 dark:shadow-none bg-white  dark:bg-[#41464E] ">
              <div>
                <label className="text-xs font-semibold leading-3 tracking-wide uppercase text-[#495057] dark:text-[#CED4DA]  dark:text-white dark:text-white mb-3 block">
                  Name
                </label>
                <input
                  className="border border-[#ced4da] dark:border-[#fff]  dark:bg-[#41464E]  sm:[38px] h-9 p-4 text-13 font-medium leading-3 text-gray-700 placeholder:text-gray-500 dark:text-white  dark:bg-[#41464E]  rounded w-full focus:outline-none"
                  type="text"
                  name="field"
                  value={formik.values.field}
                  onChange={formik.handleChange}
                />
                {formik.errors.field ? (
                  <div className="text-xs leading-4 text-red-400 mt-1.5">
                    {formik.errors.field ? formik.errors.field : null}
                  </div>
                ) : null}
              </div>
              <div className="mt-5">
                <label className="text-xs font-semibold leading-3 tracking-wide uppercase text-[#495057] dark:text-[#CED4DA] mb-3 block">
                  Data Type
                </label>
                <input
                  className="border border-[#ced4da] dark:border-[#fff]  dark:bg-[#41464E]  sm:[38px] h-9 p-4 text-13 font-medium leading-3 text-gray-700 placeholder:text-gray-500 dark:text-white  rounded w-full"
                  value={fieldData ? fieldData['data-type'] : ''}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="flex justify-between items-center sm:mt-4 mt-3"></div>
        </div>
        <div className="md:w-5/12 w-full md:pl-2.5 md:mt-0 mt-5"></div>
      </div>
    </form>
  );
};
export default FieldForm;
