import { useRef, useState } from 'react';
import { z } from 'zod';
import DownArrow from 'assets/Images/Common/down-arrow.png';
import { useOutsideClick } from 'app/hooks/useOutsideClick';
import React from 'react';
const options = z.object({
  name: z.string(),
  value: z.string(),
});
type option = z.infer<typeof options>;
interface Props {
  options: Array<option>;
  onSelect: (e: string) => void;
  value: string;
  className?: string;
}
const   Dropdown = ({ value, options, onSelect }: Props) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [selectedDropdown, setSelectedDropdown] = useState<string>('');
  const dropdownRef = useRef<HTMLDivElement>(null);
  useOutsideClick(dropdownRef, () => setShowDropdown(false));
  return (
    <div
    ref={dropdownRef}
    onClick={() =>  setShowDropdown((prev) => !prev)}
    className="w-full relative "
  >
    <div
      className={`${
        showDropdown ? 'rounded-b-none border-primary dark:border-white' : ''
      }  border w-full border-[#CED4DA] text-sm leading-4 text-[#212529] dark:border-[#fff]  dark:text-white flex items-center justify-between sm:h-[38px] h-9 py-2 px-4 focus:outline-none rounded  cursor-pointer`}
    >
      <div
        className={`${
          showDropdown ? 'rounded-b-none border-primary dark:border-white' : ''
        } w-full text-sm leading-4  dark:text-white flex items-center justify-between  py-2 text-[#212529] focus:outline-none border-0 rounded-md dark:border-[#fff] cursor-pointer`}
      >
        {value}
        <span>
          <img
            className="dark:brightness-0 dark:invert"
            src={DownArrow}
            alt="Arrow"
          />
        </span>
      </div>
      {
        <div className="max-h-40 absolute top-10 left-0 z-[999] overflow-auto  dark:bg-[#41464E]  border-t-0 rounded-b border-gray-200 dark:border-[#fff] shadow-sm shadow-gray-200 scrollbar-hide cursor-pointer w-full">
          {showDropdown &&
            options?.map((opt) => (
              <div
                key={opt.value + opt.name}
                className={`p-3  ${selectedDropdown === opt.name && selectedDropdown !== options[0].name ? 'bg-[#2381cd] dark:text-[#495057] dark:bg-white text-white font-medium' : 'bg-white dark:bg-transparent'} text-sm block w-full text-left leading-4 text-[#495057] dark:text-[#CED4DA]  dark:text-white dark:hover:bg-white ${selectedDropdown !== opt.name && 'hover:bg-dropdownHover'} dark:hover:text-[#495057] dark:text-[#CED4DA] `}
                onClick={() => {onSelect(opt.value); setSelectedDropdown(opt.name)}}
              >
                {opt.name}
              </div>
            ))}
        </div>
      }
    </div>
  </div>
  );
};
export default Dropdown;