import React, { FC, useEffect, useRef, useState } from 'react';
import Wrapper from 'components/Wrapper';
import SendReportDropdown from './SendReportDropdown';
import { useOutsideClick } from 'app/hooks/useOutsideClick';
import { getUsers } from 'services/apiHandlers/Campaigns/Emails';
import { z } from 'zod';

export const usersDataSchema = z.object({
  administrator: z.boolean(),
  company_name: z.string(),
  created_at: z.string(),
  email_address: z.string(),
  facebook_user_id: z.string(),
  first_name: z.string(),
  id: z.number(),
  last_name: z.string(),
  may_export: z.boolean(),
  need_password_reset: z.string(),
  phone_number_fax: z.string(),
  phone_number_home: z.string(),
  phone_number_mobile: z.string(),
  phone_number_office: z.string(),
  phone_number_office_ext: z.string(),
  previous_password: z.string(),
  title: z.string(),
  updated_at: z.string(),
});

const getUsersResponseSchema = z.object({
  status: z.number(),
  data: z.object({ users: z.array(usersDataSchema) }),
});

export type UsersResponse = z.infer<typeof getUsersResponseSchema>;
const optionsSchema = z.object({ name: z.string(), value: z.string() });

type Options = z.infer<typeof optionsSchema>;

interface Props {
  id?: number,
  loading: boolean
}



const Email:FC<Props> = ({id, loading}) => {




  const [showReportsDropdown, setShowReportsDropdown] =
    useState<boolean>(false);
  const sendRef = useRef<HTMLDivElement>(null);
  const [recipientOptions, setRecipientOptions] = useState<Array<Options>>([]);
  useOutsideClick(sendRef, () => setShowReportsDropdown(false));

  const getData = async () => {
    try {
      const getUsersResponse = (await getUsers()) as UsersResponse;

      if (getUsersResponse.status === 200) {
        const tempOptions = getUsersResponse.data.users.map((data) => {
          return {
            name: data.first_name + ' ' + data.last_name,
            value: data.id.toString(),
          };
        });
        setRecipientOptions(tempOptions);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
  };


  const viewEmailHandler = (id = 0, version: string) =>{

    window.open(`https://app.ryzeo.com/send/email/postview/${id}?merge=true&part=${version}`,'500','800');
    console.log("viewing the email")

  }


  useEffect(() => {
    getData();
  }, []);

  return (
    <Wrapper parentClass="md:w-[50%] flex shadow-lg hover:shadow-xl dark:shadow-lg dark:bg-[#41464E] dark:hover:shadow-xl" childClass='w-full'>
      <div className="">
        
       <div className="flex items-center border-b justify-between dark:border-[#fff] py-5">
          <p className="px-4 py-2 text-[#495057] text-sm font-medium dark:text-white">
            View the sent Email
          </p>
          {!loading && <div className="flex items-center mr-3">
            <p onClick={()=>viewEmailHandler(id, "html")} className="px-1 py-2 text-xs text-primary underline cursor-pointer">
              Html Version
            </p>
            <p  onClick={()=>viewEmailHandler(id, "text")} className="px-1 py-2 text-xs text-primary underline cursor-pointer">
              Text Version
            </p>
            </div> }
          </div>
        <div
          ref={sendRef}
          className="flex items-center justify-between py-6 relative"
        >
          <p  className="px-4 py-2 text-[#495057] text-sm font-medium dark:text-white">
            Send this Blast Report To Someone
          </p>
          <button
            type="button"
            className="cursor-pointer mr-4 px-3 py-1 text-sm text-white rounded bg-primary "
            onClick={() => setShowReportsDropdown((prev) => !prev)}
          >
            Send
          </button>
          {showReportsDropdown && (
            <SendReportDropdown onClose={() => setShowReportsDropdown(false)} options={recipientOptions} />
          )}
        </div>
      </div>
    </Wrapper>
  );
};
export default Email;
