import React, { FC, useEffect, useState } from 'react';
import { getSubscriberEvents } from 'services/apiHandlers/List-Segments/Subscriber';
import { z } from 'zod';
import moment from 'moment';
import SubscriberEventAccordian from './SubscriberEventAccordian';
import Loader from 'components/Loader';
import { ModifiedEvents } from 'staticdata/EventLogs/Data';
import CalendarIcon from 'assets/Images/Dashboard/calender.png';
import Wrapper from 'components/Wrapper';
import SubscriberEventAccordianModal from './SubscriberEventAccordianModal';
import CustomSkeleton from 'components/Skeleton/Skeleton';


export const eventResultDataSchema = z.object({
  accountId: z.number(),
  attributes: z.object({
    anonymousId: z.string(),
    hostname: z.string(),
    ip: z.string(),
    ipCity: z.string(),
    ipCountry: z.string(),
    ipState: z.string(),
    path: z.string(),
    personIdentifier: z.string(),
    referrer: z.string(),
    referrerHostname: z.string(),
    timestampOffset: z.string(),
    title: z.string(),
    url: z.string(),
    userAgent: z.string(),
  }),
  id: z.string(),
  name: z.string(),
  timestamp: z.number(),
});

const getSubscriberEventResponseSchema = z.object({
  data: z.object({
    next_start_token: z.string(),
    result: z.array(eventResultDataSchema),
    totalCount: z.string(),
  }),
  status: z.number(),
});

export type EventResultData = z.infer<typeof eventResultDataSchema>;
type GetSubscriberEventResponse = z.infer<typeof getSubscriberEventResponseSchema>;

interface Props {
  email: string;
  eventDetails: string;
  setEventDetails: (show: string) => void;
}

const SubscriberEvents: FC<Props> = ({
  email,
  eventDetails,
  setEventDetails,
}) => {
  const [subscriberEvents, setSubscriberEvents] = useState<Array<EventResultData>>([]);
  const [startToken, setStartToken] = useState<string>('');
  const [showMore, setShowMore] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);

  const getEvents = async (startToken?: string) => {
    if (email.length > 1) {
      setLoading(true)
      try {
        const getSubscriberEventResponse = (await getSubscriberEvents(
          email,
          startToken
        )) as GetSubscriberEventResponse;
        if (getSubscriberEventResponse.data.result.length === 0) {
          setSubscriberEvents(subscriberEvents);
          setShowMore(false);
        } else {
          setSubscriberEvents(getSubscriberEventResponse.data.result);
          setStartToken(getSubscriberEventResponse.data.next_start_token);
        }
      } catch (error) {
        setLoading(false);

        console.log('error is : ', error);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    getEvents();
  }, [email]);


  return (
    <Wrapper>
      <div className="p-4">
        <div className="">
          <h2 className="text-lg mb-4 text-[#495057] font-medium dark:text-[#CED4DA]">
            Subscriber Events
          </h2>
          <div className="max-h-[690px] overflow-auto no-scrollbar">
            {loading ? (
              <CustomSkeleton columns={5} />
            ) : (
              <>
                {subscriberEvents.length > 0 ? (
                  subscriberEvents.map((event) => (
                    <div
                      key={event.id}
                      className=" mt-5 mb-2 flex items-start"
                    >
                      <img
                        className="h-4 w-4 dark:invert dark:brightness-0"
                        src={CalendarIcon}
                        alt="CalendarIcon"
                      />
                      <div className="">
                        <h5 className="font-semibold pl-4 text-[#212529] relative top-[-3px] dark:text-[#CED4DA]">
                          {ModifiedEvents[event.name]
                            ? ModifiedEvents[event.name].name
                            : event.name}
                        </h5>
                        <div className="border-l pb-4 border-[#e9ebec] dark:border-[#fff]  pl-5 relative left-[-8px]">
                          <p className="text-sm text-[#494057] dark:text-[#CED4DA]">
                            {moment(event.timestamp).format(
                              'MMMM DD, YYYY hh:mm:ss A'
                            )}
                          </p>
                          <p
                            onClick={() => {
                              setEventDetails(event.id);
                            }}
                            className="text-primary text-sm mt-3 cursor-pointer"
                          >
                            View details
                          </p>
                        </div>
                      </div>
                      {eventDetails === event.id && (
                        <SubscriberEventAccordianModal
                          onClose={() => setEventDetails('')}
                        >
                          <SubscriberEventAccordian onClose={() => setEventDetails('')} data={event} />
                        </SubscriberEventAccordianModal>
                      )}
                    </div>
                  ))
                ) : (
                  <div className='dark:text-white'>There are no Events for this Subscriber</div>
                )}
              </>
            )}
          </div>
        </div>

        {showMore && !loading && subscriberEvents.length > 0 && (
          <button
            disabled={loading}
            onClick={() => {
              getEvents(startToken);
            }}
            className="fs-13 underline py-2 px-4 text-primary flex items-center mx-auto font-inter mt-5"
          >
            {loading && (
              <div className="mr-1">
                <Loader />
              </div>
            )}
            {loading ? 'Loading' : 'Show More'}
          </button>
        )}
      </div>
    </Wrapper>
  );
};
export default SubscriberEvents;
