import Header from 'components/DashboardReports/Header';
import Overview from 'assets/Images/Dashboard/overview.png';
import Calendar from 'assets/Images/Dashboard/calender.png';
import React from 'react';
import BreadcrumbsWrapper from 'components/Wrapper/BreadcrumbsWrapper';

export enum TabsEnum {
  ANALYTICS = 'analytics',
  STORE_REPORT = 'store_report',
  CALENDAR = 'calendar',
  ACTIVITY = 'activity',
}

export const TabsData = [
  { title: 'Analytics', searchQuery: TabsEnum.ANALYTICS, src: Overview },
  { title: 'Store Report', searchQuery: TabsEnum.STORE_REPORT, src: Overview },
  { title: 'Calendar', searchQuery: TabsEnum.CALENDAR, src: Calendar },
];

const Reports = () => {
  return (
    <div>
      <BreadcrumbsWrapper />
        <div>
          <div className="md:px-6 px-3 pt-5 bg-contentBg  dark:bg-[#41464E]  font-inter">
            <div>
              <Header />
            </div>
          </div>
        </div>
    </div>
  );
};
export default Reports;
