import ActivityTrigger from 'assets/Images/Workflow/activity-trigger.png';
import { FC, useContext, useState } from 'react';
import TimeClock from 'assets/Images/Workflow/time-clock.png';
import Connector from './Connector';
import {
  WorkflowAction,
  WorkflowCondition,
  InitialEventData,
  EventConditionData,
  TagsConditionData,
  SubscriberConditionData,
  ListConditionData,
  WebhookConditionData,
  ListActionData,
  WebhookActionData,
  PublishActionData,
  ScriptActionData,
  UpdateScoreData,
  TimerActionData,
  NotifyEmailData,
  SendEmailData,
} from './index.type';
import WorkflowModal from 'components/Alert/Modal';
import BranchNodes from './BranchNodes';
import DeleteModal from './Modals/DeleteModal';
import InitialTag from './Modals/InitialTag';
import InitialEvent from './Modals/InitialEvent';
import { WorkflowContext } from 'store/WorkflowContext';
import EventCondition from './Modals/EventCondition';
import Close from 'assets/Images/Workflow/close-Icon.png';
import SubscribersField from './Modals/SubscriberField';
import List from './Modals/ListActions';
import WebhookCondition from './Modals/WebhookCondition';
import WebhookAction from './Modals/WebhookAction';
import PublishAction from './Modals/PublishAction';
import UnsubscribeAction from './Modals/UnsubscribeAction';
import ScriptAction from './Modals/ScriptAction';
import UpdateScore from './Modals/UpdateScore';
import DelayTime from './Modals/DelayTime';
import NotifyEmail from './Modals/NotifyEmail';
import AddBlock from './Modals/AddBlock';
import SendEmail from './Modals/SendEmail';
import uuid from 'react-uuid';
import { z } from 'zod';
import React from "react"

const addBlockDataSchema = z.object({
  direction: z.string(),
  nodeId: z.string(),
  addType: z.string()
});
type AddBlockData = z.infer<typeof addBlockDataSchema>;

interface Props {
  nodeName: string | undefined;
  initialNode?: boolean;
  actionNode?: boolean;
  conditionNode?: boolean;
  workflow?: WorkflowAction | WorkflowCondition;
}

interface Modal {
  [key: string]: JSX.Element;
  TagInitialConditionGroup: JSX.Element;
  deleteNode: JSX.Element;
  EventInitialConditionGroup: JSX.Element;
  EventConditionNode: JSX.Element;
  SubscriberFieldConditionNode: JSX.Element;
  TagConditionNode: JSX.Element;
  ListMembershipConditionNode: JSX.Element;
  WebhookResponseConditionNode: JSX.Element;
  AddToListActionNode: JSX.Element;
  RemoveFromListActionNode: JSX.Element;
  WebhookActionNode: JSX.Element;
  PublishEventActionNode: JSX.Element;
  UnsubscribeNode: JSX.Element;
  ExecuteScriptNode: JSX.Element;
  UpdateScoreActionNode: JSX.Element;
  DelayNode: JSX.Element;
  NotifyByEmailActionNode: JSX.Element;
  AddBlock: JSX.Element;
  SendEmailActionNode: JSX.Element;
}

const Node: FC<Props> = ({
  nodeName,
  initialNode,
  actionNode,
  conditionNode,
  workflow,
}) => {
  const [showModal, setShowModal] = useState(false);
  const [hasChildActionNodes, setHasActionChildNodes] = useState(false);
  const [nodeId, setNodeId] = useState<string | undefined>('');
  const [hasBranchNodes, setHasBranchNodes] = useState<boolean>(false);
  const [modalType, setModalType] = useState<string>('');
  const [initialTagData, setInitialTagData] = useState<TagsConditionData>();
  const [initialEventData, setInitialEventData] = useState<InitialEventData>();
  const [eventConditionData, setEventConditionData] = useState<EventConditionData>();
  const [subscriberConditionData, setSubscriberConditionData] = useState<SubscriberConditionData>();
  const [tagConditionData, setTagConditionData] = useState<TagsConditionData>();
  const [listConditionData, setListConditionData] = useState<ListConditionData>();
  const [webhhookConidtionData, setWebhookConditionData] = useState<WebhookConditionData>();
  const [listActionData, setListActionData] = useState<ListActionData>();
  const [webhookActionData, setWebhookActionData] = useState<WebhookActionData>();
  const [publishActionData, setPublishActionData] = useState<PublishActionData>();
  const [scriptActionData, setScriptActionData] = useState<ScriptActionData>();
  const [scoreData, setScoreData] = useState<UpdateScoreData>();
  const [timerData, setTimerData] = useState<TimerActionData>();
  const [notifyEmailData, setNotifyEmailData] = useState<NotifyEmailData>();
  const [sendEmailData, setSendEmailData] = useState<SendEmailData>();
  const [initialAdd, setInitialAdd] = useState<boolean>(false);
  const [addBlockData, setAddBlockData] = useState<AddBlockData>({
    nodeId: '',
    direction: '',
    addType: ''
  });

  const workflowCtx = useContext(WorkflowContext);

  const addInitialNode = (
    data:
      | EventConditionData
      | SubscriberConditionData
      | ListConditionData
      | WebhookConditionData,
    addType: string,
    nodeType: string
  ) => {
    if (addType === 'action') {
      const updatedData = {
        id: uuid(),
        _type: nodeType,
        action: data,
        nextNode: workflowCtx?.workflow?.workflow,
      };
      workflowCtx?.workflowChangeHandler({
        ...workflowCtx?.workflow,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        workflow: updatedData,
      });
    } else if (addType === 'condition') {
      const updatedData = {
        id: uuid(),
        _type: nodeType,
        conditionGroup: data,
        trueNode: workflowCtx?.workflow?.workflow,
      };
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      workflowCtx?.workflowChangeHandler({
        ...workflowCtx?.workflow,
        workflow: updatedData,
      });
    }
  };

  const updateNode = (
    id: string,
    newNode:
      | EventConditionData
      | SubscriberConditionData
      | ListConditionData
      | WebhookConditionData,
    workflow: WorkflowAction | WorkflowCondition | null | undefined
  ) => {
    const updatedWorkflow = { ...workflow };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const findAndReplaceNode = (node: any) => {
      if (node.id === id) {
        if (node.conditionGroup) {
          node.conditionGroup = newNode;
        } else if (node.action) {
          node.action = newNode;
        }
        return true;
      }
      if (node.trueNode && findAndReplaceNode(node.trueNode)) return true;
      if (node.falseNode && findAndReplaceNode(node.falseNode)) return true;
      if (node.nextNode && findAndReplaceNode(node.nextNode)) return true;
      return false;
    };
    findAndReplaceNode(updatedWorkflow);
    return updatedWorkflow;
  };

  const addActionNode = (
    id: string,
    direction: string,
    nodeType: string,
    data:
      | EventConditionData
      | SubscriberConditionData
      | ListConditionData
      | WebhookConditionData,
    workflow: WorkflowAction | null | undefined
  ): WorkflowAction | null | undefined => {
    const updatedWorkflow = { ...workflow };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const findAndReplaceNode = (id: string,
      direction: string,
      nodeType: string,
      data:
        | EventConditionData
        | SubscriberConditionData
        | ListConditionData
        | WebhookConditionData,
      workflow: WorkflowAction | null | undefined) => {
      if (workflow) {
        if (workflow.id === id) {
          if ('nextNode' in workflow && direction === '') {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            workflow.nextNode = { id: uuid(), _type: nodeType, action: data, nextNode: workflow.nextNode, };
            return workflow;
          }
          if ("trueNode" in workflow && direction === "left") {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            workflow.trueNode = { id: uuid(), _type: nodeType, action: data, nextNode: workflow.trueNode }
            return workflow;
          }
          if ("falseNode" in workflow && direction === "right") {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            workflow.falseNode = { id: uuid(), _type: nodeType, action: data, nextNode: workflow.falseNode }
            return workflow;
          }
          if (direction === "right") {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            workflow.falseNode = { id: uuid(), _type: nodeType, action: data }
          }
          if (direction === "left") {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            workflow.trueNode = { id: uuid(), _type: nodeType, action: data }
          }
          if (direction === "") {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            workflow.nextNode = { id: uuid(), _type: nodeType, action: data }
          }
          return true;
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore 
        if ("trueNode" in workflow && findAndReplaceNode(id, direction, nodeType, data, workflow.trueNode)) return true;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if ("falseNode" in workflow && findAndReplaceNode(id, direction, nodeType, data, workflow.falseNode)) return true;
        if (workflow?.nextNode && findAndReplaceNode(id, direction, nodeType, data, workflow.nextNode)) return true;
        return false;
      }
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    findAndReplaceNode(id, direction, nodeType, data, updatedWorkflow);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return updatedWorkflow;
  };

  const addConditionNode = (
    id: string,
    direction: string,
    nodeType: string,
    data:
      | EventConditionData
      | SubscriberConditionData
      | ListConditionData
      | WebhookConditionData,
    workflow: WorkflowAction | null | undefined
  ): WorkflowAction | null | undefined => {
    const updatedWorkflow = { ...workflow };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const findAndReplaceNode = (id: string,
      direction: string,
      nodeType: string,
      data:
        | EventConditionData
        | SubscriberConditionData
        | ListConditionData
        | WebhookConditionData,
      workflow: WorkflowAction | null | undefined) => {
      if (workflow) {
        if (workflow.id === id) {
          if ('nextNode' in workflow && direction === '') {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            workflow.nextNode = { id: uuid(), _type: nodeType, conditionGroup: data, trueNode: workflow.nextNode, };
            return workflow;
          }
          if ("trueNode" in workflow && direction === "left") {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            workflow.trueNode = { id: uuid(), _type: nodeType, conditionGroup: data, trueNode: workflow.trueNode }
            return workflow;
          }
          if ("falseNode" in workflow && direction === "right") {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            workflow.falseNode = { id: uuid(), _type: nodeType, conditionGroup: data, trueNode: workflow.falseNode }
            return workflow;
          }
          if (direction === "right") {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            workflow.falseNode = { id: uuid(), _type: nodeType, conditionGroup: data }
          }
          if (direction === "left") {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            workflow.trueNode = { id: uuid(), _type: nodeType, conditionGroup: data }
          }
          if (direction === "") {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            workflow.nextNode = { id: uuid(), _type: nodeType, conditionGroup: data }
          }
          return true;
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore 
        if ("trueNode" in workflow && findAndReplaceNode(id, direction, nodeType, data, workflow.trueNode)) return true;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if ("falseNode" in workflow && findAndReplaceNode(id, direction, nodeType, data, workflow.falseNode)) return true;
        if (workflow?.nextNode && findAndReplaceNode(id, direction, nodeType, data, workflow.nextNode)) return true;
        return false;
      }
    };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    findAndReplaceNode(id, direction, nodeType, data, updatedWorkflow);
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return updatedWorkflow;
  };

  const updateNodeHandler = (
    data:
      | EventConditionData
      | SubscriberConditionData
      | ListConditionData
      | WebhookConditionData,
    nodeType: string,
  ) => {
    if (initialAdd) {
      addInitialNode(data, addBlockData.addType, nodeType);
    } else if (addBlockData.addType === 'action') {
      const node = addActionNode(
        addBlockData.nodeId,
        addBlockData.direction,
        nodeType,
        data,
        workflowCtx?.workflow?.workflow
      );
      workflowCtx?.workflowChangeHandler({
        ...workflowCtx?.workflow,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        workflow: node,
      });
    } else if (addBlockData.addType === "condition") {
      const node = addConditionNode(
        addBlockData.nodeId,
        addBlockData.direction,
        nodeType,
        data,
        workflowCtx?.workflow?.workflow
      );

      workflowCtx?.workflowChangeHandler({
        ...workflowCtx?.workflow,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        workflow: node,
      });
    } else {
      const updatedNode = updateNode(
        nodeId || '',
        data,
        workflowCtx?.workflow?.workflow
      );
      workflowCtx?.workflowChangeHandler({
        ...workflowCtx.workflow,
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        workflow: updatedNode,
      });
    }
  };

  const onEditInitialNode = (data: TagsConditionData | InitialEventData) => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    workflowCtx?.workflowChangeHandler({
      ...workflowCtx.workflow,
      initialConditionGroup: data,
    });
  };

  const deleteFalseNode = (
    node: WorkflowAction | WorkflowCondition | undefined | null,
    id: string | undefined
  ) => {
    if (!node) {
      return null;
    }
    if (node.id === id) {
      return 'trueNode' in node ? node.trueNode : null;
    }
    if ('trueNode' in node) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      node.trueNode = deleteFalseNode(node.trueNode, id);
    }
    if ('falseNode' in node) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      node.falseNode = deleteFalseNode(node.falseNode, id);
    }

    if ('nextNode' in node) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      node.nextNode = deleteFalseNode(node.nextNode, id);
    }
    return node;
  };

  const deleteYesNode = (
    node: WorkflowAction | WorkflowCondition | undefined | null,
    id: string | undefined
  ) => {
    if (!node) {
      return null;
    }
    if (node.id === id) {
      return 'falseNode' in node ? node.falseNode : null;
    }

    if ('trueNode' in node) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      node.trueNode = deleteYesNode(node.trueNode, id);
    }

    if ('falseNode' in node) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      node.falseNode = deleteYesNode(node.falseNode, id);
    }

    if ('nextNode' in node) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      node.nextNode = deleteYesNode(node.nextNode, id);
    }

    return node;
  };

  const deleteNode = (
    node: WorkflowAction | WorkflowCondition | undefined | null,
    id: string | undefined,
    deleteAllChilds?: boolean
  ): WorkflowAction | WorkflowCondition | null | undefined => {
    if (!node) {
      return null;
    }

    if (node.id === id) {
      if (deleteAllChilds) {
        return null;
      } else {
        return 'nextNode' in node
          ? node.nextNode
          : 'trueNode' in node && node.trueNode !== null
            ? node.trueNode
            : 'falseNode' in node
              ? node.falseNode
              : null;
      }
    }

    if ('trueNode' in node) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      node.trueNode = deleteNode(node.trueNode, id, deleteAllChilds);
    }
    if ('falseNode' in node) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      node.falseNode = deleteNode(node.falseNode, id, deleteAllChilds);
    }
    if ('nextNode' in node) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      node.nextNode = deleteNode(node.nextNode, id, deleteAllChilds);
    }

    if ('nextNode' in node && node.nextNode?.id === id) {
      delete node.nextNode;
      return node;
    }

    if (!('nextNode' in node)) {
      return node;
    }

    if (deleteAllChilds) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const nextNode: any = deleteNode(node.nextNode, id, true);
      if ('nextNode' in node && node?.nextNode?.id === id) {
        node.nextNode = nextNode?.nextNode;
      } else {
        if (nextNode !== node.nextNode) {
          node.nextNode = nextNode;
        }
      }
      return node;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const nextNode: any = deleteNode(node.nextNode, id);
      if (nextNode === node.nextNode) {
        return node;
      }
      if (!nextNode) {
        delete node.nextNode;
      }
      return {
        ...node,
        nextNode,
      };
    }
  };

  const deleteWorkflowHandler = (
    workflow: WorkflowAction | WorkflowCondition | undefined
  ) => {
    setModalType('deleteNode');
    setNodeId(workflow?.id);
    if (workflow) {
      if ('nextNode' in workflow && workflow.nextNode) {
        setHasActionChildNodes(true);
      } else if (
        'trueNode' in workflow &&
        'falseNode' in workflow &&
        workflow.trueNode &&
        workflow.falseNode
      ) {
        setHasBranchNodes(true);
      } else if (
        (('trueNode' in workflow && workflow.trueNode) || ('falseNode' in workflow && workflow.falseNode))
      ) {
        setHasActionChildNodes(true);
      } else {
        setHasBranchNodes(false);
        setHasActionChildNodes(false);
      }
    }
    setShowModal(true);
  };

  const deleteAllChildHandler = () => {
    const updatedWorkflow = deleteNode(
      workflowCtx?.workflow?.workflow,
      nodeId ? nodeId : '',
      true
    );
    workflowCtx?.workflowChangeHandler({
      ...workflowCtx.workflow,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      workflow: updatedWorkflow,
    });
    setShowModal(false);
  };

  const singleNodeDeleteHandler = () => {
    const updatedWorkflow = deleteNode(workflowCtx?.workflow?.workflow, nodeId);
    workflowCtx?.workflowChangeHandler({
      ...workflowCtx.workflow,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      workflow: updatedWorkflow,
    });
    setShowModal(false);
  };

  const deleteYesNodeHandler = () => {
    const updatedNode = deleteYesNode(workflowCtx?.workflow?.workflow, nodeId);
    workflowCtx?.workflowChangeHandler({
      ...workflowCtx.workflow,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      workflow: updatedNode,
    });
    setShowModal(false);
  };

  const deleteNoNodeHandler = () => {
    const updatedNode = deleteFalseNode(
      workflowCtx?.workflow?.workflow,
      nodeId
    );
    workflowCtx?.workflowChangeHandler({
      ...workflowCtx.workflow,
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      workflow: updatedNode,
    });
    setShowModal(false);
  };

  const nodeClickHandler = () => {
    setNodeId(workflow?.id);
    if (initialNode) {
      setModalType(
        workflowCtx?.workflow?.initialConditionGroup?._type
          ? workflowCtx?.workflow?.initialConditionGroup?._type
          : ''
      );
      const node = workflowCtx?.workflow?.initialConditionGroup;
      if (node) {
        if ('tagCondition' in node && 'tags' in node) {
          setInitialTagData(node);
        } else if ('eventName' in node) {
          setInitialEventData(node);
        }
      }
    } else {
      setModalType(workflow?._type ? workflow._type : '');
      if (workflow) {
        if ('conditionGroup' in workflow) {
          if (
            workflow.conditionGroup._type === 'EventConditionGroup' &&
            'timestampCondition' in workflow.conditionGroup
          ) {
            setEventConditionData(workflow.conditionGroup);
          }
          if (
            workflow.conditionGroup._type === 'SubscriberFieldConditionGroup' &&
            'conditions' in workflow.conditionGroup
          ) {
            setSubscriberConditionData(workflow.conditionGroup);
          }
          if (
            workflow.conditionGroup._type === 'TagConditionGroup' &&
            'tags' in workflow.conditionGroup
          ) {
            setTagConditionData(workflow.conditionGroup);
          }
          if (
            workflow.conditionGroup._type === 'ListMembershipCondition' &&
            'listCondition' in workflow.conditionGroup
          ) {
            setListConditionData(workflow.conditionGroup);
          }
          if (
            workflow.conditionGroup._type === 'WebhookResponseConditionGroup' &&
            'webhookUrl' in workflow.conditionGroup
          ) {
            setWebhookConditionData(workflow.conditionGroup);
          }
        }
        if ('action' in workflow) {
          if (
            workflow.action?._type === 'AddToListTriggerAction' &&
            'listIds' in workflow.action
          ) {
            setListActionData(workflow.action);
          }
          if (
            workflow.action?._type === 'RemoveFromListTriggerAction' &&
            'listIds' in workflow.action
          ) {
            setListActionData(workflow.action);
          }
          if (
            workflow.action?._type === 'WebhookTriggerAction' &&
            'url' in workflow.action
          ) {
            setWebhookActionData(workflow.action);
          }
          if (
            workflow.action?._type === 'PublishEventAction' &&
            'eventName' in workflow.action
          ) {
            setPublishActionData(workflow.action);
          }
          if (
            workflow.action?._type === 'ExecuteScriptAction' &&
            'workflowScriptId' in workflow.action
          ) {
            setScriptActionData(workflow.action);
          }
          if (
            workflow.action?._type === 'UpdateScoreAction' &&
            'scoreChange' in workflow.action
          ) {
            setScoreData(workflow.action);
          }
          if (
            workflow.action?._type === 'DelayAction' &&
            'useDayOfWeek' in workflow.action
          ) {
            setTimerData(workflow.action);
          }
          if (
            workflow.action?._type === 'NotifyByEmailAction' &&
            'emailName' in workflow.action
          ) {
            setNotifyEmailData(workflow.action);
          }
          if (
            workflow.action?._type === 'SendEmailAction' &&
            'fromEmailName' in workflow.action
          ) {
            setSendEmailData(workflow.action);
          }
        }
      }
    }
    setShowModal(true);
  };

  const selectedModal: Modal = {
    deleteNode: (
      <DeleteModal
        onClose={() => setShowModal(false)}
        hasChildActionNodes={hasChildActionNodes}
        hasBranchNodes={hasBranchNodes}
        onDeleteAllChildNodes={() => deleteAllChildHandler()}
        onDeleteSingleNode={() => {
          singleNodeDeleteHandler();
        }}
        onLastNodeDelete={() => {
          singleNodeDeleteHandler();
        }}
        onDeleteYesNode={() => {
          deleteYesNodeHandler();
        }}
        onDeleteNoNode={() => {
          deleteNoNodeHandler();
        }}
      />
    ),
    TagInitialConditionGroup: (
      <InitialTag
        onClose={() => setShowModal(false)}
        tagData={initialTagData}
        onEventSelect={() => {
          setInitialEventData(undefined);
          setModalType('EventInitialConditionGroup');
        }}
        onSave={(data) => onEditInitialNode(data)}
      />
    ),
    EventInitialConditionGroup: (
      <InitialEvent
        onClose={() => setShowModal(false)}
        eventData={initialEventData}
        onTagSelect={() => {
          setInitialTagData(undefined);
          setModalType('TagInitialConditionGroup');
        }}
        onSave={(data) => onEditInitialNode(data)}
      />
    ),
    EventConditionNode: (
      <EventCondition
        data={eventConditionData}
        onClose={() => setShowModal(false)}
        onSave={(data) => {
          updateNodeHandler(data, 'EventConditionNode');
        }}
      />
    ),
    SubscriberFieldConditionNode: (
      <SubscribersField
        onClose={() => setShowModal(false)}
        data={subscriberConditionData}
        onSave={(data) => {
          updateNodeHandler(data, 'SubscriberFieldConditionNode');
        }}
      />
    ),
    TagConditionNode: (
      <InitialTag
        tagData={tagConditionData}
        onClose={() => setShowModal(false)}
        condition={true}
        onSave={(data) => {
          const initialData = { ...data, _type: 'TagConditionGroup' };
          updateNodeHandler(initialData, 'TagConditionNode');
        }}
      />
    ),
    ListMembershipConditionNode: (
      <List
        data={listConditionData}
        onClose={() => setShowModal(false)}
        onSave={(data) => {
          updateNodeHandler(data, 'ListMembershipConditionNode');
        }}
      />
    ),
    WebhookResponseConditionNode: (
      <WebhookCondition
        data={webhhookConidtionData}
        onClose={() => setShowModal(false)}
        onSave={(data) => {
          updateNodeHandler(data, 'WebhookResponseConditionNode');
        }}
      />
    ),
    AddToListActionNode: (
      <List
        action={true}
        data={listActionData}
        onClose={() => setShowModal(false)}
        onSave={(data) => {
          const addListData = {
            ...data,
            _type: 'AddToListTriggerAction',
          };
          updateNodeHandler(addListData, 'AddToListActionNode');
        }}
      />
    ),
    RemoveFromListActionNode: (
      <List
        isRemove={true}
        action={true}
        data={listActionData}
        onClose={() => setShowModal(false)}
        onSave={(data) => {
          const removeListData = {
            ...data,
            _type: 'RemoveFromListTriggerAction',
          };
          updateNodeHandler(removeListData, 'RemoveFromListActionNode');
        }}
      />
    ),
    WebhookActionNode: (
      <WebhookAction
        data={webhookActionData}
        onClose={() => setShowModal(false)}
        onSave={(data) => {
          updateNodeHandler(data, 'WebhookActionNode');
        }}
      />
    ),
    PublishEventActionNode: (
      <PublishAction
        data={publishActionData}
        onClose={() => setShowModal(false)}
        onSave={(data) => {
          updateNodeHandler(data, 'PublishEventActionNode');
        }}
      />
    ),
    UnsubscribeNode: (
      <UnsubscribeAction
        onClose={() => setShowModal(false)}
        onSave={(data) => {
          updateNodeHandler(data, 'UnsubscribeNode');
        }}
      />
    ),
    ExecuteScriptNode: (
      <ScriptAction
        data={scriptActionData}
        onClose={() => setShowModal(false)}
        onSave={(data) => {
          updateNodeHandler(data, 'ExecuteScriptNode');
        }}
      />
    ),
    UpdateScoreActionNode: (
      <UpdateScore
        data={scoreData}
        onClose={() => setShowModal(false)}
        onSave={(data) => {
          updateNodeHandler(data, 'UpdateScoreActionNode');
        }}
      />
    ),
    DelayNode: (
      <DelayTime
        data={timerData}
        onClose={() => setShowModal(false)}
        onSave={(data) => {
          updateNodeHandler(data, 'DelayNode');
        }}
      />
    ),
    NotifyByEmailActionNode: (
      <NotifyEmail
        emailData={notifyEmailData}
        onClose={() => setShowModal(false)}
        onSave={(data) => {
          updateNodeHandler(data, 'NotifyByEmailActionNode');
        }}
      />
    ),
    AddBlock:
      <AddBlock
        onAddBlock={(type, addType) => {
          setAddBlockData({ ...addBlockData, addType: addType })
          setModalType(type)
        }}
        onClose={() => setShowModal(false)}
      />,
    SendEmailActionNode: (
      <SendEmail
        emailData={sendEmailData}
        onClose={() => setShowModal(false)}
        onSave={(data) => updateNodeHandler(data, 'SendEmailActionNode')}
      />
    ),
  };
  return (
    <div className="relative">
      <div>
        <WorkflowModal
          isOpen={showModal}
        >
          {selectedModal[modalType]}
        </WorkflowModal>
        <div>
          <div
            onClick={() => nodeClickHandler()}
            className="group w-52 mx-auto border border-gray-100 rounded-lg boxShadow border-md bg-white hover:bg-workflowHover hover:text-white py-6 px-4 relative cursor-pointer ease-in duration-300"
          >
            <p className="text-xs font-semibold leading-4 tracking-wide text-gray-700 text-center ease-in duration-300">
              {initialNode
                ? 'Trigger Event'
                : actionNode
                  ? 'Action'
                  : conditionNode
                    ? 'Condition'
                    : ''}
            </p>
            <div className="w-7 h-7 mx-auto rounded-full flex justify-center items-center mt-4 bg-[#c2dbd9]">
              <img src={ActivityTrigger} />
            </div>
            <p className="text-sm font-semibold leading-3 text-black-300 mt-5 text-center ease-in duration-300">
              {nodeName}
            </p>
            {!initialNode && (
              <img
                onClick={(e) => {
                  e.stopPropagation();
                  deleteWorkflowHandler(workflow);
                }}
                className="absolute w-4 h-4 top-2 right-2  cursor-pointer"
                src={Close}
              />
            )}
          </div>
          {initialNode && (
            <>
              <Connector />
              <div
                onClick={() => {
                  setInitialAdd(true);
                  setShowModal(true);
                  setModalType('AddBlock');
                  setAddBlockData({ ...addBlockData });
                }}
                className="group w-52 mx-auto flex justify-center items-center text-13 font-medium leading-3 text-black-300 px-4 py-4 rounded-lg border border-gray-100 boxShadow bg-gray-400 hover:bg-workflowHover cursor-pointer ease-in duration-300"
              >
                <img className="mr-2" src={TimeClock} alt="Clock" />
                <span className='ease-in duration-300'>Add Block</span>
              </div>
              {workflow && <Connector />}
              <BranchNodes
                onAddBlock={(
                  id: string,
                  direction?: string
                ) => {
                  setInitialAdd(false);
                  setAddBlockData({
                    ...addBlockData,
                    nodeId: id,
                    direction: direction || '',
                  });
                  setShowModal(true);
                  setModalType('AddBlock');
                }}
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};
export default Node;
