import React, { FC } from 'react';
import NextArrow from 'assets/Images/Dashboard/next-Arrow.svg';

interface Props {
  currentStep: number;
  handleNextStep: () => void;
}
const NextButton: FC<Props> = ({ currentStep, handleNextStep }) => {
  const NextButtonText: { [key: number]: string } = {
    1: 'Sender',
    2: 'Subject',
    3: 'Delivery Options',
    4: 'Schedule Blast',
    5: 'Finish',
    6: '',
  };

  return (
    <button
      onClick={handleNextStep}
      className="xl:ml-auto xl:mt-0 mt-4 bg-primary xl:w-auto w-full justify-center h-[38px] flex items-center px-3 py-2 rounded-md text-white"
    >
      <p className="mr-2 text-13">Go to {NextButtonText[currentStep]}</p>
      <img className="w-4 h-4" src={NextArrow} alt="NextArrow" />
    </button>
  );
};
export default NextButton;
