import { useContext, useEffect} from 'react';
import { getSingleWorkflow } from 'services/apiHandlers/Workflows/Workflow';

import { WorkflowSchema } from './index.type';
import { z } from 'zod';
import Header from './WorkflowHeader';
import { WorkflowContext } from 'store/WorkflowContext';
import WorkflowPreview from './WorkflowPreview';
import CreateWorkflowHeader from './CreateWorkflowHeader';
import { useParams } from 'react-router-dom';
import React from "react"
import Wrapper from 'components/Wrapper';


const responseSchema = z.object({
  data: WorkflowSchema,
  status: z.number(),
});

type Response = z.infer<typeof responseSchema>;

const Workflow = () => {
  const workflowCtx = useContext(WorkflowContext);
  const { id } = useParams();

  const getData = async () => {
    try {
      if (id) {
        const response = (await getSingleWorkflow(id)) as Response;
        if (response.status === 200) {
          if (WorkflowSchema.safeParse(response.data)) {
            workflowCtx?.workflowChangeHandler(response.data);
            workflowCtx?.onChangeWorkflowId(response.data.id || '')
          }
        }
      }
    } catch (error) {
      console.log('error is : ', error);
    }
  };

  useEffect(() => {
    getData()
  }, [id])
  
  return (
    <div className='mx-4 my-8 min-h-[71vh]'>
    <Wrapper>
    <div className=" font-inter  h-full ">
      <div className="  mb-16">
      <div className="body-content-right">
      
        {id ? <Header /> : <CreateWorkflowHeader />}
        <WorkflowPreview onSave={getData}/>
      </div>
    </div>
    </div>
    </Wrapper>
    </div>
  );
};
export default Workflow;
