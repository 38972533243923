import { Toastify } from 'App';
import { FC, useContext } from 'react';
import {
    createCampaign,
    createTrigger,
} from 'services/apiHandlers/Campaigns/Emails';
import { CampaignContext } from 'store/CampaignContext';
import { z } from 'zod';
import { CreateTrigger } from './index.type';
import React from 'react';
import moment from 'moment';

const createResponseSchema = z.object({ status: z.number() });
type CreateResponse = z.infer<typeof createResponseSchema>;
interface Props {
    // setCampaignCreated: ()=> void
    setCampaignCreated: (created: boolean) => void;
}
const SaveCampaign: FC<Props> = ({ setCampaignCreated }) => {
    const campaignCtx = useContext(CampaignContext);

    const saveCampaignHandler = async () => {
        if (campaignCtx) {
            if (campaignCtx.campaignData && campaignCtx.onFormSubmit()) {
                if (campaignCtx.campaignData.schedule_type === 'now') {
                    try {
                        const createCampaignResponse = (await createCampaign({
                            ...campaignCtx.campaignData,
                            from_id: campaignCtx.campaignData.from.id,
                        })) as CreateResponse;
                        if (createCampaignResponse.status === 201) {
                            setCampaignCreated(true);
                            Toastify(
                                'Campaign Created Successfully',
                                'success',
                                'header1'
                            );
                        }
                    } catch (error) {
                        Toastify(
                            'Creating Campaign Failed',
                            'error',
                            'header2'
                        );
                    }
                } else if (
                    campaignCtx.campaignData.schedule_later ||
                    campaignCtx.campaignData.subscriberField
                ) {
                    const triggerData = campaignCtx.campaignData;
                    const data: CreateTrigger = {
                        trigger: {
                            'stops_at(1i)': '',
                            'stops_at(2i)': '',
                            'stops_at(3i)': '',
                            offset: -7,
                            field_id: triggerData.subscriberField?.id,
                            name: triggerData.triggerName || '',
                            description: '',
                            status: 'enabled',
                            date_type:
                                triggerData.schedule_type === 'subscriber'
                                    ? 'field'
                                    : 'date',
                            email_id: triggerData.email_id?.toString() || '',
                            from_name:
                                triggerData.senderTriggerData?.from_name || '',
                            from_email_address:
                                triggerData.senderTriggerData
                                    ?.from_email_address || '',
                            reply_to_name: triggerData.reply_to_name,
                            reply_to_email_address:
                                triggerData.reply_to_email_address,
                            subject: triggerData.subject,
                            tag:
                                triggerData?.tag_list
                                    ?.join('')
                                    .replace(/[[\]]/g, '') || '',
                            track_clicks: triggerData.options.track_clicks
                                ? 'yes'
                                : 'no',
                            google_analytics: `utm_source=${triggerData.utm_source}&utm_medium=${triggerData.utm_medium}&utm_content=${triggerData.utm_content}&utm_campaign=${triggerData.utm_campaign}`,
                            include_social_bar: triggerData.options
                                .include_social_bar
                                ? 1
                                : 0,
                            share_title: triggerData.share_title,
                            share_description: triggerData.share_description,
                            date_month: triggerData.schedule_later?.month || '',
                            date_day: triggerData.schedule_later?.day || '',
                            date_year: triggerData.schedule_later?.year || '',
                        },
                        trigger_status_options_visible: 0,
                        pseudo_stops_at: 1,
                        stops_at: {
                            month: moment().format('M'),
                            day: moment().format('D'),
                            year: moment().format('YYYY'),
                        },
                        trigger_kind: '',
                        date_field_offset:
                            triggerData.subscriberField?.offset || 0,
                        date_field_relativity:
                            triggerData.subscriberField?.relativity || '',
                        trigger_repetition: {
                            frequency:
                                triggerData.schedule_type === 'subscriber'
                                    ? triggerData.subscriberField?.frequency ||
                                      ''
                                    : triggerData.schedule_later?.frequency ||
                                      '',
                            interval:
                                triggerData.schedule_later?.interval || '',

                            days_of_week:
                                triggerData.schedule_later?.days_of_week.filter(
                                    (value) => value !== 0
                                ) || [],
                        },
                        trigger_time: {
                            month: moment().format('M'),
                            day: moment().format('D'),
                            year: moment().format('YYYY'),
                            hour: triggerData.schedule_later?.hour || '',
                            minute: triggerData.schedule_later?.minute || '',
                        },
                        finder: {
                            for_blast: 'true',
                            inclusion_lists: triggerData.include_lists,
                            segment_id: triggerData.filter_id,
                            person_database_id: '1',
                            exclusion_lists: triggerData.exclude_lists,
                        },
                        merge: {
                            fields: triggerData.mergeFields || '',
                        },
                        newTag: '',
                        google_analytics_utm_source: triggerData.utm_source,
                        google_analytics_utm_medium: triggerData.utm_medium,
                        google_analytics_utm_content: triggerData.utm_content,
                        google_analytics_utm_campaign: triggerData.utm_campaign,
                        google_analytics_integration:
                            triggerData.google_analytics ? 1 : 0,
                        commit: 'Create',
                    };
                    try {
                        console.log('test');
                        const createTriggerResponse = (await createTrigger(
                            data
                        )) as CreateResponse;
                        if (createTriggerResponse.status) {
                            Toastify(
                                'Trigger Created Successfully',
                                'success',
                                'header3'
                            );
                            setCampaignCreated(true);
                        }
                    } catch (error) {
                        Toastify('Creating Trigger Failed', 'error', 'header4');
                    }
                }
            }
        }
    };

    return (
        <div className="flex items-center">
            <button
                type="button"
                className="text-sm font-medium h-11 w-28 leading-5 text-white py-2.5 px-4 rounded-md bg-primary"
                onClick={saveCampaignHandler}
            >
                Save
            </button>
        </div>
    );
};
export default SaveCampaign;
