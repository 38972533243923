import { useEffect, useState } from 'react';
import CopyPasteHtml from './CopyPasteHtml';
import ImportWebPage from './ImportWebPage';
import UploadFile from './UploadFile';
import { z } from 'zod';
import { getMailMergeOptions } from 'services/apiHandlers/Campaigns/Emails';
import React from 'react';

const getMergeOptionResponseSchema = z.object({
    data: z.any(),
    status: z.number(),
});
type getMergeOptionResponse = z.infer<typeof getMergeOptionResponseSchema>;


const OwnHtml = () => {
    const [showSection, setShowSection] = useState<string>('');
    const [groups, setGroups] = useState<Array<string>>([]);
    const [groupOptions, setGroupOptions] = useState<Array<Array<string>>>([]);

    const getData = async () => {
        try {
            const getMergeOptionsResponse =
                (await getMailMergeOptions()) as getMergeOptionResponse;
            const tempGroups: string[] = [];
            const tempOptions: string[][] = [];
            if (getMergeOptionsResponse.status === 200) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                getMergeOptionsResponse.data.map((options: any) => {
                    tempGroups.push(options[0]);
                    tempOptions.push(options[1]);
                });
            }
            setGroupOptions(tempOptions);
            setGroups(tempGroups);
        } catch (error) {
            console.log('error is : ', error);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className="overflow-auto pb-2">
            <p className="pl-7 text-sm font-normal leading-5 text-[#212529] dark:text-white mt-1">
                Copy-paste, Import from the web, or upload from a file
            </p>
            <div>
                <CopyPasteHtml
                    onCopyShow={() => setShowSection('copy')}
                    showContent={showSection === 'copy'}
                    groups={groups}
                    options={groupOptions}
                />
                <ImportWebPage
                    showContent={showSection === 'web'}
                    onWebShow={() => setShowSection('web')}
                />
                <UploadFile
                    showContent={showSection === 'upload'}
                    onUploadFile={() => setShowSection('upload')}
                />
            </div>
        </div>
    );
};
export default OwnHtml;
