import ShopifyForm from './ShopifyForm';
import { useEffect, useState } from 'react';
import { cartOptions } from 'staticdata/integration/DropdownOptions';
import Dropdown from 'components/Dropdown/Dropdown';

import Magneto2Form from './Magneto2-Form';
import Magneto1Form from './Magento1-Form';
import BigCommerceLegacyForm from './BigCommerceLegacyForm';
import BigCommerceForm from './BigCommerceForm';
import WooCommerceForm from './WooCommerce';
import {
  validate2Magento,
  validateMagento,
  validateBigCommerce,
  validateBigCommerceLegacy,
  integration,
  validateWooCommerce,
  validateShopify,
} from './Types';
import { getIntegrations } from 'services/apiHandlers/Integration/CartIntegration';
import { z } from 'zod';
import ErrorBoundary from 'components/ErrorBoundary';
import React from 'react';
import Wrapper from 'components/Wrapper';
import BreadcrumbsWrapper from 'components/Wrapper/BreadcrumbsWrapper';

const responseStatusSchema = z.object({
  status: z.number(),
  data: z.array(z.any()),
});
type ResponseStatus = z.infer<typeof responseStatusSchema>;

const Cart = () => {
  const [integration, setIntegration] = useState('Select Integration');
  const [integrations, setIntegrations] = useState<Array<string>>([]);
  const [showIntegration, setShowIntegration] = useState<boolean>(false);
  const [integrationData, setIntegrationData] = useState<Array<integration>>(
    []
  );
  const [disableOptions, setDisableOptions] = useState<Array<string>>([]);
  const [disableIntegration, setDisableIntegration] = useState<boolean>(false);
  const getData = async () => {
    try {
      const getInetgrationResponse =
        (await getIntegrations()) as ResponseStatus;
      if (getInetgrationResponse.status === 200) {
        //LOGIN TO BE IMPLEMENTED UPON GETTING INTEGRATION DATA
      }
    } catch (error) {
      console.log('error is: ', error);
    }
  };

  const removeIntegrationHandler = (type: string) => {
    setIntegrationData(integrationData.filter((data) => data._type !== type));
  };

  const setOptions = () => {
    let tempOptions = [];
    tempOptions = integrationData.map((data) => data._type);
    setDisableOptions(tempOptions);
  };

  const selectHandler = (option: string) => {
    setDisableIntegration(true);
    let tempOptions = [];
    tempOptions = integrationData.map((data) => data._type);
    tempOptions.push(option);
    setDisableOptions(tempOptions);
    setIntegration(option);
  };

  const removeHandler = () => {
    setDisableIntegration(false);
    setIntegration('Select Integration');
    setOptions();
  };

  useEffect(() => {
    getData();
    const tempIntegrations: string[] = [];
    cartOptions.map((option) => tempIntegrations.push(option.name));
    setIntegrations(tempIntegrations);
  }, []);

  useEffect(() => {
    setOptions();
  }, [integrationData]);

  return (
    <ErrorBoundary>
      <BreadcrumbsWrapper/>
      <div id="CartIntegration_content" className="tabcontent px-4 py-4 min-h-[76vh]">
        <Wrapper>
        <div className="sm:px-6 px-4 sm:py-4 py-2.5">
          <div className="lg:flex">
            <div className="lg:w-6/12 lg:pr-5">
              <>
                <div className="flex justify-between ">
                  <h3 className="text-base font-semibold  leading-4 text-textColor dark:text-white  mb-0 ">
                    Cart integrations
                  </h3>
                  <div className="text-right">
                    <a
                      data-pw="add-integrations"
                      id={
                        disableIntegration
                          ? 'add-integration-disabled'
                          : 'add-integration-enabled'
                      }
                      className={`${
                        disableIntegration
                          ? ' text-[#495057] dark:text-[#CED4DA]  dark:text-white opacity-50 cursor-not-allowed '
                          : ' text-primary underline cursor-pointer '
                      } text-xs font-medium dark:text-white mt-1 inline-block `}
                      onClick={() => setShowIntegration(true)}
                    >
                      Add Cart Integrations
                    </a>
                  </div>
                </div>

                {showIntegration && (
                  <div className="my-3">
                    <Dropdown
                      options={integrations}
                      onSelect={(option) => selectHandler(option)}
                      value={integration}
                      disableOptions={disableOptions}
                    />
                  </div>
                )}

                {integration === 'Shopify' && (
                  <div className="mt-3">
                    <ShopifyForm
                      getData={getData}
                      removeShopify={removeHandler}
                    />
                  </div>
                )}

                {integration === 'Big Commerce' && (
                  <div className="mt-3">
                    <BigCommerceForm
                      getData={getData}
                      removeBigCommerce={removeHandler}
                    />
                  </div>
                )}

                {integration === 'Big Commerce Legacy' && (
                  <div className="mt-3">
                    <BigCommerceLegacyForm
                      getData={getData}
                      removeBigCommerce={removeHandler}
                    />
                  </div>
                )}

                {integration === 'Woo Commerce' && (
                  <div className="mt-3">
                    <WooCommerceForm
                      getData={getData}
                      removeWooCommerce={removeHandler}
                    />
                  </div>
                )}

                {integration === 'Magento 2' && (
                  <div className="mt-3">
                    <Magneto2Form
                      getData={getData}
                      removeMagento={removeHandler}
                    />
                  </div>
                )}

                {integration === 'Magento1' && (
                  <div className="mt-3">
                    <Magneto1Form
                      getData={getData}
                      removeMagento={removeHandler}
                    />
                  </div>
                )}

                {integrationData.map((data) => (
                  <div className="mt-3" key={data._type}>
                    {data._type === 'shopify' && (
                      <ShopifyForm
                        getData={getData}
                        removeShopify={() =>
                          removeIntegrationHandler(data._type)
                        }
                        data={data as validateShopify}
                      />
                    )}
                    {data._type === 'magento1' && (
                      <Magneto1Form
                        removeMagento={() =>
                          removeIntegrationHandler(data._type)
                        }
                        getData={getData}
                        data={data as validateMagento}
                      />
                    )}
                    {data._type === 'magento2' && (
                      <Magneto2Form
                        removeMagento={() =>
                          removeIntegrationHandler(data._type)
                        }
                        getData={getData}
                        data={data as validate2Magento}
                      />
                    )}
                    {data._type === 'bigcommerce' && (
                      <BigCommerceForm
                        removeBigCommerce={() =>
                          removeIntegrationHandler(data._type)
                        }
                        getData={getData}
                        data={data as validateBigCommerce}
                      />
                    )}
                    {data._type === 'bigcommercelegacy' && (
                      <BigCommerceLegacyForm
                        removeBigCommerce={() =>
                          removeIntegrationHandler(data._type)
                        }
                        getData={getData}
                        data={data as validateBigCommerceLegacy}
                      />
                    )}
                    {data._type === 'woocommerce' && (
                      <WooCommerceForm
                        removeWooCommerce={() =>
                          removeIntegrationHandler(data._type)
                        }
                        getData={getData}
                        data={data as validateWooCommerce}
                      />
                    )}
                  </div>
                ))}
              </>
              {integration === 'Select Integration' &&
                integrationData.length === 0 && (
                  <div className='text-13 text-[#878A99] '>No Integrations to display yet</div>
                )}
            </div>
           
          </div>
          
        </div>
        </Wrapper>
      </div>
    </ErrorBoundary>
  );
};
export default Cart;
