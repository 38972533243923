import React, { FC, useState } from 'react';
import moment from 'moment';
import DateRange from 'components/DateRange';
import Tabs from 'components/Graph/Tabs';
import { DateRanges } from './index.type';
import SortByDropdown from 'components/Dropdown/SortByDropdown';

interface Props {
    getWorkflowReportsData: (start: string, end: string) => void;
    activeTab: string;
    setActiveTab: (tab: string) => void;
    tabs: string[];
    selectedFilter: string;
    onFilterSelect: (filter: string) => void;
}
const WorfklowReportTableHeader: FC<Props> = ({

    getWorkflowReportsData,
    activeTab,
    setActiveTab,
    tabs,
    selectedFilter,
    onFilterSelect }) => {

    const [dateRange, setDateRange] = useState<DateRanges>({
        startDate: moment(moment().format('YYYY-MM-DD'))
            .subtract(1, 'month')
            .toDate(),
        endDate: moment().toDate(),
    });

    return (
        <div className='dark:border-[#fff] border-[#ced4da] border-b'>
            <div className="flex items-center justify-between px-4 pb-4">
                {/* <div className="flex justify-between p-4 border-b dark:border-[#fff] border-[#ced4da] min-h-[71px] items-center"> */}
                <p className="text-base font-medium text-darkText dark:text-[#878A99] pt-4 min-w-[120px]">
                    {activeTab === 'Email Report'
                        ? 'All Workflows'
                        : activeTab === 'Conversion Report' 
                        ? 'Workflow Conversions'
                        : activeTab === 'Performance Report' 
                        ? 'Workflow Performance' 
                        : activeTab === 'Orders'
                        ? 'Workflow Orders' 
                        : ''}
                </p>

                <div className="relative flex items-center flex-wrap justify-end">
                    <div className=" z-20 pt-4">
                        <Tabs
                            activeTab={activeTab}
                            onTabClick={setActiveTab}
                            tabs={tabs}
                        />
                    </div>
                    <div className="pt-4">
                        <DateRange
                            dateRange={dateRange}
                            onChangeRange={(range: DateRanges) => {
                                setDateRange(range);
                                getWorkflowReportsData(
                                    moment(range.startDate).format('YYYY-MM-DD'),
                                    moment(range.endDate).format('YYYY-MM-DD')
                                );
                            }}
                        />
                    </div>
                </div>
            </div>
            {activeTab === 'Email Report' && 
            <div className='pb-2 flex justify-end pr-4'>
                <SortByDropdown
                    options={['Revenue', 'Status','AOV','Orders']}
                    value={selectedFilter}
                    onSelect={onFilterSelect}
                />
            </div>}
        </div>
    );
};
export default WorfklowReportTableHeader;
