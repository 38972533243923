import DownArrow from 'assets/Images/Common/down-arrow.png';
import Edit from 'assets/Images/ListsegmentImages/edit.png';
import Delete from 'assets/Images/ListsegmentImages/delete.png';
import { useFormik } from 'formik';

import {
  getConnectedServices,
  createConnectedServices,
  editConnectedServices,
} from 'services/apiHandlers/Integration/Miscellaneous';
import { FC, useEffect, useState } from 'react';
import { z } from 'zod';
import * as Yup from 'yup';
import { Toastify } from 'App';
import ErrorBoundary from 'components/ErrorBoundary';
import uuid from 'react-uuid';
import Dropdown from 'components/Dropdown/Dropdown';
import React from "react";
import Wrapper from 'components/Wrapper';

interface Props {
  workflow?: boolean;
  mailer?: string;
}

export const mailerDropdownOptions = ['Mandrill', 'Sendgrid'];

const postResponseSchema = z.object({ status: z.number() });
type postResponse = z.infer<typeof postResponseSchema>;

const dataSchema = z.object({
  accountId: z.string(),
  id: z.string(),
  links: z.object({ triggers: z.array(z.string()) }),
  name: z.string(),
  type: z.string(),
});
type data = z.infer<typeof dataSchema>;

const responseSchema = z.object({
  data: z.array(dataSchema),
  status: z.number(),
});
type response = z.infer<typeof responseSchema>;

const ConnectedServices: FC<Props> = ({ workflow }) => {
  const [showDefaultMailer, setShowDefaultMailer] = useState(false);
  const [showMailingServices, setShowMailingServices] = useState(false);
  const [editServices, setEditServices] = useState(false);
  const [servicesData, setServicesData] = useState<data>();
  const getData = async () => {
    const getConnectedServicesResponse =
      (await getConnectedServices()) as response;
    if (getConnectedServicesResponse.status === 200) {
      setServicesData(getConnectedServicesResponse.data[0]);
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      apiKey: '',
      type: 'Mandrill',
      accountId: 2,
      id: 'c2b6e48a-700e-4411-8eaa-2b6ae6d8a53e',
      links: { triggers: [] },
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
      apiKey: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      if (editServices) {
        try {
          const editServicesResponse = (await editConnectedServices(
            values
          )) as postResponse;
          if (editServicesResponse.status === 200) {
            Toastify('Editing Connected Services Failed', 'error', 'services3');
          }
        } catch (error) {
          Toastify('Editing Connected Services Failed', 'error', 'services3');
        }
      } else {
        const createData = {
          type: values.type,
          name: values.name,
          apiKey: values.apiKey,
        };
        try {
          const createServicesResponse = (await createConnectedServices(
            createData
          )) as postResponse;
          if (createServicesResponse.status === 200) {
            Toastify(
              'Connected Services Created Successfully',
              'success',
              'services1'
            );
          }
        } catch (error) {
          Toastify('Creating Connected Services Failed', 'error', 'services2');
        }
      }
    },
  });

  const editMailerHandler = () => {
    setEditServices(true);
    setShowMailingServices(true);
    formik.setFieldValue('name', servicesData?.name);
    formik.setFieldValue('apiKey', uuid());
    formik.setFieldValue(
      'type',
      servicesData?.type ? servicesData.type : 'Mandrill'
    );
  };

  const mailingServiceHandler = () => {
    setShowMailingServices((prev) => !prev);
    formik.setFieldValue('name', '');
    formik.setFieldValue('type', 'Mandrill');
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <ErrorBoundary>
      <Wrapper>
      <form
        onSubmit={formik.handleSubmit}
        className='p-4'
        // className="after:left-[-3px] after:bottom-[-2px] after:border-b-[#e9ebec] after:border-l-[#e9ebec] after:border-t-transparent after:border-r-transparent after:content-[''] after:h-[13px] after:w-[13px] after:absolute after:border-[3px] before:right-[-2px] before:top-[-3px] before:border-b-transparent before:border-l-transparent before:border-t-[#e9ebec] before:border-r-[#e9ebec] before:content-[''] before:h-[13px] before:w-[13px] before:absolute before:border-[3px]  border border-[rgba(0, 0, 0, 0.125)] p-4 rounded dark:shadow-none  dark:bg-[#41464E] "
      >
        {!workflow && (
          <h3 className="text-13 font-medium leading-4  text-[#495057] dark:text-white mb-2">
            Connected Services
          </h3>
        )}
        <div className='z-10 bg-white mt-4  dark:bg-[#41464E]  border-[#ced4da] dark:border-[#fff] border rounded dropdown-show-hide relative sm:h-[38px] h-9'>
        <div
          onClick={() => setShowDefaultMailer((prev) => !prev)}
          className="  text-sm leading-4 text-[#212529] dark:text-white flex items-center justify-between h-full px-4 focus:outline-none border-0 rounded cursor-pointer"
        >
          <p className="text-13 hiddenleading-5 text-gray-700 dark:text-white">
            {servicesData?.name ? servicesData.name : 'Default Mailer'}
          </p>
          <img src={DownArrow} alt="Arrow" />
        </div>
        </div>
        {showDefaultMailer && (
          <div className="bg-white w-[96%] absolute shadow-md top-[70px] z-[999999]  dark:bg-[#41464E]  mt-4 border border-[#ced4da] dark:border-[#fff] rounded-md boxShadow left-[17px]">
            <div className="flex justify-between item rounded-t bg-[#4a6ae70d] p-3">
              <p className="text-sm font-medium leading-5 text-[#495057] dark:text-[#CED4DA]">
                Default Mailer
              </p>
              <div className="flex items-center">
                <button
                  onClick={editMailerHandler}
                  type="button"
                  className="w-3.5 h-4 mr-2"
                >
                  <img className="w-full dark:invert dark:brightness-0" src={Edit} alt="Edit" />
                </button>
                <button type="button" className="w-3.5 h-4">
                  <img className="w-full dark:invert dark:brightness-0" src={Delete} alt="Delete" />
                </button>
              </div>
            </div>
            <div className="p-3 relative bg-white z-[9999] dark:bg-[#41464E]">
              <div
                className="relative  rounded h-[38px] cursor-pointer px-4 py-1.5 border mb-4 border-[#CED4DA] dark:border-[#fff] "
                onClick={mailingServiceHandler}
              >
                <p className="text-sm font-medium leading-5  text-[#212529] dark:text-white">
                  Connect New Mailing Service
                </p>
                <img
                  className="absolute right-1.5 top-2"
                  src={DownArrow}
                  alt="Arrow"
                />
              </div>
              {showMailingServices && (
                <>
                  {/* <div className="flex justify-between items-center border h-[38px] border-[#ced4da] dark:border-[#fff] rounded-md relative md:w-7/12 mt-2.5">
                    <select
                      className="h-full rounded-md w-full px-3.5 text-13 leading-3 text-[#212529] dark:text-white focus:outline-none  dark:bg-[#41464E] "
                      value={formik.values.type}
                      name="type"
                      defaultValue={formik.values.type}
                      onChange={formik.handleChange}
                    >
                      {mailerDropdownOptions.map((options) => (
                        <option key={options} value={options} label={options}>
                          {options}
                        </option>
                      ))}
                    </select>
                  </div> */}
                  <div className='w-[180px]'>
                  <Dropdown 
                    options={mailerDropdownOptions}
                    onSelect={ (option: string)=>formik.setFieldValue('type',option)}
                    value={formik.values.type}
                  />
                  </div>
                  <div className="mt-4 md:w-7/12">
                    <input
                      className="text-13 placeholder:dark:text-white focus:border-primary dark:focus:border-white focus:outline-none leading-3 text-[#212529] dark:text-white py-2 rounded-md px-4 bg-white border border-[#ced4da] dark:border-[#fff] w-full sm:h-[38px] h-9  dark:bg-[#41464E] "
                      type="text"
                      placeholder="Name for this account"
                      value={formik.values.name}
                      name="name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.name && formik.touched.name ? (
                      <div className="text-xs leading-4 text-red-400 mt-1.5">
                        {formik.errors.name}
                      </div>
                    ) : null}
                  </div>
                  <div className="mt-4 md:w-7/12">
                    <input
                      className="text-13 placeholder:dark:text-white focus:border-primary dark:focus:border-white focus:outline-none leading-3 text-[#212529] dark:text-white py-2 rounded-md px-4 bg-white border border-[#ced4da] dark:border-[#fff] w-full  dark:bg-[#41464E]  sm:h-[38px] h-9"
                      type="text"
                      name="apiKey"
                      value={formik.values.apiKey}
                      placeholder="API key"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.apiKey && formik.touched.apiKey ? (
                      <div className="text-xs leading-4 text-red-400 mt-1.5">
                        {formik.errors.apiKey}
                      </div>
                    ) : null}
                  </div>
                  {editServices ? (
                    <div className="mt-4 flex">
                      <div>
                        <button
                          type="submit"
                          className="text-13 font-medium leading-5 rounded py-2 px-4 text-white bg-primary dark:hover:bg-white dark:hover:text-[#495057] dark:text-[#CED4DA] "
                        >
                          Update
                        </button>
                      </div>
                      <div className="ml-4">
                        <button
                          type="button"
                          className="text-center sm:text-13 text-xs py-2.5 px-4 h-9 flex justify-center items-center w-28 font-medium leading-5 mr-2  rounded-md bg-[#f3f6f9] hover:bg-[#cfd1d4] transition-bg text-[#212529]"
                        >
                          Cancel
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="mt-4">
                      <button
                        type="submit"
                        className="text-13 font-medium leading-5 rounded-md py-2 px-4 text-white bg-primary ease-in-in duration-300 hover:bg-primary hover:scale-105"
                      >
                        Connect
                      </button>
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </form>
      </Wrapper>
    </ErrorBoundary>
  );
};
export default ConnectedServices;
