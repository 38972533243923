import React, { FC, useContext } from 'react';
import { CampaignContext } from 'store/CampaignContext';
import Wrapper from 'components/Wrapper';
import GetFormattedItem from './GetFormattedItem';


const weekDays: { [key: number]: string } = {
	1: 'Monday',
	2: 'Tuesday',
	3: 'Wednesday',
	4: 'Thursday',
	5: 'Friday',
	6: 'Saturday',
	7: 'Sunday',
};
interface Props {
	selectedTemplate: string;
}

const ScheduleBlast: FC<Props> = ({ selectedTemplate }) => {
	const campaignCtx = useContext(CampaignContext);

	return (
		<Wrapper parentClass="mb-6">
			<>
				<p className="text-[#495057] dark:text-white font-semibold text-lg py-2 px-4 border-b">
					Schedule Blast
				</p>
				<div className="p-4">
					<div className="">
						<GetFormattedItem item={{ title: 'Tag Blast', value: campaignCtx?.campaignData?.tag_list ? campaignCtx?.campaignData?.tag_list[0] : 'None' }} itemStyle='text-[#212529]' />
						<GetFormattedItem item={{ title: 'Email Templates', value: selectedTemplate }} itemStyle='text-[#212529]' />
					</div>
					<div className="flex justify-between items-center">
						<p className="text-[#495057] dark:text-white text-base mt-2 font-semibold ">
							Schedule based on
						</p>
						<p className="text-[#495057] dark:text-[#878A99] capitalize text-base mt-2 font-semibold ml-2">
							{campaignCtx?.campaignData?.schedule_type ===
								'now'
								? 'Now'
								: campaignCtx?.campaignData
									?.schedule_type === 'later'
									? 'Specific Date'
									: campaignCtx?.campaignData
										?.schedule_type === 'subscriber'
										? 'Subscribres Field'
										: 'None'}
						</p>
					</div>
					<div>
						{campaignCtx?.campaignData?.schedule_type !== 'now' && (
							<div>
								<div className=" mt-2">
									<GetFormattedItem item={{ title: 'Trigger Name', value: campaignCtx?.campaignData?.triggerName || '' }} itemStyle='text-[#212529]' />
									<div className="flex items-center justify-between text-[#878A99] text-sm">
										<p className=" text-[#212529] dark:text-white text-sm font-medium mt-2">
											Schecule:
										</p>
										<div className='flex items-center'>
											{campaignCtx?.campaignData?.schedule_type === 'subscriber' && (
												<div className="px-1 flex">
													{campaignCtx.campaignData.subscriberField && campaignCtx.campaignData.subscriberField['relativity'] !== 'on' &&
														<div className='flex'>
															<p>{campaignCtx.campaignData.subscriberField && campaignCtx.campaignData.subscriberField['offset']}</p>
															<p className='ml-2'> Day(s) </p>
														</div>}
													<p className='ml-2'>{campaignCtx.campaignData.subscriberField && campaignCtx.campaignData.subscriberField['relativity']}</p>
													<p className='ml-2'>{campaignCtx.campaignData.subscriberField && campaignCtx.campaignData.subscriberField['id'] === -1
														? 'Subscriber Date'
														: campaignCtx.campaignData.subscriberField && campaignCtx.campaignData.subscriberField['id'] === -3
															? 'Last Clicked Date'
															: campaignCtx.campaignData.subscriberField && campaignCtx.campaignData.subscriberField['id'] === -4
																? 'Last Viewed Date'
																: '-'}</p>
													<p className="ml-2">and</p>
													<p className="ml-2">
														{campaignCtx?.campaignData?.subscriberField && campaignCtx?.campaignData?.subscriberField['frequency'] !==
															'does not repeat' && ' Repeats '}
														{campaignCtx?.campaignData?.subscriberField && campaignCtx?.campaignData?.subscriberField['frequency']}
													</p>
												</div>
											)}
										</div>
										{campaignCtx?.campaignData?.schedule_type === 'later' && (
											<div className="px-1 flex">
												<p>{campaignCtx.campaignData.schedule_later && campaignCtx.campaignData.schedule_later['day']}
													-
													{campaignCtx.campaignData.schedule_later && campaignCtx.campaignData.schedule_later['month']}
													-
													{campaignCtx.campaignData.schedule_later && campaignCtx.campaignData.schedule_later['year']}
												</p>
												<p className='ml-2'>and</p>
												<p className="px-1 ml-2 flex">
													{campaignCtx?.campaignData?.schedule_later && campaignCtx?.campaignData?.schedule_later['frequency'] !==
														'does not repeat' &&
														'Repeats'}
													<p className='ml-2'>{campaignCtx?.campaignData?.schedule_later && campaignCtx?.campaignData?.schedule_later['frequency']}</p>
												</p>
												{campaignCtx?.campaignData?.schedule_later && campaignCtx?.campaignData?.schedule_later['frequency'] !== 'weekly' && campaignCtx?.campaignData?.schedule_later['frequency'] !== 'does not repeat' && (
													<p className='flex'>
														Every
														<p className='ml-2'>{campaignCtx.campaignData.schedule_later['interval']}</p>
														<p className='ml-2'>{campaignCtx.campaignData.schedule_later['frequency'] === 'daily'
															? 'Day(s)'
															: campaignCtx.campaignData.schedule_later['frequency'] === 'monthly'
																? 'Month(s)'
																: campaignCtx.campaignData.schedule_later['frequency'] === 'yearly'
																	? 'Year(s)'
																	: 'None'}
														</p>
													</p>
												)}
												{campaignCtx?.campaignData?.schedule_later && campaignCtx?.campaignData?.schedule_later['frequency'] === 'weekly' && (
													<div>
														<p className='flex'>
															Every
															<p className='ml-2'>{campaignCtx.campaignData.schedule_later['interval']}</p>
															<p className='ml-2'>week(s) on</p>
														</p>

														<p>
															{campaignCtx.campaignData.schedule_later && campaignCtx.campaignData.schedule_later['days_of_week'] &&
																campaignCtx.campaignData.schedule_later['days_of_week'].map(
																	(day) => (
																		<p key={day}>
																			{weekDays[day]}
																		</p>
																	)
																)}
														</p>
													</div>
												)}
											</div>
										)}
									</div>
								</div>
								<div className="flex justify-between items-center mt-2">
									<p className=" text-[#212529] dark:text-white text-sm font-medium">
										At what time?
									</p>
									<p className="text-[#878A99] text-sm  ml-2">
										{campaignCtx?.campaignData?.schedule_later && (
											<p>
												{campaignCtx?.campaignData?.schedule_later['hour']}:{campaignCtx?.campaignData?.schedule_later['minute']}
											</p>
										)}
									</p>
								</div>
							</div>
						)}
					</div>
				</div>
			</>
		</Wrapper>
	);
};
export default ScheduleBlast;