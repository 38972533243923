import { useOutsideClick } from 'app/hooks/useOutsideClick';
import React, { FC, useRef } from 'react';

interface Props {
    onClose: () => void;
    children: JSX.Element;
}
const SubscriberEventAccordianModal: FC<Props> = ({ onClose, children }) => {
    const modalRef = useRef<HTMLDivElement>(null);
    useOutsideClick(modalRef, onClose);
    return (
        <div>
            <div className=" px-6 w-full  fixed left-0 top-0 h-full z-30 bg-black-200 duration-300">
                
                <div className="">
                    <div
                        ref={modalRef}
                        style={{
                            margin: '0  auto',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '40%',
                            position: 'relative',
                            top: '-40px'
                        }}
                    >
                        <div className="w-full  mx-auto my-24  rounded-lg flex items-center justify-center">
                            <div className="bg-white dark:bg-[#41464E] rounded-lg w-full max-h-[75vh] overflow-auto scrollbar-hide">
                                <div className=" dark:bg-[#41464E]">{children}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default SubscriberEventAccordianModal;
