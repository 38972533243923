import {
    deleteEditorImage,
    getEditorImages,
    // uploadEditorImage,
} from 'services/apiHandlers/Campaigns/Emails';
// import React from 'react';
import { html } from './html';
import { Toastify } from 'App';
import { css } from './stripoCss';

const {
    REACT_APP_USEREMAIL,
    REACT_APP_SECRET_KEY,
    REACT_APP_PLUGIN_ID,
    REACT_APP_BASE_URL,
    // eslint-disable-next-line no-undef
} = process.env;

const URL = REACT_APP_BASE_URL;

// window.ExternalImagesLibrary = (function () {
//     let externalLibrary;

//     let imageSelectCallback;

//     let cancelCallback;
//     let images;

//     const close = function () {
//         externalLibrary.style.visibility = 'hidden';
//     };

//     const cancelAndClose = function () {
//         close();
//         cancelCallback();
//     };

//     const deleteImage = async (imageSrc) => {
//         const filteredImage = images?.filter(
//             (image) => URL + image[0] === imageSrc
//         );
//         try {
//             const deleteEditorImageResponse = await deleteEditorImage(
//                 filteredImage[0]
//             );
//             if (deleteEditorImageResponse.status === 200) {
//                 const getEditorImagesResponse = await getEditorImages();
//                 if (getEditorImagesResponse.status === 200) {
//                     Toastify(
//                         'Image Deleted Successfully',
//                         'success',
//                         'commonDelete'
//                     );
//                     const updatedImages = getEditorImagesResponse.data;
//                     const imagesDiv = document.getElementById('editorImages');
//                     if (imagesDiv) {
//                         imagesDiv.innerHTML = `<div>
//               <div id="externalImagesLibrary" style="background-color: rgba(0,0,0,.5); overflow: hidden; position: fixed; top: 0; right: 0;  bottom: 0; left: 0; z-index: 1050; font-family: sans-serif;">\
//                  <div style="margin: 10px;">\
//                    <div style="background-color: #f6f6f6; border-radius: 17px 17px 30px 30px; max-width: 900px; margin: 0 auto;">\
//                        <div style="padding: 15px; border-bottom: 1px solid #e5e5e5;">\
//                            <div>\
//                               <button class="close" type="button" style="cursor: pointer; background: transparent; border: 0; float: right; font-size: 21px; font-weight: bold; opacity: .2;">\
//                                    <span>×</span>\
//                                </button>\
//                                <h4 style="margin: 0; font-size: 18px; color: rgb(85, 85, 85);">External Images Library</h4>\
//                            </div>\
//                        </div>\
//                        <div id="editorImages" style="padding: 15px;">\
//                            ${updatedImages?.map((image) => {
//                                const imageSrc = URL + image[0];
//                                return `<div class="thumbnail" style="display: inline-block; width: 154px; cursor: pointer; padding: 4px; background-color: #ffffff; border: 1px solid #b80000; border-radius: 10px; margin-right: 10px">
//                              <button data-image-src="${imageSrc}" id="deleteImage" type="button" style="cursor: pointer; background: transparent; border: 0; float: right; font-size: 21px; font-weight: bold; opacity: 0.2;">
//                                <span>&times;</span>
//                              </button>
//                              <img style="height: 100px; margin-left: auto; margin-right: auto; max-width: 100%; display: block; vertical-align: middle;"
//                                src="${imageSrc}">
//                             </div>`;
//                            })}

//                        </div>\
//                    </div>\
//                </div>

//                <div style="width: 66.666667%; margin-left: 7%; margin-top: 1rem; padding-top: 1.5rem; padding-bottom: 2rem; display: flex; justify-content: center; align-items: center; position: relative; border: 1px dashed #3182ce; border-radius: 0.25rem; background-color: #fbfcfd;">
//                <div style="width: 194px; margin-left: auto; margin-right: auto;">
//                  <div style="padding-left: 0.125rem;">
//                    <button style="font-size: 13px; font-weight: 500; line-height: 1; color: #fff; border-radius: 0.25rem; display: flex; align-items: center; justify-content: center; padding-top: 0.75rem; padding-bottom: 0.75rem; padding-left: 0.5rem; padding-right: 0.5rem; width: 100%; margin-top: 0.625rem; background-color: #3182ce;">
//                      <img src="" />
//                      <span style="margin-left: 0.75rem;">Select files to upload</span>
//                    </button>
//                  </div>
//                </div>
//                <input id="fileUpload" style="position: absolute; top: 0; width: 100%; height: 100%; opacity: 0; cursor: pointer;" type="file" name="upload" />
//              </div>

//                </div>`;
//                         document.body.appendChild(imagesDiv);
//                     }
//                 }
//             }
//         } catch (error) {
//             Toastify('Image Deletetion Failed', 'error', 'commonDelete2');
//         }
//     };

//     const deleteImageClicked = function (imageSrc) {
//         const modal = document.createElement('div');
//         modal.innerHTML = `<div id="deleteConfirmationModal" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background-color: rgba(0, 0, 0, 0.5); z-index: 9999; display: flex; justify-content: center; align-items: center;">
//         <div style="background-color: #fff; padding: 20px; border-radius: 5px; text-align: center;">
//           <p>If you delete this image, it will be removed from your past emails. Do you want to continue?</p>
//           <button id="cancelButton" style="margin-right: 10px;">Cancel</button>
//           <button id="continueButton">Continue</button>
//         </div>
//       </div>`;

//         document.body.appendChild(modal);

//         const cancelButton = document.getElementById('cancelButton');
//         cancelButton?.addEventListener('click', function () {
//             document.body.removeChild(modal);
//         });

//         const continueButton = document.getElementById('continueButton');
//         continueButton?.addEventListener('click', function () {
//             deleteImage(imageSrc);
//             document.body.removeChild(modal);
//         });
//     };

//     const fileUploadHandler = async (file) => {
//         const uploadFileResponse = await uploadEditorImage(file);
//         console.log('upload file response is : ', uploadFileResponse);
//     };

//     const initLibrary = async function () {
//         // const getImages = async() => {
//         try {
//             const getEditorImagesResponse = await getEditorImages();
//             if (getEditorImagesResponse.status === 200) {
//                 images = getEditorImagesResponse.data;
//             }
//         } catch (error) {
//             images = [];
//             console.log('error is : ', error);
//         }
//         // }

//         // getImages()

//         const div = document.createElement('div');
//         div.innerHTML = ` <div id="editorImages">
//         <div id="externalImagesLibrary" style="background-color: rgba(0,0,0,.5); overflow: hidden; position: fixed; top: 0; right: 0;  bottom: 0; left: 0; z-index: 1050; font-family: sans-serif;">\
//            <div style="margin: 10px;">\
//              <div style="background-color: #f6f6f6; border-radius: 17px 17px 30px 30px; max-width: 900px; margin: 0 auto;">\
//                  <div style="padding: 15px; border-bottom: 1px solid #e5e5e5;">\
//                      <div>\
//                         <button class="close" type="button" style="cursor: pointer; background: transparent; border: 0; float: right; font-size: 21px; font-weight: bold; opacity: .2;">\
//                              <span>×</span>\
//                          </button>\
//                          <h4 style="margin: 0; font-size: 18px; color: rgb(85, 85, 85);">External Images Library</h4>\
//                      </div>\
//                  </div>\
//                  <div style="padding: 15px;">\
//                      ${images?.map((image) => {
//                          const imageSrc = URL + image[0];
//                          return `<div class="thumbnail" style="display: inline-block; width: 154px; cursor: pointer; padding: 4px; background-color: #ffffff; border: 1px solid #b80000; border-radius: 10px; margin-right: 10px">
//                        <button data-image-src="${imageSrc}" id="deleteImage" type="button" style="cursor: pointer; background: transparent; border: 0; float: right; font-size: 21px; font-weight: bold; opacity: 0.2;">
//                          <span>&times;</span>
//                        </button>
//                        <img style="height: 100px; margin-left: auto; margin-right: auto; max-width: 100%; display: block; vertical-align: middle;"
//                          src="${imageSrc}">
//                       </div>`;
//                      })}

//                  </div>\
//              </div>\
//          </div>

//          <div style="width: 66.666667%; margin-left: 7%; margin-top: 1rem; padding-top: 1.5rem; padding-bottom: 2rem; display: flex; justify-content: center; align-items: center; position: relative; border: 1px dashed #3182ce; border-radius: 0.25rem; background-color: #fbfcfd;">
//          <div style="width: 194px; margin-left: auto; margin-right: auto;">
//            <div style="padding-left: 0.125rem;">
//              <button style="font-size: 13px; font-weight: 500; line-height: 1; color: #fff; border-radius: 0.25rem; display: flex; align-items: center; justify-content: center; padding-top: 0.75rem; padding-bottom: 0.75rem; padding-left: 0.5rem; padding-right: 0.5rem; width: 100%; margin-top: 0.625rem; background-color: #3182ce;">
//                <img src="" />
//                <span style="margin-left: 0.75rem;">Select files to upload</span>
//              </button>
//            </div>
//          </div>
//          <input id="fileUpload" style="position: absolute; top: 0; width: 100%; height: 100%; opacity: 0; cursor: pointer;" type="file" name="upload" />
//        </div>

//          </div>`;
//         document.body.appendChild(div);

//         const deleteButtons = document.querySelectorAll('#deleteImage');
//         deleteButtons.forEach((button) => {
//             button.addEventListener('click', function () {
//                 deleteImageClicked(button.getAttribute('data-image-src') || '');
//             });
//         });

//         const fileUpload = document.getElementById('fileUpload');
//         fileUpload?.addEventListener('change', function (e) {
//             fileUploadHandler(e?.target?.files[0]);
//         });

//         externalLibrary = document.getElementById('externalImagesLibrary');
//         externalLibrary
//             .querySelector('.close')
//             .addEventListener('click', cancelAndClose);

//         externalLibrary.addEventListener('click', function (e) {
//             if (!e.target.matches('img')) {
//                 return;
//             }

//             const exampleOfCallbackImageObject = {
//                 originalName: '9091542014595406.png',
//                 resolution: '600 x 410 px',
//                 size: '169.20 kb',
//                 url: e.target.getAttribute('src'),
//             };
//             imageSelectCallback(exampleOfCallbackImageObject);
//             close();
//         });
//     };

//     const renderLibrary = function () {
//         if (!externalLibrary) {
//             initLibrary();
//         }
//         externalLibrary.style.visibility = 'visible';
//     };

//     return {
//         openLibrary: function (onImageSelectCallback, onCancelCallback) {
//             imageSelectCallback = onImageSelectCallback;
//             cancelCallback = onCancelCallback;
//             renderLibrary();
//         },
//     };
// })();

window.ExternalImagesLibrary = (function () {
    let images;
    let externalLibrary;
    let imageSelectCallback;
    let cancelCallback;

    const close = function () {
        externalLibrary.style.visibility = 'hidden';
    };

    const libraryHtmlHandler = (content) => {
        return `<div id="externalImagesLibrary" style="background-color: rgba(0,0,0,.5); overflow: hidden; position: fixed; top: 0; right: 0;  bottom: 0; left: 0; z-index: 1050; font-family: sans-serif;">\
    <div class="backdrop" style="background-color: rgba(0,0,0,.5); position: fixed; left: 0px ; right: 0px ; height: 100% ; width: 100% "></div>
    <div style="margin: 10px;;z-index: 999999999;position: relative;"> <div style="background-color: #f6f6f6; border-radius: 17px 17px 30px 30px; max-width: 900px; margin: 0 auto;">
        <div style={padding: "15px", borderBottom: "1px solid #e5e5e5"}>
            <div>
               <button class="close" type="button" style="cursor: pointer; background: transparent; border: 0; float: right; font-size: 21px; font-weight: bold; opacity: .2;">\
                    <span>×</span>
                </button>
                <h4 style="margin: 0; font-size: 18px; color: rgb(85, 85, 85);">External Images Library</h4>
            </div>
        </div>
        <div style="padding: 15px;">
             ${content}
        </div>
    </div>
</div>`;
    };

    const deleteImage = async (imageSrc) => {
        const filteredImages = images?.filter(
            (image) => URL + image[0] !== imageSrc
        );

        try {
            const deleteEditorImageResponse = await deleteEditorImage(
                images.filter((image) => URL + image[0] !== imageSrc)[0]
            );

            if (deleteEditorImageResponse.status === 200) {
                console.log(
                    'filtered image is : ',
                    deleteEditorImageResponse,
                    filteredImages
                );

                libraryHtmlHandler(
                    ` ${filteredImages?.map((image) => {
                        const imageSrc = URL + image[0];
                        return `<div class="thumbnail" style="display: inline-block; width: 154px; cursor: pointer; padding: 4px; background-color: #ffffff; border: 1px solid #b80000; border-radius: 10px; margin-right: 10px">
                                       <button data-image-src="${imageSrc}"  id="deleteImage" type="button" style="cursor: pointer; background: transparent; border: 0; float: right; font-size: 21px; font-weight: bold; opacity: 0.2;">
                                         <span>&times</span>
                                       </button>
                                       <img style="height: 100px; margin-left: auto; margin-right: auto; max-width: 100%; display: block; vertical-align: middle;"
                                         src="${imageSrc}">
                                      </div>`;
                    })}`
                );
                appendContentHandler();
            }
        } catch (error) {
            Toastify('Image Deletetion Failed', 'error', 'commonDelete2');
        }
    };

    const deleteImageClicked = (imageSrc) => {
        const modal = document.createElement('div');
        modal.innerHTML = `<div id="deleteModal">
        <div id="deleteConfirmationModal" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background-color: rgba(0, 0, 0, 0.5); z-index: 9999; display: flex; justify-content: center; align-items: center;">
        <div style="background-color: #fff; padding: 20px; border-radius: 5px; text-align: center;">
          <p>If you delete this image, it will be removed from your past emails. Do you want to continue?</p>
          <button class="es-button" id="cancelButton" style="margin-right: 10px;">Cancel</button>
          <button class="es-button" id="continueButton">Continue</button>
        </div>
      </div></div>`;

        document.body.appendChild(modal);

        const cancelButton = document.getElementById('cancelButton');
        cancelButton?.addEventListener('click', function () {
            document.body.removeChild(modal);
        });

        const continueButton = document.getElementById('continueButton');
        continueButton?.addEventListener('click', function () {
            deleteImage(imageSrc);
            document.body.removeChild(modal);
        });

        // const modalDiv = document.getElementById('deleteModal');

        // modalDiv
        //     .querySelector('.modalBackdrop')
        //     .addEventListener('click', function () {
        //         document.body.removeChild(modal);
        //     });
    };

    const cancelAndClose = function () {
        close();
        cancelCallback();
    };

    const appendContentHandler = () => {
        externalLibrary = document.getElementById('externalImagesLibrary');
        externalLibrary
            .querySelector('.close')
            .addEventListener('click', cancelAndClose);

        externalLibrary
            .querySelector('.backdrop')
            .addEventListener('click', cancelAndClose);

        externalLibrary.addEventListener('click', function (e) {
            if (!e.target.matches('img')) {
                return;
            }
            var exampleOfCallbackImageObject = {
                originalName: '9091542014595406.png',
                resolution: '600 x 410 px',
                size: '169.20 kb',
                url: e.target.getAttribute('src'),
            };
            imageSelectCallback(exampleOfCallbackImageObject);
            close();
        });

        const deleteButtons = document.querySelectorAll('#deleteImage');
        deleteButtons.forEach((button) => {
            button.addEventListener('click', function () {
                deleteImageClicked(button.getAttribute('data-image-src') || '');
            });
        });
    };

    const initLibrary = async function () {
        const div = document.createElement('div');
        const getImages = async () => {
            try {
                const getEditorImagesResponse = await getEditorImages();
                if (getEditorImagesResponse.status === 200) {
                    images = getEditorImagesResponse.data;

                    div.innerHTML = libraryHtmlHandler(`    
                              ${images?.map((image) => {
                                  const imageSrc = URL + image[0];
                                  return `<div class="thumbnail" style="display: inline-block; width: 154px; cursor: pointer; padding: 4px; background-color: #ffffff; border: 1px solid #b80000; border-radius: 10px; margin-right: 10px">
                                                       <button data-image-src="${imageSrc}"  id="deleteImage" type="button" style="cursor: pointer; background: transparent; border: 0; float: right; font-size: 21px; font-weight: bold; opacity: 0.2;">
                                                         <span>&times</span>
                                                       </button>
                                                       <img style="height: 100px; margin-left: auto; margin-right: auto; max-width: 100%; display: block; vertical-align: middle;"
                                                         src="${imageSrc}">
                                                      </div>`;
                              })}
                             `);

                    appendContentHandler();
                }
            } catch (error) {
                images = [];
                console.log('error is : ', error);
            }
        };

        getImages();

        div.innerHTML = libraryHtmlHandler('');

        document.body.appendChild(div);

        appendContentHandler();
    };

    const renderLibrary = function () {
        if (!externalLibrary) {
            initLibrary();
        }
        externalLibrary.style.visibility = 'visible';
    };

    return {
        openLibrary: function (onImageSelectCallback, onCancelCallback) {
            imageSelectCallback = onImageSelectCallback;
            cancelCallback = onCancelCallback;
            renderLibrary();
        },
    };
})();

window.ExternalPreviewPopup = (() => {
    try {
        let externalPreviewPopup;

        const close = () => {
            externalPreviewPopup.parentNode.remove();
            externalPreviewPopup = null;
        };

        const onMobileIframeLoaded = () => {
            const frameDocument =
                document.getElementById('iframeMobile').contentDocument;
            frameDocument.documentElement.style.setProperty(
                'overflow-x',
                'visible',
                'important'
            );

            frameDocument.body.style.setProperty(
                'overflow-y',
                'scroll',
                'important'
            );
            frameDocument.body.style.setProperty(
                'visibility',
                'visible',
                'important'
            );

            const isAdaptive =
                !frameDocument.documentElement.querySelector('.gmail-fix');

            if (!isAdaptive) {
                const clientWidth = frameDocument.documentElement.clientWidth;
                const scrollWidth = frameDocument.documentElement.scrollWidth;
                const scale = (clientWidth / scrollWidth).toFixed(2);

                frameDocument.documentElement.style.setProperty(
                    'height',
                    '100%',
                    'important'
                );
                frameDocument.documentElement.style.setProperty(
                    'transform',
                    `scale(${scale})`,
                    'important'
                );
                frameDocument.documentElement.style.setProperty(
                    'transform-origin',
                    'top left',
                    'important'
                );
                frameDocument.body.style.setProperty(
                    'overflow-x',
                    'hidden',
                    'important'
                );
            }
        };

        const initPreviewPopup = () => {
            const div = document.createElement('div');

            div.innerHTML = `
            <div id="externalPreviewPopup">\
               <div class="mobile-backdrop" style="position: absolute; height: 100% ; width: 100%"></div>\
                <div class="modal-container" style="position: relative;">\
                    <div class="modal-header-container">\
                        <div>\
                           <button type="button" class="close modal-close-button">\
                                <span>×</span>\
                            </button>\
                            <div class="modal-header-title-container">\
                            <h4 class="modal-title">Desktop View</h4>\
                            <h4 class="modal-title">Mobile View</h4>\
                            </div>\
                        </div>\
                    </div>\
                    <div id="content" style="padding: 15px;" class="preview-container-fluid  dark:bg-[#41464E] ">\
                       <div class="preview-row">\
                            <div class="preview-col-sm-8">\
                                <div class="esdev-desktop-device">\
                                    <div class="esdev-email-window-panel">\
                                        <div class="esdev-email-subject" style="min-height: 20px"></div>\
                                    </div>\
                                    <div class="esdev-desktop-device-screen">\
                                        <iframe id="iframeDesktop" frameborder="0" width="100%" height="642"></iframe>\
                                    </div>\
                                </div>\
                            </div>\
                            <div class="preview-col-sm-4 esdev-no-padding-left">\
                                <div class="esdev-mobile-device center-block">\
                                    <div class="esdev-mobile-device-screen">\
                                        <img src="mobile-view-top-bar.png" alt="">\
                                        <iframe id="iframeMobile" frameborder="0" width="100%" height="459"></iframe>\
                                        <img class="esdev-mail-bottom-bar" src="mobile-view-bottom-bar.png" alt="">\
                                    </div>\
                                </div>\
                            </div>\
                       </div>\
                    </div>\
                </div>\
                
            </div>`;
            document.body.appendChild(div);

            externalPreviewPopup = document.getElementById(
                'externalPreviewPopup'
            );

            externalPreviewPopup
                .querySelector('.mobile-backdrop')
                .addEventListener('click', close);

            externalPreviewPopup
                .querySelector('.close')
                .addEventListener('click', close);

            document
                .getElementById('iframeMobile')
                .addEventListener('load', onMobileIframeLoaded);
        };

        const openPreviewPopup = (html, ampHtml) => {
            initPreviewPopup();
            updateContent(html, ampHtml);
            externalPreviewPopup.style.visibility = 'visible';
        };

        const updateContent = function (html, ampHtml) {
            const htmlToSet = ampHtml || html;
            const iframeDesktop = document.querySelector('#iframeDesktop');

            iframeDesktop.contentWindow.document.open('text/html', 'replace');

            iframeDesktop.contentWindow.document.write(htmlToSet);

            iframeDesktop.contentWindow.document.close();

            const iframeMobile = document.querySelector('#iframeMobile');

            iframeMobile.contentWindow.document.open('text/html', 'replace');

            iframeMobile.contentWindow.document.write(htmlToSet);

            iframeMobile.contentWindow.document.close();
        };

        return {
            openPreviewPopup: openPreviewPopup,
        };
    } catch (error) {
        console.log('mobile error is : ', error);
    }
})();

const request = (method, url, data, callback) => {
    const req = new XMLHttpRequest();
    req.onreadystatechange = () => {
        if (req.readyState === 4 && req.status === 200) {
            callback(req.responseText);
        } else if (req.readyState === 4 && req.status !== 200) {
            console.error(
                'Can not complete request. Please check you entered a valid PLUGIN_ID and SECRET_KEY values'
            );
        }
    };
    req.open(method, url, true);
    if (method !== 'GET') {
        req.setRequestHeader('content-type', 'application/json');
    }
    req.send(data);
};

export const initPlugin = (loadedHtml, codeChanged, edit, onLoaded) => {
    const apiRequestData = {
        emailId: REACT_APP_USEREMAIL,
    };

    const script = document.createElement('script');
    script.id = 'stripoScript';
    script.type = 'text/javascript';
    script.class = 'stripo';
    script.src = 'https://plugins.stripo.email/static/latest/stripo.js';

    try {
        script.onload = () => {
            window.Stripo.init({
                settingsId: 'stripoSettingsContainer',
                previewId: 'stripoPreviewContainer',
                codeEditorButtonId: 'codeEditor',
                // undoButtonId: 'undoButton',
                // redoButtonId: 'redoButton',
                locale: 'en',
                html: loadedHtml || html,

                customBlocks: { enabled: true },
                generatedBlock: {
                    moduleSectionEnable: true,
                    enabled: true,
                    dataSources: Array(0),
                },
                blocks: { moveBlockAvailability: true },
                draft: {
                    showAutoSaveLoader: () => {
                        console.log('Auto save in process');
                    },
                    hideAutoSaveLoader: function (error) {
                        console.log('Auto save completed', error);
                    },
                },
                onChangeFromCodeEditor: () => {
                    codeChanged();
                },
                onCodeEditorVisibilityChanged: (editor) => {
                    console.log('visibility changed', editor);
                },
                onSaveEmail: () => {
                    console.log('email saved');
                },
                onSaveTemplate: () => {
                    console.log('template saved');
                },
                onDataChanged: () => {
                    codeChanged();
                },
                onDragOver: () => {
                    console.log('code changed draggged');
                },
                onDragStart: () => {
                    console.log('code changed started');
                },
                saveEmailButtonId: 'email',
                saveTemplateButtonId: 'template',
                css: css,
                notifications: {
                    info: (message) =>
                        console.log('info notification message : ', message),
                    error: (message) =>
                        console.error('error notification message : ', message),
                    success: (message) =>
                        console.log('success notification message : ', message),
                    warn: (message) =>
                        console.log('warn notification message : ', message),
                    loader: (message) =>
                        console.log('loader notification message : ', message),
                    hide: (message) =>
                        console.log('hide notification message : ', message),
                },
                onToggleCodeEditor: (editor) => {
                    console.log('editor loaded: ', editor);
                },
                onTemplateLoaded: () => {
                    onLoaded();
                },
                externalImagesLibrary: window.ExternalImagesLibrary,
                viewOnly: edit,
                apiRequestData: apiRequestData,
                userFullName: 'Ryzeo User',

                getAuthToken: (callback) => {
                    request(
                        'POST',
                        'https://plugins.stripo.email/api/v1/auth',
                        JSON.stringify({
                            pluginId: REACT_APP_PLUGIN_ID,
                            secretKey: REACT_APP_SECRET_KEY,
                        }),
                        (data) => {
                            callback(JSON.parse(data).token);
                        }
                    );
                },
            });
        };
        document.body.appendChild(script);
    } catch (error) {
        console.log('stripo error caught : ', error);
    }
};

// const data = (
//     <div id="externalPreviewPopup">
//         <div class="modal-container">
//             <div class="modal-header-container">
//                 <div>
//                     <button type="button" class="close modal-close-button">
//                         <span>×</span>
//                     </button>
//                     <h4 class="modal-title">Mobile View</h4>
//                 </div>
//             </div>
//             <div
//                 id="content"
//                 style="padding: 15px;"
//                 class="preview-container-fluid  dark:bg-[#41464E] "
//             >
//                 <div class="preview-row">
//                     <div class="preview-col-sm-8">
//                         <div class="esdev-desktop-device">
//                             <div class="esdev-email-window-panel">
//                                 <div
//                                     class="esdev-email-subject"
//                                     style="min-height: 20px"
//                                 ></div>
//                             </div>
//                             <div class="esdev-desktop-device-screen">
//                                 \
//                                 <iframe
//                                     id="iframeDesktop"
//                                     frameborder="0"
//                                     width="100%"
//                                     height="642"
//                                 ></iframe>
//                             </div>
//                         </div>
//                     </div>
//                     <div class="preview-col-sm-4 esdev-no-padding-left">
//                         <div class="esdev-mobile-device center-block">
//                             <div class="esdev-mobile-device-screen">
//                                 <img src="mobile-view-top-bar.png" alt="" />
//                                 <iframe
//                                     id="iframeMobile"
//                                     frameborder="0"
//                                     width="100%"
//                                     height="459"
//                                 ></iframe>
//                                 <img
//                                     class="esdev-mail-bottom-bar"
//                                     src="mobile-view-bottom-bar.png"
//                                     alt=""
//                                 />
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         </div>
//     </div>
// );
