import Orders from './Orders';
import { useEffect, useState } from 'react';
import { WorkflowResponse } from 'components/DashboardReports/Types';
import { getWorkflowReports } from 'services/apiHandlers/Dashboard/Analytics';
import { Workflows } from './Types';
import moment from 'moment';
import AllWorkflows from './AllWorkflows';
import ErrorBoundary from 'components/ErrorBoundary';
import React from 'react';
import BreadcrumbsWrapper from 'components/Wrapper/BreadcrumbsWrapper';
const StoreReport = () => {
  const [workflows, setWorkflows] = useState<Workflows>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const getData = async () => {
    setLoading(true);
    try {
      const workflowReportResponse = (await getWorkflowReports(
        moment().subtract(7, 'days').format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD')
      )) as WorkflowResponse;
      if (workflowReportResponse.status === 200) {
        setWorkflows(workflowReportResponse.data.data);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <ErrorBoundary>
      <BreadcrumbsWrapper />
        <div>
          <div className="md:px-6 px-3 py-5 font-inter  dark:bg-[#41464E]  h-full">
            <div className="md:px-1.5  mb-16">
              <Orders loading={loading} />
              <AllWorkflows workflows={workflows} loading={loading} />
            </div>
          </div>
        </div>
    </ErrorBoundary>
  );
};
export default StoreReport;












