import { useOutsideClick } from 'app/hooks/useOutsideClick';
import Close from 'assets/Images/Workflow/close-Icon.png';
import { FC, useRef } from 'react';
import React from 'react';
interface Props {
  onClose: () => void;
  children: JSX.Element;
  disabled?: boolean;
  buttonTxt?: string;
  cancelButtonTxt?: string;
}
const AttributesWrapper: FC<Props> = ({ onClose, children }) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useOutsideClick(modalRef, onClose);

  return (
    <div
      style={{
       
        margin : '0  auto',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width : '50%',
      }}
    >
      <div
        ref={modalRef}
        className="w-full  mx-auto my-24  rounded-lg flex items-center justify-center"
      >
        <div className="relative">
          <img
            src={Close}
            onClick={() => onClose()}
            className="absolute top-0 right-0 mt-2 mr-2 border-none cursor-pointer dark:invert dark:brightness-0"
          />
        </div>
        <div className="bg-white rounded-lg w-full max-h-[700px] overflow-auto scrollbar-hide">
          <div className=" dark:bg-[#41464E]">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
export default AttributesWrapper;
