import { FC, useContext, useEffect, useState } from 'react';
import { InitialEventConditionData } from './index.type';
import SearchDropdown from 'components/Dropdown/SearchDropdown';
import Dropdown from 'components/Dropdown/Dropdown';
import Delete from 'assets/Images/ListsegmentImages/segment-delete.png';
import TagInput from 'components/InputTag';
import MultiValueDropdown from 'components/Dropdown/MultiValueDropdown';
import React from 'react';
import { UserContext } from 'store/UserContext';
import MultiValueOptionDropdown from 'components/Dropdown/MultiValueOptionDropdwon';
import { option } from 'components/Dropdown/MultiValueOptionDropdwon';

interface Props {
  conditions: InitialEventConditionData[];
  isSubscriber?: boolean;
  fieldOptions?: string[];
  attributes?: string[] | undefined;
  onChange: (data: InitialEventConditionData[]) => void;
  isValid: boolean;
  onValidForm: (valid: boolean) => void;
  hideButton?: boolean;
  onSetTimeUnit?: (units: string[]) => void;
  onSetTimeValue?: (values: number[]) => void;
  timeUnit?: string[];
  timeValue?: number[];
}

interface EventType {
  [key: string]: string;
  EqualsCondition: string;
  IsNotEqualCondition: string;
  IsGreaterThanCondition: string;
  IsLessThanCondition: string;
  IsBlankCondition: string;
  IsNotBlankCondition: string;
  AnyOfCondition: string;
  NotAnyOfCondition: string;
  ContainsAllOfCondition: string;
  ContainsAnyOfCondition: string;
  DoesNotContainAnyOfCondition: string;
  TimestampWithinTheLastCondition: string;
  Anytime: string;
}

const eventType: EventType = {
  EqualsCondition: 'Is Equal To',
  IsNotEqualCondition: 'Is Not Equal To',
  IsGreaterThanCondition: 'Is Greater Than',
  IsLessThanCondition: 'Is Less Than',
  IsBlankCondition: 'Is Blank',
  IsNotBlankCondition: 'Is Not Blank',
  AnyOfCondition: 'Is Any Of',
  NotAnyOfCondition: 'Is Not Any Of',
  ContainsAllOfCondition: 'Contains All Of',
  ContainsAnyOfCondition: 'Contains Any Of',
  DoesNotContainAnyOfCondition: 'Does Not Contain Any Of',
  TimestampWithinTheLastCondition: 'Within the last...',
  Anytime: 'Anytime',
};

const attributeTypeOptions = [
  'Is Equal To',
  'Is Not Equal To',
  'Is Greater Than',
  'Is Less Than',
  'Is Blank',
  'Is Not Blank',
  'Is Any Of',
  'Is Not Any Of',
  'Contains All Of',
  'Contains Any Of',
  'Does Not Contain Any Of',
];

const subscriberAttributeOptions = ['Is Equal To', 'Is Blank', 'Is Not Blank'];
const subscriberStatusOptions = [
  'Active',
  'Inactive',
  'Pending',
  'Unsubscribed',
];

const timeOptions = ['minute(s)', 'hour(s)', 'day(s)'];

const checkEventType = (type: string) => {
  if (attributeTypeOptions.slice(0, 4).includes(type)) {
    return true;
  } else if (
    attributeTypeOptions.slice(6, attributeTypeOptions.length).includes(type)
  ) {
    return false;
  }
};

const hideFields = (type: string) => {
  if (attributeTypeOptions.slice(4, 6).includes(type) || type === 'Anytime') {
    return true;
  }
};

const WorkflowConditions: FC<Props> = ({
  conditions,
  isSubscriber,
  fieldOptions,
  attributes,
  onChange,
  isValid,
  onValidForm,
  hideButton,
  onSetTimeUnit,
  onSetTimeValue,
  timeUnit,
  timeValue,
}) => {
  const userCtx = useContext(UserContext);
  const [workflowOptions, setWorkflowOptions] = useState<Array<option>>([]);

  const onInputChange = (
    value: string | string[],
    index: number,
    field: string
  ) => {
    let finalValue: string | string[] = '';
    const tempEventConditions = [...conditions];
    if (field === '_type') {
      if (value === 'Anytime') {
        finalValue = 'Anytime';
      }
      finalValue = Object.keys(eventType).find(
        (key) => eventType[key] === value
      ) as string;
    } else {
      finalValue = value;
    }
    tempEventConditions.map((condition, i) => {
      if (i === index) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        condition[field as keyof InitialEventConditionData] = finalValue;
      }
    });
    onChange(tempEventConditions);
  };

  const deleteEventHandler = (index: number) => {
    const tempEventConditions = [...conditions];
    tempEventConditions.splice(index, 1);
    onChange(tempEventConditions);
  };

  const onEventAdd = () => {
    const tempEvents = [...conditions];
    tempEvents.push({
      attributeName: 'Select an item',
      value: '',
      _type: 'EqualsCondition',
    });
    onChange(tempEvents);
  };

  const checkSubscriberAttribute = (attribute: string) => {
    if (attribute === 'opt_status') {
      return [...subscriberAttributeOptions, 'Is Any Of', 'Is Not Any Of'];
    } else if (attribute === 'subscribed_at' || attribute === 'created_at') {
      return ['Anytime', 'Within the last...'];
    } else {
      return subscriberAttributeOptions;
    }
  };

  const checkTime = (value: string | undefined) => {
    if (value) {
      const minutes = parseInt(value) / 60;
      if (minutes % 60 === 0) {
        const hours = minutes / 60;
        if (hours % 24 === 0) {
          return { value: hours / 24, unit: 'day(s)' };
        } else {
          return { value: hours, unit: 'hour(s)' };
        }
      } else {
        return { value: minutes, unit: 'minute(s)' };
      }
    }
    return { value: 0, unit: '' };
  };

  const setUnitHandler = (value: string, index: number) => {
    if (timeUnit && onSetTimeUnit) {
      const tempTimeUnit = [...timeUnit];
      tempTimeUnit[index] = value;
      onSetTimeUnit(tempTimeUnit);
    }
  };

  const setValueHandler = (value: number, index: number) => {
    if (timeValue && onSetTimeValue) {
      const tempTimeValue = [...timeValue];
      tempTimeValue[index] = value;
      onSetTimeValue(tempTimeValue);
    }
  };

  const onTypeValueChange = (value: string, index: number) => {
    if (
      value === 'Within the last...' &&
      timeUnit &&
      onSetTimeUnit &&
      timeValue &&
      onSetTimeValue
    ) {
      const tempTimeUnit = [...timeUnit];
      const tempTimeValue = [...timeValue];
      tempTimeUnit[index] = 'day(s)';
      tempTimeValue[index] = 1;
      onSetTimeUnit(tempTimeUnit);
      onSetTimeValue(tempTimeValue);
    }
    onInputChange(value, index, '_type');
  };

  useEffect(() => {
    if (userCtx?.workflows) {
      const tempWrokflowOptions: option[] = userCtx?.workflows?.map(
        (workflow) => {
          return { name: workflow.name || '', value: workflow.id || '' };
        }
      );
      setWorkflowOptions(tempWrokflowOptions);
    }
  }, [userCtx?.workflows]);

  useEffect(() => {
    let valid = true;
    const timeUnits: string[] = [],
      timeValues: number[] = [];

    conditions.map((condition) => {
      if (condition.attributeName === 'Select an item') {
        valid = false;
      }

      timeValues.push(checkTime(condition.value)?.value);

      timeUnits.push(checkTime(condition.value)?.unit);
    });
    onSetTimeUnit && onSetTimeUnit(timeUnits);
    onSetTimeValue && onSetTimeValue(timeValues);
    valid && onValidForm(valid);
  }, [conditions]);

  return (
    <>
      <div>
        {conditions.map((condition, index) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div key={index}>
              {index !== 0 && (
                <div className="flex align-baseline my-4 relative top-2 ">
                  <span className="w-1/2 border-t  border-dashed "></span>

                  <p className="text-sm font-medium leading-4 text-[#495057] dark:text-[#CED4DA] text-center bg-white dark:bg-transparent px-2 relative -top-2.5 ">
                    And
                  </p>
                  <span className="w-1/2 border-t border-dashed"></span>
                </div>
              )}
              <div className="flex items-center flex-wrap justify-between mt-2">
                <div className="bg-white  dark:bg-[#41464E]  rounded-lg dropdown-show-hide relative sm:[38px] h-9 sm:w-[48%] w-full z-9 cursor-pointer">
                  <SearchDropdown
                    options={
                      fieldOptions ? fieldOptions : attributes ? attributes : []
                    }
                    value={condition.attributeName}
                    onSelect={(value) => {
                      if (value === 'created_at' || value === 'subscribed_at') {
                        onInputChange('Anytime', index, '_type');
                      }
                      onInputChange(value, index, 'attributeName');
                    }}
                  />
                </div>
                <div className="bg-white  dark:bg-[#41464E] sm:mt-0 mt-2  rounded-lg dropdown-show-hide relative sm:[38px] h-9 sm:w-[48%] w-full z-9">
                  <Dropdown
                    options={
                      isSubscriber
                        ? checkSubscriberAttribute(condition.attributeName)
                        : attributeTypeOptions
                    }
                    value={
                      condition._type === 'IsNotBlankCondition' &&
                      condition.attributeName === 'created_at'
                        ? 'Anytime'
                        : eventType[condition._type]
                    }
                    onSelect={(value) => onTypeValueChange(value, index)}
                  />
                </div>
                {!hideFields(eventType[condition._type]) && (
                  <div className="relative w-11/12 sm:[38px] h-9 mt-2">
                    <>
                      {condition._type === 'TimestampWithinTheLastCondition' ? (
                        <div className="flex gap-2">
                          <div className=" w-1/2">
                            <input
                              className="text-13 font-medium leading-3 placeholder:text-gray-500 text-gray-700 px-2 sm:[38px] h-9 focus:outline-none focus:border-primary shadow-sm shadow-gray-200 border rounded-md border-[#ced4da] bg-white  dark:bg-[#41464E]  dark:text-white dark:hover:text-white dark:border-[#fff] py-2 w-full"
                              type="number"
                              value={
                                timeValue && timeValue.length !== 0
                                  ? timeValue[index]
                                  : ''
                              }
                              onChange={(e) => {
                                setValueHandler(
                                  parseInt(e.target.value),
                                  index
                                );
                              }}
                              min={0}
                            />
                          </div>
                          <div className="w-1/2">
                            <Dropdown
                              options={timeOptions}
                              value={
                                timeUnit && timeUnit[index] !== ''
                                  ? timeUnit[index]
                                  : timeUnit && timeUnit[index] === ''
                                  ? 'days(s)'
                                  : ''
                              }
                              onSelect={(value) => setUnitHandler(value, index)}
                            />
                          </div>
                        </div>
                      ) : condition.attributeName === 'opt_status' &&
                        condition._type === 'EqualsCondition' ? (
                        <div className="bg-white  dark:bg-[#41464E]  rounded dropdown-show-hide relative sm:[38px] h-9 w-full">
                          <Dropdown
                            options={subscriberStatusOptions}
                            value={
                              condition.value
                                ? condition.value
                                : 'Select an item'
                            }
                            onSelect={(value) =>
                              onInputChange(value, index, 'value')
                            }
                          />
                        </div>
                      ) : condition.attributeName === 'opt_status' &&
                        (condition._type === 'AnyOfCondition' ||
                          condition._type === 'NotAnyOfCondition') ? (
                        <MultiValueDropdown
                          options={subscriberStatusOptions}
                          selectedList={condition.values}
                          onOptionChange={(updatedList) =>
                            onInputChange(updatedList, index, 'values')
                          }
                        />
                      ) : condition.attributeName === 'triggerId' &&
                        (condition._type === 'AnyOfCondition' ||
                          condition._type === 'NotAnyOfCondition') ? (
                        <MultiValueOptionDropdown
                          options={workflowOptions}
                          selectedList={condition.values}
                          onOptionChange={(updatedList) => {
                            onInputChange(updatedList, index, 'values');
                          }}
                        />
                      ) : checkEventType(eventType[condition._type]) ? (
                        <input
                          className="text-13 focus:border-primary dark:focus:border-white dark:border-[#fff] focus:outline-none leading-3 text-[#212529] dark:text-white py-2 rounded-md px-4  bg-white border border-[#ced4da] md:w-8/12 sm:h-[38px] h-9  dark:bg-[#41464E] "
                          type="text"
                          value={condition.value}
                          onChange={(e) =>
                            onInputChange(e.target.value, index, 'value')
                          }
                        />
                      ) : (
                        <TagInput
                          tagInputs={condition.values}
                          onChange={(values) => {
                            if (
                              condition.values?.includes(
                                values[values.length - 1]
                              )
                            ) {
                              return;
                            }
                            onInputChange(values, index, 'values');
                          }}
                        />
                      )}
                    </>
                  </div>
                )}
                <div className="sm:mt-2 mt-4 sm:w-[4.333%] w-[24px] bg-[#ff000029] p-[5px] rounded-full">
                  <img
                    className="ml-auto dark:invert dark:brightness-0 cursor-pointer"
                    onClick={() => deleteEventHandler(index)}
                    src={Delete}
                    alt="Delete"
                  />
                </div>
              </div>
            </div>
          );
        })}
        {!isValid && (
          <div className="px-6 py-3 bg-[#f7e8e7] rounded mt-4">
            <p className="text-sm font-normal leading-4 text-[#CB5A5A]">
              You must select attribute to compare
            </p>
          </div>
        )}
      </div>
      {!hideButton && (
        <div className="mt-4">
          <button
            type="button"
            className="text-sm font-medium leading-5 rounded-md py-2.5 px-4 h-11 text-white hover:bg-primary bg-primary hover:scale-105 ease-in duration-300"
            onClick={() => onEventAdd()}
          >
            Add a Condition
          </button>
        </div>
      )}
    </>
  );
};
export default WorkflowConditions;
