import React, { FC } from 'react';
import NextArrow from 'assets/Images/Dashboard/next-Arrow.svg';

interface Props {
  setCurrentStep: (step: number) => void;
  currentStep: number;
}
const BackButton: FC<Props> = ({ currentStep, setCurrentStep }) => {
  const BackButtonText: { [key: number]: string } = {
    1: '',
    2: 'Subscribers',
    3: 'Sender',
    4: 'Subject',
    5: 'Delivery Options',
    6: 'Schedule Blast',
  };

  return (
    <button
      onClick={() => setCurrentStep(currentStep - 1)}
      className="bg-[#F3F6F9] flex items-center xl:w-auto w-full  rounded-md text-[#212529]"
    >
      <div className="bg-[rgba(33,37,41,.05)] h-[38px] w-8 flex justify-center items-center">
        <img
          className="w-4 h-4 rotate-180 invert-0 brightness-0"
          src={NextArrow}
          alt="NextArrow"
        />
      </div>
      <p className=" px-3 py-2 text-13">
        Back to {BackButtonText[currentStep]}
      </p>
    </button>
  );
};
export default BackButton;
