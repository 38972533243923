import { TableColumn } from 'react-data-table-component';
import Table from 'components/Table';
import { FC, useContext, useMemo, useState } from 'react';
import { z } from 'zod';
import { WorkflowType, InitialConditionGroup } from './index.type';
import { tableSort } from 'utils/common';
import WorkflowActions from './WorkflowActions';
import { updateWorkflow } from 'services/apiHandlers/Workflows/Workflow';
// import Skeleton from 'components/Skeleton/Skeleton';
import { workflow } from 'services/constant/routes';
import { Link } from 'react-router-dom';
import { UserContext } from 'store/UserContext';
import moment from 'moment-timezone';
import { ModifiedEvents } from 'staticdata/EventLogs/Data';
import React from 'react';

interface Props {
  workflows: WorkflowType[];
  setWorkflows: (workflows: WorkflowType[]) => void;
  getWorkflows: (notLoad: boolean) => void;
  loading: boolean;
}

const responseSchema = z.object({ status: z.number() });
type Response = z.infer<typeof responseSchema>;

const logsSchema = z.object({
  accountId: z.number(),
  executionId: z.string(),
  id: z.string(),
  message: z.string(),
  personIdentifier: z.string(),
  triggerId: z.string(),
  workflowNodeId: z.string(),
});

const executionDataSchema = z.object({
  logs: z.array(logsSchema),
  workflow: z.string(),
});

export type ExecutionData = z.infer<typeof executionDataSchema>;
export type ExecutionLog = z.infer<typeof logsSchema>;

const workflowLogSchema = z.object({
  accountId: z.number(),
  executionId: z.string(),
  id: z.string(),
  message: z.string(),
  personIdentifier: z.string(),
  triggerId: z.string(),
  workflowNodeId: z.string(),
});
export type WorkflowLogs = z.infer<typeof workflowLogSchema>;

export const checkTrigger = (conditionGroup: InitialConditionGroup) => {
  if ('tags' in conditionGroup) {
    return 'Subscriber Tag';
  }
  if ('eventName' in conditionGroup) {
    return conditionGroup.eventName;
  }
};

const Workflows: FC<Props> = ({
  workflows,
  setWorkflows,
  getWorkflows,
  loading,
}) => {
  const [loadingIndex, setLoadingIndex] = useState<number>();
  const [actionId, setActionId] = useState<string | null>(null);
  // const [changingStatus, setStatusChanging] = useState<boolean>(false);
  const userCtx = useContext(UserContext);

  const statusChangeHandler = async (
    id: string,
    status: boolean,
    index: number
  ) => {
    setLoadingIndex(index);
    const filteredWorkflow = workflows.filter((workflow) => workflow.id === id);
    const updatedWorkflow = { ...filteredWorkflow[0], isEnabled: !status };

    const tempWorkflows = [...workflows];

    workflows.map((workflow, index) => {
      if (workflow.id === id) {
        tempWorkflows[index].isEnabled = !status;
      }
    });
    setWorkflows(tempWorkflows);
    try {
      const updateWorkflowResponse = (await updateWorkflow(
        updatedWorkflow,
        id
      )) as Response;
      if (updateWorkflowResponse.status === 200) {
        // setStatusLoading(false);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
  };

  const workflowSort = (rowA: WorkflowType, rowB: WorkflowType) => {
    return tableSort(rowA.name || '', rowB.name || '');
  };

  const checkStatus = (workflow: WorkflowType) => {

    if (workflow.isEnabled && !workflow.isTestMode && !workflow.expiresAt) {
      return 'Enabled';
    }

    if (workflow.isEnabled && !workflow.isTestMode) {
      return 'Expired';
    }

    if (!workflow.isEnabled && (!workflow.isTestMode || workflow.isTestMode)) {
      return 'Disabled';
    }

    if (workflow.isEnabled && workflow.isTestMode && (workflow.expiresAt || !workflow.expiresAt)) {
      return 'Enabled(Test Mode)';
    }
  };

  const columns: TableColumn<WorkflowType>[] = useMemo(
    () => [
      {
        name: <div className="pl-5">Name</div>,
        cell: (row) => {
          return (
            <Link
              to={`${workflow}/${row?.id?.toString()}`}
              className="dark:text-white text-[#495057] cursor-pointer font-medium "
            >
              <div>{row.name}</div>
            </Link>
          );
        },
        sortable: true,
        sortFunction: workflowSort,
        style: { width: '200px', paddingLeft: '20px' },
      },
      {
        name: <div>Triggers on</div>,
        cell: (row) => {
          return (
            <div
              className={`${ModifiedEvents[checkTrigger(row.initialConditionGroup) || '']?.color || 'bg-primary'} dark:text-white text-white px-1 py-1 rounded font-medium text-xs`}
            >
              <div>
                {ModifiedEvents[checkTrigger(row.initialConditionGroup) || '']
                  ? ModifiedEvents[
                      checkTrigger(row.initialConditionGroup) || ''
                    ].name
                  : checkTrigger(row.initialConditionGroup)}
              </div>
            </div>
          );
        },
        width: '200px',
      },
      {
        name: <div>Converts On</div>,
        cell: (row) => {
          return (
            <div
              className={`${
                row.defaultConversionReportingEvent ? 'px-1 py-[2px]' : 'p-0'
              } dark:text-white text-[#212529] rounded `}
            >
              {row.defaultConversionReportingEvent}
            </div>
          );
        },
        width: '180px',
      },
      {
        name: <div>Status</div>,
        cell: (row) => {
   
          return (
            <div
              className={`${
                row.expiresAt && row.isEnabled
                  ? 'bg-gray-100 text-gray-400 dark:text-white dark:bg-[#121215] '
                  : ''
              } ${!row.isEnabled ? 'bg-[#EE63521A] text-[#ee6352]' : ''} ${
                row.isEnabled && !row.expiresAt
                  ? 'bg-green-100 text-green-400 dark:bg-green-300'
                  : ''
              }   px-1  rounded font-medium`}
            >
              {checkStatus(row)}
            </div>
          );
        },
        width: '200px',
        center: true,
      },
      {
        name: <div className="w-[400px]">Date Modified</div>,
        cell: (row) => {
          return row.lastModified ? (
            <div className="dark:text-white w-[400px]">
              {moment
                .tz(row.lastModified, userCtx?.usersData?.timeZone || '')
                .format('MMMM D, YYYY h:mm:ss A')}
            </div>
          ) : (
            <>-</>
          );
        },
        width: '220px',
      },
      {
        name: <div>Last Triggered</div>,
        cell: (row) => {
          return (
            <div className="dark:text-white">
              {row.lastTriggered
                ? moment
                    .tz(row.lastTriggered, userCtx?.usersData?.timeZone || '')
                    .format('MMMM D, YYYY h:mm:ss A')
                : '-'}
            </div>
          );
        },
        width: '220px',
      },
      {
        name: <div className="pr-5">Actions</div>,
        cell: (row, index) => {
          return (
            <WorkflowActions
              row={row}
              onStatusChange={(id: string, status: boolean) =>
                statusChangeHandler(id, status, index)
              }
              index={index}
              getData={getWorkflows}
              logStatus={row.isEnabled ? 'active' : 'inactive'}
              setActionId={setActionId}
              actionId={actionId}
            />
          );
        },
        width: '120px',
        style: { paddingRight: '20px' },
      },
    ],
    [loadingIndex, workflows, userCtx?.usersData?.timeZone, actionId]
  );

  return (
    <div>
      <Table
        className="scrollbar-hide"
        data={workflows}
        columns={columns}
        progressPending={loading}
      />
    </div>
  );
};
export default Workflows;
