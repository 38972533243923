import React, { FC, useState } from 'react';
import { calculatePercentage } from 'utils/common';
import Wrapper from 'components/Wrapper';
import { Blast, ClickResponseSchema } from './index';
import ExportModal from './ExportModal';
import AddListModal from './AddListModal';
import Modal from 'components/Alert/Modal';
import { z } from 'zod';
import Skeleton from 'components/Skeleton/Skeleton';
import moment from 'moment';
import Tippy from '@tippyjs/react'


const getListResponseDataSchema = z.object({
  id: z.number(),
  name: z.string(),
  audience_id: z.number(),
});
type Lists = z.infer<typeof getListResponseDataSchema>;

const fieldsSchema = z.object({
  ['allow-other']: z.boolean(),
  ['data-type']: z.string(),
  id: z.number(),
  ['include-blank']: z.boolean(),
  name: z.string(),
  slug: z.string(),
  'used-in-filter': z.string(),
});

export type Fields = z.infer<typeof fieldsSchema>;

interface Props {
  clicks: ClickResponseSchema | undefined;
  blast: Blast | undefined;
  fields: Fields[];
  lists: Lists[];
  loading: boolean;
}
const TotalClickers: FC<Props> = ({
  blast,
  fields,
  lists,
  loading,
  clicks,
}) => {
  const [showExportModal, setShowExportModal] = useState<boolean>(false);
  const [showListModal, setShowListModal] = useState<boolean>(false);
  // const [lastIndex, setLastIndex] = useState<number>(0);
  console.log(clicks);
  const colors: string[] = [
    '#8c68cd',
    '#40BB82',
    '#FFCA5B',
    '#EE6352',
    '#3FA7D6',
    '#12442d',
  ];

  clicks?.data?.links?.sort((a, b) => b.clicks_count - a.clicks_count);
  console.log(clicks);

  let remainingPercentage = 0;
  const percentagesArray: number[] = [];
  const bars: { color: string; width: number }[] = [];

  if (clicks && !('message' in clicks.data) && !loading) {
    clicks.data.links.map((link, index) => {
      if ( link.clicks_count !==0 && index < 5) {
        percentagesArray.push(
          calculatePercentage(link.clicks_count, clicks.data.clicks)
        );
      }
    });

    console.log([percentagesArray]);

    let sum = 0;

    for (let i = 0; i < percentagesArray.length; i++) {
      sum += percentagesArray[i];
    }
    remainingPercentage = 100 - sum;
    

    clicks.data.links.map((link, index) => {
      if (index < 5) {
        bars.push({ color: colors[index], width: percentagesArray[index] });
      }
    });

    console.log(bars);

    if (clicks.data.links.length > 5) {
      bars.push({ color: colors[5], width: remainingPercentage });
    }
  }

  const tempBars = []
  bars.forEach((element)=>{element.width>0&&tempBars.push(element)});
  console.log(tempBars.length)
  return (
    <>
      <Wrapper parentClass="2xl:w-4/12 shadow-lg hover:shadow-xl dark:shadow-lg dark:bg-[#41464E] dark:hover:shadow-xl" childClass="h-full">
        <>
          <div className="cardHeader dark:border-[#fff]">
            <div>
              <div className="flex items-center">
                <h4 className="text-base mr-2 text-darkText font-medium dark:text-[#878A99]">
                  Unique Clickers
                </h4>
                <div className="flex justify-center items-center  rounded-md bg-[#3FA7D6] px-1  ">
                  <p className=" text-white text-sm font-medium dark:text-[#CED4DA]">
                    {blast?.blast.unique_clicks_count
                      ? blast?.blast.unique_clicks_count.toLocaleString()
                      : '0'}
                  </p>
                </div>
              </div>
              <div className="flex items-center">
                <p className="text-[#40BB82] mr-2 bg-[#E2F5EC] text-[10px] px-1 rounded">
                  {calculatePercentage(
                    blast?.blast.unique_clicks_count || 0,
                    blast?.blast.number_sent || 0
                  )}
                  %
                </p>
                <p className="text-[#87AA99] text-sm">of delivered</p>
              </div>
            </div>

            {loading ? (
              <Skeleton columns={1} />
            ) : (
              blast?.blast &&
              (blast.blast?.unique_clicks_count > 0 ? (
                <div className="flex">
                  <div
                    onClick={() => setShowExportModal(true)}
                    className="group rounded py-1 px-2 mr-2 text-primary underline cursor-pointer ease-in-in duration-300"
                  >
                    <p className="flex items-center dark:text-white text-xxs">
                      Export
                    </p>
                  </div>
                  <div
                    onClick={() => setShowListModal(true)}
                    className="group rounded py-1 px-2 bg-[#3575d526] hover:bg-primary cursor-pointer ease-in-in duration-300"
                  >
                    <p className="flex items-center text-primary group-hover:text-white dark:text-white text-xxs">
                      Add To List
                    </p>
                  </div>
                </div>
              ) : null)
            )}
          </div>
          <Modal isOpen={showExportModal || showListModal}>
            {showExportModal ? (
              <ExportModal
                onClose={() => setShowExportModal(false)}
                fields={fields}
                click={true}
              />
            ) : showListModal ? (
              <AddListModal
                onClose={() => setShowListModal(false)}
                lists={lists}
                click={true}
              />
            ) : (
              <></>
            )}
          </Modal>
          <div className="px-4 py-2">
            <h3 className="text-[#878A99] text-sm font-semibold">
              Total Referrals Page
            </h3>
            <p className="text-[#495059] text-lg mt-1 font-medium dark:text-[#CED4DA]">
              {blast?.blast.clicks_count
                ? blast?.blast.clicks_count?.toLocaleString()
                : '0'}
            </p>

            {loading ? (
              <Skeleton columns={1} />
            ) : (
              <div className="mt-3">
                <div className="flex w-full">
                  {bars?.map((bar, index) => (
                    <div
                      key={index}
                      style={{
                        backgroundColor: `${bar.color}`,
                        width: `${bar.width}%`,
                        height: '1rem',
                        borderRadius: tempBars.length===1 ? '0.75rem 0.75rem 0.75rem 0.75rem' : index ===0 ? '0.75rem 0 0 0.75rem' : index === tempBars.length-1? '0 0.75rem 0.75rem 0' : 'none'
                      }}
                    ></div>
                  ))}
                </div>
                {clicks?.data && !('message' in clicks.data) && (
                  <div className=" w-full mt-4 flex justify-between items-center">
                    <div className=" w-3/5 flex justify-start">
                      <p className="ml-2 text-[#495077] text-sm break-all dark:text-white">URL </p>
                    </div>
                    <div className="flex justify-between w-2/5">
                      <p className="text-sm text-[#998787]">Last Clicked</p>
                      <p className="text-sm text-[#998787]">Percentage</p>
                    </div>
                  </div>
                )}

                {clicks?.data.links &&
                  clicks.data.links.map(
                    (click, index) =>
                    click.clicks_count !==0 && index < 5 && (
                        <div key={index} className=" w-full mt-4 flex justify-between items-center">
                          <div className=" w-3/5 flex justify-start items-center">
                            <span
                              style={{
                                display: 'block',
                                position: 'relative',
                                top: '0.5px',
                                backgroundColor: `${bars[index].color}`,
                                height: '12px',
                                width: '12px',
                                borderRadius: '50%',
                              }}
                            ></span>
                            
                            <div className='flex max-w-full'
                               style={{
                                width: "calc(100% - 40px)"
                              }}
                            >
                            <Tippy interactive = {true} content = {click.url} className='break-all'> 
                            <p className="ml-2 text-[#495077] break-all text-sm pr-2 cursor-pointer dark:text-white" onClick={()=>{window.open(click.url)}}>
                            {click.url.length>70? `${click.url.slice(0,70)}....`:click.url}                               
                            </p>
                            </Tippy>
                            </div>
                          </div>

                          <div className= "flex justify-between w-2/5 items-center h-full">
                            <p className= "text-sm text-[#998787]">
                              {click?.last_clicked !== ''
                                ? moment(click?.last_clicked).format(
                                    'MMMM DD, YY [at] hh:mm A'
                                  )
                                : '-'}
                            </p>
                            <p className="text-sm text-[#998787]">{`${percentagesArray[index]}%`}</p>
                          </div>
                        </div>
                      )
                  )}
                {percentagesArray.length === 5 && (
                  <div className="mt-4 flex justify-between items-center">
                    <div className="flex items-center">
                      <span className="block bg-[#12442d] h-3 w-3 rounded-full"></span>
                      <p className="ml-2 relative top-[0.5px] text-[#495077] text-sm dark:text-white">
                        Others{' '}
                      </p>
                    </div>
                    <p className="text-sm text-[#878A99]">{`${remainingPercentage}%`}</p>
                  </div>
                )}
              </div>
            )}
          </div>
          {clicks?.data && ('message' in clicks.data) && (

            <div className="flex pb-12 w-full justify-center align-middle">
              <h1>No Click Data to Show...</h1>
            </div>
          )}
        </>
      </Wrapper>
</>
  );
}
export default TotalClickers;
