/* eslint-disable no-mixed-spaces-and-tabs */
import UserIcon from 'assets/Images/Common/user.svg';
import Key from 'assets/Images/Common/key.gif';
import { getUsers } from 'services/apiHandlers/Campaigns/Emails';
import { UsersResponse, usersDataSchema } from 'components/Report/Campaigns/Email';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';

import z from 'zod';
import Table from 'components/Table'
import { TableColumn } from 'react-data-table-component';
import Wrapper from 'components/Wrapper';
type UserDataSchema = z.infer<typeof usersDataSchema>

const Users: FC = () => {
	const [users, setUsers] = useState<Array<UserDataSchema>>([]);
	const [loading, setLoading] = useState<boolean>(true);

	const getData = async () => {
		setLoading(true);
		try {
			const getUsersResponse = (await getUsers()) as UsersResponse;
			
			console.log(getUsersResponse);
			if (getUsersResponse.status === 200) {
				setUsers(getUsersResponse.data.users)
			}
		} catch (error) {
			console.log('error is : ', error);
			setLoading(false);
		}
		setLoading(false);

	};

	const columns: TableColumn<UserDataSchema>[] = useMemo(
        () => [
           {
			name: 'Name', 
			cell: (row) => {
				return (
					<td>
						<div className='flex items-center'>
							<img className='mr-1' src={UserIcon} alt='user' />
							<Link className='text-primary underline cursor-pointer mr-4 ml-2 dark:text-[#CED4DA]' to={`${row.id}`}>
							{row.first_name+" "+row.last_name}
							</Link>
							</div>
					</td>
				);
			}, 
			width: "20%"
			}, 
			{
				name: 'Title', 
				cell: (row) => {
					return (
						<td>
							<div><p className='dark:text-[#CED4DA]'>{row.title? row.title: "-"}</p></div>
						</td>
					);
				}, 
				width: '20%',
				
				}, 
			{
				name: 'Email', 
				cell: (row) => {
					return (
						<td>
							<div><p className='dark:text-[#CED4DA]'>{row.email_address}</p></div>
						</td>
					);
				},
				width:"30%" 
				}, 
				{
					name: 'Is Admin', 
					cell: (row) => {
						return (
							<td>
								<div>{row.administrator? <img src={Key} />:"-" }</div>
							</td>
						);
					}, 
					width:"10%"
					}, 
					{
						name: 'Actions',
						cell: (row)=>{
							return(
								<Link
								to={`${row.id}`}
								className="whitespace-break-spaces sm:align-top align-middle py-3 text-center text-primary font-medium dark:text-white table-cell w-full cursor-pointer"
							  >
								<div className="mr-4 flex justify-center items-center">
								  <svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 23 23"
									strokeWidth={1.5}
									stroke="currentColor"
									className="w-4 h-4 relative left-1.5"
								  >
									<path
									  strokeLinecap="round"
									  strokeLinejoin="round"
									  d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
									/>
									<path
									  strokeLinecap="round"
									  strokeLinejoin="round"
									  d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
									/>
								  </svg>
				  
								  <span className='ml-2 dark:text-[#CED4DA]'>View Details</span>
								</div>
							  </Link>
							)
						},
						center: true,
        				width: '20%',
					}
        ],
        []
    );







	useEffect(() => {
		getData()
	}, [])

	return (
		
		<div className=" pb-8 font-inter h-full min-h-[74vh] dark:bg-[#41464E]">
		<Wrapper>

		<div className="relative pb-3 ml-4">
                {/* <div className="after:left-[-3px] after:bottom-[-2px] after:border-b-[#e9ebec] after:border-l-[#e9ebec] after:border-t-transparent after:border-r-transparent after:content-[''] after:h-[13px] after:w-[13px] after:absolute after:border-[3px] before:right-[-2px] before:top-[-3px] before:border-b-transparent before:border-l-transparent before:border-t-[#e9ebec] before:border-r-[#e9ebec] before:content-[''] before:h-[13px] before:w-[13px] before:absolute before:border-[3px]  border border-[rgba(0, 0, 0, 0.125)] rounded dark:shadow-none dark:border-[#fff] mb-16"> */}
                    <div className="flex md:flex-row flex-col justify-between items-center px-5 border-b dark:border-white border-[#e9ebec] py-[18px]">
                        <p className="text-base text-[#495057] font-medium  dark:text-[#CED4DA]">
                            All Users
                        </p>
			</div>
			<Table 
			data={users} 
			columns={columns} 
			pagination={false}
			progressPending={loading}
			/>
			
			</div>			
		</Wrapper>
		</div>
		

	);
};
export default Users;
