export const home = '/';
export const login = '/login';
export const listSegments = '/list&segments';
export const createSegment = '/create-segment';
export const integration = '/integrations';
export const campaigns = '/campaigns';
export const emailTemplates = '/email-templates';
export const createCampaign = '/create-campaign';
export const emailEditor = '/email';
export const workflow = '/workflows';
export const previewEmail = '/preview-email';
export const workflowReport = '/workflows-report';
export const storeReports = 'store_report';
export const activity = 'activity';
export const calendar = 'calendar';
export const matches = 'matches';
export const add = 'add';
export const filter = 'filter';
export const campaignReport = '/campaigns-report';
export const create = 'create';
export const edit = 'edit';
export const id = ':id';
export const nestedId = ':idNested';
export const logs = 'logs';
export const filters = 'filters';
export const segmentDefinition = 'segment-definition';
export const view = 'view';
export const eventLogs = '/event-logs';
export const upload = 'upload';
export const fields = 'fields';
export const listId = 'list_id';
export const list = 'lists';
export const profile = 'profile';
export const report = 'report';
export const campaignId = 'campaignId';
export const reports = '/reports';
export const tab = 'tab';
export const analytics= 'analytics';
export const subscribersProfile = 'subscribers-profile';
export const cartIntegration = 'cart-integration';
export const scripts = 'scripts';
export const google = 'google';
export const miscellaneous = 'miscellaneous';
export const workflowReports = "workflow-reports"
export const campaignReports ="campaign-reports"
export const users = "users";
export const account = "account";