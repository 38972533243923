import { useContext, useEffect, useState } from 'react';
import Header from 'components/Header';
import SideBar from 'components/Sidebar';
import ErrorBoundary from 'components/ErrorBoundary';
import React from 'react';
import { useLocation } from 'react-router-dom';
import { UserContext } from 'store/UserContext';
import Footer from 'components/Footer';

type Props = {
  children: JSX.Element;
};
const MainLayout: React.FC<Props> = ({ children }: Props) => {
  const [showSidebar, setShowsidebar] = useState(false);
  const [showFullSidebar, setShowFullSidebar] = useState<boolean>(true);
  const toggleSidebar = () => {
    setShowsidebar((prev) => !prev);
  };
  const userCtx = useContext(UserContext);

  const location = useLocation();

  useEffect(() => {
    userCtx?.filterValueHandler('');
  }, [location]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1024) { // Adjust the breakpoint as needed
        setShowFullSidebar(false);
      } else {
        setShowFullSidebar(true);
      }
    };

    // Call the handleResize function on initial load
    handleResize();

    // Attach the event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <ErrorBoundary>
      <div className="">
        <SideBar
          toggleSidebar={toggleSidebar}
          showSidebar={showSidebar}
          showFullSidebar={showFullSidebar}
          setShowFullSidebar={setShowFullSidebar}
        />
        <div
          className={`${
            showFullSidebar
              ? ' lg:w-[calc(100%-256px)] sm:w-[calc(100%-4rem)] lg:ml-[256px] sm:ml-16'
              : 'lg:w-[calc(100%-80px)] sm:w-[calc(100%-80px)] lg:ml-[80px] ml-auto '
          } bg-[#FAFAFA] bg-[url('https://www.transparenttextures.com/patterns/little-pluses.png')] dark:bg-[url('assets/Images/Common/denimbg.webp')]
            bg-fixed w-full `}
        >
          {/* Ok lets try #FFFFFF.   #F9F9F9.     #FFFFEE */}
          <div className='relative'>         
          <Header toggleSidebar={toggleSidebar} />
          {children}
          <div className='Test'>
        </div>
        <Footer/>
        </div>
        </div>
      </div>
      

    </ErrorBoundary>
  );
};
export default MainLayout;
