import React, { FC, useMemo } from 'react';

import { calculatePercentage } from 'utils/common';
import { TableColumn } from 'react-data-table-component';
import Table from 'components/Table';
import { WorkflowReportsData } from '.';

interface Props {
  reportsLoading: boolean;
  workflowReports: WorkflowReportsData[];
  
}

const ReportsTable: FC<Props> = ({
  // startDate,
  // endDate,
  // setEndDate,
  // setStartDate,
  reportsLoading,
  workflowReports,
  // onApply,
}) => {
  const columns: TableColumn<WorkflowReportsData>[] = useMemo(
    () => [
      {
        name: <div className='px-4'>Names</div>,
        cell: (row) => {
          return (
            <td className="whitespace-nowrap align-top px-4 text-13 font-medium text-[#495057] dark:text-[#CED4DA]">
              {row.name}
            </td>
          );
        },
      
      },

      {
        name: <div className='px-4'>Recipients</div>,
        cell: (row) => {
          return (
            <td className="whitespace-nowrap px-4 text-13 text-[#495057] text-center dark:text-[#CED4DA] align-top table-cell w-full">
              {row.uniquePeople}
            </td>
          );
        },
        center: true,
        width : '180px',

      },
      {
        name: <div className='px-4'>Converted</div>,
        cell: (row) => {
          return (
            <td className="whitespace-nowrap sm:align-top align-middle px-4 text-13 text-center text-[#495057] dark:text-[#CED4DA] table-cell w-full">
              <div>{row.uniqueConversions}</div>
              <div className='text-11'>{calculatePercentage(row.uniqueConversions, row.delivered)}%</div>
              
            </td>
          );
        },
        center: true,
        width : '180px',

      },
      {
        name: <div className='px-4'>Delivered</div>,
        cell: (row) => {
          return (
            <td className="whitespace-nowrap px-4 text-13 text-[#495057] text-center dark:text-[#CED4DA] align-top table-cell w-full">
             <div>{row.totalSent}</div>
              <div className='text-11'>{calculatePercentage(row.delivered, row.totalSent)}%</div>
              
            </td>
          );
        },
        center: true,
        width : '180px',
      },
      {
        name: <div className='px-4'>Opened</div>,
        cell: (row) => {
          return (
            <td className="whitespace-nowrap sm:align-top align-middle px-4 text-13 text-center text-[#495057] dark:text-[#CED4DA] table-cell w-full">
                <div>{row.uniqueViews}</div>
              <div className='text-11'>
                {calculatePercentage(row.uniqueViews, row.uniquePeople)}%
              </div>
           
            </td>
          );
        },
        center: true,
        width : '180px',
      },
      {
        name: <div className='px-4'>Clicked</div>,
        cell: (row) => {
          return (
            <td className="whitespace-nowrap sm:align-top align-middle px-4 text-center text-13 text-[#495057] dark:text-[#CED4DA] table-cell w-full">
                 <div>{row.uniqueClicks}</div>
              <div  className='text-11'>
                {calculatePercentage(row.uniqueClicks, row.uniquePeople)}%
              </div>
           
            </td>
          );
        },
        center: true,
        width : '180px',

      },

      {
        name: <div className='px-4'>Unsubscribed</div>,
        cell: (row) => {
          return (
            <td className="whitespace-nowrap sm:align-top align-middle px-4 text-center text-13 text-[#495057] dark:text-[#CED4DA] table-cell w-full">
               <div >{row.unsubscribes}</div>
              <span className='text-11'>
                {calculatePercentage(row.unsubscribes, row.uniquePeople)}%
              </span>
             
            </td>
          );
        },
        center: true,
        width : '180px',

      },
    ],
    []
  );

  return (
    <div className="min-h-[280px] font-inter">
        <div id="allWorkflows" className="flex flex-col">
          <div className="-my-2 overflow-x-auto">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="grid grid-cols-1 gap-4 font-inter pt-3">
                <Table
                  className="scrollbar-hide"
                  data={workflowReports}
                  columns={columns}
                  progressPending={reportsLoading}
                  pagination={false}
                />
              </div>
            </div>
          </div>
        </div>
    
    </div>
  );
};
export default ReportsTable;
