import ErrorBoundary from 'components/ErrorBoundary';
import React, { FC } from 'react';
import Users from 'components/Users';
import BreadcrumbsWrapper from 'components/Wrapper/BreadcrumbsWrapper';


const UsersPage: FC = () => {
    return (
        <ErrorBoundary>
            <BreadcrumbsWrapper />
            <div className='flex flex-col w-full bg-white mt-8 border-b shadow-0 shadow ml-4 '>
            <Users />
            </div>
        </ErrorBoundary>
    );
};
export default UsersPage;