import { FC, useRef, useState } from 'react';
import DownArrow from 'assets/Images/Common/down-arrow.png';
import { useOutsideClick } from 'app/hooks/useOutsideClick';
import React from 'react';
interface Props {
  options: string[] | undefined;
  onSelect?: (option: string) => void;
  value: string | undefined;
  disableOptions?: string[];
  id?: string;
  isZones? : boolean
}
const Dropdown: FC<Props> = ({
  options,
  onSelect,
  value,
  disableOptions,
  id,
  isZones
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [selectedDropdown, setSelectedDropdown] = useState<string>('');
  const dropdownRef = useRef<HTMLDivElement>(null);
  useOutsideClick(dropdownRef, () => {
    setShowDropdown(false);
  });
  return (
    <div
      onClick={() => setShowDropdown((prev) => !prev)}
      className="dark:shadow-none rounded-md w-full relative"
      ref={dropdownRef}
    >
      <div
        className={`${
          showDropdown ? 'rounded-b-none border-primary dark:border-white' : ''
        } border bg-white min-w-[150px] border-[#CED4DA] dark:border-[#fff] text-sm font-normal placeholder:font-normal leading-4 text-[#212529] dark:text-white flex items-center justify-between sm:h-[38px] h-9 py-2 px-4 focus:outline-none rounded  dark:bg-[#41464E]  cursor-pointer`}
      >
        {value}
        {onSelect && (
          <span>
            <img
              className="dark:brightness-0 dark:invert"
              src={DownArrow}
              alt="Arrow"
            />
          </span>
        )}
      </div>
      {showDropdown && (
        <div
          className={`max-h-50 overflow-auto z-[999] rounded-md dropdownAnimation shadow-md  scrollbar-hide cursor-pointer absolute w-full border ${isZones && `h-[400px]`}`}
        >
          {options?.map((option, index) => (
            <button
              onClick={() => {
                setSelectedDropdown(option);
                onSelect && onSelect(option);
              }}
              className={`${
                disableOptions?.includes(option)
                  ? 'bg-gray-800 text-[#495057] dark:text-[#CED4DA]  cursor-not-allowed '
                  : `dark:hover:bg-[#061F3b]  ${
                      selectedDropdown !== option && 'hover:bg-[#eff2f7]'
                    } cursor-pointer `
              } ${options.length === index + 1 ? ' border-b-0' : ''} ${
                selectedDropdown === option && selectedDropdown !== options[0] && selectedDropdown === value
                  ? 'bg-[#0A4D85] text-white font-medium'
                  : 'bg-white  dark:bg-[#41464E]  '
              } p-3 text-sm block w-full text-left leading-4 text-[#495057] dark:text-[#CED4DA]  dark:hover:text-white `}
              key={option}
              disabled={disableOptions?.includes(option)}
              data-pw={index === options.length - 1 ? 'last-option' : ''}
              id={index === options.length - 1 ? id : '0'}
            >
              {option}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
export default Dropdown;
