import httpService from 'services/core/HttpService';
import {
  GET_CSV_EXPORT,
  GET_FILTERED_CAMPAIGNS,
  GET_ORDER_REVENUE_REPORT,
  GET_PERFORMANCE_REPORTS,
  GET_REPORT_QOUTA,
  GET_WORKFLOW_CONVERSION_REPORTS,
} from 'services/constant/apiRoutes';

const { REACT_APP_BASE_URL } = process.env;

export const getOrderRevenueReport = async (
  startDate: string,
  endDate: string
) => {
  const orderUrl =
    GET_ORDER_REVENUE_REPORT + `endDate=${endDate}&startDate=${startDate}`;
  return new Promise((resolve, reject) => {
    httpService
      .get(orderUrl)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getReportQouta = () => {
  return new Promise((resolve, reject) => {
    httpService
      .get(GET_REPORT_QOUTA)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getCsvExport = (startDate: string, endDate: string) => {
  const exportUrl =
    GET_CSV_EXPORT + `startDate=${startDate}&endDate=${endDate}`;
  window.open(REACT_APP_BASE_URL + exportUrl);
};

export const getFilteredCampaigns = async (
  startDate: string,
  endDate: string,
  filter?: string
) => {
  const getBlastByStatusUrl =
    GET_FILTERED_CAMPAIGNS +
    `start_date=${startDate}&end_date=${endDate}${filter  ? `&${filter}=true` : "" }`;
  return new Promise((resolve, reject) => {
    httpService
      .get(getBlastByStatusUrl)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getConversionReports = (startDate: string, endDate: string) => {
  const getConversionReportUrl = GET_WORKFLOW_CONVERSION_REPORTS.replace(
    '{{START_DATE}}',
    startDate
  ).replace('{{END_DATE}}', endDate);

  return new Promise((resolve, reject) => {
    httpService
      .get(getConversionReportUrl)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getPerformanceReports = (
  startDate: string,
  endDate: string,
  accountId: string
) => {
  const getConversionReportUrl = GET_PERFORMANCE_REPORTS.replace(
    '{{START_DATE}}',
    startDate
  )
    .replace('{{END_DATE}}', endDate)
    .replace('{{ID}}', accountId);

  return new Promise((resolve, reject) => {
    httpService
      .get(getConversionReportUrl)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
