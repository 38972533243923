import React, { FC, useContext } from 'react';
import { CampaignContext } from 'store/CampaignContext';
import Wrapper from 'components/Wrapper';
import GetFormattedItem from './GetFormattedItem';

const DeliveryOptions: FC = () => {

	const campaignCtx = useContext(CampaignContext);


	return (
		<Wrapper>
			<>
				<p className="text-[#495057] dark:text-white font-semibold text-lg py-2 px-4 border-b">
					Delivery Options
				</p>
				<div className="p-4">
					<div className="flex items-center justify-between">
						<p className="text-[#495057] dark:text-white text-base mt-2 font-semibold">
							Trick click-through activity
						</p> 
						<p className="text-[#495057] dark:text-[#878A99] text-base mt-2 font-semibold">
							{campaignCtx?.campaignData?.options[
								'track_clicks'
							]
								? 'Yes'
								: 'No'}
						</p>
					</div>
					<div className="flex items-center justify-between">
						<p className="text-[#495057] dark:text-white text-base mt-4 font-semibold">
							Social Bar
						</p>
						<p className="text-[#495057] dark:text-[#878A99] text-base mt-2 font-semibold">
							{campaignCtx?.campaignData?.options['include_social_bar']
								? 'Yes'
								: 'No'}
						</p>
					</div>
					{campaignCtx?.campaignData?.options['include_social_bar'] && (
						<div className="">
							<GetFormattedItem item={{ title: 'Title', value: campaignCtx.campaignData.share_title }} />
							<GetFormattedItem item={{ title: 'Description', value: campaignCtx.campaignData.share_description }} valueStyle='text-[#878A99] h-5' />
						</div>
					)}
					<div>
						<div className="flex justify-between items-center mb-2">
							<p className="text-[#495057] dark:text-white text-base mt-4 font-semibold">
								Integrate Google Analytics URLs
							</p> 
							<p className="text-[#495057] dark:text-[#878A99] text-base mt-2 font-semibold">
								{campaignCtx?.campaignData?.google_analytics
									? 'Yes'
									: 'No'}
							</p>
						</div>
						{campaignCtx?.campaignData?.google_analytics && (
							<div className="">
								<GetFormattedItem item={{ title: 'Campaign Source', value: campaignCtx.campaignData.utm_source }} />
								<GetFormattedItem item={{ title: 'Campaign Medium', value: campaignCtx.campaignData.utm_medium }} />
								<GetFormattedItem item={{ title: 'Campaign Content', value: campaignCtx.campaignData.utm_content }} />
								<GetFormattedItem item={{ title: 'Campaign Name', value: campaignCtx.campaignData.utm_campaign }} />
							</div>
						)}
					</div>
				</div>
			</>
		</Wrapper>
	);
};
export default DeliveryOptions;