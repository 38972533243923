import { FC } from 'react';
import { Link } from 'react-router-dom';
import { workflowReport, workflow, } from 'services/constant/routes';
import React from 'react';
import Skeleton from 'components/Skeleton/Skeleton';
import { TopPerformingData } from '../index.type';

interface Props {
  workflows: TopPerformingData[];
  loading: boolean;
  reports?:boolean
}
import Wrapper from 'components/Wrapper';

const TopPerformingWorkflows: FC<Props> = ({ workflows, loading , reports }) => {
  return (
    <Wrapper parentClass="!mt-0 h-full" childClass='h-full' >
      <div className={`w-full  2xl:h-[276px] ${reports ? 'hover:shadow-none' : 'hover:shadow-lg'}  overflow-hidden`}>
        <div className="cardHeader !items-start !py-5 flex-wrap dark:border-[#fff]">
        <div>
          <h4 className="lg:text-base text-sm text-darkText font-medium dark:text-[#878A99]">
            Top Performing Worklflows 
            
          </h4>
          <p className='text-11 text-secondary '>Previous 6 Months</p>
          </div>
          <div className="group rounded py-1 px-2 bg-[#3575d526] hover:bg-primary cursor-pointer ease-in-in duration-300">
            <Link
              to={workflow}
              className="flex items-center text-primary group-hover:text-white dark:text-white text-xxs"
            >
              View All
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="ml-1 w-3 h-3 text-primary group-hover:text-white"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                />
              </svg>
            </Link>
          </div>
        </div>
        {loading ? (
          <Skeleton columns={4} />
        ) : (
          <div>
            <ul>
              {workflows?.length > 0 ? (
                workflows?.map((workflow) => {
                  return (
                    <li
                      key={workflow.id}
                      className="flex items-center justify-between px-4 py-3  border-b dark:border-[#fff] border-[#ced4da]"
                    >
                      <div className="flex items-center w-3/4">
                        <div className="w-6 h-6 rounded-full overflow-hidden">
                          <img
                            src="https://themesbrand.com/velzon/html/galaxy/assets/images/nft/img-01.jpg"
                            alt="top performing workflows"
                          />
                        </div>
                        <div className="ml-3">
                          <Link to={`${workflowReport}/${workflow.id}`}>
                            <h6 className="text-sm text-darkText dark:text-[#CED4DA]">
                              {workflow.name}
                            </h6>
                          </Link>
                        </div>
                      </div>
                      <div className="">
                        <h6 className='dark:text-[#CED4DA]'>${Math.round(workflow.revenue).toLocaleString()}</h6>
                        <p className="text-xs text-secondary">
                          {workflow.orders} orders
                        </p>
                      </div>
                    </li>
                  );
                })
              ) : (
                <div className="flex justify-center text-[#212529] items-center mt-20  dark:text-white">
                  No Latest Workflows Found
                </div>
              )}
            </ul>
          </div>
        )}
      </div>
    </Wrapper>
  );
};
export default TopPerformingWorkflows;
