import {  FC, useContext, useEffect, useState } from 'react';
import { useFormik, FormikValues } from 'formik';
import * as Yup from 'yup';
import EmailAlert from 'components/Alert/EmailAlert';
import { CampaignContext } from 'store/CampaignContext';
import Modal from 'components/Alert/Modal';
import { Option } from './Types';
import Select from 'react-select';
import { Mail } from './Types';
import React from 'react';

interface Props {
  setShowSubjectError : (show:boolean) => void;
  showSubjectError: boolean;
  mails: Mail[];
  mergeOptions: Option[];
  mergeMail: string;
  setMergeMail: (mail:string)=> void;
}
const SubjectForm : FC<Props> = ({setShowSubjectError,showSubjectError,mails,mergeMail,mergeOptions,setMergeMail}) => {
  const campaignCtx = useContext(CampaignContext);

  const [showMergeMail, setShowMergeMail] = useState<boolean>(false);
  const [defaultValue, setDefaultValue] = useState<string>('');

  const onSelectHandler = (e: Option) => {
    const mail = mails.filter((mail) => {
      if (mail.name === e.label) {
        return mail.tag;
      }
    });
    setDefaultValue(e.label);
    setMergeMail(mail[0].tag);
  };
  
  const formik: FormikValues = useFormik({
    enableReinitialize: true,
    initialValues: {
      subject: campaignCtx?.campaignData?.subject || '',
    },
    validationSchema: Yup.object({
      subject: Yup.string().required('Subject cannot be empty'),
    }),
    onSubmit: async (values) => {
      console.log('values subject', values);
    },
  });

  useEffect(() => {
    if (campaignCtx?.campaignData) {
      campaignCtx?.onUpdateCampaignData({
        ...campaignCtx.campaignData,
        subject: formik.values.subject,
      });
    }
    if (
      campaignCtx?.campaignFormErrors.subjectFormError ||
      (formik.touched.subject && formik.values.subject.length === 0)
    ) {
      setShowSubjectError(true);
    } else {
      setShowSubjectError(false);
    }
  }, [formik.values, campaignCtx?.campaignFormErrors]);
  useEffect(() => {
    if (campaignCtx?.campaignData) {
      if (campaignCtx?.campaignData?.subject?.length > 0) {
        campaignCtx.onChangeFormValidity('subjectFormError', false);
      }
    }
  }, [campaignCtx?.campaignData?.subject]);
  useEffect(() => {
    setDefaultValue(mails[0]?.name || '');
  }, [mails]);
  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="min-h-[271px]">
        <h3 className=" text-lg leading-5 text-[#495057] font-medium dark:text-white">
          Subject <span className='text-red-700'>*</span>
        </h3>
        <div className=" py-4  ">
          <input
            className="w-full h-[38px] focus:border-primary dark:focus:border-white focus:outline-none py-2 px-4 text-13 font-medium leading-3 border border-[#CED4DA] placeholder:text-gray-500 dark:placeholder:text-white dark:border-[#fff]  dark:bg-[#41464E]  placeholder:font-normal rounded text-[#495057] dark:text-[#CED4DA]  dark:text-white"
            type="text"
            name="subject"
            data-pw="subject-input"
            onChange={formik.handleChange}
            value={formik.values.subject}
            onBlur={formik.handleBlur}
            placeholder="Enter Subject"
          />
          {showSubjectError && (
            <div className="text-xs leading-4 text-red-400 mt-1.5">
              Subject cannot be empty
            </div>
          )}
          <div className="mt-4 ">
            <Select
              className="basic-single"
              classNamePrefix="select"
              isSearchable={true}
              value={{ label: defaultValue, value: defaultValue }}
              options={mergeOptions}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              onChange={(selectedOption : any) => {
                onSelectHandler(selectedOption);
              }}
            />
            <div className="flex justify-end mt-2">
              <a
                className=" cursor-pointer inline-flex items-center text-xs font-medium text-primary ml-3"
                onClick={() => setShowMergeMail((prev) => !prev)}
              >
                <span className="w-3 h-3 flex justify-center items-center rounded-full bg-primary text-white text-xs font-bold mr-1">
                  +
                </span>
                <span className="unde</div>rline">Add Mail Merge</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={showMergeMail}>
        <EmailAlert
          cancelClicked={() => setShowMergeMail(false)}
          createClicked={(mail) => {
            if (campaignCtx?.campaignData) {
              campaignCtx?.onUpdateCampaignData({
                ...campaignCtx.campaignData,
                mergeFields: mail,
              });
            }
            formik.setFieldValue('subject', formik.values.subject.concat(mail));
            setShowMergeMail(false);
          }}
          mergeMail={mergeMail}
        />
      </Modal>
    </form>
  );
};
export default SubjectForm;