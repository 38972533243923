import React, { FC, useEffect, useMemo, useRef, useState } from 'react';
import DownArrow from 'assets/Images/Common/down-arrow.png';
import { ModifiedEvents } from 'staticdata/EventLogs/Data';
import { useOutsideClick } from 'app/hooks/useOutsideClick';
import { transformEvents } from 'staticdata/EventLogs/Data';

interface Props {
  options: string[];
  onSelect: (option: string) => void;
  value: string | undefined;
  setSelectedDropdownEvent?: (event: string) => void;
}

const Dropdown: FC<Props> = ({
  options,
  onSelect,
  value,
  setSelectedDropdownEvent,
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);

  const [searchedValue, setSearchedValue] = useState<string>("");
  const [selectedDropdown, setSelectedDropdown] = useState<string | undefined>(value && (ModifiedEvents[value] ? ModifiedEvents[value].name : value));
  let updatedEvents: { [eventName: string]: string[]} = {}
  const dropdownRef = useRef<HTMLDivElement>(null);
  useOutsideClick(dropdownRef, () => setShowDropdown(false));

  const filteredOptions = useMemo(() => {
    
    const Options: string[] = options.filter(option => option !== selectedDropdown)
    if(selectedDropdown) Options.unshift(selectedDropdown); // push selected option at the start of array
      
    
    //Transforming Events
    updatedEvents = transformEvents(Options);

    const eventsToShow: string[] = [];
    if (searchedValue) {
      for (const key in updatedEvents) {
        if (key.toLocaleLowerCase().includes(searchedValue.toLowerCase())) {
          eventsToShow.push(key);
        }
      }
    } else {
      for (const key in updatedEvents) {
        eventsToShow.push(key);
      }
    }

    return eventsToShow;
  }, [searchedValue, options, selectedDropdown, updatedEvents]);

  useEffect(()=>{
      setSelectedDropdown(value && (ModifiedEvents[value] ? ModifiedEvents[value].name : value))
  },[value])

  return (
    <div className='' ref={dropdownRef}>
      <div
        onClick={() => setShowDropdown((prev) => !prev)}
        className={`${
          showDropdown ? 'rounded-md border-primary dark:border-white' : ''
        } text-sm rounded-md leading-4 text-[#212529] dark:text-white flex items-center cursor-pointer justify-between sm:h-[38px] py-2 px-4 focus:outline-none bg-white border border-[#CED4DA] dark:bg-transparent dark:border-[#fff]`}
        data-pw="dropdown"
      >
        {selectedDropdown}
        <span>
          <img
            className="dark:brightness-0 dark:invert"
            src={DownArrow}
            alt="Arrow"
          />
        </span>
      </div>
      {showDropdown ? (
        <div className=" relative ">
          <div className="min-w-[200px] w-full shadow-lg absolute border-t-0 rounded-b-md border-[#CED4DA] dark:border-[#fff]  z-[99999] overflow-hidden">
            <input
              className="border border-[#CED4DA] focus:outline-none dark:border-[#fff]  dark:bg-[#41464E]  sm:[38px] h-9 p-4 text-13 font-medium leading-3 text-gray-700 placeholder:text-gray-500 dark:text-white dark:placeholder:text-white rounded-t w-full"
              type="text"
              name="Search"
              placeholder="Filter Items"
              value={searchedValue}
              onChange={(e) => setSearchedValue(e.target.value)}
            />
            <div className=" max-h-40 overflow-auto bg-white">
              {filteredOptions?.map((option, index) => (
                <div
                  onClick={() => {
                    onSelect(updatedEvents[option].join(','));
                    setShowDropdown(false);
                    setSelectedDropdown(option);
                    setSelectedDropdownEvent &&
                    setSelectedDropdownEvent(option);
                  }}
                  className={`${
                    filteredOptions.length === index + 1 ? 'border-b-0' : ''
                  } p-3 text-sm cursor-pointer block w-full text-left leading-4 text-textColor dark:text-white ${
                    selectedDropdown !== option && 'hover:bg-dropdownHover'
                  } dark:hover:bg-white focus:bg-primary focus:text-white dark:text-white dark:hover:text-[#495057] dark:text-[#CED4DA]  ${
                    selectedDropdown === option
                      ? 'bg-[#0A4D85] text-white font-medium'
                      : 'bg-white'
                  }  dark:bg-[#41464E]  dark:text-white`}
                  key={index}
                  data-pw={
                    filteredOptions.length - 1 === index ? 'last-option' : ''
                  }
                >
                  {option}
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default Dropdown;
