import DownArrow from 'assets/Images/Common/down-arrow.png';
import { useEffect, useState, FC } from 'react';
import { useFormik, FormikValues } from 'formik';
import {
  createIntegration,
  validateIntegration,
} from 'services/apiHandlers/Integration/CartIntegration';
import { Toastify } from 'App';
import uuid from 'react-uuid';
import { z } from 'zod';
import React from "react";

const validateShopifySchema = z.object({
  APIkey: z.string(),
  domainUrl: z.string(),
  excludeProduct: z.string(),
  excludeProductsList: z.union([z.array(z.string()), z.tuple([])]),
  formAutoEmailCapture: z.boolean().optional(),
  id: z.string(),
  password: z.string(),
  publishEvents: z.boolean(),
  sendAnonymousEventsAfterIdentification: z.boolean().optional(),
  sharedSecret: z.string(),
  shouldGrabOrderHistory: z.boolean(),
  showExcludeProductSection: z.boolean(),
  storeName: z.string(),
  storeUrl: z.string(),
  uniqueIdConstraint: z.string(),
  _type: z.string(),
});
type validateShopify = z.infer<typeof validateShopifySchema>;

const validateResponseSchema = z.object({
  status: z.number(),
});
type validateResponse = z.infer<typeof validateResponseSchema>;

interface Props {
  removeShopify: () => void;
  getData: () => void;
  data?: validateShopify | undefined;
}

const eventCheckboxOptions = [
  {
    title: 'Auto capture Email',
    id: '1',
    name: "formAutoEmailCapture"
  },
  { title: 'Send anonymous events after identification', id: '2' , name: "sendAnonymousEventsAfterIdentification" },
];


const checkboxOptions = [
  {
    title: 'Grab Order History (Last 2 year Orders data)',
    id: '1',
    defaultChecked: true,
    name: 'shouldGrabOrderHistory',
  },
  { title: 'Publish Events', id: '2', name: 'publishEvents' },
];

const radioOptions = [
  {
    title: 'store + orderId combination',
    id: '1',
    defaultChecked: true,
    value: 'composite',
  },
  { title: 'orderId only', id: '2', value: 'single' },
];

const ShopifyData = [
  { name: 'storeUrl', placeholder: 'Store Url' },
  { name: 'APIkey', placeholder: 'Api Key' },
  { name: 'password', placeholder: 'Api Password' },
  { name: 'sharedSecret', placeholder: 'Shared Secret' },
];

const ShopifyForm: FC<Props> = ({ removeShopify, getData, data }) => {
  const [invalidCredentials, setInvalidCredentials] = useState<boolean>(false);
  const [isValidData, setIsValidData] = useState<boolean>(false);
  const [showOptions, setShowOptions] = useState<boolean>(true);
  const [excludeProduct, setExcludeProduct] = useState<string>('');
  const [showAddProductSection, setShowAddProductSection] =
    useState<boolean>(false);
  const [excludeProductList, setExcludeProductList] = useState<Array<string>>(
    []
  );

  const formik: FormikValues = useFormik({
    enableReinitialize: true,
    initialValues: {
      APIkey: '',
      password: '',
      storeUrl: '',
      sharedSecret: '',
      shouldGrabOrderHistory: true,
      publishEvents: false,
      uniqueIdConstraint: 'composite',
      formAutoEmailCapture: false,
      sendAnonymousEventsAfterIdentification: true,
    },
    onSubmit: async (values, { resetForm }) => {
      resetForm();
      const data = {
        ...values,
        domainUrl: 'null',
        excludeProduct: '',
        excludeProductsList: excludeProductList,
        id: uuid(),
        showExcludeProductSection: showAddProductSection,
        storeName: 'null',
        _type: 'shopify',
      };

      try {
        const createShopifyResponse = (await createIntegration(
          data
        )) as validateResponse;
        if (createShopifyResponse.status === 200) {
          setIsValidData(false);
          setInvalidCredentials(false);
          getData();
          Toastify('Shopify Created Successfullly', 'success', 'shopifyForm1');
          resetForm();
        }
      } catch (error) {
        Toastify('Shopify Creation Failed', 'error', 'shopifyForm2');
      }
    },
  });

  const addProductHandler = (name: string) => {
    const tempProduct = [...excludeProductList];
    tempProduct.push(name);
    setExcludeProductList(tempProduct);
    setExcludeProduct('');
  };

  const deleteProductHandler = (index: number) => {
    const tempProduct = [...excludeProductList];
    tempProduct.splice(index, 1);
    setExcludeProductList(tempProduct);
  };

  const checkValidation = async () => {
    if (
      formik.values.APIkey.length > 0 &&
      formik.values.password.length > 0 &&
      formik.values.sharedSecret.length > 0 &&
      formik.values.storeUrl.length > 0
    ) {
      const data = {
        ...formik.values,
        domainUrl: 'null',
        excludeProduct: '',
        excludeProductsList: excludeProductList,
        id: '8eea0796-232a-4293-b377-6b251b0064a3',
        showExcludeProductSection: showAddProductSection,
        storeName: 'null',
        _type: 'shopify',
      };
      try {
        const validateShopifyResponse = (await validateIntegration(
          data
        )) as validateResponse;
        if (validateShopifyResponse.status === 200) {
          setIsValidData(true);
        } else {
          Toastify('Shopify Data is Not Valid', 'error', 'shopifyForm3');
          setInvalidCredentials(true);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    if (!formik.values.publishEvents) {
      setShowAddProductSection(false);
    }
    const checkInputValidation = setTimeout(() => {
      checkValidation();
    }, 3000);
    return () => clearTimeout(checkInputValidation);
  }, [formik.values]);

  useEffect(() => {
    data ? formik.setValues(data) : formik.setValues(formik.initialValues);
  }, []);

  return (
    <div>
      <form
        onSubmit={formik.handleSubmit}
        className="bg-white mt-6 p-4 bg-[#f5f5f5] border dark:border-[#fff] rounded  dark:bg-[#41464E] "
      >
        <h4 className="text-13 font-semibold leading-4 text-black-700 dark:text-white mb-3">
          Shopify
        </h4>
        {ShopifyData.map((field) => (
          <div key={field.name}>
            <input
              className="w-full text-13 focus:border-primary dark:focus:border-white opacity-1 placeholder:text-gray-500 focus:outline-none font-medium leading-3 text-gray-700 dark:text-white py-2 rounded-md px-4 bg-white  dark:bg-[#41464E]  border border-[#ced4da] dark:border-[#fff] sm:[38px] h-9 mt-4 dark:placeholder:text-white"
              type="text"
              placeholder={field.placeholder}
              name={field.name}
              value={formik.values[field.name]}
              onChange={formik.handleChange}
            />
          </div>
        ))}
        <div>
          {checkboxOptions.map((option) => (
            <div
              key={option.id}
              className="relative flex items-center cursor-pointer mt-1"
            >
              <input
                name={option.name}
                id={option.name}
                className="cursor-pointer"
                value="checked"
                type="checkbox"
                defaultChecked={option.defaultChecked}
                checked={formik.values[option.name]}
                onChange={formik.handleChange}
              />
              <label
                className="checkBox relative cursor-pointer pl-2"
                htmlFor={option.name}
              >
                <span className="text-13 font-normal leading-3 text-black-700 dark:text-white">
                  {option.title}
                </span>
              </label>
            </div>
          ))}
          {formik.values.publishEvents && showAddProductSection && (
            <div>
              <div className="w-60">
                <input
                  className="w-full text-13 font-medium placeholder:dark:text-white leading-3 text-gray-700 dark:text-white py-2 rounded px-4 bg-white  dark:bg-[#41464E]  border border-[#ced4da] dark:border-[#fff] sm:[38px] h-9 mt-4"
                  value={excludeProduct}
                  type="text"
                  placeholder="Product Name"
                  onChange={(e) => {
                    setExcludeProduct(e.target.value);
                  }}
                />
              </div>
              <div className="flex items-center">
                {excludeProductList.map((product, index) => (
                  <div
                    key={product}
                    className="flex items-center py-1.5 px-2 mt-4 mr-4 rounded border border-[#ced4da] dark:border-[#fff] text-sm font-medium leading-5 text-[#495057] dark:text-[#CED4DA] "
                  >
                    <span
                      onClick={() => deleteProductHandler(index)}
                      className="text-lg font-semibold leading-5 text-red-600 mr-2 -mt-0.5 cursor-pointer"
                    >
                      x
                    </span>
                    <div>{product}</div>
                  </div>
                ))}
              </div>
              <div className="flex flex-wrap items-center mt-4">
                <button
                  type="button"
                  className="text-13 font-medium leading-5 rounded py-2 px-4 text-white bg-primary dark:hover:bg-white dark:hover:text-[#495057] dark:text-[#CED4DA]  dark:text-white mr-4"
                  onClick={() => addProductHandler(excludeProduct)}
                >
                  Add Product
                </button>
                <button
                  type="button"
                  className="text-center sm:text-13 text-xs py-2.5 px-4 h-9 flex justify-center items-center w-28 font-medium leading-5 mr-2  rounded-md bg-[#f3f6f9] hover:bg-[#cfd1d4] transition-bg text-[#212529]"
                  onClick={() => setShowAddProductSection(false)}
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
          {formik.values.publishEvents && !showAddProductSection && (
            <div
              onClick={() => setShowAddProductSection(true)}
              className="flex items-center mt-3"
            >
              <button
                className="text-lg font-semibold leading-4 text-black-700 px-0.5 border border-[#ced4da] dark:border-[#fff] dark:text-white rounded"
                type="button"
              >
                +
              </button>
              <div className="text-13 font-medium leading-3 text-black-700 dark:text-white cursor-pointer ml-2">
                Add Product Names to Exclude from Events
              </div>
            </div>
          )}
        </div>
        <div>
          <div
            onClick={() => setShowOptions((prev) => !prev)}
            className="flex items-center relative cursor-pointer mt-4"
          >
            <p className="text-xs font-semibold leading-4 text-black-700 dark:text-white">
              Advance Configuration Options
            </p>
            <img className="pl-2" src={DownArrow} alt="Arrow"/>
          </div>
          {showOptions && (
            <>
              <p className="text-xs font-medium leading-3 text-primary dark:text-white mt-4 mb-3">
                Unique OrderId Constraint:
              </p>
              <div>
                {radioOptions.map((option) => (
                  <div
                    key={option.id}
                    className="radio-btn block relative flex items-center text-13 font-normal leading-3 text-[#495057] dark:text-[#CED4DA]  dark:text-white dark:text-white mt-2"
                  >
                    <input
                      id={option.title}
                      type="radio"
                      value={option.value}
                      name="uniqueIdConstraint"
                      className="w-4 h-4 text-primary bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600 mr-2"
                      defaultChecked={option.defaultChecked}
                      checked={
                        formik.values.uniqueIdConstraint === option.value
                      }
                      onChange={formik.handleChange}
                    />
                    <label
                      htmlFor={option.title}
                      className="text-xs font-semibold leading-3 tracking-wide uppercase text-[#495057] dark:text-[#CED4DA]  dark:text-white dark:text-white inline-block inline-block"
                    >
                      {option.title}
                    </label>
                  </div>
                ))}
              </div>
              <p className="text-xs font-medium leading-3 text-primary dark:text-white mt-4">
                Event Publish Configurations
              </p>
              {eventCheckboxOptions.map((option) => (
                <>
                  <div
                    key={option.id}
                    className="relative flex items-center cursor-pointer mt-1"
                  >
                    <input
                      name={option.name}
                      id={option.name}
                      className="cursor-pointer "
                      value=""
                      type="checkbox"
                      defaultChecked
                      checked={formik.values[option.name]}
                      disabled={!formik.values.publishEvents}
                      onChange={formik.handleChange}
                    />
                    <label
                      className="checkBox relative cursor-pointer pl-2"
                      htmlFor={option.name}
                    >
                      <span className="text-13 font-normal leading-3 text-black-700 dark:text-white">
                        {option.title}
                      </span>
                    </label>
                  </div>
                </>
              ))}
            </>
          )}
        </div>
        <div className="flex flex-wrap items-center mt-4">
          <button
            type="submit"
            className={` ${
              !isValidData
                ? 'bg-gray-800 text-[#495057] dark:text-[#CED4DA]  dark:text-white font-nomral '
                : 'bg-primary text-white '
            }text-13 font-medium leading-5 rounded py-2 px-4 dark:hover:bg-white dark:hover:text-[#495057] dark:text-[#CED4DA]  dark:text-white mr-4 mt-4`}
            disabled={!isValidData}
          >
            Save
          </button>
          <button
            type="button"
            className="text-13 font-medium leading-5 rounded py-2 px-4 text-white bg-[#B31E33] dark:hover:bg-white dark:hover:text-[#b31e33] mr-4 mt-4"
            onClick={removeShopify}
          >
            Remove
          </button>
          <a
           
            className="text-sm leading-4 text-[#cb5b57] dark:text-white mt-4"
          >
            {invalidCredentials
              ? 'invalid Credentials'
              : !isValidData
              ? 'All fields are required'
              : ''}
          </a>
        </div>
      </form>
    </div>
  );
};
export default ShopifyForm;
