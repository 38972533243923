import React, { useEffect, useState, FC } from 'react';
import moment from 'moment';
import { z } from 'zod';
import MixGraph from 'components/Graph/MixGraph';
import CustomSkeleton from 'components/Skeleton/Skeleton';

import { ChartData } from 'chart.js';
import {
    WorkflowPerformanceData,
    CampaignItemData,
} from 'components/Dashboard/index.type';
import Tabs from 'components/Graph/Tabs';
import Wrapper from 'components/Wrapper';

const datasetsSchema = z.object({
    backgroundColor: z.string(),
    borderColor: z.string(),
    data: z.array(z.string()),
    label: z.string(),
    type: z.string(),
    fill: z.boolean().optional(),
});

type Dataset = z.infer<typeof datasetsSchema>;

interface Props {
    campaignPerformanceData: CampaignItemData[] | undefined;
    workflowPerformanceData: WorkflowPerformanceData;
    loading: boolean;
}

const tabs = ['Revenue', 'Orders'];

const Revenues: FC<Props> = ({
    campaignPerformanceData,
    workflowPerformanceData,
    loading,
}) => {
    const [labels, setLabels] = useState<Array<string>>([]);
    const [dataset, setDatasets] = useState<Array<Dataset>>([]);
    const [activeTab, setActiveTab] = useState<string>('Revenue');
    const [redraw, setRedraw] = useState<boolean>(false);

    const getPerformanceData = (tab?: string) => {
        setActiveTab(tab || 'Revenue');
        try {
            const campaignOrders = campaignPerformanceData?.map((campaign) => {
                return Math.round(campaign.order);
            });
            const campaignRevenues = campaignPerformanceData?.map(
                (campaign) => {
                    return Math.round(campaign.revenue);
                }
            );

            const previousMonths = [];
            for (let i = 5; i >= 0; i--) {
                const month = moment().subtract(i, 'months').format('MMMM');
                previousMonths.push(month);
            }
            setLabels(previousMonths);

            if (tab === 'Orders') {
                setDatasets([
                    {
                        backgroundColor: '#65c5959c',
                        borderColor: '#65C595',
                        data: workflowPerformanceData.orders || [],
                        label: 'Workflow Orders',
                        type: 'line',
                        fill: true,
                    },
                    {
                        backgroundColor: '#fcf12194',
                        borderColor: '#FCF121',
                        data: campaignOrders?.map(String) || [],
                        label: 'Campaign Orders',
                        type: 'line',
                        fill: true,
                    },
                ]);
            } else {
                setDatasets([
                    {
                        backgroundColor: '#6597fd8c',
                        borderColor: '#6597FD',
                        data: workflowPerformanceData.revenue,
                        label: 'Workflow Revenues',
                        type: 'line',
                        fill: true,
                    },
                    {
                        backgroundColor: '#9c7bd491',
                        borderColor: '#9C7BD4',
                        data: campaignRevenues?.map(String) || [],
                        label: 'Campiagn Revenues',
                        type: 'line',
                        fill: true,
                    },
                ]);
            }
        } catch (error) {
            console.log('error is: ', error);
        }
    };

    useEffect(() => {
        getPerformanceData();
    }, [campaignPerformanceData, workflowPerformanceData]);

    return (
        <Wrapper parentClass="!mt-0 ">
            <div className="shadow-lg hover:rounded-lg hover:shadow-lg overflow-hidden hover:shadow-gray-200 dark:shadow-none  storeTable">
                <div className="cardHeader dark:border-[#fff]">
                    <h4 className="text-base text-darkText font-medium dark:text-[#878A99]">
                        Workflows vs Campaigns
                    </h4>
                    <Tabs
                        activeTab={activeTab}
                        onTabClick={(tab) => {
                            if (tab === 'All') {
                                setRedraw(true);
                            } else {
                                setRedraw(false);
                            }
                            getPerformanceData(tab);
                        }}
                        tabs={tabs}
                    />
                </div>
                <div className="px-4 pt-0 pb-10">
                    {loading ? (
                        <CustomSkeleton columns={9} />
                    ) : !campaignPerformanceData ||
                      !workflowPerformanceData.orders ? (
                        <div
                            style={{
                                width: '100%',
                                height: '360px',
                            }}
                        >
                            <CustomSkeleton columns={10} />
                        </div>
                    ) : (
                        <MixGraph
                            isQuantitativeData={activeTab === 'Orders'}
                            labels={labels}
                            dataset={
                                dataset as unknown as ChartData<
                                    'line',
                                    string[],
                                    unknown
                                >
                            }
                            redraw={redraw}
                        />
                    )}
                </div>
            </div>
        </Wrapper>
    );
};
export default Revenues;
