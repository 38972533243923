import React, { FC, useContext, useEffect } from 'react';
import ErrorBoundary from 'components/ErrorBoundary';
import { useParams } from 'react-router-dom';
import { getNewCampaignData } from 'services/apiHandlers/Campaigns/Emails';
import { CampaignContext } from 'store/CampaignContext';
import ListSegmentsFilter from './ListSegmentsFilter';
import SenderSubject from './SenderSubject';
import DeliveryOptions from './DeliveryOptions';
import ScheduleBlast from './ScheduleBlast';
import { z } from 'zod';

const newCampaignDataSchema = z.object({
	finder_id: z.number(),
	hide_continue_button: z.boolean(),
	campaign_source: z.string(),
	draft_changed: z.boolean(),
	franchise_enabled: z.boolean(),
	no_subscribers: z.boolean(),
	personDbId: z.number(),
	resold: z.boolean(),
	rss_enabled: z.boolean(),
	rss_included: z.boolean(),
	buy_more_quota_link: z.string(),
	upgrade_account_link: z.string(),
	preview_link: z.string(),
	account_tracking_hash: z.string(),
	default_owner: z.object({
		owner: z.object({
			account_id: z.number(),
			city: z.string(),
			country: z.string(),
			created_at: z.string(),
			custom_field_values: z.string(),
			deleted_at: z.string(),
			franchise_code: z.string(),
			franchise_id: z.number(),
			from_email_address: z.string(),
			from_name: z.string(),
			id: z.number(),
			name: z.string(),
			owner_type: z.string(),
			state: z.string(),
			street_address_1: z.string(),
			street_address_2: z.string(),
			tracking_hash: z.string(),
			updated_at: z.string(),
			zip: z.string(),
		}),
	}),
	default_owner_valid: z.boolean(),
	include_social_bar: z.boolean(),
	share_description: z.string(),
	use_google_analytics: z.boolean(),
});

const getNewCampaignResponseSchema = z.object({
	data: newCampaignDataSchema,
	status: z.number(),
});

const informationItemSchema = z.object({
	title: z.string(),
	value: z.string()
})

export type InformationItem = z.infer<typeof informationItemSchema>;
type GetNewCampaignDataResponse = z.infer<typeof getNewCampaignResponseSchema>;

interface Props {
	selectedTemplate: string;
	selectedFilter: string;
}
const FinalInformation: FC<Props> = ({ selectedTemplate, selectedFilter }) => {
	const { id } = useParams();
	const campaignCtx = useContext(CampaignContext);


	const getNewCampaignDataHandler = async (id: string) => {
		try {
			const getNewCampaignDataResponse = (await getNewCampaignData(
				id
			)) as GetNewCampaignDataResponse;
			if (getNewCampaignDataResponse.status === 200) {
				const {
					finder_id,
					campaign_source,
					share_description,
					include_social_bar,
					use_google_analytics,
				} = getNewCampaignDataResponse.data;

				if (campaignCtx?.campaignData) {
					campaignCtx?.onUpdateCampaignData({
						...campaignCtx.campaignData,
						finder_id: finder_id,
						email_id: parseInt(id),
					});
				}
				campaignCtx?.onChangeInitialData({
					campaignData: {
						utm_campaign:
							campaignCtx.campaignData?.utm_campaign || '',
						utm_content:
							campaignCtx.campaignData?.utm_content || '',
						utm_medium: campaignCtx.campaignData?.utm_medium || '',
						utm_source: campaign_source,
					},
					description: share_description,
					socialBar: include_social_bar,
					googleAnalytics: use_google_analytics,
					trackClick: false,
				});
			}
		} catch (error) {
			console.log('erorr is  : ', error);
		}
	};

	//TO-FIX why this is used check it
	useEffect(() => {
		const emailId = id;

		if (emailId) {
			getNewCampaignDataHandler(emailId);
		}
	}, []);

	return (
		<ErrorBoundary>
			<ListSegmentsFilter selectedFilter={selectedFilter} />
			<SenderSubject />
			<DeliveryOptions />
			<ScheduleBlast selectedTemplate={selectedTemplate} />
		</ErrorBoundary>
	);
};
export default FinalInformation;