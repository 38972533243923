import {
  deleteEditorImage,
  getEditorImages,
  uploadEditorImage,
} from 'services/apiHandlers/Campaigns/Emails';
import { z } from 'zod';
// import Plus from 'assets/Images/svg-images/plus.svg';
import { html } from './html';
import { Toastify } from 'App';

const {
  REACT_APP_USEREMAIL,
  REACT_APP_SECRET_KEY,
  REACT_APP_PLUGIN_ID,
  REACT_APP_BASE_URL,
} = process.env;

const URL = REACT_APP_BASE_URL;

const getImageResponseSchema = z.object({
  data: z.array(z.array(z.string())),
  status: z.number(),
});
type GetImageResponse = z.infer<typeof getImageResponseSchema>;

const deleteImageResponseSchema = z.object({ status: z.number() });
type DeleteImageResponse = z.infer<typeof deleteImageResponseSchema>;

const SinglecampaignSchema = z.object({
  monetaryValue: z.number(),
  campaignName: z.string(),
  conversions: z.string().optional(),
  id: z.number().optional(),
  date: z.string().optional(),
  eventDate: z.string().optional(),
});

const campaignsSchema = z.array(SinglecampaignSchema);

export type campaigns = z.infer<typeof campaignsSchema>;

const callbackSchema = z.function().args(z.any());

type Callback = z.infer<typeof callbackSchema>;

export const commonNumbers = 0;
export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
export const formatXmlJsonResponse = (
    data: []
): Array<Record<string, string>> => {
    /* eslint-disable */
    const formatedData = [];
    for (let i = 0; data?.length > i; i++) {
        const keys: any = Object?.keys(data[i]);
        const temp: Record<string, string> = {};
        for (let j = 0; keys?.length > j; j++) {
            temp[keys[j]] =
                typeof data[i][keys[j]][0]['_text'] == 'object'
                    ? ''
                    : data[i][keys[j]][0]['_text'];
        }

        formatedData.push(temp);
    }
    /* eslint-enable  */

    return formatedData;
};

export const tableSort = (a: string | number, b: string | number) => {
    if (a.toString().toLowerCase() > b.toString().toLowerCase()) {
        return 1;
    }

    if (b.toString().toLowerCase() > a.toString().toLowerCase()) {
        return -1;
    }

    return 0;
};

export const tableDateSort = (a: string, b: string) => {
    const first = new Date(a);
    const second = new Date(b);
    const firstDate = first.toDateString();
    const secondDate = second.toDateString();
    if (firstDate > secondDate) {
        return 1;
    }
    if (secondDate > firstDate) {
        return -1;
    }

    return 0;
};

export const scrollToTop = () => {
  window && window?.scrollTo({ top: 0, left: 0 });
};

export const checkSymbol = (symbol: string) => {
    if (symbol === '!=') {
        return 'is not equal to';
    } else if (symbol === '%') {
        return 'conatins';
    } else if (symbol === '>') {
        return 'is greater than';
    } else if (symbol === '<') {
        return 'is less than';
    } else if (symbol === '==') {
        return 'is equal to';
    } else if (symbol === '>=') {
        return 'is greater than or equal to';
    } else {
        return 'not identified';
    }
  };
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.ExternalImagesLibrary = (function () {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let externalLibrary: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let imageSelectCallback: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let cancelCallback: any;
  let images: string[][] = [];

  const close = function () {
    externalLibrary.style.visibility = 'hidden';
  };

  const cancelAndClose = function () {
    close();
    cancelCallback();
  };

  const deleteImage = async (imageSrc: string) => {
    const filteredImage = images?.filter(
      (image) => URL + image[0] === imageSrc
    );
    try {
      const deleteEditorImageResponse = (await deleteEditorImage(
        filteredImage[0]
      )) as DeleteImageResponse;
      if (deleteEditorImageResponse.status === 200) {
        const getEditorImagesResponse =
          (await getEditorImages()) as GetImageResponse;
        if (getEditorImagesResponse.status === 200) {
          Toastify('Image Deleted Successfully', 'success', 'commonDelete');
          const updatedImages = getEditorImagesResponse.data;
          const imagesDiv = document.getElementById('editorImages');
          if (imagesDiv) {
            imagesDiv.innerHTML = `<div>
            <div id="externalImagesLibrary" style="background-color: rgba(0,0,0,.5); overflow: hidden; position: fixed; top: 0; right: 0;  bottom: 0; left: 0; z-index: 1050; font-family: sans-serif;">\
               <div style="margin: 10px;">\
                 <div style="background-color: #f6f6f6; border-radius: 17px 17px 30px 30px; max-width: 900px; margin: 0 auto;">\
                     <div style="padding: 15px; border-bottom: 1px solid #e5e5e5;">\
                         <div>\
                            <button class="close" type="button" style="cursor: pointer; background: transparent; border: 0; float: right; font-size: 21px; font-weight: bold; opacity: .2;">\
                                 <span>×</span>\
                             </button>\
                             <h4 style="margin: 0; font-size: 18px; color: rgb(85, 85, 85);">External Images Library</h4>\
                         </div>\
                     </div>\
                     <div id="editorImages" style="padding: 15px;">\
                         ${updatedImages?.map((image) => {
                           const imageSrc = URL + image[0];
                           return `<div class="thumbnail" style="display: inline-block; width: 154px; cursor: pointer; padding: 4px; background-color: #ffffff; border: 1px solid #b80000; border-radius: 10px; margin-right: 10px">
                           <button data-image-src="${imageSrc}" id="deleteImage" type="button" style="cursor: pointer; background: transparent; border: 0; float: right; font-size: 21px; font-weight: bold; opacity: 0.2;">
                             <span>&times;</span>
                           </button>                         
                           <img style="height: 100px; margin-left: auto; margin-right: auto; max-width: 100%; display: block; vertical-align: middle;"
                             src="${imageSrc}">
                          </div>`;
                         })}
                       
                     </div>\
                 </div>\
             </div>
                      
             <div style="width: 66.666667%; margin-left: 7%; margin-top: 1rem; padding-top: 1.5rem; padding-bottom: 2rem; display: flex; justify-content: center; align-items: center; position: relative; border: 1px dashed #3182ce; border-radius: 0.25rem; background-color: #fbfcfd;">
             <div style="width: 194px; margin-left: auto; margin-right: auto;">
               <div style="padding-left: 0.125rem;">
                 <button style="font-size: 13px; font-weight: 500; line-height: 1; color: #fff; border-radius: 0.25rem; display: flex; align-items: center; justify-content: center; padding-top: 0.75rem; padding-bottom: 0.75rem; padding-left: 0.5rem; padding-right: 0.5rem; width: 100%; margin-top: 0.625rem; background-color: #3182ce;">
                   <img src="" />
                   <span style="margin-left: 0.75rem;">Select files to upload</span>
                 </button>
               </div>
             </div>
             <input id="fileUpload" style="position: absolute; top: 0; width: 100%; height: 100%; opacity: 0; cursor: pointer;" type="file" name="upload" />
           </div>
             
             </div>`;
            document.body.appendChild(imagesDiv);
          }
        }
      }
    } catch (error) {
      Toastify('Image Deletetion Failed', 'error', 'commonDelete2');
    }
  };

  const deleteImageClicked = function (imageSrc: string) {
    const modal = document.createElement('div');
    modal.innerHTML = `<div id="deleteConfirmationModal" style="position: fixed; top: 0; right: 0; bottom: 0; left: 0; background-color: rgba(0, 0, 0, 0.5); z-index: 9999; display: flex; justify-content: center; align-items: center;">
      <div style="background-color: #fff; padding: 20px; border-radius: 5px; text-align: center;">
        <p>If you delete this image, it will be removed from your past emails. Do you want to continue?</p>
        <button id="cancelButton" style="margin-right: 10px;">Cancel</button>
        <button id="continueButton">Continue</button>
      </div>
    </div>`;

    document.body.appendChild(modal);

    const cancelButton = document.getElementById('cancelButton');
    cancelButton?.addEventListener('click', function () {
      document.body.removeChild(modal);
    });

    const continueButton = document.getElementById('continueButton');
    continueButton?.addEventListener('click', function () {
      deleteImage(imageSrc);
      document.body.removeChild(modal);
    });
  };

  const fileUploadHandler = async (file: File) => {
    const uploadFileResponse = await uploadEditorImage(file);
    console.log('upload file response is : ', uploadFileResponse);
  };

  const initLibrary = async function () {
    // const getImages = async() => {
    try {
      const getEditorImagesResponse =
        (await getEditorImages()) as GetImageResponse;
      if (getEditorImagesResponse.status === 200) {
        images = getEditorImagesResponse.data;
      }
    } catch (error) {
      images = [];
      console.log('error is : ', error);
    }
    // }

    // getImages()

    const div = document.createElement('div');
    div.innerHTML = ` <div id="editorImages">
      <div id="externalImagesLibrary" style="background-color: rgba(0,0,0,.5); overflow: hidden; position: fixed; top: 0; right: 0;  bottom: 0; left: 0; z-index: 1050; font-family: sans-serif;">\
         <div style="margin: 10px;">\
           <div style="background-color: #f6f6f6; border-radius: 17px 17px 30px 30px; max-width: 900px; margin: 0 auto;">\
               <div style="padding: 15px; border-bottom: 1px solid #e5e5e5;">\
                   <div>\
                      <button class="close" type="button" style="cursor: pointer; background: transparent; border: 0; float: right; font-size: 21px; font-weight: bold; opacity: .2;">\
                           <span>×</span>\
                       </button>\
                       <h4 style="margin: 0; font-size: 18px; color: rgb(85, 85, 85);">External Images Library</h4>\
                   </div>\
               </div>\
               <div style="padding: 15px;">\
                   ${images?.map((image) => {
                     const imageSrc = URL + image[0];
                     return `<div class="thumbnail" style="display: inline-block; width: 154px; cursor: pointer; padding: 4px; background-color: #ffffff; border: 1px solid #b80000; border-radius: 10px; margin-right: 10px">
                     <button data-image-src="${imageSrc}" id="deleteImage" type="button" style="cursor: pointer; background: transparent; border: 0; float: right; font-size: 21px; font-weight: bold; opacity: 0.2;">
                       <span>&times;</span>
                     </button>                         
                     <img style="height: 100px; margin-left: auto; margin-right: auto; max-width: 100%; display: block; vertical-align: middle;"
                       src="${imageSrc}">
                    </div>`;
                   })}
                 
               </div>\
           </div>\
       </div>
                
       <div style="width: 66.666667%; margin-left: 7%; margin-top: 1rem; padding-top: 1.5rem; padding-bottom: 2rem; display: flex; justify-content: center; align-items: center; position: relative; border: 1px dashed #3182ce; border-radius: 0.25rem; background-color: #fbfcfd;">
       <div style="width: 194px; margin-left: auto; margin-right: auto;">
         <div style="padding-left: 0.125rem;">
           <button style="font-size: 13px; font-weight: 500; line-height: 1; color: #fff; border-radius: 0.25rem; display: flex; align-items: center; justify-content: center; padding-top: 0.75rem; padding-bottom: 0.75rem; padding-left: 0.5rem; padding-right: 0.5rem; width: 100%; margin-top: 0.625rem; background-color: #3182ce;">
             <img src="" />
             <span style="margin-left: 0.75rem;">Select files to upload</span>
           </button>
         </div>
       </div>
       <input id="fileUpload" style="position: absolute; top: 0; width: 100%; height: 100%; opacity: 0; cursor: pointer;" type="file" name="upload" />
     </div>
       
       </div>`;
    document.body.appendChild(div);

    const deleteButtons = document.querySelectorAll('#deleteImage');
    deleteButtons.forEach((button) => {
      button.addEventListener('click', function () {
        deleteImageClicked(button.getAttribute('data-image-src') || '');
      });
    });

    const fileUpload = document.getElementById('fileUpload');
    fileUpload?.addEventListener('change', function (e: Event) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      fileUploadHandler(e?.target?.files[0]);
    });

    externalLibrary = document.getElementById('externalImagesLibrary');
    externalLibrary
      .querySelector('.close')
      .addEventListener('click', cancelAndClose);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    externalLibrary.addEventListener('click', function (e: any) {
      if (!e.target.matches('img')) {
        return;
      }

      const exampleOfCallbackImageObject = {
        originalName: '9091542014595406.png',
        resolution: '600 x 410 px',
        size: '169.20 kb',
        url: e.target.getAttribute('src'),
      };
      imageSelectCallback(exampleOfCallbackImageObject);
      close();
    });
  };

  const renderLibrary = function () {
    if (!externalLibrary) {
      initLibrary();
    }
    externalLibrary.style.visibility = 'visible';
  };

  return {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    openLibrary: function (onImageSelectCallback: any, onCancelCallback: any) {
      imageSelectCallback = onImageSelectCallback;
      cancelCallback = onCancelCallback;
      renderLibrary();
    },
  };
})();

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
window.ExternalPreviewPopup = (() => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let externalPreviewPopup: any;

    const close = () => {
      externalPreviewPopup.parentNode.remove();
      externalPreviewPopup = null;
    };

    const onMobileIframeLoaded = () => {
      const frameDocument =
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        document.getElementById('iframeMobile').contentDocument;
      frameDocument.documentElement.style.setProperty(
        'overflow-x',
        'visible',
        'important'
      );

      frameDocument.body.style.setProperty('overflow-y', 'scroll', 'important');
      frameDocument.body.style.setProperty(
        'visibility',
        'visible',
        'important'
      );

      const isAdaptive =
        !frameDocument.documentElement.querySelector('.gmail-fix');

      if (!isAdaptive) {
        const clientWidth = frameDocument.documentElement.clientWidth;
        const scrollWidth = frameDocument.documentElement.scrollWidth;
        const scale = (clientWidth / scrollWidth).toFixed(2);

        frameDocument.documentElement.style.setProperty(
          'height',
          '100%',
          'important'
        );
        frameDocument.documentElement.style.setProperty(
          'transform',
          `scale(${scale})`,
          'important'
        );
        frameDocument.documentElement.style.setProperty(
          'transform-origin',
          'top left',
          'important'
        );
        frameDocument.body.style.setProperty(
          'overflow-x',
          'hidden',
          'important'
        );
      }
    };

    const initPreviewPopup = () => {
      const div = document.createElement('div');

      // eslint-disable-next-line no-multi-str
      div.innerHTML = `
          <div id="externalPreviewPopup">\
              <div class="modal-container">\
                  <div class="modal-header-container">\
                      <div>\
                         <button type="button" class="close modal-close-button">\
                              <span>×</span>\
                          </button>\
                          <h4 class="modal-title">Mobile View</h4>\
                      </div>\
                  </div>\
                  <div id="content" style="padding: 15px;" class="preview-container-fluid  dark:bg-[#41464E] ">\
                     <div class="preview-row">\
                          <div class="preview-col-sm-8">\
                              <div class="esdev-desktop-device">\
                                  <div class="esdev-email-window-panel">\
                                      <div class="esdev-email-subject" style="min-height: 20px"></div>\
                                  </div>\
                                  <div class="esdev-desktop-device-screen">\
                                      <iframe id="iframeDesktop" frameborder="0" width="100%" height="642"></iframe>\
                                  </div>\
                              </div>\
                          </div>\
                          <div class="preview-col-sm-4 esdev-no-padding-left">\
                              <div class="esdev-mobile-device center-block">\
                                  <div class="esdev-mobile-device-screen">\
                                      <img src="mobile-view-top-bar.png" alt="">\
                                      <iframe id="iframeMobile" frameborder="0" width="100%" height="459"></iframe>\
                                      <img class="esdev-mail-bottom-bar" src="mobile-view-bottom-bar.png" alt="">\
                                  </div>\
                              </div>\
                          </div>\
                     </div>\
                  </div>\
              </div>\
          </div>`;
      document.body.appendChild(div);

      externalPreviewPopup = document.getElementById('externalPreviewPopup');
      externalPreviewPopup
        .querySelector('.close')
        .addEventListener('click', close);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      document
        .getElementById('iframeMobile')
        .addEventListener('load', onMobileIframeLoaded);
    };

    const openPreviewPopup = (html: string, ampHtml: string) => {
      initPreviewPopup();
      updateContent(html, ampHtml);
      externalPreviewPopup.style.visibility = 'visible';
    };

    const updateContent = function (html: string, ampHtml: string) {
      const htmlToSet = ampHtml || html;
      const iframeDesktop = document.querySelector('#iframeDesktop');
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore-start
      iframeDesktop.contentWindow.document.open('text/html', 'replace');
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore-start
      iframeDesktop.contentWindow.document.write(htmlToSet);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore-start
      iframeDesktop.contentWindow.document.close();

      const iframeMobile = document.querySelector('#iframeMobile');
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore-start
      iframeMobile.contentWindow.document.open('text/html', 'replace');
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore-start
      iframeMobile.contentWindow.document.write(htmlToSet);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore-start
      iframeMobile.contentWindow.document.close();
    };

    return {
      openPreviewPopup: openPreviewPopup,
    };
  } catch (error) {
    console.log('mobile error is : ', error);
  }
})();

const request = (
  method: string,
  url: string,
  data: Document | XMLHttpRequestBodyInit | null | undefined,
  callback: Callback
) => {
  const req = new XMLHttpRequest();
  req.onreadystatechange = () => {
    if (req.readyState === 4 && req.status === 200) {
      callback(req.responseText);
    } else if (req.readyState === 4 && req.status !== 200) {
      console.error(
        'Can not complete request. Please check you entered a valid PLUGIN_ID and SECRET_KEY values'
      );
    }
  };
  req.open(method, url, true);
  if (method !== 'GET') {
    req.setRequestHeader('content-type', 'application/json');
  }
  req.send(data);
};

export const initPlugin = (
  loadedHtml: string,
  codeChanged: () => void,
  edit: boolean,
  onLoaded: () => void
) => {
  console.log('entered first plugin : ', loadedHtml);
  const apiRequestData = {
    emailId: REACT_APP_USEREMAIL,
  };
  const script = document.createElement('script');
  script.id = 'stripoScript';
  script.type = 'text/javascript';
  script.src = 'https://plugins.stripo.email/static/latest/stripo.js';
  script.onload = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.Stripo.init({
      settingsId: 'stripoSettingsContainer',
      previewId: 'stripoPreviewContainer',
      codeEditorButtonId: 'codeEditor',
      undoButtonId: 'undoButton',
      redoButtonId: 'redoButton',
      locale: 'en',
      html: loadedHtml || html,
      customBlocks: { enabled: true },
      generatedBlock: {
        moduleSectionEnable: true,
        enabled: true,
        dataSources: Array(0),
      },
      blocks: { moveBlockAvailability: true },
      draft: {
        showAutoSaveLoader: () => {
          console.log('Auto save in process');
        },
        hideAutoSaveLoader: function (error: string) {
          console.log('Auto save completed', error);
        },
      },
      onChangeFromCodeEditor: () => {
        codeChanged();
      },
      onCodeEditorVisibilityChanged: (editor: string) => {
        console.log('visibility changed', editor);
      },
      onSaveEmail: () => {
        console.log('email saved');
      },
      onSaveTemplate: () => {
        console.log('template saved');
      },
      onDataChanged: () => {
        codeChanged();
      },
      onDragOver: () => {
        console.log('code changed draggged');
      },
      onDragStart: () => {
        console.log('code changed started');
      },
      saveEmailButtonId: 'email',
      saveTemplateButtonId: 'template',
      // css: exampleCss,
      notifications: {
        info: (message: string) =>
          console.log('info notification message : ', message),
        error: (message: string) =>
          console.error('error notification message : ', message),
        success: (message: string) =>
          console.log('success notification message : ', message),
        warn: (message: string) =>
          console.log('warn notification message : ', message),
        loader: (message: string) =>
          console.log('loader notification message : ', message),
        hide: (message: string) =>
          console.log('hide notification message : ', message),
      },
      onToggleCodeEditor: (editor: string) => {
        console.log('editor loaded: ', editor);
      },
      onTemplateLoaded: () => {
        onLoaded();
      },
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      externalImagesLibrary: window.ExternalImagesLibrary,
      viewOnly: edit,
      apiRequestData: apiRequestData,
      userFullName: 'Ryzeo User',

      // versionHistory: {
      //   changeHistoryLinkId: 'changeHistoryLink',
      //   onInitialized: (lastChangeIndoText: string) => {
      //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //     // @ts-ignore
      //     '#changeHistoryContainer'.show();
      //     console.log('history is: ', lastChangeIndoText);
      //   },
      // },
      getAuthToken: (callback: Callback) => {
        request(
          'POST',
          'https://plugins.stripo.email/api/v1/auth',
          JSON.stringify({
            pluginId: REACT_APP_PLUGIN_ID,
            secretKey: REACT_APP_SECRET_KEY,
          }),
          (data: string) => {
            callback(JSON.parse(data).token);
          }
        );
      },
    });
  };
  document.body.appendChild(script);
};

export const formatString = (value: string) => {
    if (value.includes(' ')) {
        return value.replace(/ /g, '%20');
    } else {
        return value;
    }
};

export const calculatePercentageDifference = (
    changeTo: number,
    changeFrom: number
) => {
  if(changeFrom === 0 ){
    return '-'
  }
    return ((changeTo - changeFrom) / changeFrom) * 100;
};

export const calculatePercentage = (
    firstValue: number,
    secondValue: number
) => {
    if (secondValue === 0) {
        return 0;
    }
    return Math.round((firstValue * 100) / secondValue);
};

export const calculateNumberFromPercentage = (
    initialNumber: number,
    totalNumber: number
) => {
    return Math.floor((initialNumber / 100) * totalNumber);
};

export const convertStringToWholeNumber = (number: string) => {
    if (number) {
        return Math.floor(parseFloat(number.replace(',', '')));
    } else {
        return 0;
    }
};

export const initialLetters = (inputString: string) => {
    const words = inputString.split(' ');

    if (words.length === 1) {
        return words[0].charAt(0).toUpperCase();
    } else {
        return (
            words[0].charAt(0).toUpperCase() + words[1].charAt(0).toUpperCase()
        );
    }
};

export const getFirstLetterCapital = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1);
};

export const calculateRate = (a: number, b: number) => {
  if (isNaN(a / b) || b === 0) {
    return 0;
  } else {
    return Math.round((a / b));
  }
};

export const getYAxisGraphValue = (value : string | number ) => {

  if(+value < 1000){
      return value
  } else if(+value >= 1000 && +value < 1000000){
      const calculatedValue = +value/1000;
        return   (+value % 1000 === 0 ? calculatedValue.toFixed(0) : calculatedValue.toFixed(1)) + "k"
  } else {
    const calculatedValue = +value/1000000;
    return (+value%1000000  === 0 ? (calculatedValue).toFixed(0) : calculatedValue.toFixed(1)) + "M"
  }
}

export async function copyTextToClipboard(text:string) {
  if ('clipboard' in navigator) {
    return await navigator.clipboard.writeText(text);
  } else {
    return document.execCommand('copy', true, text);
  }
}

