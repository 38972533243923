import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import LogoInitial from 'assets/Images/Login/ryzeo-logo.png';
import Logo from 'assets/Images/Common/logo-r-final.png';
import Help from 'assets/Images/svg-images/help.svg';
import SidebarItem from './SidebarItems';
import BoxImage from 'assets/Images/svg-images/box.svg';
import CloudImg from 'assets/Images/svg-images/cloud.svg';
import MailImg from 'assets/Images/svg-images/mail.svg';
import NotificationImg from 'assets/Images/svg-images/notification.svg';
import TargetImg from 'assets/Images/svg-images/target.svg';
import UnionImg from 'assets/Images/svg-images/union.svg';
import UserImg from 'assets/Images/svg-images/user.svg';
import Hierarchy from 'assets/Images/svg-images/hierarchy.svg';
import YouTubeIcon from 'assets/Images/Common/youTubeIcon.svg';

import {
  listSegments,
  home,
  integration,
  campaigns,
  emailTemplates,
  workflow,
  eventLogs,
  reports,
  filters,
  segmentDefinition,
  calendar,
  list,
  subscribersProfile,
  scripts,
  cartIntegration,
  google,
  miscellaneous,
  workflowReports,
  campaignReports,
} from 'services/constant/routes';
import ErrorBoundary from 'components/ErrorBoundary';
import { Link, useLocation } from 'react-router-dom';
import { sidebar } from './index.types';
import { ThemeContext } from 'store/ThemeContext';

interface Props {
  toggleSidebar?: () => void;
  showSidebar?: boolean;
  showFullSidebar?: boolean;
  setShowFullSidebar?: (show: boolean) => void;
}
const SideBarData: sidebar[] = [
  {
    id: '1',
    text: 'Dashboard',
    image: BoxImage,
    link: home,
  },
  {
    id: '10',
    text: 'Reports',
    image: NotificationImg,
    link: reports,
    dropdown: [
      { title: 'Workflow Reports', link: `${reports}/${workflowReports}` },
      { title: 'Campaign Report', link: `${reports}/${campaignReports}` },
    ],
  },
  {
    id: '2',
    text: 'Campaigns',
    image: UnionImg,
    link: campaigns,
    dropdown: [
      { title: 'All Campaigns', link: campaigns },
      { title: 'Calendar', link: `${campaigns}/${calendar}` },
    ],
  },
  {
    id: '3',
    text: 'Workflows',
    image: Hierarchy,
    link: workflow,
  },
  {
    id: '4',
    text: 'Email - Templates',
    image: MailImg,
    link: emailTemplates,
  },
  {
    id: '5',
    text: 'Integrations',
    image: CloudImg,
    link: integration,
    dropdown: [
      {
        title: 'Cart Integration',
        link: `${integration}/${cartIntegration}`,
      },
      { title: 'Scripts', link: `${integration}/${scripts}` },
      { title: 'Google Analytics', link: `${integration}/${google}` },
      { title: 'Miscellaneous', link: `${integration}/${miscellaneous}` },
    ],
  },
  {
    id: '6',
    text: 'Lists & Segments',
    image: TargetImg,
    link: listSegments,
    dropdown: [
      { title: 'Lists', link: `${listSegments}/${list}` },
      { title: 'Filters', link: `${listSegments}/${filters}` },
      {
        title: 'List Segmentation',
        link: `${listSegments}/${segmentDefinition}`,
      },
    ],
  },
  {
    id: '7',
    text: 'Subscribers',
    image: UserImg,
    link: subscribersProfile,
  },
  {
    id: '8',
    text: 'Pop-Ups',
    image: NotificationImg,
    link: 'https://screenpopper.com/login',
  },
  {
    id: '9',
    text: 'Event Logs',
    image: TargetImg,
    link: `${eventLogs}`,
  },

  
];
const SideBar: FC<Props> = ({
  toggleSidebar,
  showSidebar,
  showFullSidebar,
  setShowFullSidebar,
}) => {
  const { pathname, search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const [dropdownMenu, setDropdownMenu] = useState<string>('');
  const activeItemId = useMemo(() => {
    const foundItem = SideBarData.find((sidebarItem) => {
      if (!searchParams.get('profile')) {
        return sidebarItem.link !== home && pathname.includes(sidebarItem.link);
      }
    });
    return foundItem ? foundItem.id : '';
  }, [pathname]);
  useEffect(() => {
    setDropdownMenu(activeItemId);
  }, [pathname]);

  const themeCtx = useContext(ThemeContext);

  return (
    <ErrorBoundary>
      <div>
        {showSidebar && (
          <div
            className="fixed top-0 w-full h-full z-10 bg-black-200 duration-300"
            onClick={toggleSidebar}
          />
        )}
        {window.innerWidth > 1024 && (
          <div
            onClick={() =>
              setShowFullSidebar && setShowFullSidebar(!showFullSidebar)
            }
            className={`${showFullSidebar ? 'left-[240px] rotate-180' : 'left-[64px]'
              } fixed rounded-full flex items-center   w-8 h-8 z-20 top-12 bodyShadow cursor-pointer
            ${themeCtx?.theme === 'dark'
                ? 'bg-[#41464E] '
                : " sideBarBackground"
              }
            `}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="mx-auto w-6 h-6 text-white"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M8.25 4.5l7.5 7.5-7.5 7.5"
              />
            </svg>
          </div>
        )}
        <div
          id="sidebar-mob"
          className={`h-screen sm:block sm:ml-0 ${themeCtx?.theme === 'dark'
            ? 'darkSideBarBackground'
            : 'sideBarBackground '
            }  ${showFullSidebar ? 'lg:w-64 sm:w-16 w-54 overflow-auto' : 'w-[80px]'} ${showSidebar ? 'sidebarscreen:left-0 ' : 'sidebarscreen:-left-56'
            } boxshadow-gray-100  font-inter fixed scrollbar-hide top-0 z-10 dark:border-r dark:border-white ${themeCtx?.theme === 'dark'
              ? 'darkSideBarBackground'
              : "bg-[url('assets/Images/Common/sideBarBg.png')]"
            } bg-fixed `}
        >
          <div className="px-5 py-3 ">
            {showFullSidebar ?
              <div className="w-40 pl-0.5">
                <img
                  className="invert-[100%] sepia-[0%] saturate-[7427%] hue-rotate-[23deg] brightness-[0%] contrast-[118%] "
                  src={Logo}
                  alt="Logo"
                />
              </div>
              :
              <img
                className="invert-[100%] sepia-[0%] saturate-[7427%] hue-rotate-[23deg] brightness-[0%] contrast-[118%] "
                src={LogoInitial}
                alt="Logo"
              />
            }
          </div>

          <div className="flex flex-col justify-between h-sideBarContentHeight px-2 pb-6">
            <div>
              {SideBarData.map((sidebar) => (
                <SidebarItem
                  key={sidebar.id}
                  sidebarItem={sidebar}
                  showFullSidebar={showFullSidebar}
                  setDropdownMenu={setDropdownMenu}
                  dropdownMenu={dropdownMenu}
                />
              ))}
            </div>
            <div
              className="cursor-pointer flex justify-between items-center rounded 2xl:py-2.5 py-1.5 pr-2.5 pl-6 2xl:mt-3 mt-1 group hover:bg-primaryHover dark:hover:bg-white group"
            >
              <Link to="mailto:customers@ryzeo.com">
                <div className="flex items-center">
                    <img
                      className="w-5 h-5 brightness-0 invert group-hover:invert group-hover:brightness-0 dark:brightness-0 dark:invert dark:group-hover:invert-0 dark:group-hover:brightness-100"
                      src={Help}
                      alt="Help"
                    />
                  <span
                    className={`${showFullSidebar ? 'lg:block sm:hidden' : 'hidden'
                      } text-15 font-medium leading-3 text-foreground dark:group-hover:text-[#495057] dark:text-[#CED4DA] pl-3 group-hover:text-white`}
                  >
                    Help
                  </span>
                </div>
              </Link>
              {showFullSidebar && (
                <Link
                  target="_blank"
                  rel="noreferrer"
                  to="https://www.youtube.com/channel/UCrg4iOGHKmXVorzCdBg-JVw"
                  className=" block hover:scale-150 ease-in-in duration-300"
                >
                  <img
                    className="group-hover:invert-0 dark:group-hover:invert dark:group-hover:brightness-100 h-6 w-6"
                    src={YouTubeIcon}
                    alt="YouTubeIcon"
                  />
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};
export default SideBar;
