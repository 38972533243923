import Dropdown from 'components/Dropdown/Dropdown';
import ErrorBoundary from 'components/ErrorBoundary';
import { useEffect, useState } from 'react';
import { getOnloadScripts } from 'services/apiHandlers/Integration/Scripts';
import { z } from 'zod';
import OnloadScriptForm from './OnloadScriptForm';
import React from 'react';
import Wrapper from 'components/Wrapper';

const onloadSchema = z.object({
  id: z.string(),
  javascript: z.string(),
  name: z.string(),
  domains: z.union([z.array(z.string()), z.string()]),
});
export type onload = z.infer<typeof onloadSchema>;

const onloadScriptResponseSchema = z.object({
  data: z.object({ onloadScripts: z.array(onloadSchema) }),
  status: z.number(),
});
type onloadScriptResponse = z.infer<typeof onloadScriptResponseSchema>;

const OnloadScript = () => {
  const [onload, setOnload] = useState<string>('Select an item');
  const [onloads, setOnloads] = useState<Array<string>>();
  const [onloadScripts, setOnloadScripts] = useState<Array<onload>>([]);
  const [showForm, setShowForm] = useState<boolean>(false);
  const [editOnloadData, setEditOnloadData] = useState<onload>();

  const getData = async () => {
    try {
      const getOnloadScriptsResponse =
        (await getOnloadScripts()) as onloadScriptResponse;
      if (getOnloadScriptsResponse.status === 200) {
        setOnloadScripts(getOnloadScriptsResponse.data.onloadScripts);
        const transformScripts: string[] = [];
        getOnloadScriptsResponse.data.onloadScripts.map((scripts) => {
          transformScripts.push(scripts.name);
        });
        setOnloads(transformScripts);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const editOnloadHandler = (script: onload) => {
    const temp = {
      ...script,
      domains: Array.isArray(script.domains)
        ? script.domains.join('\n')
        : script.domains,
    };
    setEditOnloadData(temp);
  };

  const onloadChangeHandler = (option: string) => {
    setOnload(option);
    setShowForm(true);
    onloadScripts?.map(
      (script) => script.name === option && editOnloadHandler(script)
    );
  };

  const showFormHandler = () => {
    setEditOnloadData(undefined);
    setOnload('Select an item');
    setShowForm(true);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <ErrorBoundary>
      <div className="mb-4 lg:w-6/12 lg:mr-3 relative ">
        <Wrapper parentClass='!mt-0'>
          <div className="">
            <h3 className="p-4 border-b dark:border-[#fff] border-[#ced4da] text-base font-semibold leading-5 text-[#495057] dark:text-white">
              Onload script
            </h3>
            <div className='p-4 '>
            <div>
              <Dropdown
                onSelect={onloadChangeHandler}
                options={onloads}
                value={onload}
                id={
                  onloadScripts[onloadScripts?.length - 1] ? onloadScripts[onloadScripts?.length - 1].id : '0'
                }
              />
              <div className="w-full text-right">
                <div
                  className="text-primary underline cursor-pointer  text-xs font-medium mt-1 inline-block  "
                  onClick={showFormHandler}
                  data-pw="add-onload"
                >
                  Add onload script
                </div>
              </div>
            </div>
            {showForm && (
              <OnloadScriptForm
                getData={getData}
                setShowForm={setShowForm}
                editOnloadData={editOnloadData}
                setOnload={setOnload}
                setEditOnloadData={setEditOnloadData}
              />
            )}
          </div>
          </div>
         
        
        </Wrapper>
      </div>
    </ErrorBoundary>
  );
};
export default OnloadScript;
