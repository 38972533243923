import Wrapper from "components/Wrapper";
import React from "react";
import { Link } from "react-router-dom";
import { subscribersProfile, upload } from "services/constant/routes";

const SubscriberList = () => {
  
  return (
  
    <div className="md:w-6/12 w-full pl-6 pr-3  ">
      <Wrapper>
      <div className="p-4 ">
        <h3 className="md:text-base text-sm font-semibold leading-6 text-black-300 dark:text-white">
          Individual Entry or Import From File
        </h3>
        <p className="sm:text-sm text-13 min-h-[40px] font-normal leading-5 text-black-300 dark:text-white mt-0.5">
          Add subscribers one at a time or upload subscribers from a file.
        </p>
        <div className="mt-6">
          <Link
          
            to={`/${subscribersProfile}/${upload}`}
            className="text-13 font-medium leading-4 text-white py-2.5 px-4 rounded-md w-28 h-11 bg-primary ease-in-in duration-300 hover:bg-primary hover:scale-105"
          >
            Get Started
          </Link>
        </div>
      </div>
      </Wrapper>
    </div>
  );
};
export default SubscriberList;
