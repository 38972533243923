import { FC, useEffect, useState } from 'react';
import {
  InitialEventConditionData,
  SubscriberConditionData,
} from '../index.type';
import { getFields } from 'services/apiHandlers/List-Segments/Field';
import xmlToJSON from 'utils/xmlToJSON';
import { formatXmlJsonResponse } from 'utils/common';
import WorkflowConditions from '../WorkflowConditions';
import ModalWrapper from 'components/Workflow/ModalWrapper';
import React from 'react';

interface Props {
  onClose: () => void;
  data: SubscriberConditionData | undefined;
  onSave: (data: SubscriberConditionData) => void;
}

const onTimeChange = (value: number | undefined, unit: string | undefined) => {
  if (value) {
    const numberValue = parseInt(value.toString(), 10);
    if (unit === 'minute(s)') {
      return numberValue * 60;
    } else if (unit === 'hour(s)') {
      return numberValue * 60 * 60;
    } else if (unit === 'day(s)') {
      return numberValue * 60 * 60 * 24;
    }
  }
  return 0;
};

const SubscriberField: FC<Props> = ({ onClose, data, onSave }) => {
  const [timeUnit, setTimeUnit] = useState<Array<string>>();
  const [timeValue, setTimeValue] = useState<Array<number>>();
  const [fieldOptions, setFieldOptions] = useState<Array<string>>([]);
  const [conditions, setConditions] = useState<
    Array<InitialEventConditionData>
  >([{ attributeName: 'Select an item', value: '', _type: 'EqualsCondition' }]);
  const [formIsValid, setFormIsValid] = useState<boolean>(true);
  const getData = async () => {
    /* eslint-disable */
    const getFieldsResponse: any = await getFields();
    const data: any = xmlToJSON.parseString(getFieldsResponse.data);
    const fields = formatXmlJsonResponse(data?.fields[0]?.field);
    let tempFieldOptions: string[] = [];
    fields.map((field) => tempFieldOptions.push(field.name));
    tempFieldOptions.unshift('opt_status', 'created_at', 'subscribed_at');
    setFieldOptions(tempFieldOptions);
    /* eslint-enable */
  };

  const saveDataHandler = () => {
    const tempConditions = [...conditions];
    let valid = true;
    
    tempConditions.map((condition) => {
      if (condition.attributeName === 'Select an item') {
        valid = false;
      }
    });
    
    setFormIsValid(valid)
    
    if (valid) {
      tempConditions.map((condition, index) => {
        if (
          condition._type === 'TimestampWithinTheLastCondition' &&
          timeUnit &&
          timeValue &&
          timeUnit[index] !== ''
        ) {
          tempConditions[index].value = onTimeChange(
            timeValue[index],
            timeUnit[index]
          ).toString();
        } else if (
          condition._type === 'Anytime' ||
          condition._type === 'IsNotBlankCondition'
        ) {
          delete tempConditions[index].value;
          tempConditions[index]._type = 'IsNotBlankCondition';
        }
      });

      const data = {
        _type: 'SubscriberFieldConditionGroup',
        conditions: tempConditions,
      };

      onSave(data);
      onClose();
    }
  };

  useEffect(() => {
    if (data?.conditions) {
      setConditions(data.conditions);
    }
  }, [data?.conditions]);
  useEffect(() => {
    getData();
  }, []);

  return (
    <ModalWrapper
      onClose={onClose}
      disabled={!formIsValid || conditions.length === 0}
      onSave={saveDataHandler}
    >
      <div className="">
        <h2 className="sm:text-xl text-base sm:leading-5 leading-3 text-black-700 font-medium mb-8 dark:text-white">
          Condition
        </h2>
        <WorkflowConditions
          conditions={conditions}
          isSubscriber={true}
          fieldOptions={fieldOptions}
          isValid={formIsValid}
          onValidForm={(valid) => setFormIsValid(valid)}
          onChange={(conditions) => {
            setConditions(conditions);
          }}
          timeUnit={timeUnit}
          timeValue={timeValue}
          onSetTimeUnit={(units) => setTimeUnit(units)}
          onSetTimeValue={(values) => setTimeValue(values)}
        />
      </div>
    </ModalWrapper>
  );
};
export default SubscriberField;
