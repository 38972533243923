import { useOutsideClick } from 'app/hooks/useOutsideClick';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import { addList } from 'services/apiHandlers/Campaigns/Emails';
import { z } from 'zod';

const getListResponseDataSchema = z.object({
  id: z.number(),
  name: z.string(),
  audience_id: z.number(),
});
type Lists = z.infer<typeof getListResponseDataSchema>;

const responseMessageSchema = z.object({
  message: z.string(),
  type: z.string(),
});
type ResponseMessage = z.infer<typeof responseMessageSchema>;

const optionsSchema = z.object({ label: z.string(), value: z.string() });
type Options = z.infer<typeof optionsSchema>;

const addListResponseSchema = z.object({ status: z.number() });
type AddListResponse = z.infer<typeof addListResponseSchema>;

interface Props {
  onClose: () => void;
  lists: Lists[];
  click?:boolean
}

const AddListModal: FC<Props> = ({ onClose, lists , click }) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [options, setOptions] = useState<Array<Options>>([]);
  useOutsideClick(modalRef, () => onClose());
  const [selectedList, setSelectedList] = useState<string>('');
  const [responseMessage, setResponseMessage] = useState<ResponseMessage>({
    message: '',
    type: '',
  });
  const [isAdding , setIsAdding] = useState<boolean>(false);
  const { id } = useParams();

  const addListHandler = async () => {
    if (selectedList.length === 0) {
      setResponseMessage({
        message: 'Select a List to Continue',
        type: 'error',
      });
    } else {
      setResponseMessage({ message: '', type: '' });
      setIsAdding(true);
      try {
        const addListData = {
          list_id: parseInt(selectedList),
          report_type: click ? 'unique_clickers': 'unique_viewers',
          list_name: '',
          owner_id: '',
          blast_id: parseInt(id || '0'),
        };

        const addListResponse = (await addList(
          selectedList,
          addListData
        )) as AddListResponse;
        if (addListResponse.status === 200) {
          setResponseMessage({
            message: 'Added to the Selected List Successfully',
            type: 'success',
          });
        }
        setIsAdding(false);
      } catch (error) {
        setResponseMessage({
            message: 'Something Went Wrong While Adding List',
            type: 'error',
          });
        console.log('error is : ', error);
      }
    }
  };

  useEffect(() => {
    const listOptions = lists?.map((list) => {
      return { label: list.name, value: list.id.toString() };
    });
    setOptions(listOptions);
  }, [lists]);

  return (
    <div className="flex bg-white rounded-md items-center w-[40%] mx-auto mt-40 relative">
      <div className="w-full"  ref={modalRef}>
        <p className="text-base p-4 text-[#495057] font-medium  dark:text-[#CED4DA]p-4 border-b border-[#e9ebec] ">
          Add to List
        </p>
        <p className="text-base p-4 text-[#495057] font-medium  dark:text-[#CED4DA]p-4 border-b border-[#e9ebec] ">To which list should subscribers for this report be added?</p>
        <div className="p-4">
          <Select
            options={options}
            onChange={(option) => setSelectedList(option?.value || '')}
          />
          <button
            onClick={() => addListHandler()}
            className="bg-primary text-white font-medium text-sm rounded p-2 mt-4"
          >
            {isAdding? "Adding...": "Add to List"}
          </button>
          <button
            onClick={onClose}
            className="ml-80 bg-primary text-white font-medium text-sm rounded p-2 mt-4"
          >
            {responseMessage.message.length>0 && responseMessage.type==='success'? "OK" : "Cancel"}
          </button>
          {responseMessage.message.length > 0 && (
            <div
              className={`px-6 py-3 ${
                responseMessage.type === 'success'
                  ? 'bg-green-100'
                  : ' bg-[#f7e8e7]'
              }  rounded mt-4`}
            >
              <p
                className={`text-sm ${
                  responseMessage.type === 'success'
                    ? 'text-green-600'
                    : 'text-[#CB5A5A]'
                } font-normal leading-4 `}
              >
                {responseMessage.message}
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default AddListModal;
