import React, { FC, useContext } from 'react';
import { CampaignContext } from 'store/CampaignContext';
import Wrapper from 'components/Wrapper';
import GetFormattedItem from './GetFormattedItem';

const SenderSubject: FC = () => {
    const campaignCtx = useContext(CampaignContext);


    return (
        <>
            <Wrapper>
                <>
                    <p className="text-[#495057] dark:text-white font-semibold text-lg py-2 px-4 border-b">
                        Reply to
                    </p>
                    <div className="p-4">
                        <GetFormattedItem item={{ title: 'Name', value: campaignCtx?.campaignData?.reply_to_name || '' }} itemStyle='text-[#212529]' />
                        <GetFormattedItem item={{ title: 'Email', value: campaignCtx?.campaignData?.reply_to_email_address || '' }} itemStyle='text-[#212529]' />
                    </div>
                </>
            </Wrapper>
            <Wrapper>
                <>
                    <p className="text-[#495057] dark:text-white font-semibold text-lg py-2 px-4 border-b">
                        Subject
                    </p>
                    <div className="p-4">
                        <GetFormattedItem item={{ title: 'Name', value: campaignCtx?.campaignData?.subject || '' }} itemStyle='text-[#212529]' />
                    </div>
                </>
            </Wrapper>
        </>

    );
};
export default SenderSubject;