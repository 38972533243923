import { useOutsideClick } from 'app/hooks/useOutsideClick';
import React, { FC, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Select from 'react-select';
import {
  checkExportStatus,
  downloadReport,
  startExport,
} from 'services/apiHandlers/Campaigns/Emails';
import { z } from 'zod';

const fieldsSchema = z.object({
  ['allow-other']: z.boolean(),
  ['data-type']: z.string(),
  id: z.number(),
  ['include-blank']: z.boolean(),
  name: z.string(),
  slug: z.string(),
  'used-in-filter': z.string(),
});

type Fields = z.infer<typeof fieldsSchema>;

const optionsSchema = z.object({ label: z.string(), value: z.string() });
type Options = z.infer<typeof optionsSchema>;

const startExportResponseSchema = z.object({
  data: z.object({ blastExportId: z.number() }),
  status: z.number(),
});
type StartExportResponse = z.infer<typeof startExportResponseSchema>;

const checkStatusResponseSchema = z.object({
  data: z.object({ status: z.string(), downloadURI: z.string() }),
  status: z.number(),
});
type CheckStatusResponse = z.infer<typeof checkStatusResponseSchema>;

interface Props {
  onClose: () => void;
  fields: Fields[];
  click?: boolean;
}

const UniqueViewerDropdown: FC<Props> = ({ onClose, fields, click }) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [dropdownOptions, setDropdownOptions] = useState<Array<Options>>([]);
  const [selectedFields, setSelectedFields] = useState<Array<string>>([
    'Email Address',
    'Last Viewed At',
    click ? 'Total Clicks' : 'Total Views',
  ]);
  useOutsideClick(modalRef, () => onClose());
  const { id } = useParams();
  const [checkStatusId, setCheckStatusId] = useState<number | null>(null);
  const [downloadReportId, setDownloadReportId] = useState<number | null>(null);

  const downloadReportFile = () => {
    downloadReport(downloadReportId || 0);
  };

  const checkStatusHandlerHandler = async () => {
    try {
      const checkStatusResponse = (await checkExportStatus(
        checkStatusId || 0
      )) as CheckStatusResponse;
      if (checkStatusResponse.status === 200) {
        if (checkStatusResponse.data.status === 'completed') {
          setDownloadReportId(checkStatusId);
          setCheckStatusId(null);
        }
      }
    } catch (error) {
      console.log('error is : ', error);
      setCheckStatusId(null);
    }
  };

  const startExportHandler = async () => {
    try {
      const tempSelectedRecipients = selectedFields.slice(
        3,
        selectedFields.length
      );

      const receipients = fields?.filter((field) =>
        tempSelectedRecipients.includes(field.slug)
      );

      const recipientIds = receipients?.map((recipient) => recipient.id);

      const exportData = {
        export: { seperator: ',', title_row: '1' },
        export_type: click ? 'unique_clickers' : 'unique_viewers',
        [`field_options_${click ? 'unique_clickers' : 'unique_viewers'}_${id}`]:
          'Add a field...',
        ...(recipientIds.length > 0
          ? {
              fields: recipientIds,
            }
          : {}),
      };
      const startExportResponse = (await startExport(
        id || '',
        exportData
      )) as StartExportResponse;
      if (startExportResponse.status === 201) {
        setCheckStatusId(startExportResponse.data.blastExportId);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
  };

  useEffect(() => {
    if (checkStatusId) {
      const interval = setInterval(() => checkStatusHandlerHandler(), 3000);
      return () => {
        clearInterval(interval);
      };
    }
  }, [checkStatusId]);

  useEffect(() => {
    console.log('fields are : ', fields);
    const tempOptions = fields
      ?.map((field) => {
        return { label: field.slug, value: field.slug };
      })
      .sort((a, b) => a.label.localeCompare(b.label));

    setDropdownOptions(tempOptions);
  }, [fields]);
  return (
    <div className="flex bg-white rounded-md items-center w-[40%] mx-auto mt-40 relative">
      {/* <Wrapper parentClass="" childClass=""> */}
      <div className="w-full" ref={modalRef}>
        <p className="text-base text-[#495057] font-bold p-4  dark:text-[#CED4DA]p-4 border-b border-[#e9ebec] ">
          Export to Spreedsheet
        </p>
        <div className="p-4">
          <Select
            options={dropdownOptions}
            isMulti
            onChange={(options: readonly Options[]) => {
              setCheckStatusId(null);
              setDownloadReportId(null);
              const selectedOptions = options?.map((option) => option.label);
              setSelectedFields(
                selectedFields.slice(0, 3).concat(selectedOptions)
              );
            }}
          />
          <div>
            {selectedFields.map((field) => (
              <div key={field}>
                <li>{field}</li>
              </div>
            ))}
          </div>
          <button
            onClick={() => {
              if (downloadReportId) {
                downloadReportFile();
              } else {
                startExportHandler();
              }
            }}
            className="bg-primary text-white font-medium text-sm rounded p-2 mt-4"
          >
            {checkStatusId
              ? 'Loading...'
              : downloadReportId
              ? 'Download File'
              : 'Download'}
          </button>
          <button
            onClick={onClose}
            className="ml-80 bg-primary text-white font-medium text-sm rounded p-2 mt-4"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
export default UniqueViewerDropdown;
