import React, { useEffect, useState, useRef, useContext } from 'react';
import axios, { AxiosError } from 'axios';
import LoginForm from 'components/Login/LoginForm';
import AuthModal from 'components/Alert/AuthModal';
import { useOutsideClick } from 'app/hooks/useOutsideClick';
import { UserContext } from 'store/UserContext';

type Props = {
  children: JSX.Element;
};

const AuthenticationError: React.FC<Props> = ({ children }: Props) => {
  const userCtx = useContext(UserContext);
  const [showError, setShowError] = useState<boolean>(false);
  const outsideRef = useRef<HTMLDivElement>(null);

  useOutsideClick(outsideRef, () => {
    setShowError(false);
    userCtx?.setShowAuthError(false);
  });

  useEffect(() => {
    axios.interceptors.response.use(response => response, async (error: AxiosError) => {
      console.log('Error in Authentication is: ', error)
      if (error.response?.status === 401) {
        setShowError(true);
      }
    })
  }, [])

  return (
    <div>
      {children}
      <AuthModal isOpen={showError &&  userCtx?.showAuthError}>
        <div ref={outsideRef} className="max-w-[1140px] px-3 h-[100vh] flex justify-center items-center mx-auto ">
          <div className="relative rounded w-full max-w-[28.063rem] bg-white p-6 border-2 border-solid border-[#E9EBEC] mx-auto my-6">
          <button
              className="absolute right-2.5 top-2 bg-red-500 hover:bg-red-600 text-white h-6 w-6 flex justify-center items-center rounded-full "
              onClick={()=>{setShowError(false),userCtx?.setShowAuthError(false)}}
            >
              x
            </button>
            <LoginForm setShowError={setShowError} />
          </div>
        </div>
      </AuthModal>
    </div>
  )
}

export default AuthenticationError;