import React from "react"
const Loader = () => {
  return (
    <div className="flex justify-center items-center ">
      <div className="animate-spin h-5 w-5 rounded-full border-4 border-gray-600 border-t-4 border-t-blue-600 ..."></div>
    </div>
  );
};

export default Loader;
 