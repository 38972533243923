import editBlue from 'assets/Images/Subscribers/edit-blue.png';
import { Form } from './SubscribersForm';
import { deleteField } from 'services/apiHandlers/List-Segments/Field';
import { useState } from 'react';
import DeleteModal from 'components/Alert/DeleteModal';
import { z } from 'zod';
import { Toastify } from 'App';
import React from 'react';
import { Link } from 'react-router-dom';
import { edit } from 'services/constant/routes';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';

interface Props {
  data: Form;
  getData: () => void;
  index: number;
  first: boolean;
}

const ResponseSchema = z.object({ status: z.number() });
type Response = z.infer<typeof ResponseSchema>;

const FieldData: React.FC<Props> = ({ data, getData, index, first }: Props) => {
  const [deleteId, setDeleteId] = useState<number>(0);
  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);
  const [tooltipOpen,setTooltipOpen] = useState<boolean>(false);
  
  const deleteHandler = async (id: number) => {
    try {
      const deleteFieldResponse = (await deleteField(id)) as Response;
      if (deleteFieldResponse.status === 200) {
        Toastify('Field Deleted Successfully', 'success', 'fielddata1');
        getData();
      }
    } catch (error) {
      Toastify('Deleting Field Failed', 'error', 'fielddata2');
    }
  };

  return (
    <div className="flex sm:flex-row flex-col sm:justify-between items-center md:px-6 px-3 py-1.5 border-b-2 boder-gray-800 border-dotted dark:border-[#fff]">
      <div className="flex items-baseline sm:w-auto w-full">
        <h3
          data-pw={`${first ? 'first-field-name' : ''}`}
          className="sm:text-base text-sm sm:leading-5 leading-3 text-[#212529] dark:text-white font-medium mr-2"
        >
          {data.name}
        </h3>
        <span className="text-xs leading-4 text-gray-500 dark:text-white">{`(${data['data-type']})`}</span>
      </div>
      <div className="flex justify-end items-center sm:w-auto w-full">
        {(data['data-type'] === 'Select' ||
          data['data-type'] === 'Radio' ||
          data['data-type'] === 'Checkbox') && (
          <Link
            to={`${edit}/${data.id}`}
            className="text-sm font-medium text-primary underline mr-2"
          >
            Edit Options
          </Link>
        )}
        <Link
          type="button"
          className="text-sm font-medium leading-5 p-2 text-primary inline-block flex items-center sm:ml-auto"
          id={first ? data.id.toString() : ''}
          to={`${edit}/${data.id}`}
        >
          <img className="mr-1" src={editBlue} alt="Edit" />
          Edit
        </Link>
        {data['used-in-filter'] ? (
          <div className='flex items-center'>
            <div className="flex items-center mr-2 my-1.5 hover:text-[#3575d5]">
              Locked
            </div>

            <div className="inline-block relative">
              <div
                
                className="w-4 h-4  rounded-full bg-gray-600 flex items-center justify-center"
                id={"tooltip"}
                onMouseEnter={() => {
                  setTooltipOpen(true)
                }}
                onMouseLeave={() => {
                  setTooltipOpen(false)
                }}
              > ? </div>
              <Tooltip
                style={{
                  pointerEvents: 'auto',
                  border: '1px solid #9e9e9e',
                  zIndex: '1',
                  opacity: '1',
                  backgroundColor: "white"
                }}
                isOpen={tooltipOpen}
                anchorId={'tooltip'}
                classNameArrow="-bottom-[5px] border border-gray-500 border-t-0 border-l-0"
              >
                <div className="text-[#495057] dark:text-[#CED4DA]  dark:text-white w-60">
                  <div className="flex gap-1 items-center">
                    <h1 className="font-bold text-lg py-2">
                      Used by a Trigger
                    </h1>
                    <span>
                      Filter cannot be deleted because it is being used by the
                      filter:
                      <span className="font-bold">
                        {data['used-in-filter']}
                      </span>
                    </span>
                  </div>
                </div>
              </Tooltip>
            </div>
          </div>
        ) : (
          <button
            type="button"
            onClick={() => {
              setDeleteId(data.id);
              setShowDeleteAlert(true);
            }}
            className="text-sm font-medium leading-5 p-2 text-red-500 inline-block flex ml-3"
            data-pw={`${index !== 0 ? 'delete-field' : ''} `}
            id={`${index !== 0 ? data.id : ''} `}
          >
            Delete
          </button>
        )}
      </div>
      {showDeleteAlert && (
        <div
          onClick={() => setShowDeleteAlert(false)}
          className="fixed left-0 top-0 w-full h-full z-10 bg-black-200 duration-300"
        >
          <DeleteModal
            onDelete={() => deleteHandler(deleteId)}
            onCancel={() => setShowDeleteAlert(false)}
          />
        </div>
      )}
    </div>
  );
};
export default FieldData;
