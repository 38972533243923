import { FC, useContext, useEffect, useState } from 'react';
import { WebhookActionData } from '../index.type';
import { WorkflowContext } from 'store/WorkflowContext';
import { sendTestRequest } from 'services/apiHandlers/Workflows/Workflow';
import { z } from 'zod';
import { useFormik, FormikValues } from 'formik';
import * as Yup from 'yup';
import ModalWrapper from 'components/Workflow/ModalWrapper';
import React from 'react';
import Loader from 'components/Loader';

interface Props {
  onClose: () => void;
  data: WebhookActionData | undefined;
  onSave: (data: WebhookActionData) => void;
}

const responseSchema = z.object({ status: z.number() });
type Response = z.infer<typeof responseSchema>;

const WebhookAction: FC<Props> = ({ onClose, data, onSave }) => {
  const workflowCtx = useContext(WorkflowContext);
  const [requestStatus, setRequestStatus] = useState<string>('');
  const [formIsValid, setFormIsValid] = useState<boolean>(true);
  const [sendingTest, setSendingTest] = useState<boolean>(false);

  const formik: FormikValues = useFormik({
    enableReinitialize: true,
    initialValues: {
      url: '',
    },
    validationSchema: Yup.object({
      url: Yup.string().required('Required'),
    }),
    onSubmit: (values) => {
      onSave({ ...values, _type: 'WebhookTriggerAction' });
      onClose();
    },
  });

  const sendTestRequestHandler = async () => {
    setSendingTest(true);
    let event = '';
    if (
      workflowCtx?.workflow?.initialConditionGroup &&
      'eventName' in workflowCtx.workflow.initialConditionGroup
    ) {
      event = workflowCtx.workflow.initialConditionGroup.eventName || '';
    }
    const data = {
      eventName: event,
      url: formik.values.url,
      workflowName: workflowCtx?.workflow?.name || '',
    };
    try {
      const sendTestResponse = (await sendTestRequest(data)) as Response;
      if (sendTestResponse.status === 200) {
        setRequestStatus('pass');
      }
    } catch (error) {
      setRequestStatus('fail');
    }
    setSendingTest(false);
  };

  useEffect(() => {
    if (formik.isValid) {
      setFormIsValid(formik.isValid);
    }
  }, [formik.isValid, formik.values]);

  useEffect(() => {
    if (data) {
      formik.setValues(data);
    }
  }, []);
  return (
    <form onSubmit={formik.handleSubmit}>
      <ModalWrapper
        onClose={onClose}
        disabled={!formIsValid}
        onSave={() => {
          if (!formIsValid || !formik.dirty) {
            setFormIsValid(false);
          }
        }}
      >
        <>
          <div className="bg-white dark:bg-[#41464E]">
            <h2 className="sm:text-xl text-base sm:leading-5 leading-3 text-black-700 font-medium mb-8 dark:text-white">
              Action
            </h2>
            <div>
              <label className="text-xs font-semibold leading-3 block tracking-wide text-gray-500 uppercase mb-3 dark:text-white">
                Webhook URL
              </label>
              <div className="flex items-center gap-3">
                <input
                  className="text-13 font-medium leading-3 text-[#495057] dark:text-[#CED4DA]   py-3 px-4 w-4/6 rounded-md border border-[#ced4da] focus:outline-none focus:border-primary dark:bg-[#41464E] dark:border-[#fff] h-[38px]"
                  type="text"
                  name="url"
                  value={formik.values.url}
                  onChange={formik.handleChange}
                />
                <button
                  type="button"
                  className={`${
                    !formik.isValid || !formik.dirty
                      ? ' bg-gray-200 text-[#495057] dark:text-[#212529] '
                      : sendingTest
                      ? 'text-white bg-primary'
                      : requestStatus === 'pass'
                      ? 'bg-green-200 text-white'
                      : requestStatus === 'fail'
                      ? 'bg-red-100 text-red-800'
                      : 'text-white bg-primary'
                  } flex items-center text-13  leading-5 rounded-md px-4 py-2.5  h-[38px]`}
                  onClick={() => sendTestRequestHandler()}
                  disabled={!formik.isValid || !formik.dirty}
                >
                  {sendingTest && (
                    <div className="">
                      <Loader />
                    </div>
                  )}
                  {sendingTest
                    ? 'Sending Test Request'
                    : requestStatus === 'pass'
                    ? 'Test Successfull'
                    : requestStatus === 'fail'
                    ? '!Failed , Try Again'
                    : 'Send Test Request'}
                </button>
              </div>
            </div>
          </div>
          {!formIsValid && (
            <div className="px-6 py-3 bg-[#f7e8e7] rounded mt-4">
              <p className="text-sm font-normal leading-4 text-[#CB5A5A]">
                Please correct the errors above
              </p>
            </div>
          )}
        </>
      </ModalWrapper>
    </form>
  );
};
export default WebhookAction;
