import { ChartDataset } from 'chart.js';
import PieGraph from 'components/Graph/PieGraph';
import React, { FC, useState, useEffect } from 'react';
import { Workflows } from './Types';
import { getReportQouta } from 'services/apiHandlers/Dashboard/Reports';
import { z } from 'zod';
import CustomSkeleton from 'components/Skeleton/Skeleton';
import { useLocation } from 'react-router-dom';
// import Wrapper from 'components/Wrapper';
// import moment from 'moment';
const qoutaDataSchema = z.object({
  unlimited_quota: z.boolean().nullable(),
  quota: z.number(),
  used: z.number(),
  remaining: z.number(),
});
const getQoutaEmailResponseSchema = z.object({
  data: qoutaDataSchema,
  status: z.number(),
});
type QoutaEmailResponse = z.infer<typeof getQoutaEmailResponseSchema>;
const defaultPieData = [
  {
    data: [],
    backgroundColor: [],
  },
];
interface Props {
  workflows?: Workflows
}
const SentEmails: FC<Props> = ({ workflows }) => {
  const [sentMails, setSentMails] = useState<number>(0);
  const [pieLabels, setPieLabels] = useState<Array<string>>([]);
  const [pieData, setPieData] = useState<ChartDataset<'doughnut', number[]>[]>();
  const [loading, setLoading] = useState<boolean>(true)
  const [totalQouta, setTotalQouta] = useState<number>(0);
  const [limitIsUnlimited, setLimitIsUnLimited] = useState<boolean>(false);
  const location = useLocation();


  const getSentEmailData = async () => {
    setLoading(true)
    try {
      const getQoutaEmailResponse =
        (await getReportQouta()) as QoutaEmailResponse;
      if (getQoutaEmailResponse.status === 200) {

        setSentMails(getQoutaEmailResponse.data.used);
        setTotalQouta(getQoutaEmailResponse.data.quota)



        const tempWorkflows = workflows;
        const totalWorkflows = tempWorkflows && tempWorkflows.reduce(
          (accumulator, workflow) => {
            return accumulator + workflow.sends;
          },
          0
        );
        const totalCampaigns =
          getQoutaEmailResponse.data.used - (totalWorkflows || 0);
          const totalSent = (totalWorkflows || 0) + (totalCampaigns || 0);

          
         


        if (getQoutaEmailResponse.data.unlimited_quota) {
         
            setLimitIsUnLimited(true);

        } else {

          setPieLabels([
            'Sent',
            'Available',
          ]);
          setPieData([
            {
              data: [
                totalSent,
                getQoutaEmailResponse.data.remaining,
              ],
              backgroundColor: ['#84bfe3','#005EB4'],
              // borderWidth: 10,
            },
          ]);
        }
      }
    } catch (error) {
      console.log('error is : ', error);
    }
    setLoading(false)
  };
  useEffect(() => {
    getSentEmailData();
  }, [workflows,]);
  return (
    <div className="">
        <div className="2xl:h-full hover:rounded-lg hover:shadow-lg shadow-md hover:shadow-gray-200 dark:shadow-none dark:bg-[#41464E] dark:shadow-lg dark:hover:shadow-2xl" >
          <div className='cardHeader dark:border-[#fff]'>
            <h4 className="text-base text-darkText font-medium dark:text-[#878A99]">
              Sent Emails
            </h4>
          </div>
          <div className="2xl:pt-3 2xl:pb-3 xl:px-7 lg:px-5 px-3.5 2xl:mt-0 lg:mt-14 mt-5">
            {totalQouta ?
              <div className="flex items-end mb-3">
                <h2 className="2xl:text-3xl text-xl 2xl:leading-10 dark:text-white font-medium text-lightParagraphColor">
                  {sentMails.toLocaleString()} of {totalQouta.toLocaleString()}
                </h2>
              </div>:(null)}
            {(loading) ? (<CustomSkeleton columns={5} />) : (
            <div>
              { !limitIsUnlimited ? (
              <div className={pieData ? '' : 'hidden'}>
                <PieGraph
                  width={'100%'}
                  height={'100%'}
                  labels={pieLabels}
                  datasets={pieData || defaultPieData}
                />
              </div>):(


             
            <div>  
            {location.pathname === '/' ?
              <div style={{display:'flex' ,paddingBottom: '2rem', justifyContent:'space-between', flexDirection: 'column'}} >
              
              <div className= " mb-4 h-20 w-5/6 px-2 2xl:h-full hover:rounded-lg hover:shadow-lg shadow-md hover:shadow-gray-200 dark:shadow-none bg-white dark:bg-transparent" >
                <div className='flex items-center h-full'>
                <p className='text-darkText font-normal dark:text-white'>Available Emails:</p>
                <p className='ml-3 text-sm text-darkText dark:text-[#CED4DA]'>Unlimited</p>
                </div>
              </div>

              <div className="h-20 w-5/6 px-2 2xl:h-full hover:rounded-lg hover:shadow-lg shadow-md hover:shadow-gray-200 dark:shadow-none bg-white dark:bg-transparent ">
                <div className='flex items-center h-full'>
                <p className='text-darkText font-normal dark:text-white'>Sent Emails:</p>
                <p className=' ml-3 text-sm text-darkText dark:text-[#CED4DA]'>{sentMails}</p>
                </div>
              </div>
              </div>: 

              <div style={{display:'flex' ,paddingBottom: '2rem', justifyContent:'space-between'}} >
              
              <div className= " mb-4 h-20 w-1/2 px-2 2xl:h-full hover:rounded-lg hover:shadow-lg shadow-md hover:shadow-gray-200 dark:shadow-none bg-white dark:bg-transparent" >
                <div className='flex items-center h-full'>
                <p className='text-darkText font-normal dark:text-white'>Available Emails:</p>
                <p className='ml-3 text-sm text-darkText dark:text-[#CED4DA]'>Unlimited</p>
                </div>
              </div>

              <div className="h-20 w-1/3 px-2 2xl:h-full hover:rounded-lg hover:shadow-lg shadow-md hover:shadow-gray-200 dark:shadow-none bg-white dark:bg-transparent ">
                <div className='flex items-center h-full'>
                <p className='text-darkText font-normal dark:text-white'>Sent Emails:</p>
                <p className=' ml-3 text-sm text-darkText dark:text-[#CED4DA]'>{sentMails}</p>
                </div>
              </div>
              </div>
              
              
              
              
              
              
              
              }
              </div>




              )
              }

            </div>)}
          </div>
        </div>
    </div>
  );
};
export default SentEmails;