import ErrorBoundary from 'components/ErrorBoundary';
import { Outlet } from 'react-router-dom';
import React from 'react';

const SegmentDefinitionHeader = () => {
  return (
    <ErrorBoundary>
      <div className="flex justify-between items-center px-5 border-b dark:border-[#fff] border-[#e9ebec] py-[18px]">
        <h1 className="text-base text-[#495057] font-medium  dark:text-[#CED4DA]">
          All Lists & Segments
        </h1>
      </div>
      <Outlet />
    </ErrorBoundary>
  );
};
export default SegmentDefinitionHeader;
