import Table from 'components/Table';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { OrderReportData } from './index.type';

interface Props {
  orderReports: OrderReportData[];
  loading: boolean;
}

const OrderReports: FC<Props> = ({ orderReports, loading }) => {
  const [reportsData, setReportsData] = useState<Array<OrderReportData>>([]);

  const columns: TableColumn<OrderReportData>[] = useMemo(() => {
    const dateColumns = orderReports[0]?.dates?.map((report) => ({
      name: report.date,
      cell: (row: OrderReportData) => {
        const metricData = orderReports.find(
          (item) => item.metric === row.metric
        );
        const dateData = metricData?.dates.find(
          (date) => date.date === report.date
        );
        const value = dateData ? dateData.total : '';
        const monthData = typeof value === "number" ? value : parseInt(value)
        return (
          <div className="flex items-center pl-3">
            <div>
              <div className="flex items-center justify-center text-white rounded-lg">
                <p className="text-sm font-medium text-darkText dark:text-[#878A99] truncate w-[110px]">
                  {row.metric === 'Orders' ? Math.round(monthData).toLocaleString() : row.metric === 'Average Revenue' ? `$${Math.round(monthData).toLocaleString()}` : `$${Math.round(monthData).toLocaleString()}`}
                </p>
              </div>
            </div>
          </div>
        );
      },
    }));

    return [
      {
        name: <div className="pl-3">Metric</div>,
        cell: (row) => (
          <div className="flex items-center pl-3">
            <div>
              <div className="flex items-center justify-center text-white rounded-lg">
                <p className="text-sm font-medium text-darkText dark:text-[#878A99]  truncate ">
                  {row.metric}
                </p>
              </div>
            </div>
          </div>
        ),
      },
      ...dateColumns || [],
    ];
  }, [orderReports]);

  useEffect(() => {
    setReportsData(orderReports);
  }, [orderReports]);


  return (
    <div className="grid grid-cols-1 gap-4 font-inter overflow-hidden rounded-xl allWorkFlows">
      <Table
        className="rounded-t-2xl scrollbar-hide overflow-auto p-0"
        data={reportsData}
        columns={columns}
        progressPending={loading}
        pagination={false}
        // persistTableHead={false}
        fixedHeader={false}
      />
    </div>

  );
};
export default OrderReports;
