import React, { FC, useRef, useState } from 'react';
import 'react-date-range/dist/styles.css';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/theme/default.css';
import { z } from 'zod';
import moment from 'moment';
import { useOutsideClick } from 'app/hooks/useOutsideClick';
import CalendarIcon from 'assets/Images/Dashboard/calender.png';

const DateRangeSchema = z.object({
  startDate: z.date().nullable(),
  endDate: z.date().nullable(),
});
type DateRanges = z.infer<typeof DateRangeSchema>;

interface Props {
  dateRange: DateRanges;
  onChangeRange: (date: DateRanges) => void;
}

const CustomDateRange: FC<Props> = ({ dateRange, onChangeRange }) => {
  const [showDateRange, setShowDateRange] = useState<boolean>(false);
  const [range, setRange] = useState<DateRanges>(dateRange);
  const dateRef = useRef<HTMLDivElement>(null);

  useOutsideClick(dateRef, () => {
    setShowDateRange(false);
    setRange(dateRange);
  });


  
  return (
    <div
      ref={dateRef}
      className="flex items-center ml-6 z-49 relative"
    >
      {showDateRange && (
        <div className="relative h-full">
          <DateRange
            ranges={[
              {
                startDate: range.startDate || moment().toDate(),
                endDate: range.endDate || moment().toDate(),
              },
            ]}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            onChange={(date: any) => {
              if (
                moment(date.range1.endDate).isAfter(
                  moment(date.range1.startDate)
                )
              ) {
                setRange({
                  startDate: date.range1.startDate,
                  endDate: date.range1.endDate,
                });
              } else {
                setRange({
                  ...dateRange,
                  startDate: date.range1.startDate,
                });
              }
            }}
            retainEndDateOnFirstSelection={true}
            className="reactDateRange absolute z-[999] shadow-md h-[380px] w-[288px]  top-8"
          />
        </div>
      )}
      <div 
        onClick={() => {

          setShowDateRange((prev) => !prev);
        }}

        className="flex items-center relative, border border-[#ced4da] dark:border-[#fff] rounded"
      >
        <div className="datePicker flex min-w-[160px] justify-between items-center w-full px-4 py-2 text-13 leading-3 dark:bg-transparent  text-[#212529] dark:text-white sm:h-[38px]">
          <p>{moment(range.startDate).format('MMM, DD , YYYY')}</p>
          <img
            src={CalendarIcon}
            alt="CalendarIcon"
            className="dark:invert dark:brightness-0"
          />
        </div>
        <p className="px-2 text-sm font-semibold text-darkText dark:text-[#fff]">To</p>
        <div className="datePicker flex min-w-[160px] justify-between items-center w-full px-4 py-2 text-13 leading-3 dark:bg-transparent  text-[#212529] dark:text-white sm:h-[38px]">
          <p> {moment(range.endDate).format('MMM, DD , YYYY')}</p>
          <img
            src={CalendarIcon}
            alt="CalendarIcon"
            className="dark:invert dark:brightness-0"
          />

        </div>

      </div>
      {showDateRange && 
      <button
        className="z-[99999] px-2.5 py-[5px] absolute sm:text-sm text-xs font-medium leading-5 flex items-center rounded-md sm:pl-4 sm:pr-3.5 font-inter text-white bg-primary ease-in-in duration-300 hover:scale-105 mt-[760px] right-2.5 cursor-pointer"
        onClick={() => {
          onChangeRange(range);
          setShowDateRange(false);
        }}
      >
        Apply
      </button>}
    </div>
  );
};
export default CustomDateRange;
