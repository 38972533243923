import WorkflowTriggerData from './WorkflowTriggerData';
import HeaderInformation from './HeaderInformation';
import React from "react"

const Header = () => {
  return (
    <>
      <HeaderInformation />
      <WorkflowTriggerData />
    </>
  );
};
export default Header;
