import React, { useEffect, useState, useMemo, useContext } from 'react';
import EditBlue from 'assets/Images/Common/edit-blue.png';
import Form from 'components/DashboardReports/Body/Common/Form';
import Table from 'components/Table';
import {
  exportFilter,
  getFilterSubscribers,
} from 'services/apiHandlers/List-Segments/Filter';
import { z } from 'zod';
import { TableColumn } from 'react-data-table-component';
import momentTimezone from 'moment-timezone';
import { Link, useParams } from 'react-router-dom';
import { filters, listSegments, subscribersProfile, view } from 'services/constant/routes';
import { UserContext } from 'store/UserContext';
import BreadcrumbsWrapper from 'components/Wrapper/BreadcrumbsWrapper';

const subscriberDataSchema = z.object({
  person: z.object({
    created_at: z.string(),
    deleted_at: z.string().nullable(),
    email_address: z.string(),
    email_content_format: z.string(),
    id: z.number(),
    ip_address: z.string().nullable(),
    last_blast_click_created_at: z.string().nullable(),
    last_blast_view_created_at: z.string(),
    opt_status: z.string(),
    owner_name: z.string(),
    owner_id: z.number(),
    person_database_id: z.number(),
    soft_bounce_count: z.number(),
    source_id: z.string(),
    source_type: z.string(),
    subscribed_at: z.string(),
    tracking_hash: z.string(),
    unsubscribed_at: z.string(),
    updated_at: z.string(),
    user_agent: z.string().nullable(),
  }),
});

const getFilterResponseSchema = z.object({
  data: z.object({
    count: z.number(),
    subscriber: z.array(subscriberDataSchema),
  }),
  status: z.number(),
});

type GetFilterResponse = z.infer<typeof getFilterResponseSchema>;
type SubscriberData = z.infer<typeof subscriberDataSchema>;

const FilterSubscribers = () => {
  const [subscribersData, setSubscribersData] = useState<Array<SubscriberData>>(
    []
  );
  const [filteredSubscribers, setFilteredSubscribers] = useState<
    Array<SubscriberData>
  >([]);
  const [searchQuery, setSearchQuery] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(true);
  const [totalRecords, setTotalRecords] = useState<number>();
  const userCtx = useContext(UserContext);
  const { id } = useParams();
  const filterId = parseInt(id || '');

  const getSubscribers = async (page: number) => {
    setLoading(true);
    try {
      const getFilterSubscribersResponse = (await getFilterSubscribers(
        filterId,
        page
      )) as GetFilterResponse;
      if (getFilterSubscribersResponse.status === 200) {
        setSubscribersData(getFilterSubscribersResponse.data.subscriber);
        setFilteredSubscribers(getFilterSubscribersResponse.data.subscriber);
        setTotalRecords(getFilterSubscribersResponse.data.count);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
    setLoading(false);
  };

  const handlePageChange = (page: number) => {
    getSubscribers(page);
  };

  const columns: TableColumn<SubscriberData>[] = useMemo(
    () => [
      {
        name: <div className='px-6'>Email-Address</div>,
        cell: (row) => {
          return (
            <Link
              className="flex items-center overflow-auto px-6 min-w-[269px] w-[269px] py-2"
              to={`/${subscribersProfile}/${view}/${row.person.id.toString()}`}
            >
              {row.person.email_address}
            </Link>
          );
        },
      },
      {
        name: 'Status',
        cell: (row) => {
          return (
            <div className="py-0.5 px-2.5 text-[#209d7d] rounded bg-[#d5f7eb]">
              {row.person.opt_status}
            </div>
          );
        },
      },
      {
        name: 'Subscriber Date',
        cell: (row) => {
          return (
            <div>
              <p className="text-13 text-[#495057] dark:text-[#CED4DA]  dark:text-white py-4 inline-block">
                {momentTimezone
                  .tz(
                    row.person.subscribed_at,
                    userCtx?.usersData?.timeZone || ''
                  )
                  .format('MMMM D, YYYY [at] hh:mm A')}
              </p>
            </div>
          );
        },
      },
      {
        name: 'Owner',
        cell: (row) => {
          return (
            <div>
              <p className="text-13 text-[#495057] dark:text-[#CED4DA]  dark:text-white py-4">
                {row.person.owner_name}
              </p>
            </div>
          );
        },
        omit: !userCtx?.currentUserData?.franchise_enabled,
      },
    ],
    []
  );

  useEffect(() => {
    getSubscribers(1);
  }, []);

  useEffect(() => {
    const filteredSubscribers =
      searchQuery.length > 0
        ? subscribersData.filter((subscriber) =>
          subscriber.person.email_address
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        )
        : subscribersData;
    setFilteredSubscribers(filteredSubscribers);
  }, [searchQuery]);

  useEffect(() => {
    const searchInterval = setTimeout(() => {
      if (userCtx?.filteredValue) {
        const tempSubscibers = subscribersData.filter((subscriber) =>
          subscriber.person.email_address
            .toLowerCase()
            .includes(userCtx.filteredValue.toLowerCase())
        );
        setFilteredSubscribers(tempSubscibers);
      } else {
        setFilteredSubscribers(subscribersData);
      }
    }, 1000);

    return () => {
      clearInterval(searchInterval);
    };
  }, [userCtx?.filteredValue, subscribersData]);

  return (
    <div>
      <BreadcrumbsWrapper />
      <div className="text-right mt-2">
        <Link
          to={`${listSegments}/${filters}/${filterId}`}
          className="text-sm font-medium leading-5 p-2 text-primary inline-block flex items-center sm:ml-auto dark:text-white"
        >
          <img
            className="mr-1 dark:invert dark:brightness-0"
            src={EditBlue}
            alt="Edit"
          />
          Edit Filter
        </Link>
      </div>
      <Form
        heading="All Subscribers"
        setSearchQuery={(query: string) => setSearchQuery(query)}
        hideFilter={true}
        onSelectExport={() => exportFilter(filterId)}
      />
      <div className="relative px-6">
        <div className="after:left-[-3px] after:bottom-[-2px] after:border-b-[#e9ebec] after:border-l-[#e9ebec] after:border-t-transparent after:border-r-transparent after:content-[''] after:h-[13px] after:w-[13px] after:absolute after:border-[3px] before:right-[-2px] before:top-[-3px] before:border-b-transparent before:border-l-transparent before:border-t-[#e9ebec] before:border-r-[#e9ebec] before:content-[''] before:h-[13px] before:w-[13px] before:absolute before:border-[3px]  border border-[rgba(0, 0, 0, 0.125)] dark:shadow-none   dark:bg-[#05192f]   rounded mb-16">

          <div className="grid grid-cols-1 gap-4 font-inter overflow-hidden rounded-xl allWorkFlows">
            <Table
              className="rounded-t-2xl scrollbar-hide overflow-auto"
              data={filteredSubscribers}
              columns={columns}
              progressPending={loading}
              paginationServer
              paginationTotalRows={totalRecords}
              onChangePage={handlePageChange}
            />
          </div>
        </div>
      </div>

    </div>
  );
};
export default FilterSubscribers;
