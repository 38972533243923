import Skeleton from 'components/Skeleton/Skeleton';
import { useFormik, FormikValues } from 'formik';
import { useState, FC, Dispatch, useEffect, useRef } from 'react';
import DeleteModal from 'components/Alert/DeleteModal';
import CodeMirror from 'react-codemirror';
import {
  createOnloadScript,
  updateOnloadScript,
  deleteOnloadScript,
} from 'services/apiHandlers/Integration/Scripts';
import { onload } from './OnloadScript';
import * as Yup from 'yup';
import { z } from 'zod';
import { Toastify } from 'App';
import 'codemirror/lib/codemirror.css';
import ErrorBoundary from 'components/ErrorBoundary';
import React from 'react';

const onloadScriptResponseSchema = z.object({ status: z.number() });
type onloadScriptResponse = z.infer<typeof onloadScriptResponseSchema>;

interface Props {
  getData: () => void;
  setShowForm: Dispatch<React.SetStateAction<boolean>>;
  editOnloadData: onload | undefined;
  setOnload: Dispatch<React.SetStateAction<string>>;
  setEditOnloadData: Dispatch<React.SetStateAction<onload | undefined>>;
}

const OnloadScriptForm: FC<Props> = ({
  getData,
  setShowForm,
  editOnloadData,
  setOnload,
  setEditOnloadData,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [code, setCode] = useState<string>('');
  const [deleteId, setDeleteId] = useState<string>('');
  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);
  const mirrorRef = useRef();

  const resetForm = () => {
    setEditOnloadData(undefined);
    setOnload('Select an item');
    setLoading(false);
    getData();
    setShowForm(false);
  };

  const formik: FormikValues = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      domains: '',
      javascript: '',
      id: 0,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      let tempJava: string[] = [];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      tempJava = mirrorRef.current.codeMirror.display.maxLine.parent.lines.map(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (line) => line.text
      );
      setLoading(true);
      const domains = values.domains.split('\n');
      try {
        if (editOnloadData) {
          const data = {
            onloadScript: {
              name: formik.values.name,
              domains: domains,
              javascript: tempJava.join('\n'),
              id: formik.values.id,
            },
          };
          const updateOnloadScriptResponse = (await updateOnloadScript(
            data
          )) as onloadScriptResponse;
          if (updateOnloadScriptResponse.status === 200) {
            Toastify('Onload Updated Successfully', 'success', 'onloadScript1');
            resetForm();
          }
        } else {
          const data = {
            onloadScript: {
              name: formik.values.name,
              domains: domains,
              javascript: tempJava.join('\n'),
            },
          };
          const createOnloadResponse = (await createOnloadScript(
            data
          )) as onloadScriptResponse;
          if (createOnloadResponse.status === 201) {
            Toastify('Onload Created Successfully', 'success', 'onloadScript2');
            resetForm();
          }
        }
      } catch (error) {
        resetForm();
        Toastify('Onload Changes Failed', 'error', 'onloadScript3');
      }
    },
  });

  const deleteOnloadHandler = async (id: string) => {
    try {
      setLoading(true);
      const deleteOnloadResponse = (await deleteOnloadScript(
        id
      )) as onloadScriptResponse;
      if (deleteOnloadResponse.status === 200) {
        Toastify('Onload Deleted Successfully', 'success', 'onloadScript4');
        resetForm();
      }
    } catch (error) {
      Toastify('Onload Deletion Failed', 'error', 'onloadScript5');
      resetForm();
    }
  };

  useEffect(() => {
    if (editOnloadData) {
      formik.setValues(editOnloadData);
      setCode(editOnloadData.javascript);
    } else {
      formik.setValues(formik.initialValues);
      setCode('');
    }
  }, [editOnloadData]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    mirrorRef.current.getCodeMirror().setValue(code);
  }, [code]);

  return (
    <ErrorBoundary>
      <div className=" dark:border-[#fff]">
        {loading ? (
          <Skeleton />
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <div data-pw="onload-name" className="-mt-1">
              <label className="text-sm font-medium leading-3 tracking-wide text-[#212529] dark:text-white dark:text-white">
                Name
              </label>
              <input
                className="w-full placeholder:font-normal focus:border-primary dark:focus:border-white focus:outline-none py-2 px-4 text-13 leading-3 border border-[#ced4da] placeholder:text-gray-500 dark:placeholder:text-white dark:border-[#fff]  dark:bg-[#41464E]  rounded mt-2 text-[#495057] dark:text-[#CED4DA]  dark:text-white dark:text-white "
                type="text"
                value={formik.values.name}
                name="name"
                placeholder="Name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                data-pw="onload-name-input"
              />
              {!formik.isValid && formik.touched.name ? (
                <div
                  data-pw="onload-name-required"
                  className="text-xs leading-4 text-red-400 mt-1.5"
                >
                  {formik.errors.name}
                </div>
              ) : null}
            </div>
            <div className="mt-4">
              <label className="text-sm font-medium leading-3 tracking-wide text-[#212529] dark:text-white dark:text-white">
                Domain (separated by new lines)
              </label>
              <textarea
                rows={6}
                name="domains"
                className="w-full focus:border-primary dark:focus:border-white focus:outline-none py-2 px-4 text-13 leading-4 border border-[#ced4da] dark:border-[#fff]  dark:bg-[#41464E]  rounded mt-2 text-[#495057] dark:text-[#CED4DA]  dark:text-white dark:text-white"
                value={formik.values.domains}
                onChange={formik.handleChange}
              ></textarea>
            </div>
            <div className="mt-4">
              <label className="text-sm font-medium leading-3 tracking-wide text-[#212529] dark:text-white dark:text-white">
                Javascript
              </label>

              <CodeMirror
                ref={mirrorRef}
                autoFocus={true}
                mode="javascript"
                theme="monokai"
                options={{
                  lineNumbers: true,
                  indentWithTabs: true,
                  tabSize: 2,
                }}
                className="w-full focus:border-primary dark:focus:border-white focus:outline-none  text-13 font-medium leading-4 border border-[#ced4da] dark:border-[#fff]  dark:bg-[#41464E]  rounded mt-2 text-[#495057] dark:text-[#CED4DA]  dark:text-white dark:text-white"
              />
            </div>
            <div className="w-full mt-4 flex justify-end ">
              {editOnloadData && editOnloadData?.name.length > 1 && (
                <button
                  type="button"
                  className="text-13 mx-4 font-medium leading-5 min-w-[74px] rounded py-2 px-4 text-white bg-[#f34a4a] dark:hover:bg-white dark:hover:text-[#495057] dark:text-[#CED4DA] "
                  onClick={() => {
                    setShowDeleteAlert(true);
                    setDeleteId(editOnloadData.id);
                  }}
                >
                  Delete
                </button>
              )}

              <button
                type="button"
                className="text-center sm:text-13 text-xs py-2.5 px-4 h-11 flex justify-center items-center w-28 font-medium leading-5 mr-2  rounded-md bg-[#f3f6f9] hover:bg-[#cfd1d4] transition-bg text-[#212529]"
                onClick={resetForm}
              >
                Cancel
              </button>
              <button
                data-pw="submit-onload"
                type="submit"
                className="text-13 ml-3 font-medium leading-5 min-w-[74px] rounded py-2 px-4 text-white bg-primary dark:hover:bg-white dark:hover:text-[#495057] dark:text-[#CED4DA]  ease-in duration-300 hover:bg-primaryHover hover:scale-105"
              >
                Save
              </button>
            </div>
            {showDeleteAlert && (
              <div
                onClick={() => setShowDeleteAlert(false)}
                className="fixed left-0 top-0 w-full h-full z-10 bg-black-200 duration-300"
              >
                <DeleteModal
                  onDelete={() => deleteOnloadHandler(deleteId)}
                  onCancel={() => setShowDeleteAlert(false)}
                />
              </div>
            )}
          </form>
        )}
      </div>
    </ErrorBoundary>
  );
};
export default OnloadScriptForm;
