import { FC, useRef } from 'react';
import Close from 'assets/Images/Workflow/close-Icon.png';
import { useOutsideClick } from 'app/hooks/useOutsideClick';
import React from 'react';

interface Props {
  onClose: () => void;
  hasChildActionNodes: boolean;
  onLastNodeDelete: () => void;
  onDeleteAllChildNodes: () => void;
  onDeleteSingleNode: () => void;
  hasBranchNodes: boolean;
  onDeleteYesNode: () => void;
  onDeleteNoNode: () => void;
}
const DeleteModal: FC<Props> = ({
  onClose,
  hasChildActionNodes,
  onLastNodeDelete,
  onDeleteAllChildNodes,
  onDeleteSingleNode,
  hasBranchNodes,
  onDeleteYesNode,
  onDeleteNoNode,
}) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useOutsideClick(modalRef, () => {
    onClose()
  });
  
  return (
    <div ref={modalRef}>
      <div className="fixed top-[25%] left-0 right-0 w-full max-w-xl mx-auto py-4 z-50 bg-white rounded-lg">
        <img
          src={Close}
          onClick={() => onClose()}
          className="absolute top-0 right-0 mt-2 mr-2 bg-transparent border-none cursor-pointer"
        />
        <div className="px-4">
          <div className="text-lg font-semibold leading-6 text-black-700 mb-1">
            Confirm Delete
          </div>
        </div>
        <div className="border-t my-6 border-gray-200"></div>
        <div className="px-4">
          <div className="text-sm font-normal leading-5 text-gray-700 mb-5">
            Are you sure you want to delete this node? This cannot be undone.
          </div>
          {hasChildActionNodes ? (
            <div className="flex flex-wrap justify-between items-center">
              <div
                className="bg-red-600 mb-2 border-b py-2 px-3 text-13 font-medium text-white rounded w-[49%] text-center cursor-pointer"
                onClick={() => onDeleteSingleNode()}
              >
                Delete just this node
              </div>
              <div
                className="bg-red-600 mb-2 border-b py-2 px-3 text-13 font-medium text-white rounded w-[49%] text-center cursor-pointer"
                onClick={() => onDeleteAllChildNodes()}
              >
                Delete this and all child nodes
              </div>
            </div>
          ) : hasBranchNodes ? (
            <div className="flex flex-wrap justify-between items-center">
              <div
                className="bg-red-600 mb-2 border-b py-2 px-3 text-13 font-medium text-white rounded w-[49%] text-center"
                onClick={() => onDeleteYesNode()}
              >
                Delete this and the Yes Branch
              </div>
              <div
                className="bg-red-600 mb-2 border-b py-2 px-3 text-13 font-medium text-white rounded w-[49%] text-center"
                onClick={() => onDeleteNoNode()}
              >
                Delete this and the No Branch
              </div>
              <div
                className="bg-red-600 mb-2 border-b py-2 px-3 text-13 font-medium text-white rounded w-[49%] text-center"
                onClick={() => onDeleteAllChildNodes()}
              >
                Delete this and both branches
              </div>
            </div>
          ) : (
            <div className="flex flex-wrap justify-between items-center">
              <div
                onClick={() => onLastNodeDelete()}
                className="bg-red-600 mb-2 border-b py-2 px-3 text-13 font-medium text-white rounded w-[49%] text-center"
              >
                Delete this node
              </div>
            </div>
          )}
        </div>
        <div className="border-t my-6 border-gray-200"></div>
        <div className="px-4 text-right">
          <button
            onClick={() => onClose()}
            className="px-4 py-2 text-13 font-medium text-[#495057] dark:text-[#CED4DA]  bg-white border border-gray-600 rounded"
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};
export default DeleteModal;
