import Table from 'components/Table';
import moment from 'moment-timezone';
import React, { FC, useContext, useEffect, useMemo, useRef } from 'react';
import EventLogAccordian from './EventLogAccordian';
import DatePickerCalendar from 'components/Datepicker';
import Dropdown from 'components/Dropdown/SearchDropdown';
import Copy from 'assets/Images/Workflow/copy.png';
import { TableColumn } from 'react-data-table-component';
import { UserContext } from 'store/UserContext';
import { z } from 'zod';
import Loader from 'components/Loader';
import NoData from './NoData';
import DownloadIcon from 'assets/Images/Common/downloadIcon.svg';
import DownloadIconGrey from 'assets/Images/Common/GreyDownloadIcon.svg';
import { ThemeContext } from 'store/ThemeContext';
import { ModifiedEvents } from 'staticdata/EventLogs/Data';
import Wrapper from 'components/Wrapper';
import { useLocation } from 'react-router-dom';

const resultDataSchema = z.object({
    accountId: z.number(),
    attributes: z.record(z.string()),
    id: z.string(),
    name: z.string(),
    timestamp: z.number(),
});

const filterDataSchema = z.object({
    startDate: z.string(),
    endDate: z.string(),
    searchValue: z.string(),
    selectedEvent: z.string(),
});

type FilterData = z.infer<typeof filterDataSchema>;
type ResultData = z.infer<typeof resultDataSchema>;

interface Props {
    allEvents: ResultData[];
    loading: boolean;
    showMore: () => void;
    showMoreButton: boolean;
    moreLoading: boolean;
    filterData: FilterData;
    selectedEvent: string;
    events: string[];
    onSelect: (value: string) => void;
    onDownloadLog: () => void;
    setShowEventAttributes: (attribute: boolean) => void;
    showEventAttributes: boolean;
    startDate: string;
    endDate: string;
    onStartDateChange: (date: string) => void;
    onEndDateChange: (date: string) => void;
    onApplyFilter: () => void;
    setSelectedDropdownEvent: (event: string) => void;
    selectedDropdownEvent: string;
}



const AllEvents: FC<Props> = ({
    selectedEvent,
    onSelect,
    events,
    allEvents,
    onDownloadLog,
    loading,
    showMore,
    showMoreButton,
    moreLoading,
    filterData,
    setShowEventAttributes,
    showEventAttributes,
    startDate,
    endDate,
    onStartDateChange,
    onEndDateChange,
    onApplyFilter,
    setSelectedDropdownEvent,
    selectedDropdownEvent,
    }) => {
    const userCtx = useContext(UserContext);
    const themeCtx = useContext(ThemeContext);
    const scrollRef = useRef<HTMLDivElement>(null);
    const {pathname} = useLocation();
    
    
    
    const handleEndDateChange = (date: string) => {
        
        onEndDateChange(date);
         // Trigger filter application when the end date is selected
       // onApplyFilter();
        
    };

    useEffect(()=>{onApplyFilter();},[endDate, userCtx?.filteredValue])

   

    const customStyles = {
        table: {
            style: {
                background: 'unset !important',
            },
        },
        rows: {
            style: {
                color: '#212529',
                fontSize: '14px',
                borderBottom: 'unset !important',
                backgroundColor:
                    themeCtx?.theme === 'dark'
                        ? 'unset !important'
                        : 'unset !important',

                height: '52px',
                '&:hover': {
                    backgroundColor:
                        themeCtx?.theme === 'dark'
                            ? 'rgba(255, 255, 255, 0.07) !important'
                            : 'rgb(243,246,249) !important',
                },
            },
        },

        cells: {
            style: {
                display: 'flex',
                paddingRight: '4px',
                paddingLeft: '4px',
            },
        },
        headRow: {
            style: {
                borderColor: themeCtx?.theme === 'dark' ? '#fff' : '#e9ebec',
                backgroundColor: themeCtx?.theme === 'dark' ? '#41464E' : '#FAFBFF',
                zIndex: '999999',
                color: themeCtx?.theme === 'dark' ? 'white' : '#212529',
            },
        },

        headCells: {
            style: {
                color: themeCtx?.theme === 'dark' ? '#FFFFF' : '#878A99',
                fontSize: '14px',
                height: '44px',
                fontWeight: 600,
                display: 'flex',
                paddingRight: '4px',
                paddingLeft: '4px',
                background: 'transparent',
            },
        },
        expander: {
            style: {
                color: 'red',
                fontSize: '20px',
                background: "red"
            },
        },
    };

    const columns: TableColumn<ResultData>[] = useMemo(
        () => [
            {
                name: 'Subscriber',
                cell: (row) => {
                    return (
                        <td className="dark:text-[#CED4DA] text-[#495057] font-medium">
                            <div>
                                {typeof row.attributes.personIdentifier ===
                                    'string'
                                    ? row.attributes.personIdentifier
                                    : ''}
                            </div>
                        </td>
                    );
                },
                minWidth: '250px'
            },
            {
                name: 'Event',
                cell: (row) => {
                    return (
                        <td
                            className={`${row?.name.includes('Purchase')
                                ? ' bg-[#0c68bbb5]'
                                : 'bg-[#477bd2d1]'
                                }  px-2 rounded text-white`}
                        >
                            <div>{ModifiedEvents[row.name] ? ModifiedEvents[row.name].name : row.name}</div>
                        </td>
                    );
                },
                minWidth: '250px'
            },
            {
                name: 'Date',
                cell: (row) => {
                    return (
                        <td className="whitespace-nowrap py-3  pr-3 text-13 sm:align-top align-middle text-textColor dark:text-[#CED4DA] md:w-[400px] md:min-w-[400px] w-[200px] min-w-[200px] flex flex-wrap items-center ">
                            <div className="flex items-center">
                                {moment
                                    .tz(
                                        row.timestamp,
                                        userCtx?.usersData?.timeZone || ''
                                    )
                                    .format('MMMM DD, YYYY hh:mm:ss A')}
                            </div>
                        </td>
                    );
                },
                minWidth: '250px'
            },
        ],
        []
    );


    const scrollPage = ()=>{
    if(scrollRef.current)
    scrollRef.current.scrollIntoView({behavior:"instant" as ScrollBehavior, block:"start"})
     }
    useEffect(()=>{
            if(pathname === "/event-logs" && allEvents.length !== 0)
            scrollPage();
        }, [allEvents])



    return (
        <div className="md:px-4 px-3 pb-8 pt-4 font-inter h-full  min-h-[74vh]">
        <Wrapper>
            <div ref={scrollRef} className="relative pb-3">
                {/* <div className="after:left-[-3px] after:bottom-[-2px] after:border-b-[#e9ebec] after:border-l-[#e9ebec] after:border-t-transparent after:border-r-transparent after:content-[''] after:h-[13px] after:w-[13px] after:absolute after:border-[3px] before:right-[-2px] before:top-[-3px] before:border-b-transparent before:border-l-transparent before:border-t-[#e9ebec] before:border-r-[#e9ebec] before:content-[''] before:h-[13px] before:w-[13px] before:absolute before:border-[3px]  border border-[rgba(0, 0, 0, 0.125)] rounded dark:shadow-none dark:border-[#fff] mb-16"> */}
                    <div className="flex md:flex-row flex-col justify-between items-center px-5 border-b dark:border-white border-[#e9ebec] py-[18px]">
                        <p className="text-base text-[#495057] font-medium  dark:text-[#CED4DA]">
                            Event Logs
                        </p>
                        <div className="flex md:flex-row flex-col md:mt-0 mt-2">
                            <div className="relative">
                                <div className="flex items-center">
                                    {selectedEvent !== 'All Events' &&
                                        <>
                                            <div className="mt-1.5 ml-3">
                                                <button
                                                    disabled={allEvents.length < 1}
                                                    onClick={onDownloadLog}
                                                    className=""
                                                >
                                                    <img
                                                        src={allEvents.length < 1 ? DownloadIconGrey : DownloadIcon}
                                                        className={`h-5 w-5`}
                                                        alt="DownloadIcon"
                                                    />
                                                </button>
                                            </div>
                                            <div onClick={() => { setShowEventAttributes(true) }}
                                                className="cursor-pointer pb-0.5  items-center relative border-b border-primary  mx-3 flex justify-center"
                                            >
                                                <p className="text-xs text-primary ">
                                                    Mail Merge
                                                </p>
                                                <img
                                                    className="w-4 ml-1 ImagePrimaryFilter"
                                                    src={Copy}
                                                />
                                            </div>
                                        </>
                                    }
                                    <div className="min-w-[212px]">
                                        <Dropdown
                                            value={selectedDropdownEvent}
                                            options={events}
                                            onSelect={onSelect}
                                            setSelectedDropdownEvent={setSelectedDropdownEvent}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="flex md:ml-3 justify-between items-center md:flex-row flex-col md:mt-0 mt-2">
                                <div className="">
                                    <DatePickerCalendar
                                        date={startDate}
                                        onDateChange={onStartDateChange}
                                    />
                                </div>
                                <p className="text-sm text-[#212529] dark:text-[#fff] font-medium mx-3">
                                    To
                                </p>
                                <div className="">
                                    <DatePickerCalendar
                                        date={endDate}
                                        onDateChange={handleEndDateChange}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>

                    {!showEventAttributes && (
                        <Table   
                            //className='scrollbar-hide'
                            data={allEvents}
                            columns={columns}
                            pagination={false}
                            expandableRows
                            expandableRowsComponent={EventLogAccordian}
                            progressPending={loading}
                            expandOnRowClicked
                            customStyles={customStyles}
                            noDataComponent={<NoData filterData={filterData}
                             />}
                            responsive={false}
                            
                        />
                    )}
                    {showMoreButton && !showEventAttributes && !loading && (
                        <button
                            disabled={loading || moreLoading}
                            onClick={showMore}
                            className={`${loading || moreLoading
                                ? 'border border-[#ced4da] bg-gray-200 text-[#495057] dark:text-[#CED4DA] '
                                : ' bg-primary text-white '
                                } fs-13 flex items-center mx-auto rounded-md py-2 sm:pl-4 px-2 sm:pr-3.5 font-inter  my-5 ease-in duration-300 `}
                        >
                            {moreLoading ? (
                                <>
                                    <Loader />
                                    Loading More..
                                </>
                            ) : ('Show More')}
                        </button>
                    )}
                {/* </div> */}
                
            </div>
            </Wrapper>
        </div>
    );
};
export default AllEvents;
