import { FC, useContext, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Calendar from 'assets/Images/Workflow/calendar.png';
import styled from 'styled-components';
import { useOutsideClick } from 'app/hooks/useOutsideClick';
import React from 'react';
import moment from 'moment';
import { ThemeContext } from 'store/ThemeContext';

interface Props {
  date: string;
  onDateChange: (date: string) => void;
  showTime?: boolean;
}



const DatePickerCalendar: FC<Props> = ({ date, onDateChange, showTime }) => {
  
  const themeCtx = useContext(ThemeContext)

  const StyledWrapper = styled.div`
  .react-datepicker {
    border: 0px;
    width : 100%;
    z-index: 99999999;
    position: relative;
  }

  .react-datepicker__today-button {
    background-color: #3575d5;
    color: white;
    border: 0px;
    font-size: 13px;
    font-weight: 500;
  }

  .react-datepicker-wrapper {
    font-size: 13px;
    font-weight: 500;
    line-height: 13px;
    color: #bdbdbd;
  }

  .react-datepicker-wrapper input:focus {
    outline: none;
  }
  .react-datepicker-popper {
    z-index: 99;
  }
  .datePicker input {
    color: ${themeCtx?.theme === "dark" ? "white" : "#212529"}
  }
`;

  const [showCalendar, setShowCalendar] = useState(false);
  const calendarRef = useRef<HTMLDivElement>(null);

  const toggleCalendar = () => {
    setShowCalendar((prev) => !prev);
  };

  useOutsideClick(calendarRef, () => setShowCalendar(false));

  return (
    <StyledWrapper>
      <div className="datePicker flex w-full px-4 py-2 text-13 leading-3 dark:bg-transparent border border-[#ced4da] dark:border-[#fff] rounded text-[#212529] dark:text-white sm:h-[38px]" ref={calendarRef}>
        <DatePicker
          open={showCalendar}
          selected={
            date === '' || !moment(date).isValid()
              ? null
              : new Date(date)   
          }
          onChange={(date: string) => {
            setShowCalendar(false);
            onDateChange(date);
          }}
          todayButton={<div>Today</div>}
          dateFormat={`EEEE, MMMM d, yyyy ${showTime ? 'h:mm aa' : ''}`}
          showTimeSelect={showTime}
          timeFormat="HH:mm aa"
          timeCaption="Select Time"
          
        >
          <div className="flex justify-between items-center py-2">
            <div
              className="text-sm font-medium leading-4 text-[#8c8c8c] dark:text-white"
              onClick={() => onDateChange('')}
            >
              Clear
            </div>
            <div
              className="text-sm font-medium leading-4 text-primary"
              onClick={() => setShowCalendar(false)}
            >
              Close
            </div>
          </div>
        </DatePicker>
        <div
          onClick={() => {
            toggleCalendar();
          }}
          className="w-5 h-5 top-1.5 right-[9px] z-10"
        >
          <img
            className="dark:invert dark:brightness-0"
            src={Calendar}
            alt="Calendar"
          />
        </div>
      </div>
    </StyledWrapper>
  );
};

export default DatePickerCalendar;
