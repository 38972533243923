import { TableColumn } from 'react-data-table-component';
import { FC, useMemo } from 'react';
import StoreTable from '../Common/StoreTable';
import Table from 'components/Table';
import { tableSort } from 'utils/common';
import { Workflows, campaigns, SingleWorkflow, Singlecampaign } from './Types';
import React from 'react';
import { Link, useSearchParams } from 'react-router-dom';
import { campaignReport, workflowReport } from 'services/constant/routes';

interface Props {
  campaigns: campaigns;
  workflows: Workflows;
  loading: boolean
}
const AnalyticsTable: FC<Props> = ({ loading }) => {
  
  const [searchParams, setSearchParams] = useSearchParams();
  
  const workflowSort = (rowA: SingleWorkflow, rowB: SingleWorkflow) => {
    return tableSort(rowA.workflowName, rowB.workflowName);
  };

  const campaignSort = (rowA: Singlecampaign, rowB: Singlecampaign) => {
    return tableSort(rowA.campaignName, rowB.campaignName);
  };

  const workflowRevenueSort = (rowA: SingleWorkflow, rowB: SingleWorkflow) => {
    return tableSort(rowA.monetaryValue, rowB.monetaryValue);
  };

  const campaignRevenueSort = (rowA: Singlecampaign, rowB: Singlecampaign) => {
    return tableSort(rowA.monetaryValue, rowB.monetaryValue);
  };

  const showWorkflowDetails = (id: string) => {
    searchParams.set('analytics', 'true');
    searchParams.set('workflowId', id);
    setSearchParams(searchParams);
  };

  const workflowColumns: TableColumn<SingleWorkflow>[] = useMemo(
    () => [
      {
        name: 'Workflow Name',
        cell: (row) => {
          return (
            <Link
            to={`${workflowReport}/${row.workflowId}?analytics=true`}
              onClick={() => {
                showWorkflowDetails(row.workflowId);
              }}
              className="dark:text-white cursor-pointer"
            >
              <div>{row.workflowName}</div>
            </Link>
          );
        },
        sortable: true,
        sortFunction: workflowSort,
      },
      {
        name: 'Revenue',
        right: true,
        cell: (row) => {
          return (
            <div className="dark:text-white justify-end w-full text-right ">
              <div>{Math.floor(row.monetaryValue)}</div>
            </div>
          );
        },
        sortFunction: workflowRevenueSort,
      },
    ],
    []
  );
  const campaignColumns: TableColumn<Singlecampaign>[] = useMemo(
    () => [
      {
        name: 'campaign Name',
        cell: (row) => {
          return (
            <Link
              to={`${campaignReport}/${row.id}`}
              className="dark:text-white cursor-pointer"
            >
              <div>{row.campaignName}</div>
            </Link>
          );
        },
        sortFunction: campaignSort,
        sortable: true,
      },
      {
        name: 'Revenue',
        right: true,
        cell: (row) => {
          return (
            <div className="dark:text-white justify-end w-full text-right">
              <div>{Math.floor(row.monetaryValue)}</div>
            </div>
          );
        },
        sortFunction: campaignRevenueSort,
      },
    ],
    []
  );

  return (
    // <StyledWrapper>
      <div className="flex md:flex-row flex-col font-inter mt-4 table1">
        <div className="md:w-6/12 w-full md:pr-2.5">
          <StoreTable
            heading1="Top Performing Worklflows"
            heading2="View Reports"
            type="workflows"
          >
            <Table
              className="scrollbar-hide border-none"
              data={[]}
              columns={workflowColumns}
              pagination={false}
              progressPending={loading}
            />
          </StoreTable>
        </div>
        <div>
          <StoreTable
            heading1="Top Performing campaigns"
            heading2="View Reports"
            type="campaigns"
          >
            <Table
              className="scrollbar-hide border-none"
              data={[]}
              columns={campaignColumns}
              pagination={false}
              progressPending={loading}
            />
          </StoreTable>
        </div>
      </div>
    // </StyledWrapper>
  );
};
export default AnalyticsTable;
