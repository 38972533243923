import Table from 'components/Table';
import React, { FC, useMemo } from 'react';
import { TableColumn } from 'react-data-table-component';
import { Link } from 'react-router-dom';
import { workflowReport } from 'services/constant/routes';
import { WorkflowConversion} from './index.type';
interface Props {
  conversions: WorkflowConversion[];
  loading: boolean
}

const WorkflowConversions: FC<Props> = ({ conversions , loading }) => {
  const columns: TableColumn<WorkflowConversion>[] = useMemo(
    () => [
      {
        name: <div className="pl-3">Workflow</div>,
        cell: (row) => {
          return (
            <>
              <div className="flex items-center pl-3">
                <div>
                  <Link
                    to={`${workflowReport}/${row.workflowId}`}
                    className="flex items-center justify-center text-white rounded-lg"
                  >
                    <p className="text-sm font-medium text-darkText dark:text-[#878A99]  truncate ">
                      {row.workflowName}
                    </p>
                  </Link>
                </div>
              </div>
            </>
          );
        },
      },
      {
        name: 'Conversions',
        cell: (row) => {
          return (
            <div>
              <p className="text-13 text-[#495057] dark:text-[#CED4DA] inline-block">
                {row.conversions}
              </p>
            </div>
          );
        },
  
      },
      {
        name: 'Value',
        cell: (row) => {
          return (
            <div>
              <p className="text-13 text-[#495057] dark:text-[#CED4DA] inline-block">
                ${(row.monetaryValue === 0 ? '0' : Math.round(row.monetaryValue).toLocaleString())}
              </p>
            </div>
          );
        },
      
      },
    ],
    []
  );
   
   
  return (
      <div className="grid grid-cols-1 gap-4 font-inter  overflow-hidden rounded-xl allWorkFlows">
        <Table
          className="rounded-t-2xl scrollbar-hide overflow-auto p-0"
          data={conversions}
          columns={columns}
         progressPending={loading}
          pagination
          paginationPerPage={20}
        />
      </div>
  );
};
export default WorkflowConversions;
