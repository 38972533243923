import { FC, useEffect, useState } from 'react';
import { ScriptActionData } from '../index.type';
import { getWorkflowScripts } from 'services/apiHandlers/Integration/Scripts';
import Dropdown from 'components/Dropdown/SearchDropdown';
import { z } from 'zod';
import ModalWrapper from 'components/Workflow/ModalWrapper';
import React from 'react';
interface Props {
  onClose: () => void;
  data: ScriptActionData | undefined;
  onSave: (data: ScriptActionData) => void;
}

const workflowSchema = z.object({
  id: z.string(),
  javascript: z.string(),
  name: z.string(),
  domains: z.union([z.array(z.string()), z.string()]),
});
type workflow = z.infer<typeof workflowSchema>;

const responseSchema = z.object({
  data: z.object({ workflowScripts: z.array(workflowSchema) }),
  status: z.number(),
});
type Response = z.infer<typeof responseSchema>;

const ScriptAction: FC<Props> = ({ onClose, data, onSave }) => {
  const [workflows, setWorkflows] = useState<Array<workflow>>([]);
  const [scripts, setScripts] = useState<Array<string>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [formIsValid, setFormIsValid] = useState<boolean>(true);
  const [selectedScript, setSelectedScript] =
    useState<string>('Select an item');
  const getData = async () => {
    setLoading(true);
    const response = (await getWorkflowScripts()) as Response;
    if (response.status === 200) {
      setWorkflows(response.data.workflowScripts);
      const workflowScripts = response.data.workflowScripts.map((workflow) => {
        if (data?.workflowScriptId === workflow.id) {
          setSelectedScript(workflow.name);
        }
        return workflow.name;
      });
      setScripts(workflowScripts);
    }
    setLoading(false);
  };

  const getScriptId = (script: string) => {
    let id = '';
    workflows.map((scripts) => {
      if (scripts.name === script) {
        id = scripts.id;
      }
    });
    return id;
  };

  const saveDataHandler = () => {
    if (selectedScript === 'Select an item') {
      setFormIsValid(false);
    } else {
      const data = {
        _type: 'ExecuteScriptAction',
        workflowScriptId: getScriptId(selectedScript),
      };
      onSave(data);
      onClose();
    }
  };

  useEffect(() => {
    getData();
  }, []);
  return (
    <ModalWrapper
      onClose={onClose}
      onSave={saveDataHandler}
      disabled={!formIsValid}
    >
      <div className="bg-white dark:bg-[#41464E]">
        <h2 className="sm:text-xl text-base sm:leading-5 leading-3 text-black-700 font-medium mb-8 dark:text-white">
          Execute Script
        </h2>
        {!loading && (
          <div className="z-10  dark:bg-[#41464E]  rounded-lg dropdown-show-hide relative sm:h-11 h-9">
            <Dropdown
              options={scripts}
              value={selectedScript}
              onSelect={(value) => {
                setSelectedScript(value);
                setFormIsValid(true)
              }}
            />
          </div>
        )}
        {!formIsValid && (
          <div className="px-6 py-3 bg-[#f7e8e7] rounded mt-4">
            <p className="text-sm font-normal leading-4 text-[#CB5A5A]">
              Select a script to continue
            </p>
          </div>
        )}
      </div>
    </ModalWrapper>
  );
};
export default ScriptAction;
