import SegmentDefinitionHeader from './SegmentDefinitonHeader';
import Table from 'components/Table';
import SegmentAction from './SegmentAction';
import { useContext, useEffect, useMemo, useState } from 'react';
import { getAllSegments } from 'services/apiHandlers/List-Segments/SegmentsList';
import { TableColumn } from 'react-data-table-component';
import { tableSort } from 'utils/common';
import { z } from 'zod';
import React from 'react';
import { Link } from 'react-router-dom';
import {
  create,
  listSegments,
  segmentDefinition,
} from 'services/constant/routes';
import BreadcrumbsWrapper from 'components/Wrapper/BreadcrumbsWrapper';
import { UserContext } from 'store/UserContext';
import Wrapper from 'components/Wrapper';

export const SegmentSchema = z.object({
  segment_json: z.string(),
  elastic_json: z.string(),
  segment_name: z.string(),
  subscriber_count: z.number(),
  status: z.string(),
});
export type Segment = z.infer<typeof SegmentSchema>;

const SegmentDefinition = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [segments, setSegments] = useState<Array<Segment>>([]);
  const [filteredSegments, setFilteredSegments] = useState<Array<Segment>>([]);
  const userCtx = useContext(UserContext);

  const getData = async (toggled?: boolean) => {
    if (!toggled) {
      setLoading(true);
    }
    try {
      const getAllSegmentsResponse = await getAllSegments();
      setSegments(getAllSegmentsResponse);
      setFilteredSegments(getAllSegmentsResponse);
    } catch (error) {
      console.log('error is: ', error);
    }
    setLoading(false);
  };

  const segmentNameSort = (rowA: Segment, rowB: Segment) => {
    return tableSort(rowA.segment_name, rowB.segment_name);
  };

  const segmentMemberSort = (rowA: Segment, rowB: Segment) => {
    return tableSort(rowA.subscriber_count, rowB.subscriber_count);
  };

  const columns: TableColumn<Segment>[] = useMemo(
    () => [
      {
        name: <div className="px-3">Sr.No</div>,
        cell: (row, index) => {
          return (
            <td className="dark:text-white px-3 text-center">{index + 1}</td>
          );
        },
        width: '80px',
      },
      {
        name: 'Segments',
        cell: (row) => {
          return <td className="dark:text-white">{row.segment_name}</td>;
        },
        sortable: true,
        sortFunction: segmentNameSort,
        minWidth: '250px'
      },
      {
        name: 'Members',
        cell: (row) => {
          return <td className="dark:text-white">{row.subscriber_count}</td>;
        },
        sortable: true,
        sortFunction: segmentMemberSort,
      },
      {
        name: 'Actions',
        cell: (row, index) => {
          return (
            <td className="whitespace-nowrap py-2  pr-3 text-13 sm:align-top align-middle text-[#495057] dark:text-[#CED4DA] flex items-center ">
              <SegmentAction
                index={index}
                row_name={row.segment_name}
                status={row.status}
                getData={(toggled) => getData(toggled)}
              />
            </td>
          );
        },
        width:'350px'
      },
    ],
    []
  );

  useEffect(() => {
    const searchInterval = setTimeout(() => {
      if (userCtx?.filteredValue) {
        const tempSegments = segments.filter((segment) =>
          segment.segment_name
            .toLowerCase()
            .includes(userCtx.filteredValue.toLowerCase())
        );
        setFilteredSegments(tempSegments);
      } else {
        setFilteredSegments(segments);
      }
    }, 1000);

    return () => {
      clearInterval(searchInterval);
    };
  }, [userCtx?.filteredValue, segments]);

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <BreadcrumbsWrapper>
        <Link
          to={`${listSegments}/${segmentDefinition}/${create}`}
          className="sm:text-sm text-10 font-medium leading-5 text-white flex items-center ml-auto rounded-md sm:py-3 py-2 sm:pl-4 sm:pr-3.5 px-1 font-inter bg-primary group dark:hover:bg-white dark:hover:text-[#495057] dark:text-[#CED4DA]  dark:text-white dark:hover:bg-white ease-in duration-300 hover:bg-primaryHover hover:scale-105"
        >
          Create New Segment
        </Link>
      </BreadcrumbsWrapper>
      <div className="relative mx-4 my-8 min-h-[72vh]">
        <Wrapper>
        <>
          <SegmentDefinitionHeader />
         
            <Table
              className="scrollbar-hide"
              data={filteredSegments}
              columns={columns}
              progressPending={loading}
              // customStyles={customStyles}
            />
         
        </>
        </Wrapper>
      </div>
    </>
  );
};
export default SegmentDefinition;
