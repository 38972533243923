import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import React, {FC} from "react"
interface Props {
  children?: JSX.Element
}
const BreadcrumbsWrapper: FC<Props> = ({
  children,
}) => {
  return (
    <div className="h-[61px] bg-white dark:bg-transparent md:px-6 px-3 py-2 font-inter border-b shadow-0 shadow flex justify-between items-center dark:border-[#fff] ">
    <div className="md:px-1.5">
      <div>
        <Breadcrumbs />
      </div>
    </div>
    {children &&
      <div className="flex justify-between items-center">
        {children}
      </div>}
  </div>
  );
};
export default BreadcrumbsWrapper;