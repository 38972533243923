import React, { FC, useEffect, useState } from 'react';
import { ExpanderComponentProps } from 'react-data-table-component';
import { z } from 'zod';
import ProductTable from './ProductTable';
import { isValidUrl } from './ProductTable';
import { ResultData } from 'pages/EventLogs';
import { ProductTableKeys } from 'staticdata/EventLogs/Data';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';


const entrySchema = z.tuple([z.string(), z.string()]);
const accordianDataSchema = z.array(entrySchema);
const resultDataSchema = z.object({
    accountId: z.number(),
    attributes: z.record(z.string()),
    id: z.string(),
    name: z.string(),
    timestamp: z.number(),
});
type ExpandableRowData = z.infer<typeof resultDataSchema>;
export type AccordianData = z.infer<typeof accordianDataSchema>;

interface Products {
    [key: string]: string;
}
const EventLogAccordian: FC<ExpanderComponentProps<ExpandableRowData>> = ({ data }) => {
    
    const [accordian, setAccordian] = useState<AccordianData>([]);
    const [products, setProducts] = useState<Array<string>>([]);

    useEffect(() => {
        const tempAttributes = Object.entries(data.attributes);
        const arrayElements: string[] = [];
        const nonArrayElements: AccordianData = [];

        const products = tempAttributes.filter((attribute) => {
            return ProductTableKeys[attribute[0]]
        });
        const nonProducts = tempAttributes.filter((attribute) => {
            return !ProductTableKeys[attribute[0]]
        });
        if (products[0]) {

            const product: Products = {};
            products.forEach(item => {
                product[item[0]] = item[1];
            });

            const formattedProducts = [product];
            const jsonProducts = JSON.stringify(formattedProducts);
            arrayElements.push(jsonProducts);

            nonProducts.forEach((element) => {
                nonArrayElements.push(element);
            })

        } else {
            let tempConversions: ResultData = data;

            tempAttributes.forEach((element) => {
                if (element[0].includes('conversion')) {
                    tempConversions = JSON.parse(element[1])
                }
            })

            const newTempAttributes = Object.entries(tempConversions.attributes);

            newTempAttributes.forEach((element) => {
                try {
                    if (Array.isArray(JSON.parse(element[1])) && JSON.parse(element[1]) ? typeof JSON.parse(element[1])[0] === 'object' : false) {
                        arrayElements.push(element[1]);
                    } else {
                        nonArrayElements.push(element);
                    }
                } catch (error) {
                    //JSON.Parse is also considering the elemets with . as Arrays
                    nonArrayElements.push(element);
                }
            });
        }
        setProducts(arrayElements);
        setAccordian(nonArrayElements);
    }, [data.attributes]);

    return (
        <div className="bg-[#0c68bb30] p-4">
            <div className="md:flex flex-wrap block text-sm w-full rounded-md bg-[#F9F9F9] mb-2 dark:bg-[#365679] dark:bg-opacity-100 bg-opacity-100 ">
                {accordian.map((event) => (
                    <div key={event[0]} className={`px-4 py-2 w-1/6  `}>

                        <p className="text-sm font-medium leading-5 text-[#212529] dark:text-white">
                            {event[0]}
                        </p>
                        <Tippy interactive={true} content={typeof event[1] === 'string' ? event[1] : ''}>
                            <p onClick={() => { isValidUrl(event[1].toString()) && window.open(String(event[1]), '_blank') }} className={` ${isValidUrl(event[1].toString()) && 'text-primary cursor-pointer underline'} text-[13px] leading-5 max-w-[200px] truncate text-[#878A99] dark:text-[#CED4DA]  `}>
                                {typeof event[1] === 'string' ? event[1] : ''}
                            </p>
                        </Tippy>
                    </div>
                ))}
            </div>
            {products?.map((product) => (
                <ProductTable key={product} data={product} />
            ))}
        </div>
    );
};
export default EventLogAccordian;