import { FC } from 'react';
import Overview from './Overview';
import React from "react"

interface Props {
  activeTab: string;
}

const Body: FC<Props> = () => {

  return (
    <div className="md:px-4 px-3 py-5 font-inter min-h-[76vh] h-full ">
      <div className="relative"> 
        <Overview/>
      </div>
    </div>
  );
};
export default Body;
