import Dropdown from 'components/Dropdown/Dropdown';
import Delete from 'assets/Images/ListsegmentImages/segment-delete.png';
import Plus from 'assets/Images/ListsegmentImages/plus-blue.png';
import {  FC, useState } from 'react';
import { z } from 'zod';

import React from 'react';

const IDefinitionSchema = z.object({
  definition: z.string(),
  has_condition: z.string(),
  order_condition: z.string(),
  revenue_condition: z.string(),
  order_count: z.number(),
  revenue_count: z.number(),
  time_condition: z.string(),
  time_count1: z.number(),
  time_count2: z.number(),
  time_unit: z.string(),
  date: z.string(),
  filterCondition: z.union([z.string(), z.null()]),
  filterConditionValue: z.union([z.string(), z.null()]),
  andOr: z.string(),
  filterSearchValue: z.union([z.string(), z.null()]),
});
export type IDefinition = z.infer<typeof IDefinitionSchema>;

const DefinitionOptions = [
  
  'What someone has done (or not done)',
  'Has Revenue',
];
const HasConditionOptions = [
  'Placed Order',
  'Viewed Product',
  'Viewed Cart',
  'Viewed Category',
  'Viewed Checkout',
  'Viewed Search',
  'Screenpopper Signup',
];
const HasConditionOptionsRevenue = ['Order Revenue'];
const OrderCondition = ['equal to', 'is greater than', 'is less than'];
const OrderConditionSomeone = ['at least once', 'zero times'];
const TimeCondition = [
  'over all time',
  'in the last',
  'before',
  'after',
  'between',
];
const UnitTime = ['days', 'hours'];

const FilterCondition = [
  'Product id',
  'Product Name',
  'Product Price',
  'Product Quantity',
  'Categories',
];
const FilterSearchValue = ['contains', 'contains everywhere'];

interface Props {
  definition: IDefinition;
  showOr: boolean;
  onChange: (key: string, value: string) => void;
  onDelete: () => void;
  addDefinition: () => void;
  error: boolean;
  showMore: boolean;
  setDefinition: (definition: IDefinition) => void;
}

const SegmentDefinition: FC<Props> = ({
  definition,
  onChange,
  onDelete,
  addDefinition,
  error,
  showMore,
  setDefinition,
}) => {
  const onChangeField = (name: string, value: string) => {
    onChange(name, value);
  };
    
  const [showWhereSection, setShowWhereSection] = useState(false);
  return (
    <div>
      <div className="flex items-center relative">
        <div className="flex items-center sm:w-auto w-full sm:mt-0 ">
          {/* <div className="w-6 h-6 sm:mr-5 mr-2.5">
            <img className="w-full" src={Dots} alt="Dots" />
          </div> */}
          <div className='sm:min-w-[290px]'>
            <div className="flex justify-between items-center dark:border-[#fff] rounded-md relative max-w-xs w-full dark:text-white">
              <Dropdown
                options={DefinitionOptions}
                value={definition.definition}
                onSelect={(value) => onChangeField('definition', value)}
              />
            </div>
            {error && definition.definition.length === 0 && (
              <div
                data-pw="definition-required"
                className="text-sm leading-4 text-[#cb5b57] dark:text-white mt-2"
              >
                Required
              </div>
            )}
          </div>
        </div>
        <div className="sm:static absolute right-0 -top-3">
          <button
            onClick={onDelete}
            type="button"
            className="h-[38px] ml-2 px-4 sm:mt-0 mt-3 rounded-md border border-[#ced4da] dark:border-[#fff] dark:hover:bg-white"
            data-pw="delete-segment-section"
          >
            <img src={Delete} alt="Delete" />
          </button>
        </div>
      </div>
      {definition.definition.length > 0 ||
      definition.has_condition.length > 0 ? (
        <>
          <div className="flex justify-between md:items-center relative sm:mt-5 mt-8">
            <div className="md:flex xl:items-center sm:mt-0 sm:pl-11 pl-8 md:w-auto w-full">
              {definition.definition.length > 0 && (
                <div className="sm:flex xl:items-center mr-4 w-14 md:mb-0 mb-3">
                  <p
                    data-pw="definition-has"
                    className="text-13 font-semibold leading-3 text-black-300 dark:text-white"
                  >
                    Has
                  </p>
                </div>
              )}
              <div className="flex flex-wrap xl:justify-start justify-center items-center">
                {definition.definition.length > 0 && (
                  <div className='mr-4 min-w-[200px]'>
                    <div className="flex justify-between items-center sm:h-[38px] h-9  dark:border-[#fff] relative min-w[260px] w-full lg:mr-4 mb-3">
                      <Dropdown
                        options={
                          definition.definition === 'Has Revenue'
                            ? HasConditionOptionsRevenue
                            : HasConditionOptions
                        }
                        value={definition.has_condition}
                        onSelect={(value) =>
                          onChangeField('has_condition', value)
                        }
                      />
                    </div>
                    {error && definition.has_condition.length === 0 && (
                      <div
                        data-pw="has-condition-required"
                        className="text-sm leading-4 text-[#cb5b57] dark:text-white mt-2"
                      >
                        Required
                      </div>
                    )}
                  </div>
                )}
                {definition.has_condition.length > 0 && (
                  <>
                    <div className="sm:flex items-center md:mt-0 mt-4 lg:mr-4 w-full md:w-auto mb-3">
                      <div className="min-w-[200px] lg:mt-0 md:mt-4 flex justify-between items-center  dark:border-[#fff] rounded-md relative lg:w-36 md:w-72 w-full lg:mr-4">
                        <Dropdown
                          options={
                            definition.definition === 'Has Revenue'
                              ? OrderCondition
                              : [...OrderConditionSomeone, ...OrderCondition]
                          }
                          value={definition.order_condition}
                          onSelect={(value) =>
                            onChangeField('order_condition', value)
                          }
                        />
                      </div>
                      {(definition.order_condition === 'equal to' ||
                        definition.order_condition === 'is greater than' ||
                        definition.order_condition === 'is less than') && (
                        <div className="w-12 h-[38px] border border-[#ced4da] dark:border-[#fff] rounded text-center">
                          <input
                            data-pw="order-condition-input"
                            className="w-full h-full text-13 text-gray-700 leading-3 font-medium text-center focus:outline-none  dark:bg-[#41464E]  rounded-md dark:placeholder:text-white dark:text-white"
                            type="number"
                            value={definition.order_count}
                            name="order_count"
                            onChange={(e) =>
                              onChangeField('order_count', e.target.value)
                            }
                          />
                        </div>
                      )}
                    </div>
                    <div className="sm:flex items-center lg:mt-0 mt-4 lg:mr-4 w-full md:w-auto mb-3">
                      <div className="flex justify-between item-center dark:border-[#fff] rounded-md relative lg:w-36 md:w-72 w-full">
                        <Dropdown
                          options={TimeCondition}
                          value={definition.time_condition}
                          onSelect={(value) =>
                            onChangeField('time_condition', value)
                          }
                        />
                      </div>
                    </div>
                  </>
                )}
                {(definition.time_condition === 'in the last' ||
                  definition.time_condition === 'between') && (
                  <div className="w-12 h-[38px] flex justify-center border border-[#ced4da] dark:border-[#fff] rounded text-center mr-4 mb-3">
                    <input
                      className="w-full text-13 text-[#212529]  dark:bg-[#41464E]  rounded-md text-center leading-3 dark:placeholder:text-white dark:text-white"
                      type="number"
                      data-pw="time-condition-input-1"
                      value={definition.time_count1}
                      name="time_count1"
                      onChange={(e) =>
                        onChangeField('time_count1', e.target.value)
                      }
                    />
                  </div>
                )}
                {definition.time_condition === 'between' && (
                  <>
                    <p className="text-xs font-medium leading-3 text-gray-700 dark:text-white mr-4 mb-3">
                      and
                    </p>
                    <div className="w-12 h-[38px] border border-[#ced4da] dark:border-[#fff] rounded text-center mr-4 mb-3">
                      <input
                        className="w-full h-full text-13 text-gray-700 leading-3 font-medium text-center focus:outline-none  dark:bg-[#41464E]  rounded-md dark:placeholder:text-white dark:text-white"
                        data-pw="time-condition-input-2"
                        type="number"
                        value={definition.time_count2}
                        name="time_count2"
                        onChange={(e) =>
                          onChangeField('time_count2', e.target.value)
                        }
                      />
                    </div>
                  </>
                )}
                {(definition.time_condition === 'in the last' ||
                  definition.time_condition === 'between') && (
                  <div
                    data-pw="time-unit-dropdown"
                    className=" w-[150px] mb-3 flex justify-between item-center"
                  >
                    <Dropdown
                      options={UnitTime}
                      value={definition.time_unit || 'Select Time'}
                      onSelect={(value) => onChangeField('time_unit', value)}
                    />
                  </div>
                )}
                {(definition.time_condition === 'after' ||
                  definition.time_condition === 'before') && (
                  <div data-pw="date-fields" className="flex items-center mb-3">
                    <div className="w-28 h-11 border border-[#ced4da] dark:border-[#fff] rounded text-center mr-4">
                      <input
                        className="w-full h-full text-13 text-gray-700 leading-3 font-medium text-center focus:outline-none  dark:bg-[#41464E]  rounded-md dark:placeholder:text-white dark:text-white"
                        type="text"
                        value={definition.date}
                        name="date"
                        onChange={(e) => onChangeField('date', e.target.value)}
                      />
                    </div>
                    <span className="text-13 font-semibold leading-3 text-black-300 dark:text-white mr-4 md:w-auto w-14 md:mb-0 mb-3">
                      Format: YYYY-MM-DD
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
          {(showWhereSection ||
            (definition.filterCondition !== '' &&
              definition.filterCondition !== null)) && (
            <div
              data-pw="where-section"
              className="flex justify-between md:items-center relative mt-8"
            >
              <div className="md:flex xl:items-center md:mt-0 mt-8 sm:pl-11 pl-8 md:w-auto w-full">
                <p className="text-13 font-semibold leading-3 text-black-300 dark:text-white  mr-4 w-14 md:mb-0 mb-3">
                  Where
                </p>
                <div className="lg:flex-row flex-col xl:justify-start justify-center flex items-center md:mr-4">
                  <div>
                    <div className="flex justify-between items-center min-w-[200px] dark:border-[#fff] rounded-md relative lg:mr-4 ">
                      <Dropdown
                        options={FilterCondition}
                        value={definition.filterCondition || ' '}
                        onSelect={(value) =>
                          onChangeField('filterCondition', value)
                        }
                      />
                    </div>
                    {error && definition.filterCondition === '' && (
                      <div
                        data-pw="has-condition-required"
                        className="text-sm leading-4 text-[#cb5b57] dark:text-white mt-2 absolute"
                      >
                        Required
                      </div>
                    )}
                  </div>
                  <div className="lg:mr-4 flex justify-between items-center min-w-[250px] relative lg:w-44 w-full lg:mt-0 mt-4">
                    <Dropdown
                      options={FilterSearchValue}
                      value={definition.filterSearchValue || ' '}
                      onSelect={(value) =>
                        onChangeField('filterSearchValue', value)
                      }
                    />
                  </div>
                  <div className="flex xl:justify-start md:justify-between items-center lg:mt-0 mt-4 xl:w-auto w-full">
                    <div className="items-center dark:border-[#fff] rounded-md relative xl:w-9/12 lg:w-7/12 w-11/12">
                      <input
                        className="w-full px-4 py-2 text-13 leading-3  dark:bg-[#41464E]  border border-[#ced4da] dark:border-[#fff] rounded text-[#212529] dark:text-white sm:h-[38px]"
                        type="text"
                        name="filterConditionValue"
                        data-pw="filterCondition-value"
                        value={definition.filterConditionValue || ' '}
                        onChange={(e) =>
                          onChangeField('filterConditionValue', e.target.value)
                        }
                      />
                      {error && definition.filterConditionValue === '' && (
                        <div
                          data-pw="has-condition-required"
                          className="text-sm leading-4 text-[#cb5b57] dark:text-white mt-2"
                        >
                          Required
                        </div>
                      )}
                    </div>
                    <a
                      onClick={() => {
                        const tempDefinition = { ...definition };
                        tempDefinition.filterConditionValue = null;
                        tempDefinition.filterCondition = null;
                        tempDefinition.filterSearchValue = '';
                        setDefinition(tempDefinition);
                        setShowWhereSection(false);
                      }}
                      className="ml-4 cursor-pointer h-[38px] flex justify-center items-center px-4 rounded-md border border-[#ced4da] dark:border-[#fff] dark:hover:bg-white"
                      data-pw="delete-filterSection"
                    >
                      <img src={Delete} alt="Delete" />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className=" mb-5 mt-6 flex justify-between items-center text-right">
            {!showWhereSection &&
              definition.has_condition.length > 0 &&
              !definition.filterCondition?.length && (
                <div
                  data-pw="Add-Filter-Group"
                  onClick={() => {
                    setShowWhereSection(true);
                    onChangeField('filterConditionValue', '');
                    onChangeField('filterCondition', '');
                  }}
                  className="sm:pl-11 pl-8 inline-flex items-center sm:text-13 text-xs font-medium leading-5 text-primary dark:text-white dark:hover:text-primary underline cursor-pointer"
                >
                  <img className="mr-3" src={Plus} alt="Plus" />
                  Add Filter Group
                </div>
              )}

            {showMore && (
              <div className="ml-auto">
                <button
                  onClick={() => {
                    addDefinition();
                  }}
                  className="inline-flex items-center sm:text-13 text-xs leading-5 text-primary dark:text-white dark:hover:text-primary underline cursor-pointer"
                  type="button"
                >
                  Add More Section
                </button>
              </div>
            )}
          </div>
        </>
      ) : null}
    </div>
  );
};
export default SegmentDefinition;
