import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import React, { useEffect, useState, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { UserContext } from 'store/UserContext';
import { getSingleCampaign, getSingleCampaignClickData } from 'services/apiHandlers/Dashboard/Analytics';
import { BlastSchema, clickSchema } from 'components/DashboardReports/Types';

import { z } from 'zod';
import { calculatePercentage } from 'utils/common';
import { getBlastMonetaryValue } from 'services/apiHandlers/Dashboard/Analytics';
import { GetBlastMonetaryValueResponse } from 'components/Campaigns/Body/Overview/AllCampaigns';
import moment from 'moment';
import TotalClickers from './TotalClickers';
import BlastInformation from './BlastInformation';
import Email from './Email';
import Card from './Card';
import { getFields } from 'services/apiHandlers/List-Segments/Field';
import xmlToJSON from 'utils/xmlToJSON';
import { formatXmlJsonResponse } from 'utils/common';
import { getAllList } from 'services/apiHandlers/List-Segments/SegmentsList';

const getListResponseDataSchema = z.object({
  id: z.number(),
  name: z.string(),
  audience_id: z.number(),
});
const getListResponseSchema = z.object({
  data: z.object({
    lists: z.array(getListResponseDataSchema),
  }),
  status: z.number(),
});
type GetListResponse = z.infer<typeof getListResponseSchema>;
type Lists = z.infer<typeof getListResponseDataSchema>;
const BlastResponseSchema = z.object({ data: BlastSchema, status: z.number() });
type BlastResponse = z.infer<typeof BlastResponseSchema>;
export type Blast = z.infer<typeof BlastSchema>;
export type ClickResponseSchema = z.infer<typeof clickSchema>

const fieldsSchema = z.object({
  ['allow-other']: z.boolean(),
  ['data-type']: z.string(),
  id: z.number(),
  ['include-blank']: z.boolean(),
  name: z.string(),
  slug: z.string(),
  'used-in-filter': z.string(),
});

export type Fields = z.infer<typeof fieldsSchema>;

const CardDataSchema = z.object({
  name: z.string(),
  value: z.string(),
  calculation: z.string().optional(),
  comparisonProperty: z.string().optional(),
  export: z.string().optional(),
  tooltipContent: z.string(),
});
export type CardData = z.infer<typeof CardDataSchema>;

const defaultCardData = [
  {
    name: 'Converted',
    value: `-`,
    calculation: `-`,
    comparisonProperty: 'Viewed',
    tooltipContent: '',
  },
  {
    name: 'Unique Viewers',
    value: '-',
    calculation: `-`,
    comparisonProperty: 'Delivered',
    export: '',
    tooltipContent: 'Recipients who viewed this email',
  },
  {
    name: 'Total Views',
    value: `-`,
    calculation: `-`,
    comparisonProperty: 'Delivered',
    tooltipContent: 'All instances of recipients who viewed this email',
  },
  {
    name: 'Unsubscribers',
    value: `-`,
    calculation: `-`,
    comparisonProperty: 'Delivered',
    tooltipContent: 'Subscribers who opted out of future mailings',
  },
  {
    name: 'Complaints',
    value: `-`,
    calculation: `-`,
    comparisonProperty: 'Unsubscribers',
    tooltipContent: 'Subscribers who complained that email was spam',
  },
  {
    name: 'Forwards',
    value: `-`,
    calculation: `-`,
    comparisonProperty: 'Delivered',
    tooltipContent: 'Subscribers who forwarded this email',
  },
  {
    name: 'New Subscribers',
    value: `-`,
    calculation: `-`,
    comparisonProperty: 'Forwards',
    tooltipContent: 'New Subscribers from a forwarded email',
  },
  {
    name: 'Total Recipients',
    value: `-`,
    calculation: `-`,
    comparisonProperty: 'Delivered',
    tooltipContent: 'Total number of subscribers for this email',
  },
];

const CampaignReports = () => {
  const userCtx = useContext(UserContext);

  const { id } = useParams();
  const [blastData, setBlastData] = useState<Blast>();
  const [clicksData, setClicksData] = useState<ClickResponseSchema>();
  const [campaignsLoading, setCampaignsLoading] = useState<boolean>(true);
  const [cardsData, setCardsData] = useState<Array<CardData>>(defaultCardData);
  const [fields, setFields] = useState<Array<Fields>>([]);
  const [lists, setLists] = useState<Array<Lists>>([]);

  const getCampiagn = async (id: string) => {
    setCampaignsLoading(true);
    let monetaryValue = 0;
    let conversion = 0;
    try {
      const getBlastMonetaryValuesResponse = (await getBlastMonetaryValue(
        id
      )) as GetBlastMonetaryValueResponse;
      if (getBlastMonetaryValuesResponse.status === 200) {
        monetaryValue = getBlastMonetaryValuesResponse.data[0].monetaryValue;
        conversion = getBlastMonetaryValuesResponse.data[0].conversions;
      }
      const getSingleCampiagnResponse = (await getSingleCampaign(
        id
      )) as BlastResponse;

      if (getSingleCampiagnResponse.status === 200) {
        setBlastData(getSingleCampiagnResponse.data);
        const blastData = getSingleCampiagnResponse.data.blast;
        /* eslint-disable */
        const getFieldsResponse: any = await getFields();
        const data: any = xmlToJSON.parseString(getFieldsResponse.data);
        const fields = formatXmlJsonResponse(data?.fields[0]?.field);
        setFields(fields as any);
        /* eslint-enable */

        const getListsResponse = (await getAllList()) as GetListResponse;
        if (getListsResponse.status === 200) {
          setLists(getListsResponse.data.lists);
        }

        setCardsData([
          {
            name: 'Converted',
            value: `$${Math.round(monetaryValue).toLocaleString()}`,
            calculation: `${calculatePercentage(
              conversion,
              blastData?.unique_views_count || 0
            )}%`,
            comparisonProperty: 'Viewed',
            tooltipContent: '',
          },
          {
            name: 'Unique Viewers',
            value: `${blastData?.unique_views_count.toLocaleString()}`,
            calculation: `${calculatePercentage(
              blastData?.unique_views_count || 0,
              blastData?.number_sent || 0
            )}%`,
            comparisonProperty: 'Delivered',
            export: blastData.unique_views_count > 0 ? 'show' : 'no-data',
            tooltipContent: 'Recipients who viewed this email',
          },
          {
            name: 'Unique Clickers',
            value: `${blastData?.unique_clicks_count.toLocaleString()}`,
            calculation: `${calculatePercentage(
              blastData?.unique_clicks_count || 0,
              blastData?.number_sent || 0
            )}%`,
            comparisonProperty: 'Delivered',
            export: blastData.unique_clicks_count > 0 ? 'show' : 'no-data',
            tooltipContent: 'All instances of unique recipients who clicked this email',
          },
          {
            name: 'Total Views',
            value: `${blastData?.views_count.toLocaleString()}`,
            calculation: `${calculatePercentage(
              blastData?.views_count || 0,
              blastData?.number_sent || 0
            )}%`,
            comparisonProperty: 'Delivered',
            tooltipContent: 'All instances of recipients who viewed this email',
          },
          {
            name: 'Unsubscribers',
            value: `${blastData?.unsubscribes_count.toLocaleString()}`,
            calculation: `${calculatePercentage(
              blastData?.unsubscribes_count || 0,
              blastData?.number_sent || 0
            )}%`,
            comparisonProperty: 'Delivered',
            tooltipContent: 'Subscribers who opted out of future mailings',
          },
          {
            name: 'Complaints',
            value: `${blastData?.complaints_count.toLocaleString()}`,
            calculation: `${calculatePercentage(
              blastData?.complaints_count || 0,
              blastData?.unsubscribes_count || 0
            )}%`,
            comparisonProperty: 'Unsubscribers',
            tooltipContent: 'Subscribers who complained that email was spam',
          },
          {
            name: 'Total Recipients',
            value: `${blastData?.intended_recipients.toLocaleString()}`,
            calculation: `${calculatePercentage(
              blastData?.intended_recipients || 0,
              blastData?.number_sent || 0
            )}%`,
            comparisonProperty: 'Delivered',
            tooltipContent: 'Total number of subscribers for this email',
          },
        ]);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
    setCampaignsLoading(false);
  };

  const getClicksData = async (id: string)=>{

    console.log("Here is Clicks Data for" + id);
    setCampaignsLoading(true);
    try{
    const singleResponseClick= await getSingleCampaignClickData(id) as ClickResponseSchema;

    if(singleResponseClick.status === 200)
    {
      setClicksData(singleResponseClick);
    }




    }catch(error)
    {
      console.log('error is : ', error);
    }
   setCampaignsLoading(false);



  }


  useEffect(() => {
    if (id) {
      {
      getCampiagn(id);
      getClicksData(id);
      }
    }
  }, []);

  console.log('Check Blast: ', blastData?.blast)
  return (
    <div>
      <div className=" h-[61px] dark:border-[#fff] md:px-6 px-3 py-2 font-inter border-b shadow-0 shadow flex justify-between items-center bg-white dark:bg-[#41464E]">
        <Breadcrumbs />
      </div>
      <div className="px-6 py-5  min-h-[76vh]">
        <div className="sm:flex items-center justify-between">
          <p className='text-[#495057] dark:text-[#CED4DA]'>
            {blastData?.blast.subject} ({blastData?.blast.intended_recipients ? blastData?.blast.intended_recipients.toLocaleString() : '0'}{' '}
            people)
          </p>
          <p className='text-[#495057] dark:text-[#CED4DA]'>
            {moment
              .tz(
                blastData?.blast.scheduled_for,
                userCtx?.usersData?.timeZone || ''
              )
              .format('MMMM D, YYYY [at] h:mm A')}
          </p>
        </div>
        <div className="2xl:flex gap-4">
          <div className="2xl:w-8/12">
            <div className="md:flex gap-4">
              <BlastInformation blast={blastData} />
              <Email loading = {campaignsLoading} id={blastData?.blast.email_id}  />
            </div>

            {blastData?.blast.status === 'sent' &&
              <div className="flex gap-4">
                <div className="w-full grid md:grid-cols-4 md:grid-rows-1 xl:gap-4 md:gap-3 font-inter">
                  {cardsData.map((card) => (
                    <Card
                      key={card.name}
                      card={card}
                      loading={campaignsLoading}
                      fields={fields}
                      lists={lists}
                    />
                  ))}
                </div>
              </div>}
          </div>

          <TotalClickers
           
            clicks = {clicksData}
            blast={blastData}
            fields={fields}
            lists={lists}
            loading={campaignsLoading}
          />
        </div>
      </div>
    </div>
  );
};
export default CampaignReports;
