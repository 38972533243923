import { ChangeEvent, FC } from 'react';
import React from "react";

interface Props {
  onSelect: (e: ChangeEvent<HTMLSelectElement>) => void;
  groups: string[];
  options: string[][];
}

const GroupDropdown: FC<Props> = ({ onSelect, groups, options }) => {
  
  return (
    <div className="md:block hidden w-56 relative h-8  rounded   px-2  border border-[#ced4da] text-sm text-[#212529] dark:text-white flex items-center justify-between sm:h-[38px]  rounded  dark:border-[#fff] cursor-pointer">
      <select
        onChange={onSelect}
        className="bg-transparent focus:outline-none w-full h-full "
      >
        {groups.map((group, index) => (
          <optgroup key={group} label={group}>
            {options[index].map((option) => (
              <option key={option[1]} value={option[1]}>
                {option[0]}
              </option>
            ))}
          </optgroup>
        ))}
      </select>
    </div>
  );
};
export default GroupDropdown;
