import { getUser } from 'services/apiHandlers/Campaigns/Emails';
import xmlToJSON from 'utils/xmlToJSON';
import Key from 'assets/Images/Common/key.gif';

import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import CustomSkeleton from 'components/Skeleton/Skeleton';
import BreadcrumbsWrapper from 'components/Wrapper/BreadcrumbsWrapper';
import Wrapper from 'components/Wrapper';
import Edit from "./Edit"
interface IUserMode {
    edit?: boolean
}

export interface IUser  {
    name: string,
    firstName: string,
    lastName: string,
    email: string
    isAdministrator: boolean,
    title: string,
    companyName:string,
    phoneNumberMobile: string,
    phoneNumberHome: string
}

const User: FC<IUserMode> = ({ edit = false }) => {
    const [user, setUser] = useState<IUser>({
        name: '',
        firstName: '',
        lastName: '',
        email: '',
        isAdministrator: false,
        title: '',
        companyName:'',
        phoneNumberMobile: '',
        phoneNumberHome:''
    });
    
    const [loading, setLoading] = useState<boolean>(true);
    const { id } = useParams();
   
    const getData = async (userId: string) => {
        setLoading(true);
        try {
            /* eslint-disable */
            const getUsersResponse: any = (await getUser(userId));
            const data: any = xmlToJSON.parseString(getUsersResponse.data);
            console.log("User data after parsing: ", data.user[0]);
            setUser({
                name: (data.user[0]['first-name'][0]['_text']).concat(' ', data.user[0]['last-name'][0]['_text']),
                firstName: data.user[0]['first-name'][0]['_text'],
                lastName: data.user[0]['last-name'][0]['_text'],
                email: data.user[0]['email-address'][0]['_text'],
                companyName: data.user[0]['company-name'][0]['_text'],
                phoneNumberMobile: data.user[0]['phone-number-mobile'][0]['_text'],
                phoneNumberHome:   data.user[0]['phone-number-home'][0]['_text'],
                isAdministrator: data.user[0]['administrator'][0]['_text'],
                title: (data.user[0]['title'][0]['_text'].length > 1 ? data.user[0]['title'][0]['_text'] : '')
            })
            /* eslint-disable */

        } catch (error) {
            console.log('error is : ', error);
            setLoading(false);

        }
        setLoading(false);
    };

    useEffect(() => {
        if (id) {
            getData(id)
        }
    }, [])

    console.log("User is: ", user);

    return (
        <>
        <BreadcrumbsWrapper />
        <div className=" pb-8 font-inter h-full  min-h-[74vh] ml-4 mt-8 mr-8">
		<Wrapper>

		<div className="relative pb-3 ml-4 h-[60vh]">
                {/* <div className="after:left-[-3px] after:bottom-[-2px] after:border-b-[#e9ebec] after:border-l-[#e9ebec] after:border-t-transparent after:border-r-transparent after:content-[''] after:h-[13px] after:w-[13px] after:absolute after:border-[3px] before:right-[-2px] before:top-[-3px] before:border-b-transparent before:border-l-transparent before:border-t-[#e9ebec] before:border-r-[#e9ebec] before:content-[''] before:h-[13px] before:w-[13px] before:absolute before:border-[3px]  border border-[rgba(0, 0, 0, 0.125)] rounded dark:shadow-none dark:border-[#fff] mb-16"> */}
                    <div className="flex md:flex-row flex-col justify-between items-center border-b dark:border-white border-[#e9ebec] py-[18px]">
                        <p className="text-xl text-[#495057] font-medium  dark:text-[#CED4DA]">
                            { edit ? "Edit" : "View"} User
                        </p>
			</div>
            {loading ? <CustomSkeleton columns={4} /> : edit ? <Edit user = {user} />  : (<div>

                    <div className='mt-3 text-lg dark:text-white font-medium'>{user.name}</div>

                    {user.isAdministrator ? <div className='flex'>
                    <p className='dark:text-white'>{user.name} is an Administrator</p>
                    <img className='ml-2' src={Key} alt='key'></img>
                    </div> : null}


                    <div className='mt-5 flex justify-between w-[60%]'>
                        <div className='flex w-[50%] justify-start'>
                        <p className='mr-20 dark:text-white font-medium'>Email Address: </p>
                        </div>
                        <div className='flex w-[50%] justify-start'>
                        <p className='dark:text-white'>{user.email}</p>
                        </div>
                    </div>

                    <div className='mt-2 flex justify-between w-[60%]'>
                        <div className='flex w-[50%] justify-start'>
                        <p className='mr-20 dark:text-white font-medium'>Title: </p>
                        </div>
                        <div className='flex w-[50%] justify-start'>
                        <p className='dark:text-white'>{user.title? user.title: "-"}</p>
                        </div>
                    </div>
{/* 
                    <div className='mt-2 flex justify-between w-[60%]'>
                        <div className='flex w-[50%] justify-start'>
                        <p className='mr-20 dark:text-white font-medium'>Company Name: </p>
                        </div>
                        <div className='flex w-[50%] justify-start'>
                        <p className='dark:text-white'>{user.companyName? user.companyName: "-"}</p>
                        </div>
                    </div>

                    <div className='mt-2 flex justify-between w-[60%]'>
                        <div className='flex w-[50%] justify-start'>
                        <p className='mr-20 dark:text-white font-medium'>Phone Number Mobile: </p>
                        </div>
                        <div className='flex w-[50%] justify-start'>
                        <p className='dark:text-white'>{user.phoneNumberMobile ? user.phoneNumberMobile:"-"}</p>
                        </div>
                    </div>

                    <div className='mt-2 flex justify-between w-[60%]'>
                        <div className='flex w-[50%] justify-start'>
                        <p className='mr-20 dark:text-white font-medium'>Phone Number Home: </p>
                        </div>
                        <div className='flex w-[50%] justify-start'>
                        <p className='dark:text-white'>{user.phoneNumberHome ? user.phoneNumberHome:"-"}</p>
                        </div>
                    </div> */}

                   
                </div>)}
			
			</div>			
		</Wrapper>
		</div>




            {/* <div className='ml-10 mt-10 w-full h-full'>
                <h1 className='text-2xl mb-5 dark:text-white'>View User</h1>
                {loading ? <CustomSkeleton columns={4} /> : (<div>
                    <div className='text-xl dark:text-white'>{user.name}</div>
                    {user.isAdministrator ? <div className='flex'>
                        <img src={Key} alt='key'></img>
                        <p className='ml-2 dark:text-white'>{user.name} is an Administrator</p>
                    </div> : null}
                    <div className='mt-5 flex justify-between w-[60%]'>
                        <p className='mr-20 dark:text-white'>Email Address: </p>
                        <p className='dark:text-white'>{user.email}</p>
                    </div>
                    <div className='flex justify-between w-[60%]'>
                        <p className='mr-20 dark:text-white'>Title: </p>
                        <p className='dark:text-white'>{user.title}</p>
                    </div>
                </div>)}
            </div> */}
        </>

    );
};
export default User;
