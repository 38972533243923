import { FC, useEffect, useRef, useState } from 'react';
import DownArrow from 'assets/Images/Common/down-arrow.png';
import { useOutsideClick } from 'app/hooks/useOutsideClick';
import React from "react";

interface Props {
  options: string[];
  selectedList: string[] | undefined;
  onOptionChange: (selectedList: string[]) => void;
}
const MultiValueDropdown: FC<Props> = ({
  options,
  selectedList,
  onOptionChange,
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [listOptions, setListOptions] = useState<Array<string>>(options);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOutsideClick(dropdownRef, () => {
    setShowDropdown(false);
  });
  const optionsSelectHandler = (
    option: string,
    selectedList: string[] | undefined
  ) => {
    if (selectedList && selectedList.includes(option)) {
      const newList = selectedList.filter((str) => str !== option);
      onOptionChange(newList);
    } else if (selectedList) {
      onOptionChange([...selectedList, option]);
    } else {
      onOptionChange([option]);
    }
  };

  const onSearchHandler = (value: string) => {
    setSearchValue(value);
    const tempSelectedList = options.filter((option) =>
      option.toLowerCase().includes(value.toLowerCase())
    );
    setListOptions(tempSelectedList);
  };

  useEffect(() => {
    setListOptions(options);
  }, [options]);

  return (
    <div className="bg-white  dark:bg-[#41464E]  border-gray-200 dark:border-[#fff] border rounded-md dropdown-show-hide relative relative">
      <div data-pw="email-list" className="w-full overflow-auto" ref={dropdownRef}>
        <div
          onClick={() => setShowDropdown((prev) => !prev)}
          className={`${
            showDropdown
              ? 'rounded-b-none border-primary dark:border-white'
              : ''
          } text-sm font-medium leading-4 text-[#495057] dark:text-[#CED4DA]  dark:text-white dark:text-white flex items-center justify-between sm:[38px] py-2 px-3 focus:outline-none bg-white border border-[#ced4da] rounded-md  dark:bg-[#41464E]  dark:border-[#fff] w-full`}
          data-pw="dropdown"
          
        >
          {selectedList ? selectedList?.length : '0'} Items Selected
          <span>
            <img
              className="dark:brightness-0 dark:invert"
              src={DownArrow}
              alt="Arrow"
            />
          </span>
        </div>
        {showDropdown && (
          <div className="border border-t-0 rounded-b border-[#ced4da] dark:border-[#fff] w-full relative z-50">
            <div className="flex items-center">
              <input
                className="border focus:border-primary dark:focus:border-white focus:outline-none border-[#ced4da] dark:border-[#fff]  dark:bg-[#41464E]  sm:[38px] h-9 p-3 text-13 font-medium leading-3 text-gray-700 placeholder:text-gray-500 dark:text-white  dark:bg-[#41464E]  w-full dark:placeholder:text-white"
                type="search"
                name="Search"
                value={searchValue}
                onChange={(e) => onSearchHandler(e.target.value)}
                placeholder="Filter Items"
              />
            </div>
            <div className="bg-gray-500  dark:bg-[#41464E]  rounded-b px-3 max-h-40 overflow-auto scrollbar-hide">
              {listOptions.map((option) => (
                <div key={option}>
                  {
                    <div className="flex items-center pt-1">
                      <input
                        className="cursor-pointer mt-1"
                        checked={selectedList?.includes(option) ? true : false}
                        type="checkbox"
                        onChange={() => {
                          optionsSelectHandler(option, selectedList);
                        }}
                        id={option}
                      />
                      <label htmlFor={option} className="checkBox relative cursor-pointer pl-2">
                        <span className="text-13 font-normal leading-3 text-black-700 dark:text-white">
                          {option}
                        </span>
                      </label>
                    </div>
                  }
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
export default MultiValueDropdown;
