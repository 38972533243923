import Table from 'components/Table';
import React, { FC, useMemo } from 'react';
import { TableColumn } from 'react-data-table-component';
import { z } from 'zod';
import Copy from 'assets/Images/Workflow/copy.png';
import CloseIcon from 'assets/Images/Common/closeButtonIcon.svg';
import { copyTextToClipboard } from 'utils/common';
import Wrapper from 'components/Wrapper';
import AttributesWrapper from './AttributesWrapper';
import Tippy from '@tippyjs/react';



const eventAttributesSchema = z.object({
  name: z.string(),
  mailMerge: z.string(),
});

type EventAttributes = z.infer<typeof eventAttributesSchema>;

interface Props {
  eventAttributes: EventAttributes[];
  fields: EventAttributes[];
  onClose: (attribute: boolean) => void;
  selectedEvent: string;
  selectedDropdownEvent: string
}

const EventAttributes: FC<Props> = ({ eventAttributes, fields, onClose, selectedEvent, selectedDropdownEvent }) => {
  
  const inputRef = React.useRef<HTMLImageElement>(null)
 
  const attributeColumns: TableColumn<EventAttributes>[] = useMemo(
    () => [
      {
        
        name: <div className="px-1">Event Attributes </div>,
        cell: (row) => {
          return (
            <div className="dark:text-white px-1 ">
              <div>{row.name}</div>
            </div>
          );
        },
        width : '140px'
      },
      {
        name: <div className="px-4">Mail Merge Code</div>,
        cell: (row) => {
          return (
            <div className="dark:text-white flex px-4 align-baseline">
              <div ref={inputRef}>{row.mailMerge}</div>
              <Tippy content={"Copy"} interactive = {false} >
              <Tippy content={"Copied to Clipboard"} trigger={'click'} interactive={true} duration={[1,1000]} onShow={(instance)=>{
                  setTimeout(() => {
                    instance.hide();
                  }, 1000);
                
              }} >
              
              <img className=" cursor-pointer dark:brightness-100 w-5 h-5 ml-2 "
              onClick={()=>{ copyTextToClipboard(row.mailMerge) }
              } src={Copy} />
              </Tippy>
              </Tippy>
            </div>
          );
        },
      },
    ],
    []
  );

  const fieldsColumns: TableColumn<EventAttributes>[] = useMemo(
    () => [
      {
        name: <div className="px-4">Subscriber Attributes</div>,
        cell: (row) => {
          return (
            <div className="dark:text-white px-4">
              <div>{row.name}</div>
            </div>
          );
        },
      },
      {
        name: <div className="px-4">Mail Merge Code</div>,
        cell: (row) => {
          return (
            <div className="dark:text-white flex px-4">
              <div>{row.mailMerge}</div>

              <Tippy content={"Copy"}>
              <Tippy content={"Copied to Clipboard"} trigger={'click'} duration={[1,1000]} onShow={(instance)=>{

                setTimeout(()=>{
                    instance.hide();
                },1000)

              }}>
              <img className=" cursor-pointer w-5 h-5 ml-2 " onClick={()=>{ 
              copyTextToClipboard(row.mailMerge);
              
              
              }} src={Copy} />
              </Tippy>
              </Tippy>

            </div>
          );
        },
      },
    ],
    []
  );

  return (
    <div className=" px-6 w-full  fixed left-0 top-0 h-full z-10 bg-black-200 duration-300">
      <div className="">
        <AttributesWrapper onClose={() => onClose(false)}>
          <>
          <div className='flex justify-between items-center px-5 border-b dark:border-b-0 border-[#e9ebec] py-[18px]'>
            <div className='flex items-center'>
            <p className='text-base text-[#495057] font-medium  dark:text-[#CED4DA]'>Attributes</p>
            <p className='text-sm bg-green-400 text-white px-2 rounded ml-3 dark:text-white'>{`${selectedDropdownEvent}`}</p>
            <p className='text-sm text-black-400 px-2 rounded ml-3 dark:text-white'>{`${" ("+selectedEvent.split(",").join(", ")+")"}`}</p>
            </div>
            <img onClick={() => onClose(false)} src={CloseIcon} alt='CloseIcon' className='w-5 h-5 cursor-pointer'/> 
          </div>

          <div className="flex justify-between px-5 mx-auto gap-4 pb-5">
            <Wrapper parentClass=' ' childClass=' scrollbar-hide'>
              <Table
                className="scrollbar-hide h-[400px] overflow-auto "
                data={eventAttributes}
                columns={attributeColumns}
                pagination={false}
              />
            </Wrapper>
            <Wrapper parentClass='' childClass='scrollbar-hide ' >
              <Table
                className="scrollbar-hide h-[400px] overflow-auto "
                data={fields}
                columns={fieldsColumns}
                pagination={false}
              />
            </Wrapper>
          </div>
          </>
        </AttributesWrapper>
      </div>
    </div>
  );
};
export default EventAttributes;
