import React, { FC, useContext } from 'react';
import { UserContext } from 'store/UserContext';
import Wrapper from 'components/Wrapper';
import moment from 'moment';
import { Blast } from './index';
import ListSegments from './ListSegments';
import Status from './Status';

interface Props {
  blast: Blast | undefined;
}
const BlastInformation: FC<Props> = ({ blast }) => {
  const userCtx = useContext(UserContext);
  return (
    <Wrapper parentClass="md:w-[50%] shadow-lg hover:shadow-xl dark:shadow-lg dark:bg-[#41464E] dark:hover:shadow-xl ">
      <>
        <div className="flex items-center border-b justify-between dark:border-[#fff]">
          <p className="px-4 py-2 text-[#495057] text-sm font-medium dark:text-white">
            Blast Id
          </p>
          <p className="px-4 py-2 text-sm text-[#878A99]">{blast?.blast.id}</p>
        </div>
        <div className="flex items-center  border-b justify-between dark:border-[#fff]">
          <p className="px-4 py-2 text-[#495057] text-sm font-medium dark:text-white">From</p>
          {
            <p className="px-4 py-2 text-sm text-[#878A99]">
              {blast?.blast.from_name}
              {' <'}
              {blast?.blast.from_email_address}
              {'> '}
            </p>
          }
        </div>
        {blast?.blast.reply_to_email_address && blast.blast.reply_to_name && (
          <div className="flex items-center  border-b justify-between dark:border-[#fff]">
            <p className="px-4 py-2 text-[#495057] text-sm font-medium dark:text-white">
              Reply To
            </p>
            {
              <p className="px-4 py-2 text-sm text-[#878A99]">
                {blast?.blast.reply_to_name}
                {' <'}
                {blast?.blast.reply_to_email_address}
                {'> '}
              </p>
            }
          </div>
        )}

        <ListSegments blast={blast} />

        <Status blast={blast} />

        {blast?.blast.status === 'sent' && (
          <div className="flex items-center justify-between">
            <p className="px-4 py-2 text-[#495057] text-sm font-medium dark:text-white">
              Completed At
            </p>
            <p className="px-4 py-2 text-sm text-[#878A99]">
              {blast?.blast.completed_at
                ? moment
                    .tz(blast.blast.completed_at, userCtx?.usersData?.timeZone || '')
                    .format('MMMM D, YYYY [at] h:mm A')
                : '-'}
            </p>
          </div>
        )}
      </>
    </Wrapper>
  );
};
export default BlastInformation;
