import DownArrow from 'assets/Images/Common/down-arrow.png';
import React, { FC, useContext, useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import { createBlasts } from 'services/apiHandlers/Campaigns/CampaignInformation';
import * as Yup from 'yup';
import { Toastify } from 'App';
import { z } from 'zod';
import { CampaignContext } from 'store/CampaignContext';
import { useOutsideClick } from 'app/hooks/useOutsideClick';

const responseStatusSchema = z.object({ status: z.number() });
export type ResponseStatus = z.infer<typeof responseStatusSchema>;

interface Props {
  getData: () => void;
  blasts: string[];
  setBlasts: (blast: string[]) => void;
}

const TagBlast: FC<Props> = ({ getData, blasts, setBlasts }) => {
  const campaignCtx = useContext(CampaignContext);
  const [showBlast, setShowBlast] = useState<boolean>(false);
  const [blast, setBlast] = useState<string>(
    (campaignCtx?.campaignData?.tag_list &&
      campaignCtx?.campaignData?.tag_list[0]) ||
      'Select a Blast'
  );
  const dropdownRef = useRef<HTMLDivElement>(null);
  useOutsideClick(dropdownRef, () => {
    setShowBlast(false);
  });

  const formik = useFormik({
    initialValues: { search: '', blastTag: '' },
    validationSchema: Yup.object({
      blastTag: Yup.string().required('Enter a blast value'),
    }),
    onSubmit: async (values, { resetForm }) => {
      const data = { tag: values.blastTag };
      try {
        const createBlastResponse = (await createBlasts(
          data
        )) as ResponseStatus;
        if (createBlastResponse.status === 200) {
          Toastify('Blast Added Successfully', 'success', 'tagblast1');
          getData();
          resetForm();
        }
      } catch (error) {
        Toastify('Blast Not Added', 'error', 'tagblast2');
      }
    },
  });

  const onBlastClickHandler = (blast: string) => {
    if (campaignCtx?.campaignData) {
      campaignCtx?.onUpdateCampaignData({
        ...campaignCtx.campaignData,
        tag_list: [blast],
      });
    }
    setBlast(blast);
    setShowBlast(false);
  };

  useEffect(() => {
    const filterBlasts = [...blasts].filter((blast) => {
      return blast.toLowerCase().includes(formik.values.search.toLowerCase());
    });
    setBlasts(filterBlasts);
  }, [formik.values.search]);
  return (
    <div className="relative">
      <h3 className=" text-lg leading-5 mb-4 text-[#495057] font-medium dark:text-white">
        Tag Blast
      </h3>
      <div className="relative w-full" ref={dropdownRef}>
        <div
          onClick={() => setShowBlast((prev) => !prev)}
          className="relative sm:h-[38px] h-9 border border-[#CED4DA] dark:border-[#fff] rounded-md w-full"
        >
          <div className="text-13 w-full text-[#495057] dark:text-[#CED4DA]  dark:text-white h-full py-2 px-4 focus:outline-none  flex items-center  dark:bg-[#41464E] ">
            {blast}
          </div>
          <img
            className="absolute right-1.5 top-3 "
            src={DownArrow}
            alt="Arrow"
          />
        </div>
        {showBlast && (
          <div className="border border-[#CED4DA] absolute bg-white  p-2.5  z-50 w-full rounded  dark:bg-[#41464E] ">
            <div className="w-full no-scrollbar overflow-auto max-h-[295px]">
              <div className="flex items-center">
                <input
                  className="w-full px-3 py-2.5 text-13 placeholder:text-gray-800 font-medium leading-3 text-gray-800 border border-[#CED4DA] rounded  dark:bg-[#41464E]  dark:text-white dark:focus:outline-none dark:placeholder:text-white mr-2.5"
                  type="search"
                  name="search"
                  value={formik.values.search}
                  placeholder="Filter Items"
                  onChange={formik.handleChange}
                />
              </div>
              {blasts.map((blast) => (
                <div
                  onClick={() => {
                    onBlastClickHandler(blast);
                  }}
                  key={blast}
                  className="py-1 px-3 mr-2.5 text-[#495057] dark:text-[#CED4DA]  hover:bg-primaryHover hover:text-white dark:hover:text-[#495057] dark:text-[#CED4DA]  dark:hover:bg-white dark:text-white cursor-pointer"
                >
                  <label className="text-sm font-normal leading-3 checkBox relative pl-1">
                    {blast}
                  </label>
                </div>
              ))}
            </div>
            <form
              onSubmit={formik.handleSubmit}
              className="mt-4 pt-4 border-t border-[#CED4DA]"
            >
              <label className="text-xs font-semibold leading-3 text-[#495057] dark:text-[#CED4DA]  dark:text-white block">
                Create new tag
              </label>
              <div className="flex items-center mt-2.5">
                <input
                  className="border border-[#CED4DA] placeholder:text-gray-500 rounded-md w-full max-w-md text-13 font-medium leading-3 sm:w-52 w-44 h-8 p-4  dark:bg-[#41464E]  dark:focus:outline-none dark:placeholder:text-white dark:text-white"
                  type="text"
                  placeholder="tag"
                  name="blastTag"
                  value={formik.values.blastTag}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                />
                <button
                  type="submit"
                  className="text-13 font-medium leading-5 rounded-md py-1.5 px-4 text-white bg-primary ml-5 ease-in duration-300 hover:bg-primary hover:scale-105"
                >
                  Add
                </button>
                {formik.errors.blastTag && formik.touched.blastTag ? (
                  <div className="text-xs leading-4 text-red-400 mt-1.5 ml-4">
                    {formik.errors.blastTag}
                  </div>
                ) : null}
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};
export default TagBlast;
