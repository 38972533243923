import Table from 'components/Table';
import React, { FC, useContext, useMemo } from 'react';
import { TableColumn } from 'react-data-table-component';
import { z } from 'zod';
import { ThemeContext } from 'store/ThemeContext';
import { ProductTableKeys } from 'staticdata/EventLogs/Data';

const productDataSchema = z.array(z.unknown());
type ProductData = z.infer<typeof productDataSchema>;
interface Props {
  data: string;
}
const applyWidth = (colName: string) => {
  if (colName === "Product Image") {
    return "150px"
  }
  else if (colName === "Product Name") {
    return "250px"
  }
  else if (colName === "Product Price") {
    return "200px"
  }
  else if (colName === "currencyType") {
    return "140px"
  }
  else if (colName === "Product URL") {
    return "450px"
  }
  else if (colName === "Product Quantity") {
    return "150px"
  }
}
export const isValidUrl = (url: string) => {
  try {
    new URL(url);
    return true;
  } catch (err) {
    return false;
  }
}

const imageExtensions: { [key: string]: string } = {
  '.jpeg': '.jpeg',
  '.jpg': '.jpg',
  '.png': '.png',
  '.gif': '.gif',
  '.bmp': '.bmp',
  '.webp': '.webp',
  '.tiff': '.tiff',
  '.svg': '.svg',
  '.ico': '.ico',
  '.jp2': '.jp2',
  '.jxr': '.jxr',
};


const ProductTable: FC<Props> = ({ data }) => {
  const parsedData: ProductData = JSON.parse(data);
  const themeCtx = useContext(ThemeContext)
  const customStyles = {
    table: {
      style: {
        backgroundColor: themeCtx?.theme === 'dark' ? '' : 'rgb(243,246,249)',
        borderRadius: '8px !important',
        paddingBottom: '8px !important'
      },
    },
    rows: {
      style: {
        color: '#212529',
        fontSize: '14px',
        borderBottom: 'unset !important',
        backgroundColor: themeCtx?.theme === 'dark' ? '#2A4562' : 'rgb(243,246,249)',
        height: '100%',
        paddingTop: '8px',
        paddingBottom: '8px',
        '&:hover': {
          backgroundColor: themeCtx?.theme === 'dark' ? '#2A4562' : 'rgb(243,246,249)',
        },
      },
    },
    cells: {
      style: {
        display: 'flex',
        paddingRight: '16px',
        paddingLeft: '16px',
      },
    },
    headRow: {
      style: {
        borderColor: themeCtx?.theme === 'dark' ? '#2A4562 !important' : '#E9EBEC !important',
        backgroundColor: themeCtx?.theme === 'dark' ? '#2A4562' : 'rgb(243,246,249)',
      },
    },
    headCells: {
      style: {
        color: themeCtx?.theme === 'dark' ? '#fffff' : '#878A99',
        fontSize: '14px',
        height: '44px',
        fontWeight: 600,
        display: 'flex',
        paddingRight: '16px',
        paddingLeft: '16px',
        borderColor: '#E9EBEC !important',
      },
    },
  };
  const columns: TableColumn<unknown>[] = useMemo(() => {
    if (parsedData.length === 0) {
      return [];
    }
    const tempKeys = Object.keys(parsedData[0] as Record<string, unknown>);
    // const keys = tempKeys.map((key) => (
    //   ProductTableKeys[key] ? ProductTableKeys[key] : key
    // ))
    return tempKeys.map((key) => ({
      name: <div className='dark:text-white'>{key}</div>,
      cell: (row) => {
        const value: string | number = (row as Record<string, number>)[key];
        const fileExtensionRegex = /\.([0-9a-z]+)(?=[?#])|(\.)(?:[\w]+)$/i;
        const matches = String(value).match(fileExtensionRegex);
        return imageExtensions[matches && matches[0] || ''] ? (
          <div className='h-[64px] w-[64px] '>
            <img
              className=" h-full rounded cursor-pointer"
              src={value !== null && value !== undefined ? String(value) : ''}
              alt="Product Image"
              onClick={() => { value !== null && value !== undefined && window.open(String(value), '_blank') }}
            />
          </div>
        ) : (
          <div className="dark:text-white">
            <div onClick={() => { isValidUrl(value.toString()) && window.open(String(value), '_blank') }} className={`${isValidUrl(value.toString()) && 'text-primary cursor-pointer underline'}`}>
              {value !== null && value !== undefined ? ((key.includes('price') || key.includes('total')) ? '$' + String(value) : String(value)) : ''}
            </div>
          </div>
        );
      },
      width: applyWidth(ProductTableKeys[key] ? ProductTableKeys[key] : key)
    }
    ));
  }, [parsedData]);
  return (
    <div className='mt-0'>
      <Table
        className="scrollbar-hide  "
        data={parsedData}
        columns={columns}
        pagination={false}
        customStyles={customStyles}
      />
    </div>
  );
};
export default ProductTable;












