import { FC } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { z } from 'zod';
import React from 'react';
import { listSegments, segmentDefinition } from 'services/constant/routes';

export const SegmentSchema = z.object({
  segment_json: z.string(),
  elastic_json: z.string(),
  segment_name: z.string(),
  subscriber_count: z.number(),
  status: z.string(),
});
export type Segment = z.infer<typeof SegmentSchema>;

interface Props {
  //TODO: Check if we can use them in single function
  addSegment: () => void;
}

const Footer: FC<Props> = ({ addSegment }) => {
  const { pathname } = useLocation();
  let create;
  if (pathname.includes('/create')) {
    create = true;
  }
  return (
    <div>
      <div className="py-3 mt-14 sm:flex-nowrap flex-wrap  border-t flex justify-end items-center">
      <div onClick={addSegment} className="text-right">
          <button
            type="button"
            className="sm:mb-0 mb-2 mr-3 px-4 underline sm:text-13 text-xs font-medium leading-4 text-primary"
          >
            Add More Group
          </button>
        </div>
        <Link
          to={`${listSegments}/${segmentDefinition}`}
          className="sm:mb-0 mb-2 text-center sm:text-13 text-xs py-2.5 px-4 h-11 flex justify-center items-center w-28 font-medium leading-5 mr-2  rounded-md bg-[#f3f6f9] hover:bg-[#cfd1d4] transition-bg text-[#212529]"
          data-pw="cancel-segment"
        >
          Cancel
        </Link>
        <div>
          <button
            data-pw={create ? '' : 'edit-segment-button'}
            type="submit"
            className="sm:mb-0 mb-2 py-2.5 px-4 h-11 mr-3 sm:text-sm text-xs font-medium leading-4 text-white rounded-md bg-primary ease-in-in duration-300 hover:bg-primary hover:scale-105"
          >
            {create ? 'Save Segment' : 'Edit Segment'}
          </button>
        </div>
        
      </div>
    </div>
  );
};
export default Footer;
