import { FC } from 'react';
import { Link } from 'react-router-dom';
import {
  campaignReport,
  campaigns as campaign,
} from 'services/constant/routes';
import React from 'react';
import Skeleton from 'components/Skeleton/Skeleton';
import { TopPerformingData } from '../index.type';
import Wrapper from 'components/Wrapper';

interface Props {
  campaigns: TopPerformingData[];
  loading: boolean;
}

const TopCampaigns: FC<Props> = ({ campaigns, loading }) => {
  return (
    <Wrapper parentClass='2xl:!mt-4 !mt-0 h-full' childClass='h-full'>
      <div className="min-h-[294px] overflow-hidden ">
        <div className="cardHeader dark:border-[#fff]">
          <h4 className="lg:text-base text-sm text-darkText font-medium dark:text-[#878A99]">
            Top Performing Campaigns
          </h4>
          <div className="group rounded py-1 px-2 bg-[#3575d526] hover:bg-primary cursor-pointer ease-in-in duration-300">
            <Link
              to={campaign}
              className="flex items-center text-primary group-hover:text-white dark:text-white text-xxs"
            >
              View All
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="ml-1 w-3 h-3 text-primary group-hover:text-white"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M17.25 8.25L21 12m0 0l-3.75 3.75M21 12H3"
                />
              </svg>
            </Link>
          </div>
        </div>
        {loading ? (
          <Skeleton columns={3} />
        ) : (
          <div className="2xl:pb-5">
            <ul>
              {campaigns?.length > 0 ? (
                campaigns?.map((campaign) => {
                  return (
                    <li
                      key={campaign.id}
                      className="flex items-center justify-between py-2 px-4"
                    >
                      <div className="flex items-center w-2/3">
                        <div className="w-6 h-6 rounded-full overflow-hidden">
                          <img
                            src="https://themesbrand.com/velzon/html/galaxy/assets/images/nft/img-01.jpg"
                            alt="top performing workflows"
                          />
                        </div>
                        <div className="ml-3">
                          <Link to={`${campaignReport}/${campaign.id}`}>
                            <h6 className="text-sm text-darkText dark:text-[#CED4DA]">
                              {campaign.name}
                            </h6>
                          </Link>
                        </div>
                      </div>
                      <div className='mr-3'>
                        <img
                          src="https://i.ibb.co/2vbPCdJ/down.png"
                          alt="down"
                        />
                      </div>
                      <div>
                        <h6 className='text-darkText dark:text-[#CED4DA]'>${Math.round(campaign.revenue).toLocaleString()}</h6>
                        <p className="text-xs text-secondary">
                          {campaign.orders} orders
                        </p>
                      </div>
                    </li>
                  );
                })
              ) : (
                <div className="flex mt-20 text-[#212529] justify-center items-center dark:text-white">
                  No Latest Campaigns Found
                </div>
              )}
            </ul>
          </div>
        )}
      </div>
    </Wrapper>
  );
};
export default TopCampaigns;
