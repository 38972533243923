import React, { useContext, useEffect, useMemo, useState } from 'react';
import { WorkflowLogs as Logs } from '.';
import { TableColumn } from 'react-data-table-component';
import { getWorkflowLogs } from 'services/apiHandlers/Workflows/Workflow';
import Table from 'components/Table';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import Loader from 'components/Loader';
import { z } from 'zod';
import {
  workflow,
  logs as workflowLogs,
  edit,
  subscribersProfile,
} from 'services/constant/routes';
import moment from 'moment';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { UserContext } from 'store/UserContext';
import Wrapper from 'components/Wrapper';

const workflowLogSchema = z.object({
  accountId: z.number(),
  executionId: z.string(),
  id: z.string(),
  message: z.string(),
  personIdentifier: z.string(),
  triggerId: z.string(),
  workflowNodeId: z.string(),
});
export type WorkflowLogs = z.infer<typeof workflowLogSchema>;

const workflowLogResponseSchema = z.object({
  data: z.array(workflowLogSchema),
  status: z.number(),
});
type WorkflowLogResponse = z.infer<typeof workflowLogResponseSchema>;


const formatDate = (id: string) => {
  const parts = id.split('-');
  const time =
    parseInt(parts[2].substring(1) + parts[1] + parts[0], 16) -
    122192928000000000;
  const date = moment(Math.floor(time / 10000));

  const formattedDate = date.format('MMM DD, YYYY hh:mm:ss A');
  return formattedDate;
};

const WorkflowLogs = () => {
  const [searchParams] = useSearchParams();
  const [showMoreButton, setShowMoreButton] = useState<boolean>(true);
  const [logs, setLogs] = useState<Array<WorkflowLogs>>([]);
  const [filteredLogs, setFilteredLogs] = useState<Array<WorkflowLogs>>([]);
  const [moreLoading, setMoreLoading] = useState<boolean>(false);
  const [logsLoading, setLogsLoading] = useState<boolean>(true);
  const userCtx = useContext(UserContext);
  const { id } = useParams();
  const status = searchParams.get('status');

  const columns: TableColumn<Logs>[] = useMemo(
    () => [
      {
        name: <div className="px-6">Timestamp</div>,
        cell: (row) => {
          return (
            <div className="dark:text-white cursor-pointer px-6 text-[#495057]">
              <div>{formatDate(row.id)}</div>
            </div>
          );
        },
        sortable: true,
      },
      {
        name: 'Subscriber',
        cell: (row) => {
          return (
            <Link
              to={`/${subscribersProfile}/${edit}/${row.personIdentifier}`}
              className="text-primary underline dark:text-white"
            >
              <div>{row.personIdentifier}</div>
            </Link>
          );
        },
      },
      {
        name: 'Execution Id',
        cell: (row) => {
          return (
            <Link
              to={`${workflow}/${workflowLogs}/${row.triggerId}/${row?.executionId}`}
              className="text-primary underline dark:text-white"
            >
              {row.executionId}
            </Link>
          );
        },
      },
      {
        name: 'Message',
        cell: (row) => {
          return <Tippy  interactive={true} 
                    content={ <div className="dark:text-white ">{row.message}</div> }>
                  <div className="dark:text-white max-w-[280px] px-3 truncate">{row.message}</div>
                </Tippy>;
        },
      },
    ],
    []
  );

  const getWorkflowLogsHandler = async (id: string, logId?: string) => {
    setLogsLoading(true);
    if (logId) {
      setMoreLoading(true);
    }

    try {
      const workflowLogResponse = (await getWorkflowLogs(
        id,
        logId
      )) as WorkflowLogResponse;
      if (workflowLogResponse.status === 200) {
        if (logId && workflowLogResponse.data.length === 0) {
          setLogs(logs);
          setShowMoreButton(false);
        } else {
          setLogs(workflowLogResponse.data);
        }
      }
    } catch (error) {
      console.log('error is : ', error);
    }
    setMoreLoading(false);
  };
  const showMore = () => {
    getWorkflowLogsHandler(id || '', logs[logs.length - 1].id);
    console.log('id: ', id);
  };

  useEffect(() => {
    const searchInterval = setTimeout(() => {
      if (userCtx?.filteredValue) {
        const tempLogs = logs?.filter((log) =>
          log.personIdentifier
            .toLowerCase()
            .includes(userCtx.filteredValue.toLowerCase())
        );
        setFilteredLogs(tempLogs);
      } else {
        setFilteredLogs(logs);
      }
      setLogsLoading(false);
    }, 1000);

    return () => {
      clearInterval(searchInterval);
    };
  }, [userCtx?.filteredValue, logs]);

  useEffect(() => {
    getWorkflowLogsHandler(id || '');
  }, []);

  return (
    <div className="md:px-6 px-3 py-5 font-inter  h-full min-h-[76vh]">
      <Wrapper>
     {logs.length === 0 && !logsLoading ? (
            <div className="flex justify-center my-24 text-lg text-[#212529] dark:text-white">
              This workflow is {status} and nothing has been logged for it yet
            </div>
          ) : (
            <>
              {/* <StyleWrapper> */}
              <Table
                className="scrollbar-hide"
                data={filteredLogs}
                columns={columns}
                pagination={false}
                progressPending={logsLoading}
                
              />
              {/* </StyleWrapper> */}
              {showMoreButton && (
                <button
                  disabled={moreLoading}
                  onClick={showMore}
                  className="fs-13 font-medium leading-5 text-white flex items-center ml-auto rounded-md py-3 sm:pl-4 px-2 sm:pr-3.5 font-inter bg-primary mt-5 ease-in duration-300 hover:bg-primaryHover hover:scale-105"
                >
                  {moreLoading && (
                    <div className="">
                      <Loader />
                    </div>
                  )}
                  {moreLoading ? 'Loading' : 'Show More'}
                </button>
              )}
            </>
          )}
       </Wrapper>
    </div>
  );
};
export default WorkflowLogs;
