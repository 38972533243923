import { Toastify } from 'App';
import { useFormik } from 'formik';
import { useEffect } from 'react';
import xmlToJSON from 'utils/xmlToJSON';
import { formatXmlJsonResponse } from 'utils/common';
import {
  createList,
  getSingleList,
  updateList,
} from 'services/apiHandlers/List-Segments/SegmentsList';
import * as Yup from 'yup';
import { z } from 'zod';
import { useParams, Link, useNavigate } from 'react-router-dom';
import React from 'react';
import { list, listSegments } from 'services/constant/routes';
import Wrapper from 'components/Wrapper';
import BreadcrumbsWrapper from 'components/Wrapper/BreadcrumbsWrapper';

const responseStatusSchema = z.object({ status: z.number() });
type ResponseStatus = z.infer<typeof responseStatusSchema>;

const NewList = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const getSingleListHandler = async () => {
    try {
      /* eslint-disable */
      const getListsResponse: any = await getSingleList(id);
      const data: any = xmlToJSON.parseString(getListsResponse.data);
      const list: any = formatXmlJsonResponse(data.list);
      /* eslint-enable  */
      formik.setFieldValue('name', list[0].name);
      formik.setFieldValue('description', list[0].description);
    } catch (error) {
      console.log('error is: ', error);
    }
  };

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      const data = {
        name: values.name,
        description: values.description,
        id: Number(id),
      };
      const list = {
        list: { name: values.name, description: values.description },
      };
      try {
        const createListResponse = id
          ? ((await updateList(data)) as ResponseStatus)
          : ((await createList(list)) as ResponseStatus);
        if (
          createListResponse.status === 200 ||
          createListResponse.status === 201
        ) {
          Toastify(
            `${id ? 'Changes Done' : 'Created'} Successfully`,
            'success',
            'NewList1'
          );
          navigate(-1);
        }
      } catch (error) {
        Toastify('Changing List Failed', 'error', 'NewList2');
      }
    },
  });

  useEffect(() => {
    if (id) {
      getSingleListHandler();
    }
  }, [id]);

  return (
    <>
      <BreadcrumbsWrapper />
      <form onSubmit={formik.handleSubmit}>
        <div className="px-6 min-h-[75vh]">
          <Wrapper>
            <>
              <div className="flex items-center">
                <div className="flex justify-between items-center px-5 border-b py-[18px] w-full">
                  <h2 className="sm:text-xl text-base font-medium leading-5 text-black-300 dark:text-white">
                    {id ? 'Edit List' : 'Create New List'}
                  </h2>
                  
                </div>
              </div>
              <div className="sm:flex justify-between p-4">
                <div className="sm:w-6/12 w-full sm:pr-3">
                  <p className="text-sm font-medium leading-3 tracking-wide text-[#212529] dark:text-white">
                    Segment Name
                  </p>
                  <input
                    className="w-full placeholder:font-normal focus:border-primary dark:focus:border-white focus:outline-none py-2 px-4 text-13 leading-3 border border-[#ced4da] placeholder:text-gray-500 dark:placeholder:text-white dark:border-[#fff]  dark:bg-[#41464E]  rounded mt-2 text-[#495057] dark:text-[#CED4DA]  dark:text-white"
                    type="text"
                    name="name"
                    placeholder="Segment name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.name && formik.values.name?.length === 0 ? (
                    <div
                      data-pw="Segment-Required"
                      className="text-xs leading-4 text-red-400 mt-1.5"
                    >
                      {formik.errors.name ? formik.errors.name : null}
                    </div>
                  ) : null}
                </div>
                <div className="sm:w-6/12 w-full sm:pl-3 sm:mt-0 mt-4">
                  <p className="text-sm font-medium leading-3 tracking-wide text-[#212529] dark:text-white ">
                    Description
                  </p>
                  <input
                    className="w-full placeholder:font-normal focus:border-primary dark:focus:border-white focus:outline-none py-2 px-4 text-13 leading-3 border border-[#ced4da] placeholder:text-gray-500 dark:placeholder:text-white dark:border-[#fff]  dark:bg-[#41464E]  rounded mt-2 text-[#495057] dark:text-[#CED4DA]  dark:text-white"
                    type="Text"
                    name="description"
                    placeholder="Segment description"
                    value={formik.values.description}
                    onChange={formik.handleChange}
                  />
                </div>
                
              </div>
              <div className="flex items-center text-13 py-3 px-4 justify-end font-medium leading-5">
                    <Link
                      to={`${listSegments}/${list}`}
                      className="text-center sm:text-13 text-xs py-2.5 px-4 h-11 flex justify-center items-center w-28 font-medium leading-5 mr-2  rounded-md bg-[#f3f6f9] hover:bg-[#cfd1d4] transition-bg text-[#212529]"
                    >
                      Cancel
                    </Link>
                    <button
                      data-pw="Create-List"
                      type="submit"
                      className="text-sm font-medium leading-5 rounded-md px-4 py-2.5 w-28 h-11 bg-primary hover:bg-primary text-white hover:scale-105 ease-in duration-300"
                    >
                      {id ? 'Save' : 'Create'}
                    </button>
                  </div>
            </>
          </Wrapper>
        </div>
      </form>
    </>
  );
};
export default NewList;
