import Close from 'assets/Images/Workflow/close-Icon.png';
import { FC, useContext, useEffect, useState } from 'react';
import Plus from 'assets/Images/ListsegmentImages/plus-blue.png';
import { useFormik, FormikValues } from 'formik';
import Dropdown from 'components/Dropdown/MultiElementsDropdown';
import * as Yup from 'yup';
import { getUsers, sendTestEmail } from 'services/apiHandlers/Campaigns/Emails';
import { z } from 'zod';
import { useParams } from 'react-router-dom';
import React from 'react';
import { UserContext } from 'store/UserContext';
import Loader from 'components/Loader';

const usersDataSchema = z.object({
    administrator: z.boolean(),
    company_name: z.string(),
    created_at: z.string(),
    email_address: z.string(),
    facebook_user_id: z.string(),
    first_name: z.string(),
    id: z.number(),
    last_name: z.string(),
    may_export: z.boolean(),
    need_password_reset: z.string(),
    phone_number_fax: z.string(),
    phone_number_home: z.string(),
    phone_number_mobile: z.string(),
    phone_number_office: z.string(),
    phone_number_office_ext: z.string(),
    previous_password: z.string(),
    title: z.string(),
    updated_at: z.string(),
});
type UsersData = z.infer<typeof usersDataSchema>;

const getUsersResponseSchema = z.object({
    status: z.number(),
    data: z.object({ users: z.array(usersDataSchema) }),
});
type UsersResponse = z.infer<typeof getUsersResponseSchema>;

const optionsSchema = z.object({ name: z.string(), value: z.string() });
type Options = z.infer<typeof optionsSchema>;

interface Props {
    onClose: () => void;
}
const SendTestModal: FC<Props> = ({ onClose }) => {
    const [showOthersSection, setShowOthersSection] = useState<boolean>(false);
    const [showEmailError, setShowEmailError] = useState<boolean>(false);
    const [selectedRecipients, setSelectedRecipients] = useState<Array<string>>(
        []
    );
    const [recipientOptions, setRecipientOptions] = useState<Array<Options>>(
        []
    );
    const [formIsValid, setFormIsValid] = useState<boolean>(false);
    const [showRecipientError, setShowRecipientError] =
        useState<boolean>(false);
    const [showSendTestError, setShowSendTestError] = useState<boolean>(false);
    const [usersData, setUsersData] = useState<Array<UsersData>>([]);
    const [recipients, setRecipients] = useState<string>('');
    const [testSubmitted, setTestSubmitted] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const userCtx = useContext(UserContext);

    const {id} = useParams();



    const checkEmails = (users: string) => {
        const commaCount = users.split(',').length - 1;
        return commaCount >= 5;
    };

    const getData = async () => {
        try {
            const getUsersResponse = (await getUsers()) as UsersResponse;
            if (getUsersResponse.status === 200) {
                setUsersData(getUsersResponse.data.users);
                const tempOptions = getUsersResponse.data.users.map((data) => {
                    return {
                        name: data.first_name + ' ' + data.last_name,
                        value: data.id.toString(),
                    };
                });
                setRecipientOptions(tempOptions);
            }
        } catch (error) {
            console.log('error is : ', error);
        }
    };

    const formik: FormikValues = useFormik({
        enableReinitialize: true,
        initialValues: {
            from: '',
            subject: '',
            others: '',
        },
        validationSchema: Yup.object({
            from: Yup.string()
                .email('Email is Invalid')
                .required('Email is required'),
            subject: Yup.string().required('Required'),
        }),
        onSubmit: async (values) => {
            if(selectedRecipients.length !==0){
            setLoading(true);
            const data = {
                to: formik.values.others
                    ? recipients + ',' + formik.values.others
                    : recipients,
                subject: values.subject,
                from: {
                    email_address: values.from,
                    name: values.from,
                },
                body: {
                    email_id: id || '0',
                },
                email_draft_in_session: true,
            };
            try {
                const sendTestResponse = (await sendTestEmail(
                    data
                )) as UsersResponse;
                if (sendTestResponse.status === 200) {
                    setShowSendTestError(false);
                    setTestSubmitted(true);
                }
            } catch (error) {
                setShowSendTestError(true);
                setTestSubmitted(false);
            }
            setLoading(false);
        } else setShowRecipientError(true)
        },
    });

    const optionChangeHandler = (value: string[]) => {
        if(selectedRecipients.length === 0)
        setShowRecipientError(true)


        let tempRecipient = '';
        usersData.map((user) => {
            if (value.includes(user.id.toString())) {
                tempRecipient =
                    tempRecipient.length > 0
                        ? tempRecipient + ',' + user.email_address
                        : user.email_address;
            }
        });
        setRecipients(tempRecipient);
        setSelectedRecipients(value);
        if (formik.isValid) {
            setFormIsValid(true);
        } else {
            setFormIsValid(false);
            setShowRecipientError(true);
        }

        if (value.length === 0) {
            setShowRecipientError(true);
            setFormIsValid(false);
        } else {
            setShowRecipientError(false);
            setFormIsValid(true);
        }
    };

    useEffect(() => {
        if (
            formik.isValid &&
            selectedRecipients.length !== 0 &&
            !checkEmails(formik.values.others)
        ) {
            setFormIsValid(true);
        } else {
            setFormIsValid(false);
        }
    }, [formik.values.for, formik.values.subject, selectedRecipients]);

    useEffect(() => {
        if (checkEmails(formik.values.others)) {
            setShowEmailError(true);
            setFormIsValid(false);
        } else if (formik.values.others.length > 0) {
            setShowEmailError(false);
            setFormIsValid(true);
        }
    }, [formik.values.others]);

    useEffect(() => {
        if (userCtx?.currentUserData?.email) {
            formik.setFieldValue('from', userCtx.currentUserData.email);
        }
        getData();
    }, []);

    return (
        <form onSubmit={formik.handleSubmit} className="px-4">
            <div className="w-full max-w-md p-5 absolute right-0 z-50">
                <div className="relative">
                    <img
                        src={Close}
                        onClick={() => onClose()}
                        className="absolute top-0 right-0 mt-2 mr-2 border-none cursor-pointer"
                    />
                </div>
                <div className="bg-white rounded-lg shadow-md shadow-gray-200 border border-gray-200 dark:bg-[#41464E]">
                    <div className="bg-white rounded-t-lg p-4 dark:bg-[#41464E]">
                        <div>
                            {(showSendTestError || testSubmitted) && (
                                <div
                                    className={`${
                                        showSendTestError
                                            ? 'bg-[#f7e8e7]'
                                            : testSubmitted
                                            ? 'bg-green-100'
                                            : ''
                                    } ml-2 mr-2 mb-2 px-6 py-3  rounded mt-4`}
                                >
                                    <p
                                        className={` ${
                                            showSendTestError
                                                ? 'text-[#CB5A5A]'
                                                : testSubmitted
                                                ? 'text-green-600'
                                                : ''
                                        } text-sm font-normal leading-4 `}
                                    >
                                        {showSendTestError
                                            ? 'Send Failed There was a problem sending your email(s)!'
                                            : testSubmitted
                                            ? 'Send Succesfull. Your test email(s) were sent successfully!'
                                            : ''}
                                    </p>
                                </div>
                            )}
                            <label className="text-xs font-semibold leading-3 block tracking-wide text-gray-500 uppercase mb-3 dark:text-white">
                                From
                            </label>
                            <div className="flex items-center gap-3">
                                <input
                                    className="text-13 font-medium leading-3 text-[#495057] dark:text-[#CED4DA]  w-full py-3 px-4 rounded-md border border-[#ced4da] shadow-sm shadow-gray-200 focus:outline-none focus:border-primary  dark:bg-[#41464E]  dark:text-white dark:placeholder:text-white"
                                    type="email"
                                    name="from"
                                    value={formik.values.from}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            {formik.errors.from && (
                                <div className="text-xs leading-4 text-red-400 mt-1.5">
                                    {formik.errors.from}
                                </div>
                            )}
                        </div>
                        <div>
                            <label className="text-xs font-semibold leading-3 block tracking-wide text-gray-500 uppercase mb-3 mt-4 dark:text-white">
                                Subject
                            </label>
                            <div className="flex items-center gap-3">
                                <input
                                    className="text-13 font-medium leading-3 text-[#495057] dark:text-[#CED4DA]  w-full py-3 px-4 rounded-md border border-[#ced4da] shadow-sm shadow-gray-200 focus:outline-none focus:border-primary  dark:bg-[#41464E]  dark:text-white dark:placeholder:text-white"
                                    type="text"
                                    name="subject"
                                    value={formik.values.subject}
                                    onChange={formik.handleChange}
                                />
                            </div>
                             {formik.errors.subject && (
                                <div className="text-xs leading-4 text-red-400 mt-1.5">
                                    {formik.errors.subject}
                                </div>
                            )}
                        </div>
                        <div>
                            <label className="text-xs font-semibold leading-3 block tracking-wide text-gray-500 uppercase mb-3 mt-4 dark:text-white">
                                Email To
                            </label>
                            {showRecipientError && (
                                <div className="ml-2 mr-2 mb-2 px-6 py-3 bg-[#f7e8e7] rounded mt-4">
                                    <p className="text-sm font-normal leading-4 text-[#CB5A5A]">
                                        Please select recipient for your email
                                    </p>
                                </div>
                            )}
                            <div className="z-10 bg-white  dark:bg-[#41464E]  border-gray-200 dark:border-[#fff] rounded-md dropdown-show-hide relative sm:h-11 h-9">
                                <Dropdown
                                    options={recipientOptions}
                                    selectedList={selectedRecipients}
                                    onOptionChange={(value) => {
                                        optionChangeHandler(value);
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    {showEmailError && (
                        <div className="ml-2 mr-2 mb-2 px-6 py-3 bg-[#f7e8e7] rounded mt-4">
                            <p className="text-sm font-normal leading-4 text-[#CB5A5A]">
                                Maximum number of additional recipients is
                                limited to five.
                            </p>
                        </div>
                    )}
                    <div 
                        onClick={() => setShowOthersSection((prev) => !prev)}
                        className="ml-2 flex items-center mb-3"
                    >
                        <img
                            src={Plus}
                            alt="Edit"
                            className="inline ml-2 mr-1.5 dark:invert dark:brightness-0"
                        />
                        <div className="text-sm font-medium leading-4 text-[#495057] dark:text-[#CED4DA]  cursor-pointer hover:text-primary dark:text-white">
                            Send to Other People ?
                        </div>
                    </div>
                    {showOthersSection && (
                        <>
                            <div className="ml-3 items-center gap-3 mb-2">
                                <input
                                    className="text-13 font-medium leading-3 text-[#495057] dark:text-[#CED4DA]  w-full py-3 px-4 rounded-md border border-[#ced4da] shadow-sm shadow-gray-200 focus:outline-none focus:border-primary w-7/12  dark:bg-[#41464E]  dark:text-white h-11"
                                    type="text"
                                    name="others"
                                    value={formik.values.others}
                                    onChange={formik.handleChange}
                                />
                            </div>
                            <label className="ml-3 text-xs font-semibold leading-3 block tracking-wide text-gray-500 uppercase mb-3 mt-4 dark:text-white">
                                Up to 5 emails, separate by commas
                            </label>
                        </>
                    )}
                    <div className="border-t border-gray-200"></div>
                    <div className="bg-white rounded-b-lg py-3 px-5 dark:bg-[#41464E]">
                        <div className="flex justify-end items-center">
                            <button
                                type="submit"
                                disabled={!formIsValid}
                                className={`flex items-center text-13 font-medium leading-5 rounded-md px-4 py-2 mr-6 ${
                                    loading
                                        ? 'hover:border-gray-700 bg-gray-500 text-black'
                                        : 'hover:border-blue-700 bg-primary text-white'
                                } ${
                                    !formIsValid
                                        ? 'border-2 border-primary  ease-in duration-300 hover:bg-primaryHover hover:scale-105 cursor-pointer'
                                        : 'bg-primary text-white'
                                }`}
                            >
                                {loading && <Loader />}
                                {loading ? 'Sending Test' : 'Send Test'}
                            </button>
                            <button
                                type="button"
                                onClick={() => {
                                    onClose();
                                }}
                                className="text-center sm:text-13 text-xs py-2.5 px-4 h-11 flex justify-center items-center w-28 leading-5 mr-2  rounded-md bg-[#f3f6f9] hover:bg-[#cfd1d4] transition-bg text-[#212529]"
                            >
                                Cancel
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    );
};
export default SendTestModal;
