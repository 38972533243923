import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import SegmentDefinition from './definition';
import { IDefinition } from './definition';
import {
  createSegmentHandler,
  getSingleSegment,
} from 'services/apiHandlers/List-Segments/Segment';
import Footer from './footer';
import * as Yup from 'yup';
import { Toastify } from 'App';
import { z } from 'zod';
import ErrorBoundary from 'components/ErrorBoundary';

import React from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import BreadcrumbsWrapper from 'components/Wrapper/BreadcrumbsWrapper';
import Wrapper from 'components/Wrapper';

export const SegmentSchema = z.object({
  segment_json: z.string(),
  elastic_json: z.string(),
  segment_name: z.string(),
  subscriber_count: z.number(),
  status: z.string(),
});
export type Segment = z.infer<typeof SegmentSchema>;

const responseSchema = z.object({ status: z.number(), data: SegmentSchema });
type response = z.infer<typeof responseSchema>;

const responseStatusSchema = z.object({ status: z.number() });
export type ResponseStatus = z.infer<typeof responseStatusSchema>;

const DefaultDefinition: IDefinition = {
  definition: '',
  has_condition: '',
  order_condition: 'at least once',
  revenue_condition: '',
  order_count: 0,
  revenue_count: 0,
  time_condition: 'over all time',
  time_count1: 0,
  time_count2: 15,
  time_unit: '',
  date: moment().format('YYYY-MM-DD'),
  filterCondition: null,
  filterConditionValue: null,
  andOr: '',
  filterSearchValue: 'contains',
};

export const EditDataSchema = z.object({
  elastic_json: z.string(),
  segment_json: z.string(),
  segment_name: z.string(),
  status: z.string(),
  subscriber_count: z.number(),
});
export type EditData = z.infer<typeof EditDataSchema>;

const SegmentStructure = () => {
  const [error, setError] = useState(false);
  const [definition, setDefinition] = useState<Array<Array<IDefinition>>>([
    [DefaultDefinition],
  ]);

  const [editData, setEditData] = useState<Segment | null | undefined>();
  const { id } = useParams();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const duplicate = queryParams.get('duplicate') === 'true';

  const formik = useFormik({
    initialValues: {
      segmentName: '',
    },
    validationSchema: Yup.object({
      segmentName: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      const tempDefinition = [...definition];
      for (let i = 0; i < definition.length; i++) {
        const singleDefinition = definition[i];
        for (let j = 0; j < singleDefinition.length; j++) {
          if (
            singleDefinition[j].definition.length === 0 ||
            singleDefinition[j].has_condition.length === 0 ||
            singleDefinition[j].filterCondition?.length === 0 ||
            singleDefinition[j].filterConditionValue?.length === 0
          ) {
            setError(true);
            return;
          }
          if (singleDefinition[j].filterCondition === null) {
            tempDefinition[i][j].filterCondition = '';
          }
          if (singleDefinition[j].filterConditionValue === null) {
            tempDefinition[i][j].filterConditionValue = '';
          }
        }
      }

      const segmentData = {
        elastic_json: '',
        segment_json: JSON.stringify(definition),
        segment_name: values.segmentName,
        update: editData && !duplicate ? true : false,
      };

      try {
        if (editData) {
          const editSegmentResponse = (await createSegmentHandler(
            segmentData
          )) as ResponseStatus;
          if (editSegmentResponse.status === 200) {
            Toastify('Segment Edited Successfully', 'success', 'index1');
            navigate(-1);
          }
        } else {
          const createSegmentResponse = (await createSegmentHandler(
            segmentData
          )) as ResponseStatus;
          if (createSegmentResponse.status === 200) {
            Toastify('Segment Created Successfully', 'success', 'index2');
            navigate(-1);
          }
        }
      } catch (error) {
        Toastify('Segment Changes Failed', 'error', 'index3');
      }
    },
  });
  const editHandler = async (name: string) => {
    const editSegmentResponse = (await getSingleSegment(name)) as response;

    if (editSegmentResponse.status === 200) {
      const EditResponse = EditDataSchema.safeParse(editSegmentResponse.data);
      if (EditResponse.success) {
        setEditData(editSegmentResponse.data);
      }
    }
  };
  const onChangeHandler = (
    [key, value]: [string, string],
    [outerindex, innerIndex]: [number, number]
  ) => {
    if ((key === 'definition' || key === 'has_condition') && value.length > 0) {
      setError(false);
    }
    const tempdefinition = [...definition];
    tempdefinition[outerindex][innerIndex] = {
      ...tempdefinition[outerindex][innerIndex],
      [key]: value,
    };
    setDefinition(tempdefinition);
  };

  const addSegmentHandler = () => {
    const tempDefiniton = [...definition];
    tempDefiniton.push([DefaultDefinition]);
    setDefinition(tempDefiniton);
  };

  const addDefinitionHandler = ([outerindex]: [number, number]) => {
    const tempDefiniton = [...definition];
    tempDefiniton[outerindex].push(
      tempDefiniton.length > 0
        ? { ...DefaultDefinition, andOr: 'or' }
        : { ...DefaultDefinition }
    );
    setDefinition(tempDefiniton);
  };

  const onDeleteHandler = ([outerindex, innerIndex]: [number, number]) => {
    const tempDefiniton = [...definition];
    tempDefiniton.map((defArr, i) => {
      if (defArr.length === 1 && outerindex === i) {
        tempDefiniton.splice(i, 1);
        definition.length === 1 &&
          setTimeout(() => {
            setDefinition([[DefaultDefinition]]);
          }, 1000);
      }
      defArr.map(
        (_, j) => innerIndex === j && outerindex === i && defArr.splice(j, 1)
      );
    });
    setDefinition(tempDefiniton);
  };

  const deleteFilterGroupHandler = (
    filterDefinition: IDefinition,
    outerIndex: number,
    innerIndex: number
  ) => {
    const tempDefinition = [...definition];
    tempDefinition.map((definition, i) => {
      if (outerIndex === i) {
        definition.map((_, j) => {
          if (j === innerIndex) {
            tempDefinition[outerIndex][innerIndex] = filterDefinition;
          }
        });
      }
    });
    setDefinition(tempDefinition);
  };

  useEffect(() => {
    if (id) {
      editHandler(id);
    }
  }, []);
  useEffect(() => {
    if (editData?.segment_json) {
      const tempDefinition = [...JSON.parse(editData?.segment_json)];
      for (let i = 0; i < tempDefinition.length; i++) {
        const singleDefinition = tempDefinition[i];
        for (let j = 0; j < singleDefinition.length; j++) {
          if (singleDefinition[j].filterCondition === '') {
            tempDefinition[i][j].filterCondition = null;
          }
          if (singleDefinition[j].filterConditionValue === '') {
            tempDefinition[i][j].filterConditionValue = null;
          }
        }
      }

      setDefinition(tempDefinition);
      formik.setFieldValue(
        'segmentName',
        duplicate ? editData?.segment_name + '(Copy)' : editData?.segment_name
      );
    }
  }, [editData]);

  return (
    <ErrorBoundary>
      <BreadcrumbsWrapper />
      <div className="px-4 py-8 min-h-[76vh]">
        <Wrapper>
          <form onSubmit={formik.handleSubmit}>
            <div className="flex justify-between items-center px-5 border-b dark:border-[#fff] border-[#e9ebec] py-[18px]">
              <h2 className="text-base text-[#495057] font-medium  dark:text-[#CED4DA]">
                {editData ? 'Edit Segment' : 'Create new segment'}
              </h2>
            </div>

            <div className="p-4">
              <div className="sm:flex justify-between items-center">
                <div className="sm:w-6/12 w-full sm:pr-3">
                  <p className="text-sm font-medium leading-3 tracking-wide text-[#212529] dark:text-white ">
                    Segment Name
                  </p>
                  <input
                    disabled={editData && !duplicate ? true : false}
                    className="w-full px-4 py-2 text-13 leading-3  dark:bg-[#41464E]  border border-[#ced4da] dark:border-[#fff] rounded mt-2 text-[#212529] dark:text-white sm:h-[38px]"
                    data-pw="segment-name"
                    type="Name"
                    name="segmentName"
                    placeholder="Segment Name"
                    value={formik.values.segmentName}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.segmentName ? (
                    <div
                      data-pw="segment-name-required"
                      className="text-xs leading-4 text-red-400 mt-1.5"
                    >
                      {formik.errors.segmentName
                        ? formik.errors.segmentName
                        : null}
                    </div>
                  ) : null}
                </div>
              </div>
              <p className="text-xs dark:text-white font-semibold leading-3 tracking-wide uppercase text-[#495057] dark:text-[#CED4DA]  dark:text-white mb-3 mt-6">
                Definition
              </p>
              <div>
              <>
                {definition.map((def, i) => {
                  const defLength = def.length;
                  return (
                    <>
                    <Wrapper childClass='p-4'>
                      <div
                        // eslint-disable-next-line react/no-array-index-key
                        key={i}
                        className=" text-sm font-medium leading-3 tracking-wide text-[#212529] dark:text-white "
                      >
                        <>
                          {def.map((segmentDefinition, j) => {
                            return (
                              <>
                                <SegmentDefinition
                                  // eslint-disable-next-line react/no-array-index-key
                                  key={j}
                                  definition={segmentDefinition}
                                  showOr={defLength > 1 && j > 0}
                                  onChange={(key: string, value: string) =>
                                    onChangeHandler([key, value], [i, j])
                                  }
                                  onDelete={() => onDeleteHandler([i, j])}
                                  addDefinition={() =>
                                    {addDefinitionHandler([i, j])}
                                  }
                                  error={error}
                                  showMore={j === defLength - 1}
                                  setDefinition={(definition: IDefinition) =>
                                    deleteFilterGroupHandler(definition, i, j)
                                  }
                                />
                                {defLength > 1 && j + 1 !== defLength && (
                                  <div className="flex items-center my-10">
                                    <div className="w-full h-px bg-gray-800 dark:bg-[#41464E]"></div>
                                    <div
                                      data-pw="segment-or"
                                      className="w-28 text-center py-[6px] px-2.5 font-normal text-[#212529] rounded text-sm leading-3 mx-2"
                                    >
                                      OR
                                    </div>
                                    <div className="w-full h-px bg-gray-800 dark:bg-[#41464E]"></div>
                                  </div>
                                )}
                              </>
                            );
                          })}
                        </>
                      </div>
                      </Wrapper>
                      {definition.length >= 2 &&
                        i + 1 !== definition.length && (
                          
                          <div className="flex items-center my-10">
                            <div className="w-full h-px bg-gray-800 dark:bg-[#41464E]"></div>
                            <div
                              data-pw="segment-and"
                              className="w-28 text-center py-[6px] px-2.5 text-[#212529] rounded text-sm leading-3 mx-2"
                            >
                              AND
                            </div>
                            <div className="w-full h-px bg-gray-800 dark:bg-[#41464E]"></div>
                          </div>
                         
                        )}
                    </>

                  );
                })}
              </>
            </div>
            </div>
            {definition.length > 0 && (
                  <Footer addSegment={addSegmentHandler} />
                )}
            
          </form>
        </Wrapper>
      </div>
    </ErrorBoundary>
  );
};

export default SegmentStructure;
