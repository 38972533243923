import { FC, useRef, useState } from 'react';
import DownArrow from 'assets/Images/Common/down-arrow.png';
import { useOutsideClick } from 'app/hooks/useOutsideClick';
import React from 'react';
interface Props {
  options: string[] | undefined;
  onSelect?: (option: string) => void;
  value: string | undefined;
  disableOptions?: string[];
  id?: string;
}
const SortByDropdown: FC<Props> = ({
  options,
  onSelect,
  value,
  disableOptions,
  id,
}) => {
  const [showDropdown, setShowDropdown] = useState<boolean>(false);
  const [selectedDropdown, setSelectedDropdown] = useState<string>('');
  const dropdownRef = useRef<HTMLDivElement>(null);
  useOutsideClick(dropdownRef, () => {
    setShowDropdown(false);
  });
  return (
    <div
      onClick={() => onSelect && setShowDropdown((prev) => !prev)}
      className="relative flex items-center ml-2"
      ref={dropdownRef}
    >
      <p className="text-xs font-semibold text-[#212529] dark:text-[#CED4DA] mr-1">Sort by:</p>
      <div
        className={`${
          showDropdown ? 'rounded-b-none border-primary dark:border-white' : ''
        }  text-sm font-normal text-[#878A99]  dark:text-white flex items-center justify-between cursor-pointer`}
      >
        {value}
        {onSelect && (
          <span>
            <img
              className="dark:brightness-0 dark:invert ml-1"
              src={DownArrow}
              alt="Arrow"
            />
          </span>
        )}
      </div>
      {showDropdown && (
        <div
          className={`dropdownAnimation  overflow-auto z-[999] top-8 dark:border-[#fff] dark:border rounded-md bg-white dark:bg-[#41464E] shadow-lg scrollbar-hide cursor-pointer absolute w-full`}
        >
          {options?.map((option, index) => (
            <button
              onClick={() => {
                setSelectedDropdown(option);
                onSelect && onSelect(option);
              }}
              className={`${
                disableOptions?.includes(option)
                  ? ' cursor-not-allowed '
                  : `dark:hover:bg-[#061F3b] ${
                      selectedDropdown !== option && 'hover:bg-[#eff2f7]'
                    } cursor-pointer `
              } ${options.length === index + 1 ? ' border-b-0' : ''} ${
                selectedDropdown === option && selectedDropdown !== options[0]
                  ? 'bg-[#0A4D85] text-white font-medium'
                  : 'bg-white dark:text-[#A6AFB8] dark:bg-[#41464E] dark:border-b dark:border-[#fff] dark:rounded'
              } p-3 text-sm block w-full text-left leading-4 `}
              key={option}
              disabled={disableOptions?.includes(option)}
              data-pw={index === options.length - 1 ? 'last-option' : ''}
              id={index === options.length - 1 ? id : '0'}
            >
              {option}
            </button>
          ))}
        </div>
      )}
    </div>
  );
};
export default SortByDropdown;