import EmailTemplates from './EmailTemplates';
import TagBlast from './TagBlast';
import { Option,CampaignEmail } from './Types';

import React,{ FC } from 'react';

interface Props {
    onEmailChange: (id: string) => void;
    selectedTemplate: string;
    setSelectedTemplate: (name: string) => void;
    setShowEmailTemplateError: (show: boolean) => void;
    showEmailTemplateError: boolean;
    getData: ()=>void;
    blasts: string[];
    setBlasts: (blast: string[])=>void;
    emailOptions: Option[];
    campaigns: CampaignEmail[];
}
const Forms: FC<Props> = ({
    onEmailChange,
    selectedTemplate,
    setSelectedTemplate,
    setShowEmailTemplateError,
    showEmailTemplateError,
    getData,
    blasts,
    setBlasts,
    emailOptions,
    campaigns
}) => {
    return (
        <div className="">
            <TagBlast getData={getData} blasts={blasts} setBlasts={setBlasts}/>
            <EmailTemplates
                setShowEmailTemplateError={setShowEmailTemplateError}
                showEmailTemplateError={showEmailTemplateError}
                selectedTemplate={selectedTemplate}
                setSelectedTemplate={setSelectedTemplate}
                onEmailChange={(id: string) => onEmailChange(id)}
                emailOptions={emailOptions}
                campaigns={campaigns}
            />
        </div>
    );
};
export default Forms;