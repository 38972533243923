import { FC } from 'react';
import React from "react"
interface Props {
  onTabClick: (tab: string) => void;
  activeTab: string;
}

const tabs = ['Mailer', 'Sender', 'Content', 'Options'];
const Tabs: FC<Props> = ({ onTabClick, activeTab }) => {
  return (
    <div>
      <div className="items-center flex border-b mr-7">
        {tabs.map((tab) => (
          <div key={tab}>
            <div
              key={tab}
              onClick={() => {
                onTabClick(tab);
              }}
              className="pt-4"
            >
              <button
                type="button"
                className={`text-sm font-medium leading-3 text-darkParagraphColor dark:text-white dark:text-white sm:px-6 px-2 pb-4 hover:text-primary hover:border-b-2 border-primary dark:hover:border-white group hover:-mb-0.5 ${
                  tab === activeTab
                    ? ' border-b-2 border-primary dark:border-white text-primary -mb-0.5'
                    : ''
                } flex justify-center items-center group`}
              >
                <span
                  className={
                    activeTab === tab ? 'dark:text-white text-primary' : ''
                  }
                >
                  {tab}
                </span>
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
export default Tabs;
