import SearchImage from 'assets/Images/Common/search.png';
import Filter from 'assets/Images/ListsegmentImages/filter.png';
import Export from 'assets/Images/Dashboard/export.png';
import { FC, useRef, useState } from 'react';
import ErrorBoundary from 'components/ErrorBoundary';
import { useOutsideClick } from 'app/hooks/useOutsideClick';
import React from 'react';

interface Props {
  heading: string;
  setSearchQuery?: (e: string) => void;
  showFilter?: boolean;
  hideFilter?: boolean;
  filterOptions?: string[];
  onFilterSelect?: (filter: string) => void;
  searchQuery?: string;
  onSelectExport?: () => void;
  selectedFilter?: string
  hideForm?: boolean
}

const AllProductForm: FC<Props> = ({
  heading,
  setSearchQuery,
  showFilter,
  hideFilter,
  filterOptions,
  onFilterSelect,
  searchQuery,
  onSelectExport,
  selectedFilter,
  hideForm
}) => {
  const [showFilterOptions, setShowFilterOptions] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useOutsideClick(dropdownRef, () => {
    setShowFilterOptions(false);
  });
  return (
    <ErrorBoundary>
      <div className="flex justify-between items-center px-6 my-4">
        <h1 className="sm:text-xl text-base sm:leading-5 leading-3 text-black dark:text-white font-medium">
          {heading}
        </h1>
        {!hideForm && <div className="w-8/12 lg:block hidden">
          <div className="relative flex items-center py-2 px-4 border rounded-md border-gray-200 bg-white dark:bg-[#41464E]">
            <input
              className="text-13 font-medium placeholder:text-gray-500 leading-3 text-gray-500 w-full pl-8 h-6 focus:outline-none dark:text-white dark:placeholder:text-white dark:bg-[#41464E]"
              type="text"
              name="search"
              placeholder="Search"
              value={searchQuery}
              onChange={(e) => setSearchQuery && setSearchQuery(e.target.value)}
            />
            <img
              className="absolute dark:invert dark:brightness-0"
              src={SearchImage}
              alt="Search"
            />
          </div>
        </div>}
        <div className="flex lg:w-60 justify-end">
          {!hideFilter && (
            <div className="relative" ref={dropdownRef}>
              <button
                type="button"
                className="text-primary dark:text-white text-sm font-medium boxShadow leading-4 sm:h-11 h-8 font-inter flex items-center sm:py-3 py-1.5 xl:pl-4 lg:pl-2 sm:pl-4 sm:pr-3 px-2 border rounded-md border border-gray-200 bg-white dark:bg-[#41464E] dark:text-white"
                onClick={() => setShowFilterOptions((prev) => !prev)}
              >
                <img
                  className="mr-2.5 dark:invert dark:brightness-0"
                  src={Filter}
                  alt="Filter"
                />
                {selectedFilter || "Filter"}
              </button>
              {showFilterOptions && (
                <div className="max-h-40 overflow-auto bg-white  dark:bg-[#41464E]  border-t-0 rounded-b-md border-[#ced4da] dark:border-[#fff] shadow-sm shadow-gray-200 scrollbar-hide cursor-pointer absolute z-50 w-full">
                  {filterOptions?.map((option, index) => (
                    <button
                      onClick={() => {
                        onFilterSelect && onFilterSelect(option);
                        setShowFilterOptions(false);
                      }}
                      className={`
                        dark:hover:bg-white hover:bg-primary cursor-pointer p-3 text-sm font-medium block w-full text-left leading-4 text-[#495057] dark:text-[#CED4DA]  dark:text-white hover:text-white dark:text-white dark:hover:text-[#495057] dark:text-[#CED4DA]  border border-gray-200 ${
                          filterOptions.length === index + 1
                            ? ' border-b-0'
                            : ''
                        } `}
                      key={option}
                    >
                      {option}
                    </button>
                  ))}
                </div>
              )}
            </div>
          )}
          {!showFilter && (
            <div className="sm:mr-4 mr-2" ref={dropdownRef}>
              <button
                type="button"
                className="h-11 w-28 mr-2.5 px-2.5 py-1 rounded-lg flex justify-center items-center border page-active dark:bg-white text-lightParagraphColor text-sm dark:text-white text-sm font-medium leading-4 hover:scale-105 ease-in duration-300"
                onClick={() => onSelectExport && onSelectExport()}
              >
                <img
                  className="mr-2.5 dark:invert dark:brightness-0"
                  src={Export}
                  alt="Export"
                />
                Export
              </button>
            </div>
          )}
        </div>
      </div>
    </ErrorBoundary>
  );
};
export default AllProductForm;
