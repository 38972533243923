import React, { FC, useContext, useState, useRef } from 'react';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    registerables,
    ChartData,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import ErrorBoundary from 'components/ErrorBoundary';
import { ThemeContext } from 'store/ThemeContext';
import { useOutsideClick } from 'app/hooks/useOutsideClick';
import { getYAxisGraphValue } from 'utils/common';
import Modal from 'components/Alert/Modal';
import { z } from 'zod';

const tooltipDataSchema = z.object({ formattedValue: z.string() });
type TooltipCallback = z.infer<typeof tooltipDataSchema>;

interface Props {
    labels: string[];
    dataset: ChartData<'line', string[], unknown> | undefined;
    redraw: boolean;
    isQuantitativeData?: boolean;
}

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    ...registerables
);

const MixGraph: FC<Props> = ({
    labels,
    dataset,
    redraw,
    isQuantitativeData,
}) => {
    const themeCtx = useContext(ThemeContext);
    const theme = themeCtx?.theme;
    const [isFullView, setIsFullView] = useState(false);
    const outsideRef = useRef<HTMLDivElement>(null);

    useOutsideClick(outsideRef, () => setIsFullView(false));

    const getOrCreateLegendList = (id: string) => {
        const legendContainer: HTMLElement | null = document.getElementById(id);
        let listContainer = legendContainer?.querySelector('ul');

        if (!listContainer) {
            listContainer = document.createElement('ul');
            listContainer.style.display = 'flex';
            listContainer.style.flexDirection = 'row';
            listContainer.style.margin = '0';
            listContainer.style.padding = '0';
            listContainer.style.justifyContent = 'center';
            listContainer.style.flexWrap = 'wrap';

            legendContainer && legendContainer.appendChild(listContainer);
        }

        return listContainer;
    };

    const htmlLegendPlugin = {
        id: 'htmlLegend',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        afterUpdate(chart: any) {
            const ul = getOrCreateLegendList(isFullView ? 'legend-container-full' : 'legend-container');

            // Remove old legend items
            while (ul.firstChild) {
                ul.firstChild.remove();
            }

            const items =
                chart.options.plugins.legend.labels.generateLabels(chart);

            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            items.forEach((item: any) => {
                const li = document.createElement('li');
                li.style.alignItems = 'center';
                li.style.cursor = 'pointer';
                li.style.display = 'flex';
                li.style.flexDirection = 'row';
                li.style.marginLeft = '10px';

                li.onclick = () => {
                    const { type } = chart.config;
                    if (type === 'pie' || type === 'doughnut') {
                        chart.toggleDataVisibility(item.index);
                    } else {
                        chart.setDatasetVisibility(
                            item.datasetIndex,
                            !chart.isDatasetVisible(item.datasetIndex)
                        );
                    }
                    chart.update();
                };

                // Color box

                // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                let boxSpan: HTMLSpanElement = null!;
                boxSpan = document.createElement('span');
                boxSpan.style.background = item.strokeStyle;
                boxSpan.style.borderColor = item.strokeStyle;
                boxSpan.style.borderWidth = item.lineWidth + 'px';
                boxSpan.style.display = 'inline-block';
                boxSpan.style.height = '10px';
                boxSpan.style.marginRight = '10px';
                boxSpan.style.width = '10px';
                boxSpan.style.borderRadius = '50%';

                // Text
                const textContainer: HTMLElement | null =
                    document.createElement('p');
                textContainer.style.color = item.fontColor;
                textContainer.style.margin = '0';
                textContainer.style.padding = '0';
                textContainer.style.fontSize = '13px';
                textContainer.style.textDecoration = item.hidden
                    ? 'line-through'
                    : '';

                const text = document.createTextNode(item.text);
                textContainer.appendChild(text);

                li.appendChild(boxSpan);
                li.appendChild(textContainer);
                ul.appendChild(li);
            });
        },
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        elements: {
            line: {
                tension: 0.5,
            },
        },
        plugins: {
            title: {
                display: true,
            },
            tooltip: {
                callbacks: {
                    label: function (data: TooltipCallback) {
                        if (!isQuantitativeData) {
                            return '$' + data.formattedValue;
                        } else {
                            return data.formattedValue;
                        }
                    },
                },
            },
            legend: {
                display: false,
            },
        },
        scales: {
            x: {
                beginAtZero: true,
                categorySpacing: 0,
                ticks: {
                    color: `${theme === 'dark' ? '#CED4DA' : ''}`,
                    beginAtZero: true,
                    stepSize: 0,
                },
                grid: {
                    display: false,
                },
            },
            y: {
                beginAtZero: true,
                categorySpacing: 0,
                ticks: {
                    color: `${theme === 'dark' ? '#CED4DA' : ''}`,
                    beginAtZero: true,
                    callback: function (value: string | number) {
                        const formattedValue = getYAxisGraphValue(value)
                        const isDollarSign = !isQuantitativeData ? '$' : '';
                        return isDollarSign + (value === 0 ? value : formattedValue)
                    },
                },
                grid: {
                    color: `${theme === 'dark' ? '#fff' : '#e9ebec'}`,
                },
            },
        },
        interaction: {
            mode: 'index' as const,
            intersect: false,
        },
    };

    const data = {
        labels: labels,
        datasets: dataset,
    };

    return (
        <ErrorBoundary>
            <div onDoubleClick={() => setIsFullView(true)} style={{ width: '100%', height: '360px' }}>
                {dataset && labels ? (
                    <>
                        <Line
                            options={options}
                            data={
                                data as unknown as ChartData<
                                    'line',
                                    string[],
                                    unknown
                                >
                            }
                            plugins={[htmlLegendPlugin]}
                            redraw={redraw}
                        />
                        <div id="legend-container"></div>
                    </>
                ) : (
                    <></>
                )}
            </div>
            <div  className="flex justify-between items-center">
                <Modal isOpen={isFullView}>
                    <div ref={outsideRef} className="h-[70vh] mt-[100px] bg-white dark:bg-[url('assets/Images/Common/denimbg.webp')]  w-[70%] rounded p-6 mx-auto ">
                        <div style={{ width: '100%', height: '100%' }}>
                            {dataset && labels ? (
                                <>
                                    <Line
                                        options={options}
                                        data={
                                            data as unknown as ChartData<
                                                'line',
                                                string[],
                                                unknown
                                            >
                                        }
                                        plugins={[htmlLegendPlugin]}
                                        redraw={redraw}
                                    />
                                    <div  id="legend-container-full"></div>
                                </>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </Modal>
            </div>
        </ErrorBoundary>
    );
};

export default MixGraph;
