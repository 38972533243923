import { FC, useContext, useEffect, useRef, useState } from 'react';
// import SaveModal from './SaveModal';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { emailTemplates, create, campaigns } from 'services/constant/routes';
import BackImage from 'assets/Images/Header/back.png';
import { EmailContext } from 'store/EmailContext';
// import { ThemeContext } from 'store/ThemeContext';
import { useOutsideClick } from 'app/hooks/useOutsideClick';
import React from 'react';
import Wrapper from 'components/Wrapper';
import CustomSkeleton from 'components/Skeleton/Skeleton';

interface Props {
  draft?: string;
  onSaveData?: (name: string) => void;
  name: string;
  labels: string;
  loading: boolean;
  onBackToCreation?: () => void;
  view?: string;
  createEmailSubmitted?: boolean;
  showEmailModal?: () => void;
}

const Editor: FC<Props> = ({
  // onSaveData,
  // name,
  // labels,
  loading,
  onBackToCreation,
  view,
  draft,
  createEmailSubmitted,
  showEmailModal,
}) => {
  // const [showSaveModal, setShowSaveModal] = useState<boolean>(false);
  // const [savedEmail, setSavedEmail] = useState<string>('');
  const [emailCreateMode, setEmailCreateMode] = useState<boolean>(false);
  const emailCtx = useContext(EmailContext);
  // const themeCtx = useContext(ThemeContext);
  // const theme = themeCtx?.theme;
  const navigate = useNavigate();
  const { id } = useParams();
  const modalRef = useRef<HTMLDivElement>(null);

  useOutsideClick(modalRef, () => {
    // setShowSaveModal(false);
  });

  const continueButtonClickHandler = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent>
  ) => {
    if (loading) {
      e.preventDefault();
    }
    if (
      (view === 'emailSelected' || view === 'create') &&
      !createEmailSubmitted
    ) {
      e.preventDefault();
      showEmailModal && showEmailModal();
    }
  };

  useEffect(() => {
    if (emailCtx?.htmlSubmitted) {
      setEmailCreateMode(true);
    }
  }, [emailCtx?.htmlSubmitted]);

  // const mobileViewHandler = () => {
  //     // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //     // @ts-ignore
  //     window.StripoApi.compileEmail(function (
  //         error: string,
  //         html: string,
  //         ampHtml: string
  //     ) {
  //         // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //         // @ts-ignore
  //         window.ExternalPreviewPopup.openPreviewPopup(html, ampHtml);
  //     });
  // };

  // const submitHtmlHandler = () => {
  //     if (!loading) {
  //         setShowSaveModal((prev) => !prev);
  //         // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //         // @ts-ignore
  //         // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //         // @ts-ignore
  //         window.StripoApi.compileEmail((error: string, html: string) =>
  //             console.log('email is : ', error, html)
  //         );
  //         // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //         // @ts-ignore
  //         window.StripoApi.getTemplate((email: string) => {
  //             setSavedEmail(email);
  //         });
  //     }
  // };


  return (
    <>
      {/* {loading ? (
        <CustomSkeleton columns={10}/>
      ) : ( */}
      <div className="text-center h-auto xl:px-4 px-2 emailScreen:px-4 py-4 pt-0">
        <div className=" w-full mx-auto px-4">
          <div className="flex justify-end">
            <span id="changeHistoryContainer" style={{ display: 'none' }}>
              Last change:
              <a href="dev1.ryzeo.com" id="changeHistoryLink"></a>
            </span>
            <button type="button" id="undoButton" className="control-button">
              Undo
            </button>
            <button type="button" id="redoButton" className="control-button">
              Redo
            </button>
            {/* <button
            id="codeEditor"
            className={`${
              loading
                ? 'ease-in duration-300 border border-[#ced4da] bg-gray-200 bg:bg-gray-300 text-[#495057] dark:text-[#CED4DA] '
                : 'ease-in duration-300 text-white bg-primary hover:bg-primaryHover hover:scale-105'
            } ease-in duration-300 text-sm font-medium leading-6 rounded-md h-11 w-28 p-2`}
            disabled={loading}
          >
            Code editor
          </button> */}
            {/* <div ref={modalRef} className="relative">
            <button
              id="template"
              onClick={submitHtmlHandler}
              className={` ${
                loading
                  ? 'ease-in duration-300 border border-[#ced4da] bg-gray-200 bg:bg-gray-300 text-[#495057] dark:text-[#CED4DA] '
                  : 'ease-in duration-300 text-white bg-primary hover:bg-primaryHover hover:scale-105'
              } ml-4 ease-in duration-300 text-sm font-medium leading-6 rounded-md h-11 w-28 py-2 px-4`}
            >
              Save Html
            </button>
            {showSaveModal && (
              <div>
                <SaveModal
                  onClose={() => setShowSaveModal(false)}
                  email={savedEmail}
                  onSaveData={onSaveData}
                  name={name}
                  labels={labels}
                  view={view}
                />
              </div>
            )}
          </div> */}
            {/* <button
            onClick={mobileViewHandler}
            className={`${
              loading
                ? 'border border-[#ced4da] bg-gray-200 text-[#495057] dark:text-[#CED4DA] '
                : 'text-white bg-primary ease-in-in duration-300 hover:bg-primary hover:scale-105'
            } ml-4 text-sm font-medium leading-5 rounded-md py-2 px-4 `}
            disabled={loading}
          >
            Mobile View
          </button> */}
          </div>
        </div>
        <div className=" w-full mx-auto rounded-2xl flex flex-col">
          <div
            className={`emailScreen:block flex justify-between ${
              loading ? 'block' : ''
            }`}
          >
            <Wrapper parentClass="h-full inline-block w-auto">
              <>
                {loading ? <CustomSkeleton height={400} /> : null}
                <div
                  id="stripoSettingsContainer"
                  // style={{
                  //   backgroundColor: `${theme === 'dark' ? '#1e293b' : '#ededed'}`,
                  // }}
                ></div>
              </>
            </Wrapper>
            <Wrapper
              parentClass="w-full xl:ml-5 emailScreen:ml-0 ml-2"
              childClass="h-full"
            >
              <>
                {loading ? <CustomSkeleton  height={700} /> : null}
                <div
                  id="stripoPreviewContainer"
                  // style={{
                  //   backgroundColor: `${theme === 'dark' ? '#1e293b' : '#ededed'}`,
                  // }}
                ></div>

              </>
            </Wrapper>
          </div>
        </div>
        {draft === '' && (
          <div className="flex justify-between w-full mt-5 mx-auto">
            <div
              onClick={() => {
                if (!loading) {
                  if (emailCreateMode) {
                    onBackToCreation && onBackToCreation();
                  } else {
                    navigate(emailTemplates);
                  }
                }
              }}
              className={`${
                loading
                  ? 'border border-[#ced4da] bg-gray-200 text-[#495057] dark:text-[#CED4DA] '
                  : ' hover:bg-primary text-primary'
              }  flex items-center text-sm w-44 cursor-pointer dark:bg-white dark:hover:bg-primary hover:text-white font-medium leading-5  py-2.5 px-2 border border-primary rounded-md mr-4  ease-in duration-300 h-[38px]`}
            >
              <img className="mt-0.5 mr-2 p-2" src={BackImage} />
              <span>Go back a step</span>
            </div>
            {id && (
              <Link
                onClick={continueButtonClickHandler}
                className={`${
                  loading
                    ? 'border border-[#ced4da] bg-gray-200 text-[#495057] dark:text-[#CED4DA] '
                    : 'text-white bg-primary'
                } text-sm font-medium flex justify-center items-center leading-5 rounded-md py-1.5 px-4 w-28 h-[38px] ease-in duration-300 cursor-pointer`}
                to={`${campaigns}/${create}/${id}`}
              >
                Continue
              </Link>
            )}
          </div>
        )}
      </div>
    </>
  );
};
export default Editor;
