import React, { FC } from 'react';
// import { Tooltip } from 'react-tooltip';
// import 'react-tooltip/dist/react-tooltip.css';
import Computer from 'assets/Images/Common/computer.png';
import { z } from 'zod';
import { edit, emailEditor } from 'services/constant/routes';
import { Link } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';


const emailTriggerSchema = z.object({ name: z.string(), id: z.number() });

const campaignEmailSchema = z.object({
  id: z.number(),
  name: z.string(),
  created_at: z.string(),
  html_part_present: z.boolean(),
  html_part_blank: z.boolean(),
  include_event: z.boolean(),
  needs_widgetization: z.boolean(),
  widgetization_error: z.boolean(),
  new_editor_compatible: z.boolean(),
  text_part_present: z.boolean(),
  text_part_blank: z.boolean(),
  email_triggers: z.array(emailTriggerSchema),
});
type SingleCampaignEmail = z.infer<typeof campaignEmailSchema>;

interface Props {
  row: SingleCampaignEmail;
  index: number;
}

const CampaignNames: FC<Props> = ({ row, index }) => {
  // const [anchorId, setAnchorId] = useState<string>('');
  // const [selectedName, setSelectedName] = useState<string | null>(null);
  // const themeCtx = useContext(ThemeContext);
  // const theme = themeCtx?.theme;
  return (
    <td className=" whitespace-nowrap align-top py-2 px-3 line-clamp-1 truncate dark:text-white min-w-[293px]">
      <a
        className={`${!row.needs_widgetization && !row.widgetization_error
          ? 'cursor-pointer text-sm font-medium text-[#495057] hover:text-blue-700'
          : 'text-primary hover:text-blue-700'
          }  mt-1 flex gap-3 dark:text-white`}
      >
        <Link
          to={`${emailEditor}/${edit}/${row.id}`}
          data-pw={index === 0 ? 'first-email' : ''}
          className='line-clamp-1 truncate'
        >
          {row.include_event ? <div className='flex'>{row.name}<p className='bg-gray-600 text-white text-center ml-2 p-1'>Insights</p></div> : <p>{row.name}</p>}
        </Link>

        {row.email_triggers.length > 0 && (
          <>
            <Tippy interactive={true}
              content={
                <div className="dark:text-[#CED4DA]  dark:text-white w-60">
                  <div className="flex gap-1 items-center">
                    <h1 className="font-bold text-lg py-2">Triggers</h1>
                    <img src={Computer} alt="computer" className="w-4 h-4" />
                  </div>
                  {row.email_triggers?.map((email) => (
                    <ul
                      onClick={() => console.log('tirgger clicked : ', email)}
                      key={email.id}
                    >
                      <hr />
                      <li className="py-2">{email.name}</li>
                    </ul>
                  ))}
                </div>
              }>
              <img
                src={Computer}
                alt="computer"
                className="w-4 h-4"
                id={`tooltip-anchor-children-${index}`}
              />
            </Tippy>
            {
              /* <div className="inline-block relative">
                <img
                  src={Computer}
                  alt="computer"
                  className="w-4 h-4"
                  id={`tooltip-anchor-children-${index}`}
                  onMouseEnter={() => {
                    setAnchorId(`tooltip-anchor-children-${index}`);
                    setSelectedName(row.name);
                  }}
                />
  
                <Tooltip
                  style={{
                    pointerEvents: 'auto',
                    backgroundColor: `${theme === 'dark' ? '#1e293b' : 'white'}`,
                    border: '1px solid #9e9e9e',
                    zIndex: '1',
                    opacity: '1',
                  }}
                  isOpen={selectedName === row.name}
                  anchorId={anchorId}
                  classNameArrow="-bottom-[5px] border border-gray-500 border-t-0 border-l-0"
                >
                  <div className="text-[#495057] dark:text-[#CED4DA]  dark:text-white w-60">
                    <div className="flex gap-1 items-center">
                      <h1 className="font-bold text-lg py-2">Triggers</h1>
                      <img src={Computer} alt="computer" className="w-4 h-4" />
                    </div>
                    {row.email_triggers?.map((email) => (
                      <ul
                        onClick={() => console.log('tirgger clicked : ', email)}
                        key={email.id}
                      >
                        <hr />
                        <li className="py-2">{email.name}</li>
                      </ul>
                    ))}
                  </div>
                </Tooltip>
              </div> */
            }
          </>
        )}
      </a>
    </td>
  );
};
export default CampaignNames;
