import React,{ useContext, useState } from 'react';
import { excludedValues } from './ScheduleTime';
import Dropdown from 'components/Dropdown/Dropdown';
import { CampaignContext } from 'store/CampaignContext';

const days = ['before', 'after', 'on'];
const dates = ['Subscriber Date', 'Last Clicked Date', 'Last Viewed Date'];
const repeats = ['does not repeat', 'repeats monthly', 'repeats yearly'];
interface DateType {
  [key: string]: number;
  ['Subscriber Date']: number;
  ['Last Clicked Date']: number;
  ['Last Viewed Date']: number;
}
interface FrequencyType {
  [key: string]: string;
  ['does not repeat']: string;
  ['repeats monthly']: string;
  ['repeats yearly']: string;
}
const selectedDate: DateType = {
  ['Subscriber Date']: -1,
  ['Last Clicked Date']: -3,
  ['Last Viewed Date']: -4,
};
const selectedFrequency: FrequencyType = {
  ['does not repeat']: 'does not repeat',
  ['repeats monthly']: 'monthly',
  ['repeats yearly']: 'yearly',
};
const ScheduleDays = () => {
  const campaignCtx = useContext(CampaignContext);
  const [daysData, setDaysData] = useState({
    relativity: campaignCtx?.campaignData?.subscriberField && campaignCtx?.campaignData?.subscriberField['relativity'] || days[1],
    id: campaignCtx?.campaignData?.subscriberField && campaignCtx?.campaignData?.subscriberField['id'] || -1,
    frequency: campaignCtx?.campaignData?.subscriberField && campaignCtx?.campaignData?.subscriberField['frequency'] || repeats[0],
    offset: campaignCtx?.campaignData?.subscriberField && campaignCtx?.campaignData?.subscriberField['offset'] || 0,
  });
  const onSelectHandler = (value: string | number, name: string) => {
    let finalValue = value;
    if (name === 'id' && typeof finalValue === 'string') {
      finalValue = selectedDate[finalValue];
    }
    if (name === 'frequency' && typeof finalValue === 'string') {
      finalValue = selectedFrequency[finalValue];
    }
    setDaysData({ ...daysData, [name]: finalValue });
    if (
      campaignCtx?.campaignData?.subscriberField &&
      campaignCtx.campaignData
    ) {
      const tempCampaignData = {
        ...campaignCtx.campaignData,
        subscriberField: { ...daysData, [name]: finalValue },
      };
      campaignCtx?.onUpdateCampaignData(tempCampaignData);
    }
  };
  return (
    <div className="mb-4">
      <div className="flex flex-wrap items-center">
        {daysData.relativity === 'after' && (
          <>
            <input
              className="block w-28 h-11 text-13 py-3 border border-gray-800 dark:border-[#fff] focus:outline-none  dark:bg-[#41464E]  rounded-md px-4 font-medium leading-3 text-[#495057] dark:text-[#CED4DA]  dark:text-white mt-4 mr-4"
              type="Number"
              name="days"
              value={daysData.offset}
              onKeyDown={(e) => {
                if (excludedValues.includes(e.key)) {
                  e.preventDefault()
                }
              }}
              onChange={({ target: { value } }) => {
                if (parseInt(value) >= 0 || value === '') {
                  onSelectHandler(value, 'offset')
                }
              }}
            />
            <p className="text-sm font-medium leading-4 text-[#495057] dark:text-[#CED4DA]  dark:text-white mt-4 mr-4">
              Days
            </p>
          </>
        )}
        <div className="w-auto h-11 flex items-center relative mt-4 mr-4 min-w-[200px]">
          <Dropdown
            options={days}
            onSelect={(e) => onSelectHandler(e, 'relativity')}
            value={daysData.relativity}
            disableOptions={["before"]}
          />
        </div>
      </div>
      <div className='flex items-center'>
        <div className="w-44 h-11 flex items-center relative mt-4 mr-4 min-w-[200px]">
          <Dropdown
            options={dates}
            onSelect={(e) => onSelectHandler(e, 'id')}
            value={Object.keys(selectedDate).find(
              (key) => selectedDate[key] === daysData.id
            )}
          />
        </div>
        <p className="text-sm font-medium leading-4 text-[#495057] dark:text-[#CED4DA]  dark:text-white mt-4 mr-4">
          and
        </p>
        <div className="w-44 h-11 flex items-center relative mt-4 min-w-[200px]">
          <Dropdown
            options={repeats}
            onSelect={(e) => onSelectHandler(e, 'frequency')}
            value={Object.keys(selectedFrequency).find(
              (key) => selectedFrequency[key] === daysData.frequency
            )}
          />
        </div>
      </div>
    </div>
  );
};
export default ScheduleDays;