import React, { useEffect, useState } from 'react';
import {
    addBlock,
    editFilter,
    getFilterById,
    getFilterCount,
    updateFilter,
} from 'services/apiHandlers/List-Segments/Filter';
import {
    BlockSchema,
    // segmentDataSchema,
    newRuleDataSchema,
    addBlockRuleSchema,
} from './Types';
import Segments from './Segments';
import Check from 'assets/Images/Filter/check-icon.png';
import GroupDropdown from 'components/Dropdown/GroupDropdown';
import { z } from 'zod';
import { Toastify } from 'App';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { filters, listSegments, matches } from 'services/constant/routes';
import moment from 'moment';
import BreadcrumbsWrapper from 'components/Wrapper/BreadcrumbsWrapper';
import Wrapper from 'components/Wrapper';
import ErrorBoundary from 'components/ErrorBoundary';

const filterDataSchema = z.object({
    audience_id: z.string(),
    blocks: z.object({ block: z.array(BlockSchema) }),
    created_at: z.string(),
    description: z.string(),
    id: z.number(),
    logic_operator: z.string(),
    mutable: z.string(),
    name: z.string(),
    updated_at: z.string(),
    used_by_trigger: z.union([z.string(), z.null()]),
});

const getFilterDataSchema = z.object({
    fields: z.array(z.any()),
    filter: filterDataSchema,
    restored: z.boolean(),
});

const getFilterResponseSchema = z.object({
    data: getFilterDataSchema,
    status: z.number(),
});

const addBlockResponseDataSchema = z.object({
    custom_field_name: z.string(),
    field_id: z.string(),
    field_name: z.string(),
    field_source: z.string(),
    field_type: z.string(),
    id: z.string(),
    logic_operator: z.string(),
    rules: z.object({ rule: addBlockRuleSchema }),
});

const addBlockResponseSchema = z.object({
    data: z.object({ block: addBlockResponseDataSchema }),
    status: z.number(),
});

const editFilterResponseSchema = z.object({ status: z.number() });

const RuleSchema = z.object({
    comparison_operator: z.string(),
    comparison_value: z.string(),
});

const RulesSchema = z.record(RuleSchema);

const getCountFilterResponseSchema = z.object({
    data: z.number(),
    status: z.number(),
});

const updateFilterResponseSchema = z.object({ status: z.number() });

type UpdateFilterResponse = z.infer<typeof updateFilterResponseSchema>;
type GetCountFilterResponse = z.infer<typeof getCountFilterResponseSchema>;

type EditRule = z.infer<typeof RulesSchema>;
type AddBlockResponse = z.infer<typeof addBlockResponseSchema>;
type RuleData = z.infer<typeof newRuleDataSchema>;
type FilterData = z.infer<typeof getFilterDataSchema>;
type GetFilterResponse = z.infer<typeof getFilterResponseSchema>;
// type SegmentData = z.infer<typeof segmentDataSchema>;
type EditFilterResponse = z.infer<typeof editFilterResponseSchema>;

const EditFilters = () => {
    const [filtersData, setFiltersData] = useState<FilterData>();
    const [groups, setGroups] = useState<Array<string>>([]);
    const [groupOptions, setGroupOptions] = useState<Array<Array<string>>>([]);
    const [selectedField, setSelectedField] = useState<string>('');
    const [showRestore, setShowRestore] = useState<boolean>(false);
    const [restoreFilter, setRestoreFilter] = useState<boolean>(false);
    const [filterCount, setFilterCount] = useState<number>();
    const [formTouched, setFormTouched] = useState<boolean>(false);
    const navigate = useNavigate();
    const { id } = useParams();
    const idEdit = parseInt(id || '');

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const formatRules = (rule: any) => {
        if (Array.isArray(rule)) {
            return { rule: rule };
        } else {
            return { rule: [rule] };
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const formatBlocks = (block: any) => {
        if (Array.isArray(block)) {
            const filteredBlockRules = block.map((block) => {
                return { ...block, rules: formatRules(block.rules.rule) };
            });
            return { block: filteredBlockRules };
        } else {
            return {
                block: [{ ...block, rules: formatRules(block.rules.rule) }],
            };
        }
    };

    const formatFilter = (data: FilterData) => {
        const formattedFilter = {
            ...data,
            filter: {
                ...data.filter,
                blocks: formatBlocks(data.filter.blocks.block),
            },
        };
        return formattedFilter;
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            description: '',
        },
        validationSchema: Yup.object({
            name: Yup.string().required('Name Cannot Be Empty'),
        }),
        onSubmit: async (values) => {
            const data = {
                name: values.name,
                description: values.description,
            };
            try {
                const updateFilterResponse = (await updateFilter(
                    idEdit,
                    data
                )) as UpdateFilterResponse;
                if (updateFilterResponse.status === 200) {
                    Toastify(
                        'Filter Information Updated Successfully',
                        'success',
                        'edit4'
                    );
                }
            } catch (error) {
                Toastify('Error While Updating Filter', 'error', 'edit3');
            }
        },
    });

    const getFilterData = async (id: number) => {
        try {
            const getFiltersResponse = (await getFilterById(
                id.toString()
            )) as GetFilterResponse;
            if (getFiltersResponse.status === 200) {

                if (typeof getFiltersResponse.data.filter.blocks === 'string') {
                    setFiltersData(getFiltersResponse.data);
                } else {
                    setFiltersData(formatFilter(getFiltersResponse.data));
                }
                setShowRestore(getFiltersResponse.data.restored);
                setRestoreFilter(getFiltersResponse.data.restored);
                const tempGroups: string[] = [];
                const tempOptions: string[][] = [];
                getFiltersResponse.data.fields.map((options) => {
                    tempGroups.push(options[0]);
                    tempOptions.push(options[1]);
                });
                setGroupOptions(tempOptions);
                setGroups(tempGroups);
                setSelectedField(tempOptions[0][0][1]);
                formik.setFieldValue(
                    'name',
                    getFiltersResponse.data.filter.name
                );
                formik.setFieldValue(
                    'description',
                    getFiltersResponse.data.filter.description
                );
            }
        } catch (error) {
            console.log('error is : ', error);
        }
    };

    // const getSegmentName = () => {
    //   if (selectedField.includes('Person')) {
    //     const filteredOption = groupOptions[0].filter((option) =>
    //       option[1].includes(selectedField.split(',')[1])
    //     );
    //     return filteredOption[0][0];
    //   } else {
    //     const filteredOption = groupOptions[1].filter((option) =>
    //       option[1].includes(selectedField.split(',')[1])
    //     );
    //     return filteredOption[0][0];
    //   }
    // };

    const addBlockHandler = async () => {
        setShowRestore(false);
        try {
            const addBlockResponse = (await addBlock(
                idEdit,
                selectedField
            )) as AddBlockResponse;
            if (addBlockResponse.status === 200) {
                if (filtersData) {
                    const addSegment = addBlockResponse.data.block;

                    const addBlockData = {
                        id: addSegment.id,
                        field_name: addSegment.field_name,
                        field_source: addSegment.field_source,
                        field_type: addSegment.field_type,
                        logic_operator: addSegment.logic_operator,
                        rules: {
                            rule: [
                                {
                                    id: addSegment.rules.rule.id,
                                    field_type: '',
                                    // addSegment.rules.rule.data_type?.table
                                    //     ?.name || '',
                                    class: addSegment.rules.rule.class || '',
                                    operator:
                                        addSegment.rules.rule?.operator || '',
                                    value: addSegment.rules.rule?.value || '',
                                    comparison_operators:
                                        addSegment.rules.rule
                                            .comparison_operators,
                                },
                            ],
                        },
                    };
                    if (typeof filtersData.filter.blocks === 'string') {
                        setFiltersData({
                            ...filtersData,
                            filter: {
                                ...filtersData.filter,
                                blocks: { block: [addBlockData] },
                            },
                        });
                    } else {
                        setFiltersData({
                            ...filtersData,
                            filter: {
                                ...filtersData.filter,
                                blocks: {
                                    block: [
                                        ...filtersData.filter.blocks.block,
                                        addBlockData,
                                    ],
                                },
                            },
                        });
                    }
                }
            }
        } catch (error) {
            console.log('error is : ', error);
        }
    };

    const onBlockSwap = () => {
        setShowRestore(false);
        if (filtersData) {
            setFiltersData({
                ...filtersData,
                filter: {
                    ...filtersData.filter,
                    logic_operator:
                        filtersData.filter.logic_operator === 'AND'
                            ? 'OR'
                            : 'AND',
                },
            });
        }
    };

    const onSwapRule = (id: number) => {
        setShowRestore(false);
        const tempFilterData = { ...filtersData };
        const filteredSegments = tempFilterData?.filter?.blocks?.block?.map(
            (segment) => {
                if (segment.id === id.toString()) {
                    return {
                        ...segment,
                        logic_operator:
                            segment.logic_operator === 'AND' ? 'OR' : 'AND',
                    };
                } else {
                    return segment;
                }
            }
        );
        if (filtersData && filteredSegments) {
            setFiltersData({
                ...filtersData,
                filter: {
                    ...filtersData.filter,
                    blocks: { block: filteredSegments },
                },
            });
        }
    };

    // const deleteSegment = (id: string) => {
    //     return filtersData?.filter.blocks.block.filter(
    //         (block) => block.id !== id.toString()
    //     );
    // };

    const onRuleRemove = (id: number, ruleId: number) => {
        setShowRestore(false);
        const tempFilterData = { ...filtersData };
        const filteredRules = tempFilterData?.filter?.blocks?.block?.map(
            (segment) => {
                if (segment.id === id.toString()) {
                    console.log('above filteredSegemnts 222: ');
                    return {
                        ...segment,
                        rules: {
                            rule: segment.rules.rule.filter(
                                (rule) => parseInt(rule.id) !== ruleId
                            ),
                        },
                    };
                } else {
                    return segment;
                }
            }
        );

        const filteredSegments = filteredRules?.filter(
            (filter) => filter.rules.rule.length > 0
        );

        if (filtersData && filteredSegments) {
            setFiltersData({
                ...filtersData,
                filter: {
                    ...filtersData.filter,

                    blocks: { block: filteredSegments },
                },
            });
        }
    };

    const onAddRule = (id: number, rule: RuleData) => {
        setShowRestore(false);
        const tempFilterData = { ...filtersData };

        const filteredSegments = tempFilterData?.filter?.blocks?.block?.map(
            (segment) => {
                if (segment.id === id.toString()) {
                    return {
                        ...segment,
                        rules: { rule: [...segment.rules.rule, rule] },
                    };
                } else {
                    return segment;
                }
            }
        );
        if (filtersData && filteredSegments) {
            setFiltersData({
                ...filtersData,
                filter: {
                    ...filtersData.filter,
                    blocks: { block: filteredSegments },
                },
            });
        }
    };

    const onOperatorChange = (
        id: number,
        ruleId: number,
        value: string,
        field: string
    ) => {
        setShowRestore(false);
        if (filtersData) {
            const updatedData = { ...filtersData };

            const segment = updatedData?.filter.blocks.block?.find(
                (seg) => seg.id === id.toString()
            );

            if (segment) {
                const rule = segment.rules.rule.find(
                    (r) => r.id === ruleId.toString()
                );

                if (rule) {
                    if (field === 'operator') {
                        rule.comparison_operators.selected = value;
                    } else {
                        rule.value = value;
                    }
                }
            }
            setFiltersData(updatedData);
        }
    };

    const getRulesData = (data: FilterData) => {
        const filteredRules = data.filter.blocks.block.map((segment) => {
            return segment.rules.rule;
        });
        const allRules = filteredRules.flat();

        const rules: EditRule = {};

        allRules.map((item) => {
            const ruleId = item.id.toString();

            rules[ruleId] = {
                comparison_operator: item.comparison_operators.selected,
                comparison_value: item.value,
            };
        });
        return rules;
    };

    const getCountHandler = async () => {
        setShowRestore(false);
        if (filtersData) {
            try {
                const getCountFilterResponse = (await getFilterCount(
                    idEdit,
                    restoreFilter ? 'draft' : 'final',
                    { rules: getRulesData(filtersData) }
                )) as GetCountFilterResponse;
                if (getCountFilterResponse.status === 200) {
                    setFilterCount(getCountFilterResponse.data);
                }
            } catch (error) {
                console.log('error is : ', error);
            }
        }
    };

    const editFilterHandler = async () => {
        setShowRestore(false);
        try {
            if (formik.isValid) {
                if (filtersData) {
                    const editFilterResponse = (await editFilter(idEdit, {
                        rules: getRulesData(filtersData),
                    })) as EditFilterResponse;
                    if (
                        editFilterResponse.status === 200 ||
                        editFilterResponse.status === 201
                    ) {
                        Toastify(
                            'Filter Edited Sucessfully',
                            'success',
                            'edit1'
                        );
                        navigate(`${listSegments}/${filters}`);
                    }
                }
            }
        } catch (error) {
            Toastify('Editing Filter Failed', 'error', 'edit2');
        }
    };

    useEffect(() => {
        getFilterData(idEdit);
    }, []);


    return (
        <ErrorBoundary>
            <BreadcrumbsWrapper />
            <div className="px-6 pt-8 min-h-[76vh]">
                <Wrapper>
                    <form onSubmit={formik.handleSubmit} className="p-4">
                        <div className="w-full">
                            <div className="">
                                {showRestore && (
                                    <p className='className="text-sm font-semibold leading-4 text-[#495057] dark:text-[#CED4DA] mt-0.5"'>
                                        Restore Unsaved Filter Changes
                                    </p>
                                )}
                                <p className="text-sm font-normal leading-4 text-[#495057] dark:text-[#CED4DA] mt-1">
                                    Created about
                                    {
                                        <span className="ml-1 mr-1">
                                            {moment
                                                .duration(
                                                    moment().diff(
                                                        moment
                                                            .utc(
                                                                filtersData
                                                                    ?.filter
                                                                    ?.created_at
                                                            )
                                                            .local()
                                                            .format(
                                                                'YYYY-MM-DD HH:mm:ss'
                                                            )
                                                    )
                                                )
                                                .humanize()}
                                        </span>
                                    }
                                    ago
                                </p>
                                <div className="flex gap-4 mt-6 mb-4">
                                    <input
                                        className="w-6/12 px-4  py-2 text-13 leading-3  dark:bg-[#41464E]  border border-[#ced4da] dark:border-[#fff] rounded mt-2 text-[#212529] dark:text-white sm:h-[38px]"
                                        value={formik.values.name}
                                        onChange={(e) => {
                                            setFormTouched(true);
                                            formik.handleChange(e);
                                        }}
                                        onBlur={formik.handleBlur}
                                        name="name"
                                    />
                                    {formik.dirty &&
                                        formik.values.name.length === 0 && (
                                            <div className="text-xs leading-4 text-red-400 mt-1.5">
                                                {formik.errors.name}
                                            </div>
                                        )}
                                    <input
                                        className="w-6/12 px-4 py-2 text-13 leading-3  dark:bg-[#41464E]  border border-[#ced4da] dark:border-[#fff] rounded mt-2 text-[#212529] dark:text-white sm:h-[38px]"
                                        value={formik.values.description}
                                        onChange={(e) => {
                                            setFormTouched(true);
                                            formik.handleChange(e);
                                        }}
                                        onBlur={formik.handleBlur}
                                        name="description"
                                    />
                                   
                                </div>
                                {formTouched && (
                                        <button
                                            type="submit"
                                            className="sm:text-13 text-xs font-medium leading-5 text-white sm:py-2.5 py-1.5 sm:px-6 px-3 h-11 rounded-md bg-primary ease-in-in duration-300 hover:bg-primary hover:scale-105"
                                        >
                                            Update
                                        </button>
                                    )}
                                <div className='flex items-center mt-4'>
                                    <Link
                                        to={`${listSegments}/${filters}/${matches}/${idEdit}`}
                                        className="cursor-pointer mr-4 font-semibold text-lg text-[#495057] dark:text-[#CED4DA]"
                                    >
                                        View Checks
                                    </Link>
                                    {Number.isInteger(filterCount) ? (
                                        <div className="flex justify-between my-2 items-center ">
                                            <div className="flex">
                                                <div className="text-base font-medium leading-5 text-black-700 dark:text-white ml-2">
                                                    {filterCount}
                                                </div>
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="flex justify-between my-2 items-center ">
                                            <div className="flex">
                                                <span className="w-5 h-5 rounded-full">
                                                    <img
                                                        className="w-full"
                                                        src={Check}
                                                        alt="Check"
                                                    />
                                                </span>
                                                <span
                                                    onClick={getCountHandler}
                                                    className="text-base font-medium leading-5 text-[#495057] dark:text-[#CED4DA] ml-1"
                                                >
                                                    Count?
                                                </span>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {filtersData?.filter.blocks?.block?.map(
                            (segment, index) => {
                                return (
                                    <Segments
                                        key={index}
                                        segment={segment}
                                        operator={
                                            filtersData.filter.blocks.block
                                                .length -
                                                1 ===
                                                index ||
                                            filtersData.filter.blocks.block
                                                .length < 2
                                                ? ''
                                                : filtersData?.filter
                                                      .logic_operator
                                        }
                                        onBlockSwap={onBlockSwap}
                                        onSwapRule={onSwapRule}
                                        onRuleRemove={onRuleRemove}
                                        onAddRule={onAddRule}
                                        onOperatorChange={onOperatorChange}
                                    />
                                );
                            }
                        )}
                        <div className="  mt-6">
                            <div>
                                <label className="md:text-base text-sm font-medium leading-6 text-black-300 dark:text-white">
                                    Add Database field
                                </label>
                                <div className="flex flex-wrap items-center mt-4">
                                    <div className=" flex items-center rounded relative sm:h-11 h-20 mr-2">
                                        <GroupDropdown
                                            options={groupOptions}
                                            groups={groups}
                                            onSelect={(e) =>
                                                setSelectedField(e.target.value)
                                            }
                                        />
                                    </div>
                                    <button
                                        onClick={addBlockHandler}
                                        type="button"
                                        className="text-13 font-medium leading-5 rounded-md py-1.5 px-4 text-white bg-primary dark:hover:bg-white dark:hover:text-[#495057] dark:text-[#CED4DA]  dark:text-white sm:h-[38px] h-9"
                                    >
                                        Add
                                    </button>
                                </div>
                            </div>
                        </div>
                   
               
                <div className="flex justify-end py-4 items-center w-full sm:mt-0 mt-2.5">
                    <Link
                        to={`${listSegments}/${filters}`}
                        // onClick={() => onChangeView('')}
                        className="text-center sm:text-13 text-xs py-2.5 px-4 h-11 flex justify-center items-center w-28 font-medium leading-5 mr-2  rounded-md bg-[#f3f6f9] hover:bg-[#cfd1d4] transition-bg text-[#212529]"
                    >
                        Cancel
                    </Link>
                    <button
                        type="button"
                        className="sm:text-13 text-xs font-medium leading-5 text-white sm:py-2.5 py-1.5 sm:px-4 px-3 h-11 rounded-md bg-primary ease-in-in duration-300 hover:bg-primary hover:scale-105"
                        onClick={editFilterHandler}
                    >
                        Save Changes
                    </button>
                </div>
                </form>
                </Wrapper>
            </div>
        </ErrorBoundary>
    );
};
export default EditFilters;
