import React,{ useEffect, useState } from 'react';
import {
  getDefaultConversionPeriod,
  updateConversionPeriod,
} from 'services/apiHandlers/Integration/Miscellaneous';
import { z } from 'zod';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Toastify } from 'App';
import ErrorBoundary from 'components/ErrorBoundary';
import Wrapper from 'components/Wrapper';
import { excludedValues } from 'components/NewCampaign/ScheduleTime';

const responseSchema = z.object({
  data: z.object({ defaultConversionPeriod: z.number() }),
  status: z.number(),
});
type response = z.infer<typeof responseSchema>;

const updateConversionPeriodResponseSchema = z.object({ status: z.number() });
type updateConversionPeriodResponse = z.infer<
  typeof updateConversionPeriodResponseSchema
>;

const ConversionPeriod = () => {
  const [conversionPeriod, setConversionPeriod] = useState<number>();

  const getData = async () => {
    const getConversionPeriodResponse =
      (await getDefaultConversionPeriod()) as response;
    if (getConversionPeriodResponse.status === 200) {
      setConversionPeriod(
        getConversionPeriodResponse.data.defaultConversionPeriod
      );
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      conversionPeriod: conversionPeriod,
    },
    validationSchema: Yup.object({
      conversionPeriod: Yup.number().min(1, `Range should be between (1-90)`).max(90, `Range should be between (1-90)`).required('Required'),
    }),
    onSubmit: async (values) => {
      try {
        const data = { defaultConversionPeriod: values.conversionPeriod };
        const updateConversionPeriodResponse = (await updateConversionPeriod(
          data
        )) as updateConversionPeriodResponse;
        if (updateConversionPeriodResponse.status === 200) {
          Toastify('Conversion Period Updated', 'success', 'period1');
        }
      } catch (error) {
        Toastify('Updating Conversion Period Failed', 'error', 'period2');
      }
    },
  });
  useEffect(() => {
    getData();
  }, []);

  const { handleSubmit, handleChange, errors, values } = formik
  const { conversionPeriod: conversionPeriodValue } = values
  const { conversionPeriod: conversionPeriodError } = errors;

  return (
    <ErrorBoundary>
      <div className=" relative ">
        <Wrapper>
          <form
            onSubmit={handleSubmit}
            className='p-4'
          >
            <h3 className="text-13 font-medium leading-4 text-[#495057] dark:text-white mb-2">
              Conversion Period
            </h3>
            <p className="text-13 leading-4 text-[#878a99] dark:text-white ">
              Number of days <span className="font-semibold">(1-90)</span> to look
              back to attribute conversions to emails. Conversions are attributed
              to the latest email view or click occurring within:
            </p>
            <div className="flex items-center mt-3">
              <input
                className="border focus:border-primary  focus:outline-none border-[#ced4da] dark:border-[#fff]  dark:bg-[#41464E]  sm:h-[38px] h-9 px-4 py-2 text-13 leading-3 text-[#212529] placeholder:text-gray-500 dark:text-white  rounded-md w-full focus:border-primary dark:focus:border-white md:w-7/12"
                type="Number"
                name="conversionPeriod"
                data-pw="conversionPeriod"
                id="conversion"
                value={conversionPeriodValue || 0}
                max='90'
                min='1'
                onKeyDown={(e) => {
                  if (excludedValues.includes(e.key)) {
                    e.preventDefault()
                  }
                }}
                onChange={handleChange}
              />
              <span className="text-13 font-normal leading-4 text-[#878A99] dark:text-white ml-2.5">
                day(s)
              </span>
            </div>
            {conversionPeriodError ? (
              <div
                data-pw="conversion-period-required"
                className="text-xs leading-4 text-red-400 mt-1.5"
              >
                {conversionPeriodError}
              </div>
            ) : null}
            <p className="text-sm leading-4 text-[#878a99] dark:text-white mt-2.5">
              note: changes to the conversion period will impact new reporting
              data only
            </p>
            <div className="mt-4 flex justify-end">
              <button
                type="submit"
                className="sm:py-2.5 py-1.5 sm:px-4 px-2 sm:text-13 text-xs font-medium leading-4 text-white rounded-md bg-primary ease-in-in duration-300 hover:bg-primary hover:scale-105"
                data-pw="update-conversion-period"
              >
                Update
              </button>
            </div>

          </form>
        </Wrapper>
      </div>
    </ErrorBoundary>
  );
};
export default ConversionPeriod;
