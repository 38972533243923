import {
  createList,
  // getAllList,
} from 'services/apiHandlers/List-Segments/SegmentsList';
import SegmentClose from 'assets/Images/Campaign/segment-close.png';
import * as Yup from 'yup';
import { useFormik, FormikValues } from 'formik';
import {
  ListResponse,
} from 'components/List-Segments/Body/List';
import Plus from 'assets/Images/ListsegmentImages/plus-blue.png';
import { useEffect, useState, FC } from 'react';
import Skeleton from 'components/Skeleton/Skeleton';
import React from 'react';
import ErrorBoundary from 'components/ErrorBoundary';
import { z } from 'zod';
import { Toastify } from 'App';

interface Props {
  onListChange: (id: number) => void;
  selectedLists: number[];
  filteredList?: string;
  workflow?: boolean;
  setDeletedList?: (list: number[]) => void;
  showCreateList?: boolean;
  loading: boolean;
  list?: ListType[];
  listItems?: SelectedList[];
  getList: (list: boolean) => void;
  isRemove?: boolean;
}

const getListResponseDataSchema = z.object({
  id: z.number(),
  name: z.string(),
  audience_id: z.number(),
});



const selectedListSchema = z.object({ id: z.number(), label: z.string() });
type SelectedList = z.infer<typeof selectedListSchema>;
type ListType = z.infer<typeof getListResponseDataSchema>

const List: FC<Props> = ({
  onListChange,
  selectedLists,
  filteredList,
  workflow,
  setDeletedList,
  showCreateList,
  loading,
  list,
  listItems,
  getList,
  isRemove,

}) => {
  const [selectedList, setSelectedList] = useState<Array<SelectedList>>([]);
  const [showListForm, setShowListForm] = useState<boolean>(false);


  const formik: FormikValues = useFormik({
    enableReinitialize: true,
    initialValues: {
      list: '',
    },
    validationSchema: Yup.object({
      list: Yup.string().required('Required'),
    }),
    onSubmit: async (values, { resetForm }) => {
      const data = {
        list: {
          name: values.list,
        },
      };
      try {
        const createListResponse = (await createList(data)) as ListResponse;
        if (createListResponse.status === 201) {
          Toastify('List Created Successfully', 'success', 'list1');
          getList(true);
          resetForm();
        }
      } catch (error) {
        console.log('error is : ', error);
      }
    },
  });

  const deleteListHandler = (index: number) => {
    const tempSelectedItems = [...selectedList];
    tempSelectedItems.splice(index, 1);
    setDeletedList &&
      setDeletedList(
        tempSelectedItems.map((list) => {
          return list.id;
        })
      );
  };
  
  useEffect(() => {
    const tempSelectedItems = listItems?.filter((list) =>
      selectedLists.includes(list.id)
    );
    setSelectedList(tempSelectedItems || selectedList);
  }, [selectedLists, listItems]);

  const List =
    filteredList && filteredList.length > 0
      ? list?.filter((l) => l.name.toLowerCase().includes(filteredList))
      : list;

  return (
    <ErrorBoundary>
      <div className='sm:px-6 px-4 sm:py-4 py-2.5 bg-white  dark:bg-[#41464E]'>
        {loading ? (
          <Skeleton />
        ) : (
          <div className='h-[195px] overflow-auto no-scrollbar'>
            {List?.map((list, index) => (
              <React.Fragment key={list.id}>
                {
                  <div className="flex items-center pt-1">
                    <input
                      className="cursor-pointer w-[14px] h-[14px] text-primary bg-gray-100 border-gray-300  dark:focus:ring-blue-600 dark:ring-offset-gray-800  dark:bg-gray-700 dark:border-gray-600"
                      value={list.name}
                      checked={selectedLists.includes(list.id) ? true : false}
                      type="checkbox"
                      onChange={() => onListChange(list.id)}
                      id={index.toString()}
                    />
                    <label
                      htmlFor={index.toString()}
                      className="checkBox relative cursor-pointer pl-2 "
                    >
                      <span className="ml-2 text-[#212529] leading-5 text-sm font-medium dark:text-[#CED4DA]">
                        {list.name}
                      </span>
                    </label>
                  </div>
                }
              </React.Fragment>
            ))}
          </div>
        )}
        {workflow && selectedList && (
              <div className="text-sm font-normal leading-4 text-[#495057] dark:text-[#CED4DA] ">
                <div className="border-t my-2.5 border-[#ced4da]"></div>
                <div className="mb-2 text-base font-medium dark:text-white">
                  {selectedList.length} items selected
                </div>
                <div className="mb-3">
                  {selectedList.map((list, index) => (
                    <div
                      onClick={() => deleteListHandler(index)}
                      key={list.id}
                      className="flex justify-between items-center mb-1 dark:text-white cursor-pointer"
                    >
                      {list.label}
                      <img
                        className="dark:invert dark:brightness-0"
                        src={SegmentClose}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          {showCreateList && !isRemove && (
              <div className="h-24">
                <div className="border-t my-2 border-[#ced4da]"></div>
                <div className="flex justify-between items-center mb-3">
                  <div className="text-sm font-medium leading-4 text-[#495057]  dark:text-white">
                    Create New List
                  </div>
                  <img
                    onClick={() => setShowListForm((prev) => !prev)}
                    src={showListForm ? SegmentClose : Plus}
                    alt="Edit"
                    className="inline mr-1.5 dark:invert dark:brightness-0 cursor-pointer"
                  />
                </div>
                {showListForm && (
                  <form
                    onSubmit={formik.handleSubmit}
                    className="flex justify-between items-center gap-2 pb-4"
                  >
                    <input
                      className="text-13 font-medium leading-3 placeholder:text-gray-500 text-gray-700 px-2 sm:h-[38px] h-9 focus:outline-none focus:border-primary shadow-sm shadow-gray-200 border rounded-md border-[#ced4da] bg-white  dark:bg-[#41464E]  dark:border-[#fff] py-2 w-8/12 dark:bg-white"
                      value={formik.values.list}
                      name="list"
                      onChange={formik.handleChange}
                    />
                    <button
                      disabled={
                        Object.keys(formik.errors).length > 0 ||
                        formik.values.list.length === 0
                      }
                      type="submit"
                      className={`${
                        Object.keys(formik.errors).length > 0 ||
                        formik.values.list.length === 0
                          ? 'bg-primary text-white '
                          : 'text-white bg-primary '
                      } text-13 font-medium leading-5 rounded-md py-2 h-[38px] px-2.5 w-3/12 ease-in duration-300 hover:bg-primary hover:scale-105 cursor-pointer`}
                    >
                      Add
                    </button>
                  </form>
                )}
              </div>
            )}
      </div>
    </ErrorBoundary>
  );
};
export default List;
