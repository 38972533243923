import { EmailContextProvider } from 'store/EmailContext';
import ErrorBoundary from 'components/ErrorBoundary';
import React from 'react';
import { Outlet } from 'react-router-dom';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';

const Email = () => {
  return (
    <ErrorBoundary>
      <EmailContextProvider>
      <>
        <div className="h-full">
        <div className="">
          <div className='h-[61px] bg-white dark:bg-transparent md:px-6 px-3 py-2 font-inter border-b shadow-0 shadow flex justify-between items-center dark:border-[#fff]'>
          <Breadcrumbs />
          </div>
          <Outlet />
        </div>
        </div>
      </>
      </EmailContextProvider>
    </ErrorBoundary>
  );
};
export default Email;
