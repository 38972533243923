import { useEffect, useState, useContext } from 'react';
import { UserContext } from 'store/UserContext';

import TopPerformingCampaigns from 'components/Dashboard/Body/TopPerformingCampaigns';
import SentEmails from 'components/DashboardReports/Body/Analytics/SentEmails';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';

import moment from 'moment';
import Calendar from 'components/Dashboard/Body/Calendar';
import TotalOrder from 'components/Dashboard/Body/Orders/TotalOrders';
import AverageRevenue from 'components/Dashboard/Body/Orders/AverageRevenue';
import Revenue from 'components/Dashboard/Body/Orders/Revenue';


import Revenues from 'components/Revenues-Orders';
import AllWokflows from './AllWorkflows';

import { storeReportOrder } from 'components/DashboardReports/Body/Store-Report/Types';
import {
  calculateRate,
  calculatePercentageDifference,
  convertStringToWholeNumber,
} from 'utils/common';
import { getWorlkflowsStatus } from 'utils/dashboard';
import { WorkflowResponse } from 'components/DashboardReports/Types';
import { Workflows } from 'components/DashboardReports/Body/Store-Report/Types';
import React from 'react';
import {
  getBlastMonetaryValue,
  getScheduledBlasts,
  getWorkflowReports,
} from 'services/apiHandlers/Dashboard/Analytics';
import {
  OrderResponse,
  OrderData,
  GetFilteredCampaignResponse,
  GetBlastMonetaryValueResponse,
  FilteredCampaignData,
  TopPerformingData,
  CalendarEvents,
  BlastData,
  CampaignItemData,
  CampaignGroupData,
  WorkflowPerformanceData,
} from '../index.type';
import { getPreviousSixMonthsCampaignData } from 'utils/dashboard';
import {
  getFilteredCampaigns,
  getOrderRevenueReport,
} from 'services/apiHandlers/Dashboard/Reports';
import { z } from 'zod';
import WorkflowTableHeader from './WorkflowTableHeader';
import Wrapper from 'components/Wrapper';

const listDataSchema = z.object({
  deleted: z.boolean(),
  name: z.string(),
  id: z.number(),
});

const audienceDataSchema = z.object({
  name: z.string(),
  id: z.number(),
  included_list: z.array(listDataSchema),
  excluded_list: z.array(listDataSchema),
  segment: listDataSchema,
});

export const BlastSchema = z.object({
  blast: z.object({
    account_id: z.number(),
    audience: audienceDataSchema.optional(),
    blast_address_id: z.number(),
    bounced: z.number().optional(),
    cleaned_at: z.null(),
    clicks_count: z.number(),
    complaints_count: z.number(),
    completed_at: z.null(),
    created_at: z.string(),
    creator_id: z.number(),
    creator_type: z.null(),
    delivered: z.number().optional(),
    deleted_at: z.null(),
    email_id: z.number(),
    finder_id: z.number(),
    for_individual_owner: z.boolean(),
    forwards_count: z.number(),
    from_email_address: z.string(),
    from_name: z.string(),
    google_analytics: z.null(),
    hard_bounces_count: z.number(),
    host: z.string(),
    id: z.number(),
    include_social_bar: z.boolean(),
    intended_recipients: z.number(),
    is_test: z.boolean(),
    mailengine_id: z.number(),
    mailengine_version: z.null(),
    number_sent: z.number(),
    person_database_id: z.number(),
    piece_id: z.number(),
    priority: z.number(),
    reply_to_email_address: z.null(),
    reply_to_name: z.null(),
    schedule_method: z.string(),
    scheduled_for: z.string(),
    share_description: z.string(),
    share_report: z.string(),
    share_title: z.string(),
    soft_bounces_count: z.number(),
    social_views_count: z.number().optional(),
    social_count: z.number().optional(),
    status: z.string(),
    subject: z.string(),
    tag_list: z.array(z.string()),
    test_email_addresses: z.null(),
    timezone: z.string(),
    track_clicks: z.string(),
    track_forwards: z.string(),
    track_views: z.string(),
    tracking_hash: z.string(),
    trigger_id: z.null(),
    unique_clicks_count: z.number(),
    unique_complaints_count: z.number(),
    unique_views_count: z.number(),
    unsubscribes_count: z.number(),
    updated_at: z.string(),
    views_count: z.number(),
    whitelisting_completed_at: z.null(),
    whitelisting_scheduled_at: z.null(),
    date: z.string().optional(),
  }),
});
const BlastResponseSchema = z.object({
  data: z.array(BlastSchema),
  status: z.number(),
});


//upcoming campaing schema
const campaignsSchema = z.object({
  collection: z.union([z.array(BlastSchema), z.tuple([])]),
  current_page: z.number(),
  per_page: z.number(),
  total_count: z.number(),
});

const getFilterCampaignsSchema = z.object({
  data: campaignsSchema,
  status: z.number(),
});






export type BlastResponse = z.infer<typeof BlastResponseSchema>;
export type Blasts = z.infer<typeof BlastSchema>;
type GetFilterCampaigns = z.infer<typeof getFilterCampaignsSchema>;

const Dashboard = () => {
  const userCtx = useContext(UserContext);
  const [orders, setOrders] = useState<storeReportOrder>();
  const [averageRevenue, setAverageRevenue] = useState<storeReportOrder>();
  const [revenue, setRevenue] = useState<storeReportOrder>();
  const [topCampaigns, setTopCampaigns] = useState<Array<TopPerformingData>>(
    []
  );
  const [campaignEvents, setCampaignEvents] = useState<Array<CalendarEvents>>();
  const [workflows, setWorkflows] = useState<Workflows>([]);
  const [campaignPerformanceData, setCampaignPerformanceData] = useState<
    Array<CampaignItemData>
  >([]);
  const [workflowPerformanceData, setWorkflowPerformanceData] =
    useState<WorkflowPerformanceData>({ orders: null, revenue: [] });
  const [loading, setLoading] = useState<boolean>(true);
  const [filteredWorkflows, setFilteredWorkflows] = useState<Workflows>([]);
  const [selectedFilter, setSelectedFilter] =
    useState<string>('Select a Filter');
  const [filterOrder, setFilterOrder] = useState<string>('Desc');

  const errorCaseHandler = () => {
    setWorkflowPerformanceData({ revenue: [], orders: [] });
    setCampaignPerformanceData([]);
    setOrders({
      orderType: 'Total Orders',
      orderValue: '0',
      orderPercentage: '0',
      graphType: 'Line',
      graphCategories: [],
      graphData: [
        {
          // label: '  Current Year',
          data: [0],
          // borderColor: '#FBB846',
          backgroundColor: ['#FBB846'],
        },
        {
          // label: '  Previous Year',
          data: [0],
          // borderColor: '#A292DA',
          backgroundColor: ['#A292DA'],
        },
      ],
      graphHeight: 280,
      graphWidth: 450,
      graphStyle: { maxWidth: '450px', width: '100%', height: 'auto' },
      lastMonth: '0',
      last90Days: '0',
      last14Days: '0',
      hideLegend: true,
    });
    setAverageRevenue({
      orderType: 'AOV',
      orderValue: '0',
      orderPercentage: '0',
      graphType: 'Coloumn',
      graphCategories: [],
      graphData: [
        {
          // label: ' Current Year',
          data: [0],
          backgroundColor: ['#2EA1F9'],
          // barPercentage: 1.0,
          // barThickness: 25,
        },
        {
          // label: ' Previous Year',
          data: [0],
          backgroundColor: ['#8FDBC0'],
          // barPercentage: 1.0,
          // barThickness: 25,
        },
      ],
      graphStyle: { maxWidth: '450px', width: '100%' },
      graphHeight: 186,
      lastMonth: '0',
      last90Days: '0',
      last14Days: '0',
      hideLegend: true,
    });
    setRevenue({
      orderType: 'Revenue',
      orderValue: '0',
      orderPercentage: '0',
      graphType: 'Line',
      graphCategories: [],
      graphData: [
        {
          // label: '  Current Year',
          data: [0],
          // borderColor: '#FBB846',
          backgroundColor: ['#FBB846'],
        },
        {
          // label: '  Previous Year',
          data: [0],
          // borderColor: '#A292DA',
          backgroundColor: ['#A292DA'],
        },
      ],
      graphHeight: 280,
      graphWidth: 450,
      graphStyle: { maxWidth: '450px', width: '100%', height: 'auto' },
      lastMonth: '0',
      last90Days: '0',
      last14Days: '0',
      hideLegend: true,
    });
  };

  const calculateTopCampaigns = async (
    campaignData: FilteredCampaignData[]
  ) => {
    try {
      const currentYearBlastIds = campaignData
        ?.map((campaign) => {
          return campaign.id;
        })
        .join(',');

      const getCurrentYearBlastMonetaryValues = (await getBlastMonetaryValue(
        currentYearBlastIds
      )) as GetBlastMonetaryValueResponse;

      if (getCurrentYearBlastMonetaryValues?.status === 200) {
        const tempTopCampaigns = getCurrentYearBlastMonetaryValues.data
          .sort((a, b) => b.monetaryValue - a.monetaryValue)
          .slice(0, 3)
          .map((campaign) => {
            return {
              name:
                campaignData?.find(
                  (filteredCampaign: FilteredCampaignData) =>
                    filteredCampaign.id === campaign.blastId
                )?.subject || '',
              orders: campaign.conversions,
              revenue: campaign.monetaryValue,
              id: campaign.blastId.toString(),
            };
          });
        setTopCampaigns(tempTopCampaigns);
      }
    } catch (error) {
      console.log('error occured top is ', error);
    }
  };

  const getCampaignData = (
    allCampaigns: FilteredCampaignData[],
    blastCampaigns: BlastData[]
  ) => {
    const filteredCampaigns = allCampaigns?.map((campaign, index) => {
      if (campaign.id === blastCampaigns[index].blastId) {
        return {
          month: moment(campaign.created_at).format('MMMM'),
          order: blastCampaigns[index].conversions,
          revenue: blastCampaigns[index].monetaryValue,
          averageRevenue: 0,
        };
      }
    });

    return getPreviousSixMonthsCampaignData(
      filteredCampaigns as CampaignGroupData[]
    );
  };

  const calculatePerformanceData = async (
    currentYearWorkflowData: OrderData,
    previousYearWorkflowData: OrderData,
    currentYearCampaignData: FilteredCampaignData[],
    previousYearCampaignData: FilteredCampaignData[]
  ) => {
    try {
      const labels: string[] = [];
      const tempOrderLabels: string[] = [];
      const tempPreviousYearOrderData: string[] = [];
      const tempCurrentYearOrderData: string[] = [];
      const tempAverageRevenueLabels: string[] = [];
      const tempCurrentYearAverageRevenueData: string[] = [];
      const tempPreviousYearAverageRevenueData: string[] = [];
      const tempRevenueLabels: string[] = [];
      const tempCurrentYearRevenueData: string[] = [];
      const tempPreviousYearRevenueData: string[] = [];
      let tempCurrentYearCampaignData: CampaignItemData[] = [];
      let tempPreviousYearCampaignData: CampaignItemData[] = [];

      calculateTopCampaigns(currentYearCampaignData);

      const currentYearCampaignIds = currentYearCampaignData
        ?.map((campaign) => campaign.id)
        .join(',');

      const previousYearCampaignIds = previousYearCampaignData
        ?.map((campaign) => campaign.id)
        .join(',');

      const getCurrentYearBlastMonetaryValues = (await getBlastMonetaryValue(
        currentYearCampaignIds
      )) as GetBlastMonetaryValueResponse;

      const getPreviousYearBlastMonetaryValues = (await getBlastMonetaryValue(
        previousYearCampaignIds
      )) as GetBlastMonetaryValueResponse;

      if (
        getCurrentYearBlastMonetaryValues?.status === 200 &&
        currentYearCampaignIds.length > 0
      ) {
        tempCurrentYearCampaignData = getCampaignData(
          currentYearCampaignData,
          getCurrentYearBlastMonetaryValues.data
        );
      } else {
        tempCurrentYearCampaignData = getCampaignData(
          currentYearCampaignData,
          []
        );
      }

      if (
        getPreviousYearBlastMonetaryValues?.status === 200 &&
        previousYearCampaignIds.length > 0
      ) {
        tempPreviousYearCampaignData = getCampaignData(
          previousYearCampaignData,
          getPreviousYearBlastMonetaryValues.data
        );
      } else {
        tempPreviousYearCampaignData = getCampaignData(
          previousYearCampaignData,
          []
        );
      }
      currentYearWorkflowData?.data?.map((order) =>
        order.dates?.map(
          (date) => (
            order.metric === 'Orders' &&
              tempOrderLabels.push(
                moment(date.date, 'MMMM YYYY').format('MMM')
              ),
            order.metric === 'Orders' &&
              tempCurrentYearOrderData.push(Math.round(date.total).toString()),
            order.metric === 'Average Revenue' &&
              tempAverageRevenueLabels.push(
                moment(date.date, 'MMMM YYYY').format('MMM')
              ),
            order.metric === 'Average Revenue' &&
              tempCurrentYearAverageRevenueData.push(
                Math.round(date.total).toString()
              ),
            order.metric === 'Revenue' &&
              tempRevenueLabels.push(
                moment(date.date, 'MMMM YYYY').format('MMM')
              ),
            order.metric === 'Revenue' &&
              tempCurrentYearRevenueData.push(
                Math.round(date.total).toString()
              ),
            order.metric === 'Revenue' &&
              labels.push(moment(date.date, 'MMMM YYYY').format('MMM'))
          )
        )
      );

      previousYearWorkflowData?.data?.map((data) =>
        data.dates?.map(
          (date) => (
            data.metric === 'Orders' &&
              tempOrderLabels.push(
                moment(date.date, 'MMMM YYYY').format('MMM')
              ),
            data.metric === 'Orders' &&
              tempPreviousYearOrderData.push(Math.round(date.total).toString()),
            data.metric === 'Average Revenue' &&
              tempAverageRevenueLabels.push(
                moment(date.date, 'MMMM YYYY').format('MMM')
              ),
            data.metric === 'Average Revenue' &&
              tempPreviousYearAverageRevenueData.push(
                Math.round(date.total).toString()
              ),
            data.metric === 'Revenue' &&
              tempRevenueLabels.push(
                moment(date.date, 'MMMM YYYY').format('MMM')
              ),
            data.metric === 'Revenue' &&
              tempPreviousYearRevenueData.push(
                Math.round(date.total).toString()
              )
          )
        )
      );

      const currentYearTotalAverageRevenue =
        tempCurrentYearAverageRevenueData.map((averageRevenue, index) =>
          Math.round(
            parseFloat(averageRevenue) +
              (tempCurrentYearCampaignData[index]
                ? tempCurrentYearCampaignData[index].averageRevenue || 0
                : 0)
          ).toString()
        );
      const previousYearTotalAverageRevenue =
        tempPreviousYearAverageRevenueData?.map((averageRevenue, index) =>
          Math.round(
            parseFloat(averageRevenue) +
              (tempPreviousYearCampaignData[index]
                ? tempPreviousYearCampaignData[index]?.averageRevenue || 0
                : 0)
          ).toString()
        );

      const averageRevenueData = [
        {
          label: ' Current Year',
          data: currentYearTotalAverageRevenue,
          backgroundColor: '#2EA1F9',
          barPercentage: 1.0,
          barThickness: 25,
        },
        {
          label: ' Previous Year',
          data: previousYearTotalAverageRevenue,
          backgroundColor: '#8FDBC0',
          barPercentage: 1.0,
          barThickness: 25,
        },
      ];

      const currentYearTotalRevenue = tempCurrentYearRevenueData.map(
        (averageRevenue, index) =>
          Math.round(
            parseFloat(averageRevenue) +
              (tempCurrentYearCampaignData[index]
                ? tempCurrentYearCampaignData[index].revenue || 0
                : 0)
          ).toString()
      );

      const previousYearTotalRevenue = tempPreviousYearRevenueData.map(
        (averageRevenue, index) =>
          Math.round(
            parseFloat(averageRevenue) +
              (tempPreviousYearCampaignData[index]
                ? tempPreviousYearCampaignData[index]?.revenue || 0
                : 0)
          ).toString()
      );

      const revenueData = [
        {
          label: ' Current Year',
          data: currentYearTotalRevenue,
          borderColor: '#FBB846',
          backgroundColor: '#FBB846',
        },
        {
          label: ' Previous Year',
          data: previousYearTotalRevenue,
          borderColor: '#A292DA',
          backgroundColor: '#A292DA',
        },
      ];

      const currentYearTotalOrders = tempCurrentYearOrderData.map(
        (averageRevenue, index) =>
          Math.round(
            parseFloat(averageRevenue) +
              (tempCurrentYearCampaignData[index]
                ? tempCurrentYearCampaignData[index].order || 0
                : 0)
          ).toString()
      );

      const previousYearTotalOrders = tempPreviousYearOrderData.map(
        (averageRevenue, index) =>
          Math.round(
            parseFloat(averageRevenue) +
              (tempPreviousYearCampaignData[index]
                ? tempPreviousYearCampaignData[index]?.order || 0
                : 0)
          ).toString()
      );

      const OrdersData = [
        {
          label: '  Current Year',
          data: currentYearTotalOrders,
          borderColor: '#FBB846',
          backgroundColor: '#FBB846',
        },
        {
          label: '  Previous Year',
          data: previousYearTotalOrders,
          borderColor: '#A292DA',
          backgroundColor: '#A292DA',
        },
      ];
      const currentTotalOrders = tempCurrentYearOrderData.reduce(
        (accumulator, orderData) => {
          return accumulator + parseInt(orderData);
        },
        0
      );
      const previousTotalOrders = tempPreviousYearOrderData.reduce(
        (accumulator, orderData) => {
          return accumulator + parseInt(orderData);
        },
        0
      );
      const ordersPercenatge = calculatePercentageDifference(
        previousTotalOrders,
        currentTotalOrders
      );
      const lastThreeMonthOrders = tempCurrentYearOrderData
        .slice(3, tempCurrentYearOrderData.length)
        .reduce((accumulator, orderData) => {
          return accumulator + parseInt(orderData);
        }, 0);
      const currentOrderLastThreeMonths = tempCurrentYearOrderData.reduce(
        (accumulator, orderData) => {
          return accumulator + parseInt(orderData);
        },
        0
      );

      const totalOrders = {
        orderType: 'Total Orders',
        orderValue: currentTotalOrders.toString(),
        orderPercentage: ordersPercenatge.toString(),
        graphType: 'Line',
        graphCategories: labels,
        graphData: OrdersData,
        graphHeight: 310,
        graphWidth: 450,
        graphStyle: {
          // maxWidth: '450px',
          width: '100%',
          height: 'auto',
        },
        lastMonth:
          tempCurrentYearOrderData[tempCurrentYearOrderData.length - 1],
        last90Days: lastThreeMonthOrders.toString(),
        last14Days: currentOrderLastThreeMonths.toString(),
      };
      const totalAverageRevenue = tempCurrentYearAverageRevenueData.reduce(
        (accumulator, averageRevenue) => {
          return accumulator + parseInt(averageRevenue);
        },
        0
      );
      const lastThreeMonthAverageRevenue = Math.floor(
        tempCurrentYearAverageRevenueData
          .slice(3, tempCurrentYearOrderData.length)
          .reduce((accumulator, averageRevenue) => {
            return accumulator + parseInt(averageRevenue);
          }, 0)
      );
      const previousAverageRevenues = tempPreviousYearAverageRevenueData.reduce(
        (accumulator, averageRevenue) => {
          return accumulator + parseFloat(averageRevenue);
        },
        0
      );
      const averageRevenuePercentage = calculatePercentageDifference(
        previousAverageRevenues,
        totalAverageRevenue
      );
      const lastSixMonthAverageRevenue = Math.floor(
        tempCurrentYearAverageRevenueData.reduce(
          (accumulator, averageRevenueData) => {
            return accumulator + parseInt(averageRevenueData);
          },
          0
        )
      );
      const lastAverageMonth = convertStringToWholeNumber(
        tempCurrentYearAverageRevenueData[
          tempCurrentYearAverageRevenueData.length - 1
        ]
      );

      const AverageRevenue = {
        orderType: 'AOV',
        orderValue: `$${totalAverageRevenue.toString()}`,
        orderPercentage: averageRevenuePercentage.toString(),
        graphType: 'Coloumn',
        graphCategories: labels,
        graphData: averageRevenueData,
        graphStyle: {
          // maxWidth: '450px',
          width: '100%',
        },
        graphHeight: 206,
        lastMonth: `$${lastAverageMonth.toString()}`,
        last90Days: `$${lastThreeMonthAverageRevenue}`,
        last14Days: `$${lastSixMonthAverageRevenue}`,
      };

      const totalRevenue = tempCurrentYearRevenueData.reduce(
        (accumulator, revenue) => {
          return accumulator + parseInt(revenue);
        },
        0
      );
      const lastThreeMonthRevenue = Math.floor(
        tempCurrentYearRevenueData
          .slice(3, tempCurrentYearRevenueData.length)
          .reduce((accumulator, revenue) => {
            return accumulator + parseInt(revenue);
          }, 0)
      );
      const previousYearsRevenue = tempPreviousYearRevenueData.reduce(
        (accumulator, revenue) => {
          return accumulator + parseInt(revenue);
        },
        0
      );
      const totalRevenuePercentage = calculatePercentageDifference(
        previousYearsRevenue,
        totalRevenue
      );
      const lastSixMonthRevenue = Math.floor(
        tempCurrentYearRevenueData.reduce((accumulator, revenueData) => {
          return accumulator + parseInt(revenueData);
        }, 0)
      );
      const revenue = {
        orderType: 'Revenue',
        orderValue: `$${totalRevenue.toString()}`,
        orderPercentage: totalRevenuePercentage.toString(),
        graphType: 'Line',
        graphCategories: labels,
        graphData: revenueData,
        graphHeight: 310,
        graphWidth: 450,
        graphStyle: {
          // maxWidth: '450px',
          width: '100%',
          height: 'auto',
        },
        lastMonth: `$${convertStringToWholeNumber(
          tempCurrentYearRevenueData[tempCurrentYearRevenueData.length - 1]
        )}`,
        last90Days: `$${lastThreeMonthRevenue.toString()}`,
        last14Days: `$${lastSixMonthRevenue}`,
      };
      setCampaignPerformanceData(tempCurrentYearCampaignData);
      setWorkflowPerformanceData({
        orders: tempCurrentYearOrderData,
        revenue: tempCurrentYearRevenueData,
      });
      setOrders(totalOrders);
      setAverageRevenue(AverageRevenue);
      setRevenue(revenue);
    } catch (error) {
      console.log('error occured third is : ', error);
      errorCaseHandler();
    }
  };



  const getDashboardData = async () => {
    setLoading(true);

    const currentYearMonthDate = moment().format('YYYY-MM-DD');
    const currentYearLastSixMonthDate = moment()
      .subtract(5, 'months')
      .startOf('month')
      .format('YYYY-MM-DD');
    const previousYearDate = moment().subtract(1, 'year').format('YYYY-MM-DD');
    const previousYearSixMonthsDate = moment(previousYearDate)
      .subtract(5, 'months')
      .startOf('month')
      .format('YYYY-MM-DD');
    // const previousWeekDate = moment().add(6, 'days').format('YYYY-MM-DD');
    // const startOfWeek = moment()
    //   .clone()
    //   .startOf('week')
    //   .add(1, 'days')
    //   .format('YYYY-MM-DD');
    // const endOfWeek = moment()
    //   .clone()
    //   .endOf('week')
    //   .add(1, 'days')
    //   .format('YYYY-MM-DD');
    try {
      const currentYearWorkflowReportResponse = (await getWorkflowReports(
        currentYearLastSixMonthDate,
        currentYearMonthDate
      )) as WorkflowResponse;

    

      const getCurrentYearWorfklowPerformanceResponse =
        (await getOrderRevenueReport(
          currentYearLastSixMonthDate,
          currentYearMonthDate
        )) as OrderResponse;

      const getPreviousYearWorfklowPerformanceResponse =
        (await getOrderRevenueReport(
          previousYearSixMonthsDate,
          previousYearDate
        )) as OrderResponse;

     

      const getCurrentYearFilteredCampaignsResponse =
        (await getFilteredCampaigns(
          currentYearLastSixMonthDate,
          currentYearMonthDate,
          'report'
        )) as GetFilteredCampaignResponse;

 
      
      // const getPreviousWeekFilteredCampaignsResponse =
      //   (await getFilteredCampaigns(
      //     currentYearMonthDate,
      //     previousWeekDate,
      //     'no_pagination'
      //   )) as GetFilteredCampaignResponse;

        
      
      const getComingWeekFilteredCampaignsResponse = (await getScheduledBlasts()) as GetFilterCampaigns;

      const getPreviousYearFilteredCampaignsResponse =
        (await getFilteredCampaigns(
          previousYearSixMonthsDate,
          previousYearDate,
          'report'
        )) as GetFilteredCampaignResponse;

    

      if (currentYearWorkflowReportResponse.status === 200) {
        const tempTopWorkflows = currentYearWorkflowReportResponse.data.data
          .sort((a, b) => b.monetaryValue - a.monetaryValue)
          .slice(0, 4)
          .map((workflow) => {
            return {
              name: workflow.workflowName,
              revenue: workflow.monetaryValue,
              orders: workflow.uniqueConversions,
              id: workflow.workflowId,
            };
          });
        tempTopWorkflows.splice(0, 1);
        // setTopWorkflows(tempTopWorkflows);
        const tempWorkflows = currentYearWorkflowReportResponse.data.data.map(
          (workflow) => {
            return {
              ...workflow,
              status: getWorlkflowsStatus(
                workflow.workflowId,
                userCtx?.workflows
              ),
            };
          }
        );
        setWorkflows(tempWorkflows);
        setFilteredWorkflows(tempWorkflows);
      }

      if (
        getCurrentYearWorfklowPerformanceResponse.status === 200 &&
        getPreviousYearWorfklowPerformanceResponse.status === 200 &&
        getCurrentYearFilteredCampaignsResponse.status === 200 &&
        getPreviousYearFilteredCampaignsResponse.status === 200
      ) {
        calculatePerformanceData(
          getCurrentYearWorfklowPerformanceResponse,
          getPreviousYearWorfklowPerformanceResponse,
          getCurrentYearFilteredCampaignsResponse.data,
          getPreviousYearFilteredCampaignsResponse.data
        );
      }

      if (getComingWeekFilteredCampaignsResponse.status === 200) {
      
        
        const filteredCampaigns = getComingWeekFilteredCampaignsResponse.data.collection?.filter(
          (campaign) => campaign.blast.status === 'waiting'
        );
        
        
        const tempCalendarEvents =
          filteredCampaigns.map((campaign) => {
              return {
              name: campaign.blast.subject,
              events: campaign.blast.scheduled_for,
              id: campaign.blast.id.toString(),
            };
          });
        setCampaignEvents(tempCalendarEvents);
      }
    } catch (error) {
      console.log('error occured first is : ', error);
      errorCaseHandler();
    }
    setLoading(false);
  };

  const onFilterSelect = (filter: string) => {
    if (filter === 'Status') {
      setFilteredWorkflows(
        [...workflows].sort((a, b) =>
          (a?.status || '') > (b?.status || '') ? 1 : -1
        )
      );
    } else if (filter === 'Revenue') {
      if (selectedFilter === 'Revenue' && filterOrder === 'Desc') {
        setFilteredWorkflows(
          [...workflows].sort((a, b) => a.monetaryValue - b.monetaryValue)
        );
        setFilterOrder('Asc');
      } else {
        setFilteredWorkflows(
          [...workflows].sort((a, b) => b.monetaryValue - a.monetaryValue)
        );
        setFilterOrder('Desc');
      }
    } else if (filter === 'Orders') {
      if (selectedFilter === 'Orders' && filterOrder === 'Desc') {
        setFilteredWorkflows(
          [...workflows].sort(
            (a, b) => a.uniqueConversions - b.uniqueConversions
          )
        );
        setFilterOrder('Asc');
      } else {
        setFilteredWorkflows(
          [...workflows].sort(
            (a, b) => b.uniqueConversions - a.uniqueConversions
          )
        );
        setFilterOrder('Desc');
      }
    } else if (filter === 'AOV') {
      if (selectedFilter === 'AOV' && filterOrder === 'Desc') {
        setFilteredWorkflows(
          [...workflows].sort(
            (a, b) =>
              calculateRate(a.monetaryValue, a.uniqueConversions) -
              calculateRate(b.monetaryValue, b.uniqueConversions)
          )
        );
        setFilterOrder('Asc');
      } else {
        setFilteredWorkflows(
          [...workflows].sort(
            (a, b) =>
              calculateRate(b.monetaryValue, b.uniqueConversions) -
              calculateRate(a.monetaryValue, a.uniqueConversions)
          )
        );
        setFilterOrder('Desc');
      }
    }
    setSelectedFilter(filter);
  };

  useEffect(() => {
    getDashboardData();
  }, []);



  return (
    <>
      <div className="h-[61px] bg-white md:px-6 px-3 py-2 font-inter border-b shadow-0 shadow flex justify-between items-center dark:border-[#fff] dark:shadow-none dark:bg-[#41464E]">
        <Breadcrumbs />
      </div>
      <div className="px-4 py-8 h-full">
        <div className="flex 2xl:flex-row flex-col gap-4 w-full">
          <div className="2xl:w-[70%] w-full">
            <div className="flex items-center  w-full mb-4 gap-4 xl:justify-between justify-center flex-wrap xl:flex-nowrap ">
              <div className="2xl:w-[32%] xl:w-[33%] sm:w-[48%] w-full shadow-lg hover:rounded-lg hover:shadow-xl hover:shadow-gray-200 dark:shadow-none dark:bg-[#41464E] dark:shadow-lg  dark:hover:shadow-2xl">
                <Revenue revenue={revenue} loading={loading} />
              </div>
              <div className="2xl:w-[32%] xl:w-[33%] sm:w-[48%] w-full shadow-lg hover:rounded-lg hover:shadow-xl hover:shadow-gray-200 dark:shadow-none dark:bg-[#41464E] dark:shadow-lg  dark:hover:shadow-2xl">
                <TotalOrder
                  isQuantitativeData={true}
                  orders={orders}
                  loading={loading}
                />
              </div>
              <div className="2xl:w-[32%] xl:w-[33%] sm:w-[48%] w-full shadow-lg hover:rounded-lg hover:shadow-xl hover:shadow-gray-200  dark:shadow-none dark:bg-[#41464E] dark:shadow-lg  dark:hover:shadow-2xl">
                <AverageRevenue
                  averageRevenue={averageRevenue}
                  loading={loading}
                />
              </div>
            </div>
            <div className="lg:flex w-full md:flex-row flex-col gap-4 dark:shadow-none dark:bg-[#41464E] dark:shadow-lg dark:hover:rounded-xl dark:hover:shadow-2xl">
              <div className="w-full mb-4">
                <Revenues
                  campaignPerformanceData={campaignPerformanceData}
                  workflowPerformanceData={workflowPerformanceData}
                  loading={!orders ? true :  loading}
                />
              </div>
              {/* <div className="mb-4 2xl:w-[44%] lg:w-[40%]">
                <LatestSubscribers />
              </div> */}
            </div>



          <div className="flex 2xl:flex-row flex-col lg:flex-nowrap gap-4 mb-4">
          <div className="bg-white boxShadowIdeal w-full h-full relative dark:shadow-none dark:bg-[#41464E] dark:shadow-lg dark:hover:rounded-xl dark:hover:shadow-2xl">
            <div className="w-full">
              <WorkflowTableHeader
                selectedFilter={selectedFilter}
                onFilterSelect={onFilterSelect}
              />
              <AllWokflows
                filteredWorkflows={filteredWorkflows}
                loading={loading}
              />
            </div>
          </div>
        </div>

          </div>

          <div className=" h-full 2xl:w-[30%] 2xl:block lg:flex xl:flex-nowrap flex-wrap md:flex-row flex-col mb-2 gap-4 xl:gap-0 justify-center xl:justify-between">
            <div className="mb-4 2xl:w-full xl:w-[32.3%] lg:w-[48%] w-full ">
              <Wrapper parentClass='h-full !mt-0'>
                <SentEmails />
              </Wrapper>
            </div>
            <div className="mb-5 2xl:w-full xl:w-[32.3%] lg:w-[48%] w-full">
              {/* <TopPerformingWorkflows
                workflows={topWorkflows}
                loading={loading}
              /> */}
              <Calendar campaignEvents={campaignEvents} loading={loading} />
            </div>
            <div className="shadow-xl mb-5 2xl:w-full xl:w-[32.3%] lg:w-[48%] w-full dark:shadow-none dark:bg-[#41464E] dark:shadow-lg dark:hover:shadow-2xl">
              <TopPerformingCampaigns
                campaigns={topCampaigns}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Dashboard;