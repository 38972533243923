import Mail from 'assets/Images/svg-images/mail.svg';
import {
  forwardRef,
  ForwardRefRenderFunction,
  RefObject,
  useEffect,
  useState,
} from 'react';
import { getcampaignEmails } from 'services/apiHandlers/Campaigns/Emails';
import Dropdown from 'components/Dropdown/SearchValueDropdown';
import { SendEmailData } from '../index.type';
import { z } from 'zod';
import { useFormik, FormikValues } from 'formik';
import * as Yup from 'yup';
import { Link, useSearchParams } from 'react-router-dom';
import { emailEditor } from 'services/constant/routes';
import uuid from 'react-uuid';
import React from "react"

interface Props {
  emailData: SendEmailData | undefined;
  formIsValid: (valid: boolean) => void;
  emailRef: RefObject<HTMLDivElement>;
  onDataChange: (data: ProviderData) => void;
  onFormTouch: () => void;
  senderIsValid: boolean;
}

const options = z.object({
  name: z.string(),
  value: z.string(),
});

type option = z.infer<typeof options>;

const emailTriggerSchema = z.object({ name: z.string(), id: z.number() });

const campaignEmailSchema = z.object({
  id: z.number(),
  name: z.string(),
  created_at: z.string(),
  html_part_present: z.boolean(),
  html_part_blank: z.boolean(),
  include_event: z.boolean(),
  needs_widgetization: z.boolean(),
  widgetization_error: z.boolean(),
  new_editor_compatible: z.boolean(),
  text_part_present: z.boolean(),
  text_part_blank: z.boolean(),
  email_triggers: z.array(emailTriggerSchema),
});

type campaigns = z.infer<typeof campaignEmailSchema>;

const campaignResponseSchema = z.object({
  data: z.object({
    emails: z.array(campaignEmailSchema),
    readonly_mode: z.boolean(),
    total_entries: z.number(),
  }),
  status: z.number(),
});

type campaignResponse = z.infer<typeof campaignResponseSchema>;

const providerDataSchema = z.object({
  emailId: z.number().nullable(),
  subject: z.string(),
});
type ProviderData = z.infer<typeof providerDataSchema>;

const EmailContent: ForwardRefRenderFunction<HTMLDivElement, Props> = (
  {
    emailData,
    formIsValid,
    emailRef,
    onDataChange,
    onFormTouch,
    senderIsValid,
  },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ref
) => {
  const [campaigns, setcampaigns] = useState<Array<option>>([]);
  const [email, setEmail] = useState<string>('Select an Email');
  const [allCampaigns, setAllCampaigns] = useState<Array<campaigns>>([]);
  const [emailId, setEmailId] = useState<number | null>(0);
  const [searchParams,setSearchParams] = useSearchParams()

  const formik: FormikValues = useFormik({
    enableReinitialize: true,
    initialValues: {
      subject: '',
      emailId: null,
    },
    validationSchema: Yup.object({
      subject: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      console.log('values', values);
    },
  });

  const getData = async () => {
    const tempcampaigns: option[] = [];
    const response = (await getcampaignEmails()) as campaignResponse;
    if (response.status === 200) {
      response.data.emails.map((data) => {
        if (emailData?.senderId && parseInt(emailData?.senderId) === data.id) {
          setEmail(data.name);
        }
        tempcampaigns.push({ name: data.name, value: data.id.toString() });
      });
      response.data.emails.map((email) => {
        if (email.id === emailData?.emailId) {
          setEmail(email.name);
        }
      });
      setcampaigns(tempcampaigns);
      setAllCampaigns(response.data.emails);
    }
  };

  const emailChangeHandler = (value: string) => {
    onFormTouch();
    let emailId = 0,
      emailName = '';
    if (formik.values.subject === '') {
      formIsValid(false);
    } else {
      formIsValid(true);
    }
    allCampaigns.map((data) => {
      if (value === data.id.toString()) {
        emailId = data.id;
        emailName = data.name;
      }
    });

    onDataChange({
      subject: formik.values.subject,
      emailId: emailId,
    });
    setEmailId(emailId);
    setEmail(emailName);
  };

  useEffect(() => {
    if (formik.dirty) {
      onFormTouch();
    }
    if (!formik.isValid || email === 'Select an Email') {
      formIsValid(false);
    } else {
      formIsValid(true);
    }

    onDataChange({
      emailId: emailId || 0,
      subject: formik.values.subject,
    });
  }, [formik.values, formik.errors, formik.isValid]);

  useEffect(() => {
    if (emailData) {
      let emailName = '';
      allCampaigns.map((data) => {
        if (emailData.emailId === data.id) {
          emailName = data.name;
        }
      });
      setEmailId(emailData.emailId);
      setEmail(emailName);
      formik.setFieldValue('subject', emailData.subject);
      formIsValid(true);
    }
    getData();
  }, [emailData]);
  
  return (
    <div ref={emailRef}>
      <div className="flex items-center my-6 dark:invert dark:brightness-0">
        <span className="mr-1.5">
          <img src={Mail} alt="Mail" />
        </span>
        <p className="sm:text-xl text-base sm:leading-5 leading-3 text-black-700 font-medium dark:text-white">
          Provide your email content
        </p>
      </div>
      <label className="text-xs font-semibold leading-3 tracking-wide uppercase text-[#495057] dark:text-[#CED4DA]  block my-4 dark:text-white">
        Subject
      </label>
      <div>
        <input
          className="text-13 font-medium leading-3 text-gray-700 py-2 rounded-md px-4 bg-white border border-gray-600 sm:[38px] h-9 w-full  dark:bg-[#41464E]  dark:text-white"
          type="text"
          onBlur={formik.handleBlur}
          value={formik.values.subject}
          name="subject"
          onChange={formik.handleChange}
        />
      </div>
      <label className="text-xs font-semibold leading-3 tracking-wide uppercase text-[#495057] dark:text-[#CED4DA]  block my-4 dark:text-white">
        Email
      </label>
      <div className=" dropdown-show-hide relative sm:[38px] h-9">
        <Dropdown
          value={email}
          options={campaigns}
          onSelect={(value) => {
            emailChangeHandler(value);
          }}
        />
      </div>
      {senderIsValid && emailId !==0 && (
        <Link
          target="popup"
          onClick={() => {
            searchParams.set("id",emailId?.toString() || '')
            setSearchParams(searchParams)
            window.open(
              `${emailEditor}?id=${emailId}`,
              `html${uuid()}`,
              `width=800,height=${window.screen.availHeight}`
            )
          }}
          to={`${emailEditor}?id=${emailId}`}
          className="flex mt-2 items-center text-13 font-medium leading-5 rounded px-4 py-2.5 bg-primary text-white"
        >
          Preview Email
        </Link>
      )}
    </div>
  );
};
export default forwardRef(EmailContent);
