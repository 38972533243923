
import { ReactNode, FC } from 'react';
import React from 'react';

interface Props {
    title: string,
    children: ReactNode | string;
}

const FormRow:FC<Props> = ({ title, children }) => {

    return ( 
        <div className='mt-5 flex justify-between w-[60%]'>
            <div className='flex w-[50%] justify-start'>
                <p className='mr-20 dark:text-white font-medium'>{title}</p>
            </div>
            <div className='flex w-[50%] justify-start'>
                <p className='dark:text-white'>{children}</p>
            </div>
        </div>
    )
}

export default FormRow