import { FC, useContext, useState } from 'react';
import { WorkflowContext } from 'store/WorkflowContext';
import Modal from 'components/Alert/Modal';
import SaveModal from './Modals/SaveModal';
import React from "react"

interface Props{
  onSave: () => void
}

const PreviewHeader:FC<Props> = ({onSave}) => {
  const workflowCtx = useContext(WorkflowContext);
  const [showModal, setShowModal] = useState<boolean>(false);

  return (
    <div className="flex justify-between items-center mb-4 px-4">
      <Modal isOpen={showModal}>
        <SaveModal onClose={() => setShowModal(false)} onSave={onSave}/>
      </Modal>
      <h2 className="text-base text-[#495057] font-medium  dark:text-[#CED4DA]">
        Workflow Preview
      </h2>
      <button
        type="button"
        className={`text-sm font-medium leading-5 rounded-md px-4 py-2.5 w-28 cursor-pointer h-11 ease-in duration-300 ${
          !workflowCtx?.workflow?.workflow || !workflowCtx.workflow
            ? ' border-gray-800 bg-gray-200 text-[#495057] dark:text-[#000] '
            : 'bg-primary hover:bg-primary text-white hover:scale-105'
        } `}
        onClick={() => setShowModal(true)}
        disabled={!workflowCtx?.workflow?.workflow || !workflowCtx.workflow}
      >
        Save
      </button>
    </div>
  );
};
export default PreviewHeader;
