import Config from 'assets/Images/Workflow/config-setting.png';
import Meter from 'assets/Images/Workflow/meter.svg';
import List from 'assets/Images/Workflow/list.png';
import Link from 'assets/Images/Workflow/link.png';
import Remove from 'assets/Images/Workflow/remove.png';
import Mail from 'assets/Images/Workflow/mail.png';
import BackImage from 'assets/Images/Header/back.png';
import Code from 'assets/Images/Workflow/code.svg';
import Clock from 'assets/Images/Workflow/clock.png';
import User from 'assets/Images/Workflow/user.png';
import { FC, useRef } from 'react';
import { useOutsideClick } from 'app/hooks/useOutsideClick';
import React from 'react';

interface Props {
  onAddBlock: (type: string , addType: string) => void;
  onClose: () => void
}
const conditions = [
  { title: 'Custom Event', src: Config, type: 'EventConditionNode' },
  {
    title: 'Subscriber Fields',
    src: User,
    type: 'SubscriberFieldConditionNode',
  },
  { title: 'Subscriber Tags', src: Meter, type: 'TagConditionNode' },
  {
    title: 'List Membership',
    src: List,
    type: 'ListMembershipConditionNode',
  },
  {
    title: 'Webhook Response',
    src: Link,
    type: 'WebhookResponseConditionNode',
  },
];

const actions = [
  { title: 'Add to List', src: List, type: 'AddToListActionNode' },
  { title: 'Remove From List', src: Remove, type: 'RemoveFromListActionNode' },
  { title: 'Send to Webhook', src: Link, type: 'WebhookActionNode' },
  { title: 'Send Email', src: Mail, type: 'SendEmailActionNode' },
  { title: 'Publish Event', src: BackImage, type: 'PublishEventActionNode' },
  { title: 'Unsubscribe', src: Remove, type: 'UnsubscribeNode' },
  { title: 'Execute Script', src: Code, type: 'ExecuteScriptNode' },
  { title: 'Update Score', src: Meter, type: 'UpdateScoreActionNode' },
  { title: 'Notify by Email', src: BackImage, type: 'NotifyByEmailActionNode' },
];

const AddBlock: FC<Props> = ({ onAddBlock,onClose }) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useOutsideClick(modalRef, onClose);
  return (
    <div ref={modalRef} className="w-full max-w-[615px] m-auto p-5 fixed top-0 left-0 right-0 z-50 h-[95vh] flex items-center">
      <div className="bg-white p-5 rounded-lg dark:bg-[#41464E] sm:h-auto h-[380px] overflow-auto">
        <div className="bg-white dark:bg-[#41464E]">
          <h2 className="sm:text-xl text-base sm:leading-5 leading-3 text-black-700 font-medium mb-8 dark:text-white">
            Add Block
          </h2>
          <div className="my-auto overflow-y-auto scrollbar-hide">
            <h4 className="sm:text-lg text-sm sm:leading-5 leading-3 text-black-700 font-medium mb-2 dark:text-white">
              Conditions
            </h4>
            <div className="flex flex-wrap items-center gap-3 mb-8">
              {conditions.map((condition) => (
                <button
                  onClick={() => onAddBlock(condition.type, "condition")}
                  key={condition.title}
                  type="button"
                  className="group sm:w-[170px] w-[100%] text-13 font-medium text-[#495057]  border border-[#bdbdbd] h-11 rounded-md flex justify-center items-center py-2 px-2  dark:bg-[#41464E]  dark:text-white hover:bg-workflowHover cursor-pointer ease-in duration-300"
                >
                  <span className="w-5 h-auto mr-2">
                    <img
                      className="w-full h-auto dark:invert dark:brightness-0"
                      src={condition.src}
                      alt="Back Arrow"
                    />
                  </span>
                  <span className='group ease-in duration-300'>{condition.title}</span>
                </button>
              ))}
            </div>
            <h4 className="sm:text-lg text-sm sm:leading-5 leading-3 text-black-700 font-medium mb-2 dark:text-white">
              Actions
            </h4>
            <div className="flex flex-wrap items-center gap-3 mb-8">
              {actions.map((action) => (
                <button
                  onClick={() => onAddBlock(action.type , "action")}
                  key={action.title}
                  type="button"
                  className="sm:w-[165px] w-full text-13 font-medium text-[#495057] border border-[#bdbdbd] h-11 rounded-md flex justify-center items-center py-2 px-2  dark:bg-[#41464E]  dark:text-white hover:bg-workflowHover cursor-pointer ease-in duration-300"
                >
                  <span className="w-5 h-auto mr-2">
                    <img
                      className="w-full h-auto dark:invert dark:brightness-0"
                      src={action.src}
                      alt="Back Arrow"
                    />
                  </span>
                  <span className='group ease-in duration-300'>{action.title}</span>
                </button>
              ))}
            </div>
            <h4 className="sm:text-lg text-sm sm:leading-5 leading-3 text-black-700 font-medium mb-2 dark:text-white">
              Timer
            </h4>
            <div className="flex flex-wrap items-center gap-3 mb-4">
              <button
                onClick={() => onAddBlock("DelayNode" ,"action")}
                type="button"
                className="w-[165px] text-13 font-medium text-[#495057] dark:text-[#CED4DA]  border border-[#bdbdbd] h-11 rounded-md flex justify-center items-center py-2 px-2  dark:bg-[#41464E]  dark:text-white hover:bg-workflowHover cursor-pointer ease-in duration-300"
              >
                <span className="w-5 h-auto mr-2">
                  <img className="w-full h-auto dark:invert dark:brightness-0" src={Clock} alt="Clock" />
                </span>
                <span className="group ease-in duration-300">Delay</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default AddBlock;
