import BarGraph from 'components/Graph/BarGraph';
import { ChartDataset } from 'chart.js';
import { FC, useEffect, useState } from 'react';
import { campaigns, Workflows } from './Types';
import moment from 'moment';
import { z } from 'zod';
import React from 'react';
import Skeleton from 'components/Skeleton/Skeleton';
const groupedDataSchema = z.record(
  z.object({
    date: z.string(),
    monetaryValue: z.number(),
  })
);
const MonetaryValueSchema = z.object({
  workflows: z.number(),
  total: z.number(),
  campaigns: z.number(),
});
const ChartDataSchema = z.object({
  barThickness: z.number(),
  barPercentage: z.number(),
  backgroundColor: z.string(),
  label: z.string(),
  data: z.array(z.string()),
});
const ChartSchema = z.array(ChartDataSchema);
type ChartData = z.infer<typeof ChartSchema>;
type MonetaryValue = z.infer<typeof MonetaryValueSchema>;
type GroupedData = z.infer<typeof groupedDataSchema>;
interface Props {
  campaigns: campaigns;
  workflows: Workflows;
  orderDates: number[];
  loading: boolean;
}
const Revenue: FC<Props> = ({ campaigns, workflows, orderDates, loading }) => {
  const [monetaryValue, setMonetaryValues] = useState<MonetaryValue>({
    workflows: 0,
    total: 0,
    campaigns: 0,
  });
  const [chartData, setChartData] = useState<ChartData>([]);
  const [chartLabels, setChartLabels] = useState<Array<string | undefined>>();
  const [revenueLoading, setRevenueLoading] = useState<boolean>(true);
  const getData = () => {
    setRevenueLoading(true);
    const tempChartData = [];
    const tempMonetaryValue = monetaryValue;
    let campaignMonetaryValues: string[] = [];
    let tempChartLabels: string[] = [];
    const tempWorkflowDates = orderDates.map((data) =>
      moment(data).format('MMM DD')
    );
    const tempDates = tempWorkflowDates?.filter(
      (item, i, ar) => ar.indexOf(item) === i
    );
    tempChartLabels = tempDates || [];
    const tempBlastValues: campaigns = [];
    tempMonetaryValue.workflows =
      workflows.reduce(
        (accumulator, currentValue) => accumulator + currentValue.monetaryValue,
        tempMonetaryValue.workflows
      ) || 0;
    const workflowMonetary = workflows.map((data) =>
      String(data.monetaryValue)
    );
    tempChartData.push({
      barThickness: 16,
      barPercentage: 0.5,
      backgroundColor: '#8FDBC0',
      label: ' Revenue from Workflows',
      data: workflowMonetary ? workflowMonetary : [],
    });
    const groupedCampaigns = campaigns.reduce((result: GroupedData, item) => {
      const { date, monetaryValue } = item;
      if (date !== undefined) {
        if (!result[date]) {
          result[date] = { date, monetaryValue: 0 };
        }
        result[date].monetaryValue += monetaryValue;
      }
      return result;
    }, {});
    if (groupedCampaigns) {
      tempChartLabels = tempChartLabels.concat(
        Object.values(groupedCampaigns).map((data) => String(data.date))
      );
      campaignMonetaryValues = Object.values(groupedCampaigns).map((data) =>
        String(data.monetaryValue)
      );
    }
    tempChartData.push({
      barThickness: 16,
      barPercentage: 0.5,
      backgroundColor: '#2EA1F9',
      label: ' Revenue from campaigns',
      data: campaignMonetaryValues ? campaignMonetaryValues : [],
    });
    tempMonetaryValue.campaigns = campaigns
      ? tempBlastValues.reduce(
          (accumulator, currentValue) =>
            accumulator + currentValue.monetaryValue,
          tempMonetaryValue.campaigns
        )
      : 0;
    tempMonetaryValue.total =
      tempMonetaryValue.campaigns + tempMonetaryValue.workflows;
    const sortedDates = tempChartLabels.sort((a, b) =>
      moment(a, 'MMM DD').diff(moment(b, 'MMM DD'))
    );
    setChartLabels(sortedDates);
    setChartData(tempChartData);
    setMonetaryValues(tempMonetaryValue);
    setRevenueLoading(false);
  };
  useEffect(() => {
    getData();
    //need to dicusss in detail
  }, [orderDates, workflows, campaigns]);

  return (
    <div className="mt-11 ">
      <h2 className="sm:text-xl text-base sm:leading-5 leading-3 text-black dark:text-white font-medium">
        Revenue
      </h2>
      <div>
        <div className="flex md:flex-row flex-col font-inter mt-4">
          <div className="w-full">
            <div className="xl:px-7 lg:px-5 px-3.5 sm:py-4 py-2.5 rounded-lg shadow-lg shadow-gray-200 dark:shadow-none bg-white  dark:bg-[#41464E] ">
              <div className="flex xl:flex-row justify-between flex-col">
                <div className="w-8/12 xl:pl-10">
                  {revenueLoading || loading ? (
                    <Skeleton columns={10} />
                  ) : (
                    <BarGraph
                      height={361}
                      stacked={true}
                      chartLabels={chartLabels}
                      datasets={
                        chartData as unknown as ChartDataset<
                          'bar',
                          (number | [number, number] | null)[]
                        >[]
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Revenue;
