import { FC, useRef } from 'react';
import { useOutsideClick } from 'app/hooks/useOutsideClick';
import ModalWrapper from 'components/Workflow/ModalWrapper';
import React from 'react';

interface Props {
  onClose: () => void;
  onSave: () => void;
}
const CopyWorkflowModal: FC<Props> = ({ onClose, onSave }) => {
  const modalRef = useRef<HTMLDivElement>(null);

  useOutsideClick(modalRef, onClose);

  return (
    <ModalWrapper onClose={onClose} onSave={onSave} buttonTxt="Yes">
      <div className="bg-white dark:bg-transparent">
        <p className="text-13 font-medium leading-4 text-[#495057] dark:text-[#CED4DA]  dark:text-[#CED4DA] mt-4">
          Are you sure you want to create copy of this Workflow?
        </p>
      </div>
    </ModalWrapper>
  );
};
export default CopyWorkflowModal;
