import Breadcrumb from 'components/Breadcrumbs/Breadcrumbs';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { create, workflow } from 'services/constant/routes';
import Plus from 'assets/Images/svg-images/plus.svg';

const Header = () => {

  const {pathname} = useLocation();

  return (
    <div className="h-[61px] bg-white dark:bg-transparent md:px-6 px-3 py-2 font-inter border-b shadow-0 shadow flex justify-between items-center dark:border-[#fff]">
 
        <Breadcrumb />
        {pathname === workflow && <div>
          <Link
            to={`${workflow}/${create}`}
            className="transition ease-in-out delay-150 duration-300 fs-13 font-medium leading-5 text-white flex items-center ml-auto rounded-md md:py-3 py-1.5 sm:pl-4 sm:px-2 px-1 sm:pr-3.5 font-inter bg-primary hover:bg-primaryHover hover:scale-105"
          >
            <img className="sm:mr-2.5 mr-1" src={Plus} alt="Plus" /> Create Workflow
          </Link>
        </div>}
      </div>
    
  );
};
export default Header;
