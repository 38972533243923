import React, { FC, useContext } from 'react';
import ErrorBoundary from 'components/ErrorBoundary';
import { CampaignContext } from 'store/CampaignContext';
import Select from 'react-select';
import { Option } from './Types';

interface Props {
  filterOptions: Option[];
  listOptions: Option[];
  list: Option[];
  subscribers: Option[];
  setList: (list: Option[]) => void;
  setSubscribers: (list: Option[]) => void;
  setSelectedFilter :(filter:string) =>  void;
  showSendToError: boolean;
}

const ListSegments: FC<Props> = ({
  filterOptions,
  listOptions,
  list,
  setList,
  subscribers,
  setSubscribers,
  setSelectedFilter, 
  showSendToError, 
  
}) => {
  const campaignCtx = useContext(CampaignContext);

  const changeCampaignData = (name: string, value: number[] | string) => {
    if (campaignCtx?.campaignData) {
      campaignCtx?.onUpdateCampaignData({
        ...campaignCtx.campaignData,
        [name]: value,
      });
    }
  };

  const filterListSelectHandler = (id: Option) => {
    setSelectedFilter(id.label);
    changeCampaignData('filter_id', id.value);
  };
  const subscriberChangeHandler = (selectedValues: readonly Option[]) => {
    const updatedList = selectedValues.map((lists) => {
      return { value: lists.value, label: lists.label };
    });
    const includeIds = selectedValues.map((list) => parseFloat(list.value));
    changeCampaignData('include_lists', includeIds);
    setSubscribers(updatedList);
  };
  const excludeListChangeHandler = (selectedValues: readonly Option[]) => {
    const updatedList = selectedValues.map((lists) => {
      return { value: lists.value, label: lists.label };
    });
    const excludeIds = selectedValues.map((list) => parseFloat(list.value));
    changeCampaignData('exclude_lists', excludeIds);
    setList(updatedList);
  };

  return (
    <ErrorBoundary>
      <div className="w-full">
        <div className=" w-full flex items-center">
          <h3 className="text-lg leading-5 text-[#495057] font-medium dark:text-white ">
            Lists & Segments
          </h3>
          <a className="text-xs font-medium leading-3 text-primary ml-2 underline pt-0.5 cursor-pointer">
            Refresh
          </a>
        </div>
        <div className="mt-4">
          <div>
            <div className="">
              <p className="text-sm font-medium leading-3 tracking-wide text-[#212529] dark:text-white mb-2">
                Send to <span className='text-red-700'>*</span>
              </p>
              <div className="relative">
                <div className=" w-full">
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    value={subscribers}
                    isSearchable={true}
                    isMulti={true}
                    placeholder={<div>Send to..</div>}
                    options={listOptions}
                    onChange={(selectedOption: readonly Option[]) => {
                      subscriberChangeHandler(selectedOption);
                      
                    }}
                  />
                </div>
              </div>
            </div>
            {showSendToError ?<div className="text-xs leading-4 text-red-400 mt-1.5">
            Send to cannot be empty
          </div>:<></> }

            <div className=" mt-4">
              <div className="">
                <p className="text-sm font-medium leading-3 tracking-wide text-[#212529] dark:text-white mb-2">
                  Exclude List
                </p>
                <div className="relative">
                  <div className="w-full">
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      value={list}
                      isSearchable={true}
                      isMulti={true}
                      placeholder={<div>Exclude List..</div>}
                      options={listOptions}
                      onChange={(selectedOption: readonly Option[]) => {
                        excludeListChangeHandler(selectedOption);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="mt-4">
              <div className="">
                <p className="text-sm font-medium leading-3 tracking-wide text-[#212529] dark:text-white mb-2">
                  Apply Filters
                </p>
                <div className="">
                  <Select
                    className="basic-single"
                    classNamePrefix="select"
                    isSearchable={true}
                    options={filterOptions}
                    placeholder={<div>Select a Filter..</div>}
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    onChange={(selectedOption: any) => {
                      if (selectedOption) {
                        filterListSelectHandler(selectedOption);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};
export default ListSegments;
