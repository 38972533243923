import httpService from 'services/core/HttpService';
import { GET_ALL_LOGS } from 'services/constant/apiRoutes';

const { REACT_APP_BASE_URL } = process.env;

let successfullEvent: string;

export const getAllLogs = (
  startDate: string,
  endDate: string,
  filter?: string,
  startToken?: string,
  selectedEvent?: string
) => {
 
  
if(selectedEvent && selectedEvent === 'All Events')
  {

    return new Promise((resolve, reject) => {
    let url =
      GET_ALL_LOGS.replace(
        '{{FILTER}}',
        filter && filter?.length > 0 ? `&filter=${filter}` : ''
      )
        .replace('{{START}}', startDate)
        .replace('{{END}}', endDate) +
      (startToken ? `&startToken=${startToken}` : '');

   url = url.replace('{{NAME}}', '');

  httpService
      .get(url)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });

  }else 
  if(selectedEvent  && selectedEvent !== 'All Events'){

    
    
    const eventValues = selectedEvent?.split(',').map(value => value.trim());

    const promises =  eventValues.map( eventName =>  {
      let url = GET_ALL_LOGS.replace(
        '{{FILTER}}',
        filter && filter?.length > 0 ? `&filter=${filter}` : ''
      )
      .replace('{{START}}', startDate)
      .replace('{{END}}', endDate) +
      (startToken ? `&startToken=${startToken}` : '');
    
      if (eventName) {
        url = url
          .replace('_by_timestamp', '')
          .replace('{{NAME}}', `&name=${eventName}`);
      } else {
        url = url.replace('{{NAME}}', '');
      }
    
      try {
        return  httpService.get(url);
      } catch (error) {
        
      
        console.error(`Failed to fetch data for event: ${eventName}`, error);
        return null;
      }
    });
    
    

  const promisesToReturn =   Promise.all(promises)
      .then(responses => {
         

        const successfulResults = responses.filter(response => response?.data.result.length !== 0);
        
        if(successfulResults.length==1)
        {
            successfullEvent = successfulResults[0]?.data.result[0].name;
            return successfulResults[0];
        }else if(successfulResults.length>1)
        {
          const combinedRespnse = {
            //TODO
            //return combined reponse incase there are more than one calls with data response, 

          }

          return combinedRespnse;
        }
        else return responses[0];

      })
      .catch(error => {
        
        console.error('Promise.all rejected:', error);
      });

    
      return promisesToReturn;
  
  

  }





};


export const downloadLogReport = (
  selectedEvent: string,
  filter: string,
  startDate: string,
  endDate: string,
  startToken: string
) => {
  selectedEvent = successfullEvent;
  const url =
    GET_ALL_LOGS.replace('_by_timestamp', '')
      .replace('{{NAME}}', `&name=${selectedEvent}`)
      .replace('{{FILTER}}', filter ? `&filter=${filter}` : '')
      .replace('{{START}}', startDate)
      .replace('{{END}}', endDate) +
    `&startToken=${startToken}` +
    `&format=csv`;
    
  window.open(REACT_APP_BASE_URL + url);
};
