import Table from 'components/Table';
import moment from 'moment';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import { calculatePercentage } from 'utils/common';
import { PerformanceReports, OrderReportData } from './index.type';

interface Props {
  performanceReports: PerformanceReports[];
  loading: boolean;
}

const PerformanceReport: FC<Props> = ({ performanceReports, loading }) => {
  const [reportsData, setReportsData] = useState<Array<OrderReportData>>([]);

  const getFilteredData = (reports: PerformanceReports[]) => {
    const recipients = reports.map((report) => {
      return {
        date:
          moment()
            .month(report.month - 1)
            .format('MMMM') +
          ' ' +
          report.year,
        total: report.people,
      };
    });
    const clickedRate = reports.map((report) => {
      return {
        date:
          moment()
            .month(report.month - 1)
            .format('MMMM') +
          ' ' +
          report.year,
        total:  calculatePercentage(report.uniqueClicks, report.people),
      };
    });
    const conversions = reports.map((report) => {
      return {
        date:
          moment()
            .month(report.month - 1)
            .format('MMMM') +
          ' ' +
          report.year,
        total: report.monetaryValue,
      };
    });
    const conversionRate = reports.map((report) => {
      return {
        date:
          moment()
            .month(report.month - 1)
            .format('MMMM') +
          ' ' +
          report.year,
        total:  calculatePercentage(report.conversions, report.people),
      };
    });

    const tempReportsData = [
      { metric: 'Recipients', dates: recipients },
      { metric: 'Clicked(%)', dates: clickedRate },
      { metric: 'Conversions($)', dates: conversions },
      { metric: 'Conversions(%)', dates: conversionRate },
    ];

    setReportsData(tempReportsData);
  };

  const columns: TableColumn<OrderReportData>[] = useMemo(() => {
    if (reportsData.length > 0) {
      const dateColumns = reportsData[0]?.dates?.map((report) => ({
        name: report.date,
        cell: (row: OrderReportData) => {
          const metricData = reportsData?.find(
            (item) => item.metric === row.metric
          );
          const dateData = metricData?.dates.find(
            (date) => date.date === report.date
          );
          const value = dateData ? dateData.total : '';

          return (
            <div className="flex items-center pl-3">
              <div>
                <div className="flex items-center justify-center text-white rounded-lg">
                  <p className="text-sm font-medium text-darkText dark:text-[#878A99]  truncate w-[110px]">
                    {row.metric === 'Recipients'
                      ? value.toLocaleString()
                      : row.metric === 'Conversions($)'
                      ? `$${typeof value === "number" ? Math.round(value).toLocaleString() : Math.round(parseInt(value)).toLocaleString()}`
                      : `${value}%`}
                  </p>
                </div>
              </div>
            </div>
          );
        },
      }));

      return [
        {
          name: <div className="pl-3">Metric</div>,
          cell: (row) => (
            <div className="flex items-center pl-3">
              <div>
                <div className="flex items-center justify-center text-white rounded-lg">
                  <p className="text-sm font-medium text-darkText dark:text-[#878A99]  truncate w-[110px]">
                    {row.metric}
                  </p>
                </div>
              </div>
            </div>
          ),
        },
        ...dateColumns,
      ];
    } else {
      return [];
    }
  }, [reportsData]);

  useEffect(() => {
    getFilteredData(performanceReports);
  }, [performanceReports]);


  return (

      <div className="grid grid-cols-1 gap-4 font-inter overflow-hidden rounded-xl allWorkFlows">
        <Table
          className="rounded-t-2xl scrollbar-hide overflow-auto p-0"
          data={reportsData}
          columns={columns}
          progressPending={loading}
          pagination={false}
          // persistTableHead={false}
          fixedHeader={false}
        />
      </div>

  );
};
export default PerformanceReport;
