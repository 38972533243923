import { ChartDataset, ScatterDataPoint } from 'chart.js';
import LineGraph from 'components/Graph/LineGraph';
import { FC, useRef, useState } from 'react';
import PieGraph from 'components/Graph/PieGraph';
import BarGraph from 'components/Graph/BarGraph';
import { storeReportOrder } from './Types';
import ErrorBoundary from 'components/ErrorBoundary';
import React from 'react';
import CustomSkeleton from 'components/Skeleton/Skeleton';
import Wrapper from 'components/Wrapper';
import { useOutsideClick } from 'app/hooks/useOutsideClick';
import Modal from 'components/Alert/Modal';


interface Props {
  data: storeReportOrder;
  loading?: boolean;
  isQuantitativeData?: boolean;
}

const Order: FC<Props> = ({ isQuantitativeData, data, loading }) => {
  const [isFullView, setIsFullView] = useState(false);

  const outsideRef = useRef<HTMLDivElement>(null);
  useOutsideClick(outsideRef, () => setIsFullView(false));
  return (
    <ErrorBoundary>
      <div className="xl:mt-0 sm:mt-5 ">
        <Wrapper parentClass="!mt-0 " childClass="h-auto ">
          <div className="">
            <div className="cardHeader dark:border-[#fff]">
              <h4 className="text-base text-darkText font-medium dark:text-[#878A99]">
                {data.orderType}
              </h4>
            </div>
            <div className="lg:px-3 px-3.5">
              {(loading && data.graphData && !data.graphData[0].data[0] && !data.graphData[1].data[0]) ? (
                <CustomSkeleton columns={5} />
              ) : (
                <div >
                  <div onDoubleClick={() => setIsFullView(true)}>
                    {data.graphType === 'Line' && (
                      <LineGraph
                        chartLabels={data.graphCategories}
                        datasets={
                          data.graphData as ChartDataset<
                            'line',
                            (number | ScatterDataPoint | null)[]
                          >[]
                        }
                        width={data.graphWidth}
                        height={data.graphHeight}
                        style={data.graphStyle}
                        isQuantitativeData={isQuantitativeData}
                        hideLegend={data?.hideLegend}
                      />
                    )}
                  </div>
                  <div onDoubleClick={() => setIsFullView(true)}>
                    {data.graphType === 'Coloumn' && (
                      <BarGraph
                        height={data.graphHeight}
                        chartLabels={data.graphCategories}
                        datasets={
                          data.graphData as ChartDataset<
                            'bar',
                            (number | [number, number] | null)[]
                          >[]
                        }
                        style={data.graphStyle}
                        hideLegend={data?.hideLegend}
                      />
                    )}
                  </div>
                  <div onDoubleClick={() => setIsFullView(true)}>
                    {data.graphType === 'Pie' && (
                      <PieGraph
                        labels={data.graphCategories}
                        datasets={
                          data.graphData as ChartDataset<'doughnut', number[]>[]
                        }
                        height={data.graphHeight || 0}
                        width={data.graphWidth}
                      />
                    )}
                  </div>
                  <div className="flex justify-between items-center">
                    <Modal isOpen={isFullView}>
                      <div ref={outsideRef} className="h-[70vh] mt-[100px] bg-white dark:bg-[url('assets/Images/Common/denimbg.webp')]  w-[70%] rounded p-6 mx-auto ">
                        {data.graphType === 'Line' && (
                          <LineGraph
                            chartLabels={data.graphCategories}
                            datasets={
                              data.graphData as ChartDataset<
                                'line',
                                (number | ScatterDataPoint | null)[]
                              >[]
                            }
                            isQuantitativeData={isQuantitativeData}
                            hideLegend={data?.hideLegend}
                            width={data.graphWidth}
                            height={data.graphHeight}
                          />
                        )}
                        {data.graphType === 'Coloumn' && (
                          <BarGraph
                            chartLabels={data.graphCategories}
                            datasets={
                              data.graphData as ChartDataset<
                                'bar',
                                (number | [number, number] | null)[]
                              >[]
                            }
                            hideLegend={data?.hideLegend}
                          />
                        )}
                        {data.graphType === 'Pie' && (
                          <PieGraph
                            labels={data.graphCategories}
                            datasets={
                              data.graphData as ChartDataset<
                                'doughnut',
                                number[]
                              >[]
                            }
                            height={'100%'}
                            width={'100%'}
                          />
                        )}
                      </div>
                    </Modal>
                  </div>
                </div>
              )}
            </div>
          </div>
        </Wrapper>
      </div>
    </ErrorBoundary>
  );
};
export default Order;
