import React, { FC } from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  registerables,
  ChartDataset,
  ScatterDataPoint,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { useContext } from 'react';
import { ThemeContext } from 'store/ThemeContext';
import { z } from 'zod';
import { getYAxisGraphValue } from 'utils/common';
import ErrorBoundary from 'components/ErrorBoundary';

const tooltipDataSchema = z.object({ formattedValue: z.string() });
type TooltipCallback = z.infer<typeof tooltipDataSchema>;

const chartStyleSchema = z.object({
  maxWidth: z.string().optional(),
  width: z.string().optional(),
  height: z.string().optional(),
  padding: z.string().optional(),
});

type chartStyle = z.infer<typeof chartStyleSchema>;

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ArcElement,
  ...registerables
);

interface Props {
  chartLabels: string[] | undefined;
  datasets: ChartDataset<'line', (number | ScatterDataPoint | null)[]>[];
  width?: number | string;
  height?: number | string;
  style?: chartStyle;
  isQuantitativeData?: boolean;
  hideLegend?: boolean;
}

const LineGraph: FC<Props> = ({
  chartLabels,
  datasets,
  width,
  height,
  style,
  isQuantitativeData,
  hideLegend,
}) => {
  const themeCtx = useContext(ThemeContext);
  const theme = themeCtx?.theme;

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    stacked: true,
    elements: {
      line: {
        tension: 0.5,
      },
    },
    plugins: {
      title: {
        display: true,
      },
      tooltip: {
        callbacks: {
          label: function (data: TooltipCallback) {
            if (!isQuantitativeData) {
              return '$' + data.formattedValue;
            } else {
              return data.formattedValue;
            }
          },
        },
      },
      legend: {
        display: hideLegend ? false : true,
        position: 'bottom' as const,
        labels: {
          usePointStyle: true,
          boxWidth: 7,
          boxHeight: 7,
          color: `${theme === 'dark' ? 'white' : ''}`,
          padding: 20,
          font: {},
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        categorySpacing: 0,
        ticks: {
          color: `${theme === 'dark' ? '#CED4DA' : ''}`,
          beginAtZero: true,
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        categorySpacing: 0,
        ticks: {
          color: `${theme === 'dark' ? '#CED4DA' : ''}`,
          beginAtZero: true,
          callback: function (value: number | string) {
            const formattedValue = getYAxisGraphValue(value)
            const isDollarSign = !isQuantitativeData ? '$' : '';
            return isDollarSign + (value === 0 ? value : formattedValue)
          },
        },
        grid: {
          color: `${theme === 'dark' ? '#fff' : '#e9ebec'}`,
        },
      },
    },
  };

  const labels = chartLabels;

  const data = {
    labels,
    datasets,
  };

  return (
    <ErrorBoundary>
      <Line
        width={width}
        height={height}
        options={options}
        data={data}
        style={style}

      />
    </ErrorBoundary>
  );
};
export default LineGraph;
