/* eslint-disable @typescript-eslint/no-var-requires */
import { FC, useEffect, useState } from 'react';
import { useFormik } from 'formik';
// import Tooltip from 'components/Tooltip';
import * as Yup from 'yup';
import { saveEmail } from 'services/apiHandlers/Campaigns/Emails';
import { Toastify } from 'App';
import Label from 'assets/Images/Campaign/label.svg';
import { z } from 'zod';
import React from 'react';
import { Tooltip } from 'react-tooltip';

interface Props {
  onClose: () => void;
  email: string;
  onSaveData?: (name: string) => void;
  name: string;
  labels: string;
  view: string | undefined;
}

const sendEmailResponseSchema = z.object({
  status: z.number(),
  data: z.object({ id: z.number() }),
});
type SendEmailResponse = z.infer<typeof sendEmailResponseSchema>;

const SaveModal: FC<Props> = ({
  onClose,
  email,
  onSaveData,
  name,
  labels,
}) => {
  const [currentVersion, setCurrentVersion] = useState(true);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const formik = useFormik({
    initialValues: { name: '', labels: '' },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      const data = {
        draft: { html_part: email, name: values.name, tag_list: values.labels },
        options: { save_over: currentVersion ? true : false },
        commit: currentVersion ? 'Save over current e-mail' : 'Save now',
      };
      try {
        const saveEmailResponse = (await saveEmail(data)) as SendEmailResponse;
        if (saveEmailResponse.status === 200) {
          Toastify('Email Submitted Successfully', 'success', 'save2');
          if (onSaveData) {
            onSaveData(values.name);
            // if (view !== 'edit') {
            //   navigate(`${emailEditor}/${edit}/${saveEmailResponse.data.id}`);
            // }
          }
        }
      } catch (error) {
        Toastify('Email Submission Failed', 'error', 'save2');
      }
      onClose();
    },
  });

  useEffect(() => {
    if (name !== '') {
      formik.setFieldValue('name', name);
      formik.setFieldValue('labels', labels);
    }
  }, [name, labels]);

  return (
    <form
      onSubmit={formik.handleSubmit}
      className="absolute z-10 mt-4 w-[450px] -right-2 top-2"
    >
      <div className="bg-white rounded-lg overflow-hidden shadow-xl dark:bg-[#41464E]">
        <div className="text-center">
          <h3 className="text-lg font-medium leading-6 text-gray-900 my-4 dark:text-white">
            Save Component
          </h3>
        </div>
        <div className="flex flex-col gap-2 px-5">
          <div className="flex flex-col gap-1">
            <label
              htmlFor="name"
              className="text-sm font-medium text-gray-700 mb-1 text-left dark:text-white"
            >
              Name
            </label>
            <input
              id="name"
              type="text"
              value={formik.values.name}
              className="border border-[#ced4da] rounded-md px-2 py-1 focus:outline-none focus:border-primary dark:border-[#fff] shadow-sm shadow-gray-200 dark:shadow-none sm:[38px] h-9  dark:bg-[#41464E]  dark:text-white dark:hover:text-white"
              onChange={formik.handleChange}
            />
          </div>
          {formik.values.name.length === 0 && formik.dirty && (
            <div className="ml-2 mr-2 mb-2 px-6 py-3 bg-[#f7e8e7] rounded mt-4">
              <p className="text-sm font-normal leading-4 text-[#CB5A5A]">
                Name cannot be empty
              </p>
            </div>
          )}
          <div className="flex flex-col gap-1">
            <label
              htmlFor="labels"
              className="text-sm font-medium text-gray-700 mb-1 text-left dark:text-white"
            >
              Labels
            </label>
            <input
              id="labels"
              type="text"
              className="border border-[#ced4da] rounded-md px-2 py-1 focus:outline-none focus:border-primary dark:border-[#fff] shadow-sm shadow-gray-200 dark:shadow-none sm:[38px] h-9  dark:bg-[#41464E]  dark:text-white dark:hover:text-white"
              value={formik.values.labels}
              onChange={formik.handleChange}
            />

            <div className="flex items-center text-sm text-gray-500 mt-1 dark:text-white">
              <img
                src={Label}
                alt="comma separator"
                className="w-4 h-4 mr-1 dark:invert dark:brightness-0"
              />
              <span>Enter labels separated by commas</span>
              <span className="ml-auto text-black">
                <div
                  onMouseEnter={() => {
                    setTooltipOpen(true);
                  }}
                  onMouseLeave={() => setTooltipOpen(false)}
                  id="tooltip-anchor-children"
                >
                  <span className="text-black-600 font-medium dark:text-white cursor-pointer">
                    Help
                  </span>
                  <div>
                    <Tooltip
                      isOpen={tooltipOpen}
                      anchorId="tooltip-anchor-children"
                    >
                      <div>
                        <h1 className="font-bold">What are Labels?</h1>
                        <p>
                          <b>Labels</b> are keywords assigned to an e-mail that
                          describe its content or purpose so you can
                          <b> retrieve</b> it later.
                        </p>
                      </div>
                    </Tooltip>
                  </div>
                </div>
              </span>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-2 pb-4 px-5">
          <input
            id="overwrite"
            type="checkbox"
            className="rounded-lg border-gray-300"
            checked={currentVersion}
            onChange={(e) => setCurrentVersion(e.target.checked)}
          />
          <label
            htmlFor="overwrite"
            className="text-sm font-medium text-gray-700 repeating dark:text-white"
          >
            Save over current version?
          </label>
        </div>
        <div className="flex items-center justify-end p-5 border-t">
          <button
            onClick={() => onClose()}
            className="text-center  sm:text-13 text-xs py-2.5 px-4 h-11 flex justify-center items-center w-28 leading-5 mr-2  rounded-md bg-[#f3f6f9] hover:bg-[#cfd1d4] transition-bg text-[#212529]"
            type="button"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="bg-primary ease-in-in h-11 duration-300 hover:bg-primaryHover hover:scale-105 text-white rounded-md px-3 py-2"
          >
            {currentVersion ? 'Save over current e-mail' : 'Save now'}
          </button>
        </div>
      </div>
    </form>
  );
};

export default SaveModal;
