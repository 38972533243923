import React, { FC, useContext, useEffect, useState } from 'react';
import { WorkflowType } from 'components/Workflow/Body/Workflows/index.type';
import moment from 'moment-timezone';
import { UserContext } from 'store/UserContext';
import Wrapper from 'components/Wrapper';
import ConversionImage from 'assets/Images/Dashboard/average-open-rate.svg';
import TargetEvent from 'assets/Images/Dashboard/average-deliverability-rate.svg';
import Status from 'assets/Images/Dashboard/average-click-rate.svg';
import Modified from 'assets/Images/Dashboard/average-conversion-rate.svg';
import { z } from 'zod';

const cardDataSchema = z.object({
  src: z.string(),
  title: z.string(),
  value: z.string(),
});

type CardData = z.infer<typeof cardDataSchema>;

interface Props {
  workflowsLoading: boolean;
  selectedWorkflowData: WorkflowType | undefined;
}

const Header: FC<Props> = ({ workflowsLoading, selectedWorkflowData }) => {
  const userCtx = useContext(UserContext);
  const [cardData, setCardData] = useState<Array<CardData>>([]);
  useEffect(() => {
    setCardData([
      {
        title: 'Conversion',
        src: ConversionImage,
        value: selectedWorkflowData?.name || '',
      },
      {
        title: 'Trigger Event',
        src: TargetEvent,
        value:
          selectedWorkflowData?.initialConditionGroup &&
          'eventName' in selectedWorkflowData.initialConditionGroup
            ? (selectedWorkflowData?.initialConditionGroup.eventName || '')
            : '-',
      },
      {
        title: 'Status',
        src: Status,
        value:
          selectedWorkflowData?.expiresAt && selectedWorkflowData?.isEnabled
            ? 'Expired'
            : selectedWorkflowData?.isEnabled &&
              selectedWorkflowData?.isTestMode
            ? 'Enabled(Test Mode)'
            : selectedWorkflowData?.isEnabled
            ? 'Enabled'
            : 'Disabled',
      },
      {
        title: 'Modified',
        src: Modified,
        value: moment
          .tz(
            selectedWorkflowData?.lastModified,
            userCtx?.usersData?.timeZone || ''
          )
          .format('MMMM D, YYYY h:mm:ss A'),
      },
      {
        title: 'Last Triggered',
        src: ConversionImage,
        value: selectedWorkflowData?.lastTriggered
          ? moment
              .tz(
                selectedWorkflowData?.lastTriggered,
                userCtx?.usersData?.timeZone || ''
              )
              .format('MMMM D, YYYY h:mm:ss A')
          : '-',
      },
    ]);
  }, [selectedWorkflowData]);
  return (
    <div className="md:px-6 px-3 py-5 font-inter">
      <div className="flex font-inter 2xl:flex-nowrap flex-wrap w-full 2xl:gap-2 gap-4 justify-center 2xl:justify-between ">
        {cardData?.map((card) => (
          <Wrapper
            key={card.title}
            childClass="p-4"
            parentClass="2xl:max-w-[19%] xl:max-w-[32%] lg:max-w-[48%] w-full"
          >
            <>
              <div className="flex items-center">
                <div className="h-8 w-8 mr-3 flex items-center justify-center bg-[#3575d526] rounded-md">
                  <img src={card.src} alt="No" />
                </div>
                <p className="tracking-wide text-secondary dark:text-[#CED4DA] text-sm leading-4 truncate ">
                  {card.title}
                </p>
              </div>
              <p className="my-6 leading-4 text-[#212529] dark:text-[#CED4DA] text-medium  text-base truncate">
                {workflowsLoading ? "-----------" : card.value}
              </p>
            </>
          </Wrapper>
        ))}
      </div>
    </div>
  );
};
export default Header;
