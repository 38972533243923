import ToggleSwitch from 'ToggleSwitch/ToggleSwitch';
import Edit from 'assets/Images/ListsegmentImages/edit.png';
import Delete from 'assets/Images/ListsegmentImages/delete.png';
import List from 'assets/Images/Workflow/list.png';
import Report from 'assets/Images/Dashboard/Overview-gray.svg';
import { WorkflowType } from './index.type';
import { FC, useState, Dispatch, SetStateAction, useRef } from 'react';
import {
    createWorkflow,
    deleteWorkflow,
} from 'services/apiHandlers/Workflows/Workflow';
import { z } from 'zod';
import { Toastify } from 'App';
import DeleteModal from 'components/Alert/DeleteModal';
import Duplicate from 'assets/Images/Workflow/copy.png';
import { Link } from 'react-router-dom';
import { logs, workflow, workflowReport } from 'services/constant/routes';
import React, { useEffect } from 'react';
const responseSchema = z.object({ status: z.number() });
type Response = z.infer<typeof responseSchema>;
interface Props {
    row: WorkflowType;
    onStatusChange: (id: string, status: boolean) => void;
    index: number;
    logStatus: string;
    getData: (notLoad: boolean) => void;
    actionId: string | null;
    setActionId: Dispatch<SetStateAction<string | null>>;
}
const WorkflowActions: FC<Props> = ({
    row,
    onStatusChange,
    index,
    getData,
    logStatus,
    setActionId,
    actionId,
}) => {
    const dropdownRef = useRef<HTMLDivElement>(null);
    const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);
    const toggleDropdown = (index: string | null) => {
        setActionId(index);
    };
    const handleClickOutside = (event: MouseEvent | TouchEvent) => {
        if (
            dropdownRef.current &&
            !dropdownRef.current.contains(event.target as Node)
        ) {
            setActionId(null);
        }
    };
    useEffect(() => {
        document.addEventListener('click', handleClickOutside);
    }, [dropdownRef]);
    const onDuplicateHandler = async () => {
        const duplicateWorkflow = { ...row, name: 'Copy of ' + row.name };
        delete duplicateWorkflow.id;
        setActionId(null);
        try {
            const duplicateWorkflowResponse = (await createWorkflow(
                duplicateWorkflow
            )) as Response;
            if (duplicateWorkflowResponse.status === 201) {
                Toastify('Duplicate Workflow Created', 'success', 'action1');
                getData(true);
            }
        } catch (error) {
            console.log('error is : ', error);
        }
    };
    const deleteHandler = async () => {
        setActionId(null);
        try {
            const deleteWorkflowResponse = (await deleteWorkflow(
                row?.id || ''
            )) as Response;
            if (deleteWorkflowResponse.status === 200) {
                getData(true);
                Toastify('Workflow Deleted Successfully', 'success', 'action2');
            }
        } catch (error) {
            console.log('error is : ', error);
        }
    };
    const toggleShowDelete = (state: boolean) => {
        setShowDeleteAlert(state);
        setActionId(null);
    };
    return (
        <div className="whitespace-nowrap text-13 sm:align-top align-middle text-[#495057] dark:text-[#CED4DA]  dark:text-white w-[270px] flex items-center">
            <ToggleSwitch
                onChange={() =>
                    onStatusChange(row.id || '', row.isEnabled || false)
                }
                checked={row.isEnabled}
                id={index}
            />

            {/* <Link
                to={`${workflow}/${logs}/${row?.id}?status=${logStatus}`}
                type="button"
                className="flex items-center mr-4 py-3 p-0 dark:text-white"
            >
                <img
                    className="mr-2 dark:invert dark:brightness-0"
                    src={List}
                    alt="List"
                />
                <span className="dark:text-white text-[#212529]">Logs</span>
            </Link> */}
            {/* <Link
                to={`${workflowReport}/${row.id}`}
                className="flex items-center mr-4 py-3 p-0 dark:text-white"
            >
                <img
                    className="mr-2 dark:invert dark:brightness-0"
                    src={Report}
                    alt="List"
                />
                <span className="dark:text-white text-[#212529]">Reports</span>
            </Link> */}
            <div className="relative">
                <button
                    onClick={() => toggleDropdown(row.id || null)}
                    type="button"
                    className="flex rounded bg-blue-100  dark:bg-[#41464E]  items-center p-0.5 px-2 dark:text-white"
                >
                    <span className="dark:text-white text-primary">...</span>
                </button>
                {actionId === row.id && (
                    <div
                        ref={dropdownRef}
                        className="bg-white absolute -left-[130px] z-[9999] w-[160px] shadow-lg rounded-lg  dark:bg-[#41464E] "
                    >
                        <button
                            onClick={() => onDuplicateHandler()}
                            type="button"
                            className="w-full flex items-center hover:bg-[rgb(243,246,249)] px-4 py-2  dark:text-white group"
                        >
                            <img
                                src={Duplicate}
                                alt="Duplicate"
                                className="inline mr-1.5 h-[18px] w-[18px] dark:invert dark:brightness-0 dark:group-hover:invert-1 dark:group-hover:brightness-100"
                            />
                            <span className="dark:text-white dark:group-hover:text-[#212529]">Duplicate</span>
                        </button>
                        <button
                            onClick={() => toggleShowDelete(true)}
                            type="button"
                            className="flex items-center hover:bg-[rgb(243,246,249)] px-4 py-2 w-full dark:text-white group"
                        >
                            <img
                                className="mr-2 dark:invert dark:brightness-0 dark:group-hover:invert-1 dark:group-hover:brightness-100"
                                src={Delete}
                                alt="Delete"
                            />
                            <span className="dark:text-white dark:group-hover:text-[#212529]">Delete</span>
                        </button>
                        <div className=" hover:bg-[rgb(243,246,249)] px-4 py-2 group">
                            <Link to={`${workflow}/${row.id.toString()}`}>
                                <img
                                    src={Edit}
                                    alt="Edit"
                                    className="inline mr-1.5 dark:invert dark:brightness-0 dark:group-hover:invert-1 dark:group-hover:brightness-100"
                                />
                                <span className="dark:text-white relative top-0.5 dark:group-hover:text-[#212529]">
                                    Edit
                                </span>
                            </Link>
                        </div>
                        <div className=" hover:bg-[rgb(243,246,249)] px-4 py-2 group">
                            <Link
                                to={`${workflow}/${logs}/${row?.id}?status=${logStatus}`}
                                type="button"
                                className="flex items-center "
                            >
                                <img
                                    className="mr-[6px] dark:invert dark:brightness-0 h-[18px] w-[18px] dark:group-hover:invert-1 dark:group-hover:brightness-100"
                                    src={List}
                                    alt="List"
                                />
                                <span className="dark:text-white relative -top-0.5 text-[#212529] dark:group-hover:text-[#212529]">
                                    Logs
                                </span>
                            </Link>
                        </div>
                        <div className=" hover:bg-[rgb(243,246,249)] px-4 py-2 group ">
                            <Link
                                to={`${workflowReport}/${row.id}`}
                                className="flex items-center dark:text-white "
                            >
                                <img
                                    className="mr-[6px] h-[18px] w-[18px] dark:invert dark:brightness-0 dark:group-hover:invert-1 dark:group-hover:brightness-100"
                                    src={Report}
                                    alt="List"
                                />
                                <span className="dark:text-white text-[#212529] dark:group-hover:text-[#212529]">
                                    Reports
                                </span>
                            </Link>
                        </div>
                    </div>
                )}
            </div>
            {showDeleteAlert && (
                <div
                    onClick={() => toggleShowDelete(false)}
                    className="fixed left-0 top-0 w-full h-full z-10 bg-black-200 duration-300"
                >
                    <DeleteModal
                        onDelete={() => deleteHandler()}
                        onCancel={() => toggleShowDelete(false)}
                    />
                </div>
            )}
        </div>
    );
};
export default WorkflowActions;
