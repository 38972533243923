import ConnectedServices from './ConnectedServices';
import EmailEvents from './EmailEvents';
import ConversionPeriod from './ConversionPeriod';
import WorkflowActions from './WorkflowActions';
import ConversionEvent from './ConversionEvent';
import EmailSender from './EmailSender';
import ErrorBoundary from 'components/ErrorBoundary';
import React from "react";
import BreadcrumbsWrapper from 'components/Wrapper/BreadcrumbsWrapper';
const Miscellaneous = () => {
  return (
    <ErrorBoundary>
      <BreadcrumbsWrapper/>
      <div id="otherconversion_content" className="tabcontent px-4 py-4 min-h-[76vh]">
        <div className="pb-6 dark:border-[#fff] ">
          <div>
            <div className="lg:flex flex-wrap">
              <div className="lg:w-6/12 lg:pr-5">
                <ConnectedServices />
                <ConversionPeriod />
                <ConversionEvent />
              </div>
              <div className="lg:w-6/12 lg:pl-5 lg:mt-0 mt-8">
                <EmailEvents />
                <WorkflowActions />
                <EmailSender />
              </div>
            </div>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};
export default Miscellaneous;
