import React , { useContext } from 'react';
import { DarkModeSwitch } from 'react-toggle-dark-mode';
import { ThemeContext } from 'store/ThemeContext';

export const Switcher = () => {
  const themeCtx = useContext(ThemeContext);

  const toggleDarkMode = () => {
    themeCtx?.themeChangeHandler(themeCtx?.colorTheme);
  };
  
  return (
    <>
      <div className="sm:ml-7 mx-4 sm:mx-0 flex flex-col items-center">
        <DarkModeSwitch
          checked={themeCtx?.theme !== 'light'}
          onChange={toggleDarkMode}
          size={22}
        />
      </div>
    </>
  );
};
