import { FC, Dispatch, SetStateAction } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { home } from 'services/constant/routes';
import SidebarDropdown from './SidebarDropdown';
import { sidebar } from './index.types';
import React from 'react';
interface Props {
  sidebarItem: sidebar;
  showFullSidebar: boolean | undefined;
  setDropdownMenu: Dispatch<SetStateAction<string>>;
  dropdownMenu: string;
}
const SidebarItem: FC<Props> = ({
  sidebarItem,
  showFullSidebar,
  setDropdownMenu,
  dropdownMenu,
}) => {
  const { pathname } = useLocation();
  const checkActiveLink = () => {
    //TO-FIX remove all conditions and make one
    if (
      pathname.includes(sidebarItem.link || '') &&
      sidebarItem.link !== home
    ) {
      return true;
    } else if (pathname === home && sidebarItem.link === home) {
      return true;
    } else if(sidebarItem.link.includes('email') && pathname.includes('email')){
      return true;
    } else {
      return false;
    }
  };
  
  return (
    <>
      {!sidebarItem.dropdown ? (
        <Link
          to={sidebarItem.link}
          target={sidebarItem.text === 'Pop-Ups' ? '_blank' : '_self'}
          onClick={() => setDropdownMenu(sidebarItem.id)}
        >
          <div
            className={`${
              checkActiveLink() ? 'bg-primaryHover ' : ''
            } flex justify-between items-center rounded 2xl:py-2.5 py-1 pl-6 pr-2.5 mt-3 group hover:bg-primaryHover dark:hover:bg-white group cursor-pointer`}
          >
            <div className="flex items-center">
              <span>
                <img
                  className={
                    'brightness-0 invert  w-5 h-5 group-hover:invert group-hover:brightness-0 dark:brightness-0 dark:invert dark:group-hover:invert-0 dark:group-hover:brightness-100'
                  }
                  src={sidebarItem.image}
                  alt="Box"
                />
              </span>
              <span
                className={`${
                  checkActiveLink()
                    ? 'text-white '
                    : ' text-foreground dark:text-white '
                } ${
                  showFullSidebar ? 'lg:block sm:hidden' : 'hidden'
                } text-15 font-medium leading-3 dark:text-[#A4AAC7] dark:group-hover:text-[#495057] dark:text-[#CED4DA]  pl-3 sidebar-links group-hover:text-white`}
              >
                {sidebarItem.text}
              </span>
            </div>
          </div>
        </Link>
      ) : (
        <SidebarDropdown
          sidebarItem={sidebarItem}
          dropdownMenu={dropdownMenu}
          setDropdownMenu={setDropdownMenu}
          showFullSidebar={showFullSidebar}
        />
      )}
    </>
  );
};
export default SidebarItem;
