import { useContext, useEffect, useState } from 'react';
import ToggleSwitch from 'ToggleSwitch/ToggleSwitch';
import { useFormik, FormikValues } from 'formik';
import { CampaignContext } from 'store/CampaignContext';
import React from 'react';
const googleAnalytics = [
  { label: 'Campaign Source: *', name: 'utm_source' },
  { label: 'Campaign Medium: *', name: 'utm_medium' },
  { label: 'Campaign Content:', name: 'utm_content' },
  { label: 'Campaign Name:', name: 'utm_campaign' },
];
const GoogleAnalytics = () => {
  const campaignCtx = useContext(CampaignContext);
  const [showAnalytics, setShowAnalytics] = useState<boolean>(
    campaignCtx?.campaignData?.google_analytics || false
  );
  const formik: FormikValues = useFormik({
    initialValues: {
      utm_source:
        campaignCtx?.campaignData?.utm_source ||
        campaignCtx?.initialData.campaignData.utm_source,
      utm_medium:
        campaignCtx?.campaignData?.utm_medium ||
        campaignCtx?.initialData.campaignData.utm_medium,
      utm_content:
        campaignCtx?.campaignData?.utm_content ||
        campaignCtx?.initialData.campaignData.utm_content,
      utm_campaign:
        campaignCtx?.campaignData?.utm_campaign ||
        campaignCtx?.initialData.campaignData.utm_campaign,
    },
    onSubmit: () => console.log(),
  });
  useEffect(() => {
    if (campaignCtx?.campaignData) {
      campaignCtx?.onUpdateCampaignData({
        ...campaignCtx.campaignData,
        ...formik.values,
        google_analytics: showAnalytics,
      });
    }
  }, [showAnalytics, formik.values]);
  // useEffect(() => {
  //   if (campaignCtx?.campaignData) {
  //     formik.setFieldValue('utm_campaign', campaignCtx?.campaignData.subject);
  //     campaignCtx?.onUpdateCampaignData({
  //       ...campaignCtx.campaignData,
  //       utm_campaign: campaignCtx?.campaignData.subject,
  //     });
  //   }
  // }, [campaignCtx?.campaignData?.subject]);
  useEffect(() => {
    if (campaignCtx?.initialData && campaignCtx?.campaignData) {
      if (
        !campaignCtx.campaignData.utm_campaign &&
        !campaignCtx.campaignData.utm_content &&
        !campaignCtx.campaignData.utm_medium &&
        !campaignCtx.campaignData.utm_source
      ) {
        formik.setValues(campaignCtx.initialData.campaignData);
        campaignCtx?.onUpdateCampaignData({
          ...campaignCtx.campaignData,
          utm_source: campaignCtx.initialData.campaignData.utm_source,
        });
      }
      if (!campaignCtx.campaignData.google_analytics) {
        setShowAnalytics(campaignCtx.initialData.googleAnalytics);
      }
    }
  }, [campaignCtx?.initialData]);
  return (
    <div className="mt-8">
      <div className="flex w-full">
        <div className=" w-full">
          <div className="flex justify-between items-center">
            <p className="text-sm font-medium text-[#495057] dark:text-white mb-1">
              Integrate Google Analytics URLs
            </p>
            <div>
              <ToggleSwitch
                onChange={() => setShowAnalytics((prev) => !prev)}
                checked={showAnalytics}
              />
            </div>
          </div>
          <p className="text-xs font-normal leading-4 text-[#878A99] dark:text-white">
            Google Analytics Integration automatically modifies all links in
            your HTML email with campaign parameters. 
            <span className="font-semibold">
              Your website must have Google Analytics Integrated.
            </span>
          </p>
          <p className="text-xs font-normal leading-4 text-[#878A99] dark:text-white">
            Sample Link URL
            <span className="font-medium break-all">
              :
              http://www.example.com?utm_source=email_marketing_system&amp;utm_medium=email&amp;utm_content=%7B%7B%7Bblast_id%7D%7D%7D&amp;utm_campaign=photo
            </span>
          </p>
        </div>
      </div>
      {showAnalytics && (
        <div className="mt-4">
          {googleAnalytics.map((input) => (
            <div key={input.name} className=" mt-4">
              <label className="text-sm font-medium leading-3 tracking-wide text-[#212529] dark:text-white">
                {input.label}
              </label>
              <input
                className="block placeholder:text-[#212529] w-8/12 px-4 py-2 text-13 leading-3  dark:bg-[#41464E]  border border-[#CED4DA] dark:border-[#fff] rounded mt-2 text-[#212529] dark:text-white sm:h-[38px]"
                type="text"
                id={input.name}
                name={formik[input.name]}
                value={formik.values[input.name]}
                onChange={formik.handleChange}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
export default GoogleAnalytics;