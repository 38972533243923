import { FC, useEffect, useState } from 'react';
import { PublishActionData, PublishEventActionData } from '../index.type';
import { useFormik, FormikValues } from 'formik';
import * as Yup from 'yup';
import PublishAttributes from '../PublishAttributes';
import ModalWrapper from 'components/Workflow/ModalWrapper';
import React from 'react';

interface Props {
  data: PublishActionData | undefined;
  onClose: () => void;
  onSave: (data: PublishActionData) => void;
}

const PublishAction: FC<Props> = ({ data, onClose, onSave }) => {
  const [isFormValid, setIsFormValid] = useState<boolean>(true);
  const [attributes, setAttributes] = useState<Array<PublishEventActionData>>(
    []
  );
  const [showNameError, setShowNameError] = useState<boolean>(false);

  const formik: FormikValues = useFormik({
    enableReinitialize: true,
    initialValues: {
      eventName: '',
    },
    validationSchema: Yup.object({
      eventName: Yup.string().required('Required'),
    }),
    onSubmit: (values) => {
      let valid = true;

      attributes.map((attribute) => {
        if (attribute.name === '' || attribute.value === '') {
          valid = false;
        }
      });

      setIsFormValid(valid);

      if (valid) {
        onSave({
          ...values,
          _type: 'PublishEventAction',
          attributes: attributes,
        });
        onClose();
      }
    },
  });

  useEffect(() => {
    if (formik.values.eventName !== '') {
      setShowNameError(false);
      setIsFormValid(true);
    }
  }, [formik.values.eventName, formik.isValid]);

  useEffect(() => {
    if (data) {
      formik.setValues({ eventName: data.eventName });
      setAttributes(data.attributes);
    }
  }, []);

  return (
    <form onSubmit={formik.handleSubmit}>
      <ModalWrapper
        onClose={onClose}
        disabled={!isFormValid}
        onSave={() => {
          if (!formik.dirty) {
            setShowNameError(true);
          }
          if (formik.values.eventName === '') {
            let valid = true;

            attributes.map((attribute) => {
              if (attribute.name === '' || attribute.value === '') {
                valid = false;
              }
            });
            setIsFormValid(valid);
          }
        }}
      >
        <div className="bg-white dark:bg-[#41464E]">
          <h2 className="sm:text-xl text-base sm:leading-5 leading-3 text-black-700 font-medium mb-4 dark:text-white">
            Action
          </h2>
          <div>
            <label className="text-xs leading-3 block tracking-wide text-[#495057] uppercase mb-1 dark:text-white">
              Event Name
            </label>
            <input
              className="text-13 font-medium leading-3 text-[#495057] dark:text-[#CED4DA]  w-full py-3 px-4 rounded-md border border-gray-800 focus:outline-none focus:border-primary sm:h-[38px] h-9 sm:w-7/12   dark:bg-[#41464E]"
              name="eventName"
              type="text"
              value={formik.values.eventName}
              onChange={formik.handleChange}
            />
            {showNameError && (
              <div className="px-6 py-3 bg-[#f7e8e7] rounded mt-4">
                <p className="text-sm font-normal leading-4 text-[#CB5A5A]">
                  Enter an event to continue
                </p>
              </div>
            )}
          </div>
          {attributes.length === 0 ? (
            <button
              type="button"
              className="flex items-center mt-4 text-13 font-medium leading-5 text-white rounded-md bg-primary px-4 py-2.5 h-11 mr-7 ease-in duration-300 hover:bg-primary hover:scale-105"
              onClick={() => {
                setAttributes([{ name: '', value: '' }]);
              }}
            >
              Add Attributes
            </button>
          ) : (
            <>
              <h2 className="dark:text-white sm:text-xl text-base sm:leading-5 leading-3 text-black-700 font-medium mb-4 mt-8">
                Attributes
              </h2>
              <PublishAttributes
                attributes={attributes}
                setAttributes={(attributes) => setAttributes(attributes)}
                invalidForm={!isFormValid}
                setIsFormValid={(valid) => setIsFormValid(valid)}
              />
              <p className="text-13 font-medium leading-4 text-[#495057] dark:text-[#CED4DA] mt-6">
                You may use subscriber or event attribute merge values e.g.
                &#123;&#123;event.attributes.url&#125;&#125;
              </p>
            </>
          )}
        </div>
      </ModalWrapper>
    </form>
  );
};
export default PublishAction;
