import React, { useMemo, useState, useEffect, useContext } from 'react';
import { getExecutionLogs } from 'services/apiHandlers/Workflows/Workflow';
import { TableColumn } from 'react-data-table-component';
import { Link, useParams } from 'react-router-dom';
import Table from 'components/Table';
import { z } from 'zod';
import moment from 'moment';
import { edit, subscribersProfile } from 'services/constant/routes';
import { UserContext } from 'store/UserContext';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import Wrapper from 'components/Wrapper';

const logsSchema = z.object({
  accountId: z.number(),
  executionId: z.string(),
  id: z.string(),
  message: z.string(),
  personIdentifier: z.string(),
  triggerId: z.string(),
  workflowNodeId: z.string(),
});
const executionDataSchema = z.object({
  logs: z.array(logsSchema),
  workflow: z.string(),
});
const executionResponseSchema = z.object({
  data: executionDataSchema,
  status: z.number(),
});

type ExecutionDataResponse = z.infer<typeof executionResponseSchema>;
export type ExecutionData = z.infer<typeof executionDataSchema>;
export type ExecutionLog = z.infer<typeof logsSchema>;

const formatDate = (id: string) => {
  const parts = id.split('-');
  const time =
    parseInt(parts[2].substring(1) + parts[1] + parts[0], 16) -
    122192928000000000;
  const date = moment(Math.floor(time / 10000));

  const formattedDate = date.format('MMM DD, YYYY hh:mm:ss A');
  return formattedDate;
};

const ExecutionLogs = () => {
  const { id, idNested } = useParams();
  const [logsLoading, setLogsLoading] = useState<boolean>(true);
  const [executionLogs, setExecutionLogs] = useState<Array<ExecutionLog>>();
  const [filteredLogs, setFilteredLogs] = useState<Array<ExecutionLog>>([]);
  const userCtx = useContext(UserContext);

  const getExecutionLogsHandler = async (id: string, logId: string) => {
    setLogsLoading(true);
    try {
      const executionLogResponse = (await getExecutionLogs(
        id,
        logId
      )) as ExecutionDataResponse;
      if (executionLogResponse.status === 200) {
        setExecutionLogs(executionLogResponse.data.logs);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
  };

  const columns: TableColumn<ExecutionLog>[] = useMemo(
    () => [
      {
        name: <div className='px-6'>Timestamp</div>,
        cell: (row) => {
          return (
            <div className="dark:text-white cursor-pointer px-6 text-[#495057] ">
              <div>{formatDate(row.id)}</div>
            </div>
          );
        },
        sortable: true,
      },
      {
        name: 'Subscriber',
        cell: (row) => {
          return (
            <Link
              to={`/${subscribersProfile}/${edit}/${row.personIdentifier}`}
              className="text-primary underline dark:text-white"
            >
              <div>{row.personIdentifier}</div>
            </Link>
          );
        },
      },
      {
        name: 'Message',
        cell: (row) => {
          return <Tippy interactive={true}
            content={<div>{row.message}</div>}>
            <div className='dark:text-white max-w-[300px] truncate'>{row.message}</div>
          </Tippy>;
        },
      },
    ],
    []
  );
  useEffect(() => {
    getExecutionLogsHandler(id ? id : '', idNested ? idNested : '');
  }, []);

  useEffect(() => {
    const searchInterval = setTimeout(() => {
      if (userCtx?.filteredValue) {
        const tempLogs = executionLogs?.filter((log) =>
          log.personIdentifier
            .toLowerCase()
            .includes(userCtx.filteredValue.toLowerCase())
        );
        setFilteredLogs(tempLogs || []);

      } else {
        setFilteredLogs(executionLogs || []);
      }
      setLogsLoading(false);
    }, 1000);

    return () => {
      clearInterval(searchInterval);
    };
  }, [userCtx?.filteredValue, executionLogs]);

  return (
    <div className='md:px-6 px-3 py-5 font-inter  h-full min-h-[76vh]'>
      <Wrapper>
          <Table
            className="scrollbar-hide"
            data={filteredLogs}
            columns={columns}
            pagination={false}
            progressPending={logsLoading}
          />
       </Wrapper>
    </div>
  );
};
export default ExecutionLogs;
