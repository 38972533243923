import { useFormik } from 'formik';
import React, { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from 'services/Authentication';
import { home } from 'services/constant/routes';
import * as Yup from 'yup';
import { Toastify } from 'App';
import { z } from 'zod';
import Eye from 'assets/Images/Login/eye-password.svg';
import Loader from 'components/Loader';
import EyeCross from 'assets/Images/Login/eye-text.svg';

export const LoginResponseSchema = z.object({
  success: z.boolean(),
  message: z.string(),
  data: z.object({ success: z.boolean(), message: z.string() }),
});
export type LoginResponse = z.infer<typeof LoginResponseSchema>;

interface Props {
  hideButton?: boolean;
  submitted?: boolean;
  setSubmit?: (submit: boolean) => void;
  onClose?: () => void;
  setShowError?: (error: boolean) => void;
}

const LoginForm: FC<Props> = ({
  hideButton,
  submitted,
  setSubmit,
  onClose,
  setShowError
}) => {
  const [responseError, setResponseError] = useState('');
  const [loading, setLoading] = useState<boolean>(false);
  const [passwordField, setPasswordField] = useState<boolean>(true);
  const navigate = useNavigate();
  
  const formik = useFormik({
    initialValues: {
      username: '',
      password: '',
      check: [],
    },
    validationSchema: Yup.object({
      password: Yup.string().required('Required'),
      username: Yup.string().email('Invalid email').required('Email Required'),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      try {
        const loginResponse = (await login(
          values.username,
          values.password,
          values.check.length > 0 ? true : false
        )) as LoginResponse;
       
        if (loginResponse.data.success) {
          setResponseError('');
          if (!hideButton && !setShowError) {
            navigate(home);
          } 
          if (setShowError) {
            setShowError(false)
          }
          onClose && onClose();
        } else {
          Toastify('Wrong Credentials', 'error', 'login1');
        }
      } catch (error) {
        Toastify('Login Failed', 'error', 'login1');
      }
      setLoading(false);
    },
  });

  useEffect(() => {
    if (submitted) {
      formik.handleSubmit();
    }
  }, [submitted]);

  useEffect(() => {
    setSubmit && setSubmit(false);
  }, [formik.errors, submitted]);

  return (
    <div className="mt-6 px-1.5 py-2">
      <form onSubmit={formik.handleSubmit}>
        <div className="mb-4 text-[0.85rem]">
          <label className="text-[#212529] font-medium" id="username">
            Username
          </label>
          <input
            id="username"
            name="username"
            type="text"
            className="border border-solid mt-2 border-[#CED4DA] py-2 px-[0.9rem] text-[#212529] rounded bg-[#E8F0FE] text-[0.85rem] w-full max-w-[24.063rem]"
            placeholder="Enter Username"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.username}
          />
          {formik.touched.username && formik.errors.username ? (
            <div className="text-xs leading-4 text-red-400 mt-1.5">
              {formik.errors.username}
            </div>
          ) : null}
        </div>
        <div className="mb-4 relative text-[0.85rem]">
          {/* <div className="float-right text-[#878A99]">
            <a href="#/">Forgot password?</a>
          </div> */}
          <label
            className="text-[0.85rem] text-[#212529] font-medium"
            id="userName"
          >
            Password
          </label>
          <div className="relative">
            <input
              id="password"
              name="password"
              type={passwordField ? 'password' : 'text'}
              className={`${formik.errors.password ? 'border-red-300' : ''
                } relative border border-solid mt-2 border-[#CED4DA] py-2 px-[0.9rem] text-[#212529] rounded bg-[#E8F0FE] text-[0.85rem] w-full max-w-[24.063rem]`}
              placeholder="Enter password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
            />
            {!passwordField ? (
              <button
                type="button"
                className="absolute right-0 top-2 p-3"
                onClick={() => setPasswordField((prev) => !prev)}
              >
                <img
                  src={EyeCross}
                  alt="eye-password"
                  className="w-[14px]"
                />
              </button>
            ) : (
              <button
                type="button"
                className="absolute right-0 top-2 p-3"
                onClick={() => setPasswordField((prev) => !prev)}
              >
                <img
                  src={Eye}
                  alt="eye-text"
                  className="w-[14px]"
                />
              </button>
            )}

            {formik.touched.password && formik.errors.password ? (
              <div className="text-xs leading-4 text-red-400 mt-1.5">
                {formik.errors.password}
              </div>
            ) : null}
            {responseError.length > 0 && (
              <div className="text-sm leading-4 text-red-400 mt-1.5">
                {responseError}
              </div>
            )}
          </div>
        </div>
        <div className="flex items-center mb-0.5" >
          <input
            type="checkbox"
            id="remember-me"
            name="check"
            onChange={formik.handleChange}
            value="check"
            className="cursor-pointer"
          />
          <label htmlFor="remember-me" className="text-[0.85rem] text-[#212529] font-medium cursor-pointer pl-2">
            Remember me
          </label>
        </div>
        {!hideButton &&
          <button
            type="submit"
            className={`mt-6 bg-primary w-full text-[0.85rem] text-white py-2 px-[0.9rem] text-center rounded`}
            disabled={loading}
          >
            {loading ? <Loader/> : 'Sign In'}
          </button>}
      </form>
    </div>
  );
};
export default LoginForm;
