import { TableColumn } from 'react-data-table-component';
import Table from 'components/Table';
import { useContext, useEffect, useMemo, useState } from 'react';
import ErrorBoundary from 'components/ErrorBoundary';
import { z } from 'zod';
import {
  getAllBlasts as getBlastByStatus,
  getBlastMonetaryValue,
} from 'services/apiHandlers/Dashboard/Analytics';
import { getAllBlasts } from 'services/apiHandlers/Campaigns/CampaignInformation';
import { BlastSchema } from 'components/DashboardReports/Types';
import { calculatePercentage, calculateRate } from 'utils/common';
import moment from 'moment';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { campaignReport } from 'services/constant/routes';
import { UserContext } from 'store/UserContext';
import Dropdown from 'components/Dropdown/Dropdown';
import SortByDropdown from 'components/Dropdown/SortByDropdown';
import { BlastData } from 'components/Dashboard/index.type';
import Wrapper from 'components/Wrapper';

const BlastMonetaryValueResponseData = z.object({
  blastId: z.number(),
  conversions: z.number(),
  monetaryValue: z.number(),
});

const getBlastMonetaryValueResponseSchema = z.object({
  data: z.array(BlastMonetaryValueResponseData),
  status: z.number(),
});

const campaignsSchema = z.object({
  collection: z.union([z.array(BlastSchema), z.tuple([])]),
  current_page: z.number(),
  per_page: z.number(),
  total_count: z.number(),
});

const getFilterCampaignsSchema = z.object({
  data: campaignsSchema,
  status: z.number(),
});

const BlastResponseSchema = z.object({
  data: z.object({
    collection: z.union([z.array(BlastSchema), z.tuple([])]),
    total_count: z.number(),
  }),
  status: z.number(),
});

const PageDataSchema = z.object({
  page: z.number(),
  per_page: z.number(),
});

type Blasts = z.infer<typeof BlastSchema>;
export type GetBlastMonetaryValueResponse = z.infer<
  typeof getBlastMonetaryValueResponseSchema
>;
export type BlastMonetaryData = z.infer<typeof BlastMonetaryValueResponseData>;
type GetFilterCampaigns = z.infer<typeof getFilterCampaignsSchema>;
type BlastResponse = z.infer<typeof BlastResponseSchema>;
type PageData = z.infer<typeof PageDataSchema>;

interface Status {
  [key: string]: string;
  Sent: string;
  Sending: string;
  Scheduled: string;
  Preparing: string;
}
interface Props {
  isReports?: boolean;
}

const campaignStatus: Status = {
  Sent: 'sent',
  Sending: 'sending',
  Scheduled: 'waiting',
  Preparing: 'preparing',
};

// interface FilterValue {
//   [key: string]: string;
//   Recepients: string;
// }

// const selectedFilterValue: FilterValue = {
//   Recepients: 'intended_recipients',
// };

const AllCampaigns: FC<Props> = ({ isReports }) => {
  const [allCampaigns, setAllCampaigns] = useState<Array<Blasts>>([]);
  const [filteredCampaigns, setFilteredCampaigns] = useState<Array<Blasts>>(allCampaigns);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedFilter, setSelectedFilter] = useState<string>('Sent');
  const [selectedCampaignFilter, setSelectedCampaignFilter] = useState<string>('Select Filter');
  const [blastMonetaryValues, setBlastMonetaryValues] = useState<Array<BlastData>>([]);
  const [totalCampaigns, setTotalCampaigns] = useState<number>(0);
  const [filterOrder, setFilterOrder] = useState<string>('Desc');

  const userCtx = useContext(UserContext);



  const getData = async (paginationData: PageData) => {
    setLoading(true);
    try {
      const getBlastStatusResponse = (await getAllBlasts(
        paginationData
      )) as BlastResponse;

      if (getBlastStatusResponse.status === 200) {

        const filteredCampaigns =
          getBlastStatusResponse.data.collection?.filter(
            (campaign) => campaign.blast.status === 'sent'
          );
        const filteredIds = filteredCampaigns?.map((data) => {
          return data.blast.id;
        });

         if(filteredIds.length>0){ 
        const getBlastMonetaryValuesResponse = (await getBlastMonetaryValue(
          filteredIds?.join(',')
        )) as GetBlastMonetaryValueResponse;
        

        if (getBlastMonetaryValuesResponse.status === 200) {
          setBlastMonetaryValues(getBlastMonetaryValuesResponse.data);
        }
      }
        setAllCampaigns(getBlastStatusResponse.data.collection);
        setFilteredCampaigns(getBlastStatusResponse.data.collection);
        setTotalCampaigns(getBlastStatusResponse.data.total_count);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
    setLoading(false);
  };
  const getFilteredCampaigns = async (filter: string, pageData: PageData) => {
    setLoading(true);
    try {
      const getFilteredResponse = (await getBlastByStatus(filter, {
        page: pageData.page,
        per_page: pageData.per_page,
      })) as GetFilterCampaigns;
      if (getFilteredResponse.status === 200) {
        if (filter === 'sent') {
          const filteredCampaigns = getFilteredResponse.data.collection?.filter(
            (campaign) => campaign.blast.status === 'sent'
          );
          const filteredIds = filteredCampaigns?.map((data) => {
            return data.blast.id;
          });

          

          if(filteredIds.length>0){
          const getBlastMonetaryValuesResponse = (await getBlastMonetaryValue(
            filteredIds?.join(',')
          )) as GetBlastMonetaryValueResponse;


        

          if (getBlastMonetaryValuesResponse.status === 200) {
            setBlastMonetaryValues(getBlastMonetaryValuesResponse.data);
          }
        }
      }

        setAllCampaigns(getFilteredResponse.data.collection);
        setFilteredCampaigns(getFilteredResponse.data.collection);
        setTotalCampaigns(getFilteredResponse.data.total_count);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
    setLoading(false);
  };


  const checkSentConvertValue = (id: number, status: string) => {
    if (status === 'sent') {
      const filteredId = blastMonetaryValues.filter(
        (blast) => blast.blastId === id
      );
      return {
        orders: filteredId[0] ? filteredId[0]?.conversions : 0,
        revenue: filteredId[0] ? filteredId[0]?.monetaryValue : 0,
        AOV: filteredId[0]
          ? calculateRate(filteredId[0]?.monetaryValue, filteredId[0]?.conversions)
          : 0,

        // AOV: filteredId[0]
        // ? ( filteredId[0]?.monetaryValue / filteredId[0]?.conversions ).toFixed(2)
        // : 0,
      };
    } else {
      return { orders: 0, revenue: 0, AOV: 0 };
    }
  };

  const campaignSort = (rowA: Blasts, rowB: Blasts) => {
    const a = rowA.blast.subject.toLowerCase();
    const b = rowB.blast.subject.toLowerCase();

    if (a > b) {
      return 1;
    }

    if (b > a) {
      return -1;
    }

    return 0;
  };
  const handlePageChange = (page: number) => {
    if (selectedFilter !== 'All') {
      getFilteredCampaigns(campaignStatus[selectedFilter], {
        page: page,
        per_page: 15,
      });
    } else {
      getData({ page: page, per_page: 15 });
    }
  };
  const columns: TableColumn<Blasts>[] = useMemo(
    () => [
      {
        name: <div className="px-2">ID</div>,
        cell: (row) => {
          const { blast: { id = 0 } } = row || {};
          return (
            <Link
              to={`${campaignReport}/${id}`}
              className="whitespace-break-spaces align-top px-2 text-13 font-medium line-clamp-1 truncate dark:text-white cursor-pointer"
            >
              {id}
            </Link>
          );
        },
        width: '90px',
        // center: true,
      },

      {
        name: 'Subject',
        cell: (row) => {
          const { blast: { id = 0, subject = '', scheduled_for = '' } } = row || {};

          return (
            <div>
              <Link
                to={`${campaignReport}/${id}`}
                className="whitespace-break-spaces text-[#495057] font-medium line-clamp-1 truncate dark:text-white align-top cursor-pointer"
              >
                {subject}
              </Link>
              <div className="dark:text-white">
                {moment
                  .tz(
                    scheduled_for,
                    userCtx?.usersData?.timeZone || ''
                  )
                  .format('MMM D, YYYY h:mm A')}
              </div>
            </div>
          );
        },
        sortable: true,
        // center: true,
        sortFunction: campaignSort,
        minWidth: '250px',
      },
      {
        name: 'Status',
        cell: (row) => {
          const { blast: { status = '' } } = row || {};
          return (
            <td className="whitespace-break-spaces py-3 text-center dark:text-white align-top table-cell w-full">
              <span
                className={`${status === 'sending'
                  ? 'text-[#ea7104] bg-[#f8eade]'
                  : status === 'waiting'
                    ? 'text-primary bg-[#eaeef4]'
                    : 'text-green-600 bg-green-100'
                  } text-13 capitalize rounded py-0.5 px-2.5 `}
              >
                {status}
              </span>
            </td>
          );
        },
        width: '110px',
        center: true,
      },
      {
        name: 'Orders',
        cell: (row) => {
          const { blast: { id = 0, status = '' } } = row || {};
          return (
            <td className="whitespace-break-spaces  dark:text-white align-top text-center table-cell w-full">
              {checkSentConvertValue(id, status).orders.toLocaleString()}
            </td>
          );
        },
        width: isReports ? ' 80px' : '110px',
        center: true,
      },
      {
        name: 'Revenue',
        cell: (row) => {
          const { blast: { id = 0, status = '' } } = row || {};
          return (
            <td className="whitespace-break-spaces  dark:text-white align-top text-center table-cell w-full">
              ${(Math.round(checkSentConvertValue(id, status).revenue)).toLocaleString()}
            </td>
          );
        },
        width: '110px',
        center: true,
      },
      {
        name: 'AOV',
        cell: (row) => {
          const { blast: { id = 0, status = '' } } = row || {};
          return (
            <td className="whitespace-break-spaces  dark:text-white align-top text-center table-cell w-full">
              ${(Math.round(checkSentConvertValue(id, status).AOV)).toLocaleString()}
            </td>
          );
        },
        width: isReports ? ' 85px' : '110px',
        center: true,
      },
      {
        name: 'Recipients',
        cell: (row) => {
          const { blast: { intended_recipients = 0 } } = row || {};
          return (
            <td className="whitespace-break-spaces  dark:text-white align-top text-center table-cell w-full">
              {intended_recipients?.toLocaleString()}
            </td>
          );
        },
        width: isReports ? ' 90px' : '110px',
        center: true,
      },
      {
        name: 'Delivered',
        cell: (row) => {
          const { blast: { number_sent = 0, intended_recipients = 0, soft_bounces_count = 0, hard_bounces_count = 0 } } = row || {}
          const delivered = number_sent - (soft_bounces_count + hard_bounces_count);
          return (
            <td className="whitespace-break-spaces dark:text-white align-top text-center table-cell w-full">
              <div>{delivered.toLocaleString() || '-'}</div>
              <div className=" dark:text-white">
                {delivered
                  ? calculatePercentage(
                    delivered,
                    intended_recipients
                  ) + '%'
                  : '-'}
              </div>
            </td>
          );
        },
        width: isReports ? ' 90px' : '110px',
        center: true,
      },
      {
        name: 'Bounced',
        cell: (row) => {
          const { blast: { number_sent = 0, soft_bounces_count = 0, hard_bounces_count = 0 } } = row || {}
          return (
            <td className="whitespace-break-spaces dark:text-white text-center align-top table-cell w-full">
              <div>
                {(soft_bounces_count + hard_bounces_count).toLocaleString() ||
                  '-'}
              </div>
              <div className=" dark:text-white">
                {soft_bounces_count || hard_bounces_count
                  ? calculatePercentage(
                    soft_bounces_count +
                    hard_bounces_count,
                    number_sent
                  ) + '%'
                  : '-'}
              </div>
            </td>
          );
        },
        width: isReports ? ' 90px' : '110px',
        center: true,
      },
      {
        name: 'Unique Viewers',
        cell: (row) => {
          const { blast: { number_sent = 0, soft_bounces_count = 0, hard_bounces_count = 0, unique_views_count = 0 } } = row || {}
          const delivered = number_sent - (soft_bounces_count + hard_bounces_count);
          return (
            <td className="whitespace-break-spaces dark:text-white align-top text-center table-cell w-full">
              <div>{unique_views_count.toLocaleString()}</div>
              <div className=" dark:text-white">
                {delivered
                  ? calculatePercentage(
                    unique_views_count,
                    delivered
                  ) + '%'
                  : '-'}
              </div>
            </td>
          );
        },
        width: '120px',
        center: true,
      },
      {
        name: 'Unique Clickers',
        cell: (row) => {
          const { blast: { number_sent = 0, soft_bounces_count = 0, hard_bounces_count = 0, unique_clicks_count = 0 } } = row || {}
          const delivered = number_sent - (soft_bounces_count + hard_bounces_count);
          return (
            <td className="whitespace-break-spaces sm:align-top align-middle text-center dark:text-white table-cell w-full">
              <div>{unique_clicks_count.toLocaleString()}</div>
              <div className=" dark:text-white">
                {delivered
                  ? calculatePercentage(
                    unique_clicks_count,
                    delivered
                  ) + '%'
                  : '-'}
              </div>
            </td>
          );
        },
        width: '120px',
        center: true,
      },
      {
        name: 'Unsubscribes',
        cell: (row) => {
          const { blast: { number_sent = 0, soft_bounces_count = 0, hard_bounces_count = 0, complaints_count = 0, unsubscribes_count = 0 } } = row || {}
          const delivered = number_sent - (soft_bounces_count + hard_bounces_count);
          return (
            <td className="whitespace-break-spaces sm:align-top align-middle text-center dark:text-white table-cell w-full">
              <div>
                {(unsubscribes_count + complaints_count).toLocaleString()}
              </div>
              <div className="">
                {delivered
                  ? calculatePercentage(
                    unsubscribes_count,
                    delivered
                  ) + '%'
                  : '_'}
              </div>
            </td>
          );
        },
        center: true,
        width: '110px',
      },
      {
        name: 'Actions',
        cell: (row) => {
          const { blast: { id = 0 } } = row || {}
          return (
            <Link
              to={`${campaignReport}/${id}`}
              className="whitespace-break-spaces sm:align-top align-middle py-3 text-center text-primary font-medium dark:text-white table-cell w-full cursor-pointer"
            >
              <div className="mr-4 flex justify-between items-center">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 23 23"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-4 h-4 relative left-1.5"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>

                <span>View Details</span>
              </div>
            </Link>
          );
        },
        center: true,
        width: '130px',
      },
    ],
    [filteredCampaigns]
  );

  const onFilterSelect = (filter: string) => {
    if (filter === 'All') {
      getData({ page: 1, per_page: 15 });
    } else {
      getFilteredCampaigns(campaignStatus[filter], {
        page: 1,
        per_page: 15,
      });
    }
    setSelectedFilter(filter);
  };

  const onSelectCampaignFilter = (filter: string) => {
    if (filter === 'Orders') {
      if(selectedCampaignFilter === 'Orders' && filterOrder === 'Desc'){
        const tempFilteredCampaigns = [...filteredCampaigns].sort(
          (a, b) =>
            checkSentConvertValue(a.blast.id, b.blast.status).orders -
            checkSentConvertValue(b.blast.id, a.blast.status).orders
        );
        setFilteredCampaigns(tempFilteredCampaigns);
        setFilterOrder('Asc')
      } else {
        const tempFilteredCampaigns = [...filteredCampaigns].sort(
          (a, b) =>
            checkSentConvertValue(b.blast.id, a.blast.status).orders -
            checkSentConvertValue(a.blast.id, b.blast.status).orders
        );
        setFilteredCampaigns(tempFilteredCampaigns);
        setFilterOrder('Desc')
      }

    } else if (filter === 'Revenue') {
      if(selectedCampaignFilter === 'Revenue' && filterOrder === 'Desc'){
        const tempFilteredCampaigns = [...filteredCampaigns].sort(
          (a, b) =>
            checkSentConvertValue(a.blast.id, b.blast.status).revenue -
            checkSentConvertValue(b.blast.id, a.blast.status).revenue
        );
        setFilteredCampaigns(tempFilteredCampaigns);
        setFilterOrder('Asc')
      } else {
        const tempFilteredCampaigns = [...filteredCampaigns].sort(
          (a, b) =>
            checkSentConvertValue(b.blast.id, a.blast.status).revenue -
            checkSentConvertValue(a.blast.id, b.blast.status).revenue
        );
        setFilteredCampaigns(tempFilteredCampaigns);
        setFilterOrder('Desc')
      }

    } else if (filter === 'AOV'){
      if(selectedCampaignFilter === 'AOV' && filterOrder === 'Desc'){
        const tempFilteredCampaigns = [...filteredCampaigns].sort(
          (a, b) =>
            checkSentConvertValue(a.blast.id, b.blast.status).AOV -
            checkSentConvertValue(b.blast.id, a.blast.status).AOV
        );
        setFilteredCampaigns(tempFilteredCampaigns);
        setFilterOrder('Asc')
      } else {
        const tempFilteredCampaigns = [...filteredCampaigns].sort(
          (a, b) =>
            checkSentConvertValue(b.blast.id, a.blast.status).AOV -
            checkSentConvertValue(a.blast.id, b.blast.status).AOV
        );
        setFilteredCampaigns(tempFilteredCampaigns);
        setFilterOrder('Desc')
      }
    }
    //  else {
    //   const tempFilteredCampaigns = [...filteredCampaigns].sort(
    //     (a, b) =>
    //       // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //       // @ts-ignore
    //       b.blast[selectedFilterValue[filter] as keyof Blasts] -
    //       // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //       // @ts-ignore
    //       a.blast[selectedFilterValue[filter] as keyof Blasts]
    //   );

    //   setFilteredCampaigns(tempFilteredCampaigns);
    // }
    setSelectedCampaignFilter(filter);
  };


  useEffect(() => {
    getFilteredCampaigns(campaignStatus[selectedFilter], {
      page: 1,
      per_page: 15,
    });
  }, []);

  useEffect(() => {
    const checkFilterChange = setTimeout(() => {
      if (userCtx?.filteredValue && userCtx?.filteredValue.length > 0) {
        const tempCampaigns = allCampaigns?.filter((campaign) =>
          campaign.blast.subject
            .toLowerCase()
            .includes(userCtx.filteredValue.toLowerCase())
        );
        setFilteredCampaigns(tempCampaigns);
      } else {
        setFilteredCampaigns(allCampaigns);
      }
    }, 1000);
    return () => clearTimeout(checkFilterChange);
  }, [userCtx?.filteredValue, allCampaigns]);

  
  return (
    <ErrorBoundary>
      <Wrapper>
        <>
          <div className="flex justify-between items-center px-5 border-b dark:border-[#fff] border-[#e9ebec] py-[18px] dark:shadow-none dark:bg-[#41464E]">
            <p className="text-base text-[#495057] font-medium  dark:text-[#CED4DA] ">
              Blasts Campaigns
            </p>
            <div className="flex ">
              <div className="flex items-center cursor-pointer">
                <div className="flex items-center min-w-[200px]">
                  <p className="text-xs font-semibold text-[#212529] dark:text-[#CED4DA] mr-2">
                    Status:
                  </p>
                  <Dropdown
                    options={['All', 'Sent', 'Sending', 'Scheduled', 'Preparing']}
                    value={selectedFilter}
                    onSelect={onFilterSelect}
                  />
                </div>
                <div className="w-[154px]">
                  <SortByDropdown
                    options={['Orders', 'Revenue', 'AOV']}
                    value={selectedCampaignFilter}
                    onSelect={onSelectCampaignFilter}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-4 font-inter relative dark:bg-[#41464E]">
            <Table
              className="scrollbar-hide"
              data={filteredCampaigns}
              columns={columns}
              progressPending={loading}
              paginationServer
              paginationTotalRows={totalCampaigns}
              paginationPerPage={15}
              onChangePage={handlePageChange}
            // customStyles={customStyles}
            />
          </div>
        </>
      </Wrapper>
    </ErrorBoundary>
  );
};
export default AllCampaigns;
