import { FC, useContext, useEffect, useMemo, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import Table from 'components/Table';
import view from 'assets/Images/Dashboard/view.png';
import { calculatePercentage } from 'utils/common';
import { SingleWorkflow, Workflows } from './Types';
import moment from 'moment';
import React from 'react';
import { getCsvExport } from 'services/apiHandlers/Dashboard/Reports';
import { Link } from 'react-router-dom';
import { workflowReport } from 'services/constant/routes';
import { UserContext } from 'store/UserContext';
import Dropdown from 'components/Dropdown/valueDropdown';
const options = [
  { name: 'Conversion', value: 'uniquePeople' },
  { name: 'Recepients', value: 'uniqueConversions' },
];
interface Props {
  workflows: Workflows
  loading: boolean
}
const AllWorkflows: FC<Props> = ({workflows,loading}) => {
  const [tableData, setTableData] = useState<Workflows | undefined>(
    workflows
  );
  const userCtx = useContext(UserContext);
  const [selectedFilter, setSelectedFilter] =
    useState<string>('Select a Filter');
  const columns: TableColumn<SingleWorkflow>[] = useMemo(
    () => [
      {
        name: <div className='px-6'>Workflow</div>,
        cell: (row) => {
          return (
            <Link
              to={`${workflowReport}/${row.workflowId}`}
              className="flex items-center overflow-auto px-6 text-[#495057] font-medium min-w-[269px] w-[269px] py-2"
            >
              {row.workflowName}
            </Link>
          );
        },
      },
      {
        name: 'Recipients',
        cell: (row) => {
          return <p className="text-13 dark:text-white">{row.uniquePeople}</p>;
        },
        center: true,
      },
      {
        name: 'Converted',
        cell: (row) => {
          return (
            <div>
              <p className="text-13 dark:text-white inline-block">
                {row.uniqueConversions}
              </p>
              <p>
                {row.uniquePeople > 0
                  ? `${parseInt(
                      calculatePercentage(
                        row.uniqueConversions,
                        row.uniquePeople
                      ).toFixed(2)
                    )}%`
                  : '-'}
              </p>
            </div>
          );
        },
        center: true,
      },
      {
        name: 'Delivered',
        cell: (row) => {
          return (
            <div>
              <p className="text-13 dark:text-white">{row.deliveries}</p>
              <p>
                {row.deliveries > 0
                  ? `${Math.floor(
                      calculatePercentage(row.deliveries, row.sends)
                    )}%`
                  : '-'}
              </p>
            </div>
          );
        },
        center: true,
      },
      {
        name: 'Clicked',
        cell: (row) => {
          return (
            <div>
              <p className="text-13 dark:text-white py-2">{row.uniqueClicks}</p>
              <p>
                {row.uniquePeople > 0
                  ? `${parseInt(
                      calculatePercentage(
                        row.uniqueClicks,
                        row.uniquePeople
                      ).toFixed(2)
                    )}%`
                  : '-'}
              </p>
            </div>
          );
        },
        center: true,
      },
      {
        name: 'Unsubscribed',
        cell: (row) => {
          return (
            <p className="text-13 dark:text-white py-2">{row.unsubscribes}</p>
          );
        },
        center: true,
      },
      {
        name: 'Actions',
        cell: (row) => {
          return (
            <Link
              to={`${workflowReport}/${row.workflowId}`}
              className="flex items-center mr-4 group cursor-pointer"
            >
              <img
                src={view}
                alt="view"
                className="inline mr-1.5 dark:invert dark:brightness-0"
              />
              <span className="text-primary dark:text-white font-medium">View Details</span>
            </Link>
          );
        },
        center: true,
      },
    ],
    []
  );
  const onSelectFilter = (filter: string) => {
    if (tableData) {
      const tempFilteredWorkflows = [...tableData].sort((a, b) => {
        const aValue = a[filter as keyof SingleWorkflow];
        const bValue = b[filter as keyof SingleWorkflow];
        if (typeof aValue === 'number' && typeof bValue === 'number') {
          return aValue - bValue;
        }
        return 0;
      });
      setTableData(tempFilteredWorkflows);
    }
    setSelectedFilter(
      options.filter((option) => option.value === filter)[0].name
    );
  };
  const onExportClickHandler = async () => {
    const currentDate = moment().format('YYYY-MM-DD');
    const previousDate = moment().subtract(7, 'days').format('YYYY-MM-DD');
    try {
      getCsvExport(previousDate, currentDate);
    } catch (error) {
      console.log('error is : ', error);
    }
  };
  useEffect(() => {
    const searchInterval = setTimeout(() => {
      if (userCtx?.filteredValue) {
        const tempSegments = workflows.filter((workflow) =>
          workflow.workflowName
            .toLowerCase()
            .includes(userCtx.filteredValue.toLowerCase())
        );
        setTableData(tempSegments);
      } else {
        setTableData(workflows);
      }
    }, 1000);
    return () => {
      clearInterval(searchInterval);
    };
  }, [userCtx?.filteredValue,workflows]);
  return (
    <>
      <div className="flex justify-between items-center px-5 border-b dark:border-[#fff] border-[#e9ebec] py-[18px]">
        <p className="text-base text-[#495057] font-medium  dark:text-[#CED4DA]">All Workflows</p>
        <div className="flex">
          <div className="relative">
            <div className="flex items-center cursor-pointer">
              <p className="text-xs font-semibold text-[#212529] dark:text-[#CED4DA]">Sort By:</p>
              <Dropdown
                options={options}
                value={selectedFilter}
                onSelect={onSelectFilter}
              />
              <button
                onClick={onExportClickHandler}
                className="shadow-sm shadow-gray-200 dark:shadow-none rounded-md w-full relative ml-2"
              >
                Export
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="shadow-lg shadow-gray-200 dark:shadow-none  dark:bg-[#41464E]   py-3 rounded-lg mb-16 allWorkFlows">
        <Table
          className="rounded-t-2xl scrollbar-hide overflow-auto"
          data={tableData ? tableData : []}
          columns={columns}
          progressPending={loading}
        />
      </div>
    </>
  );
};

export default AllWorkflows;
