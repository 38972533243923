import {
  FC,
  useEffect,
  useState,
} from 'react';
import Edit from 'assets/Images/ListsegmentImages/edit.png';
import Duplicate from 'assets/Images/Workflow/copy.png';

import Delete from 'assets/Images/ListsegmentImages/delete.png';
import { sleep } from 'utils/common';
import DeleteModal from 'components/Alert/DeleteModal';
import ToggleSwitch from 'ToggleSwitch/ToggleSwitch';
import {
  deleteSegment,
  toggleStatus,
  getUpdatedSegment,
} from 'services/apiHandlers/List-Segments/Segment';
import { Link } from 'react-router-dom';
import { z } from 'zod';
import { Toastify } from 'App';
import React from 'react';
import { listSegments, segmentDefinition } from 'services/constant/routes';

const DisableSegmentSchema = z.object({
  segment_name: z.string(),
  status: z.string(),
});

const SegmentSchema = z.object({
  segment_json: z.string(),
  elastic_json: z.string(),
  segment_name: z.string(),
  subscriber_count: z.number(),
  status: z.string(),
});


const responseSchema = z.object({ status: z.number(), data: SegmentSchema });
type response = z.infer<typeof responseSchema>;

interface Props {
  row_name: string;
  status: string;
  getData: (toggled?: boolean) => void;
  index: number;
}

const statusResponseSchema = z.object({
  data: z.object({ status: z.string() }),
});
type statusResponse = z.infer<typeof statusResponseSchema>;

const SegmentAction: FC<Props> = ({
  row_name,
  index,
  status,
  getData,
}) => {
  const [segmentName, setSegmentName] = useState<string>('');
  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);

  const deleteHandler = async (name: string) => {
    const segmentData = {
      segment_name: name,
    };
    try {
      const deleteSegmentResponse = (await deleteSegment(
        segmentData
      )) as response;
      if (deleteSegmentResponse.status === 200) {
        Toastify('Segment Deleted Successfully', 'success', 'action1');
        getData(true);
      }
    } catch (error) {
      Toastify('Segment Deletion Failed', 'error', 'action2');
    }
  };

  const disableSegmentHandler = async (name: string, status: string) => {
    const segmentData = {
      segment_name: name,
      status: status,
    };
    const disableSegmentResponse = DisableSegmentSchema.safeParse(segmentData);
    if (disableSegmentResponse) {
      const response = (await toggleStatus(segmentData)) as response;
      if (response.status === 200) {
        getData(true);
      }
    }
  };

  const processingHandler = async (name: string) => {
    const changeStatusResponse = (await getUpdatedSegment(
      name
    )) as statusResponse;
    do {
      await sleep(1500);
      const updatedResponse = (await getUpdatedSegment(name)) as statusResponse;
      if (updatedResponse.data.status !== 'processing') {
        break;
      }
    } while (changeStatusResponse.data.status === 'processing');
    getData();
  };

  useEffect(() => {
    status === 'processing' && processingHandler(row_name);
  }, [status]);

  return (
    <>
      {status === 'processing' ? (
        <div className="animate-pulse">Processing ...</div>
      ) : (
        <>
          {status !== 'disabled' && (
            <>
              <Link
              to={`${listSegments}/${segmentDefinition}/${row_name}`}
                className="mr-4 mb-1.5 dark:text-white"
              >
                <img
                  src={Edit}
                  alt="Edit"
                  className="inline mr-1.5 dark:invert dark:brightness-0"
                />
                <span>Edit</span>
              </Link>
              <button
                data-pw={index === 0 ? 'delete-segment' : ''}
                onClick={() => {
                  setShowDeleteAlert(true);
                  setSegmentName(row_name);
                }}
                type="button"
                className="flex items-center mr-3 mb-1.5 dark:text-white"
              >
                <img
                  className="mr-2 dark:invert dark:brightness-0"
                  src={Delete}
                  alt="Delete"
                />
                <span>Delete</span>
              </button>
            </>
          )}
          <>
            <ToggleSwitch
              onChange={() => disableSegmentHandler(row_name, status)}
              checked={status === 'active'}
              id={index}
            />
            <label
              htmlFor={index?.toString()}
              className="flex items-center -ml-4 mr-3 my-1.5 dark:text-white cursor-pointer"
            >
              <span className="ml-2">
                {status === 'disabled' ? 'Enable' : 'Disable'}
              </span>
            </label>
            <Link
                to={`${listSegments}/${segmentDefinition}/${row_name}?duplicate=true`}
              className="mr-4 my-1.5 dark:text-white"
            >
              <img
                  src={Duplicate}
                  alt="Duplicate"
                  className="inline mr-1.5 h-[18px] w-[18px] dark:invert dark:brightness-0"
                />
              <span>Duplicate</span>
            </Link>
          </>
        </>
      )}
      <>
        {showDeleteAlert && (
          <div
            onClick={() => setShowDeleteAlert(false)}
            className="fixed left-0 top-0 w-full h-full z-10 bg-black-200 duration-300"
          >
            <DeleteModal
              onDelete={() => deleteHandler(segmentName)}
              onCancel={() => setShowDeleteAlert(false)}
            />
          </div>
        )}
      </>
    </>
  );
};
export default SegmentAction;
