import React, { FC } from 'react';

interface Props {
  activeTab: string;
  onTabClick: (tab: string) => void;
  tabs: string[]
}

const Tabs: FC<Props> = ({ activeTab, onTabClick,tabs }) => {
  return (
    <div className="">
      <div className="flex ">
        {tabs.map((tab) => (
          <button
            key={tab}
            className={` px-1.5 ml-2 text-darkText dark:text-[#CED4DA] ${
              activeTab === tab
                ? 'border-b-2 border-primary dark:border-[#CED4DA] '
                : ''
            }`}
            onClick={() => onTabClick(tab)}
          >
            {tab}
          </button>
        ))}
      </div>
    </div>
  );
};
export default Tabs;
