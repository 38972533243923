import { FC, useEffect, useState } from 'react';
import { z } from 'zod';
import React from 'react';
import Wrapper from 'components/Wrapper';
import moment from 'moment';


const EmailDataSchema = z.object({
  title: z.string(),
  currentMonthData: z.number(),
  previousMonthData: z.number(),
  performanceRate: z.union([z.number(), z.string()]),
  id: z.string(),
  image: z.string(),
});

const workflowEmailDataSchema = z.object({
  title: z.string(),
  filteredWorkflowData: z.number(),
  id: z.string(),
  image: z.string(),
});
type EmailData = z.infer<typeof EmailDataSchema>;
type workflowEmailData = z.infer<typeof workflowEmailDataSchema>;

interface Props {
  emailPerformance?: EmailData;
  loading: boolean;
  workflowEmailPerformance?: workflowEmailData; 
  startDate?: string;
  endDate?: string;
}

const EmailPerformance: FC<Props> = ({ emailPerformance, loading, workflowEmailPerformance, startDate, endDate }) => {
  const [emailData, setEmailData] = useState<EmailData>();
  const [workflowEmailData, setWorkFlowEmailData] = useState<workflowEmailData>();
 


  useEffect(() => {

    if(emailPerformance)
    {
      setEmailData(emailPerformance);
    }
    else {
      setWorkFlowEmailData(workflowEmailPerformance)
    }
    
  }, [emailPerformance, workflowEmailPerformance, loading]);

  return (
    <div>
      <Wrapper parentClass='!mt-0'>
      <>
      {emailPerformance && 
        <div key={emailData?.id} className="xl:h-[168px] h-[180px] flex flex-col justify-between xl:p-4 px-2 py-3 overflow-hidden">
          <div>
            <div>
              <div className=" flex items-center justify-between">
                <div className='flex items-center'>
                  <div className="h-8 w-8 xl:h-12 xl:w-12 p-2 flex items-center justify-center bg-[#3575d526] rounded-md">
                    <img src={emailData?.image} alt="No" />
                  </div>
                  <p className="tracking-wide text-secondary dark:text-[#CED4DA] text-sm leading-4 xl:px-2  px-2 xl:mt-0 mt-2 truncate">
                    {emailData?.title}
                  </p>
                </div>
                <p className='text-xs xl:mt-0 mt-3 max-w-[70px] text-white bg-[#3c763d]  ml-2 rounded px-1'>{moment().format("MMMM")}</p>
              </div>
            </div>
          </div>
          <div className="mt-6">

            <h3 className="font-semibold leading-7 text-3xl text-darkText dark:text-[#CED4DA]">
              {loading ? '-' : `${emailData?.currentMonthData}%`}
            </h3>


          </div>
          <div className="mt-4 mb-0 text-secondary text-xs flex xl:items-center xl:flex-row flex-col">
            <div
              className={`${emailData?.performanceRate && typeof (emailData?.performanceRate) === 'number' && emailData?.performanceRate > 0
                ? 'bg-success text-success'
                : 'bg-danger text-danger'
                } flex justify-center rounded-md px-2 py-1 inline-flex w-12 items-center text-xxs mb-0`}
            >
              {typeof (emailData?.performanceRate) === 'number' &&
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-3 h-3"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d={`${emailData?.performanceRate > 0
                      ? 'M8.25 6.75L12 3m0 0l3.75 3.75M12 3v18'
                      : 'M15.75 17.25L12 21m0 0l-3.75-3.75M12 21V3'
                      }`}
                  />
                </svg>}
              {loading ? '-' : ` ${typeof (emailData?.performanceRate) === 'number' ? Math.floor(emailData?.performanceRate)+'%' : emailData?.performanceRate }`}
            </div>

            <p className="ml-2 xl:mt-0 mt-1 font-medium">
              vs. {loading ? '-' : `${emailData?.previousMonthData}%`} of previous month
            </p>
          </div>
        </div> }
          {workflowEmailPerformance && 
          
          <div key={workflowEmailData?.id} className="xl:h-[168px] h-[180px] flex flex-col justify-between xl:p-4 px-2 py-3 hover:shadow-lg overflow-hidden">
          <div>
            <div>
              <div className=" flex items-center justify-between">
                <div className='flex items-center'>
                  <div className="h-8 w-8 xl:h-12 xl:w-12 p-2 flex items-center justify-center bg-[#3575d526] rounded-md">
                    <img src={workflowEmailData?.image} alt="No" />
                  </div>
                  <p className="tracking-wide text-secondary dark:text-[#CED4DA] text-sm leading-4 xl:px-2  px-2 xl:mt-0 mt-2 truncate">
                    {workflowEmailData?.title}
                  </p>
                </div>
                <p className='text-xs xl:mt-0 mt-3 max-w-[100px] text-white bg-[#3c763d]  ml-2 rounded px-1'>{moment(startDate).format("MMM DD")} - {moment(endDate).format('MMM DD')}</p>
              </div>
            </div>
          </div>
          <div className="mt-6">

            <h3 className="font-semibold leading-7 text-3xl text-darkText dark:text-[#CED4DA]">
              {loading ? '-' : `${workflowEmailData?.filteredWorkflowData}%`}
            </h3>


          </div>
        
        </div>
          
          
          
          }            
        



        </>
      </Wrapper>
    </div>
  );
};
export default EmailPerformance;
