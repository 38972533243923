import { z } from 'zod';

const addListSchema = z.object({
  listIds: z.array(z.number()),
  _type: z.string(),
});

const removeListSchema = z.object({
  listIds: z.array(z.number()),
  _type: z.string(),
});

const webhookSchema = z.object({
  url: z.string(),
  _type: z.string(),
});

const sendEmailSchema = z.object({
  emailProviderId: z.string(),
  subject: z.string(),
  emailId: z.number().nullable(),
  fromEmailName: z.string(),
  fromEmailAddress: z.string(),
  replyToEmailAddress: z.string(),
  ignoreOptStatus: z.boolean(),
  senderId: z.string().optional(),
  _type: z.string(),
});

const publishAttributeSchema = z.object({
  name: z.string().optional(),
  value: z.string(),
});

const publishEventSchema = z.object({
  _type: z.string(),
  eventName: z.string(),
  attributes: z.array(publishAttributeSchema),
});

const unsubscribeActionSchema = z.object({
  unsubscribeActionEnabled: z.boolean(),
  _type: z.string(),
});

const executeScriptSchema = z.object({
  workflowScriptId: z.string(),
  _type: z.string(),
});

const updateScoreSchema = z.object({
  scoreChange: z.string(),
  subscriberFieldName: z.string(),
  _type: z.string(),
});

const notifyEmailSchema = z.object({
  emailProviderId: z.string(),
  emailName: z.string(),
  emailAddress: z.string(),
  sendTo: z.string(),
  subject: z.string(),
  message: z.string(),
  _type: z.string(),
});

const timerActionSchema = z.object({
  length: z.number(),
  delayUnit: z.object({
    _type: z.string(),
  }),
  useDayOfWeek: z.boolean(),
  sunday: z.boolean(),
  monday: z.boolean(),
  tuesday: z.boolean(),
  wednesday: z.boolean(),
  thursday: z.boolean(),
  friday: z.boolean(),
  saturday: z.boolean(),
  useTimeOfDay: z.boolean(),
  hour: z.number(),
  minute: z.number(),
  ampm: z.string(),
  timezone: z.string(),
  _type: z.string(),
});

interface workflowActionSchema {
  id: string;
  action?:
    | z.infer<typeof addListSchema>
    | z.infer<typeof removeListSchema>
    | z.infer<typeof webhookSchema>
    | z.infer<typeof sendEmailSchema>
    | z.infer<typeof publishEventSchema>
    | z.infer<typeof unsubscribeActionSchema>
    | z.infer<typeof executeScriptSchema>
    | z.infer<typeof updateScoreSchema>
    | z.infer<typeof notifyEmailSchema>
    | z.infer<typeof timerActionSchema>;
  nextNode?: workflowActionSchema | workflowConditionSchema | undefined;
  _type: string;
}

const allActionSchema = z.union([
  addListSchema,
  removeListSchema,
  webhookSchema,
  sendEmailSchema,
  publishEventSchema,
  unsubscribeActionSchema,
  executeScriptSchema,
  updateScoreSchema,
  notifyEmailSchema,
  timerActionSchema,
]);

const eventConditionsSchema = z.object({
  attributeName: z.string(),
  value: z.string().optional(),
  values: z.array(z.string()).optional(),
  _type: z.string(),
});

const customConditionSchema = z.object({
  _type: z.string(),
  eventName: z.string(),
  timestampCondition: z.object({
    _type: z.string(),
    seconds: z.number().optional(),
    date: z.number().optional(),
    startDate: z.number().optional(),
    endDate: z.number().optional(),
  }),
  frequency: z.object({
    times: z.number().optional(),
    _type: z.string(),
  }),
  negated: z.boolean(),
  conditions: z.array(eventConditionsSchema),
});

const subscriberConditionSchema = z.object({
  _type: z.string(),
  conditions: z.array(eventConditionsSchema).optional(),
});

const tagConditionSchema = z.object({
  _type: z.string(),
  tagCondition: z.object({
    _type: z.string(),
  }),
  tags: z.array(z.string()),
});

const listConditionSchema = z.object({
  _type: z.string(),
  listCondition: z.object({
    _type: z.string(),
  }),
  listIds: z.array(z.number()),
});

const webhookConditionSchema = z.object({
  _type: z.string(),
  webhookUrl: z.string(),
  regex: z.string(),
});

interface workflowConditionSchema {
  id: string;
  conditionGroup:
    | z.infer<typeof subscriberConditionSchema>
    | z.infer<typeof customConditionSchema>
    | z.infer<typeof tagConditionSchema>
    | z.infer<typeof listConditionSchema>
    | z.infer<typeof webhookConditionSchema>;
  trueNode?: workflowConditionSchema | workflowActionSchema;
  falseNode?: workflowConditionSchema | workflowActionSchema;
  _type: string;
}

const workflowConditionSchema: z.ZodType<workflowConditionSchema> =
  z.late.object(() => ({
    id: z.string(),
    conditionGroup: z.union([
      subscriberConditionSchema,
      customConditionSchema,
      tagConditionSchema,
      listConditionSchema,
      webhookConditionSchema,
    ]),
    trueNode: workflowConditionSchema.optional(),
    falseNode: workflowConditionSchema.optional(),
    _type: z.string(),
  }));

export const workflowActionSchema: z.ZodType<workflowActionSchema> =
  z.late.object(() => ({
    id: z.string(),
    action: z.union([
      addListSchema,
      removeListSchema,
      webhookSchema,
      sendEmailSchema,
      publishEventSchema,
      unsubscribeActionSchema,
      executeScriptSchema,
      updateScoreSchema,
      notifyEmailSchema,
      timerActionSchema,
    ]),
    nextNode: z
      .union([workflowActionSchema, workflowConditionSchema])
      .optional(),
    _type: z.string(),
  }));

const conditionSchema = z.object({
  attributeName: z.string(),
  values: z.array(z.string()).optional(),
  value: z.string().optional(),
  _type: z.string(),
});

const subscriberInitialCondition = z.object({
  _type: z.string(),
  eventName: z.string().optional(),
  tagCondition: z.object({
    _type: z.string(),
  }),
  tags: z.array(z.string()),
});

const eventInitialCondition = z.object({
  _type: z.string(),
  eventName: z.string(),
  conditions: z.array(conditionSchema).optional(),
});

export type Workflow =
  | z.infer<typeof workflowActionSchema>
  | z.infer<typeof workflowConditionSchema>;

export const WorkflowSchema = z.object({
  id: z.string().optional(),
  accountId: z.number().optional(),
  name: z.string().optional().optional(),
  initialConditionGroup: z.union([
    eventInitialCondition,
    subscriberInitialCondition,
  ]),
  workflow: z.union([workflowActionSchema, workflowConditionSchema]),
  isEnabled: z.boolean().optional(),
  isTestMode: z.boolean().optional(),
  runsOncePerPersonIdentifier: z.boolean().optional(),
  defaultConversionReportingEvent: z.string().optional(),
  lastModified: z.number().optional(),
  lastTriggered: z.number().optional(),
  expiresAt: z.number().optional(),
  status: z.string().optional()
});

const SaveWorkflowSchema = z.object({
  id: z.string().optional(),
  accountId: z.number().optional(),
  name: z.string().optional().optional(),
  initialConditionGroup: z.union([
    eventInitialCondition,
    subscriberInitialCondition,
  ]).optional(),
  workflow: z.union([workflowActionSchema, workflowConditionSchema]).optional(),
  isEnabled: z.boolean().optional(),
  isTestMode: z.boolean().optional(),
  runsOncePerPersonIdentifier: z.boolean().optional(),
  defaultConversionReportingEvent: z.string().optional(),
  lastModified: z.number().optional(),
  lastTriggered: z.number().optional(),
  expiresAt: z.number().optional()
});

const initialConditionGroupSchema = z.union([eventInitialCondition,subscriberInitialCondition])

export type WorkflowType = z.infer<typeof WorkflowSchema>;
export type SaveWorkflowType = z.infer<typeof SaveWorkflowSchema>
export type EventInitialConditionType = z.infer<typeof eventInitialCondition>;
export type SubscriberConditionType = z.infer<
  typeof subscriberInitialCondition
>;
export type WorkflowAction = z.infer<typeof workflowActionSchema>;
export type WorkflowCondition = z.infer<typeof workflowConditionSchema>;
export type AllActionType = z.infer<typeof allActionSchema>;
export type InitialEventData = z.infer<typeof eventInitialCondition>;
export type EventConditionData = z.infer<typeof customConditionSchema>;
export type TagsConditionData = z.infer<typeof tagConditionSchema>;
export type SubscriberConditionData = z.infer<typeof subscriberConditionSchema>;
export type ListConditionData = z.infer<typeof listConditionSchema>;
export type WebhookConditionData = z.infer<typeof webhookConditionSchema>;
export type ListActionData = z.infer<typeof addListSchema>;
export type WebhookActionData = z.infer<typeof webhookSchema>;
export type PublishActionData = z.infer<typeof publishEventSchema>;
export type ScriptActionData = z.infer<typeof executeScriptSchema>;
export type UpdateScoreData = z.infer<typeof updateScoreSchema>;
export type InitialEventConditionData = z.infer<typeof eventConditionsSchema>;
export type PublishEventActionData = z.infer<typeof publishAttributeSchema>;
export type TimerActionData = z.infer<typeof timerActionSchema>;
export type NotifyEmailData = z.infer<typeof notifyEmailSchema>;
export type SendEmailData = z.infer<typeof sendEmailSchema>;
export type SubscriberActionData = z.infer<typeof unsubscribeActionSchema>
export type InitialConditionGroup = z.infer<typeof initialConditionGroupSchema>
