import OwnHtml from './OwnHtml';
import CustomTemplates from './CustomTemplate';
import Template from './Template';
import { FC, useEffect, useState } from 'react';
import { getTemplate } from 'services/apiHandlers/Campaigns/Emails';
import { z } from 'zod';
import React from 'react';
import Wrapper from 'components/Wrapper';

const categoriesSchema = z.object({
    prebuilt_template_category: z.object({
        id: z.number(),
        library_id: z.number(),
        name: z.string(),
    }),
});
export type Categories = z.infer<typeof categoriesSchema>;

const customTemplateSchema = z.object({
    custom_template: z.object({
        account_id: z.number(),
        content: z.string(),
        created_at: z.string(),
        css: z.string().nullable(),
        id: z.number(),
        name: z.string(),
        status: z.string(),
        updated_at: z.string(),
    }),
});
export type CustomTemplate = z.infer<typeof customTemplateSchema>;

const collectionSchema = z.object({
    prebuilt_template: z.object({
        color_contrast: z.string().nullable(),
        column_count: z.number().nullable(),
        created_at: z.string(),
        css: z.string(),
        description: z.string().nullable(),
        editor_version: z.number(),
        has_images: z.boolean().nullable(),
        html_part: z.string(),
        id: z.number(),
        library_id: z.number(),
        name: z.string(),
        primary_color: z.string(),
        secondary_color: z.string(),
        status: z.string(),
        template_type: z.string(),
        updated_at: z.string(),
    }),
});
export type Collections = z.infer<typeof collectionSchema>;

const templateSchema = z.object({
    collection: z.array(collectionSchema),
    current_page: z.number(),
    per_page: z.number(),
    total_count: z.number(),
});

const getTemplateResponseSchema = z.object({
    data: z.object({
        categories: z.array(categoriesSchema),
        custom_templates: z.array(customTemplateSchema),
        show_html_option: z.boolean(),
        show_templates_option: z.boolean(),
        templates: templateSchema,
    }),
    status: z.number(),
});

type GetTemplateResponse = z.infer<typeof getTemplateResponseSchema>;

const creationSteps = [
    { id: 'html', label: 'Use Your Own HTML' },
    { id: 'custom', label: 'Use a Custom Template' },
    { id: 'template', label: 'Use a Template' },
];

const EmailCreationSteps: FC = () => {
    const [view, setView] = useState<string>('html');
    const [customTemplates, setCustomTemplates] = useState<
        Array<CustomTemplate>
    >([]);
    const [categories, setCategories] = useState<Array<Categories>>([]);
    const [emailCollections, setEmailCollections] = useState<
        Array<Collections>
    >([]);

    const getData = async () => {
        try {
            const getTemplateResponse =
                (await getTemplate()) as GetTemplateResponse;

            if (getTemplateResponse.status === 200) {
                setCustomTemplates(getTemplateResponse.data.custom_templates);
                setCategories(getTemplateResponse.data.categories);
                setEmailCollections(
                    getTemplateResponse.data.templates.collection
                );
            }
        } catch (error) {
            console.log('error is : ', error);
        }
    };

    useEffect(() => {
        getData();
    }, []);

    return (
        <div className="px-6 min-h-[74vh] h-full ">
            <Wrapper>
                <>
                    {/* <StatusBar /> */}
                    <div className="flex justify-between items-center px-5  dark:border-[#fff] border-b border-[#e9ebec] py-[18px]">
                        <h1 className="text-base text-[#495057] font-medium  dark:text-[#CED4DA]">
                            Create Email
                        </h1>
                    </div>
                    <div className=" sm:px-5 px-3 py-6 ">
                        <div className="">
                            {creationSteps.map((step, index) => (
                                <div
                                    key={step.id}
                                    className={`${index > 0 ? '' : ''} ${
                                        step.id === view
                                            ? 'bg-[aliceblue] dark:bg-transparent mt-2'
                                            : 'bg-[#F5F6F7] dark:bg-transparent mt-2'
                                    }  rounded-md py-4 px-2 `}
                                >
                                    <label
                                        htmlFor={step.id}
                                        className="radio-btn customRadio cursor-pointer"
                                    >
                                        <input
                                            className=""
                                            type="radio"
                                            name={step.id}
                                            id={step.id}
                                            checked={view === step.id}
                                            onChange={(event) => {
                                                if (event.target.checked) {
                                                    setView(step.id);
                                                }
                                            }}
                                        />
                                        <span
                                            className={`${
                                                view === step.id
                                                    ? 'before:border-unset'
                                                    : 'before:border-gray-600'
                                            } before:border-[0.5px]  text-[#212529] dark:text-white leading-5 text-sm font-medium `}
                                        >
                                            {step.label}
                                        </span>
                                    </label>

                                    {view === 'html' && step.id === 'html' && (
                                        <OwnHtml />
                                    )}
                                    {view === 'custom' &&
                                        step.id === 'custom' && (
                                            <CustomTemplates
                                                templates={customTemplates}
                                            />
                                        )}
                                    {view === 'template' &&
                                        step.id === 'template' && (
                                            <Template
                                                categories={categories}
                                                collections={emailCollections}
                                            />
                                        )}
                                </div>
                            ))}
                        </div>
                    </div>
                </>
            </Wrapper>
        </div>
    );
};
export default EmailCreationSteps;
