import { useContext, useEffect, useState } from 'react';
import ToggleSwitch from 'ToggleSwitch/ToggleSwitch';
import { useFormik } from 'formik';
import { CampaignContext } from 'store/CampaignContext';
import React from 'react';
const TrackDelivery = () => {
  const campaignCtx = useContext(CampaignContext);
  const [trackDelivery, setTrackDelivery] = useState<boolean>(
    campaignCtx?.campaignData?.options['track_clicks'] || false
  );
  const [showSocialBar, setShowSocialBar] = useState<boolean>(
    campaignCtx?.campaignData?.options['include_social_bar'] || false
  );
  const formik = useFormik({
    initialValues: {
      share_title: campaignCtx?.campaignData?.share_title || '',
      share_description: campaignCtx?.campaignData?.share_description || campaignCtx?.initialData.description || '',
    },
    onSubmit: () => console.log(),
  });
  useEffect(() => {
    const optionsData = {
      include_social_bar: showSocialBar,
      track_clicks: trackDelivery,
    };
    if (campaignCtx?.campaignData) {
      campaignCtx?.onUpdateCampaignData({
        ...campaignCtx.campaignData,
        ...formik.values,
        options: optionsData,
      });
    }
  }, [trackDelivery, showSocialBar, formik.values]);
  useEffect(() => {
    if (campaignCtx?.initialData) {
      const { socialBar, trackClick, description } = campaignCtx.initialData;
      const options = {
        include_social_bar: socialBar,
        track_clicks: trackClick,
      };
      if (
        !campaignCtx.campaignData?.share_description &&
        !campaignCtx.campaignData?.options['include_social_bar']
      ) {
        setShowSocialBar(socialBar);
        formik.setFieldValue('share_description', description);
      }
      if (campaignCtx?.campaignData) {
        campaignCtx?.onUpdateCampaignData({
          ...campaignCtx.campaignData,
          options: options,
          share_description: description,
        });
      }
    }
  }, [campaignCtx?.initialData]);
  useEffect(() => {
    if (campaignCtx?.campaignData) {
      formik.setFieldValue('share_title', campaignCtx?.campaignData.subject);
      campaignCtx?.onUpdateCampaignData({
        ...campaignCtx.campaignData,
        share_title: campaignCtx?.campaignData.subject,
      });
    }
  }, [campaignCtx?.campaignData?.subject]);
  return (
    <div className="">
      <div className="flex w-full justify-between mt-6">
        <div className="">
          <p className="text-sm font-medium text-[#495057] dark:text-white mb-1">
            Trick click-through activity
          </p>
          <p className="text-xs font-normal leading-4 text-[#878A99] dark:text-white">
            We will track recipient clicks within your email.
          </p>
        </div>
        <div>
          <ToggleSwitch
            onChange={() => setTrackDelivery((prev) => !prev)}
            checked={trackDelivery}
          />
        </div>
      </div>
      <div className="flex mt-6 w-full justify-between">
        <div className="">
          <p className="text-sm font-medium text-[#495057] dark:text-white mb-1">
            Social Bar
          </p>
          <p className="text-xs font-normal leading-4 text-[#878A99] dark:text-white">
            Add a social media bar to your email to increase the reach of your
            message.
          </p>
        </div>
        <div>
          <ToggleSwitch
            onChange={() => setShowSocialBar((prev) => !prev)}
            checked={showSocialBar}
          />
        </div>
      </div>
      {showSocialBar && (
        <div className="pt-4">
          <div className="flex items-center">
            <label className="text-xs font-semibold leading-3 text-black-300 dark:text-white md:w-28 w-20">
              Title:
            </label>
            <input
              className="border border-gray-800 rounded-md text-13 font-medium leading-3 text-[#495057] dark:text-[#CED4DA]  w-full h-11 p-4 sm:w-64 w-8/12  dark:bg-[#41464E]  dark:border-[#fff] focus:outline-none dark:text-white"
              type="text"
              name="share_title"
              value={formik.values.share_title}
              onChange={formik.handleChange}
            />
          </div>
          <div className="flex items-center mt-5">
            <label className="text-xs font-semibold leading-3 text-black-300 dark:text-white md:w-28 w-20">
              Description:
            </label>
            <textarea
              className="p-2 text-13 h-28 font-medium leading-3 sm:w-64 w-8/12 text-[#495057] dark:text-[#CED4DA]  dark:text-white focus:outline-0 border border-gray-800 rounded-md  dark:bg-[#41464E]  dark:border-[#fff] focus:outline-none"
              name="share_description"
              value={formik.values.share_description}
              onChange={formik.handleChange}
            />
          </div>
        </div>
      )}
    </div>
  );
};
export default TrackDelivery;