import React from "react";
const BigCommerceDescription = () => {
  return (
    <p className="text-sm  leading-4 text-[#878A99] dark:text-white">
      These credentials must match an api account at your BigCommerce store
      under Advanced Settings {'>'} API Accounts {'>'} Create API Account. See
      More at the Big Commerce API Documentation
    </p>
  );
};
export default BigCommerceDescription;
