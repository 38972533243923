import { FC } from 'react';
import 'chart.js/auto';
import {
  Chart as ChartJS,
  ChartDataset,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useContext } from 'react';
import { ThemeContext } from 'store/ThemeContext';
import { z } from 'zod';
import ErrorBoundary from 'components/ErrorBoundary';
import { getYAxisGraphValue } from 'utils/common';
import React from 'react';

const tooltipDataSchema = z.object({ formattedValue: z.string() });
type TooltipCallback = z.infer<typeof tooltipDataSchema>;

const chartStyleSchema = z.object({
  maxWidth: z.string().optional(),
  width: z.string().optional(),
  height: z.string().optional(),
  padding: z.string().optional(),
});

type chartStyle = z.infer<typeof chartStyleSchema>;

interface Props {
  stacked?: boolean;
  chartLabels: string[] | undefined | (string | undefined)[] | undefined;
  datasets: ChartDataset<'bar', (number | [number, number] | null)[]>[];
  height?: number | string;
  style?: chartStyle;
  hideLegend?: boolean;
  isQuantitativeData?: boolean;
}

const BarGraph: FC<Props> = ({
  chartLabels,
  datasets,
  stacked,
  height,
  style,
  hideLegend,
  isQuantitativeData,
}) => {
  const themeCtx = useContext(ThemeContext);
  const theme = themeCtx?.theme;
  ChartJS.register(
    LineController,
    LineElement,
    PointElement,
    LinearScale,
    Title
  );
  const labels = chartLabels;
  const options = {
    responsive: true,
    showTooltips: true,
    maintainAspectRatio: false,
    // aspectRatio: 2.7,
    plugins: {
      title: {
        display: true,
      },
      tooltip: {
        callbacks: {
          label: function (data: TooltipCallback) {
            if (!isQuantitativeData) {
              return '$' + data.formattedValue;
            } else {
              return data.formattedValue;
            }
          },
        },
      },
      legend: {
        margin: {
          top: 60,
        },
        display: hideLegend ? false : true,
        position: 'bottom' as const,
        align: 'center' as const,
        labels: {
          usePointStyle: true,
          boxWidth: 7,
          boxHeight: 7,
          color: `${theme === 'dark' ? 'white' : ''}`,
          padding: 20,
          textAlign: 'left' as const,
        },
      },
    },
    scales: {
      x: {
        beginAtZero: true,
        stacked: stacked,
        categorySpacing: 0,
        ticks: {
          color: `${theme === 'dark' ? '#CED4DA' : ''}`,
          beginAtZero: true,
        },
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        stacked: stacked,
        categorySpacing: 0,
        ticks: {
          color: `${theme === 'dark' ? '#CED4DA' : ''}`,
          beginAtZero: true,
          callback: function (value: string | number) {
            const formattedValue = getYAxisGraphValue(value)
            const isDollarSign = !isQuantitativeData ? '$' : '';
            return isDollarSign + (value === 0 ? value : formattedValue)
          },
          // stepSize: 10,
        },
        grid: {
          display: true,
          drawOnChartArea: true,
          color: `${theme === 'dark' ? '#fff' : '#e9ebec'}`,
        },
      },
    },
  };
  const data = {
    labels,
    datasets: datasets,
  };

  return (
    <ErrorBoundary>
      <div className="h-full">
        {datasets && (
          <Bar height={height} options={options} data={data} style={style} />
        )}
      </div>
    </ErrorBoundary>
  );
};
export default BarGraph;
