import Plus from 'assets/Images/Subscribers/plus.png';
import Upload from 'assets/Images/Subscribers/upload2.png';
import Wrapper from 'components/Wrapper';
import { useFormik, FormikValues } from 'formik';
import React from 'react';
import { Link } from 'react-router-dom';
import { create, list, listSegments } from 'services/constant/routes';

const UploadSubscribers = () => {
  const formik: FormikValues = useFormik({
    enableReinitialize: true,
    initialValues: {
      radioValue: 'individual',
    },
    onSubmit: async (values) => {
      console.log(values);
    },
  });

  return (
    <>
    <div className="px-6 min-h-[74vh]">
      <Wrapper>
        <div className="p-4">
          <div className=" w-full md:pr-2.5">
            <div className="flex justify-between items-center mb-4">
              <div>
                <h2 className="text-base font-semibold leading-5 text-[#495057] dark:text-white">
                  Add Subscriber
                </h2>
                <p className="text-13 leading-4 text-[#878a99] dark:text-white mt-2">
                  Import or add individual subscribers and optionally select
                  them for membership in your
                  <Link
                    className="text-primary underline cursor-pointer"
                    to={`${listSegments}/${list}`}
                  >
                    Lists
                  </Link>
                </p>
              </div>
              <Link
                className="flex items-center justify-end mt-4"
                to={`${create}`}
              >
                <button
                  type="button"
                  className="text-13 font-medium leading-5 text-white py-2.5 px-4 rounded-md w-28 h-11 bg-primary ease-in-in duration-300 hover:bg-primary hover:scale-105"
                >
                  Continue
                </button>
              </Link>
            </div>
          </div>
          <form onSubmit={formik.handleSubmit} className="mt-6">
            <div className="md:flex">
              <div className="md:w-7/12 w-full md:pr-2.5">
                <div>
                  <div>
                    <div className="radio-btn block relative flex items-center text-13 font-normal leading-3 text-[#495057] dark:text-[#CED4DA]  dark:text-white dark:text-white mt-4">
                      <input
                        disabled
                        id="upload"
                        type="radio"
                        value="upload"
                        name="radioValue"
                        className="w-4 h-4 text-primary bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600 mr-2"
                        checked={formik.values.radioValue === 'upload'}
                        onChange={formik.handleChange}
                      />
                      <label
                        htmlFor="upload"
                        className="text-xs font-semibold leading-3 tracking-wide uppercase text-[#495057] dark:text-white dark:text-white inline-block inline-block"
                      >
                        Upload a file
                      </label>
                    </div>
                    <p className="text-xs font-normal leading-4 text-[#878a99] dark:text-white dark:text-white mt-1.5 pl-7">
                      Please upload your list in tab delimited text format. For
                      example, if your list is in Excel, go to File → Save As,
                      then select Tab Delimited Text for the Format.
                    </p>
                    {formik.values.radioValue === 'upload' && (
                      <div className="mt-4 pt-6 pb-8 flex justify-center items-center relative border border-dashed border-ryzeo-blue rounded bg-[#fbfcfd] ml-px">
                        <div className="w-[194px] mx-auto">
                          <div>
                            <img
                              className="mx-auto"
                              src={Upload}
                              alt="Upload"
                            />
                          </div>
                          <p className="text-sm font-medium leading-4 text-black-700 dark:text-white mt-6 text-center">
                            Maximum file size is 10 MB
                          </p>
                          <div className="px-0.5">
                            <button className="text-13 font-medium leading-4 text-white rounded flex items-center justify-center py-3 px-2 w-full mt-2.5 ryeo-blue-bg">
                              <img src={Plus} alt="Plus" />
                              <span className="ml-3">
                                Select files to upload
                              </span>
                            </button>
                          </div>
                        </div>
                        <input
                          className="absolute top-0 w-full h-full opacity-0 cursor-pointer"
                          type="file"
                          name="upload"
                        />
                      </div>
                    )}
                  </div>
                  <div className="mt-8">
                    <div className="radio-btn block relative flex items-center text-13 font-normal leading-3 text-[#495057] dark:text-[#CED4DA]  dark:text-white dark:text-white mt-4">
                      <input
                        id="individual"
                        type="radio"
                        value="individual"
                        name="radioValue"
                        defaultChecked
                        className="w-4 h-4 text-primary bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 dark:bg-gray-700 dark:border-gray-600 mr-2"
                        onChange={formik.handleChange}
                      />
                      <label
                        htmlFor="individual"
                        className="text-xs font-semibold leading-3 tracking-wide uppercase text-[#495057] dark:text-white dark:text-white inline-block inline-block"
                      >
                        Individually
                      </label>
                    </div>
                    <p className="text-xs font-normal leading-4 text-[#878a99] dark:text-white mt-1.5 pl-7">
                      Add subscribers one at a time.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </Wrapper>
    </div>
    </>
  );
};
export default UploadSubscribers;
