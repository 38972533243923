import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import {
  filters,
  listId,
  listSegments,
  subscribersProfile,
  view,
} from 'services/constant/routes';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import { Blast } from './index';
import AddIcon from 'assets/Images/ListsegmentImages/plus-blue.png'

interface Props {
  blast: Blast | undefined;
}
const ListSegments: FC<Props> = ({ blast }) => {
  return (
    <div className="flex items-center  border-b justify-between dark:border-[#fff]">
      <p className="px-4 py-2 text-[#495057] text-sm font-medium dark:text-white">To</p>
      {
        <p className="px-4 py-2 text-sm text-[#878A99]">
          <div className=" items-center">
            <div className='flex items-center justify-end'>
              <Link
                to={`/${subscribersProfile}`}
                className="text-xs font-medium leading-4 mr-2 hover:text-primary hover:underline cursor-pointer"
              >
                {blast?.blast.audience?.name}
              </Link>
              <p className="text-[#212529] text-xs dark:text-[#878A99] ">
                {blast?.blast.intended_recipients ? blast?.blast.intended_recipients.toLocaleString() : '0'}{' '}
                people (expected)
              </p>
            </div>
            {blast?.blast.audience?.included_list &&
              blast.blast.audience.included_list.length > 0 && (
                <div className='flex items-center '>
                  <img className='h-[10px] w-[10px]' src={AddIcon} alt="Add Icon" />
                  <ul className="flex items-center">
                    {blast?.blast.audience?.included_list?.slice(0, 3).map((list) => (
                      list.name.length > 11 ? (
                        <Tippy key={list.id} interactive={true}
                          content={
                            <Link
                              to={`/${subscribersProfile}?${listId}=${list.id.toString()}`}
                              key={list.id}
                            >
                              {list.name}
                            </Link>
                          }>
                          <p>
                            <Link
                              to={`/${subscribersProfile}?${listId}=${list.id.toString()}`}
                              key={list.id}
                            >
                              <li className="text-xs pl-1 rounded leading-4 bg-[#f7f7f7] dark:bg-transparent text-[#878A99] mx-1 cursor-pointer max-w-[80px] truncate" >
                                {list.name}
                              </li>
                            </Link>
                          </p>
                        </Tippy>) :
                        (<Link
                          to={`/${subscribersProfile}?${listId}=${list.id.toString()}`}
                          key={list.id}
                        >
                          <li className="text-xs pl-1 rounded leading-4 bg-[#f7f7f7] dark:bg-transparent text-[#878A99] mx-1 cursor-pointer" >
                            {list.name}
                          </li>
                        </Link>)
                    ))}
                    {blast?.blast.audience?.included_list?.length > 3 &&
                      <Tippy interactive={true} content={
                        blast?.blast.audience?.included_list?.map((exc) => (
                          <Link key={exc.id} to={`/${subscribersProfile}?${listId}=${exc.id.toString()}`}>
                            <p
                              className="text-white px-2 rounded py-1 text-sm ml-2"
                            >
                              - {exc.name}
                            </p>
                          </Link>
                        ))}>
                        <button type='button' className='text-xs text-primary underline'>Show More</button>
                      </Tippy>}

                  </ul>
                </div>
              )}
            {blast?.blast.audience?.excluded_list &&
              blast?.blast.audience?.excluded_list.length > 0 && (
                <div className='flex items-center justify-end'>
                  <img className='h-[10px] w-[10px]' src={AddIcon} alt="Add Icon" />
                  <ul className="flex items-center ">
                    {blast?.blast.audience?.excluded_list?.slice(0, 3).map((list) => (
                      list.name.length > 10 ? (
                        <Tippy key={list.id} interactive={true}
                          content={
                            <Link
                              to={`/${subscribersProfile}?${listId}=${list.id.toString()}`}
                              key={list.id}
                            >
                              {list.name}
                            </Link>
                          }>
                          <p>
                            <Link
                              to={`/${subscribersProfile}?${listId}=${list.id.toString()}`}
                              key={list.id}
                            >
                              <li className="text-xs pl-1 rounded leading-4 bg-[#f7f7f7] dark:bg-transparent text-[#878A99] mx-1 cursor-pointer max-w-[80px] truncate" >
                                {list.name}
                              </li>
                            </Link>
                          </p>
                        </Tippy>) :
                        (<Link
                          to={`/${subscribersProfile}?${listId}=${list.id.toString()}`}
                          key={list.id}
                        >
                          <li className="text-xs pl-1 rounded leading-4 bg-[#f7f7f7] dark:bg-transparent text-[#878A99] mx-1 cursor-pointer truncate" >
                            {list.name}
                          </li>
                        </Link>)
                    ))}
                    {blast?.blast.audience?.excluded_list?.length > 3 &&
                      <Tippy interactive={true} content={
                        blast?.blast.audience?.excluded_list?.map((exc) => (
                          <Link key={exc.id} to={`/${subscribersProfile}?${listId}=${exc.id.toString()}`}>
                            <p
                              className="text-white px-2 rounded py-1 text-sm ml-2"
                            >
                              - {exc.name}
                            </p>
                          </Link>
                        ))}>
                        <button type='button' className='text-xs text-primary underline'>Show More</button>
                      </Tippy>}

                  </ul>
                </div>
              )}
          </div>
          {blast?.blast?.audience?.segment.name && (
            <div>
              Segment/Filter:
              <Link
                to={`${listSegments}/${filters}/${view}/${blast.blast.audience.segment.id}`}
              >
                <p className="hover:text-primary hover:underline cursor-pointer">
                  {blast.blast.audience.segment.name}
                </p>
              </Link>
            </div>
          )}
        </p>
      }
    </div>
  );
};
export default ListSegments;
