import httpService from 'services/core/HttpService';
import {
  GET_SINGLE_WORKFLOW,
  GET_EVENT_NAMES,
  GET_EVENT_ATTRIBUTES,
  UPDATE_WORKFLOW,
  GET_TAGS,
  GET_WEBHOOK_EVENTS,
  TEST_WEBHOOK,
  CREATE_WORKFLOW,
  SEND_TEST,
  DELETE_WORKFLOW,
  GET_WORKFLOW_LOGS,
  RESET_EXECUTIONS,
  GET_EXECUTION_LOGS,
  GET_CONFIGURATION,
  GET_WORKFLOW_TRANSACTION_REPORTS,
} from 'services/constant/apiRoutes';
import { SendTestRequest } from 'components/Workflow/Body/Workflows/Modals/WebhookCondition';
import {
  SaveWorkflowType,
  WorkflowType,
} from 'components/Workflow/Body/Workflows/index.type';
import {  z } from 'zod';

const sendTestSchema = z.object({
  eventName: z.string(),
  workflowName: z.string(),
  url: z.string(),
});
type SendTest = z.infer<typeof sendTestSchema>;

export const getSingleWorkflow = (id: string) => {
  const getWorkflowUrl = GET_SINGLE_WORKFLOW + id;

  return new Promise((resolve, reject) => {
    httpService
      .get(getWorkflowUrl)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getEventNames = () => {
  return new Promise((resolve, reject) => {
    httpService
      .get(GET_EVENT_NAMES)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getEventAttributes = (name: string) => {
  const events = name.split(',');
  console.log(events);

  const promises = events.map((event) => {
    const attributeUrl = GET_EVENT_ATTRIBUTES + event;
    try {
      return httpService.get(attributeUrl);
    } catch (error) {
      console.error(`Failed to fetch data for event: ${event}`, error);
      return null;
    }
  });

  console.log(promises);
  const responseToReturn = Promise.all(promises).then((responses) => {
    
    const allStatusOk = responses.every(response=>response?.status === 200);

    const joinedResponsesData: string[] = [];
    
    responses.map(response => {
      const { data } = response || {}
      for(const attribut of data)
      {
        joinedResponsesData.push(attribut)
      }}
      );

    const uniqueAttributeArray = Array.from(new Set(joinedResponsesData));
    
    return{
      status: allStatusOk? 200 : 400,
      data: uniqueAttributeArray
    }



  });
  return responseToReturn;


};

export const updateWorkflow = (
  workflow: WorkflowType | undefined | SaveWorkflowType,
  id: string
) => {
  const updateWorkflowUrl = UPDATE_WORKFLOW + id;
  return new Promise((resolve, reject) => {
    httpService
      .put(updateWorkflowUrl, workflow)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getTags = () => {
  return new Promise((resolve, reject) => {
    httpService
      .get(GET_TAGS)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getWebhookEvents = (
  name: string | undefined,
  initialGroup: string
) => {
  const webhookNameUrl = GET_WEBHOOK_EVENTS.replace('{{name}}', name || '');
  const webhookGroupUrl = webhookNameUrl + initialGroup;
  return new Promise((resolve, reject) => {
    httpService
      .get(webhookGroupUrl)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const sendWebhookRequest = (data: SendTestRequest) => {
  return new Promise((resolve, reject) => {
    httpService
      .post(TEST_WEBHOOK, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const sendTestRequest = (data: SendTest) => {
  return new Promise((resolve, reject) => {
    httpService
      .post(SEND_TEST, data)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createWorkflow = (workflow: WorkflowType | SaveWorkflowType) => {
  return new Promise((resolve, reject) => {
    httpService
      .post(CREATE_WORKFLOW, workflow)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteWorkflow = (id: string) => {
  return new Promise((resolve, reject) => {
    httpService
      .delete(DELETE_WORKFLOW + id)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getWorkflowLogs = (id: string, logId?: string) => {
  const workflowLogUrl =
    GET_WORKFLOW_LOGS.replace('{{ID}}', id) +
    `${logId ? `?fromLogEntryId=${logId}` : ''}`;
  return new Promise((resolve, reject) => {
    httpService
      .get(workflowLogUrl)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const resetExecutions = (id: string | undefined) => {
  const resetExecutionUrl = RESET_EXECUTIONS.replace('{{ID}}', id || '');
  return new Promise((resolve, reject) => {
    httpService
      .delete(resetExecutionUrl)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getExecutionLogs = (id: string, logID: string) => {
  const executionLogUrl = GET_EXECUTION_LOGS.replace('{{ID}}', id) + logID;
  return new Promise((resolve, reject) => {
    httpService
      .get(executionLogUrl)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getConfiguration = () => {
  return new Promise((resolve, reject) => {
    httpService
      .get(GET_CONFIGURATION)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getWorkflowTransactionReports = (
  triggerId: string,
  startDate: string,
  endDate: string
) => {
  return new Promise((resolve, reject) => {
    httpService
      .get(
        GET_WORKFLOW_TRANSACTION_REPORTS +
          `?triggerId=${triggerId}&startDate=${startDate}&endDate=${endDate}`
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
