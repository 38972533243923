import React, { useContext, useEffect, useState } from 'react';
import { z } from 'zod';
import { getEventAttributes, getEventNames } from 'services/apiHandlers/Workflows/Workflow';
import AllEvents from 'components/Events/AllEvents';
import { downloadLogReport, getAllLogs } from 'services/apiHandlers/Logs';
import moment from 'moment-timezone';
import EventAttributes from 'components/Events/EventAttributes';
import xmlToJSON from 'utils/xmlToJSON';
import { formatXmlJsonResponse } from 'utils/common';
import { getFields } from 'services/apiHandlers/List-Segments/Field';
import { UserContext } from 'store/UserContext';
import BreadcrumbsWrapper from 'components/Wrapper/BreadcrumbsWrapper';
import { eventExtraOptions } from 'staticdata/EventLogs/Data';



const getEventsResponseSchema = z.object({
  data: z.array(z.string()),
  status: z.number(),
});

const resultDataSchema = z.object({
  accountId: z.number(),
  attributes: z.record(z.string()),
  id: z.string(),
  name: z.string(),
  timestamp: z.number(),
});

const getEventFiltersResponseSchema = z.object({
  data: z.object({
    next_start_token: z.string(),
    totalCount: z.string(),
    result: z.array(resultDataSchema),
  }),
  status: z.number(),
});

const eventAttributesSchema = z.object({
  name: z.string(),
  mailMerge: z.string(),
});

const eventAttributesResponseSchema = z.object({
  data: z.array(z.string()),
  status: z.number(),
});

type EventAttributeResponse = z.infer<typeof eventAttributesResponseSchema>;
type EventAttribute = z.infer<typeof eventAttributesSchema>;
export type ResultData = z.infer<typeof resultDataSchema>;
type GetEventResponse = z.infer<typeof getEventFiltersResponseSchema>;
type GetEvents = z.infer<typeof getEventsResponseSchema>;

const EventLogs = () => {
  const [events, setEvents] = useState<Array<string>>([]);
  const [selectedEvent, setSelectedEvent] = useState<string>('All Events');
  const [selectedDropdownEvent, setSelectedDropdownEvent] = useState<string>('All Events');
  const [startDate, setStartDate] = useState<string>(moment().subtract(3, 'months').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = useState<string>(moment().format('YYYY-MM-DD'));
  const [allEvents, setAllEvents] = useState<Array<ResultData>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [nextStartToken, setNextStartToken] = useState<string>('');
  const [showMoreButton, setShowMoreButton] = useState<boolean>(true);
  const [moreLoading, setMoreLoading] = useState<boolean>(false);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [searchValue, setSearchValue] = useState<string>('');
  const [dateApplied, setDateApplied] = useState<boolean>(false);
  const [showEventAttributes, setShowEventAttributes] =
    useState<boolean>(false);
  const [eventAttributes, setEventAttributes] = useState<Array<EventAttribute>>(
    []
  );
  const [fields, setFields] = useState<Array<EventAttribute>>([]);
  const userCtx = useContext(UserContext);
  
  const getEventLogs = async (
    startDate: string,
    endDate: string,
    filter?: string,
    startToken?: string,
    more?: boolean,
    event?: string
  ) => {
    if (!more) {
      setLoading(true);
      
    }
    setMoreLoading(true);
    setShowMoreButton(true);
    
    const momentEndDate = moment(endDate).format('YYYY-MM-DD');
    const momentStartDate = moment(startDate).format('YYYY-MM-DD');

    try {
      const getAllEventResponse = (await getAllLogs(
        moment
          .tz(momentStartDate, userCtx?.usersData?.timeZone || '')
          .startOf('day')
          .format('YYYY-MM-DD[T00:00:00.000Z]'),
        moment
          .tz(momentEndDate, userCtx?.usersData?.timeZone || '')
          .endOf('day')
          .format('YYYY-MM-DD[T23:59:00.000Z]'),
        filter,
        startToken,
        event || selectedEvent
      )) as GetEventResponse;

      

        if (getAllEventResponse.status === 200) {
        if (getAllEventResponse.data.result.length === 0) {
          if (more) {
            setAllEvents(allEvents);
          } else {
            setAllEvents(getAllEventResponse.data.result);
          }
          setShowMoreButton(false);
        } else {
          if (event !== 'All Events') {
            if (more) {
              if (getAllEventResponse.data.result.length === 0) {
                setAllEvents(allEvents);
                setShowMoreButton(false);
              } else {
                setAllEvents([
                  ...allEvents,
                  ...getAllEventResponse.data.result,
                ]);
              }
            } else {
              setAllEvents(getAllEventResponse.data.result);
            }
          } else if (event === 'All Events') {
            setAllEvents(getAllEventResponse.data.result);
          } else if (filter && filter?.length > 0) {
            if (more) {
              if (getAllEventResponse.data.result.length === 0) {
                setAllEvents(allEvents);
                setShowMoreButton(false);
              } else {
                setAllEvents([
                  ...allEvents,
                  ...getAllEventResponse.data.result,
                ]);
              }
            } else {
              setAllEvents(getAllEventResponse.data.result);
            }
          } else if (dateApplied) {
            if (more) {
              if (getAllEventResponse.data.result.length === 0) {
                setShowMoreButton(false);
                setAllEvents(allEvents);
              } else {
                setAllEvents([
                  ...allEvents,
                  ...getAllEventResponse.data.result,
                ]);
              }
            } else {
              setAllEvents(getAllEventResponse.data.result);
            }
          } else {
            setAllEvents([...allEvents, ...getAllEventResponse.data.result]);
          }
        }
        setNextStartToken(getAllEventResponse.data.next_start_token);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
    setMoreLoading(false);
    setLoading(false);
  };

  const getEventsData = async () => {
    try {
      const getEventsResponse = (await getEventNames()) as GetEvents;
      if (getEventsResponse.status === 200) {

        setEvents([
          'All Events',
          ...getEventsResponse.data,
          ...eventExtraOptions,
        ]);
      }
    
      /* eslint-disable */
      const getFieldsResponse: any = await getFields();
      if (getFieldsResponse.status === 200) {
        const data: any = xmlToJSON.parseString(getFieldsResponse.data);
        const fields = formatXmlJsonResponse(data?.fields[0]?.field);
        const tempFields = fields.map((field) => {
          return { name: field.name, mailMerge: `{{${field.slug}}}` };
        });
        setFields(tempFields);
      }
      /* eslint-enable */

    } catch (error) {
      console.log('error is : ', error);
    }
  };

  const getEventAttributesHandler = async () => {
    try {
      const getEventAttributesResponse = (await getEventAttributes(
        selectedEvent
      )) as EventAttributeResponse;
      if (getEventAttributesResponse.status === 200) {
        
        const tempAttributes = getEventAttributesResponse.data.map(
          (attribute) => {
            return {
              name: attribute,
              mailMerge: `{{event.attributes.${attribute}}}`,
            };
          }
        );
        setEventAttributes(tempAttributes);
      }
    } catch (error) {
      console.log('eror is : ', error);
    }
  };

useEffect(() => {
  const searchInterval = setTimeout(() => {
    if (userCtx?.filteredValue && userCtx.filteredValue.length > 0) {
      getEventLogs(startDate, endDate,userCtx?.filteredValue)
    } else {
      getEventLogs(startDate, endDate)
    }
  }, 1000);
  return () => {
    clearInterval(searchInterval);
  };
}, [userCtx?.filteredValue]);

  useEffect(() => {
    if (selectedEvent !== 'All Events') {
      getEventAttributesHandler(); // events attributes
    }
  }, [selectedEvent]);

  useEffect(() => {
    getEventsData(); //events Names
  }, []);

  const filterData = { startDate, endDate, searchValue: userCtx?.filteredValue || '', selectedEvent };

  
 

  
  return (
    <div className='overflow-hidden'>
      <BreadcrumbsWrapper />
        <AllEvents
         
          events={events}
          setSelectedDropdownEvent={(event: string) => setSelectedDropdownEvent(event)}
          selectedDropdownEvent={selectedDropdownEvent}
          onSelect={(event) => {
            setSelectedEvent(event);
            getEventLogs(
              startDate,
              endDate,
              userCtx?.filteredValue,
              undefined,
              undefined,
              event
            );
          }}
          onDownloadLog={() =>
            downloadLogReport(
              selectedEvent,
              userCtx?.filteredValue || '',
              moment
                .utc(startDate)
                .startOf('day')
                .format('YYYY-MM-DD[T07:00:00.000Z]'),
              moment
                .utc(endDate)
                .startOf('day')
                .format('YYYY-MM-DD[T07:00:00.000Z]'),
              nextStartToken
            )
          }
          selectedEvent={selectedEvent}
          allEvents={allEvents}
          loading={loading}
          showMore={() =>
            getEventLogs(startDate, endDate, userCtx?.filteredValue, nextStartToken, true)
          }

          showMoreButton={showMoreButton}
          moreLoading={moreLoading}
          filterData={filterData}
          setShowEventAttributes={setShowEventAttributes}
          showEventAttributes={showEventAttributes}
          startDate={startDate}
          endDate={endDate}
          onStartDateChange={setStartDate}
          onEndDateChange={setEndDate}
          onApplyFilter={() => {
            setDateApplied(true);
            getEventLogs(startDate, endDate, userCtx?.filteredValue);
          }}
        />
      {showEventAttributes && !loading && (
        
        <EventAttributes
          selectedEvent={selectedEvent}
          selectedDropdownEvent={selectedDropdownEvent}
          onClose={setShowEventAttributes}
          eventAttributes={eventAttributes}
          fields={fields}
        />
      )}
    </div>
  );
};
export default EventLogs;
