import ModalWrapper from 'components/Workflow/ModalWrapper';
import React, { FC } from 'react';

interface Props {
    onCancel: () => void;
    onDelete: () => void;
}

const DeleteModal: FC<Props> = ({ onCancel, onDelete }) => {
    return (
        <ModalWrapper buttonTxt="Delete" onClose={onCancel} onSave={onDelete}>
            <div className="bg-white  dark:bg-[#41464E]  p-5 rounded-lg  dark:border-[#fff]">
                <h3 className="sm:text-lg text-sm font-semibold leading-4 text-[#495057] dark:text-[#CED4DA]  ">
                    Delete
                </h3>
                <p className="sm:text-sm text-xs leading-5 text-gray-700 datk:text-white dark:text-white">
                    Are you sure you want to Delete?
                </p>
            </div>
        </ModalWrapper>
    );
};
export default DeleteModal;
