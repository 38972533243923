import Dropdown from 'components/Dropdown/Dropdown';
import React , { FC } from 'react';
import { excludedValues } from 'components/NewCampaign/ScheduleTime';
import { z } from 'zod';

const timeData = z.object({
  resumeTime: z.union([z.boolean(), z.undefined()]),
  hours: z.union([z.number(), z.undefined()]),
  minutes: z.union([z.number(), z.undefined()]),
  timeZone: z.union([z.string(), z.undefined()]),
});
type TimeData = z.infer<typeof timeData>;

interface Props {
  timeData: TimeData;
  onDataChange: (name: string, value: string | boolean) => void;
  error: boolean;
}

const DelayTimeData: FC<Props> = ({ timeData, onDataChange , error }) => {
  return (
    <>
      <div className="flex items-center mt-8">
        <input
          className="cursor-pointer w-[14px] h-[14px] text-primary bg-gray-100 border-gray-300  dark:focus:ring-blue-600 dark:ring-offset-gray-800  dark:bg-gray-700 dark:border-gray-600 mr-2"
          type="checkbox"
          id="timer-resume"
          name="timer-resume"
          checked={timeData.resumeTime}
          onChange={(e) => onDataChange('resumeTime', e.target.checked)}
        />
        <label className="text-sm font-normal leading-4 text-[#495057] dark:text-[#CED4DA]">
          Resume at a specific time
        </label>
      </div>
      {timeData.resumeTime && (
        <>
          <div className="flex items-center gap-3 mt-6">
            <div className="flex items-center gap-3 dark:text-white">
              <div className="relative flex items-center sm:h-[38px] h-9 bg-white sm:w-20 dark:bg-[#41464E]">
                <input
                  className="text-13 font-medium leading-3 text-gray-500 p-2 w-full h-full border rounded-md border-gray-200  dark:bg-[#41464E]  dark:text-white"
                  type="number"
                  min='1'
                  max='12'
                  onKeyDown={(e) => {
                    if (excludedValues.includes(e.key)) {
                      e.preventDefault()
                    }
                  }}
                  value={timeData.hours}
                  onChange={(e) => onDataChange('hours', e.target.value)}
                />
              </div>
              :
              <div className="relative flex items-center sm:h-[38px] h-9 bg-white sm:w-20 dark:bg-[#41464E]">
                <input
                  className="text-13 font-medium leading-3 text-gray-500 p-2 w-full h-full border rounded-md border-gray-200  dark:bg-[#41464E]  dark:text-white"
                  type="number"
                  value={timeData.minutes}
                  min='0'
                  max='59'
                  onKeyDown={(e) => {
                    if (excludedValues.includes(e.key)) {
                      e.preventDefault()
                    }
                  }}
                  onChange={(e) => onDataChange('minutes', e.target.value)}
                />
              </div>
            </div>
            <div className="z-9 dropdown-show-hide relative sm:[38px] h-9 sm:w-28 max-w-[80px]">
              <Dropdown
                options={['AM', 'PM']}
                value={timeData.timeZone}
                onSelect={(value) => onDataChange('timeZone', value)}
              />
            </div>
            {error && (
              <div className="px-6 py-3 bg-[#f7e8e7] rounded mt-4 ml-10">
                <p className="text-sm font-normal leading-4 text-[#CB5A5A]">
                  Enter a valid time
                </p>
              </div>
            )}
          </div>
          <p className="text-13  leading-4 text-[#878a99] mt-4 dark:text-white">
            If the triggering event&#39;s &#34; timestampOffset &#34; property
            is not present or valid, this account&#39;s timezone
            (America/Los_Angeles) will be used.
          </p>
        </>
      )}
    </>
  );
};
export default DelayTimeData;
