import Dropdown from 'components/Dropdown/ActionDropdown';
import 'assets/styles/common.css';
import { getEmailSenders } from 'services/apiHandlers/Integration/Miscellaneous';
import { useEffect, useState } from 'react';
import { z } from 'zod';
import React from 'react';
import Wrapper from 'components/Wrapper';

export const dataSchema = z.object({
  accountId: z.number().optional(),
  email: z.string().optional(),
  id: z.string().optional(),
  links: z
    .object({ triggers: z.union([z.array(z.string()), z.tuple([])]) })
    .optional(),
  name: z.string().optional(),
  replyTo: z.string().optional(),
});
export type data = z.infer<typeof dataSchema>;

const responseSchema = z.object({
  data: z.array(dataSchema),
  status: z.number(),
});
type response = z.infer<typeof responseSchema>;

const EmailSender = () => {
  const [emailSenderData, setEmailSenderData] = useState<Array<data>>();
  const getData = async () => {
    const getEmailSenderResponse = (await getEmailSenders()) as response;
    if (getEmailSenderResponse.status === 200) {
      setEmailSenderData(getEmailSenderResponse.data);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <Wrapper>
      <div className="p-4">
        <h3 className="text-13 font-medium leading-4  text-[#495057] dark:text-white mb-4">
          E-Mail Senders
        </h3>
        <div className="min-h-auto border rounded dark:border-[#fff]">
          <Dropdown options={emailSenderData} getData={getData} />
        </div>
      </div>
    </Wrapper>
  );
};
export default EmailSender;
