
import AudienceGrowth from './AudienceGrowth';
import { FC, useEffect, useState } from 'react';
import Revenue from './Revenue';
import {
  BlastResponse,
  WorkflowResponse,
  AllWorkflowResponse,
} from 'components/DashboardReports/Types';

import {
  getBlastByStatus,
  getBlastMonetaryValue,
  getWorkflowReports,
  getAllWorkflows,
} from 'services/apiHandlers/Dashboard/Analytics';
import { Workflows, campaigns } from './Types';
import moment from 'moment';
import AnalyticsTable from './AnalyticsTable';
import ErrorBoundary from 'components/ErrorBoundary';
import { z } from 'zod';
import React from 'react';
import BreadcrumbsWrapper from 'components/Wrapper/BreadcrumbsWrapper';
const BlastValueSchema = z.object({
  monetaryValue: z.number().optional(),
  campaignName: z.string().optional(),
  conversions: z.number().optional(),
  id: z.number().optional(),
  date: z.string().optional(),
  uniqueConversion: z.string().optional(),
  eventDate: z.string().optional(),
});
type BlastValues = z.infer<typeof BlastValueSchema>;
const BlastMonetaryValueResponseDataSchema = z.object({
  blastId: z.number(),
  conversions: z.number(),
  monetaryValue: z.number(),
});
const BlastMonetaryValueResponseSchema = z.object({
  data: z.array(BlastMonetaryValueResponseDataSchema),
  status: z.number(),
});
type BlastMonetaryValueResponse = z.infer<
  typeof BlastMonetaryValueResponseSchema
>;
const Analytics: FC = () => {
  const [campaigns, setCampaigns] = useState<campaigns>([]);
  const [workflows, setWorkflows] = useState<Workflows>([]);
  const [orderDates, setOrderDates] = useState<Array<number>>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const getData = async () => {
    setLoading(true);
    try {
      let tempBlastValues: BlastValues[] = [];
      const getBlastResponse = (await getBlastByStatus(
        moment().subtract(7, 'days').format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD')
      )) as BlastResponse;
      const workflowReportResponse = (await getWorkflowReports(
        moment().subtract(7, 'days').format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD')
      )) as WorkflowResponse;
      const workflowResponse = (await getAllWorkflows()) as AllWorkflowResponse;
      if (workflowResponse.status === 200) {
        const tempTriggers = workflowResponse.data.triggers.map((data) => {
          return data.lastModified;
        });
        setOrderDates(tempTriggers);
      }
      if (getBlastResponse.status === 200) {
        tempBlastValues = getBlastResponse.data.collection.map((data) => ({
          id: data.blast.id,
          campaignName: data.blast.subject,
          date: moment(data.blast.created_at).format('MMM D'),
          eventDate: data.blast.created_at,
        }));
        const getBlastMonetaryValueResponse = (await getBlastMonetaryValue(
          tempBlastValues.map((data) => data.id).join(',')
        )) as BlastMonetaryValueResponse;
        if (getBlastMonetaryValueResponse.status === 200) {
          tempBlastValues = getBlastMonetaryValueResponse.data.map(
            (data, index) => ({
              monetaryValue: data.monetaryValue,
              campaignName: tempBlastValues[index].campaignName,
              date: tempBlastValues[index].date,
              conversions: data.conversions,
              eventDate: tempBlastValues[index].eventDate,
              id: tempBlastValues[index].id,
            })
          );
        }
        setCampaigns(tempBlastValues as campaigns);
      }
      if (workflowReportResponse.status === 200) {
        setWorkflows(workflowReportResponse.data.data);
      }
      
    } catch (error) {
      console.log('error is : ', error);
    }
    setLoading(false);
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <ErrorBoundary>
      <BreadcrumbsWrapper />
      <div className="bg-contentBg dark:bg-slate-900 font-inter py-7 md:px-6 px-3 pb-8">
        <div className="md:px-1.5">
          <div>
            <AudienceGrowth />
            <h2 className="sm:text-xl text-base sm:leading-5 leading-3 mt-8 text-black dark:text-white font-medium">
              Top Performing Strategies
            </h2>
            <AnalyticsTable campaigns={campaigns} workflows={workflows} loading={loading}/>
            <Revenue
              campaigns={campaigns}
              workflows={workflows}
              orderDates={orderDates}
              loading={loading}
            />
            
            {/* <EmailPerformance
              weeklyRoiData={weeklyRoiData}
              workflows={workflows}
              loading={loading} /> */}
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};
export default Analytics;
