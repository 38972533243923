import React,{ FC, useEffect, useState } from 'react';
import { EventConditionData, InitialEventConditionData } from '../index.type';
import Dropdown from 'components/Dropdown/Dropdown';
import SearchDropdown from 'components/Dropdown/SearchDropdown';
import { z } from 'zod';
import {
  getEventAttributes,
  getEventNames,
} from 'services/apiHandlers/Workflows/Workflow';
import WorkflowConditions from '../WorkflowConditions';
import DatePicker from 'components/Datepicker';
import { formatString } from 'utils/common';
import ModalWrapper from 'components/Workflow/ModalWrapper';
import { eventExtraOptions } from 'staticdata/EventLogs/Data';
import moment from 'moment';

interface Props {
  data: EventConditionData | undefined;
  onClose: () => void;
  onSave: (data: EventConditionData) => void;
}

interface Occurence {
  [key: string]: string;
  Exactly: string;
  GreaterThan: string;
  LessThan: string;
}

interface AttributeTypes {
  [key: string]: string[];
  insights_email_send: string[];
  insights_email_view: string[];
  insights_email_click: string[];
  ss_email_sent: string[];
  ss_added_to_list: string[];
  ss_unsubscribed: string[];
  ss_email_viewed: string[];
  ss_email_link_clicked: string[];
  ss_form_signup: string[];
}

const attributeType: AttributeTypes = {
  insights_email_send: [
    'actionDescription',
    'eventId',
    'messageId',
    'nodeId',
    'personIdentifier',
    'triggerId',
  ],
  insights_email_view: [
    'eventId',
    'personIdentifier',
    'messageId',
    'triggerId',
  ],
  insights_email_click: [
    'eventId',
    'personIdentifier',
    'messageId',
    'triggerId',
    'url',
  ],
  ss_email_sent: ['anonymousId', 'blast_id', 'owner_id', 'personIdentifier'],
  ss_added_to_list: [
    'anonymousId',
    'blast_id',
    'owner_id',
    'personIdentifier',
    'list_id',
  ],
  ss_unsubscribed: ['blast_id', 'personIdentifier'],
  ss_email_viewed: [
    'anonymousId',
    'blast_id',
    'ip',
    'ip_country',
    'ip_city',
    'owner_id',
    'personIdentifier',
    'referrer',
    'user_agent',
  ],
  ss_email_link_clicked: [
    'anonymousId',
    'blast_id',
    'hostname',
    'insightsTags',
    'ip',
    'ip_country',
    'ip_city',
    'owner_id',
    'path',
    'personIdentifier',
    'referrer',
    'referrerHostname',
    'title',
    'url',
    'userAgent',
  ],
  ss_form_signup: [
    'anonymousId',
    'hostname',
    'insightsTags',
    'ip',
    'ip_country',
    'ip_city',
    'path',
    'personIdentifier',
    'referrer',
    'referrerHostname',
    'title',
    'url',
    'userAgent',
  ],
};


const occurenceType: Occurence = {
  Exactly: 'Exactly',
  GreaterThan: 'Greater Than',
  LessThan: 'Less Than',
};
const responseSchema = z.object({
  status: z.number(),
  data: z.array(z.string()),
});
type Response = z.infer<typeof responseSchema>;

const frequencyOptions = ['Exactly', 'Greater Than', 'Less Than'];
const occurenceOptions = [
  'Anytime',
  'After',
  'Before',
  'Between',
  'Since entering this workflow',
  'Within the last...',
];

const EventCondition: FC<Props> = ({ data, onClose, onSave }) => {
  const [selectedFrequency, setSelectedFrequency] =
    useState<string>('Greater Than');
  const [selectedOccurence, setSelectedOccurence] = useState<string>('Anytime');
  const [frequencyTime, setFrequencyTime] = useState<number>(0);
  const [eventAttributes, setEventAttributes] = useState<Array<string>>();
  const [eventOptions, setEventOptions] = useState<Array<string>>([]);
  const [selectedEvent, setSelectedEvent] = useState<string>(data?.eventName || 'Select an item');
  const [selectedDate, setSelectedDate] = useState<string>(
    moment().format('MMMM D, YYYY')
  );
  const [startDate, setStartDate] = useState<string>(
    moment().format('MMMM D, YYYY')
  );
  const [endDate, setEndDate] = useState<string>(
    moment().format('MMMM D, YYYY')
  );
  const [frequencySeconds, setFrequencySeconds] = useState<number>(0);
  const [showOptions, setShowOptions] = useState<boolean>(false);
  const [conditions, setConditions] = useState<
    Array<InitialEventConditionData>
  >([]);
  const [formIsValid, setFormIsValid] = useState<boolean>(true);

  const getData = async () => {
    const response = (await getEventNames()) as Response;
    if (response.status === 200) {
      setEventOptions([...response.data, ...eventExtraOptions]);
    }
  };

  const getAttributes = async (name: string) => {
    const eventName = formatString(name);
    const response = (await getEventAttributes(eventName)) as Response;
    if (response.status === 200) {
      if (response.data.length > 0) {
        setEventAttributes(response.data);
      } else {
        setEventAttributes(attributeType[name]);
      }
    }
  };

  const saveDataHandler = () => {
    let valid = true;
    conditions.map((condition) => {
      if (condition.attributeName === 'Select an item') {
        valid = false;
      }
    });

    setFormIsValid(valid);

    if (valid) {
      const savedData = {
        _type: 'EventConditionGroup',
        eventName: selectedEvent,
        timestampCondition:
          selectedOccurence === 'After' || selectedOccurence === 'Before'
            ? {
                _type: selectedOccurence,
                date: moment(selectedDate, 'MMMM DD, YYYY').valueOf(),
              }
            : selectedOccurence === 'Between'
            ? {
                _type: selectedOccurence,
                startDate: moment(startDate, 'MMMM DD, YYYY').valueOf(),
                endDate: moment(endDate, 'MMMM DD, YYYY').valueOf(),
              }
            : selectedOccurence === 'Within the last...'
            ? {
                _type: 'WithinTheLast',
                seconds: frequencySeconds * 86400,
              }
            : selectedOccurence === 'Since entering this workflow'
            ? { _type: 'AfterTriggerExecutionTime' }
            : {
                _type: selectedOccurence,
              },
        frequency: {
          times: frequencyTime,
          _type: Object.keys(occurenceType).find(
            (key) => occurenceType[key] === selectedFrequency
          ) as string,
        },
        conditions: conditions,
        negated: data?.negated ? data?.negated : false,
      };
      onSave(savedData);
      onClose();
    }
  };
  useEffect(() => {
    getData();
    if (data) {
      setSelectedOccurence(data.timestampCondition._type);
      setSelectedFrequency(occurenceType[data.frequency._type]);
      setFrequencyTime(data.frequency.times ? data.frequency.times : 0);
      setSelectedEvent(data.eventName);
      setSelectedDate(
        moment(data.timestampCondition.date).format('MMMM D, YYYY')
      );
      setStartDate(
        moment(data.timestampCondition.startDate).format('MMMM D, YYYY')
      );
      setEndDate(
        moment(data.timestampCondition.endDate).format('MMMM D, YYYY')
      );
      setFrequencySeconds(
        data.timestampCondition.seconds
          ? data?.timestampCondition?.seconds / 86400
          : 0
      );
      setConditions(data.conditions);
      getAttributes(data.eventName);
    }
  }, []);

  useEffect(() => {
    getAttributes(selectedEvent);
  }, [selectedEvent]);

  const checkFrequencyValue = (value: string) => {
    if (value === 'After' || value === 'Before') {
      return value + ' ' + selectedDate;
    } else if (value === 'Between') {
      return 'Between ' + startDate + ' and ' + endDate;
    } else if (value === 'AfterTriggerExecutionTime') {
      return 'Since entering this workflow';
    } else if (value === 'WithinTheLast') {
      return 'Within the last...';
    } else {
      return value;
    }
  };

  return (
    <ModalWrapper
      onClose={onClose}
      disabled={!formIsValid || selectedEvent === 'Select an item'}
      onSave={saveDataHandler}
    >
      <div className="bg-white  dark:bg-[#41464E] ">
        <h2 className="sm:text-base text-sm font-semibold sm:leading-4 leading-3 text-[#495057] dark:text-[#CED4DA]  mb-6 dark:text-white">
          Condition
        </h2>
        <div className="z-50 bg-white dark:bg-[#41464E] rounded-lg dropdown-show-hide relative sm:[38px] h-9">
          <SearchDropdown
            options={eventOptions}
            value={selectedEvent}
            onSelect={(value) => {
              setConditions([]);
              setSelectedEvent(value);
            }}
          />
        </div>
        {selectedEvent !== 'Select an item' && (
          <>
            <div className="flex items-center gap-4 mt-4">
              <div className="w-full">
                <label className="text-sm font-medium leading-3 tracking-wide text-[#212529] dark:text-white ">
                  Event Frequency
                </label>
                <div className="sm:flex items-center">
                  <div className="z-40 bg-white  dark:bg-[#41464E]  rounded-md dropdown-show-hide relative sm:[38px] h-9 sm:w-1/2">
                    <Dropdown
                      options={frequencyOptions}
                      value={selectedFrequency}
                      onSelect={(value) => setSelectedFrequency(value)}
                    />
                  </div>
                  <div className="flex items-center sm:ml-2.5 sm:mt-0 mt-2.5 sm:w-1/2 w-full">
                    <input
                      className="w-full px-4 py-2 text-13 leading-3  dark:bg-[#41464E]  border border-[#ced4da] dark:border-[#fff] rounded text-[#212529] dark:text-white sm:h-[38px]"
                      type="number"
                      placeholder="0"
                      value={frequencyTime}
                      onChange={(event) =>
                        setFrequencyTime(parseInt(event.target.value))
                      }
                      min={0}
                    />
                    <span className="text-13 font-normal leading-4 text-gray-700 ml-2.5 sm:w-auto dark:text-white">
                      time(s)
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full mt-4">
              <label className="text-sm font-medium leading-3 tracking-wide text-[#212529] dark:text-white ">
                Event Occurrence
              </label>
              <div
                onClick={(e) => e.stopPropagation()}
                className="z-30  dark:bg-slate-8 dropdown-show-hide relative sm:[38px] h-9"
              >
                <Dropdown
                  options={occurenceOptions}
                  value={checkFrequencyValue(selectedOccurence)}
                  onSelect={(value) => {
                    setShowOptions(true);
                    setSelectedOccurence(value);
                  }}
                />
              </div>
              {showOptions && (
                <>
                  <div
                    onClick={(e) => e.stopPropagation()}
                    className="flex items-center cursor-pointer text-sm font-normal leading-6 bg-white dark:bg-[#41464E] rounded mt-6"
                  >
                    {selectedOccurence === 'After' ||
                    selectedOccurence === 'Before' ? (
                      <>
                        <p className="text-13 font-normal leading-4 text-[#495057] dark:text-[#CED4DA]  mr-3 dark:text-white">
                          {selectedOccurence}
                        </p>
                        <div className="relative w-1/2 flex items-center text-[#495057] dark:text-[#CED4DA]  bg-white  dark:bg-[#41464E]  rounded py-1.5 px-3 sm:[38px] h-9">
                          <DatePicker
                            date={selectedDate}
                            onDateChange={(date) => {
                              date === ''
                                ? setSelectedDate('')
                                : setSelectedDate(
                                    moment(date).format('MMMM D, YYYY')
                                  );
                            }}
                          />
                        </div>
                      </>
                    ) : selectedOccurence === 'Between' ? (
                      <>
                        <div className="relative sm:w-1/2 bg-white  dark:bg-[#41464E] py-1.5 ">
                          <DatePicker
                            date={startDate}
                            onDateChange={(date) => {
                              date === ''
                                ? setStartDate(date)
                                : setStartDate(
                                    moment(date).format('MMMM D, YYYY')
                                  );
                            }}
                          />
                        </div>
                        <p className="text-xs font-normal leading-5 text-primary text-center my-1.5 mx-3 dark:text-white">
                          through
                        </p>
                        <div className="relative sm:w-1/2 w-full bg-white  dark:bg-[#41464E] py-1.5">
                          <DatePicker
                            date={endDate}
                            onDateChange={(date) => {
                              date === ''
                                ? setEndDate(date)
                                : setEndDate(
                                    moment(date).format('MMMM D, YYYY')
                                  );
                            }}
                          />
                        </div>
                      </>
                    ) : selectedOccurence === 'Within the last...' ? (
                      <div className="flex items-center text-[#495057] dark:text-[#CED4DA]  bg-white dark:bg-[#41464E] rounded-b w-full">
                        <p className="text-13 font-normal leading-4 text-[#495057] dark:text-[#CED4DA]  w-3/12 dark:text-white">
                          Within the last
                        </p>
                        <div className="w-10/12 flex items-center">
                          <input
                            type="number"
                            value={frequencySeconds}
                            onChange={(e) =>
                              setFrequencySeconds(parseInt(e.target.value))
                            }
                            className="text-13 font-medium placeholder:text-gray-500 leading-3 text-gray-700 py-2 rounded px-4 bg-white border border-gray-600 md:w-9/12  dark:bg-[#41464E]  dark:text-white dark:placeholder:text-white"
                          />
                          <div className="text-13 font-normal leading-4 text-gray-700 ml-2.5 dark:text-white">
                            days(s)
                          </div>
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              )}
            </div>
            <WorkflowConditions
              conditions={conditions}
              onChange={(conditions) => {
                setConditions(conditions);
              }}
              isValid={formIsValid}
              attributes={eventAttributes}
              onValidForm={(valid) => setFormIsValid(valid)}
            />
          </>
        )}
      </div>
    </ModalWrapper>
  );
};
export default EventCondition;
