import EditBlue from 'assets/Images/Common/edit-blue.png';
import Check from 'assets/Images/Filter/check-icon.png';
import { useEffect, useState } from 'react';
import {
  getFilterById,
  getFilterCount,
} from 'services/apiHandlers/List-Segments/Filter';
import ErrorBoundary from 'components/ErrorBoundary';
import { z } from 'zod';
import { newRuleDataSchema,GetFilterResponse,FilterData } from './Types';
import { Link, useParams } from 'react-router-dom';
import { listSegments, filters, matches } from 'services/constant/routes';
import moment from 'moment';
import React from 'react';
import BreadcrumbsWrapper from 'components/Wrapper/BreadcrumbsWrapper';
import ConditionBlocks from './ConditionBlocks';
import Wrapper from 'components/Wrapper';


const RuleSchema = z.object({
  comparison_operator: z.string(),
  comparison_value: z.string(),
});

const RulesSchema = z.record(RuleSchema);

const getCountFilterResponseSchema = z.object({
  data: z.number(),
  status: z.number(),
});


type GetCountFilterResponse = z.infer<typeof getCountFilterResponseSchema>;
type EditRule = z.infer<typeof RulesSchema>;
type RuleSchema = z.infer<typeof newRuleDataSchema>

const ViewFilter = () => {
  const [filtersData, setFiltersData] = useState<FilterData>();
  const [filterCount, setFilterCount] = useState<number>();
  const { id } = useParams();
  const filterId = parseInt(id || '');

  const getFilterData = async () => {
    try {
      const getFiltersResponse = (await getFilterById(
        filterId.toString()
      )) as GetFilterResponse;
      if (getFiltersResponse.status === 200) {
        getRulesData(getFiltersResponse.data)
        setFiltersData(getFiltersResponse.data);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
  };

  const getRulesData = (data: FilterData) => {

    const filteredRules: RuleSchema[] | RuleSchema = [];

    if (Array.isArray(data.filter.blocks.block)) {
      data.filter.blocks.block.map((segment) => {
        if(Array.isArray(segment.rules.rule)){
          segment.rules.rule.map((rule)=>{
            filteredRules.push(rule)
          })
        } else {
          filteredRules.push(segment.rules.rule)
        }
      });
    } else {
      if(Array.isArray(data.filter.blocks.block.rules.rule)){
        data.filter.blocks.block.rules.rule.map((rule)=>{
          filteredRules.push(rule)
        })
      } else {
        filteredRules.push(data.filter.blocks.block.rules.rule)
      }
    }
    const allRules = filteredRules.flat();

    const rules: EditRule = {};

    allRules.map((item: RuleSchema) => {
      const ruleId = item.id.toString();

      rules[ruleId] = {
        comparison_operator: item.comparison_operators.selected,
        comparison_value: item.value,
      };
    });
    return rules;
  };

  const getCountHandler = async () => {
    if (filtersData) {
      try {
        const getCountFilterResponse = (await getFilterCount(
          filterId,
          filtersData.restored ? 'draft' : 'final',
          { rules: getRulesData(filtersData) }
        )) as GetCountFilterResponse;
        if (getCountFilterResponse.status === 200) {
          setFilterCount(getCountFilterResponse.data);
        }
      } catch (error) {
        console.log('error is : ', error);
      }
    }
  };

  useEffect(() => {
    getFilterData();
  }, []);
  return (
    <ErrorBoundary>
      <BreadcrumbsWrapper />
      <div className="md:px-6 px-3 py-5 font-inter h-screen  ">
        <Wrapper childClass='p-4'>
        <div className="">
          <div className="flex justify-between items-center mb-4">
            <>
              <h2 className="sm:text-xl text-base sm:leading-5 leading-3 text-black dark:text-white font-medium">
                {filtersData?.filter.name}
              </h2>
              <p className="text-sm font-normal leading-4 text-[#495057] dark:text-[#CED4DA] mt-0.5">
                created about
                <span className="ml-1">
                  {moment
                    .duration(
                      moment().diff(
                        moment
                          .utc(filtersData?.filter.created_at)
                          .local()
                          .format('YYYY-MM-DD HH:mm:ss ')
                      )
                    )
                    .humanize()}
                </span>
                ago
              </p>
            </>
            <div>
              {(Number.isInteger(filterCount)|| filterCount) ? (
                <div>{filterCount}</div>
              ) : (
                <div className="flex sm:justify-center justify-end items-center sm:w-4/12 w-6/12">
                  <span className="w-5 h-5 rounded-full">
                    <img className="w-full" src={Check} alt="Check" />
                  </span>
                  <button
                    type='button'
                    onClick={getCountHandler}
                    className="text-base font-medium leading-5 text-black-700 dark:text-white ml-2"
                  >
                    Count?
                  </button>
                </div>
              )}
              <Link
                to={`${listSegments}/${filters}/${matches}/${filterId}`}
                className="cursor-pointer"
              >
                View Checks
              </Link>
            </div>
          </div>
          <>
            <ConditionBlocks filtersData={filtersData} />

            <div className="text-right mt-2">
              <Link
                to={`${listSegments}/${filters}/${filterId}`}
                className="text-sm font-medium leading-5 p-2 text-primary inline-block flex items-center sm:ml-auto dark:text-white"
                type="button"
              >
                <img
                  className="mr-1 dark:invert dark:brightness-0"
                  src={EditBlue}
                  alt="Edit"
                />
                Edit Filter
              </Link>
            </div>
          </>
        </div>
        </Wrapper>
      </div>
    </ErrorBoundary >
  );
};
export default ViewFilter;
