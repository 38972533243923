import { FC } from 'react';
import { AverageOrder } from 'staticdata/Dashboard/Data';
import StoreReport from 'components/DashboardReports/Body/Store-Report/Report';
import { storeReportOrder } from 'components/DashboardReports/Body/Store-Report/Types';
import React from 'react';

interface Props {
  averageRevenue: storeReportOrder | undefined;
  loading: boolean;
}

const AverageRevenue: FC<Props> = ({ averageRevenue, loading }) => {
  return (
    <div>
      <StoreReport
        data={averageRevenue ? averageRevenue : AverageOrder}
        loading={!averageRevenue ? true : loading}
      />
    </div>
  );
};
export default AverageRevenue;
