// import Tabs from 'components/Tabs/Tabs';
import { Link, createSearchParams, useSearchParams } from 'react-router-dom';
import { useEffect } from 'react';
import Body from 'components/Campaigns/Body';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
// import Overview from 'assets/Images/Dashboard/overview.png';
// import Mail from 'assets/Images/svg-images/mail.svg';
import ErrorBoundary from 'components/ErrorBoundary';
import React from 'react';
import { campaigns, create } from 'services/constant/routes';
import Plus from 'assets/Images/svg-images/plus.svg';


// const TabsData = [
//   { title: 'Overview', src: Overview, searchQuery: 'overview' },
//   // { title: 'campaign Emails', src: Mail, searchQuery: 'campaign_emails' },
// ];

enum TabsEnum {
  OVERVIEW = 'overview',
  EMAILS = 'campaign_emails',
}
const Campaigns = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  // const onTabChangeHandler = (tab: string) => {
  //   setSearchParams(createSearchParams({ tab: tab }));
  // };
  //TODO: Need Discussion
  useEffect(() => {
    if (!searchParams.get('tab')) {
      setSearchParams(createSearchParams({ tab: TabsEnum.OVERVIEW }));
    }
  }, [searchParams]);
  const activeTab = searchParams.get('tab') || TabsEnum.OVERVIEW;
  return (
    <ErrorBoundary>
      <div>
        <div className="bg-white dark:bg-transparent md:px-6 px-3 py-2 font-inter border-b shadow-0 shadow flex justify-between items-center dark:border-[#fff]">
          <div className="md:px-1.5">
            <div >
              <Breadcrumbs />
            </div>
          </div>
          <div>
          <Link
            to={`${campaigns}/${create}`}
            className="sm:mt-0 mt-5 lg:relative right-0 lg:w-full"
          >
            <button className="sm:text-sm text-xs font-medium  text-white flex items-center ml-auto rounded-md py-3 sm:pl-4 px-2 sm:pr-3.5 font-inter bg-primary ease-in-in duration-300 hover:bg-primary hover:scale-105">
              <img className="sm:mr-3 mr-1" src={Plus} alt="Plus" />
              Create Campaign
            </button>
          </Link>
          </div>
        </div>
        <Body activeTab={activeTab} />
      </div>
    </ErrorBoundary>
  );
};
export default Campaigns;
