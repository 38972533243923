import { useFormik } from 'formik';
import React, { useContext, useEffect } from 'react';
import { CampaignContext } from 'store/CampaignContext';

export const excludedValues = ['-', '+', 'e','E','.']


const ScheduleTime = () => {
  const campaignCtx = useContext(CampaignContext);

  const formik = useFormik({
    initialValues: { hour: (campaignCtx?.campaignData?.schedule_later && campaignCtx?.campaignData?.schedule_later['hour']) || '15', minute: (campaignCtx?.campaignData?.schedule_later && campaignCtx?.campaignData?.schedule_later['minute']) || '0' },
    onSubmit: () => console.log(),
  });

  useEffect(() => {
    if (campaignCtx?.campaignData?.schedule_later && campaignCtx.campaignData) {
      const tempCampaignData = {
        ...campaignCtx.campaignData,
        schedule_later: {
          ...campaignCtx.campaignData.schedule_later,
          hour: formik.values.hour.toString(),
          minute: formik.values.minute.toString(),
        },
      };
      campaignCtx?.onUpdateCampaignData(tempCampaignData);
    }
  }, [formik.values]);


  return (
    <div className="">
      <h3 className="text-sm font-medium leading-3 tracking-wide text-[#212529] dark:text-white mb-2">
        At what time?
      </h3>
      <div>
        <div className="flex flex-wrap items-center">
          <input
            className=" h-[38px] focus:border-primary dark:focus:border-white focus:outline-none py-2 px-4 text-13 font-medium leading-3 border border-[#ced4da] placeholder:text-gray-500 dark:placeholder:text-white dark:border-[#fff]  dark:bg-[#41464E]  placeholder:font-normal rounded text-[#495057] dark:text-[#CED4DA]  dark:text-white"
            type="number"
            name="hour"
            placeholder="00"
            max={23}
            min={0}
            value={formik.values.hour}
            onKeyDown={(e) => {
              if (excludedValues.includes(e.key)) {
                e.preventDefault()
              }
            }}
            onChange={(e) => {
              if (e.target.value.length < 3 && parseInt(e.target.value) < 24 && parseInt(e.target.value) >= 0 || e.target.value === '') {
                formik.handleChange(e);
              }
            }}
          />
          <p className="text-sm mt-4 mx-3 dark:text-white">:</p>
          <input
            className=" h-[38px] focus:border-primary dark:focus:border-white focus:outline-none py-2 px-4 text-13 font-medium leading-3 border border-[#ced4da] placeholder:text-gray-500 dark:placeholder:text-white dark:border-[#fff]  dark:bg-[#41464E]  placeholder:font-normal rounded text-[#495057] dark:text-[#CED4DA]  dark:text-white"
            type="number"
            name="minute"
            placeholder="00"
            max={60}
            min={0}
            value={formik.values.minute}
            onKeyDown={(e) => {
              if (excludedValues.includes(e.key)) {
                e.preventDefault()
              }
            }}
            onChange={(e) => {
              if (e.target.value.length < 3 && parseInt(e.target.value) < 60 && parseInt(e.target.value) >= 0 || e.target.value === '') {
                formik.handleChange(e);
              }
            }}
          />
        </div>
        <p className="text-sm font-medium text-[#495057] dark:text-white mb-1 mt-4 ">
          America-Los Angeles
          <span className="text-gray-500 dark:text-white">(GMT-07:00)</span>
        </p>
        <p className="text-sm leading-4 text-gray-500 dark:text-white mt-2">
          People added after this time on the scheduled date will not receive
          this message.
        </p>
      </div>
    </div>
  );
};
export default ScheduleTime;
