import Edit from 'assets/Images/ListsegmentImages/edit-table-active.svg';
import SubscriberEvents from './SubscriberEvents';
import React, { FC, useEffect, useState } from 'react';
import xmlToJSON from 'utils/xmlToJSON';
import {
  getSubscriberById,
  getSubscriberMembershipById,
} from 'services/apiHandlers/List-Segments/Subscriber';
import { formatXmlJsonResponse } from 'utils/common';
import { getFields } from 'services/apiHandlers/List-Segments/Field';
import { Form } from './SubscribersForm';
import { getAllList } from 'services/apiHandlers/List-Segments/SegmentsList';
import { ListSchema } from 'components/List-Segments/Body/List/Types';
import { z } from 'zod';
import Skeleton from 'components/Skeleton/Skeleton';
import SubscriberTags from './SubscriberTags';
import { Link, useParams } from 'react-router-dom';
import { edit, listId, subscribersProfile } from 'services/constant/routes';
import Wrapper from 'components/Wrapper';

const ListResponseSchema = z.object({
  data: z.object({ lists: z.array(ListSchema) }),
  status: z.number(),
});
export type ListResponse = z.infer<typeof ListResponseSchema>;
type List = z.infer<typeof ListSchema>;

const loadingSchema = z.object({ fields: z.boolean(), lists: z.boolean() });
type Loading = z.infer<typeof loadingSchema>;

const ViewSubscriber: FC = () => {
  const [viewSubscriberData, setViewSubscriberData] = useState<Record<string, string>>();
  const [fields, setFields] = useState<Array<Form>>([]);
  const [filteredList, setFilteredList] = useState<Array<List>>([]);
  const [eventDetails, setEventDetails] = useState<string>('');

  const [loading, setLoading] = useState<Loading>({
    fields: true,
    lists: true,
  });
  const { id } = useParams();

  const getLists = async () => {
    try {
      const getListsResponse = (await getAllList()) as ListResponse;
      if (getListsResponse.status === 200) {
        setLoading({ ...loading, lists: false });
        return getListsResponse?.data?.lists;
      }
    } catch (error) {
      console.log('Check error is 1: ', error);

      setLoading({ fields: false, lists: false });
      return [];
    }
    setLoading({ ...loading, lists: false });

  };

  const getSubscriberData = async () => {
    setLoading({ ...loading, fields: true });
    try {
      if (id) {
        /* eslint-disable */
        const getSubscriberDataresponse = await getSubscriberById(parseInt(id));
        const subscribedListResponse = await getSubscriberMembershipById(
          parseInt(id)
        );
        const subscribedListJson: any = xmlToJSON.parseString(
          subscribedListResponse.replace(
            '<?xml version="1.0" encoding="UTF-8"?>',
            ''
          )
        );

        const allLists = await getLists();

        const getFieldsResponse: any = await getFields();
        const data: any = xmlToJSON.parseString(getFieldsResponse.data);
        const fields = formatXmlJsonResponse(data?.fields[0]?.field);

        const formatedSubscribedList = formatXmlJsonResponse(
          subscribedListJson.memberships[0].membership
        );
        const preSelectLists = formatedSubscribedList.map((list: any) =>
          Number(list['list-id'])
        );

        const filteredList = allLists?.filter((list) =>
          preSelectLists.includes(list.id)
        );

        setFilteredList(filteredList || []);

        setViewSubscriberData(getSubscriberDataresponse);
        setFields(fields as any);
        /* eslint-enable  */
      }
    } catch (error) {
      console.log('Check error is : ', error);
      setLoading({ fields: false, lists: false });
    }
    setLoading({ ...loading, fields: false });
  };

  useEffect(() => {
    getSubscriberData();
  }, []);


  return (
    <>
      <form action="#/" className="mt-6 px-6">
        <Wrapper>
          <>
            <div className='flex justify-between items-center px-5 border-b dark:border-[#fff] border-[#e9ebec] py-[18px]'>
              <h2 className="text-base text-[#495057] font-medium  dark:text-[#CED4DA]">
                View Subscriber
              </h2>
            </div>
            <div className="md:flex">

              <div className="md:w-7/12 w-full ">


                <div className="p-4">

                  <div className=" gap-8">

                    {loading.fields ? (
                      <Skeleton columns={10} />
                    ) : (
                      <Wrapper parentClass='!mt-0'>
                        <>
                          <div className="flex items-center p-4 pb-2 ">
                            <h2 className="text-lg text-[#495057] dark:text-[#CED4DA] font-medium">
                              {viewSubscriberData && viewSubscriberData['email_address']}
                            </h2>
                            <div className="">
                              <Link
                                to={`/${subscribersProfile}/${edit}/${id}`}
                                className="ml-2 hover:text-[#3575d5] dark:text-white"
                              >
                                <img
                                  src={Edit}
                                  alt="Edit"
                                  className="inline mr-1.5 h-4 w-4 dark:invert dark:brightness-0"
                                />
                              </Link>
                            </div>
                          </div>
                          <div className="w-full flex items-center flex-wrap">
                            {fields
                              .slice(0, Math.ceil(fields.length / 2))
                              .map((field, index) => (
                                <div
                                  className={`${index > 0 ? '' : ''}  mb-2 w-1/2 flex justify-between rounded py-2 px-4`}
                                  key={field.id}
                                >
                                  <label className="text-sm font-medium text-[#212529] tracking-wide dark:text-[#CED4DA]  block">
                                    {field.name}
                                  </label>
                                  <p className="text-[13px] text-[#878A99] dark:text-[#CED4DA]">
                                    {viewSubscriberData &&
                                      viewSubscriberData[field.slug]}
                                  </p>
                                </div>
                              ))}
                          </div>
                          <div className="w-full flex items-center flex-wrap">
                            {fields
                              .slice(Math.ceil(fields.length / 2), fields.length)
                              .map((field, index) => (
                                <div
                                  className={`${index > 0 ? '' : ''} mb-2 w-1/2 flex justify-between rounded py-2 px-4`}
                                  key={field.id}
                                >
                                  <label className="text-sm font-medium text-[#212529]  tracking-wide block dark:text-[#CED4DA]">
                                    {field.name}
                                  </label>
                                  <p className="text-[13px] text-[#878A99] ">
                                    {viewSubscriberData &&
                                      !Array.isArray(viewSubscriberData[field.slug])
                                      ? viewSubscriberData[field.slug]
                                      : viewSubscriberData &&
                                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                      // @ts-ignore
                                      viewSubscriberData[field.slug].join(' , ')}
                                  </p>
                                </div>
                              ))}
                          </div>
                        </>
                      </Wrapper>
                    )}
                  </div>
                </div>
                <div className="md:px-6 px-3 pt-4 pb-6 ">
                  <label className="dark:text-[#CED4DA] text-xs font-semibold leading-3 text-[#9e9e9e] tracking-wide uppercase mb-2 block">
                    E-mail Format
                  </label>
                  {loading.fields ?
                    (<p>---</p>) :
                    (<p className="text-[13px] font-medium leading-[18px] text-616161 dark:text-[#CED4DA]">
                      <span className="font-bold">
                        {viewSubscriberData?.email_content_format.toUpperCase()}{' '}
                      </span>
                      format messages
                    </p>)}
                </div>
                <div className="md:px-6 px-3 py-4 mt-4">
                  <div className="">
                    <label className="dark:text-[#CED4DA] text-xs font-semibold leading-3 text-[#9e9e9e] tracking-wide uppercase mb-3 block">
                      List Membership
                    </label>
                    {loading.fields ? (
                      <Skeleton columns={4} />
                    ) : filteredList?.length === 0 ? (
                      <p className="text-[13px] font-medium leading-[18px] text-616161 dark:text-[#CED4DA]">
                        No List Found
                      </p>
                    ) : (
                      <>
                        {filteredList?.map((list) => (
                          <Link
                            key={list.id}
                            className="text-[13px] font-medium leading-[18px] text-616161 dark:text-[#CED4DA]"
                            to={`/${subscribersProfile}?${listId}=${list.id.toString()}`}
                          >
                            <div className="text-ryzeoBlue underline">
                              {list.name}
                            </div>
                          </Link>
                        ))}
                      </>
                    )}
                  </div>
                </div>
                <>
                  <SubscriberTags
                    email={
                      viewSubscriberData
                        ? viewSubscriberData['email_address']
                        : ''
                    }
                  />
                </>
              </div>

              <div className="md:w-5/12 w-full md:px-4 ">
                <SubscriberEvents
                  email={viewSubscriberData ? viewSubscriberData['email_address'] : ''}
                  eventDetails={eventDetails}
                  setEventDetails={setEventDetails}
                />
              </div>
            </div>
          </>
        </Wrapper>
      </form>
    </>
  );
};
export default ViewSubscriber;
