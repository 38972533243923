import * as Yup from 'yup';
import { useFormik } from 'formik';
import { z } from 'zod';
import ErrorBoundary from 'components/ErrorBoundary';
import { createFilter } from 'services/apiHandlers/List-Segments/Filter';
import { Toastify } from 'App';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { filters, listSegments } from 'services/constant/routes';
import Wrapper from 'components/Wrapper';
import BreadcrumbsWrapper from 'components/Wrapper/BreadcrumbsWrapper';

const createFilterResponseSchema = z.object({
  data: z.object({ id: z.number() }),
  status: z.number(),
});

type CreateFilterResponse = z.infer<typeof createFilterResponseSchema>;

const AddFilterForm = () => {
  const navigate = useNavigate();
  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Name Cannot Be Empty'),
    }),
    onSubmit: async (values) => {
      try {
        const createFilterResponse = (await createFilter({
          name: values.name,
          description: values.description,
        })) as CreateFilterResponse;
        if (createFilterResponse.status === 200) {
          Toastify('Filter Created Sucessfully ', 'success', 'create1');
          navigate(
            `${listSegments}/${filters}/${createFilterResponse.data.id}`
          );
        }
      } catch (error) {
        Toastify('Filter Creation Failed', 'error', 'create2');
        console.log('error is : ', error);
      }
    },
  });

  return (
    <ErrorBoundary>
      <BreadcrumbsWrapper />
      <form onSubmit={formik.handleSubmit} className="px-6 min-h-[74vh]">
        <Wrapper>
          <>
          <h2 className="p-4 border-b text-base font-medium leading-5 text-[#495057] dark:text-white">
            Create New Filter
          </h2>
          <div className="p-4">
            <div className="flex justify-between items-center">
              <div>
                <p className="text-sm mb-3 font-normal leading-5 text-[#878a99] dark:text-white">
                  Once you name your new filter, you can start adding rules.
                </p>
              </div>
            </div>
            <div>
              <div>
                <label className="text-xs font-semibold leading-3 tracking-wide uppercase text-[#495057] dark:text-[#CED4DA]  dark:text-white dark:text-white inline-block inline-block mb-3">
                  Name
                </label>
                <input
                  className="w-4/12 block p-4 text-13 font-medium leading-3  dark:bg-[#41464E]  border border-[#ced4da] dark:border-[#fff] rounded-md text-[#495057] dark:text-[#CED4DA]  dark:text-white sm:[38px] h-9 focus:outline-none"
                  type="text"
                  name="name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                />
                {formik.errors.name ? (
                  <div
                    data-pw="filter-required"
                    className="text-xs leading-4 text-red-400 mt-1.5"
                  >
                    {formik.errors.name}
                  </div>
                ) : null}
              </div>
              <div className="mt-4">
                <label className="text-xs font-semibold leading-3 tracking-wide uppercase text-[#495057] dark:text-[#CED4DA]  dark:text-white dark:text-white inline-block inline-block mb-3">
                  Description
                </label>
                <input
                  className="w-4/12 block p-4 text-13 font-medium leading-3  dark:bg-[#41464E]  border border-[#ced4da] dark:border-[#fff] rounded-md text-[#495057] dark:text-[#CED4DA]  dark:text-white sm:[38px] h-9 focus:outline-none"
                  type="text"
                  name="description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                />
              </div>
              <div className="flex items-center justify-end pt-4">
                <Link
                  to={`${listSegments}/${filters}`}
                  className="text-center sm:text-13 text-xs py-2.5 px-4 h-[38px] flex justify-center items-center w-28 font-medium leading-5 mr-2  rounded-md bg-[#f3f6f9] hover:bg-[#cfd1d4] transition-bg text-[#212529]"
                >
                  Cancel
                </Link>
                <button
                  type="submit"
                  className="sm:text-13 text-xs font-medium leading-5 text-white py-2.5 px-4 h-[38px] w-28 rounded-md bg-primary "
                >
                  Create
                </button>
              </div>
            </div>
          </div>
          </>
        </Wrapper>
      </form>
    </ErrorBoundary>
  );
};
export default AddFilterForm;
