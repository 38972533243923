import React, { FC, useEffect, useState } from 'react';
import ReactDOM from 'react-dom';


interface Props {
  isOpen: boolean | undefined;
  children: JSX.Element;
}

const AuthModal: FC<Props> = ({ isOpen, children}) => {
  const [portalNode, setPortalNode] = useState<Element | null>(null);
  
  useEffect(() => {
    const node = document.getElementById('modal');
    if (node) {
      setPortalNode(node);
    }
  }, []);

  useEffect(() => {
   if(isOpen){
    document.body.style.overflow = 'hidden';
   }
   else{
    document.body.style.overflow = 'unset';
   }
  },[isOpen])

  if (!portalNode || !isOpen) return null;
   
  return ReactDOM.createPortal(
    <div>
        <div className="fixed top-0 left-0 w-full h-full backdrop-blur-sm bg-black-200 z-[99999]">
        {children} 
      </div>
    </div>,
    portalNode
  );
};
export default AuthModal;
