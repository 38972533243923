import { useFormik } from 'formik';
import { useContext, useEffect } from 'react';
import { CampaignContext } from 'store/CampaignContext';
import * as Yup from 'yup';
import React from "react"

const Reply = () => {
  const campaignCtx = useContext(CampaignContext);

  const formik = useFormik({
    initialValues: { reply_to_name: '', reply_to_email_address: '' },
    validationSchema: Yup.object({
      email: Yup.string().email('Email is invalid'),
    }),
    onSubmit: () => {
      console.log();
    },
  });

  useEffect(() => {
    if (campaignCtx?.senderActivatedData) {
      formik.setValues(campaignCtx.senderActivatedData);
    }
  }, [campaignCtx?.senderActivatedData]);

  useEffect(() => {
    if (campaignCtx?.campaignData) {
      campaignCtx?.onUpdateCampaignData({
        ...campaignCtx.campaignData,
        ...formik.values,
      });
    }
  }, [formik.values]);

  return (
    <div className='relative mt-4'>
     <div>
        <h3 className=" text-base  leading-5 text-[#495057] font-medium dark:text-white">
          Reply to
        </h3>
        <div className=''>
          <input
            className="w-full focus:border-primary dark:focus:border-white focus:outline-none py-2 px-4 text-13  leading-3 border border-[#ced4da] placeholder:text-gray-500 dark:placeholder:text-white dark:border-[#fff]  dark:bg-[#41464E]  rounded my-4 text-[#212529] dark:text-white placeholder:font-normal"
            type="text"
            name="reply_to_name"
            placeholder="Full Name"
            value={formik.values.reply_to_name}
            onChange={formik.handleChange}
          />
          <input
            className="w-full focus:border-primary dark:focus:border-white focus:outline-none py-2 px-4 text-13 leading-3 border border-[#ced4da] placeholder:text-gray-500 dark:placeholder:text-white dark:border-[#fff]  dark:bg-[#41464E]  rounded text-[#212529] dark:text-white placeholder:font-normal"
            type="email"
            name="reply_to_email_address"
            placeholder="Email Address"
            value={formik.values.reply_to_email_address}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </div>
      </div>
    </div>
  );
};
export default Reply;
