import React, { useState, FC } from 'react';
import ExportModal from './ExportModal';
import Wrapper from 'components/Wrapper';
import CustomSkeleton from 'components/Skeleton/Skeleton';
import { CardData } from '.';
import Modal from 'components/Alert/Modal';
import { z } from 'zod';
import AddListModal from './AddListModal';
import Tippy from '@tippyjs/react';

const getListResponseDataSchema = z.object({
  id: z.number(),
  name: z.string(),
  audience_id: z.number(),
});
type Lists = z.infer<typeof getListResponseDataSchema>;

const fieldsSchema = z.object({
  ['allow-other']: z.boolean(),
  ['data-type']: z.string(),
  id: z.number(),
  ['include-blank']: z.boolean(),
  name: z.string(),
  slug: z.string(),
  'used-in-filter': z.string(),
});

export type Fields = z.infer<typeof fieldsSchema>;

interface Props {
  card: CardData;
  loading: boolean;
  fields: Fields[];
  lists: Lists[];
}
const Card: FC<Props> = ({ card, loading, fields, lists }) => {
  const [showExportModal, setShowExportModal] = useState<boolean>(false);
  const [showListModal, setShowListModal] = useState<boolean>(false);
  return (
    <div className="sm:w-auto w-full sm:max-w-none  sm:mx-0 mx-auto shadow-lg hover:shadow-xl dark:shadow-lg dark:bg-[#41464E] dark:hover:shadow-xl">
      <Wrapper childClass="p-4 h-[167px] flex flex-col justify-between">
        {loading ? (
          <CustomSkeleton />
        ) : (
          <>
            <div className="flex justify-between">
              <Tippy interactive={true} content={card.tooltipContent} disabled={card.name==="Converted"}>
              <p className="cursor-pointer text-base font-medium leading-4 text-headingColor tracking-wide mb-2.5 text-gray-700 dark:text-white break-all">
                {card.name}
              </p>
              </Tippy>
              {card?.export &&
                (card.export === 'show' ? (
                  <button
                    onClick={() => setShowExportModal(true)}
                    className="ml-8 underline cursor-pointer text-sm font-sm leading-4  tracking-wide mb-2.5 text-blue-400 dark:text-white "
                  >
                    Export
                  </button>
                ) : (
                  ''
                  // <div className=''> No data</div>
                ))}
            </div>

            <Modal isOpen={showExportModal || showListModal}>
              {showExportModal ? (
                <ExportModal
                  onClose={() => setShowExportModal(false)}
                  fields={fields}
                />
              ) : showListModal ? (
                <AddListModal
                  onClose={() => setShowListModal(false)}
                  lists={lists}
                />
              ) : (
                <></>
              )}
            </Modal>

            <h2 className="lg:text-28 text-lg leading-10 font-medium text-black-300 dark:text-[#CED4DA]">
              {card.value || '-'}
            </h2>
            {card.calculation && card.comparisonProperty && (
              <div className="flex items-center mt-4 justify-between">
                <span className="text-green-400 text-xs font-gilroySemibold leading-4 ml-1 ">
                  <span className="text-[#40BB82] rounded px-1 mr-1 bg-[#E2F5EC]">
                    {card.calculation || '-'}
                  </span>
                  <span className="text-[#878A99]">
                    of {card.comparisonProperty}
                  </span>
                </span>
                {card?.export && card.export==="show"&& (
                  <span
                    onClick={() => setShowListModal(true)}
                    className="ml-7 cursor-pointer text-sm underline text-blue-400"
                  >
                    Add to list
                  </span>
                )}
              </div>
            )}
          </>
        )}
      </Wrapper>
    </div>
  );
};
export default Card;
