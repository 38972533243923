import { CampaignItemData } from "components/Dashboard/index.type";
import { calculateRate } from "./common";
import moment from "moment";
import { WorkflowType } from 'components/Workflow/Body/Workflows/index.type';

export const getPreviousSixMonthsCampaignData = (campaignData: CampaignItemData[]) => {

    const previousSixMonths: string[] = [];
    for (let i = 5; i >= 0; i--) {
        const month = moment().subtract(i, 'months').format('MMMM');
        previousSixMonths.push(month);
    }

    const tempPreviousSixMonthsData: CampaignItemData[] = campaignData.filter((item) =>
        previousSixMonths.includes(item.month)
    );

    const groupedData = tempPreviousSixMonthsData.reduce(
        (acc: CampaignItemData[], item) => {

            const existingIndex = acc.findIndex((data) => data.month === item.month);
            if (existingIndex !== -1) {
                acc[existingIndex].order += item.order;
                acc[existingIndex].revenue += item.revenue;
            } else {
                acc.push({ ...item });
            }

            const previousSixMonthsData: CampaignItemData[] = previousSixMonths.map((month) => {
                const matchingMonthData = acc.find((item) => item.month === month);

                if (matchingMonthData) {
                    return {
                        month: month,
                        order: Math.round(matchingMonthData.order),
                        revenue: Math.round(matchingMonthData.revenue),
                        averageRevenue: (calculateRate(matchingMonthData.revenue, matchingMonthData.order)),
                    };
                } else {
                    return {
                        month: month,
                        order: 0,
                        revenue: 0,
                        averageRevenue: 0,
                    };
                }
            });

            return previousSixMonthsData;
        }, []);
    return groupedData;
};


export const getWorlkflowsStatus = (id: string, workflows: WorkflowType[] | undefined) => {
    const status = workflows?.filter((workflow) => workflow.id === id);
    if (status) {
        return status[0]?.expiresAt && status[0]?.isEnabled
            ? 'Expired'
            : status[0]?.isEnabled && status[0]?.isTestMode
                ? 'Enabled(Test Mode)'
                : status[0]?.isEnabled
                    ? 'Enabled'
                    : 'Disabled';
    }
    return '';
};