import { FC, useContext, useEffect, useState } from 'react';
import { CustomTemplate } from './EmailCreationSteps';
import Dropdown from 'components/Dropdown/Dropdown';
import { EmailContext } from 'store/EmailContext';
import { sendTemplate } from 'services/apiHandlers/Campaigns/Emails';
import { EmailDataResponse } from '.';
import { Toastify } from 'App';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { emailEditor,create } from 'services/constant/routes';
interface Props {
  templates: CustomTemplate[] | undefined;
}
const CustomTemplates: FC<Props> = ({ templates }) => {
  const [selectedTemplate, setSelectedTemplate] =
    useState<string>('- Pick One -');
  const [templateOptions, setTemplateOptions] = useState<Array<string>>([]);
  const [templateError, setTemplateError] = useState<string>();
  const emailCtx = useContext(EmailContext);
  const navigate = useNavigate();

  const templateSubmitHandler = async () => {
    if (selectedTemplate === '- Pick One -') {
      setTemplateError('No template is selected, please choose one.');
    } else if (templates) {
      const loadedHtml = templates?.filter(
        (data) => data.custom_template.name === selectedTemplate
      );

      const templateData = {
        html_length: '',
        blank_template_id: 12,
        new_template_id: loadedHtml[0].custom_template.id,
        new_template_type: 'CustomTemplate',
        old_template_id: '',
      };

      try {
        const sendTemplateResponse = (await sendTemplate(
          templateData
        )) as EmailDataResponse;
        if (sendTemplateResponse.status === 200) {
          emailCtx?.htmlSubmitHandler(loadedHtml[0].custom_template.content);
          emailCtx?.templateInfoHandler(
            {name: sendTemplateResponse.data.draft.email.template_name ,type: undefined}
          );
          navigate(`${emailEditor}?${create}=true`);
        }
      } catch (error) {
        Toastify('Sending Template Failed', 'error', 'custom1');
      }
    }
  };

  useEffect(() => {
    if (templates) {
      const tempOptions = templates.map((data) => {
        return data.custom_template.name;
      });
      setTemplateOptions(tempOptions);
    }
  }, [templates]);

  return (
    <div>
      <p className="pl-7 text-sm font-normal leading-5 text-[#212529] dark:text-white mt-1">
        Use a Template Created For Your Account
      </p>
      <div className="ml-7 p-2 flex items-center mt-3 md:w-8/12 bg-f6f7f9">
        <div className="w-full">
          <div className="w-60 relative inline-block">
            <Dropdown
              options={['- Pick One -', ...templateOptions]}
              onSelect={(value) => setSelectedTemplate(value)}
              value={selectedTemplate}
            />
          </div>
          <button
            type="button"
            className={`${
              selectedTemplate === '- Pick One -'
                ? 'border border-[#ced4da] bg-gray-200 text-[#495057] dark:text-[#CED4DA] '
                : 'bg-primary ease-in-in duration-300 hover:bg-primary hover:scale-105 text-white'
            } py-1 px-3 h-[38px] ml-6 rounded-md text-sm font-normal leading-4 w-28`}
            onClick={templateSubmitHandler}
            disabled={selectedTemplate === '- Pick One -'}
          >
            Submit
          </button>
        </div>
      </div>
      {templateError && (
        <div className="px-6 py-3 bg-[#f7e8e7] rounded mt-4">
          <p className="text-sm font-normal leading-4 text-[#CB5A5A]">
            {templateError}
          </p>
        </div>
      )}
    </div>
  );
};
export default CustomTemplates;
