import React,{ FC } from 'react';
import CheckIcon from 'assets/Images/Common/check-icon.png';
import closeButtonIcon from 'assets/Images/Common/Red-Close-Button-Transparent.png';

interface Props {
  handleStepClick: (step: number) => void;
  currentStep: number;
}
const CampaignSteps: FC<Props> = ({ currentStep, handleStepClick }) => {
  const Steps = [
    { id: 1, name: 'Subscribers' },
    { id: 2, name: 'Sender' },
    { id: 3, name: 'Subject' },
    { id: 4, name: 'Delivery Options' },
    { id: 5, name: 'Schedule Blast' },
    { id: 6, name: 'Finish' },
  ];

  return (
    <>
      {Steps.map((step) => (
        <li
          key={step.id}
          onClick={() => handleStepClick(step.id)}
          className={`${
            step.id !== currentStep + 1 && step.id > currentStep
              ? 'pointer-events-none  dark:bg-[#41464E] '
              : 'cursor-pointer'
          }  mt-4 flex items-center ${
            currentStep === step.id && 'border-primary'
          } ${
            step.id < currentStep && 'border-green-600'
          } text-[#212529] text-sm px-4 py-2 border border-[#E9EBEC] dark:border-[#fff] rounded-md  dark:text-[#fff]`}
        >
          <img
            className="h-4 w-4"
            src={step.id > currentStep ? closeButtonIcon : CheckIcon}
            alt="CheckIcons"
          />
          <p className="mx-2 font-semibold ">Step {step.id}</p>
          <p>{step.name}</p>
        </li>
      ))}
    </>
  );
};
export default CampaignSteps;
