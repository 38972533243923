import { FC } from 'react';
import ModalWrapper from 'components/Workflow/ModalWrapper';
import React from "react"

interface Props {
  onClose: () => void;
  onOk: () => void;
}
const SubmitEmailAlert: FC<Props> = ({ onClose, onOk }) => {
  return (
    <ModalWrapper
      onClose={onClose}
      buttonTxt="Ok"
      onSave={onOk}
      cancelButtonTxt="Close"
    >
      <div className="bg-white">
        <h2 className="sm:text-xl font-semibold text-base sm:leading-5 leading-3 text-[#495057]   mb-8 dark:text-white">
          Save HTML
        </h2>
        <p className="text-xs font-semibold leading-3 tracking-wide text-[#495057]  block mt-4 dark:text-white">
          To Send Email , Save HTML First Then Proceed.
        </p>
      </div>
    </ModalWrapper>
  );
};
export default SubmitEmailAlert;
