import { useFormik } from 'formik';
import Dropdown from 'components/Dropdown/valueDropdown';
import * as Yup from 'yup';
import { useState, FC } from 'react';
import { createField } from 'services/apiHandlers/List-Segments/Field';
import { Toastify } from 'App';
import { z } from 'zod';
import ErrorBoundary from 'components/ErrorBoundary';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import React from 'react';

const responseStatusSchema = z.object({ status: z.number() });
export type ResponseStatus = z.infer<typeof responseStatusSchema>;

interface Props {
  getData: () => void;
}

const options = [
  { name: 'Text Field', value: '1' },
  { name: 'Text Area', value: '2' },
  { name: 'Date/Time', value: '3' },
  { name: 'Select', value: '4' },
  { name: 'Radio', value: '5' },
  { name: 'Checkbox', value: '6' },
  { name: 'Date', value: '7' },
  { name: 'Time', value: '8' },
  { name: 'Numeric', value: '9' },
];
const AddFieldForm: FC<Props> = ({ getData }) => {
  const [selectedField, setSelectedField] = useState(options[0].name);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);

  const formik = useFormik({
    initialValues: {
      field: '',
    },
    validationSchema: Yup.object({
      field: Yup.string().required('Required'),
    }),
    onSubmit: async (values, { resetForm }) => {
      const data = {
        name: values.field,
        id: parseInt(
          options.filter((option) => option.name === selectedField)[0].value
        ),
      };

      try {
        const addFieldResponse = (await createField(data)) as ResponseStatus;
        if (addFieldResponse.status === 200) {
          Toastify('Field Created Successfully', 'success', 'AddFieldForm1');
          getData();
          resetForm();
        }
      } catch (error) {
        Toastify('Field Creation Failed', 'error', 'AddFieldForm2');
      }
    },
  });
  const onChangeField = (value: string) => {
    const filteredOption = options.filter((option) => option.value === value);
    setSelectedField(filteredOption[0].name);
  };
  return (
    <ErrorBoundary>
          <div className='flex justify-between items-center px-5 border-b dark:border-[#fff] border-[#e9ebec] py-[18px]'>
          <h2 className="text-base text-[#495057] font-medium  dark:text-[#CED4DA]">
            Subscriber Fields
          </h2>
          </div>
          <form
            onSubmit={formik.handleSubmit}
            className="p-5"
          >
            <div>
              <label className="text-xs font-semibold leading-3 tracking-wide uppercase text-[#495057] dark:text-[#CED4DA]  dark:text-white dark:text-white">
                Add Database field
              </label>
              <div className="flex flex-wrap mt-2.5">
                <div>
                  <input
                    className="border border-[#ced4da] dark:border-[#fff]  dark:bg-[#41464E]  sm:[38px] h-9 p-4 text-13 font-medium leading-3 text-gray-700 placeholder:text-gray-500 dark:text-white  dark:bg-[#41464E]  rounded w-full sm:w-52 w-full focus:outline-none"
                    type="text"
                    placeholder=""
                    name="field"
                    value={formik.values.field}
                    onChange={formik.handleChange}
                  />
                  {formik.errors.field ? (
                    <div
                      data-pw="add-field-required"
                      className="text-xs leading-4 text-red-400 mt-1.5"
                    >
                      {formik.errors.field ? formik.errors.field : null}
                    </div>
                  ) : null}
                </div>
                <div className="sm:mx-3 mr-2 sm:mt-0 mt-4">
                  <div className="w-44 relative sm:[38px] dark:border-[#fff] rounded-md">
                    <Dropdown
                      options={options}
                      value={selectedField}
                      onSelect={onChangeField}
                    />
                    <div className="text-right">
                      <div className="text-xs font-medium text-primary mt-1 inline-block underline">
                        <div
                          id="Tooltip"
                          onMouseEnter={() => {
                            setShowTooltip(true);
                          }}
                          onMouseLeave={() => {
                            setShowTooltip(false);
                          }}
                        >
                          Need Help?
                        </div>
                        <Tooltip
                          style={{
                            pointerEvents: 'auto',
                            backgroundColor: 'white',
                            border: '1px solid #9e9e9e',
                            zIndex: '1',
                            opacity: '1',
                          }}
                          isOpen={showTooltip}
                          anchorId={'Tooltip'}
                          classNameArrow="-bottom-[5px] border border-gray-500 border-t-0 border-l-0"
                        >
                          <div className="text-blue-600">
                            <p>Fields store bits of subscriber information.</p>
                            <p>
                              You can target specific people by applying
                              <b>filters</b> to peoples stored info.
                            </p>
                            <p className="flat">
                              Use robust <b>data types</b> to make responses
                              more meaningful.
                            </p>
                          </div>
                        </Tooltip>
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  type="submit"
                  className="sm:py-2.5 py-1.5 sm:px-4 px-2 sm:text-13 text-xs font-medium leading-4 text-white dark:hover:text-[#495057] dark:text-[#CED4DA]  dark:text-white rounded bg-primary dark:hover:bg-white sm:mt-0 mt-4 h-11"
                  data-pw="add-field"
                >
                  Add
                </button>
              </div>
            </div>
          </form>

         
      
    </ErrorBoundary>
  );
};
export default AddFieldForm;
