import Search from 'assets/Images/ListsegmentImages/search.png';
import React, { Dispatch, SetStateAction, useState } from 'react';
import Dropdown from 'components/Dropdown/Dropdown';
// import ValueDropdown from 'components/Dropdown/valueDropdown';
import { z } from 'zod';
// import { getAllList } from 'services/apiHandlers/List-Segments/SegmentsList';
import { ListSchema } from '../List-Segments/Body/List/Types';
import { Link } from 'react-router-dom';
import { fields, subscribersProfile, upload } from 'services/constant/routes';

const ListResponseSchema = z.object({
  data: z.object({ lists: z.array(ListSchema) }),
  status: z.number(),
});
export type ListResponse = z.infer<typeof ListResponseSchema>;

// const optionsSchema = z.object({
//   name: z.string(),
//   value: z.string(),
// });
// type Options = z.infer<typeof optionsSchema>;

interface Props {
  onListChange: (id: string) => void;
  onStatusChange: Dispatch<SetStateAction<SUBSCRIBER_STATUS>>;
}

export enum SUBSCRIBER_STATUS {
  SELECT = 'Select',
  ACTIVE = 'Active',
  PENDING = 'Pending',
  INACTIVE = 'Inactive',
  UNSUBSCRIBED = 'Unsubscribed',
}

// const Actions = [
//   'Select an Action',
//   'Add/Import',
//   'Fields',
// ];

const SubscribersHeader = ({
  // onListChange,
  onStatusChange,
}: Props) => {
  // const [selectedMode, setSelectedMode] = useState<string>(Actions[0]);
  // const [selectedList, setSelectedList] = useState<string>('Select a List');
  // const [options, setOptions] = useState<Array<Options>>([]);
  const [selectedStatus, setSelectedStatus] = useState<string>('Select a Status');
  // const [searchParams] = useSearchParams();
  // const listId = searchParams.get('list_id');
  // const navigate = useNavigate();

  // const getLists = async () => {
  //   try {
  //     const getListsResponse = (await getAllList()) as ListResponse;
  //     if (getListsResponse.status === 200) {
  //       const options = getListsResponse?.data?.lists.map((list) => {
  //         return { name: list.name, value: list.id.toString() };
  //       });
  //       setOptions([{ name: 'Select an option', value: '' }, ...options]);
  //     }
  //   } catch (error) {
  //     console.log('error for getting list  is : ', error);
  //   }
  // };

  // const onChangeField = (value: string) => {
  //   setSelectedMode(value);
  //   if (value === 'Add/Import') {
  //     navigate(`${upload}`);
  //   } else if (value === 'Fields') {
  //     navigate(`${fields}`);
  //   }
  // };

  // const listChangeHandler = (value: string) => {
  //   onListChange(value);
  //   const filterOptions = options.filter((option) => option.value === value);
  //   setSelectedList(filterOptions[0]?.name);
  // };


  // useEffect(() => {
  //   getLists();
  // }, []);

  // useEffect(() => {
  //   if (listId) {
  //     listChangeHandler(listId);
  //   }
  // }, [options]);

  return (
    <div className=' flex flex-wrap justify-between items-center'>
      <Link to={`/${subscribersProfile}/${upload}`} className="sm:text-sm dark:text-white text-primary cursor-pointer underline leading-5 flex items-center ml-auto rounded-md py-2 sm:pl-4 px-2 sm:pr-3.5 font-inter  ease-in-in duration-300 hover:scale-105">
        Add/Import Subscribers
      </Link>
      {/* <button type='button' className="sm:text-sm text-xs font-medium leading-5 flex items-center ml-auto rounded-md py-3 sm:pl-4 px-2 sm:pr-3.5 font-inter bg-gray-600 ease-in-in duration-300 hover:scale-105">
        Export Subscribers
      </button> */}
      <Link to={`/${subscribersProfile}/${fields}`} className="sm:text-sm dark:text-white text-xs text-primary cursor-pointer underline leading-5 flex items-center ml-auto rounded-md py-2 sm:pl-4 px-2 sm:pr-3.5 font-inter ease-in-in duration-300 hover:scale-105">
        Fields
      </Link>
      {/* <button type='button' className="sm:text-sm text-xs font-medium leading-5 flex items-center ml-auto rounded-md py-3 sm:pl-4 px-2 sm:pr-3.5 font-inter bg-gray-600 ease-in-in duration-300 hover:scale-105">
        Bulk Unsubscribe
      </button> */}

      {/* <div className="relative min-w-[180px]">
        <Dropdown
          value={selectedMode}
          options={Actions}
          onSelect={onChangeField}
        />
      </div> */}

      {/* <div
          className="relative min-w-[180px]"
          id="select-list-segment"
        >
          <ValueDropdown
            options={options}
            onSelect={(value) => listChangeHandler(value)}
            value={selectedList}
          />
        </div> */}

      <div
        className="relative min-w-[180px]"
        id="status-list-segment"
      >
        <Dropdown
          options={[...Object.values(SUBSCRIBER_STATUS)]}
          onSelect={(value) => {
            setSelectedStatus(value);
            onStatusChange(value as SUBSCRIBER_STATUS);
          }}
          value={selectedStatus}
        />
      </div>

      <div className="md:hidden mt-5">
        <div className="relative flex items-center py-2 px-4 h-9 border rounded border-gray-200 dark:border-[#fff] bg-white  dark:bg-[#41464E] ">
          <input
            className="text-13 font-medium leading-3 text-gray-500  dark:bg-[#41464E]  w-full pl-8 h-6 focus:outline-none"
            type="text"
            name="search"
            placeholder="Search"
          />
          <img className="absolute" src={Search} alt="Search" />
        </div>
      </div>
    </div>
  );
};
export default SubscribersHeader;
