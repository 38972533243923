import React, { FC, useState, useEffect } from 'react';
import { Tooltip } from 'react-tooltip';
import { Blast } from './index';
import InfoIcon from 'assets/Images/Common/infoIcon.svg'
interface Props {
  blast: Blast | undefined;
}
const Status: FC<Props> = ({ blast }) => {
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const [deliveryOptions, setDeliveryOptions] = useState<Array<string>>([]);

  useEffect(() => {
    const tempDeliveryOptions = [];
    blast?.blast.include_social_bar && tempDeliveryOptions.push('Social Bar');
    blast?.blast.track_clicks === 'yes' &&
      tempDeliveryOptions.push('Track Click-through Activity');
    blast?.blast.google_analytics &&
      tempDeliveryOptions.push('Google Analytics Integrations');
    setDeliveryOptions(tempDeliveryOptions);
  }, [blast]);

  return (
    <div className="flex items-center border-b justify-between dark:border-[#fff]">
      <p className="px-4 py-2 text-[#495057] text-sm font-medium dark:text-white">Status</p>
      <div className='flex items-center'>
      <p className="px-4 py-2 text-sm text-[#878A99]">
        {blast?.blast.status === 'waiting' ? (
          <div>Scheduled</div>
        ) : blast?.blast.status === 'sending' ? (
          <div>Sending</div>
        ) : blast?.blast.status === 'sent' ? (
          <div>Sent</div>
        ) : (
          <div>None</div>
        )}
      </p>
      {blast?.blast.tag_list[0] ?
      <p className="px-4 py-2 text-sm text-[#878A99]">
         <div>{blast?.blast.tag_list[0]}</div> 
      </p>
      : ''}
      
      {deliveryOptions.length > 0 && (
        <div className="pr-4 py-2 text-sm text-[#878A99]">
              
          <div id="tooltip" onMouseEnter={() => setShowTooltip(true)} onMouseLeave={() => setShowTooltip(false)}  className="group relative cursor-pointer flex items-center">
            <img className='h-4 w-4 mr-0.5 relative -top-[1px]' src={InfoIcon} alt='InfoIcon' />
            <span
              
              className="text-xs text-[#878a99] "
            >
              Delivery Setting
            </span>
            <Tooltip
              style={{
                pointerEvents: 'auto',
                backgroundColor: `white`,
                border: '1px solid #9e9e9e',
                zIndex: '1',
                opacity: '1',
              }}
              isOpen={showTooltip}
              anchorId={'tooltip'}
              classNameArrow="-bottom-[5px] border border-gray-500 border-t-0 border-l-0"
            >
              <div className=" min-w-[250px] mt-2 bg-white rounded boxShadow hidden group-hover:block">
                <p className="text-sm font-medium leading-4 text-[#212529]">
                  Delivery Setting
                </p>
                <ul className=" mt-1 ">
                  {deliveryOptions.map((option) => (
                    <li
                      key={option}
                      className="text-xs font-medium list-style-none leading-4 text-[#495057] py-1"
                    >
                      {option}
                    </li>
                  ))}
                </ul>
              </div>
            </Tooltip>
          </div>
        </div>
      )}
      </div>
    </div>
  );
};
export default Status;
