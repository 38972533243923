import { FC } from 'react';
import { TotalOrders } from 'staticdata/Dashboard/Data';
import StoreReport from 'components/DashboardReports/Body/Store-Report/Report';
import { storeReportOrder } from 'components/DashboardReports/Body/Store-Report/Types';
import React from 'react';

interface Props {
  orders: storeReportOrder | undefined;
  loading: boolean;
  isQuantitativeData?: boolean;
}

const TotalOrder: FC<Props> = ({ isQuantitativeData, orders, loading }) => {
  return (
    <div className=''>
      <StoreReport
        data={orders ? orders : TotalOrders}
        loading={!orders ? true : loading}
        isQuantitativeData={isQuantitativeData}
      />
    </div>
  );
};
export default TotalOrder;
