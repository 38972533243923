import { FC } from 'react';
import { OnlineStore } from 'staticdata/Dashboard/Data';
import StoreReport from 'components/DashboardReports/Body/Store-Report/Report';
import { storeReportOrder } from 'components/DashboardReports/Body/Store-Report/Types';
import React from 'react';

interface Props {
  revenue: storeReportOrder | undefined;
  loading: boolean;
}

const Revenue: FC<Props> = ({ revenue, loading }) => {
  return (
    <div>
      <StoreReport data={revenue ? revenue : OnlineStore} loading={!revenue ? true : loading} />
    </div>
  );
};
export default Revenue;
