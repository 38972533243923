import React, { FC, useState, useRef } from 'react';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartDataset,
} from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { useContext } from 'react';
import { ThemeContext } from 'store/ThemeContext';
import ErrorBoundary from 'components/ErrorBoundary';
import { useOutsideClick } from 'app/hooks/useOutsideClick';
import Modal from 'components/Alert/Modal';


ChartJS.register(ArcElement, Tooltip, Legend);

interface Props {
  labels: string[] | undefined;
  datasets: ChartDataset<'doughnut', number[]>[];
  height: string | number;
  width: string | number | undefined;
}

const PieGraph: FC<Props> = ({ labels, datasets, height, width }) => {
  const themeCtx = useContext(ThemeContext);
  const theme = themeCtx?.theme;
  const [isFullView, setIsFullView] = useState(false);
  const outsideRef = useRef<HTMLDivElement>(null);

  useOutsideClick(outsideRef, () => setIsFullView(false));

  const data = {
    labels: labels,
    datasets: datasets,
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    cutout: 60,
    elements: {
      arc: {
        borderWidth: 1,
      }
    },
    tooltips: {
      enabled: false
    },
    plugins: {
      legend: {
        display: true,
        position: 'bottom' as const,
        rtl: false,
        labels: {
          usePointStyle: true,
          boxWidth: 8,
          boxHeight: 8,
          textAlign: 'left' as const,
          color: `${theme === 'dark' ? 'white' : ''}`,
          font: {
            size: 13,
          },
          padding: 20
        }
      },

    },
  };
  return (
    <ErrorBoundary>
      <div onDoubleClick={() => setIsFullView(true)} className="2xl:h-52 h-60 w-auto relative 2xl:top-0 -top-2 ">
        <Doughnut height={height} width={width} data={data} options={options} />
      </div>
      <div  className="flex justify-between items-center z-999999">
        <Modal isOpen={isFullView}>
          <div ref={outsideRef} className="h-[70vh] mt-[140px] bg-white dark:bg-[url('assets/Images/Common/denimbg.webp')]  w-[70%] rounded p-6 mx-auto ">
            <Doughnut height={height} width={width} data={data} options={options} />
          </div>
        </Modal>
      </div>
    </ErrorBoundary>
  );
};
export default PieGraph;





