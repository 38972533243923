import React, { FC } from 'react';
import { InformationItem } from '.';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';


interface Props {
    item: InformationItem
    valueStyle?: string;
    itemStyle?: string;
}
const GetFormattedItem: FC<Props> = ({ item, itemStyle, valueStyle }) => {



    return (
        <div className="flex items-center justify-between">
            <p className={`${itemStyle || 'text-[#495057]'} dark:text-white text-sm font-medium`}>
                {item.title}:
            </p>
            {item.value.length > 30 ?
                <Tippy content={item.value} interactive={true}>
                    <p className={` ${valueStyle || 'text-[#878A99]'} max-w-[200px] truncate text-sm  ml-2 `}>
                        {item.value}
                    </p>
                </Tippy> : 
                (
                    <p className={` ${valueStyle || 'text-[#878A99]'} text-sm  ml-2 `}>
                        {item.value}
                    </p>
                )}
        </div>
    );
};
export default GetFormattedItem;