import React from "react";
const MagnetoDescription = () => {
  return (
    <p className="text-sm font-medium leading-4 text-[#495057] dark:text-[#CED4DA]  dark:text-white dark:text-white">
      These Credentials must match a user with an assigned role in your Magneto
      admin page under
      <span className="text-sm font-medium leading-4 text-[#495057] dark:text-[#CED4DA]  dark:text-white dark:text-white">
        System {'>'} Web Services {'>'} SOAP/XML-RPC - Users
      </span>
      The Users role must have access to the <span>Sales {'>'} Order </span>
      resource
    </p>
  );
};
export default MagnetoDescription;
