import React, { useEffect, useState, FC, useCallback } from 'react';
import { getFields } from 'services/apiHandlers/List-Segments/Field';
import xmlToJSON from 'utils/xmlToJSON';
import Input from './Input';
import { useFormik } from 'formik';
import {
  createSubscriber,
  getSubscriberById,
  getSubscriberMembershipById,
  editSubscriber,
} from 'services/apiHandlers/List-Segments/Subscriber';
import * as Yup from 'yup';
import { getAllList } from 'services/apiHandlers/List-Segments/SegmentsList';
import { formatXmlJsonResponse } from 'utils/common';
import Skeleton from 'components/Skeleton/Skeleton';
import { z } from 'zod';
import List from 'components/List';
import { Toastify } from 'App';
import ValueDropdown from 'components/Dropdown/valueDropdown';
import Dropdown from 'components/Dropdown/Dropdown';
import { Link, useNavigate, useParams } from 'react-router-dom';
import {
  subscribersProfile,
  upload,
  view,
} from 'services/constant/routes';
import MailIcon from 'assets/Images/Common/mail-Icon.svg';
import Wrapper from 'components/Wrapper';

const getListResponseDataSchema = z.object({
  id: z.number(),
  name: z.string(),
  audience_id: z.number(),
});
const selectedListSchema = z.object({ id: z.number(), label: z.string() });
type SelectedList = z.infer<typeof selectedListSchema>;
type ListType = z.infer<typeof getListResponseDataSchema>;
const getListResponseSchema = z.object({
  data: z.object({
    lists: z.array(getListResponseDataSchema),
  }),
  status: z.number(),
});
type GetListResponse = z.infer<typeof getListResponseSchema>;

interface SubscriberStatus {
  [key: string]: string;
  unsubscribed: string;
  active: string;
}

const status: SubscriberStatus = {
  unsubscribed: 'Unsubscribed',
  active: 'Active',
};

const breadCrumbs = z.object({
  title: z.string(),
  link: z.string().optional(),
});
const breadCrumbSchema = z.array(breadCrumbs);

const responseStatusSchema = z.object({ status: z.number() });

const FormSchema = z.object({
  ['allow-other']: z.boolean(),
  ['data-type']: z.string(),
  id: z.number(),
  ['include-blank']: z.boolean(),
  name: z.string(),
  slug: z.string(),
  'used-in-filter': z.string(),
});

const editedSubscriberSchema = z.object({
  person: z.record(z.union([z.string(), z.array(z.string())])),
});

export type Form = z.infer<typeof FormSchema>;
export type BreadCrumb = z.infer<typeof breadCrumbSchema>;
export type ResponseStatus = z.infer<typeof responseStatusSchema>;

export type EditedSubscriber = z.infer<typeof editedSubscriberSchema>;

const SubscriberForm: FC = () => {
  const [initialState, setInitialState] = useState<Record<string, string | string[]>>({});

  const [createFormData, setCreateFormData] = useState<Array<Form>>([]);
  const [selectedLists, setSelectedLists] = useState<Array<number>>([]);
  const [loadingFields, setLoadingFields] = useState<boolean>(true);
  const [loading, setLoading] = useState<boolean>(true);
  const [list, setList] = useState<Array<ListType>>([]);
  const [listItems, setListItems] = useState<Array<SelectedList>>([]);
  const [checkIndex, setCheckIndex] = useState<number | null>(null);
  const { id } = useParams();
  const navigate = useNavigate();

  const getListData = async () => {
    try {
      const getListsResponse = (await getAllList()) as GetListResponse;
      if (getListsResponse.status === 200) {
        const tempLists = getListsResponse?.data?.lists?.map((list) => {
          return { id: list.id, label: list.name };
        });
        setListItems(tempLists);
        setList(getListsResponse?.data?.lists);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
    setLoading(false);
  };

  const getSubscriberFormFields = async () => {
    /* eslint-disable */
    const getFieldResponse: any = await getFields();
    const data: any = xmlToJSON.parseString(getFieldResponse.data);
    const fields = formatXmlJsonResponse(data?.fields[0]?.field);

    const radioCheckboxFields = fields.filter(
      (field) =>
        field['data-type'] === 'Checkbox' || field['data-type'] === 'Radio'
    );
    const remainingFields = fields.filter(
      (field) =>
        field['data-type'] !== 'Checkbox' && field['data-type'] !== 'Radio'
    );
    if (remainingFields.length % 2 !== 0) {
      setCheckIndex(remainingFields.length);
    }

    const updatedFields = remainingFields.concat(radioCheckboxFields);

    setLoadingFields(false);
    setCreateFormData(updatedFields as any);
    /* eslint-enable  */
  };

  const textFormatValue = (format: string) => {
    if (format === 'text' || format === 'Text') {
      return 'Text';
    } else {
      return 'HTML';
    }
  };

  const getSubscriberData = useCallback(async () => {
    if (id) {
      /* eslint-disable */

      const getSubscriberDataresponse = await getSubscriberById(
        isNaN(parseInt(id)) ? id : parseInt(id)
      );
      const subscribedListResponse = await getSubscriberMembershipById(
        parseInt(getSubscriberDataresponse.id)
      );

      const subscribedListJson: any = xmlToJSON.parseString(
        subscribedListResponse.replace(
          '<?xml version="1.0" encoding="UTF-8"?>',
          ''
        )
      );

      const formatedSubscribedList = formatXmlJsonResponse(
        subscribedListJson.memberships[0].membership
      );
      const preSelectLists = formatedSubscribedList.map((list: any) =>
        Number(list['list-id'])
      );

      setInitialState(getSubscriberDataresponse);
      setSelectedLists(preSelectLists);

      /* eslint-enable  */
    }
  }, []);
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      ...initialState,
    },
    validationSchema: Yup.object({
      email_address: Yup.string().email('Invalid email').required('Required'),
    }),
    onSubmit: async () => {
      try {
        if (id) {
          const editSubscriberResponse = (await editSubscriber(
            typeof initialState.id === 'number' ? initialState.id : 0,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            { person: { ...initialState }, lists: selectedLists }
          )) as ResponseStatus;
          if (
            editSubscriberResponse.status === 201 ||
            editSubscriberResponse.status === 200
          ) {
            Toastify('Changes Made Successfully', 'success', 'subscriberform2');
            navigate(
              `/${subscribersProfile}/${view}/${initialState.id}`
            );
          }
        } else {
          const createSubscriberResponse = (await createSubscriber(
            initialState,
            selectedLists
          )) as ResponseStatus;
          if (
            createSubscriberResponse.status === 201 ||
            createSubscriberResponse.status === 200
          ) {
            Toastify('Saved Successfully', 'success', 'subscriberform2');
            navigate(`/${subscribersProfile}`);
          }
        }
      } catch (error) {
        Toastify(
          `Subscriber ${id ? 'Changes ' : 'Saving '}Failed`,
          'error',
          'subscribersform1'
        );
      }
    },
  });

  const membershipChangeHandler = (list_id: number) => {
    const updatedList = [...selectedLists];
    const idIndex = updatedList.indexOf(list_id);
    if (idIndex === -1) {
      updatedList.push(list_id);
    } else {
      updatedList.splice(idIndex, 1);
    }
    setSelectedLists(updatedList);
  };

  useEffect(() => {
    getSubscriberFormFields();
    getSubscriberData();
    getListData();
  }, []);

  return (
    <div>
      <form onSubmit={formik.handleSubmit}>
        <div className="flex xl:flex-row flex-col mx-4 my-8 gap-4">
          <div className="xl:w-7/12 xl:order-1 order-2 w-full xl:pr-2.5 relative">
            <Wrapper>
              <>
                <div className="flex justify-between items-center px-5 border-b border-[#e9ebec] py-[15px]">
                  <h2 className="text-base text-[#495057] font-medium  dark:text-[#CED4DA]">
                    {id ? 'Edit Subscriber' : 'Add Subscriber'}
                  </h2>
                </div>
                <div className="sm:px-6 px-4 sm:py-4 py-2.5 ">
                  <>
                    <div>
                      <label className="text-sm font-medium leading-3 tracking-wide text-[#212529] dark:text-white">
                        Email
                        <sup className="text-sm text-red-400 font-medium">
                          *
                        </sup>
                      </label>
                      <div className="relative">
                        <input
                          name="email_address"
                          type="email"
                          className="w-full px-4 py-2 text-13 leading-3  dark:bg-[#41464E]  border border-[#ced4da] dark:border-[#fff] rounded mt-2 text-[#212529] dark:text-white sm:h-[38px]"
                          onChange={(e) => {
                            setInitialState({
                              ...initialState,
                              email_address: e.target.value,
                            });

                            formik.handleChange(e);
                          }}
                          value={formik.values.email_address || ''}
                          onBlur={formik.handleBlur}
                        />
                        <img
                          src={MailIcon}
                          alt="MailIcon"
                          className="h-5 w-5 absolute right-3 top-[17px]"
                        />
                      </div>
                      {!formik.isValid ? (
                        <div
                          data-pw="subscriber-email-required"
                          className="text-xs leading-4 text-red-400 mt-1.5"
                        >
                          {formik.errors.email_address}
                        </div>
                      ) : null}
                    </div>
                    {loadingFields && <Skeleton />}
                    <div className="flex flex-wrap justify-between">
                      {createFormData?.map((data, index) => (
                        <Input
                          key={data.id}
                          parentClass={`mt-4 w-[49%] min-h-[77px]`}
                          fieldData={initialState}
                          field={data}
                          className="w-full px-4 py-2 text-13 leading-3  dark:bg-[#41464E]  border border-[#ced4da] dark:border-[#fff] rounded mt-2 text-[#212529] dark:text-white sm:h-[38px]"
                          onFieldValueChange={(name, value, check) => {
                            if (check) {
                              if (initialState[name]) {
                                const filteredArray = initialState[
                                  name
                                ]?.includes(value)
                                  ? // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                  // @ts-ignore
                                  initialState[name]?.filter(
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    (name) => name !== value
                                  )
                                  : // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                  // @ts-ignore
                                  [...initialState[name], value];

                                setInitialState({
                                  ...initialState,
                                  [name]: filteredArray,
                                });
                              } else {
                                setInitialState({
                                  ...initialState,
                                  [name]: [value],
                                });
                              }
                            } else {
                              setInitialState({
                                ...initialState,
                                [name]: value,
                              });
                            }
                          }}
                          index={index}
                          checkIndex={checkIndex}
                        />
                      ))}
                    </div>
                  </>
                </div>
                <div className="flex items-center justify-end border-t border-[#e9ebec] py-3 px-6 mt-2">
                  <Link
                    to={
                      !id
                        ? `/${subscribersProfile}/${upload}`
                        : `/${subscribersProfile}`
                    }
                    className="text-center sm:text-13 text-xs py-2.5 px-4 h-11 flex justify-center items-center w-28 font-medium leading-5 mr-2  rounded-md bg-[#f3f6f9] hover:bg-[#cfd1d4] transition-bg text-[#212529]"
                  >
                    Cancel
                  </Link>
                  <button
                    type="submit"
                    className="sm:text-13 text-xs font-medium leading-5 text-white py-2.5 px-4 h-11 w-28 rounded-md bg-primary ease-in-in duration-300 hover:bg-primary hover:scale-105"
                  >
                    Save
                  </button>
                </div>
              </>
            </Wrapper>
          </div>
          <div className="xl:w-5/12  w-full xl:pl-2.5">
            {id && (
              <Wrapper childClass='pb-4'>
                <>
                  <label className="flex justify-between items-center px-5 border-b border-[#e9ebec] py-[15px] text-base text-[#495057] font-medium  dark:text-[#CED4DA]">
                    Opt-Status
                  </label>
                  <div className=" dark:border-[#fff] flex items-center rounded relative sm:h-[38px] mt-4 h-9 sm:px-6 px-4  ">
                    <ValueDropdown
                      options={[
                        { name: 'Active', value: 'active' },
                        { name: 'Unsubscribed', value: 'unsubscribed' },
                      ]}
                      value={status[initialState.opt_status as string] || ''}
                      onSelect={(value) =>
                        setInitialState({ ...initialState, opt_status: value })
                      }
                    />
                  </div>
                </>
              </Wrapper>
            )}
            <Wrapper>
              <div className="pb-4">
                <label className="flex justify-between items-center px-5 border-b border-[#e9ebec] py-[15px] text-base text-[#495057] font-medium  dark:text-[#CED4DA]">
                  E-mail Format
                </label>
                <div className=" dark:border-[#fff] flex items-center rounded relative sm:h-[38px] mt-4 h-9 sm:px-6 px-4 sm:py-4 py-2.5 ">
                  <Dropdown
                    options={['HTML', 'Text']}
                    value={
                      typeof initialState?.email_content_format === 'string'
                        ? textFormatValue(initialState?.email_content_format)
                        : ''
                    }
                    onSelect={(value) =>
                      setInitialState({
                        ...initialState,
                        email_content_format: value,
                      })
                    }
                  />
                </div>
              </div>
            </Wrapper>
            <Wrapper>
              <>
                <label className="flex justify-between items-center px-5 border-b border-[#e9ebec] py-[15px] text-base text-[#495057] font-medium  dark:text-[#CED4DA]">
                  List Membership
                </label>
                <List
                  list={list}
                  listItems={listItems}
                  onListChange={membershipChangeHandler}
                  selectedLists={selectedLists}
                  loading={loading}
                  getList={getListData}
                />
              </>
            </Wrapper>
          </div>
        </div>
      </form>
    </div>
  );
};
export default SubscriberForm;
