import { useFormik, FormikValues } from 'formik';
import { workflow } from './WorkflowScript';
import { Dispatch, FC, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import {
  createWorkflowScript,
  updateWorkflowScript,
  deleteWorkflowScript,
} from 'services/apiHandlers/Integration/Scripts';
import CodeMirror from 'react-codemirror';
import 'codemirror/lib/codemirror.css';
import { z } from 'zod';
import Skeleton from 'components/Skeleton/Skeleton';
import { Toastify } from 'App';
import DeleteModal from 'components/Alert/DeleteModal';
import ErrorBoundary from 'components/ErrorBoundary';
import React from 'react';

const workflowScriptResponseSchema = z.object({ status: z.number() });
type workflowScriptResponse = z.infer<typeof workflowScriptResponseSchema>;

interface Props {
  getData: () => void;
  setShowForm: Dispatch<React.SetStateAction<boolean>>;
  setWorkflow: Dispatch<React.SetStateAction<string>>;
  editWorkflowData: workflow | undefined;
  setEditWorkflowData: Dispatch<React.SetStateAction<workflow | undefined>>;
}
const WorkflowScriptForm: FC<Props> = ({
  getData,
  setShowForm,
  editWorkflowData,
  setWorkflow,
  setEditWorkflowData,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [code, setCode] = useState<string>('');
  const [deleteId, setDeleteId] = useState<string>('');
  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);
  const mirrorRef = useRef(null);

  const resetForm = () => {
    setEditWorkflowData(undefined);
    setWorkflow('Select an item');
    setLoading(false);
    getData();
    setShowForm(false);
  };

  const formik: FormikValues = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: '',
      domains: '',
      javascript: '',
      id: 0,
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
    }),
    onSubmit: async (values) => {
      let tempJava: string[] = [];
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      tempJava = mirrorRef.current.codeMirror.display.maxLine.parent.lines.map(
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        (line) => line.text
      );
      setLoading(true);
      const domains = values.domains.split('\n');
      try {
        if (editWorkflowData) {
          const data = {
            workflowScript: {
              ...values,
              domains: domains,
              javascript: tempJava.join('\n'),
            },
          };

          const updateWorkflowScriptResponse = (await updateWorkflowScript(
            data
          )) as workflowScriptResponse;
          if (updateWorkflowScriptResponse.status === 200) {
            Toastify('Workflow Updated Successfully', 'success', 'workflow1');
            resetForm();
          }
        } else {
          const data = {
            workflowScript: {
              name: formik.values.name,
              domains: domains,
              javascript: tempJava.join('\n'),
            },
          };
          const createWorkflowScriptResponse = (await createWorkflowScript(
            data
          )) as workflowScriptResponse;
          if (createWorkflowScriptResponse.status === 201) {
            Toastify('Workflow Created Successfully', 'success', 'workflow2');
            resetForm();
          }
        }
      } catch (error) {
        Toastify('Workflow Changes Failed', 'error', 'workflow3');
        resetForm();
      }
    },
  });

  const deleteWorkflowHandler = async (id: string) => {
    try {
      setLoading(true);
      const deleteWorkflowScriptResponse = (await deleteWorkflowScript(
        id
      )) as workflowScriptResponse;
      if (deleteWorkflowScriptResponse.status === 200) {
        setWorkflow('Select an item');
        Toastify('Workflow Deleted Successfully', 'success', 'workflow4');
        resetForm();
      }
    } catch (error) {
      Toastify('Workflow Deletion Failed', 'error', 'workflow5');
      resetForm();
    }
  };

  useEffect(() => {
    if (editWorkflowData) {
      formik.setValues(editWorkflowData);
      setCode(editWorkflowData.javascript);
    } else {
      formik.setValues(formik.initialValues);
      setCode('');
    }
  }, [editWorkflowData]);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    mirrorRef.current.getCodeMirror().setValue(code);
  }, [code]);

  return (
    <ErrorBoundary>
      <div data-pw="workflow-script-form" className="">
        {loading ? (
          <Skeleton />
        ) : (
          <form onSubmit={formik.handleSubmit}>
            <div className="-mt-1">
              <label className="text-sm font-medium leading-3 tracking-wide text-[#212529] dark:text-white dark:text-white">
                Name
              </label>
              <input
                className="w-full focus:border-primary dark:focus:border-white focus:outline-none py-2 px-4 text-13 leading-3 border border-[#ced4da] placeholder:text-gray-500 dark:placeholder:text-white dark:border-[#fff] dark:text-white dark:bg-[#41464E]  rounded mt-2 text-[#212529] placeholder:font-normal "
                type="text"
                value={formik.values.name}
                name="name"
                placeholder="Name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                data-pw="workflow-name-input"
              />
              {!formik.isValid && formik.touched.name ? (
                <div
                  data-pw="workflow-name-required"
                  className="text-xs leading-4 text-red-400 mt-1.5"
                >
                  {formik.errors.name}
                </div>
              ) : null}
            </div>
            <div data-pw="workflow-domains" className="mt-4">
              <label className="text-sm font-medium leading-3 tracking-wide text-[#212529] dark:text-white">
                Domain (separated by new lines)
              </label>
              <textarea
                rows={6}
                name="domains"
                className="w-full focus:border-primary dark:focus:border-white focus:outline-none py-2 px-4 text-13 leading-4 border border-[#ced4da] dark:border-[#fff]  dark:bg-[#41464E]  rounded mt-2 text-[#495057] dark:text-[#CED4DA]  dark:text-white dark:text-white"
                value={formik.values.domains}
                onChange={formik.handleChange}
              ></textarea>
            </div>
            <div className="mt-4">
              <label className="text-sm font-medium leading-3 tracking-wide text-[#212529] dark:text-white ">
                Javascript
              </label>
              <CodeMirror
                ref={mirrorRef}
                mode="javascript"
                theme="monokai"
                options={{
                  lineNumbers: true,
                  indentWithTabs: true,
                  tabSize: 2,
                }}
                className="w-full  focus:border-primary dark:focus:border-white focus:outline-none  text-13 font-medium leading-4 border border-[#ced4da] dark:border-[#fff]  dark:bg-[#41464E]  rounded mt-2 text-[#495057] dark:text-[#CED4DA]  dark:text-white dark:text-white"
              />
            </div>

            <div className="w-full mt-4 flex justify-end">
              {editWorkflowData && editWorkflowData?.name.length > 1 && (
                <button
                  type="button"
                  className="text-13 mx-4 font-medium leading-5 min-w-[74px] rounded py-2 px-4 text-white bg-[#f34a4a] dark:hover:bg-white dark:hover:text-[#495057] dark:text-[#CED4DA] "
                  onClick={() => {
                    setShowDeleteAlert(true);
                    setDeleteId(editWorkflowData.id);
                  }}
                >
                  Delete
                </button>
              )}
              <button
                type="button"
                className="text-center sm:text-13 text-xs py-2.5 px-4 h-11 flex justify-center items-center w-28 font-medium leading-5 mr-2  rounded-md bg-[#f3f6f9] hover:bg-[#cfd1d4] transition-bg text-[#212529]"
                onClick={resetForm}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="text-13 ml-3 font-medium leading-5 min-w-[74px] rounded py-2 px-4 text-white bg-primary dark:hover:bg-white dark:hover:text-[#495057] dark:text-[#CED4DA]  ease-in duration-300 hover:bg-primaryHover hover:scale-105"
                data-pw="submit-workflow"
              >
                Save
              </button>
            </div>
            {showDeleteAlert && (
              <div
                onClick={() => setShowDeleteAlert(false)}
                className="fixed left-0 top-0 w-full h-full z-10 bg-black-200 duration-300"
              >
                <DeleteModal
                  onDelete={() => deleteWorkflowHandler(deleteId)}
                  onCancel={() => setShowDeleteAlert(false)}
                />
              </div>
            )}
          </form>
        )}
      </div>
    </ErrorBoundary>
  );
};
export default WorkflowScriptForm;
