import React, { FC } from 'react';
import { EventResultData } from './SubscriberEvents';
import Close from 'assets/Images/Workflow/close-Icon.png';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';

interface Props {
  data: EventResultData;
  onClose: () => void;

}

const SubscriberEventAccordian: FC<Props> = ({ data, onClose }) => {
  return (
    <div>
      <div className='flex justify-between items-center px-5 border-b dark:border-[#fff] border-[#e9ebec] py-[18px]'>
        <h2 className='text-base text-[#495057] font-medium  dark:text-[#CED4DA]'>Subscriber Event Details </h2>
        <img
          src={Close}
          onClick={() => onClose()}
          className="border-none cursor-pointer"
        />
      </div>
      <div className=" block text-sm w-full f p-4">
        {Object.entries(data.attributes).map(([key, value]) => (
          <div key={key} className=" flex px-4 pb-4 justify-between ">
            <p className="text-sm font-medium leading-5 text-[#212529] dark:text-[#CED4DA]">
              {key}
            </p>
            &nbsp;
            <Tippy maxWidth={1000} placement='top-start' content={<div
              className=''
            > {value}</div>}>
              <p className={`text-[13px] leading-5 text-[#878A99] max-w-[250px] truncate`}>
                {value}
              </p>
            </Tippy>
          </div>
        ))}
      </div>
    </div>
  );
};
export default SubscriberEventAccordian;
