import React, { useEffect, useState, FC, useRef } from 'react';
import CustomSkeleton from 'components/Skeleton/Skeleton';
import { ChartDataset } from 'chart.js';
import { LatestCampaigns } from 'components/Dashboard/index.type';
import Tabs from 'components/Graph/Tabs';
import Wrapper from 'components/Wrapper';
import BarGraph from 'components/Graph/BarGraph';
import { useOutsideClick } from 'app/hooks/useOutsideClick';
import Modal from 'components/Alert/Modal';

interface Props {
  latestCampaigns: LatestCampaigns[];
  loading: boolean;
}

const TopPerformanceCampaigns: FC<Props> = ({ latestCampaigns, loading }) => {
  const [activeTab, setActiveTab] = useState<string>('Revenue');
  const [, setRedraw] = useState<boolean>(false);
  const [labels, setLabels] = useState<Array<string>>([]);
  const [graphData, setGraphData] = useState<Array<number>>([]);
  const [isFullView, setIsFullView] = useState(false);
  const outsideRef = useRef<HTMLDivElement>(null);

  useOutsideClick(outsideRef, () => setIsFullView(false));

  const getPerformanceData = (tab?: string) => {
    setActiveTab(tab || 'Revenue');
    try {
      const campaignOrders = latestCampaigns.map((campaign) => {
        return campaign.orders;
      });

      const campaignRevenue = latestCampaigns.map((campaign) => {
        return campaign.revenue;
      });

      const averageCampaignRevenue = latestCampaigns.map((campaign) => {
        return campaign.averageRevenue;
      });

      const labels = latestCampaigns.map((campaign) => {
        return campaign.subject;
      });

      setLabels(labels);

      if (tab === 'AOV') {
        setGraphData(averageCampaignRevenue);
      } else if (tab === 'Orders') {
        setGraphData(campaignOrders);
      } else {
        setGraphData(campaignRevenue);
      }
    } catch (error) {
      console.log('error is: ', error);
    }
  };

  useEffect(() => {
    getPerformanceData();
  }, [latestCampaigns]);



  return (
    <Wrapper parentClass="!mt-0 ">
      <div className="hover:rounded-lg hover:shadow-lg overflow-hidden hover:shadow-gray-200 dark:shadow-none hover:bg-white  dark:bg-transparent  storeTable">
        <div className="cardHeader dark:border-[#fff]">
          <h4 className="text-base text-darkText font-medium dark:text-[#878A99]">
            Top Performance
          </h4>
          <Tabs
            activeTab={activeTab}
            onTabClick={(tab) => {
              if (tab === 'Revenue') {
                setRedraw(true);
              } else {
                setRedraw(false);
              }
              getPerformanceData(tab);
            }}
            tabs={['Revenue', 'Orders', 'AOV']}
          />
        </div>
        <div onDoubleClick={() => setIsFullView(true)} className="px-4 pt-0 pb-10 h-[380px] ">
          {loading && !latestCampaigns[0]  ? (
            <CustomSkeleton columns={9} />
          ) : (
            <>
              <BarGraph
                height={186}
                chartLabels={labels}
                datasets={
                  [
                    {
                      data: graphData,
                      backgroundColor:
                        activeTab === 'Orders'
                          ? '#EC5E82'
                          : activeTab === 'AOV'
                            ? '#FBCC55'
                            : '#56C0C1',
                      barPercentage: 1,
                      barThickness: 65,
                    },
                  ] as ChartDataset<'bar', (number | [number, number] | null)[]>[]
                }
                style={{ width: '100%' }}
                hideLegend={true}
                isQuantitativeData={activeTab === 'Orders'}
              />
              <div className="flex justify-between items-center z-999999">
                <Modal isOpen={isFullView}>
                  <div ref={outsideRef} className="h-[70vh] mt-[140px] bg-white dark:bg-[url('assets/Images/Common/denimbg.webp')]  w-[70%] rounded p-6 mx-auto ">
                    <BarGraph
                      height={186}
                      chartLabels={labels}
                      datasets={
                        [
                          {
                            data: graphData,
                            backgroundColor:
                              activeTab === 'Orders'
                                ? '#EC5E82'
                                : activeTab === 'AOV'
                                  ? '#FBCC55'
                                  : '#56C0C1',
                            barPercentage: 1,
                            barThickness: 65,
                          },
                        ] as ChartDataset<'bar', (number | [number, number] | null)[]>[]
                      }
                      style={{ maxWidth: '100%', width: '100%' }}
                      hideLegend={true}
                      isQuantitativeData={activeTab === 'Orders'}
                    />
                  </div>
                </Modal>
              </div>
            </>
          )}
        </div>
      </div>
    </Wrapper>
  );
};
export default TopPerformanceCampaigns;
