import TrackDelivery from './TrackDelivery';
import GoogleAnalytics from './GoogleAnalytics';
import React from 'react';
const DeliveryOptions = () => {
  return (
    <div className='mb-6'>
      <h1 className=" text-lg mb-4 leading-5 text-[#495057] font-medium dark:text-white">
        Delivery Options
      </h1>
      <div className="">
        <h3 className="text-sm font-medium text-[#495057] dark:text-white mb-1">
          Choose Delivery Options
        </h3>
        <p className="text-xs font-normal leading-4 text-[#878a99] dark:text-white">
          Enhance emails and blast reporting with tracking, social sharing, and
          analytics options.
        </p>
        <div className="">
          <TrackDelivery />
          <GoogleAnalytics />
        </div>
      </div>
    </div>
  );
};
export default DeliveryOptions;
