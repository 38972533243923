import React, { FC, useEffect, useState } from 'react';
import {
  PaginationChangePage,
  PaginationChangeRowsPerPage,
} from 'react-data-table-component/dist/src/DataTable/types';
import { useLocation } from 'react-router-dom';
import uuid from 'react-uuid';

interface Props {
  rowsPerPage: number;
  rowCount: number;
  currentPage: number;
  onChangePage: PaginationChangePage;
  onChangeRowsPerPage: PaginationChangeRowsPerPage;
}

const Pagination: FC<Props> = ({
  rowsPerPage,
  rowCount,
  currentPage,
  onChangePage,
}) => {
  const [pages, setPages] = useState<Array<string>>([]);
  const [totalPages, setTotalPages] = useState<string>('');
  const { pathname } = useLocation();

  const nextPageHandler = () => {
    onChangePage(currentPage + 1, rowsPerPage);
  };

  const buttonClickHandler = (page: number) => {
    !isNaN(page) && onChangePage(page, rowsPerPage);
  };

  const previousPageHandler = () => {
    const tempPages = [...pages];
    onChangePage(currentPage - 1, rowsPerPage);
    setPages(tempPages);
  };

  const nextGreaterPageHandler = (allPages: string[]) => {
    if (parseInt(totalPages) > 6) {
        setPages(allPages);
      const tempPages =
        parseInt(totalPages) - currentPage < 5
          ? [
              '1',
              '...',
              (parseInt(totalPages) - 5).toString(),
              (parseInt(totalPages) - 4).toString(),
              (parseInt(totalPages) - 3).toString(),
              (parseInt(totalPages) - 2).toString(),
              (parseInt(totalPages) - 1).toString(),
              totalPages,
            ]
          : [
              '1',
              '...',
              (currentPage - 2).toString(),
              (currentPage - 1).toString(),
              currentPage.toString(),
              (currentPage + 1).toString(),
              (currentPage + 2).toString(),
              '...',
              totalPages,
            ];
      setPages(tempPages);
    }
  };

  const showPaginationStatus = () => {
    if (currentPage === parseInt(totalPages)) {
      return `${currentPage * rowsPerPage - (rowsPerPage - 1)} - ${rowCount}`;
    } else {
      return `${currentPage * rowsPerPage - (rowsPerPage - 1)} - ${
        currentPage * rowsPerPage
      }`;
    }
  };

  useEffect(() => {
    const page = Math.ceil(rowCount / rowsPerPage);
    const tempPage: number[] = [];
    Array(page)
      .fill(0)
      .map((_, index) => tempPage.push(index + 1));
    let allPages = tempPage.map(String);

    setTotalPages(allPages.length.toString());
    allPages =
      parseInt(totalPages) < 7
        ? (allPages = [...allPages])
        : currentPage < 5
        ? (allPages = [
            '1',
            '2',
            '3',
            '4',
            '5',
            '...',
            tempPage.length.toString(),
          ])
        : (allPages = [
            ...allPages.splice(0, currentPage),
            (currentPage + 1).toString(),
            (currentPage + 2).toString(),
            '...',
            tempPage.length.toString(),
          ]);

    currentPage < 5 ? setPages(allPages) : nextGreaterPageHandler(allPages);
  }, [totalPages, rowCount, currentPage]);

  return (
    <div>
      <div
        className={`${
          pathname === '/'
            ? 'absolute bottom-0 border-t dark:border-[#fff] border-[#ced4da] '
            : ''
        } flex overflow-auto flex-wrap w-full justify-between items-center px-4 rounded-b-lg pb-4 pt-2`}
      >
        <div>
          <p className="text-secondary font-medium text-sm">{`Showing ${showPaginationStatus()}  of ${rowCount}`}</p>
        </div>
        <div className="flex items-center text-">
          <button
            onClick={() => previousPageHandler()}
            className={`${
              currentPage === 1 ? 'cursor-not-allowed ' : ' '
            } h-8 w-8	mr-2.5  py-1 rounded-lg flex justify-center items-center border page-active dark:bg-white text-lightParagraphColor`}
            disabled={currentPage === 1}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 text-lightParagraphColor rotate-180"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
              />
            </svg>
          </button>

          <div className="flex">
            {pages.map((page) => (
              <div
                key={uuid()}
                onClick={() => buttonClickHandler(parseInt(page))}
                className={` ${
                  currentPage === parseInt(page)
                    ? 'bg-primary border-primary'
                    : ''
                } cursor-pointer min-w-[28px] rounded border mr-2.5 h-[28px] flex justify-center items-center`}
              >
                <span
                  className={` ${
                    currentPage === parseInt(page) ? 'text-[#FFF]' : ''
                  } text-sm mx-[6px] focus:text-white text-[#000000A6] dark:text-white`}
                >
                  {page}
                </span>
              </div>
            ))}
          </div>
          <button
            onClick={() => nextPageHandler()}
            className={`${
              currentPage === parseInt(totalPages) ? 'cursor-not-allowed ' : ' '
            } h-8 w-8	mr-2.5 py-1 rounded-lg flex justify-center items-center border page-active dark:bg-white text-lightParagraphColor`}
            disabled={currentPage === parseInt(totalPages)}
            data-pw="next-page-button"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-4 h-4 text-lightParagraphColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M4.5 12h15m0 0l-6.75-6.75M19.5 12l-6.75 6.75"
              />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};
export default Pagination;
