import React, { useContext, useEffect, useState } from 'react';
import AverageClick from 'assets/Images/Dashboard/average-click-rate.svg';
import AverageDeliverabilityRate from 'assets/Images/Dashboard/average-deliverability-rate.svg';
import AverageOpen from 'assets/Images/Dashboard/average-open-rate.svg';
import AverageConversion from 'assets/Images/Dashboard/average-conversion-rate.svg';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import EmailPerformance from 'components/Dashboard/Body/EmailPerformance';
import moment from 'moment';
import { Blasts, BlastResponse } from 'components/Dashboard/Body';
import TotalOrder from 'components/Dashboard/Body/Orders/TotalOrders';
import AverageRevenue from 'components/Dashboard/Body/Orders/AverageRevenue';
import Revenue from 'components/Dashboard/Body/Orders/Revenue';
import { storeReportOrder } from 'components/DashboardReports/Body/Store-Report/Types';
import {
  calculatePercentage,
  calculatePercentageDifference,
  calculateRate,
} from 'utils/common';
import { getBlastMonetaryValue } from 'services/apiHandlers/Dashboard/Analytics';
import {
  GetFilteredCampaignResponse,
  GetBlastMonetaryValueResponse,
  FilteredCampaignData,
  CardData,
  BlastData,
  CampaignItemData,
  CampaignGroupData,
  LatestCampaigns,
  TopPerformingData,
} from 'components/Dashboard/index.type';
import { getPreviousSixMonthsCampaignData } from 'utils/dashboard';
import { getFilteredCampaigns } from 'services/apiHandlers/Dashboard/Reports';
import AllCampaigns from 'components/Campaigns/Body/Overview/AllCampaigns';
import TopPerformanceCampaigns from 'components/TopPerformanceGraph/Campaign';
import SentEmails from 'components/DashboardReports/Body/Analytics/SentEmails';
import TopPerformingCampaigns from 'components/Dashboard/Body/TopPerformingCampaigns';
import z from 'zod';
import { UserContext } from 'store/UserContext';

const defaultCardData = [
  {
    title: 'Conversion Rate',
    currentMonthData: 0,
    previousMonthData: 0,
    performanceRate: 0,
    id: '4',
    image: AverageConversion,
  },
  {
    title: 'Open Rate',
    currentMonthData: 0,
    previousMonthData: 0,
    performanceRate: 0,
    id: '1',
    image: AverageOpen,
  },
  {
    title: 'Click Rate',
    currentMonthData: 0,
    previousMonthData: 0,
    performanceRate: 0,
    id: '2',
    image: AverageClick,
  },
  {
    title: 'Delivery Rate',
    currentMonthData: 0,
    previousMonthData: 0,
    performanceRate: 0,
    id: '3',
    image: AverageDeliverabilityRate,
  },
];

const filteredCampaignsSchema = z.object({
  id: z.number(),
  orders: z.number(),
  revenue: z.number(),
  averageRevenue: z.number(),
});
export type FilteredCampaigns = z.infer<typeof filteredCampaignsSchema>;

const Reports = () => {
  const userCtx = useContext(UserContext);
  const [orders, setOrders] = useState<storeReportOrder>();
  const [averageRevenue, setAverageRevenue] = useState<storeReportOrder>();
  const [revenue, setRevenue] = useState<storeReportOrder>();
  const [cardData, setCardData] = useState<CardData>(defaultCardData);
  const [loading, setLoading] = useState<boolean>(true);
  const [latestCampaigns, setLatestCampaigns] = useState<Array<LatestCampaigns>>([]);
  const [topCampaigns, setTopCampaigns] = useState<Array<TopPerformingData>>([]);

  const calculateTopCampaigns = async (
    campaignData: FilteredCampaignData[]
  ) => {
    try {
      const currentYearBlastIds = campaignData
        ?.map((campaign) => {
          return campaign.id;
        })
        .join(',');

      const getCurrentYearBlastMonetaryValues = (await getBlastMonetaryValue(
        currentYearBlastIds
      )) as GetBlastMonetaryValueResponse;

      if (getCurrentYearBlastMonetaryValues?.status === 200) {
        const tempTopCampaigns = getCurrentYearBlastMonetaryValues.data
          .sort((a, b) => b.monetaryValue - a.monetaryValue)
          .slice(0, 3)
          .map((campaign) => {
            return {
              name: campaignData?.filter(
                (filteredCampaign) => filteredCampaign.id === campaign.blastId
              )[0].subject,
              orders: campaign.conversions,
              revenue: campaign.monetaryValue,
              id: campaign.blastId.toString(),
            };
          });
        setTopCampaigns(tempTopCampaigns);
      }
    } catch (error) {
      console.log('Error is ', error);
    }
  };

  const getCampaignData = (
    allCampaigns: FilteredCampaignData[],
    blastCampaigns: BlastData[]
  ) => {
    const filteredCampaigns = allCampaigns?.map((campaign, index) => {
      if (campaign.id === blastCampaigns[index].blastId) {
        return {
          month: moment
            .tz(campaign.created_at, userCtx?.usersData?.timeZone || '')
            .format('MMMM'),
          order: blastCampaigns[index].conversions,
          revenue: blastCampaigns[index].monetaryValue,
          averageRevenue: 0,
        };
      }
    });
    return getPreviousSixMonthsCampaignData(
      filteredCampaigns as CampaignGroupData[]
    );
  };

  const calculateMonthlyPerformanceData = async (
    currentMonthCampaigns: Blasts[],
    previousMonthCampaigns: Blasts[]
  ) => {
    try {
      let previousMonthCampaignsConversionRate = 0;
      let currentMonthCampaignsConversionRate = 0;

      const currentMonthBlastIds = currentMonthCampaigns
        .map((campaign) => {
          return campaign.blast.id;
        })
        .join(',');

      const previousMonthBlastIds = previousMonthCampaigns
        ?.map((campaign) => {
          return campaign.blast.id;
        })
        .join(',');

      const getCurrentMonthBlastMonetaryValues = (await getBlastMonetaryValue(
        currentMonthBlastIds
      )) as GetBlastMonetaryValueResponse;

      const getPreviousMonthBlastMonetaryValues = (await getBlastMonetaryValue(
        previousMonthBlastIds
      )) as GetBlastMonetaryValueResponse;

      const currentMonthCampaignsOpenRate: number =
        currentMonthCampaigns?.reduce((accumulator, campaign) => {
          return accumulator + campaign.blast.views_count;
        }, 0);

      const previousMonthCampaignsOpenRate = previousMonthCampaigns?.reduce(
        (accumulator, campaign) => {
          return accumulator + campaign.blast.views_count;
        },
        0
      );

      const currentMonthCampaignsClickRate = currentMonthCampaigns?.reduce(
        (accumulator, campaign) => {
          return accumulator + campaign.blast.clicks_count;
        },
        0
      );

      const currentMonthCampaignsNumberSent: number =
        currentMonthCampaigns?.reduce((accumulator, campaign) => {
          return accumulator + campaign.blast.number_sent;
        }, 0);

      const previousMonthCampaignsClickRate = previousMonthCampaigns?.reduce(
        (accumulator, campaign) => {
          return accumulator + campaign.blast.clicks_count;
        },
        0
      );

      const previousMonthCampaignsNumberSent: number =
        previousMonthCampaigns?.reduce((accumulator, campaign) => {
          return accumulator + campaign.blast.number_sent;
        }, 0);

      const currentMonthBounceCount = currentMonthCampaigns?.reduce(
        (accumulator, campaign) => {
          return (
            accumulator +
            (campaign.blast.soft_bounces_count +
              campaign.blast.hard_bounces_count)
          );
        },
        0
      );

      const previousMonthBounceCount = previousMonthCampaigns?.reduce(
        (accumulator, campaign) => {
          return (
            accumulator +
            (campaign.blast.soft_bounces_count +
              campaign.blast.hard_bounces_count)
          );
        },
        0
      );

      const currentMonthCampaignsDeliverRate =
        currentMonthCampaignsNumberSent - currentMonthBounceCount;
      const previousMonthCampaignsDeliverRate =
        previousMonthCampaignsNumberSent - previousMonthBounceCount;

      if (getPreviousMonthBlastMonetaryValues?.status === 200) {
        previousMonthCampaignsConversionRate = previousMonthCampaigns?.reduce(
          (accumulator, campaign) => {
            return (
              accumulator +
              calculatePercentage(
                getPreviousMonthBlastMonetaryValues.data.filter(
                  (blast) => blast.blastId === campaign.blast.id
                )[0].conversions,
                campaign.blast.number_sent
              )
            );
          },
          0
        );
      }

      if (getCurrentMonthBlastMonetaryValues?.status === 200) {
        currentMonthCampaignsConversionRate = currentMonthCampaigns?.reduce(
          (accumulator, campaign) => {
            return (
              accumulator +
              calculatePercentage(
                getCurrentMonthBlastMonetaryValues.data.filter(
                  (blast) => blast.blastId === campaign.blast.id
                )[0].conversions,
                campaign.blast.number_sent
              )
            );
          },
          0
        );
      }

      setCardData([
        {
          title: 'Conversion Rate',
          currentMonthData: calculatePercentage(
            currentMonthCampaignsConversionRate,
            currentMonthCampaignsNumberSent
          ),
          previousMonthData: calculatePercentage(
            previousMonthCampaignsConversionRate,
            previousMonthCampaignsNumberSent
          ),
          performanceRate: calculatePercentageDifference(
            currentMonthCampaignsConversionRate,
            previousMonthCampaignsConversionRate
          ),
          id: '1',
          image: AverageConversion,
        },
        {
          title: 'Open Rate',
          currentMonthData: calculatePercentage(
            currentMonthCampaignsOpenRate,
            currentMonthCampaignsNumberSent
          ),
          previousMonthData: calculatePercentage(
            previousMonthCampaignsOpenRate,
            previousMonthCampaignsNumberSent
          ),
          performanceRate: calculatePercentageDifference(
            currentMonthCampaignsOpenRate,
            previousMonthCampaignsOpenRate
          ),
          id: '2',
          image: AverageOpen,
        },
        {
          title: 'Click Rate',
          currentMonthData: calculatePercentage(
            currentMonthCampaignsClickRate,
            currentMonthCampaignsNumberSent
          ),
          previousMonthData: calculatePercentage(
            previousMonthCampaignsClickRate,
            previousMonthCampaignsNumberSent
          ),
          performanceRate: calculatePercentageDifference(
            currentMonthCampaignsClickRate,
            previousMonthCampaignsClickRate
          ),
          id: '3',
          image: AverageClick,
        },
        {
          title: 'Delivery Rate',
          currentMonthData: calculatePercentage(
            currentMonthCampaignsDeliverRate,
            currentMonthCampaignsNumberSent
          ),
          previousMonthData: calculatePercentage(
            previousMonthCampaignsDeliverRate,
            previousMonthCampaignsNumberSent
          ),
          performanceRate: calculatePercentageDifference(
            currentMonthCampaignsDeliverRate,
            previousMonthCampaignsDeliverRate
          ),
          id: '4',
          image: AverageDeliverabilityRate,
        },
      ]);
    } catch (error) {
      console.log('error is : ', error);
    }
  };

  const calculatePerformanceData = async (
    currentYearCampaignData: FilteredCampaignData[],
    previousYearCampaignData: FilteredCampaignData[]
  ) => {
    try {
      let tempCurrentYearCampaignData: CampaignItemData[] = [];
      let tempPreviousYearCampaignData: CampaignItemData[] = [];

      calculateTopCampaigns(currentYearCampaignData);

      const currentMonth = moment();

      const months = Array.from({ length: 6 }, (_, index) => {
        const month = currentMonth.clone().subtract(index, 'month');
        return month.format('MMM');
      }).reverse();

      const currentYearCampaignIds = currentYearCampaignData
        ?.map((campaign) => campaign.id)
        .join(',');

      const previousYearCampaignIds = previousYearCampaignData
        ?.map((campaign) => campaign.id)
        .join(',');

      const latestCampaignIds = currentYearCampaignData
        .slice(
          currentYearCampaignData.length - 10,
          currentYearCampaignData.length
        )
        ?.map((campaign) => campaign.id)
        .join(',');

      const getCurrentYearBlastMonetaryValues = (await getBlastMonetaryValue(
        currentYearCampaignIds
      )) as GetBlastMonetaryValueResponse;

      const getLatestCampaignBlastMonetaryValues = (await getBlastMonetaryValue(
        latestCampaignIds
      )) as GetBlastMonetaryValueResponse;

      const getPreviousYearBlastMonetaryValues = (await getBlastMonetaryValue(
        previousYearCampaignIds
      )) as GetBlastMonetaryValueResponse;

      if (
        getLatestCampaignBlastMonetaryValues?.status === 200 &&
        currentYearCampaignIds.length > 0
      ) {
        const filteredCampaigns: FilteredCampaigns[] =
          getLatestCampaignBlastMonetaryValues?.data.map((campaign) => {
            return {
              id: campaign.blastId,
              orders: campaign.conversions,
              revenue: campaign.monetaryValue,
              averageRevenue: calculateRate(
                campaign.monetaryValue,
                campaign.conversions
              ),
            };
          });

        const tempLatestCampaigns = currentYearCampaignData
          .slice(
            currentYearCampaignData.length - 5,
            currentYearCampaignData.length
          )
          .map((campaign) => {
            const matchedData = filteredCampaigns.find(
              (campaignItem: FilteredCampaigns) =>
                campaignItem.id === campaign.id
            );

            if (matchedData) {
              return {
                id: matchedData.id,
                orders: Math.round(matchedData.orders),
                revenue: Math.round(matchedData.revenue),
                averageRevenue: Math.round(matchedData.averageRevenue),
                date: moment(campaign.created_at).format('DD MMMM'),
                subject: campaign.subject,
              };
            }
          });

        if (tempLatestCampaigns) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          setLatestCampaigns(tempLatestCampaigns);
        }
      } else {
        tempCurrentYearCampaignData = getCampaignData(
          currentYearCampaignData,
          []
        );
      }
      if (
        getLatestCampaignBlastMonetaryValues?.status === 200 &&
        latestCampaignIds.length > 0
      ) {
        tempCurrentYearCampaignData = getCampaignData(
          currentYearCampaignData,
          getCurrentYearBlastMonetaryValues.data
        );
      } else {
        tempCurrentYearCampaignData = getCampaignData(
          currentYearCampaignData,
          []
        );
      }
      if (
        getPreviousYearBlastMonetaryValues?.status === 200 &&
        previousYearCampaignIds.length > 0
      ) {
        tempPreviousYearCampaignData = getCampaignData(
          previousYearCampaignData,
          getPreviousYearBlastMonetaryValues.data
        );
      } else {
        tempPreviousYearCampaignData = getCampaignData(
          previousYearCampaignData,
          []
        );
      }
      const averageRevenueData = [
        {
          label: ' Current Year',
          data: tempCurrentYearCampaignData.map(
            (campaign) => campaign.averageRevenue?.toString() || '0'
          ),
          backgroundColor: '#2EA1F9',
          barPercentage: 1.0,
          barThickness: 25,
        },
        {
          label: ' Previous Year',
          data: tempPreviousYearCampaignData.map(
            (campaign) => campaign.averageRevenue?.toString() || '0'
          ),
          backgroundColor: '#8FDBC0',
          barPercentage: 1.0,
          barThickness: 25,
        },
      ];

      const revenueData = [
        {
          label: ' Current Year',
          data: tempCurrentYearCampaignData.map((campaign) =>
            campaign.revenue.toString()
          ),
          borderColor: '#FBB846',
          backgroundColor: '#FBB846',
        },
        {
          label: ' Previous Year',
          data: tempPreviousYearCampaignData.map((campaign) =>
            campaign.revenue.toString()
          ),
          borderColor: '#A292DA',
          backgroundColor: '#A292DA',
        },
      ];

      const OrdersData = [
        {
          label: '  Current Year',
          data: tempCurrentYearCampaignData.map((campaign) =>
            campaign.order.toString()
          ),
          borderColor: '#FBB846',
          backgroundColor: '#FBB846',
        },
        {
          label: '  Previous Year',
          data: tempPreviousYearCampaignData.map((campaign) =>
            campaign.order.toString()
          ),
          borderColor: '#A292DA',
          backgroundColor: '#A292DA',
        },
      ];

      const totalOrders = {
        orderType: 'Total Orders',
        orderValue: '0',
        orderPercentage: '0',
        graphType: 'Line',
        graphCategories: months,
        graphData: OrdersData,
        graphHeight: 310,
        graphWidth: 450,
        graphStyle: {
          
          width: '100%',
          height: 'auto',
        },
        lastMonth: '0',
        last90Days: '0',
        last14Days: '0',
      };

      const AverageRevenue = {
        orderType: 'AOV',
        orderValue: '$0',
        orderPercentage: '0',
        graphType: 'Coloumn',
        graphCategories: months,
        graphData: averageRevenueData,
        graphStyle: { width: '100%' },
        graphHeight: 206,
        lastMonth: `$0`,
        last90Days: `$0`,
        last14Days: `$0`,
      };

      const Revenue = {
        orderType: 'Revenue',
        orderValue: `$0`,
        orderPercentage: '0',
        graphType: 'Line',
        graphCategories: months,
        graphData: revenueData,
        graphHeight: 310,
        graphWidth: 450,
        graphStyle: {
         
          width: '100%',
          height: 'auto',
        },
        lastMonth: `$0`,
        last90Days: `$0`,
        last14Days: `$0`,
      };

      setOrders(totalOrders);
      setAverageRevenue(AverageRevenue);
      setRevenue(Revenue);
    } catch (error) {
      console.log('error is LAST ONE: ', error);
    }
  };

  const getReportsData = async () => {
    setLoading(true);
    const currentYearMonthDate = moment().format('YYYY-MM-DD');
    const currentYearLastSixMonthDate = moment()
      .subtract(5, 'months')
      .startOf('month')
      .format('YYYY-MM-DD');
    const previousYearDate = moment().subtract(1, 'year').format('YYYY-MM-DD');
    const previousYearSixMonthsDate = moment(previousYearDate)
      .subtract(5, 'months')
      .startOf('month')
      .format('YYYY-MM-DD');
    const previousMonthStart = moment()
      .subtract(1, 'months')
      .startOf('month')
      .format('YYYY-MM-DD');
    const previousMonthEnd = moment()
      .subtract(1, 'months')
      .endOf('month')
      .format('YYYY-MM-DD');
    const currentMonthStart = moment().startOf('month').format('YYYY-MM-DD');
    try {
      const getCurrentMonthFilteredCampaignsResponse =
        (await getFilteredCampaigns(
          currentMonthStart,
          currentYearMonthDate
        )) as BlastResponse;

      const getPreviousMonthFilteredCampaignsResponse =
        (await getFilteredCampaigns(
          previousMonthStart,
          previousMonthEnd
        )) as BlastResponse;

      const getCurrentYearFilteredCampaignsResponse =
        (await getFilteredCampaigns(
          currentYearLastSixMonthDate,
          currentYearMonthDate,
          'report'
        )) as GetFilteredCampaignResponse;

      const getPreviousYearFilteredCampaignsResponse =
        (await getFilteredCampaigns(
          previousYearSixMonthsDate,
          previousYearDate,
          'report'
        )) as GetFilteredCampaignResponse;

      if (
        getCurrentMonthFilteredCampaignsResponse.status === 200 &&
        getPreviousMonthFilteredCampaignsResponse.status === 200
      ) {
        calculateMonthlyPerformanceData(
          getCurrentMonthFilteredCampaignsResponse.data,
          getPreviousMonthFilteredCampaignsResponse.data
        );
      }
      if (
        getCurrentYearFilteredCampaignsResponse.status === 200 &&
        getPreviousYearFilteredCampaignsResponse.status === 200 &&
        getCurrentMonthFilteredCampaignsResponse.status === 200 &&
        getPreviousMonthFilteredCampaignsResponse.status === 200
      ) {
        calculatePerformanceData(
          getCurrentYearFilteredCampaignsResponse.data,
          getPreviousYearFilteredCampaignsResponse.data
        );
      }
    } catch (error) {
      console.log('error is : ', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getReportsData();
  }, []);
  return (
    <div>
      <div className="bg-white h-[61px] md:px-6 px-3 py-2 font-inter border-b shadow-0 shadow flex justify-between items-center dark:border-white dark:shadow-none dark:bg-[#41464E]">
        <Breadcrumbs />
      </div>
      <div className="pt-5 h-full">
        <div className="p-4">
          <div className="flex 2xl:flex-row flex-col gap-4 w-full ">
            <div className="2xl:w-[70%] w-full">
              <div className="flex items-center mb-4 gap-4 xl:justify-between justify-center flex-wrap xl:flex-nowrap ">
                <div className="2xl:w-[32%] xl:w-[33%] sm:w-[48%] w-full  hover:rounded-lg hover:shadow-lg hover:shadow-gray-200 dark:shadow-none dark:bg-[#41464E] dark:shadow-lg  dark:hover:shadow-2xl">
                  <Revenue revenue={revenue} loading={loading} />
                </div>
                <div className="2xl:w-[32%] xl:w-[33%] sm:w-[48%] w-full  hover:rounded-lg hover:shadow-lg hover:shadow-gray-200 dark:shadow-none dark:shadow-none dark:bg-[#41464E] dark:shadow-lg  dark:hover:shadow-2xl">
                  <TotalOrder
                    orders={orders}
                    loading={loading}
                    isQuantitativeData={true}
                  />
                </div>
                <div className="2xl:w-[32%] xl:w-[33%] sm:w-[48%] w-full  hover:rounded-lg hover:shadow-lg hover:shadow-gray-200 dark:shadow-none dark:shadow-none dark:bg-[#41464E] dark:shadow-lg  dark:hover:shadow-2xl">
                  <AverageRevenue
                    averageRevenue={averageRevenue}
                    loading={loading}
                  />
                </div>
              </div>
              <div className="flex w-full md:flex-row flex-col gap-4 dark:shadow-none dark:bg-[#41464E] dark:shadow-lg  dark:hover:shadow-2xl">
                <div className=" w-full mb-4 ">
                  <TopPerformanceCampaigns
                    latestCampaigns={latestCampaigns}
                    loading={!orders ? true :loading}
                  />
                </div>
              </div>
            </div>
            <div className="2xl:w-[30%] 2xl:block flex md:flex-row flex-col mb-2 h-fit justify-between">
              <div className="mb-4 2xl:w-full md:w-[49%] bg-white dark:shadow-none dark:bg-[#41464E]">
                <SentEmails />
              </div>
              <div className=" mb-4 2xl:w-full md:w-[49%] 2xl:h-[377px] md:h-[332px] shadow-lg hover:shadow-xl dark:shadow-none dark:bg-[#41464E] dark:shadow-lg  dark:hover:shadow-2xl">
                <TopPerformingCampaigns
                  campaigns={topCampaigns}
                  loading={loading}
                />
              </div>
            </div>
          </div>
         
            <div className="flex xl:flex-nowrap flex-wrap justify-between gap-4 mb-4 w-full">
              {cardData?.map((email) => (
                <div
                  key={email.id}
                  className="md:w-[48%] lg:w-[24%] w-full shadow-lg hover:shadow-xl   dark:shadow-none dark:bg-[#41464E] dark:shadow-lg  dark:hover:shadow-2xl"
                >
                  <EmailPerformance
                    emailPerformance={email}
                    loading={loading}
                  />
                </div>
              ))}
            </div>
           <div className='dark:bg-[#41464E]'>     
          <AllCampaigns isReports={true} />
          </div>
        </div>
      </div>
    </div>
  );
};
export default Reports;
