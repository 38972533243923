import React from "react"
interface Props {
  onChange: () => void;
  checked?: boolean;
  id?: number;
}
const ToggleSwitch = ({ onChange, checked, id }: Props) => {
  return (
    <label
      data-pw={`${id === 0 ? 'first-toggle' : ''}`}
      htmlFor={id?.toString()}
      className="inline-flex relative items-center cursor-pointer mr-4"
    >
      <input
        onChange={onChange}
        type="checkbox"
        value=""
        id={id?.toString()}
        className="sr-only peer"
        checked={checked}
  
      />
      <div className="w-[38px] h-5 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer  dark:bg-[#0b2e53]  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[3px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all dark:border-gray-600 peer-checked:bg-primary"></div>
    </label>
  );
};
export default ToggleSwitch;
