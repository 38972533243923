import React, { useContext, useEffect, useState } from 'react';
import Dropdown from 'components/Dropdown/valueDropdown';
import DatePicker from 'components/Datepicker';
import { getAllWorkflows } from 'services/apiHandlers/Dashboard/Analytics';
import { z } from 'zod';
import { WorkflowSchema } from 'components/Workflow/Body/Workflows/index.type';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { getWorkflowTransactionReports } from 'services/apiHandlers/Workflows/Workflow';
import Header from './Header';
import ReportsTable from './ReportsTable';
import { UserContext } from 'store/UserContext';
import Wrapper from 'components/Wrapper';

const workflowReportsDataSchema = z.object({
  name: z.string(),
  monetaryValue: z.number(),
  bounces: z.number(),
  clicks: z.number(),
  complaints: z.number(),
  delivered: z.number(),
  failed: z.number(),
  skipped: z.number(),
  inactiveSkipped: z.number(),
  unsubscribeSkipped: z.number(),
  totalSent: z.number(),
  uniqueClicks: z.number(),
  uniqueConversions: z.number(),
  uniquePeople: z.number(),
  uniqueViews: z.number(),
  unsubscribes: z.number(),
  views: z.number(),
  dateTime: z.number(),
});

const workflowResponseDataSchema = z.object({
  data: z.object({ data: z.array(workflowReportsDataSchema) }),
  status: z.number(),
});

const AllWorkflowResponseSchema = z.object({
  data: z.object({ triggers: z.array(WorkflowSchema) }),
  status: z.number(),
});

const optionsSchema = z.object({ name: z.string(), value: z.string() });

type Options = z.infer<typeof optionsSchema>;
type WorkflowResponse = z.infer<typeof AllWorkflowResponseSchema>;
export type WorkflowType = z.infer<typeof WorkflowSchema>;
type WorkflowReportsResponse = z.infer<typeof workflowResponseDataSchema>;
export type WorkflowReportsData = z.infer<typeof workflowReportsDataSchema>;

const WorkflowReports = () => {
  const [workflows, setWorkflows] = useState<Array<WorkflowType>>([]);
  const [selectedWorkflowData, setSelectedWorkflowData] =
    useState<WorkflowType>();
  const [workflowOptions, setWorkflowOptions] = useState<Array<Options>>([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState<string>('');
  const [workflowsLoading, setWorkflowsLoading] = useState<boolean>(true);
  const [startDate, setStartDate] = useState<string>(
    moment().format('YYYY-MM-DD')
  );
  const [endDate, setEndDate] = useState<string>(
    moment().subtract(1, 'month').format('YYYY-MM-DD')
  );
  const [workflowReports, setWorkflowReports] = useState<
    Array<WorkflowReportsData>
  >([]);
  const [filteredReports, setFilteredReports] = useState<
    Array<WorkflowReportsData>
  >([]);
  const [reportsLoading, setReportsLoading] = useState<boolean>(true);
  const [totalMails, setTotalMails] = useState<number>();
  const [sentMails, setSentMails] = useState<number>();
  const userCtx = useContext(UserContext);
  const { id } = useParams();

  const getWorkflowReports = async (
    startDate: string,
    endDate: string,
    id: string
  ) => {
    try {
      const getWorkflowReportResponse = (await getWorkflowTransactionReports(
        id,
        endDate,
        startDate
      )) as WorkflowReportsResponse;
      if (getWorkflowReportResponse.status === 200) {
        setWorkflowReports(getWorkflowReportResponse.data.data);
        setFilteredReports(getWorkflowReportResponse.data.data);

        setTotalMails(getWorkflowReportResponse.data.data.length);
        const tempSentMails = getWorkflowReportResponse.data.data.reduce(
          (accumulator, report) => {
            return accumulator + report.totalSent;
          },
          0
        );

        setSentMails(tempSentMails);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
    setReportsLoading(false);
  };

  const getWorkflows = async () => {
    try {
      const getWorkflowsResponse =
        (await getAllWorkflows()) as WorkflowResponse;
      if (getWorkflowsResponse.status === 200) {
        setWorkflows(getWorkflowsResponse.data.triggers);
        const tempWorkflowOptions = getWorkflowsResponse.data.triggers.map(
          (trigger) => {
            return {
              name: trigger.name || '',
              value: trigger.id?.toString() || '',
            };
          }
        );

        setWorkflowOptions(tempWorkflowOptions);

        const filteredWorkflow = getWorkflowsResponse.data.triggers.filter(
          (trigger) => trigger.id === id
        );

        setSelectedWorkflowData(filteredWorkflow[0]);
        setSelectedWorkflow(filteredWorkflow[0].name || '');
        getWorkflowReports(
          moment(startDate).format('YYYY-MM-DD'),
          moment(endDate).format('YYYY-MM-DD'),
          filteredWorkflow[0].id || ''
        );
      }
    } catch (error) {
      console.log('error is : ', error);
      setReportsLoading(false);
    }
    setWorkflowsLoading(false);
  };

  const onWorkflowSelect = (id: string) => {
    const filteredWorkflow = workflows.filter((workflow) => workflow.id === id);
    setSelectedWorkflow(filteredWorkflow[0].name || '');
    setSelectedWorkflowData(filteredWorkflow[0]);
    setReportsLoading(true);
    getWorkflowReports(
      moment().subtract(1, 'month').format('YYYY-MM-DD'),
      moment().format('YYYY-MM-DD'),
      filteredWorkflow[0]?.id || ''
    );
    setStartDate(moment().format('YYYY-MM-DD'));
    setEndDate(moment().subtract(1, 'month').format('YYYY-MM-DD'));
  };

  useEffect(() => {
    getWorkflows();
  }, []);

  useEffect(() => {
    const searchInterval = setTimeout(() => {
      if (userCtx?.filteredValue) {
        const tempReports = workflowReports.filter((workflow) =>
          workflow.name
            .toLowerCase()
            .includes(userCtx.filteredValue.toLowerCase())
        );
        setFilteredReports(tempReports);
      } else {
        setFilteredReports(workflowReports);
      }
    }, 1000);

    return () => {
      clearInterval(searchInterval);
    };
  }, [userCtx?.filteredValue, workflowReports]);

  return (
    <div>
      <div className="bg-white dark:bg-transparent h-[61px] md:px-6 px-3 py-2 font-inter border-b shadow-0 shadow flex justify-between items-center dark:border-[#fff]">
        <Breadcrumbs />
      </div>
      <div className="px-4 py-8 min-h-[76vh]">
        <Wrapper parentClass="!mt-0">
          <>
            <div className="flex justify-between items-center px-5 border-b dark:border-[#fff] border-[#e9ebec] py-[18px]">
              <h2 className="text-base text-[#495057] font-medium  dark:text-[#CED4DA]">
                Workflows
              </h2>
              <div className="w-full max-w-xs h-11 flex items-center rounded relative">
                <Dropdown
                  options={workflowOptions}
                  value={selectedWorkflow}
                  onSelect={(value: string) => onWorkflowSelect(value)}
                />
              </div>
            </div>

            <div className="px-6 py-4 flex justify-between items-center">
              <div>
                <h3 className="text-base leading-5 text-[#212529] dark:text-[#fff] font-medium">
                  Action report
                </h3>
                <p className="text-sm font-normal leading-4 text-[#878A99] mt-2">
                  A summary of transactions which occurred within the selected
                  date range.
                </p>
              </div>
              {!reportsLoading && workflowReports.length > 0 && (
                <div className="flex items-center">
                  <div className="text-[#212529] font-medium">
                    Send Email ({totalMails})
                  </div>
                  <div className="text-[#878A99] ml-1 text-sm">
                    emails Sent {sentMails}
                  </div>
                </div>
              )}
            </div>

            <Header
              workflowsLoading={workflowsLoading}
              selectedWorkflowData={selectedWorkflowData}

            />

            <div className="mt-6 relative flex items-center justify-end flex-wrap sm:px-0 px-3">
              <div className="relativ py-1.5 px-3">
                <DatePicker
                  date={endDate}
                  onDateChange={(date) => {
                    setEndDate(date);
                  }}
                />
              </div>
              <p className="text-xs font-normal leading-5 text-primary text-center  dark:text-white">
                through
              </p>
              <div className="relative relativ mx-3">
                <DatePicker
                  date={startDate}
                  onDateChange={(date) => {
                    setStartDate(date);
                  }}
                />
              </div>
              <button
                onClick={() => {
                  setReportsLoading(true);
                  getWorkflowReports(
                    moment(startDate).format('YYYY-MM-DD'),
                    moment(endDate).format('YYYY-MM-DD'),
                    selectedWorkflowData?.id || ''
                  );
                }}
                className=" text-primary text-13 underline rounded mr-6 relative"
              >
                Apply
              </button>
            </div>

            <ReportsTable
              reportsLoading={reportsLoading}
              workflowReports={filteredReports}
            />
          </>
        </Wrapper>
      </div>
    </div>
  );
};
export default WorkflowReports;
