import { FC } from 'react';
import ModalWrapper from 'components/Workflow/ModalWrapper';
import React from 'react';

interface Props {
    onClose: () => void;
    onOk: () => void;
}
const DiscardModal: FC<Props> = ({ onClose, onOk }) => {
    return (
        <ModalWrapper onClose={onClose} buttonTxt="Ok" onSave={onOk}>
            <div className="bg-white dark:bg-transparent">
                <h2 className="sm:text-xl font-semibold text-base sm:leading-5 leading-3 text-[#495057]  mb-8 dark:text-white">
                    Discard Changes?
                </h2>
                <p className="text-xs leading-3 tracking-wide text-[#495057]   block mt-4 dark:text-white">
                    Are you sure you want to change your template? This could
                    erase your changes.
                </p>
            </div>
        </ModalWrapper>
    );
};
export default DiscardModal;
