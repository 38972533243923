import FullCalendar from '@fullcalendar/react';
import interactionPlugin from '@fullcalendar/interaction';
import dayGridPlugin from '@fullcalendar/daygrid';
import '@fullcalendar/daygrid/main.css';
import moment from 'moment';
import { createRef, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ThemeContext } from 'store/ThemeContext';
import { z } from 'zod';
import ErrorBoundary from 'components/ErrorBoundary';
import React from 'react';
import { Link } from 'react-router-dom';
import { campaignReport } from 'services/constant/routes';
import BreadcrumbsWrapper from 'components/Wrapper/BreadcrumbsWrapper';
import Wrapper from 'components/Wrapper';
import { GetFilteredCampaignResponse } from 'components/Dashboard/index.type';
import { getFilteredCampaigns } from 'services/apiHandlers/Dashboard/Reports';

const CalendarSchema = z.object({
    title: z.string(),
    start: z.string(),
    allDay: z.boolean(),
    publicId: z.number(),
});
const eventSchema = z.object({
    timeText: z.string(),
    event: z.object({
        title: z.string(),
        extendedProps: z.object({ publicId: z.string() }),
    }),
});

type CalendarEvent = z.infer<typeof CalendarSchema>;
type Event = z.infer<typeof eventSchema>;

const StoreCalendar = () => {
    const themeCtx = useContext(ThemeContext);
    const theme = themeCtx?.theme;
    const [events, setEvents] = useState<Array<CalendarEvent>>([]);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const calendarRef = createRef<any>();

    const StyledWrapper = styled.div`
        .fc-daygrid-day {
            height: 130px;
            border-style: dashed;
        }
        .fc-media-screen {
            padding: 16px;
        }
        .fc-toolbar-chunk .fc-toolbar-title {
            color: ${theme === 'dark' ? '#FFFFFF' : '#495057'};
            font-weight: 500;
            font-size: 16px !important;
        }
        .fc-prev-button,
        .fc-next-button {
            background-color: rgba(71, 136, 255, 0.15);
            height: 32px;
            width: 36px;
            padding: 0px;
            border: unset;
        }
        .fc-next-button:hover,
        .fc-prev-button:hover,
        .fc-next-button:active,
        .fc-prev-button:active {
            background-color: #506fe4;
        }
        .fc-prev-button .fc-icon-chevron-left,
        .fc-next-button .fc-icon-chevron-right {
            color: ${theme === 'dark' ? '#FFFFFF !important' : '#4788ff'};
        }
        .fc-prev-button:hover .fc-icon-chevron-left,
        .fc-next-button:hover .fc-icon-chevron-right {
            color: #fff;
        }
        .fc-header-toolbar .fc-today-button,
        .fc-header-toolbar .fc-dayGridMonth-button,
        .fc-dayGridWeek-button,
        .fc-dayGridDay-button {
            background-color: rgba(71, 136, 255, 0.15);
            color: ${theme === 'dark' ? '#FFFFFF' : '#4788ff'};
            font-size: 14px;
            border: unset;
            text-transform: capitalize;
        }
        .fc-header-toolbar .fc-dayGridMonth-button:hover,
        .fc-dayGridWeek-button:hover,
        .fc-dayGridDay-button:hover {
            background-color: #506fe4 !important;
            color: #fff !important;
        }
        .fc-button-group .fc-button-active {
            background-color: #506fe4 !important;
            color: #fff !important;
        }
        .fc-button-group .fc-button-active:focus,
        .fc .fc-button-primary:focus {
            box-shadow: none;
        }
        .fc-direction-ltr .fc-daygrid-event.fc-event-start .boxShadow-left {
            box-shadow: #38a0df 5px 0px 0px inset;
        }
        .fc-col-header thead {
            height: 36px;
        }
        .fc-col-header thead .fc-col-header-cell-cushion {
            color: ${theme === 'dark' ? '#FFFFFF' : '#212529'}
            font-weight: 500;
        }
        .fc-col-header thead .fc-scrollgrid-sync-inner {
            text-align: center;
        }
        .fc-col-header thead .fc-col-header-cell {
            vertical-align: middle;
        }
        .fc .fc-daygrid-day-top a {
            color: ${theme === 'dark' ? '#FFFFFF' : '#212529'};
            text-align: right;
            font-weight: 500;
            font-size: 14px;
        }
        .fc .fc-daygrid-day-top {
            justify-content: end;
            padding-right: 4px;
        }

        .fc-col-header-cell-cushion {
            color: ${theme === 'dark' ? '#FFFFFF' : '#212121'};
        }
        .fc-daygrid-day-number {
            color: ${theme === 'dark' ? '#FFFFFF' : '#212121'};
        }
        .fc-daygrid-more-link {
            color: ${theme === 'dark' ? '#FFFFFF' : '#212121'};
        }
        .fc-scrollgrid-sync-inner {
            color: ${theme === 'dark' ? '#FFFFFF' : '#333333'};
            text-align: left;
            font-weight: 500;
            font-size: 14px;
            min-width: 100px;
        }

        .fc-daygrid-day {
            height: 130px;
            border-style: dashed;
        }

        .fc-col-header-cell-cushion {
            color: ${theme === 'dark' ? '#FFFFFF' : '#212121'};
        }
        .fc-daygrid-day-number {
            color: ${theme === 'dark' ? '#FFFFFF' : '#212121'};
        }
        .fc-daygrid-more-link {
            color: ${theme === 'dark' ? '#FFFFFF' : '#212121'};
        }
        .fc-scrollgrid-sync-inner {
            color: ${theme === 'dark' ? '#FFFFFF' : '#333333'};
            text-align: left;
            font-weight: 500;
            font-size: 14px;
            min-width: 100px;
        }

        .fc-daygrid-day {
            height: 130px;
            border-style: dashed;
        }

        .fc-col-header-cell-cushion {
            color: ${theme === 'dark' ? '#FFFFFF' : '#212121'};
        }
        .fc-daygrid-day-number {
            color: ${theme === 'dark' ? '#FFFFFF' : '#212121'};
        }
        .fc-daygrid-more-link {
            color: ${theme === 'dark' ? '#FFFFFF' : '#212121'};
        }
        .fc-scrollgrid-sync-inner {
            color: ${theme === 'dark' ? '#FFFFFF' : '#333333'};
            text-align: left;
            font-weight: 500;
            font-size: 14px;
            min-width: 100px;
        }

        .fc .fc-view-harness {
            overflow: auto;
        }
        .fc .fc-view {
            min-width: 700px;
        }
        .fc-theme-standard .fc-popover-header {
            height: 35px;
        }
        @media (max-width: 767px) {
            .fc-media-screen {
                padding: 16px 8px;
            }
        }

        .fc .fc-view-harness {
            overflow: auto;
        }
        .fc .fc-view {
            min-width: 700px;
        }
        .fc .fc-daygrid-day.fc-day-today {
            background: #f8f5fe;
        }
        @media (max-width: 767px) {
            .fc-media-screen {
                padding: 16px 8px;
            }
        }

        .fc .fc-view-harness {
            overflow: auto;
        }
        .fc .fc-view {
            min-width: 700px;
        }

        .fc-prev-button .fc-icon-chevron-left {
            color: #5338a0;
        }
        .fc-next-button,
        .fc-next-button:hover {
            border: none;

            border-radius: 4px;
        }
        .fc-next-button .fc-icon-chevron-right {
            color: #5338a0;
        }

        a.fc-event,
        a.fc-event:hover {
            background: none;
        }

        @media (max-width: 767px) {
            .fc-media-screen {
                padding: 16px 8px;
            }
        }
    `;
    const renderEventContent = (eventInfo: Event) => {
        return (
            <Link
                to={`${campaignReport}/${eventInfo.event.extendedProps.publicId}`}
                className="w-full hover:shadow-none hover:border-none hover:outline-none"
            >
                <div className="pl-2 bg-transparentPrimary px-1.5 py-2 w-full boxShadow-left">
                    <p className="text-sm leading-4 text-black-700 overflow-hidden overflow-ellipsis xl:w-28 w-8 whitespace-nowrap mb-1.5">
                        {eventInfo.event.title}
                    </p>
                    <p className="text-xs font-medium leading-4 text-gray-700 mb-0">
                        {eventInfo.timeText}
                    </p>
                </div>
            </Link>
        );
    };
    const getEvents = async () => {
        try {
            const getCampaignsResponse = (await getFilteredCampaigns(
                moment().subtract(10, 'years').format('YYYY-MM-DD'),
                moment().add(1,'years').format('YYYY-MM-DD'),
                'no_pagination'
            )) as GetFilteredCampaignResponse;

            if (getCampaignsResponse.status === 200) {
                const campaignEvents = getCampaignsResponse.data.map(
                    (campaign) => ({
                        title: campaign.subject,
                        start: campaign.created_at,
                        allDay: false,
                        publicId: campaign.id,
                    })
                );

                setEvents(campaignEvents as CalendarEvent[]);
            }
        } catch (error) {
            console.log('error is : ', error);
        }
    };

    // const getEventDates = (month: string, year: string) => {
    //   const changedDate = moment(`${year}-${month}`, 'YYYY-MMMM');

    //   const currentDate = moment();

    //   const isCurrentMonthYear =
    //     changedDate.isSame(currentDate, 'month') &&
    //     changedDate.isSame(currentDate, 'year');

    //   const startOfMonth = changedDate.startOf('month').format('YYYY-MM-DD');

    //   if (isCurrentMonthYear) {
    //     const endOfMonth = currentDate.format('YYYY-MM-DD');
    //     getEvents(startOfMonth, endOfMonth);
    //   } else {
    //     const endOfMonth = changedDate.endOf('month').format('YYYY-MM-DD');
    //     getEvents(startOfMonth, endOfMonth);
    //   }
    // };

    useEffect(() => {
        getEvents();
    }, []);

    return (
        <ErrorBoundary>
            <BreadcrumbsWrapper />
            <div className=" font-inter py-7 md:px-6 px-3 pb-8">
                <div className="md:px-1.5">
                    <h1 className="sm:text-xl text-base sm:leading-5 leading-3 text-black dark:text-white font-medium">
                        Calendar View
                    </h1>
                    <Wrapper parentClass={` ${theme === 'dark' ? 'CalenderDarkMode' : ''}  `}>
                        <StyledWrapper>
                            <FullCalendar
                                ref={calendarRef}
                                customButtons={{
                                    customTitle: {
                                        text: 'Dec',
                                        classNames: 'bg-white',
                                    },
                                    prev: {
                                        text: 'Previous',
                                        click: () => {
                                            if (calendarRef.current) {
                                                // const changedMonth = calendarRef.current
                                                //   .getApi()
                                                //   .currentDataManager.data.viewTitle.split(' ');

                                                // if (changedMonth[0] === 'January') {

                                                //   getEventDates(
                                                //     moment(changedMonth[0], 'MMMM')
                                                //       .subtract(1, 'months')
                                                //       .format('MMMM'),
                                                //     (parseInt(changedMonth[1]) - 1).toString()
                                                //   );
                                                // } else {
                                                //   getEventDates(
                                                //     moment(changedMonth[0], 'MMMM')
                                                //       .subtract(1, 'months')
                                                //       .format('MMMM'),
                                                //     changedMonth[1]
                                                //   );
                                                // }

                                                calendarRef.current
                                                    .getApi()
                                                    .prev();
                                            }
                                        },
                                    },
                                    next: {
                                        text: 'Next',
                                        click: () => {
                                            if (calendarRef.current) {
                                                calendarRef.current
                                                    .getApi()
                                                    .next();
                                            }
                                        },
                                    },
                                }}
                                headerToolbar={{
                                    left: 'prev,next today',
                                    center: 'title',
                                    right: 'dayGridMonth,dayGridWeek,dayGridDay',
                                }}
                                dayMaxEvents={2}
                                eventColor={'#378006'}
                                themeSystem="lifc-media-screenght"
                                navLinks={true}
                                plugins={[interactionPlugin, dayGridPlugin]}
                                editable={true}
                                selectable={true}
                                events={events}
                                eventContent={renderEventContent}
                            />
                        </StyledWrapper>
                    </Wrapper>
                </div>
            </div>
        </ErrorBoundary>
    );
};
export default StoreCalendar;
