import ErrorBoundary from 'components/ErrorBoundary';
import OnloadScript from './OnloadScript';
import WorkflowScript from './WorkflowScript';
import React from "react";
import BreadcrumbsWrapper from 'components/Wrapper/BreadcrumbsWrapper';
const Script = () => {
  return (
    <ErrorBoundary>
      <BreadcrumbsWrapper/>
      <div className='min-h-[76vh]'>
      <div className="lg:flex px-4 py-8">
        <OnloadScript />
        <WorkflowScript />
      </div>
      </div>
    </ErrorBoundary>
  );
};
export default Script;
