import React, { useContext, useEffect, useState } from 'react';
import AverageClick from 'assets/Images/Dashboard/average-click-rate.svg';
import AverageDeliverabilityRate from 'assets/Images/Dashboard/average-deliverability-rate.svg';
import AverageOpen from 'assets/Images/Dashboard/average-open-rate.svg';
import AverageConversion from 'assets/Images/Dashboard/average-conversion-rate.svg';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import EmailPerformance from 'components/Dashboard/Body/EmailPerformance';
import moment from 'moment';
import TotalOrder from 'components/Dashboard/Body/Orders/TotalOrders';
import AverageRevenue from 'components/Dashboard/Body/Orders/AverageRevenue';
import Revenue from 'components/Dashboard/Body/Orders/Revenue';
import AllWokflows from 'components/Dashboard/Body/AllWorkflows';
import { storeReportOrder } from 'components/DashboardReports/Body/Store-Report/Types';
import {
  calculatePercentage,
 //calculatePercentageDifference,
  calculateRate
} from 'utils/common';
import { getWorlkflowsStatus } from 'utils/dashboard';
import { WorkflowResponse } from 'components/DashboardReports/Types';
import { Workflows } from 'components/DashboardReports/Body/Store-Report/Types';
import { getWorkflowReports } from 'services/apiHandlers/Dashboard/Analytics';
import {
  OrderResponse,
  OrderData,
  //CardData,
  WorkflowCardData,
  TopWorkflows,
  TopPerformingData,
} from 'components/Dashboard/index.type';
import {
  getConversionReports,
  getOrderRevenueReport,
  getPerformanceReports,
} from 'services/apiHandlers/Dashboard/Reports';
import Wrapper from 'components/Wrapper';
import TopPerformanceWorkflows from 'components/TopPerformanceGraph/Workflow';
import WorkflowConversions from './WorkflowConversions';
import {
  OrderReportData,
  WorkflowConversionResponse,
  WorkflowConversion,
  GetPerformanceReportsResponse,
  PerformanceReports,
} from './index.type';
import OrderReports from './OrderReports';
import TopPerformingWorkflows from 'components/Dashboard/Body/TopPerformingWorkflows';
import { UserContext } from 'store/UserContext';
import PerformanceReport from './PerformanceReports';
import WorfklowReportTableHeader from './WorfklowReportTableHeader';

const tabs = [
  'Email Report',
  'Conversion Report',
  'Performance Report',
  'Orders',
];

// const defaultCardData = [
//   {
//     title: 'Open Rate',
//     currentMonthData: 0,
//     previousMonthData: 0,
//     performanceRate: 0,
//     id: '1',
//     image: AverageOpen,
//   },
//   {
//     title: 'Click Rate',
//     currentMonthData: 0,
//     previousMonthData: 0,
//     performanceRate: 0,
//     id: '2',
//     image: AverageClick,
//   },
//   {
//     title: 'Delivery Rate',
//     currentMonthData: 0,
//     previousMonthData: 0,
//     performanceRate: 0,
//     id: '3',
//     image: AverageDeliverabilityRate,
//   },
//   {
//     title: 'Conversion Rate',
//     currentMonthData: 0,
//     previousMonthData: 0,
//     performanceRate: 0,
//     id: '4',
//     image: AverageConversion,
//   },
// ];


const defaultWorkflowCardData = [
  {
    title: 'Open Rate',
    filteredWorkflowData: 0,
    id: '1',
    image: AverageOpen,
  },
  {
    title: 'Click Rate',
    filteredWorkflowData: 0,
    id: '2',
    image: AverageClick,
  },
  {
    title: 'Delivery Rate',
    filteredWorkflowData: 0,
    id: '3',
    image: AverageDeliverabilityRate,
  },
  {
    title: 'Conversion Rate',
    filteredWorkflowData: 0,
    id: '4',
    image: AverageConversion,
  },
];







const Reports = () => {
  const userCtx = useContext(UserContext);

  const [orders, setOrders] = useState<storeReportOrder>();
  const [averageRevenue, setAverageRevenue] = useState<storeReportOrder>();
  const [revenue, setRevenue] = useState<storeReportOrder>();
  //const [cardData, setCardData] = useState<CardData>(defaultCardData);
  const [workflowCardData, setWorkFlowCardData] = useState<WorkflowCardData>(defaultWorkflowCardData);
  const [workflows, setWorkflows] = useState<Workflows>([]);
  const [workflowPerformanceData, setWorkflowPerformanceData] = useState<TopWorkflows>({ orders: [], revenue: [], AOV: [] });
  const [loading, setLoading] = useState<boolean>(true);
  const [cardDataLoading, setCardDataLoading] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<string>(tabs[0]);
  const [workflowConversions, setWorkflowConversions] = useState<Array<WorkflowConversion>>([]);
  const [orderReports, setOrderReports] = useState<Array<OrderReportData>>([]);
  const [topWorkflows, setTopWorkflows] = useState<Array<TopPerformingData>>([]);
  const [reportsLoading, setReportsLoading] = useState<boolean>(true);
  const [monthlyReportsLoading, setMonthlyReportsLoading] = useState<boolean>(false);
  //const [selectedMonth, setSelectedMonth] = useState<string>(moment().format('MMMM'));
  const [performanceReports, setPerformanceReports] = useState<Array<PerformanceReports>>([]);
  const [selectedFilter, setSelectedFilter] = useState<string>('Select a Filter');
  const [filteredWorkflows, setFilteredWorkflows] = useState<Workflows>([]);
  const [filterOrder, setFilterOrder] = useState<string>('Desc');
  const [workflowProformanceStartDate, setWorkflowPerformanceStartDate] = useState<string>(moment(moment()).subtract(1, 'month').format('YYYY-MM-DD'));
  const [workflowProformanceEndDate, setWorkflowPerformanceEndDate] = useState<string>(moment().format('YYYY-MM-DD') );



  
  


  const calculatePerformanceData = async (
    currentYearWorkflowData: OrderData,
    previousYearWorkflowData: OrderData
  ) => {
    try {
      const labels: string[] = [];
      const tempOrderLabels: string[] = [];
      const tempPreviousYearOrderData: string[] = [];
      const tempCurrentYearOrderData: string[] = [];
      const tempAverageRevenueLabels: string[] = [];
      const tempCurrentYearAverageRevenueData: string[] = [];
      const tempPreviousYearAverageRevenueData: string[] = [];
      const tempRevenueLabels: string[] = [];
      const tempCurrentYearRevenueData: string[] = [];
      const tempPreviousYearRevenueData: string[] = [];

      currentYearWorkflowData?.data?.map((order) =>
        order.dates?.map(
          (date) => (
            order.metric === 'Orders' &&
            tempOrderLabels.push(
              moment(date.date, 'MMMM YYYY').format('MMM')
            ),
            order.metric === 'Orders' &&
            tempCurrentYearOrderData.push(
              Math.round(date.total).toString()
            ),
            order.metric === 'Average Revenue' &&
            tempAverageRevenueLabels.push(
              moment(date.date, 'MMMM YYYY').format('MMM')
            ),
            order.metric === 'Average Revenue' &&
            tempCurrentYearAverageRevenueData.push(
              Math.round(date.total).toString()
            ),
            order.metric === 'Revenue' &&
            tempRevenueLabels.push(
              moment(date.date, 'MMMM YYYY').format('MMM')
            ),
            order.metric === 'Revenue' &&
            tempCurrentYearRevenueData.push(
              Math.round(date.total).toString()
            ),
            order.metric === 'Revenue' &&
            labels.push(
              moment(date.date, 'MMMM YYYY').format('MMM')
            )
          )
        )
      );
      previousYearWorkflowData?.data?.map((data) =>
        data.dates?.map(
          (date) => (
            data.metric === 'Orders' &&
            tempOrderLabels.push(
              moment(date.date, 'MMMM YYYY').format('MMM')
            ),
            data.metric === 'Orders' &&
            tempPreviousYearOrderData.push(
              Math.round(date.total).toString()
            ),
            data.metric === 'Average Revenue' &&
            tempAverageRevenueLabels.push(
              moment(date.date, 'MMMM YYYY').format('MMM')
            ),
            data.metric === 'Average Revenue' &&
            tempPreviousYearAverageRevenueData.push(
              Math.round(date.total).toString()
            ),
            data.metric === 'Revenue' &&
            tempRevenueLabels.push(
              moment(date.date, 'MMMM YYYY').format('MMM')
            ),
            data.metric === 'Revenue' &&
            tempPreviousYearRevenueData.push(
              Math.round(date.total).toString()
            )
          )
        )
      );
      const averageRevenueData = [
        {
          label: ' Current Year',
          data: tempCurrentYearAverageRevenueData,
          backgroundColor: '#2EA1F9',
          barPercentage: 1.0,
          barThickness: 25,
        },
        {
          label: ' Previous Year',
          data: tempPreviousYearAverageRevenueData,
          backgroundColor: '#8FDBC0',
          barPercentage: 1.0,
          barThickness: 25,
        },
      ];

      const revenueData = [
        {
          label: ' Current Year',
          data: tempCurrentYearRevenueData,
          borderColor: '#FBB846',
          backgroundColor: '#FBB846',
        },
        {
          label: ' Previous Year',
          data: tempPreviousYearRevenueData,
          borderColor: '#A292DA',
          backgroundColor: '#A292DA',
        },

      ];

      const OrdersData = [
        {
          label: '  Current Year',
          data: tempCurrentYearOrderData,
          borderColor: '#FBB846',
          backgroundColor: '#FBB846',
        },
        {
          label: '  Previous Year',
          data: tempPreviousYearOrderData,
          borderColor: '#A292DA',
          backgroundColor: '#A292DA',
        },
      ];
      const totalOrders = {
        orderType: 'Total Orders',
        orderValue: '$0',
        orderPercentage: '0',
        graphType: 'Line',
        graphCategories: labels,
        graphData: OrdersData,
        graphHeight: 310,
        graphWidth: 450,
        graphStyle: {

          width: '100%',
          height: 'auto',
        },
        lastMonth: '0',
        last90Days: '0',
        last14Days: '0',
      };

      const AverageRevenue = {
        orderType: 'AOV',
        orderValue: `$0`,
        orderPercentage: '$0',
        graphType: 'Coloumn',
        graphCategories: labels,
        graphData: averageRevenueData,
        graphStyle: { width: '100%' },
        graphHeight: 206,
        lastMonth: `$0`,
        last90Days: `$0`,
        last14Days: `$0`,
      };

      const revenue = {
        orderType: 'Revenue',
        orderValue: '$0',
        orderPercentage: '$0',
        graphType: 'Line',
        graphCategories: labels,
        graphData: revenueData,
        graphHeight: 310,
        graphWidth: 450,
        graphStyle: {

          width: '100%',
          height: 'auto',
        },
        lastMonth: `$0`,
        last90Days: `$0`,
        last14Days: `$0`,
      };
      setOrders(totalOrders);
      setAverageRevenue(AverageRevenue);
      setRevenue(revenue);
    } catch (error) {
      console.log('error is : ', error);
    }
  };


  const calculateFilteredPerformance =  async (
    filteredWorkflows: Workflows,
    
  ) => {
    try {
      
      const filteredWorkFlowsTotalData = filteredWorkflows[filteredWorkflows.length - 1];

      const filteredWorkFlowsConversionRate = calculatePercentage(
        filteredWorkFlowsTotalData.uniqueConversions,
        filteredWorkFlowsTotalData.deliveries
      );

      const filteredWorkFlowsOpenRate = calculatePercentage(
        filteredWorkFlowsTotalData.uniqueViews,
        filteredWorkFlowsTotalData.deliveries
      );


      const filteredWorkFlowsClickRate = calculatePercentage(
        filteredWorkFlowsTotalData.uniqueClicks,
        filteredWorkFlowsTotalData.deliveries
      );


      setWorkFlowCardData([
        {
          title: 'Conversion Rate',
          filteredWorkflowData: filteredWorkFlowsConversionRate,
          id: '1',
          image: AverageConversion,
        },
        {
          title: 'Open Rate',
          filteredWorkflowData: filteredWorkFlowsOpenRate,
          id: '2',
          image: AverageOpen,
        },
        {
          title: 'Click Rate',
          filteredWorkflowData: filteredWorkFlowsClickRate,
          id: '3',
          image: AverageClick,
        },
        {
          title: 'Delivery Rate',
          filteredWorkflowData: calculatePercentage(
            filteredWorkFlowsTotalData.deliveries,
            filteredWorkFlowsTotalData.sends
          ),
          id: '4',
          image: AverageDeliverabilityRate,
        },
      ]);



    }catch(error){
        console.log(error)
    }
  }





  // const calculateMonthlyPerformanceData = async (
  //   currentMonthWorkflows: Workflows,
  //   previousMonthWorkflows: Workflows
  // ) => {
  //   try {
  //     const currentMonthTotalData =
  //       currentMonthWorkflows[currentMonthWorkflows.length - 1];
  //     const previousMonthTotalData =
  //       previousMonthWorkflows[previousMonthWorkflows.length - 1];




  //     const currentMonthConversionRate = calculatePercentage(
  //       currentMonthTotalData.uniqueConversions,
  //       currentMonthTotalData.deliveries
  //     );
  //     const previousMonthConversionRate = calculatePercentage(
  //       previousMonthTotalData.uniqueConversions,
  //       previousMonthTotalData.deliveries
  //     );

  //     const currentMonthOpenRate = calculatePercentage(
  //       currentMonthTotalData.uniqueViews,
  //       currentMonthTotalData.deliveries
  //     );
  //     const previousMonthOpenRate = calculatePercentage(
  //       previousMonthTotalData.uniqueViews,
  //       previousMonthTotalData.deliveries
  //     );

  //     const currentMonthClickRate = calculatePercentage(
  //       currentMonthTotalData.uniqueClicks,
  //       currentMonthTotalData.deliveries
  //     );
  //     const previousMonthClickRate = calculatePercentage(
  //       previousMonthTotalData.uniqueClicks,
  //       previousMonthTotalData.deliveries
  //     );

  //     setCardData([
  //       {
  //         title: 'Conversion Rate',
  //         currentMonthData: currentMonthConversionRate,
  //         previousMonthData: previousMonthConversionRate,
  //         performanceRate: calculatePercentageDifference(
  //           currentMonthConversionRate,
  //           previousMonthConversionRate
  //         ),
  //         id: '1',
  //         image: AverageConversion,
  //       },
  //       {
  //         title: 'Open Rate',
  //         currentMonthData: currentMonthOpenRate,
  //         previousMonthData: previousMonthOpenRate,
  //         performanceRate: calculatePercentageDifference(
  //           currentMonthOpenRate,
  //           previousMonthOpenRate
  //         ),
  //         id: '2',
  //         image: AverageOpen,
  //       },
  //       {
  //         title: 'Click Rate',
  //         currentMonthData: currentMonthClickRate,
  //         previousMonthData: previousMonthClickRate,
  //         performanceRate: calculatePercentageDifference(
  //           currentMonthClickRate,
  //           previousMonthClickRate
  //         ),
  //         id: '3',
  //         image: AverageClick,
  //       },
  //       {
  //         title: 'Delivery Rate',
  //         currentMonthData: calculatePercentage(
  //           currentMonthTotalData.deliveries,
  //           currentMonthTotalData.sends
  //         ),
  //         previousMonthData: calculatePercentage(
  //           previousMonthTotalData.deliveries,
  //           previousMonthTotalData.sends
  //         ),
  //         performanceRate: calculatePercentageDifference(
  //           calculatePercentage(
  //             currentMonthTotalData.deliveries,
  //             currentMonthTotalData.sends
  //           ),
  //           calculatePercentage(
  //             previousMonthTotalData.deliveries,
  //             previousMonthTotalData.sends
  //           )
  //         ),
  //         id: '4',
  //         image: AverageDeliverabilityRate,
  //       },
  //     ]);
  //   } catch (error) {
  //     console.log('error is : ', error);
  //   }
  // };

  const getWorkflowReportsData = async (
    startDate: string,
    endDate: string
  ) => {
    setReportsLoading(true);
    try {
      const getWorkflowReportResponse = (await getWorkflowReports(
        startDate,
        endDate
      )) as WorkflowResponse;

      const getConversionReportResponse = (await getConversionReports(
        startDate,
        endDate
      )) as WorkflowConversionResponse;

      const getWorfklowOrderRevenueResponse = (await getOrderRevenueReport(
        startDate,
        endDate
      )) as OrderResponse;

      const getPerformanceReportsResponse = (await getPerformanceReports(
        startDate,
        endDate,
        userCtx?.usersData?.accountId.toString() || ''
      )) as GetPerformanceReportsResponse;

      if (getPerformanceReportsResponse.status === 200) {
        setPerformanceReports(getPerformanceReportsResponse.data);
      }

      if (getWorfklowOrderRevenueResponse.status === 200) {
        setOrderReports(getWorfklowOrderRevenueResponse.data);
      }

      if (getConversionReportResponse.status === 200) {
        const totalConversions = getConversionReportResponse.data.reduce((sum, item) => sum + item.conversions, 0);
        const totalMonetaryValue = getConversionReportResponse.data.reduce((sum, item) => sum + item.monetaryValue, 0);
        const totalObject = {
          workflowId: '-1',
          workflowName: 'Totals',
          conversions: Math.round(totalConversions),
          monetaryValue: Math.round(totalMonetaryValue),
        };
        setWorkflowConversions([...getConversionReportResponse.data, totalObject]);
      }

      if (getWorkflowReportResponse.status === 200) {
        const tempWorkflows = getWorkflowReportResponse.data.data.map((workflow) => {
          return {
            ...workflow,
            status: getWorlkflowsStatus(workflow.workflowId, userCtx?.workflows),
          };
        });
        setWorkflows(tempWorkflows);
        setFilteredWorkflows(tempWorkflows);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
    setReportsLoading(false);
  };

  const filteredWorfklows = (reports: Workflows) => {
    return reports.filter((report) => report.workflowName !== 'Total');
  };

  const calculateReportsData = (reports: Workflows) => {
    const workflowOrders = reports
      .map((workflow) => {
        return {
          name: workflow.workflowName,
          value: Math.round(workflow.uniqueConversions),
        };
      })
      .sort((a, b) => b.value - a.value)
      .slice(0, 6);
    const workflowRevenue = reports
      .map((workflow) => {
        return {
          name: workflow.workflowName,
          value: Math.round(workflow.monetaryValue),
        };
      })
      .sort((a, b) => b.value - a.value)
      .slice(0, 6);
    const workflowAOV = reports
      .map((workflow) => {
        return {
          name: workflow.workflowName,
          value: calculateRate(
            workflow.monetaryValue,
            workflow.uniqueConversions
          ),
        };
      })
      .sort((a, b) => b.value - a.value)
      .slice(0, 6);

    setWorkflowPerformanceData({
      orders: workflowOrders,
      revenue: workflowRevenue,
      AOV: workflowAOV,
    });
  };

  const getMonthlyWorkflowReports = async (start: string, end: string) => { 
    
    try {
      const getMonthlyWorkflowReportResponse = (await getWorkflowReports(start, end)) as WorkflowResponse;
      if (getMonthlyWorkflowReportResponse.status === 200) {
        calculateReportsData(
          filteredWorfklows(
            getMonthlyWorkflowReportResponse.data.data
          )
        );
      }
    } catch (error) {
      console.log('error is : ', error);
    }
    setMonthlyReportsLoading(false);
  };


//date based performance instead of pervious month
  const getFilteredWorkFlowPerformanceData = async (startDate: string, endDate: string)=>{
  
    setCardDataLoading(true)
    try{
    const getCurrentMonthWorkflowReportResponse =  (await getWorkflowReports(
      startDate,
      endDate
    )) as WorkflowResponse;
    

    if( getCurrentMonthWorkflowReportResponse.status === 200)
    {
      calculateFilteredPerformance(
        getCurrentMonthWorkflowReportResponse.data.data,
      );
    }
  }catch(error){
    console.log(error)
  }finally{setCardDataLoading(false)}


  } 



  const getDashboardData = async () => {
    setLoading(true);

    const currentYearMonthDate = moment().format('YYYY-MM-DD');
    
    const currentYearLastSixMonthDate = moment()
      .subtract(5, 'months')
      .startOf('month')
      .format('YYYY-MM-DD');
    const previousYearDate = moment()
      .subtract(1, 'year')
      .format('YYYY-MM-DD');
    const previousYearSixMonthsDate = moment(previousYearDate)
      .subtract(5, 'months')
      .startOf('month')
      .format('YYYY-MM-DD');

    // const previousMonthStart = moment()
    //   .subtract(1, 'months')
    //   .startOf('month')
    //   .format('YYYY-MM-DD');
    // const previousMonthEnd = moment()
    //   .subtract(1, 'months')
    //   .endOf('month')
    //   .format('YYYY-MM-DD');
    const previousMonthDate = moment(currentYearMonthDate)
      .subtract(1, 'month')
      .format('YYYY-MM-DD');
    // const currentMonthStart = moment()
    //   .startOf('month')
    //   .format('YYYY-MM-DD');

    try {
      getWorkflowReportsData(previousMonthDate, currentYearMonthDate);

      //getting data on the bases of month when the component is first loaded, 
      //(from first to the current day of current month) 
       
      //getMonthlyWorkflowReports('','',moment().format('MMMM'));
      
      //changed it to load last 30 days data 

    
    
    
  
    const endDate = moment().format('YYYY-MM-DD');
    const startDate = moment().subtract(1,'month').format('YYYY-MM-DD');
    getMonthlyWorkflowReports(startDate,endDate);


      
      const currentYearWorkflowReportResponse = (await getWorkflowReports(
        currentYearLastSixMonthDate,
        currentYearMonthDate
      )) as WorkflowResponse;
      
      // const getCurrentMonthWorkflowReportResponse =
      //   (await getWorkflowReports(
      //     currentMonthStart,
      //     currentYearMonthDate
      //   )) as WorkflowResponse;

      // const getPreviousMonthWorkflowReportResponse =
      //   (await getWorkflowReports(
      //     previousMonthStart,
      //     previousMonthEnd
      //   )) as WorkflowResponse;

      const getCurrentYearWorfklowPerformanceResponse =
        (await getOrderRevenueReport(
          currentYearLastSixMonthDate,
          currentYearMonthDate
        )) as OrderResponse;

      const getPreviousYearWorfklowPerformanceResponse =
        (await getOrderRevenueReport(
          previousYearSixMonthsDate,
          previousYearDate
        )) as OrderResponse;

      // if (
      //   getCurrentMonthWorkflowReportResponse.status === 200 &&
      //   getPreviousMonthWorkflowReportResponse.status === 200
      // ) {
      //   calculateMonthlyPerformanceData(
      //     getCurrentMonthWorkflowReportResponse.data.data,
      //     getPreviousMonthWorkflowReportResponse.data.data
      //   );
      // }

      if (
        getCurrentYearWorfklowPerformanceResponse.status === 200 &&
        getPreviousYearWorfklowPerformanceResponse.status === 200
      ) {
        calculatePerformanceData(
          getCurrentYearWorfklowPerformanceResponse,
          getPreviousYearWorfklowPerformanceResponse
        );
      }

      if (currentYearWorkflowReportResponse.status === 200) {
        const tempTopWorkflows = filteredWorfklows(
          currentYearWorkflowReportResponse.data.data
        )
          .sort((a, b) => b.monetaryValue - a.monetaryValue)
          .slice(0, 3)
          .map((workflow) => {
            return {
              name: workflow.workflowName,
              revenue: workflow.monetaryValue,
              orders: workflow.uniqueConversions,
              id: workflow.workflowId,
            };
          });
        setTopWorkflows(tempTopWorkflows);
      }
    } catch (error) {
      console.log('error is first: ', error);
    }
    setLoading(false);
  };

  const onFilterSelect = (filter: string) => {
    if (filter === 'Status') {
      setFilteredWorkflows(
        [...workflows].sort((a, b) => (a?.status || '') > (b?.status || '') ? 1 : -1)
      );
    } else if (filter === 'Revenue') {
      if (selectedFilter === 'Revenue' && filterOrder === 'Desc') {
        setFilteredWorkflows(
          [...workflows].sort((a, b) => a.monetaryValue - b.monetaryValue)
        );
        setFilterOrder('Asc')
      } else {
        setFilteredWorkflows(
          [...workflows].sort((a, b) => b.monetaryValue - a.monetaryValue)
        );
        setFilterOrder('Desc')
      }
    } else if (filter === 'Orders') {

      if (selectedFilter === 'Orders' && filterOrder === 'Desc') {
        setFilteredWorkflows(
          [...workflows].sort((a, b) => a.uniqueConversions - b.uniqueConversions)
        );
        setFilterOrder('Asc')

      } else {
        setFilteredWorkflows(
          [...workflows].sort((a, b) => b.uniqueConversions - a.uniqueConversions)
        );
        setFilterOrder('Desc')
      }
    } else if (filter === 'AOV') {
      if (selectedFilter === 'AOV' && filterOrder === 'Desc') {
        setFilteredWorkflows(
          [...workflows].sort((a, b) => calculateRate(a.monetaryValue, a.uniqueConversions) - calculateRate(b.monetaryValue, b.uniqueConversions))
        );
        setFilterOrder('Asc')

      } else {
        setFilteredWorkflows(
          [...workflows].sort((a, b) => calculateRate(b.monetaryValue, b.uniqueConversions) - calculateRate(a.monetaryValue, a.uniqueConversions))
        );
        setFilterOrder('Desc')
      }
    }
    setSelectedFilter(filter);
  };



  useEffect(()=> {

    
    getFilteredWorkFlowPerformanceData(workflowProformanceStartDate, workflowProformanceEndDate)

  },[workflowProformanceEndDate, workflowProformanceStartDate])

  useEffect(() => {
    getDashboardData();
   

  }, []);

  

  return (
    <div>
      <div className="h-[61px] bg-white md:px-6 px-3 py-2 font-inter border-b shadow-0 shadow flex justify-between items-center dark:border-[#fff] dark:shadow-none dark:bg-[#41464E]">
        <Breadcrumbs />
      </div>
      <div className="pt-5 dark:bg-transparent h-full">
        <div className=" p-4">
          <div className="flex flex-col gap-4 w-full ">
          
            <div className="2xl:w-[100%] w-full">
              <div className="flex items-center sm:mb-4 gap-4 xl:justify-between justify-center flex-wrap xl:flex-nowrap ">
                <div className="2xl:w-[32%] xl:w-[33%] sm:w-[48%] w-full shadow-md  hover:rounded-lg hover:shadow-lg hover:shadow-gray-200 dark:shadow-none dark:shadow-none dark:bg-[#41464E] dark:shadow-lg  dark:hover:shadow-2xl">
                  <Revenue revenue={revenue} loading={loading} />
                </div>
                <div className="2xl:w-[32%] xl:w-[33%] sm:w-[48%] w-full shadow-md  hover:rounded-lg hover:shadow-lg hover:shadow-gray-200 dark:shadow-none dark:shadow-none dark:bg-[#41464E] dark:shadow-lg  dark:hover:shadow-2xl">
                  <TotalOrder
                    orders={orders}
                    loading={loading}
                    isQuantitativeData={true}
                  />
                </div>
                <div className="2xl:w-[32%] xl:w-[33%] sm:w-[48%] w-full shadow-md  hover:rounded-lg hover:shadow-lg hover:shadow-gray-200 dark:shadow-none dark:shadow-none dark:bg-[#41464E] dark:shadow-lg  dark:hover:shadow-2xl">
                  <AverageRevenue
                    averageRevenue={averageRevenue}
                    loading={loading}
                  />
                </div>

              </div>
           
            </div>
           
           
            <div className="2xl:w-[100%] w-[full] 2xl:flex flex md:flex-row flex-col mb-2 h-fit justify-between sm:flex">
           
            <div className="flex 2xl:w-[66%] w-[100%] md:flex-row flex-col gap-4 dark:shadow-none dark:bg-[#41464E] dark:shadow-lg  dark:hover:shadow-2xl">
                <div className="w-full mb-4">
                  
                  <TopPerformanceWorkflows
                    topWorkflows={workflowPerformanceData}
                    
                    loading={loading || monthlyReportsLoading}
                    
                    onDateSelect={(start: string, end: string) => {
                      setMonthlyReportsLoading(true);
                      getMonthlyWorkflowReports(start, end);
                      setWorkflowPerformanceStartDate(start);
                      setWorkflowPerformanceEndDate(end);
                      
                    }}
                
                  />
                 
                </div>
              </div>
              <div className="mb-5 2xl:w-[32%] md:w-[30%] 2xl:h-[473px] h-auto shadow-md  hover:rounded-lg hover:shadow-lg hover:shadow-gray-200 dark:shadow-none dark:shadow-none dark:bg-[#41464E] dark:shadow-lg  dark:hover:shadow-2xl">
                <TopPerformingWorkflows
                  workflows={topWorkflows}
                  loading={loading}
                  reports={true}
                />
              </div>
            </div>





          </div>
          <div className="flex md:flex-row flex-col gap-4 mb-2 w-full justify-between">
            <div className="flex xl:flex-nowrap flex-wrap justify-between gap-2 w-full">
              {workflowCardData?.map((email) => (
                <div key={email.id} className="md:w-[48%] lg:w-[24%] w-full dark:shadow-none dark:bg-[#41464E] dark:shadow-lg  dark:hover:shadow-2xl">
                  <EmailPerformance
                    workflowEmailPerformance={email}
                    loading={cardDataLoading}
                    startDate={workflowProformanceStartDate}
                    endDate={workflowProformanceEndDate}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className="relative">
            <div className=" gap-4 mb-4">
              <Wrapper
                parentClass=" hover:shadow-lg  hover:shadow-gray-200 dark:hover:shadow-none w-full dark:shadow-none dark:bg-[#41464E] dark:shadow-lg  dark:hover:shadow-2xl"
                childClass="h-full"
              >
                <>
                  <WorfklowReportTableHeader
                    getWorkflowReportsData={getWorkflowReportsData}
                    activeTab={activeTab}
                    setActiveTab={setActiveTab}
                    tabs={tabs}
                    selectedFilter={selectedFilter}
                    onFilterSelect={onFilterSelect} />
                  <div className="w-full overflow-auto  ">
                    {activeTab === 'Email Report' ? (
                      <AllWokflows
                        filteredWorkflows={filteredWorkflows}
                        loading={reportsLoading}
                        isWorkflowReports={true}
                      />
                    ) : activeTab === 'Conversion Report' ? (
                      <WorkflowConversions
                        conversions={workflowConversions}
                        loading={reportsLoading}
                      />
                    ) : activeTab === 'Orders' ? (
                      <OrderReports
                        orderReports={orderReports}
                        loading={reportsLoading}
                      />
                    ) : (
                      <PerformanceReport
                        performanceReports={performanceReports}
                        loading={reportsLoading}
                      />
                    )}
                  </div>
                </>
              </Wrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Reports;