import { useEffect, useState } from 'react';
import { getFields } from 'services/apiHandlers/List-Segments/Field';
import xmlToJSON from 'utils/xmlToJSON';
import { Form } from './SubscribersForm';
import FieldData from './FieldData';
import { formatXmlJsonResponse } from 'utils/common';
import AddFieldForm from './AddFieldForm';
import Skeleton from 'components/Skeleton/Skeleton';
import ErrorBoundary from 'components/ErrorBoundary';
import React from 'react';
import Wrapper from 'components/Wrapper';

const Fields: React.FC = () => {
  const [fieldData, setFieldData] = useState<Array<Form>>([]);
  const [loadingFields, setLoadingFields] = useState<boolean>(true);

  const getData = async () => {
    /* eslint-disable */
    const getFieldsResponse: any = await getFields();
    const data: any = xmlToJSON.parseString(getFieldsResponse.data);
    const fields = formatXmlJsonResponse(data?.fields[0]?.field);
    setLoadingFields(false);
    setFieldData(fields as any);
    /* eslint-enable */
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <ErrorBoundary>
          <div className="px-6 py-8">
            <Wrapper>
              <>
                <AddFieldForm getData={getData} />
                  <div className="p-5 dark:bg-slate-700 rounded-t-lg">
                    <div className="flex items-center">
                      <h3 className="sm:text-base text-sm sm:leading-5 leading-3 text-[#212529] dark:text-white font-normal mr-2">
                        E-Mail Address
                      </h3>
                      <span className="text-sm font-medium leading-4 text-gray-500 dark:text-white">
                        (Text Field)
                      </span>
                    </div>
                    {loadingFields ? (
                      <Skeleton />
                    ) : (
                      fieldData.map((field, index) => (
                        <FieldData
                          key={field.id}
                          data={field}
                          getData={getData}
                          index={fieldData.length - 1 === index ? index : 0}
                          first={index === 0}
                        />
                      ))
                    )}
                  </div>
              </>
            </Wrapper>
      </div>
    </ErrorBoundary>
  );
};
export default Fields;
