import React, { FC, useContext, useEffect, useState } from 'react';
import { getAllList } from 'services/apiHandlers/List-Segments/SegmentsList';
import { GetListResponse, ListType } from '../index';
import { CampaignContext } from 'store/CampaignContext';
import Wrapper from 'components/Wrapper';
import Tippy from '@tippyjs/react';
import 'tippy.js/dist/tippy.css';
import GetFormattedItem from './GetFormattedItem';


interface Props {
    selectedFilter: string;
}
const ListSegmentsFilter: FC<Props> = ({ selectedFilter }) => {
    const campaignCtx = useContext(CampaignContext);
    const [sendTo, setSendTo] = useState<Array<ListType>>([]);
    const [excludeList, setExcludeList] = useState<Array<ListType>>([]);

    const getData = async () => {
        try {
            const getListsresponse = (await getAllList()) as GetListResponse;
            if (getListsresponse.status === 200) {
                const includedIds: number[] = campaignCtx?.campaignData
                    ?.include_lists
                    ? campaignCtx?.campaignData?.include_lists.length > 0
                        ? campaignCtx?.campaignData?.include_lists
                        : []
                    : [];
                const excludeIds: number[] = campaignCtx?.campaignData
                    ?.exclude_lists
                    ? campaignCtx?.campaignData?.exclude_lists.length > 0
                        ? campaignCtx?.campaignData?.exclude_lists
                        : []
                    : [];
                const tempSendto = getListsresponse.data.lists.filter((list) =>
                    includedIds.includes(list.id)
                );
                const tempExcludeList = getListsresponse.data.lists.filter(
                    (list) => excludeIds.includes(list.id)
                );
                setSendTo(tempSendto);
                setExcludeList(tempExcludeList);
            }
        } catch (error) {
            console.log('error is: ', error);
        }
    };

    useEffect(() => {
        getData();
    }, [campaignCtx?.campaignData]);


    return (
        <Wrapper parentClass="!mt-0">
            <>
                <p className="text-[#495057] dark:text-white font-semibold text-lg py-2 px-4 border-b">
                    List Segment
                </p>
                <div className="p-4">
                    <div className="flex items-center justify-between mt-2">
                        <p className="text-[#212529] dark:text-white font-medium text-sm">
                            Send To :
                        </p>
                        <div>
                            {sendTo.length > 0 ? (
                                <div className="flex justify-end">
                                    {sendTo?.slice(0, 3).map((send) => (
                                        send.name.length > 11 ?
                                            (
                                                <p key={send.id}>
                                                    <Tippy interactive={true} content={send.name}>
                                                        <span className="text-[#878A99] block text-[10px] text-center h-[20px] max-w-[90px] px-1 rounded bg-[#ebebeb] text-sm  ml-2 truncate">
                                                            {send.name}
                                                        </span>
                                                    </Tippy>
                                                </p>
                                            ) : (
                                                <p key={send.id} className="text-[#878A99] text-[10px] text-center h-[20px] max-w-[90px] px-1 rounded bg-[#ebebeb] text-sm  ml-2 truncate">
                                                    {send.name}
                                                </p>
                                            )
                                    ))}
                                    {sendTo.length > 3 && <Tippy interactive={true} content={
                                        sendTo.map((exc) => (
                                            <p
                                                key={exc.id}
                                                className="text-white px-2 rounded py-1 text-sm ml-2"
                                            >
                                                - {exc.name}
                                            </p>
                                        ))
                                    }><button className='ml-2 text-[10px] text-primary underline' type='button'>Show More</button></Tippy>}
                                </div>
                            ) : (
                                <p className='dark:text-[#878A99]'>None</p>
                            )}
                        </div>
                    </div>
                    <div className="flex items-center justify-between mt-2">
                        <p className="text-[#212529] dark:text-white font-medium text-sm">
                            Exclude List :
                        </p>
                        <div>
                            {excludeList.length > 0 ? (
                                <div className="flex justify-end">
                                    {excludeList?.slice(0, 3).map((exculde) => (
                                        exculde.name.length > 11 ?
                                            (
                                                <p key={exculde.id}>
                                                    <Tippy interactive={true} content={exculde.name}>
                                                        <span className="text-[#878A99] text-[10px] block text-center h-[20px] max-w-[90px] px-1 rounded bg-[#ebebeb] text-sm  ml-2 truncate">
                                                            {exculde.name}
                                                        </span>
                                                    </Tippy>
                                                </p>
                                            ) : (
                                                <p key={exculde.id} className="text-[#878A99] text-[10px] text-center h-[20px] max-w-[90px] px-1 rounded bg-[#ebebeb] text-sm  ml-2 truncate">
                                                    {exculde.name}
                                                </p>
                                            )
                                    ))}
                                    {excludeList.length > 3 && <Tippy interactive={true} content={
                                        excludeList.map((exclude) => (
                                            <p
                                                key={exclude.id}
                                                className="text-white px-2 rounded py-1 text-sm ml-2"
                                            >
                                                - {exclude.name}
                                            </p>
                                        ))
                                    }><button className='ml-2 text-[10px] text-primary underline' type='button'>Show More</button></Tippy>}
                                </div>
                            ) : (
                                <p className='dark:text-[#878A99]'>None</p>
                            )}
                        </div>
                    </div>
                    <div className="mt-2">
                        <GetFormattedItem item={{ title: 'Apply Filters', value: campaignCtx?.campaignData?.filter_id ? selectedFilter : 'None' }} itemStyle='text-[#212529]' />
                    </div>
                </div>
            </>
        </Wrapper >
    );
};
export default ListSegmentsFilter;