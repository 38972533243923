import React, { FC } from 'react';
import SucessfullIcon from 'assets/Images/Common/wired-outline-1103-confetti.gif';

const CampaignCreated: FC = () => {
  return (
    <div className="mx-auto ">
      <img
        className="h-[120px] w-[120px] mx-auto"
        src={SucessfullIcon}
        alt="SucessfullIcon"
      />
      <p className=" text-lg text-[#495057] text-center font-medium">
        Your Campaign is Completed !
      </p>
      <p className="text-[#898A99] text-sm">
        You Will receive an confirmation email with details of your Campaign.
      </p>
    </div>
  );
};
export default CampaignCreated;
