export const ProductTableKeys: { [key: string]: string } = {

  'product_image': 'Product Image',
  'product_img': 'Product Image',
  'img': 'Product Image',
  'productImage': 'Product Image',
  'product_image_url': 'Product Image',
  'product_name': 'Product Name',
  'name': 'Product Name',
  'productName': 'Product Name',
  'price': 'Product Price ',
  'productPrice': 'Product Price',
  'product_price': 'Product Price',
  'productURL': 'Product URL',
  'productUrl': 'Product URL',
  'product_url': 'Product URL',
  'product_quantity': 'Product Quantity',
  'product_quan': 'Product Quantity',
  'quantity': 'Product Quantity'
};


export const ModifiedEvents: {
  [key: string]: { name: string; color: string };
} = {
  'All Events': { name: 'All Events', color: '' },
  
  'search_browse' :{name : 'Viewed searched results',color:''},
  'Search View': { name: 'Viewed searched results', color: 'bg-[#02a8b5]' },
  'search_view': { name: 'Viewed searched results', color: 'bg-[#ee6352]' },
  
  'category_browse' : {name : 'Viewed Category',color:''},
  'Category View': { name: 'Viewed Category', color: 'bg-[#8C68CD]' },
  'category_view': { name: 'Viewed Category', color: 'bg-[#3FA7D6]' },
 
  'homepage_browse': { name: 'Visited home page', color: 'bg-[#556b2f]' },
  'visit': { name: 'Visited home page', color: 'bg-[#8b008b]' },
  
  'Product View' : { name: 'Viewed Product', color: 'bg-[#945fc9]' },
  'product_view': { name: 'Viewed Product', color: 'bg-[#945fc9]' },
 
  'Cart View': { name: 'Viewed Cart', color: 'bg-[#405189]' },
  'cart_view': { name: 'Viewed Cart', color: 'bg-[#02a8b5]' },
  
  'Purchase Confirmation': { name: 'Purchased', color: 'bg-[#ca5446]' },
  'purchase': { name: 'Purchased', color: 'bg-[#ca5446]' },

  'Screenpopper Signup': { name: 'Signed up from Screenpopper', color: 'bg-[#2F574A]'},

  'insights_add_to_list': { name: 'Added to list from workflows', color: 'bg-[#dc143c]' },
  'insights_webhook_failure': { name: 'Workflow webhook failed', color: 'bg-[#00008b]' },
  'insights_webhook_success': { name: 'Workflow webhook completed successfully', color: 'bg-[#b8860b]' },
  'insights_email_send': { name: 'Email send from workflow', color: 'bg-[#ff8c00]' },
  'insights_email_view': { name: 'Viewed workflow email', color: 'bg-[#483d8b]' },
  'insights_email_click': { name: 'Clicked workflow email', color: 'bg-[#2f4f4f]' },
  'insights_email_bounce' : { name: 'Workflow Email Bounced', color: 'bg-[#E35C0B]' },
  'insights_email_complaint ' : { name: 'Workflow Email Complaint', color: 'bg-[#E35C0B]' },
  'insights_email_unsubscribe' : { name: 'Unsubscribed from workflow', color: 'bg-[#E35C0B]' },
  'insights_subscriber_converted' : { name: 'Subscriber converted from Workflow', color: 'bg-[#E35C0B]' },

  'ss_email_sent': { name: 'Email send from campaign', color: 'bg-[#b22222]' },
  'ss_added_to_list': { name: 'Added to list from campaign', color: 'bg-[#4b0082]' },
  'ss_unsubscribed': { name: 'Unsubscribed from campaign email', color: 'bg-[#808000]' },
  'ss_email_viewed': { name: 'Viewed campaign email', color: 'bg-[#8b4513]' },
  'ss_email_link_clicked': { name: 'Clicked campaign email', color: 'bg-[#9acd32]' },
  'ss_form_signup': { name: 'Signed up from Ryzeo form', color: 'bg-[#48d1cc]' },
  
  
  'blast_subscriber_converted' : { name: 'Subscriber converted from Campaign', color: 'bg-[#E35C0B]' },
  'checkout_page_visited ' : { name: 'Visited Checkout Page', color: 'bg-[#E35C0B]' },
  'send_roi_email': { name: 'Send weekly ROI report email', color: 'bg-[#a9a9a9]' },
  'Subscriber Tag': { name: 'Subscriber Tag', color: 'bg-[#E35C0B]' },
};



export const transformEvents = (Options: string[])=>{

const resultObject: { [eventName: string]: string[] } = {};

for (const option of Options) {
  if (option in ModifiedEvents) {
    
    const eventName = ModifiedEvents[option].name;

    if (resultObject[eventName] ) {
      resultObject[eventName].push(option);
    } else {
      resultObject[eventName] = [option];
    }
  } else{
    const exists = Object.values(ModifiedEvents).some(obj => obj.name === option);
    if(!exists)
    resultObject[option] = [option];
  }
}

const uniqueResultObject: { [eventName: string]: string[] } = {};

for (const eventName in resultObject) {
  const uniqueValues = Array.from(new Set(resultObject[eventName]));
  uniqueResultObject[eventName] = uniqueValues;
}

return uniqueResultObject





}




export const eventExtraOptions = [
  'insights_email_send',
  'insights_email_view',
  'insights_email_click',
  'ss_email_sent',
  'ss_added_to_list',
  'ss_unsubscribed',
  'ss_email_viewed',
  'ss_email_link_clicked',
  'ss_form_signup',
];