import { FC, useEffect } from 'react';
import { useFormik } from 'formik';
import ModalWrapper from 'components/Workflow/ModalWrapper';
import React from 'react';

interface Props {
  cancelClicked: () => void;
  createClicked: (mail: string) => void;
  mergeMail: string | undefined;
}

const EmailAlert: FC<Props> = ({ cancelClicked, createClicked, mergeMail }) => {
  const formik = useFormik({
    initialValues: { mail: '{{{email_address}}}' },
    onSubmit: () => console.log(),
  });

  useEffect(() => {
    formik.setFieldValue('mail', mergeMail);
  }, [mergeMail]);

  return (
    <ModalWrapper
      onClose={cancelClicked}
      buttonTxt="Ok"
      onSave={() => createClicked(formik.values.mail)}
    >
      <div className="w-full sm:px-0 px-3  left-0 right-0 top-40 z-50">
        <div className="  dark:bg-[#41464E]  dark:border-[#fff] border-[#ced4da]">
          <p className="text-base font-normal leading-5 text-[#212529] dark:text-white mb-8">
            {`The text ${formik.values.mail} will be replaced with a person's actual information`}
          </p>
          <label className="text-sm font-medium leading-3 tracking-wide text-[#212529] dark:text-white ">
            Click OK to insert this mail merge value.
          </label>
          <input
            className="w-full px-4 py-2 text-13 leading-3  dark:bg-[#41464E]  border dark:border-[#fff] border-[#ced4da] rounded mt-2 text-[#212529] dark:text-white sm:h-[38px]"
            type="text"
            data-pw="merge-field"
            name="mail"
            value={formik.values.mail}
            placeholder="Email address"
            onChange={formik.handleChange}
          />
        </div>
      </div>
    </ModalWrapper>
  );
};
export default EmailAlert;
