import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import Edit from 'assets/Images/ListsegmentImages/edit-table-active.svg';
import SubscribersHeader, { SUBSCRIBER_STATUS } from './SubscribersHeader';
import { TableColumn } from 'react-data-table-component';
import { getSubscribers } from 'services/apiHandlers/List-Segments/Subscriber';
import Table from 'components/Table';
import {  tableSort } from 'utils/common';
import { Link, useSearchParams } from 'react-router-dom';
import { scrollToTop } from 'utils/common';
import { z } from 'zod';
import {
  SubscribersData,
  GetSubscribersDataType,
  getSubscribersResponse,
} from './Types';
import { edit, view } from 'services/constant/routes';
import moment from 'moment-timezone';
import { UserContext } from 'store/UserContext';
import Wrapper from 'components/Wrapper';

const searchListSchema = z.object({
  list_id: z.number().nullable(),
  email_address: z.string(),
  opt_status: z.string(),
});
export type SearchData = z.infer<typeof searchListSchema>;

const PageDataSchema = z.object({
  page: z.number(),
  per_page: z.number(),
  list_id: z.number().nullable().optional(),
  opt_status: z.string().optional(),
  email_address: z.string().optional(),
});
export type PageData = z.infer<typeof PageDataSchema>;

const Subscribers: FC = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [subscriberList, setSubscriberList] = useState<GetSubscribersDataType>([]);
  const [searchParams] = useSearchParams();
  const [totalSubscribers, setTotalSubscribers] = useState<number>();
  const [searchListData, setSearchListData] = useState<PageData>({
    list_id: null,
    email_address: '',
    opt_status: SUBSCRIBER_STATUS.SELECT,
    page: 1,
    per_page: 100,
  });
  const [searchStatus, setSearchStatus] = useState<SUBSCRIBER_STATUS>(
    SUBSCRIBER_STATUS.SELECT
  );
  const userCtx = useContext(UserContext);
  const id = (searchParams.get('list_id'));

  const subscriberStatusSort = (
    rowA: SubscribersData,
    rowB: SubscribersData
  ) => {
    return tableSort(rowA.person.opt_status, rowB.person.opt_status);
  };

  const subscriberDateSort = (rowA: SubscribersData, rowB: SubscribersData) => {
    return tableSort((moment.tz(rowA.person.created_at, userCtx?.usersData?.timeZone || '').format()), (moment.tz(rowB.person.created_at, userCtx?.usersData?.timeZone || '').format()));
  };
 

  const columns: TableColumn<SubscribersData>[] = useMemo(
    () => [
      {
        name: <div className="px-3">Email</div>,
        cell: (row, index) => {
          return (
            <Link
              to={`${view}/${row.person.id}`}
              data-pw={`${index === 0 ? 'first-edit-subscriber' : ''}`}
              className="text-primary cursor-pointer underline text-sm font-medium px-3  mt-1 flex gap-3 dark:text-white"
            >
              <div>{row.person.email_address}</div>
            </Link>
          );
        },
      },
      {
        name: 'Status',
        cell: (row) => (
          <div className={`py-0.5 px-2.5 ${row.person.opt_status === 'active' ? 'text-success bg-success' : row.person.opt_status === 'unsubscribed' ? 'text-red-700 bg-red-100' : row.person.opt_status === 'inactive' ? 'text-gray-500 bg-gray-100' : 'text-yellow-500 bg-yellow-100'} rounded`}>
            {row.person.opt_status}
          </div>
        ),
        sortable: true,
        sortFunction: subscriberStatusSort,
      },
      {
        name: 'Subscribed',
        cell: (row) => {
          return (
            <div className="dark:text-white">
              {moment.tz(row.person.created_at, userCtx?.usersData?.timeZone || '').format('MMM DD, YYYY')}
            </div>
          );
        },
        sortFunction: subscriberDateSort,
      },
      {
        name: 'Actions',
        cell: (row, index) => (
          <Link
            data-pw={`${index === 0 ? 'subscriber-edit' : ''}`}
            type="button"
            className="mr-4 flex items-center font-medium hover:text-[#3575d5] dark:text-white"
            id={row.person.id.toString()}
            to={`${edit}/${row.person.id}`}
          >
            <img
              src={Edit}
              alt="Edit"
              className="inline mr-1.5 h-4 w-4 dark:invert dark:brightness-0"
            />
            <span className="text-[#3575d5] dark:text-white">Edit</span>
          </Link>
        ),
      },
    ],
    []
  );

  const responseData = async (pageData: PageData, preventLoading?: boolean) => {
    !preventLoading && setLoading(true);
    try {
      const getSubscribersResponse = (await getSubscribers(
        pageData
      )) as getSubscribersResponse;
      if (getSubscribersResponse.status === 200) {
        setTotalSubscribers(parseInt(getSubscribersResponse.data.data.total));
        setSubscriberList(getSubscribersResponse.data.data.subscribers);
      }
    } catch (error) {
      console.log('error is: ', error);
    }
    setLoading(false);
  };

  const handlePageChange = (page: number) => {
    scrollToTop();
    if (page !== searchListData.page) {
      setSearchListData({
        ...searchListData,
        page: page,
      });
      responseData({ ...searchListData, page: page });
    }
  };

  const handlePerRowsChange = async (newPerPage: number, page: number) => {
    scrollToTop();
    if (
      page !== searchListData.page ||
      newPerPage !== searchListData.per_page
    ) {
      setSearchListData({
        ...searchListData,
        page: page,
        per_page: newPerPage,
      });
      responseData({ ...searchListData, page: page, per_page: newPerPage });
    }
  };

  const onListChangeHandler = async (listId: string) => {
    setSearchListData({ ...searchListData, list_id: parseInt(listId) });
    setLoading(true);

    const pageData = {
      page: 1,
      per_page: searchListData.per_page,
      list_id: parseInt(listId) === -1 ? null : parseInt(listId),
      email_address: searchListData.email_address,
      opt_status: searchListData.opt_status,
    };
    responseData(pageData);
    setLoading(false);
  };

  useEffect(() => {
    setSearchListData({
      ...searchListData,
      opt_status: searchStatus.toLowerCase(),
      email_address: userCtx?.filteredValue || '',
    });
    const pageData = {
      page: 1,
      per_page: searchListData.per_page,
      list_id: searchListData.list_id === -1 ? null : searchListData.list_id,
      email_address: userCtx?.filteredValue || '',
      opt_status: searchStatus.toLowerCase(),
    };

    const searchInterval = setTimeout(() => {
      responseData(pageData, true);
    }, 1000);

    return () => {
      clearInterval(searchInterval);
    };
  }, [searchStatus, userCtx?.filteredValue]);

  // useEffect(() => {
  //   if (
  //     currentPage !== searchListData.page ||
  //     perPage !== searchListData.per_page
  //   ) {
  //     setSearchListData({
  //       ...searchListData,
  //       page: currentPage,
  //       per_page: perPage,
  //     });
  //     responseData({ ...searchListData, page: currentPage, per_page: perPage });
  //   }
  // }, [currentPage, perPage]);

  useEffect(() => {
    const pageData = {
      page: 1,
      per_page: searchListData.per_page,
      list_id: id ? parseInt(id) : null
    };
    responseData(pageData);
  }, []);

  return (
    <div className='px-4 p-5 min-h-[76vh]'>
      <Wrapper>
        <>
          <div className="flex flex-wrap  justify-between items-center px-4 border-b dark:border-[#fff] border-[#ced4da] py-[18px]">
            <p className="text-base text-[#495057] font-medium  dark:text-[#CED4DA] min-w-[155px]">
              Subscribers Profile
            </p>
            <div className="flex flex-wrap justify-end gap-2 items-center">
              <SubscribersHeader
                onListChange={onListChangeHandler}
                onStatusChange={setSearchStatus}
              />
            </div>
          </div>
          <Table
            data={subscriberList}
            columns={columns}
            paginationServer
            paginationTotalRows={totalSubscribers}
            paginationPerPage={searchListData.per_page}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            progressPending={loading}
          />
        </>
      </Wrapper>
    </div>
  );
};
export default Subscribers;
