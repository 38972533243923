import { FC, useContext, useEffect, useRef } from 'react';
import { useOutsideClick } from 'app/hooks/useOutsideClick';
import PopularLabels from './PopularLabels';
import Close from 'assets/Images/Workflow/close-Icon.png';
import React from 'react';
import { UserContext } from 'store/UserContext';

interface Props {
    setFilterValue: (filter: string) => void;
    onLabelClick: (value: string | undefined) => void;
    onClose: (show: boolean) => void;
}

const PopularModal: FC<Props> = ({ setFilterValue, onLabelClick, onClose }) => {
    const userCtx = useContext(UserContext);
    const modalRef = useRef<HTMLDivElement>(null);

    useOutsideClick(modalRef, () => onClose(false));

    useEffect(() => {
        const searchEmailTimeout = setTimeout(() => {
            if (userCtx?.filteredValue) {
                setFilterValue(userCtx?.filteredValue);
            } else {
                setFilterValue('');
            }
        }, 1000);

        return () => {
            clearTimeout(searchEmailTimeout);
        };
    }, [userCtx?.filteredValue]);

    return (
        <div className="flex bg-white rounded-md items-center w-[40%] mx-auto mt-40 relative">
            <div className="w-full "  ref={modalRef}>
                <div 
                    className="flex justify-between items-center px-5 border-b dark:border-b-0 border-[#e9ebec] py-[18px]"
                >
                    <p className="text-base text-[#495057] font-medium  ">
                        Popular Labels
                    </p>
                    <img
                        src={Close}
                        onClick={() => onClose(false)}
                        className="border-none cursor-pointer dark:invert dark:brightness-0"
                    />
                </div>
                <div className="flex p-4 justify-end">
                    <PopularLabels
                        onLabelClick={(value: string) => {
                            onLabelClick(value);
                        }}
                        onClose={onClose}
                    />
                </div>
            </div>
        </div>
    );
};
export default PopularModal;
