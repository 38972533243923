import {
  getAllFilters,
  deleteFilter as deleteFilterItem,
} from 'services/apiHandlers/List-Segments/Filter';
import DeleteModal from 'components/Alert/DeleteModal';
import React,{ useContext, useEffect, useMemo, useState } from 'react';
import { TableColumn } from 'react-data-table-component';
import Table from 'components/Table';
import { z } from 'zod';
import { Toastify } from 'App';
import { tableSort } from 'utils/common';
import { Link } from 'react-router-dom';
import { FilterDataSchema } from './Types';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { ThemeContext } from 'store/ThemeContext';
import {
  listSegments,
  filters as filterTab,
  view as filterView,
  create,
} from 'services/constant/routes';
import { UserContext } from 'store/UserContext';
// import DropdownIcon from 'assets/Images/Common/down-arrow.png';
import EditBlue from 'assets/Images/Common/edit-blue.png';
import DeleteIcon from 'assets/Images/Common/delete-red.png';

import BreadcrumbsWrapper from 'components/Wrapper/BreadcrumbsWrapper';
import Wrapper from 'components/Wrapper';

const responseStatusSchema = z.object({ status: z.number() });
type ResponseStatus = z.infer<typeof responseStatusSchema>;

const PageDataSchema = z.object({
  page: z.string(),
  per_page: z.string(),
});

const RuleSchema = z.object({ operator: z.string(), value: z.string() });

export const BlockSchema = z.object({
  field_name: z.string(),
  field_source: z.string(),
  field_type: z.string(),
  logic_operator: z.string(),
  rules: z.object({ rule: z.union([z.array(RuleSchema), RuleSchema]) }),
});

const filterDataSchema = z.object({
  collection: z.object({ filters: z.array(FilterDataSchema) }),
  current_page: z.number(),
  per_page: z.number(),
  total_count: z.number(),
});

const FilterSchema = z.object({
  data: filterDataSchema,
  status: z.number(),
});

export type Block = z.infer<typeof BlockSchema>;
export type FilterData = z.infer<typeof FilterDataSchema>;
export type FilterResponseData = z.infer<typeof FilterSchema>;
export type PageData = z.infer<typeof PageDataSchema>;

const Filter = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [filters, setFilters] = useState<Array<FilterData>>([]);

  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);
  const [deleteId, setDeleteid] = useState<number>(0);
  const themeCtx = useContext(ThemeContext);
  const [anchorId, setAnchorId] = useState<string>('');
  const [selectedName, setSelectedName] = useState<string | null>(null);
  const userCtx = useContext(UserContext);
  const theme = themeCtx?.theme;
  const [pageData, setPageData] = useState<PageData>({
    page: '1',
    per_page: '10',
  });
  const [totalFilters, setTotalFilters] = useState<number>(0);

  const getData = async (
    page: string,
    perPage: string,
    search?: string,
    preventLoading?: boolean
  ) => {
    !preventLoading && setLoading(true);
    try {
      const getFiltersResponse = (await getAllFilters(
        page,
        perPage,
        search
      )) as FilterResponseData;

      if (getFiltersResponse.status === 200) {
        const tempFilters = getFiltersResponse?.data?.collection?.filters.sort(
          (a, b) => a.name.localeCompare(b.name)
        );
        setFilters(tempFilters);

        setTotalFilters(getFiltersResponse.data.total_count);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
    setLoading(false);
  };

  const deleteHandler = async (id: number) => {
    try {
      const deleteFilterResponse = (await deleteFilterItem(
        id
      )) as ResponseStatus;
      if (deleteFilterResponse.status === 200) {
        Toastify('Filter Deleted Successfully', 'success', 'filter1');
        getData(pageData.page, pageData.per_page, undefined, true);
      }
    } catch (error) {
      Toastify('Deleting Filter Failed', 'error', 'filter2');
    }
  };

  const filterSort = (rowA: FilterData, rowB: FilterData) => {
    return tableSort(rowA.name, rowB.name);
  };

  const columns: TableColumn<FilterData>[] = useMemo(
    () => [
      {
        name: <div className="px-3">Filter</div>,
        cell: (row, index) => {
          return (
            <Link
              to={`${listSegments}/${filterTab}/${filterView}/${row.id}`}
              id={`${index === 0 ? row.id : ''}`}
              className="dark:text-white cursor-pointer text-[#495057] px-3 font-medium mt-1"
              data-pw={`${index === 0 ? 'first-filter' : ''}`}
            >
              <div data-pw={`${index === 0 ? 'first-filter-name' : ''}`}>
                {row.name}
              </div>
            </Link>
          );
        },
        sortable: true,
        sortFunction: filterSort,
      },
      {
        name: 'Description',
        cell: (row) => {
          return (
            <div className="dark:text-white">
              <div>{row.description}</div>
            </div>
          );
        },
      },
      {
        name: 'Actions',
        cell: (row, index) => {
          return (
            <td
              onMouseLeave={() => setSelectedName(null)}
              className="whitespace-nowrap pr-3 text-13 sm:align-top align-middle text-[#495057] dark:text-[#CED4DA]  dark:text-white dark:text-white md:w-[400px] md:min-w-[400px] w-[200px] min-w-[200px] flex flex-wrap items-center "
            >
              {!userCtx?.currentUserData?.readonly_mode && (
                <div className="flex items-center">
                  {row.mutable === 'true' && (
                    <div className="flex items-center">
                      <Link
                        to={`${listSegments}/${filterTab}/${row.id}`}
                        className="mr-4 flex items-center hover:text-[#3575d5]"
                      >
                        <img src={EditBlue} alt="EditBlue" className="mr-2" />
                        <span className="text-primary ">Edit</span>
                      </Link>
                      {row.used_by_trigger ? (
                        <>
                          <div className="flex items-center my-1.5 text-[#878A99] hover:text-[#3575d5]">
                            Locked
                          </div>
                          <div className="inline-block relative ml-2">
                            <svg
                              id={`tooltip-anchor-children-${index}`}
                              onMouseEnter={() => {
                                setAnchorId(`tooltip-anchor-children-${index}`);
                                setSelectedName(row.name);
                              }}
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                              strokeWidth="1.5"
                              stroke="#878A99"
                              className="w-3.5 h-3.5"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
                              />
                            </svg>
                            <Tooltip
                              style={{
                                pointerEvents: 'auto',
                                backgroundColor: `${
                                  theme === 'dark' ? '#1E293B' : 'white'
                                }`,
                                border: '1px solid #9E9E9E',
                                zIndex: '1',
                                opacity: '1',
                                overflow: 'hidden',
                              }}
                              isOpen={selectedName === row.name}
                              anchorId={anchorId}
                              classNameArrow="-bottom-[5px] border border-gray-500 border-t-0 border-l-0"
                            >
                              <div className="text-[#495057] dark:text-[#CED4DA]  dark:text-white w-60">
                                <div className=" gap-1 items-center">
                                  <h1 className="font-bold text-lg py-2 ">
                                    Used by a Trigger
                                  </h1>
                                  <div className="whitespace-normal">
                                    Filter cannot be deleted because it is being
                                    used by the trigger:
                                    <div className="font-bold">
                                      {row.used_by_trigger}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Tooltip>
                          </div>
                        </>
                      ) : (
                        <button
                          onClick={() => {
                            setDeleteid(row.id);
                            setShowDeleteAlert(true);
                          }}
                          type="button"
                          className="flex items-center my-1.5 hover:text-[#3575d5]"
                          data-pw={`${
                            index === 0 ? 'first-filter-delete' : ''
                          }`}
                          id={`${index === 0 ? row.id.toString() : ''}`}
                        >
                          <span className="text-red-500">Delete</span>
                          <img
                            src={DeleteIcon}
                            alt="DeleteIcon"
                            className="ml-2 h-[14px] w-[14px]"
                          />
                        </button>
                      )}
                    </div>
                  )}

                  {row.mutable === 'false' && (
                    <div>
                      <div className="flex items-center my-1.5 hover:text-[#3575d5]">
                        Locked
                      </div>
                    </div>
                  )}
                </div>
              )}
            </td>
          );
        },
      },
    ],
    [selectedName]
  );

  const handlePageChange = (page: number) => {
    setPageData({ page: page.toString(), per_page: '10' });
    getData(page.toString(), '10');
  };

  useEffect(() => {
    getData('1', '10');
  }, []);

  useEffect(() => {
    const searchInterval = setTimeout(() => {
      if (userCtx?.filteredValue) {
        getData(pageData.page, pageData.per_page, userCtx.filteredValue);
      } else {
        getData(pageData.page, pageData.per_page, '');
      }
    }, 1000);

    return () => {
      clearInterval(searchInterval);
    };
  }, [userCtx?.filteredValue]);

  return (
    <div>
      <BreadcrumbsWrapper>
        <Link
          to={`${listSegments}/${filterTab}/${create}`}
          className="text-sm font-medium leading-4 flex justify-center items-center bg-primary px-4 py-2.5 rounded-md shadow-lg shadow-gray-200 dark:shadow-none ease-in duration-300 hover:bg-primaryHover h-11 w-32 text-white hover:scale-105"
        >
          Create Filter
        </Link>
      </BreadcrumbsWrapper>
      {showDeleteAlert && (
        <div
          onClick={() => setShowDeleteAlert(false)}
          className="fixed left-0 top-0 w-full h-full z-10 bg-black-200 duration-300"
        >
          <DeleteModal
            onDelete={() => deleteHandler(deleteId)}
            onCancel={() => setShowDeleteAlert(false)}
          />
        </div>
      )}
      <div className="mx-4 my-8">
        <div className="relative min-h-[71vh]">
          <Wrapper>
            <div>
              <div className="flex justify-between items-center px-5 border-b dark:border-[#fff] border-[#e9ebec] py-[18px]">
                <p className="text-base text-[#495057] font-medium  dark:text-[#CED4DA]">
                  Filters
                </p>
                {/* <div className="flex">
                  <div className="relative">
                    <div className="flex items-center cursor-pointer">
                      <p className="text-xs font-semibold text-[#212529] dark:text-[#CED4DA]">
                        Sort by:
                      </p>
                      <p className="text-[#878A99] ml-1 text-sm">
                        Current Week
                      </p>
                      <img
                        src={DropdownIcon}
                        alt="Dropdown Icon"
                        className="ml-1"
                      />
                    </div>
                  </div>
                </div> */}
              </div>
              <Table
                className="scrollbar-hide"
                data={filters}
                columns={columns}
                progressPending={loading}
                paginationServer
                paginationTotalRows={totalFilters}
                onChangePage={handlePageChange}
                paginationPerPage={10}
              />
            </div>
          </Wrapper>
        </div>
      </div>
    </div>
  );
};
export default Filter;
