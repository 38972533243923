import { FC, useState, useEffect } from 'react';
import {
  TotalOrders,
  AverageOrder,
  OnlineStore,
} from 'staticdata/Dashboard/Data';
import StoreReport from './Report';
import { storeReportOrder } from './Types';
import React from 'react';
import { getOrderRevenueReport } from 'services/apiHandlers/Dashboard/Reports';
import { z } from 'zod';
import moment from 'moment';
import {
  calculatePercentageDifference,
  convertStringToWholeNumber,
} from 'utils/common';
const DateSchema = z.object({ date: z.string(), total: z.number() });
const OrderSchema = z.object({
  metric: z.string(),
  dates: z.array(DateSchema),
});
const OrderResponseSchema = z.object({
  data: z.array(OrderSchema),
  status: z.number(),
});
type OrderResponse = z.infer<typeof OrderResponseSchema>;
interface Props {
  loading: boolean
}
const Orders : FC<Props> = ({ loading }) =>{
  const [orders, setOrders] = useState<storeReportOrder>();
  const [averageRevenue, setAverageRevenue] = useState<storeReportOrder>();
  const [revenue, setRevenue] = useState<storeReportOrder>();
  const [ordersLoading, setOrdersLoading] = useState<boolean>(true);
  const getData = async () => {
    setOrdersLoading(true);
    const currentMonthDate = moment().format('YYYY-MM-DD');
    const previousSixMonthDate = moment()
      .subtract(5, 'months')
      .startOf('month')
      .format('YYYY-MM-DD');
    const previousYearDate = moment().subtract(1, 'year').format('YYYY-MM-DD');
    const previousYearSixMonthsDate = moment(previousYearDate)
      .subtract(5, 'months')
      .startOf('month')
      .format('YYYY-MM-DD');
    const labels: string[] = [];
    const tempOrderLabels: string[] = [];
    const tempCurrentYearOrderData: string[] = [];
    const tempCurrentOrderData: string[] = [];
    const tempAverageRevenueLabels: string[] = [];
    const tempPreviousYearAverageRevenueData: string[] = [];
    const tempCurrentYearAverageRevenueData: string[] = [];
    const tempRevenueLabels: string[] = [];
    const tempPreviousYearRevenueData: string[] = [];
    const tempCurrentYearRevenueData: string[] = [];
    try {
      const getCurrentOrderResponse = (await getOrderRevenueReport(
        previousSixMonthDate,
        currentMonthDate
      )) as OrderResponse;
      const getPreviousOrderResponse = (await getOrderRevenueReport(
        previousYearSixMonthsDate,
        previousYearDate
      )) as OrderResponse;
      if (getCurrentOrderResponse.status === 200) {
        getCurrentOrderResponse.data.map((data) =>
          data.dates.map(
            (date) => (
              data.metric === 'Orders' &&
                tempOrderLabels.push(
                  moment(date.date, 'MMMM YYYY').format('MMM')
                ),
              data.metric === 'Orders' &&
                tempCurrentOrderData.push(date.total.toString()),
              data.metric === 'Average Revenue' &&
                tempAverageRevenueLabels.push(
                  moment(date.date, 'MMMM YYYY').format('MMM')
                ),
              data.metric === 'Average Revenue' &&
                tempPreviousYearAverageRevenueData.push(date.total.toString()),
              data.metric === 'Revenue' &&
                tempRevenueLabels.push(
                  moment(date.date, 'MMMM YYYY').format('MMM')
                ),
              data.metric === 'Revenue' &&
                tempPreviousYearRevenueData.push(date.total.toString()),
              data.metric === 'Revenue' &&
                labels.push(moment(date.date, 'MMMM YYYY').format('MMM'))
            )
          )
        );
      }
      if (getPreviousOrderResponse.status === 200) {
        getPreviousOrderResponse.data.map((data) =>
          data.dates.map(
            (date) => (
              data.metric === 'Orders' &&
                tempOrderLabels.push(
                  moment(date.date, 'MMMM YYYY').format('MMM')
                ),
              data.metric === 'Orders' &&
                tempCurrentYearOrderData.push(date.total.toString()),
              data.metric === 'Average Revenue' &&
                tempAverageRevenueLabels.push(
                  moment(date.date, 'MMMM YYYY').format('MMM')
                ),
              data.metric === 'Average Revenue' &&
                tempCurrentYearAverageRevenueData.push(date.total.toString()),
              data.metric === 'Revenue' &&
                tempRevenueLabels.push(
                  moment(date.date, 'MMMM YYYY').format('MMM')
                ),
              data.metric === 'Revenue' &&
                tempCurrentYearRevenueData.push(date.total.toString())
            )
          )
        );
      }
      const averageRevenueData = [
        {
          label: ' Current Period',
          data: tempPreviousYearAverageRevenueData,
          backgroundColor: '#2EA1F9',
          barPercentage: 1.0,
          barThickness: 25,
        },
        {
          label: ' Previous Period',
          data: tempCurrentYearAverageRevenueData,
          backgroundColor: '#8FDBC0',
          barPercentage: 1.0,
          barThickness: 25,
        },
      ];
      const revenueData = [
        {
          label: ' Current Period',
          data: tempPreviousYearRevenueData,
          borderColor: '#FBB846',
          backgroundColor: '#FBB846',
        },
        {
          label: ' Previous Period',
          data: tempCurrentYearRevenueData,
          borderColor: '#A292DA',
          backgroundColor: '#A292DA',
        },
      ];
      const OrdersData = [
        {
          label: '  Current Period',
          data: tempCurrentOrderData,
          borderColor: '#FBB846',
          backgroundColor: '#FBB846',
        },
        {
          label: '  Previous Period',
          data: tempCurrentYearOrderData,
          borderColor: '#A292DA',
          backgroundColor: '#A292DA',
        },
      ];
      const currentTotalOrders = tempCurrentOrderData.reduce(
        (accumulator, orderData) => {
          return accumulator + parseInt(orderData);
        },
        0
      );
      const previousTotalOrders = tempCurrentYearOrderData.reduce(
        (accumulator, orderData) => {
          return accumulator + parseInt(orderData);
        },
        0
      );
      const ordersPercenatge = calculatePercentageDifference(
        previousTotalOrders,
        currentTotalOrders
      );
      const lastThreeMonthOrders = tempCurrentOrderData
        .slice(3, tempCurrentOrderData.length)
        .reduce((accumulator, orderData) => {
          return accumulator + parseInt(orderData);
        }, 0);
      const currentOrderLastThreeMonths = tempCurrentOrderData.reduce(
        (accumulator, orderData) => {
          return accumulator + parseInt(orderData);
        },
        0
      );
      const totalOrders = {
        orderType: 'Total Orders',
        orderValue: currentTotalOrders.toString(),
        orderPercentage: ordersPercenatge.toString(),
        graphType: 'Line',
        graphCategories: labels,
        graphData: OrdersData,
        graphHeight: 250,
        graphWidth: 450,
        graphStyle: { maxWidth: '450px', width: '100%', height: 'auto' },
        lastMonth: tempCurrentOrderData[tempCurrentOrderData.length - 1],
        last90Days: lastThreeMonthOrders.toString(),
        last14Days: currentOrderLastThreeMonths.toString(),
      };
      const totalAverageRevenue = tempPreviousYearAverageRevenueData.reduce(
        (accumulator, averageRevenue) => {
          return accumulator + parseInt(averageRevenue);
        },
        0
      );
      const lastThreeMonthAverageRevenue = Math.floor(
        tempPreviousYearAverageRevenueData
          .slice(3, tempCurrentOrderData.length)
          .reduce((accumulator, averageRevenue) => {
            return accumulator + parseInt(averageRevenue);
          }, 0)
      );
      const previousAverageRevenues = tempCurrentYearAverageRevenueData.reduce(
        (accumulator, averageRevenue) => {
          return accumulator + parseFloat(averageRevenue);
        },
        0
      );
      const averageRevenuePercentage = calculatePercentageDifference(
        previousAverageRevenues,
        totalAverageRevenue
      );
      const lastSixMonthAverageRevenue = Math.floor(
        tempPreviousYearAverageRevenueData.reduce(
          (accumulator, averageRevenueData) => {
            return accumulator + parseInt(averageRevenueData);
          },
          0
        )
      );
      const lastAverageMonth = convertStringToWholeNumber(
        tempPreviousYearAverageRevenueData[
          tempPreviousYearAverageRevenueData.length - 1
        ]
      );
      const AverageRevenue = {
        orderType: 'Average Revenue',
        orderValue: `$${totalAverageRevenue.toString()}`,
        orderPercentage: averageRevenuePercentage.toString(),
        graphType: 'Coloumn',
        graphCategories: labels,
        graphData: averageRevenueData,
        graphStyle: { maxWidth: '450px', width: '100%' },
        graphHeight: 167,
        lastMonth: `$${lastAverageMonth.toString()}`,
        last90Days: `$${lastThreeMonthAverageRevenue}`,
        last14Days: `$${lastSixMonthAverageRevenue}`,
      };
      const totalRevenue = tempPreviousYearRevenueData.reduce(
        (accumulator, revenue) => {
          return accumulator + parseInt(revenue);
        },
        0
      );
      const lastThreeMonthRevenue = Math.floor(tempPreviousYearRevenueData
        .slice(3, tempPreviousYearRevenueData.length)
        .reduce((accumulator, revenue) => {
          return accumulator + parseInt(revenue);
        }, 0));
      const previousYearsRevenue = tempCurrentYearRevenueData.reduce(
        (accumulator, revenue) => {
          return accumulator + parseInt(revenue);
        },
        0
      );
      const totalRevenuePercentage = calculatePercentageDifference(
        previousYearsRevenue,
        totalRevenue
      );
      const lastSixMonthRevenue = Math.floor(tempPreviousYearRevenueData.reduce(
        (accumulator, revenueData) => {
          return accumulator + parseInt(revenueData);
        },
        0
      ));
      const revenue = {
        orderType: 'Revenue',
        orderValue: `$${totalRevenue.toString()}`,
        orderPercentage: totalRevenuePercentage.toString(),
        graphType: 'Line',
        graphCategories: labels,
        graphData: revenueData,
        graphHeight: 250,
        graphWidth: 450,
        graphStyle: { maxWidth: '450px', width: '100%', height: 'auto' },
        lastMonth: `$${convertStringToWholeNumber(
          tempPreviousYearRevenueData[tempPreviousYearRevenueData.length - 1]
        )}`,
        last90Days: `$${lastThreeMonthRevenue.toString()}`,
        last14Days: `$${lastSixMonthRevenue}`,
      };
      setOrders(totalOrders);
      setAverageRevenue(AverageRevenue);
      setRevenue(revenue);
      setOrdersLoading(false);
    } catch (error) {
      console.log('error is: ', error);
    }
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <div>
      <div className="md:grid md:grid-cols-3 gap-4 font-inter md:mt-4">
        <StoreReport
          data={orders ? orders : TotalOrders}
          loading={ordersLoading || loading}
        />
        <StoreReport
          data={averageRevenue ? averageRevenue : AverageOrder}
          loading={ordersLoading || loading}
        />
        <StoreReport
          data={revenue ? revenue : OnlineStore}
          loading={ordersLoading || loading}
        />
      </div>
    </div>
  );
};
export default Orders;