import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import ErrorBoundary from 'components/ErrorBoundary';
import React from 'react';

const Integration = () => {
  return (
    <ErrorBoundary>
      <div>
        <div className="md:px-6 px-3 py-3 border-b dark:border-[#fff] font-inter">
          <div className="md:px-1.5 flex items-center justify-between">
            <Breadcrumbs />
            <h2 className="text-base text-[#495057] font-bold dark:text-white ml-2.5">
              Integration
            </h2>
          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};
export default Integration;
