import Editor from 'components/Email/Editor';
import { useParams} from 'react-router-dom';
import { useEffect, useState } from 'react';
import { z } from 'zod';
import { getEmail, autosaveEmail } from 'services/apiHandlers/Campaigns/Emails';
import { initPlugin } from 'utils/stripo';
import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
import React from 'react';
import moment from 'moment';

export const emailDataSchema = z.object({
  account_id: z.number(),
  agreed_to_canspam_address_for_html: z.number().nullable(),
  agreed_to_canspam_address_for_text: z.number().nullable(),
  content_type: z.string(),
  created_at: z.string(),
  creator_id: z.number(),
  creator_type: z.string(),
  css: z.string(),
  description: z.string().nullable(),
  html_part: z.string(),
  id: z.number(),
  name: z.string(),
  process: z.string(),
  recovered_draft: z.string(),
  save_type: z.string(),
  size_in_bytes: z.number(),
  source_email_id: z.number(),
  subject: z.string().nullable(),
  tag_list: z.union([z.array(z.string()), z.tuple([])]),
  template_id: z.number(),
  template_type: z.string(),
  text_part: z.string(),
  updated_at: z.string(),
  widgetization_error: z.string().nullable(),
});
export type EmailData = z.infer<typeof emailDataSchema>;

const emailResponseSchema = z.object({
  data: z.object({ email: emailDataSchema }),
  status: z.number(),
});
type EmailResponse = z.infer<typeof emailResponseSchema>;

const PreviewEditor = () => {
  const [emailData, setEmailData] = useState<EmailData>();
  const [name, setName] = useState<string>('');
  const [labels, setLabels] = useState<string>('');
  const [editorLoading, setEditorLoading] = useState<boolean>(true);
  const {id} = useParams();

  const getData = async (id: string, force?: boolean) => {
    try {
      const getEmailResponse = (await getEmail(id, force)) as EmailResponse;
      if (getEmailResponse.status === 200) {
        setEmailData(getEmailResponse.data.email);
      }
    } catch (error) {
      console.log('error is : ', error);
      setEditorLoading(false);
    }
  };

  const codeChanged = async () => {
    const saveDraftEmail = setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.StripoApi.getTemplate(async (email: string) => {
        const data = {
          draft: {
            html_part: email,
            updated_at: moment().format('DD-MM-YYYY HH:mm:ss'),
          },
        };
        try {
          await autosaveEmail(data);
        } catch (error) {
          console.log('error is : ', error);
        }
      });
    }, 5000);
    return () => clearTimeout(saveDraftEmail);
  };

  useEffect(() => {
    if (id) {
      const editEmailId = id;
      if (editEmailId) {
        getData(editEmailId);
       
        
      }
    }
  }, []);

  useEffect(() => {
    if (emailData) {
      const email = emailData.html_part;
      
      setName(emailData.name);
      setLabels(emailData.tag_list.join(','));
      initPlugin(email, codeChanged, false, () => setEditorLoading(false));
    }
  }, [emailData]);

  return (
    <div className="pt-5 h-full">
      <div className="ml-2">
        <Breadcrumbs />
      </div>
      <div className="bg-[#ddebff7a]">
        <div className="md:px-6 px-3 pt-5 bg-white  dark:bg-[#41464E]  border-b border-gray-800 dark:border-[#fff] font-inter">
          {/* <Header
            name={name}
            lastUpdated={lastUpdated}
            id={emailId}
            loading={editorLoading}
          /> */}
        </div>
        <Editor name={name} labels={labels} loading={editorLoading} />
      </div>
    </div>
  );
};
export default PreviewEditor;
