import listMenu from 'assets/Images/ListsegmentImages/list-menu.png';
import filter from 'assets/Images/ListsegmentImages/filter.png';
import lists from 'assets/Images/ListsegmentImages/lists.png';
import ListHeading from 'components/List-Segments/ListHeading';
import ErrorBoundary from 'components/ErrorBoundary';
import React from 'react';
import {
  filters,
  listSegments,
  list,
  segmentDefinition,
} from 'services/constant/routes';
import BreadcrumbsWrapper from 'components/Wrapper/BreadcrumbsWrapper';

export enum TabsEnum {
  LIST = 'list-segment',
  SUBSCRIBERS = 'subscriber',
  FILTER = 'filter',
  SEGMENT = 'segment',
}

export const tabs = [
  {
    src: listMenu,
    alt: 'List',
    title: 'Lists & Segments',
    link: listSegments,
    active: list,
  },
  {
    src: filter,
    alt: 'Filter',
    title: 'Filters',
    link: `${listSegments}/${filters}`,
    active: filters,
  },
  {
    src: lists,
    alt: 'Lists',
    title: 'List Segmentation',
    link: `${listSegments}/${segmentDefinition}`,
    active: segmentDefinition,
  },
];

const ListSegmentHeader = () => {
  return (
    <ErrorBoundary>
      <BreadcrumbsWrapper />
      <div className="md:px-6 px-3 pt-5 border-b dark:border-[#fff] font-inter">
        <div className="md:px-1.5">
          <ListHeading />
          {/* <Tabs tabs={tabs}  /> */}
        </div>
      </div>
    </ErrorBoundary>
  );
};

export default ListSegmentHeader;
