import { Link } from 'react-router-dom';
import { listSegments, create, list } from 'services/constant/routes';
import React from 'react';
import BreadcrumbsWrapper from 'components/Wrapper/BreadcrumbsWrapper';
const ListSegmentHeader = () => {
  return (
    <>
      <div>
        <BreadcrumbsWrapper>
          <Link
            to={`${listSegments}/${list}/${create}`}
            className=" lg:relative absolute right-0 lg:mr-0 mr-4 lg:w-full"
          >
            <button className="sm:text-sm text-xs font-medium  text-white flex items-center ml-auto rounded-md py-3 sm:pl-4 px-2 sm:pr-3.5 font-inter bg-primary ease-in-in duration-300 hover:bg-primary hover:scale-105">
              Create List
            </button>
          </Link>
        </BreadcrumbsWrapper>
      </div>
    </>
  );
};
export default ListSegmentHeader;
