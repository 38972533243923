import {
    workflow,
    workflowReport,
    home,
    listSegments,
    create,
    logs,
    createCampaign,
    campaigns,
    emailTemplates,
    campaignReport,
    integration,
    filters,
    segmentDefinition,
    upload,
    fields,
    edit,
    view,
    add,
    filter,
    matches,
    emailEditor,
    previewEmail,
    cartIntegration,
    scripts,
    google,
    miscellaneous,
    subscribersProfile,
    list,
    reports,
    eventLogs,
    calendar,
    workflowReports,
    campaignReports,
    users,
    account,
} from 'services/constant/routes';
import { Link, useLocation, useParams } from 'react-router-dom';
import ErrorBoundary from 'components/ErrorBoundary';
import DropdownIcon from 'assets/Images/Common/down-arrow.png';
import React from 'react';
const Breadcrumbs = () => {
    const { id, idNested } = useParams();
    // const navigate = useNavigate();
    const { pathname, search } = useLocation();
    const searchParams = new URLSearchParams(search);
    const tab = searchParams.get('tab');
    const campaignId = searchParams.get('campaignId');
    const report = searchParams.get('report');
    const createEmail = searchParams.get('create');
    const tabUpper = tab?.charAt(0).toUpperCase().concat(tab?.slice(1));
    const idFinal = id?.includes(' ') ? encodeURIComponent(id) : id;
    const BreadCrumbs = {
        [home]: [{ title: 'Dashboard', link: home }, { title: '' }],
        [campaignReport + `/${idFinal}`]: [
            { title: 'Home', link: home },
            { title: 'Campaigns', link: campaigns },
            { title: 'Campaign Report', link: campaignReport },
        ],
        [campaigns]: [
            { title: 'Home', link: home },
            { title: 'Campaigns', link: campaigns },
        ],
        [campaigns + `/${create}`]: [
            { title: 'Home', link: home },
            {
                title: 'Campaigns',
                link: campaigns,
            },
            {
                title: 'Create Campaign',
                link: `${campaigns}/${create}`,
            },
        ],
        [campaigns + `/${create}` + `/${idFinal}`]: [
            { title: 'Home', link: home },
            {
                title: 'Campaigns',
                link: campaigns,
            },
            {
                title: 'Edit Campaign',
                link: createCampaign,
            },
        ],
        [campaigns + `/${calendar}`]: [
            { title: 'Home', link: home },
            {
                title: 'Campaigns',
                link: campaigns,
            },
            {
                title: 'Calendar',
                link: `${campaigns}/${calendar}`,
            },
        ],
        [listSegments + `/${list}`]: [
            { title: 'Home', link: home },
            { title: 'List & Segments', link: `${listSegments}/${list}` },
            { title: 'List', link: '' },
        ],
        [listSegments + `/${list}/${idFinal}`]: [
            { title: 'Home', link: home },
            { title: 'List & Segments', link: `${listSegments}/${list}` },
            { title: 'List', link: `${listSegments}/${list}` },
            { title: 'Edit', link: `${listSegments}/${list}/${idFinal}` },
        ],
        [listSegments + `/${list}/${create}`]: [
            { title: 'Home', link: home },
            { title: 'List & Segments', link: `${listSegments}/${list}` },
            { title: 'List', link: `${listSegments}/${list}` },
            { title: 'Create', link: `${listSegments}/${list}/${create}` },
        ],
        [listSegments + `/${list}/${create}`]: [
            { title: 'Home', link: home },
            { title: 'List & Segments', link: `${listSegments}/${list}` },
            { title: 'List', link: `${listSegments}/${list}` },
            { title: 'Create', link: `${listSegments}/${list}/${create}` },
        ],
        [listSegments + `/${list}/${add}/${idFinal}`]: [
            { title: 'Home', link: home },
            { title: 'List & Segments', link: `${listSegments}/${list}` },
            { title: 'List', link: `${listSegments}/${list}` },
            { title: 'Add', link: `${listSegments}/${list}/${add}/${idFinal}` },
        ],
        [listSegments + `/${list}/${add}/${idFinal}/${filter}`]: [
            { title: 'Home', link: home },
            { title: 'List & Segments', link: `${listSegments}/${list}` },
            { title: 'List', link: `${listSegments}/${list}` },
            { title: 'Add', link: `${listSegments}/${list}/${add}/${idFinal}` },
            {
                title: 'Filter',
                link: `${listSegments}/${list}/${add}/${idFinal}/${filter}`,
            },
        ],
        [`/${subscribersProfile}`]: [
            { title: 'Home', link: home },
            { title: 'Subscribers', link: `/${subscribersProfile}` },
        ],
        [`/${subscribersProfile}/${upload}`]: [
            { title: 'Home', link: home },
            { title: 'Subscribers', link: `/${subscribersProfile}`  },
            {
                title: 'Upload',
                link: `/${subscribersProfile}/${upload}`,
            },
        ],
        [`/${subscribersProfile}/${upload}/${create}`]: [
            { title: 'Home', link: home },
            { title: 'Subscribers', link: `/${subscribersProfile}`  },
            {
                title: 'Upload',
                link: `/${subscribersProfile}/${upload}`,
            },
            {
                title: 'Create',
                link: `/${subscribersProfile}/${upload}/${create}`,
            },
        ],
        [`/${subscribersProfile}/${fields}`]: [
            { title: 'Home', link: home },
            { title: 'Subscribers', link: `/${subscribersProfile}`  },
            {
                title: 'Fields',
                link: `/${subscribersProfile}/${fields}`,
            },
        ],
        [`/${subscribersProfile}/${fields}/${edit}/${idFinal}`]: [
            { title: 'Home', link: home },
            { title: 'Subscribers', link: `/${subscribersProfile}`  },
            {
                title: 'Fields',
                link: `/${subscribersProfile}/${fields}`,
            },
            {
                title: 'Edit',
                link: `/${subscribersProfile}/${fields}/${edit}/${idFinal}`,
            },
        ],
        [`/${subscribersProfile}/${edit}/${idFinal}`]: [
            { title: 'Home', link: home },
            { title: 'Subscribers', link: `/${subscribersProfile}`  },
            {
                title: 'Edit',
                link: `/${subscribersProfile}/${edit}/${idFinal}`,
            },
        ],
        [`/${subscribersProfile}/${view}/${idFinal}`]: [
            { title: 'Home', link: home },
            { title: 'Subscribers', link: `/${subscribersProfile}`  },
            {
                title: 'View',
                link: `/${subscribersProfile}/${view}/${idFinal}`,
            },
        ],
        [listSegments + `/${filters}`]: [
            { title: 'Home', link: home },
            { title: 'List & Segments', link: `${listSegments}/${list}` },
            { title: 'Filters', link: `${listSegments}/${filters}` },
        ],
        [listSegments + `/${filters}/${create}`]: [
            { title: 'Home', link: home },
            { title: 'List & Segments', link: `${listSegments}/${list}` },
            { title: 'Filters', link: `${listSegments}/${filters}` },
            { title: 'Create', link: `${listSegments}/${filters}/${create}` },
        ],
        [listSegments + `/${filters}/${idFinal}`]: [
            { title: 'Home', link: home },
            { title: 'List & Segments', link: `${listSegments}/${list}` },
            { title: 'Filters', link: `${listSegments}/${filters}` },
            { title: 'Edit', link: `${listSegments}/${filters}/${idFinal}` },
        ],
        [listSegments + `/${filters}/${matches}/${idFinal}`]: [
            { title: 'Home', link: home },
            { title: 'List & Segments', link: `${listSegments}/${list}` },
            { title: 'Filters', link: `${listSegments}/${filters}` },
            { title: 'View', link: `${listSegments}/${filters}/${view}/${idFinal}` },
            {
                title: 'Matches',
                link: `${listSegments}/${filters}/${matches}/${idFinal}`,
            },
        ],
        [listSegments + `/${filters}/${view}/${idFinal}`]: [
            { title: 'Home', link: home },
            {
                title: report || campaignId ? 'Campaigns' : 'List & Segments',
                link:
                    report || campaignId
                        ? campaigns
                        : `${listSegments}/${list}`,
            },
            report || campaignId
                ? {
                      title: 'Campaign Report',
                      link: `${campaignReport}/${campaignId}`,
                  }
                : { title: null, link: `${listSegments}/${list}` },
            !report || !campaignId
                ? { title: 'Filter', link: `${listSegments}/${filters}` }
                : { title: null, link: `${campaignReport}/${campaignId}` },
            !report || !campaignId
                ? {
                      title: 'View',
                      link: `${listSegments}/${filters}/${view}/${idFinal}`,
                  }
                : {
                      title: 'View Filter',
                      link: `${listSegments}/${filters}/${view}/${idFinal}`,
                  },
        ],
        [listSegments + `/${segmentDefinition}`]: [
            { title: 'Home', link: home },
            { title: 'List & Segments', link: `${listSegments}/${list}` },
            {
                title: 'List Segmentation',
                link: `${listSegments}/${segmentDefinition}`,
            },
        ],
        [listSegments + `/${segmentDefinition}/${create}`]: [
            { title: 'Home', link: home },
            { title: 'List & Segments', link: `${listSegments}/${list}` },
            {
                title: 'List Segmentation',
                link: `${listSegments}/${segmentDefinition}`,
            },
            {
                title: 'Create',
                link: `${listSegments}/${segmentDefinition}/${create}`,
            },
        ],
        [listSegments + `/${segmentDefinition}/${idFinal}`]: [
            { title: 'Home', link: home },
            { title: 'List & Segments', link: `${listSegments}/${list}` },
            {
                title: 'List Segmentation',
                link: `${listSegments}/${segmentDefinition}`,
            },
            {
                title: 'Edit',
                link: `${listSegments}/${segmentDefinition}/${idFinal}`,
            },
        ],
        [integration + `/${cartIntegration}`]: [
            { title: 'Home', link: home },
            { title: 'Integration', link: `${integration}/${cartIntegration}` },
            {
                title: 'Cart Integration',
                link: `${integration}/${cartIntegration}`,
            },
        ],
        [integration + `/${scripts}`]: [
            { title: 'Home', link: home },
            { title: 'Integration', link: `${integration}/${cartIntegration}` },
            { title: 'Scripts', link: `${integration}/${scripts}` },
        ],
        [integration + `/${google}`]: [
            { title: 'Home', link: home },
            { title: 'Integration', link: `${integration}/${cartIntegration}` },
            { title: 'Google Analytics', link: `${integration}/${google}` },
        ],
        [integration + `/${miscellaneous}`]: [
            { title: 'Home', link: home },
            { title: 'Integration', link: `${integration}/${cartIntegration}` },
            { title: 'Miscellaneous', link: `${integration}/${miscellaneous}` },
        ],
        [emailTemplates]: [
            { title: 'Home', link: home },
            { title: 'Email Templates', link: emailTemplates },
            { title: tabUpper, link: '' },
        ],
        [emailEditor]: [
            { title: 'Home', link: home },
            { title: 'Email Templates', link: emailTemplates },
            createEmail
                ? { title: 'Create', link: `${emailEditor}/${create}` }
                : { title: null, link: emailTemplates },
            { title: 'Editor', link: emailEditor },
        ],
        [emailEditor + `/${create}`]: [
            { title: 'Home', link: home },
            { title: 'Email Templates', link: emailTemplates },
            { title: 'Create', link: `${emailEditor}/${create}` },
        ],
        [emailEditor + `/${edit}/${idFinal}`]: [
            { title: 'Home', link: home },
            { title: 'Email Templates', link: emailTemplates },
            { title: 'Edit', link: `${emailEditor}/${edit}/${idFinal}` },
        ],
        [previewEmail]: [
            { title: 'Home', link: home },
            { title: 'Email Templates', link: emailTemplates },
            { title: 'Preview Email', link: previewEmail },
        ],
        [workflowReport + `/${idFinal}`]: [
            { title: 'Home', link: home },
            { title: 'Workflow', link: workflow },
            { title: 'Workflow Reports', link: `${workflowReport}/${idFinal}` },
        ],
        [workflow]: [
            { title: 'Home', link: home },
            { title: 'Workflows', link: workflow },
        ],
        [workflow + `/${create}`]: [
            { title: 'Home', link: home },
            { title: 'Workflows', link: workflow },
            { title: 'Create Workflow', link: `${workflow}/${create}` },
        ],
        [workflow + `/${idFinal}`]: [
            { title: 'Home', link: home },
            { title: 'Workflows', link: workflow },
            { title: 'Edit Workflow', link: `${workflow}/${idFinal}` },
        ],
        [workflow + `/${logs}` + `/${idFinal}`]: [
            { title: 'Home', link: home },
            { title: 'Workflows', link: workflow },
            { title: 'Workflow Logs', link: `${workflow}/${logs}/${idFinal}` },
        ],
        [workflow + `/${logs}` + `/${idFinal}` + `/${idNested}`]: [
            { title: 'Home', link: home },
            { title: 'Workflow', link: workflow },
            { title: 'Workflow Logs', link: `${workflow}/${logs}/${idFinal}` },
            {
                title: 'Execution Logs',
                link: `${workflow}/${logs}/${idFinal}/${idNested}`,
            },
        ],
        [reports + `/${workflowReports}`]: [
            { title: 'Home', link: home },
            { title: 'Reports', link: `${reports}/${workflowReports}` },
            { title: 'Worfklow Reports', link: `${reports}/${workflowReports}` },
        ],
        [reports + `/${campaignReports}`]: [
            { title: 'Home', link: home },
            { title: 'Reports', link: `${reports}/${campaignReports}` },
            { title: 'Campaign Reports', link: `${reports}/${campaignReports}` },
        ],
        [eventLogs]: [
            { title: 'Home', link: home },
            { title: 'Event Logs', link: eventLogs },
        ],
        [`/${users}`] : [
            { title: 'Home', link: home },
            { title: 'Users', link: `/${users}` },
        ],
        [`/${account}`] : [
            { title: 'Home', link: home },
            { title: 'Account', link: `/${account}` },
        ],
        [`/${users}/${id}`] : [
            { title: 'Home', link: home },
            { title: 'Users', link: `/${users}` },
            { title: 'User', link: `/${users}/${id}` },
        ]
    };
    return (
        <ErrorBoundary>
            <div>
                <ul className="pl-0 mb-0 flex items-center">
                    {BreadCrumbs[pathname]?.map((item, index) => (
                        <li
                            onClick={() => {
                                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                // @ts-ignore
                                if (window.StripoApi) {
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    window.StripoApi.stop();
                                }
                            }}
                            key={item.title}
                            className="flex items-center"
                        >
                            <Link
                                className="flex items-center"
                                to={item.link ? item.link : ''}
                            >
                                <button
                                    type="button"
                                    disabled={
                                        index ===
                                        BreadCrumbs[pathname]?.length - 1
                                    }
                                    className={`${
                                        index ===
                                            BreadCrumbs[pathname]?.length - 1 &&
                                        BreadCrumbs[pathname].length > 1
                                            ? 'text-[#878a99]'
                                            : 'text-[#495057]'
                                    } sm:text-sm text-11 leading-3 dark:text-white mr-1`}
                                >
                                    {item.title}
                                    {index !==
                                        BreadCrumbs[pathname]?.length - 1 &&
                                        item.title !== null && (
                                            <img
                                                className="inline-block sm:px-2 rotate-[270deg]"
                                                src={DropdownIcon}
                                                alt="DropdownIcon"
                                            />
                                        )}
                                </button>
                            </Link>
                        </li>
                    ))}
                </ul>
            </div>
        </ErrorBoundary>
    );
};
export default Breadcrumbs;

{
    /* <span className=" after:content-['>'] after:text-[#878a99] after:text-[15px] px-2  "></span> */
}
