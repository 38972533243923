import ErrorBoundary from 'components/ErrorBoundary';
import Dashboards from 'components/Dashboard/Body';
import React from 'react';

const Dashboard = () => {
  return (
    <ErrorBoundary>
      <Dashboards />
    </ErrorBoundary>
  );
};
export default Dashboard;
