import React from 'react';
const CreateWorkflowHeader = () => {
  return (
    <div className="">
      <div className="flex justify-between items-center px-4 border-b border-[#e9ebec] dark:border-[#fff] py-[18px]">
        <h2 className="text-base text-[#495057] font-medium  dark:text-[#CED4DA]">
          Create Workflow
        </h2>
      </div>
      <div>
      <p className="text-13 leading-4 text-[#878a99] dark:text-white p-4 ">
        Use workflows to trigger actions based on your subscriber activities and
        behavior. Build a flow to generate segmented lists, personalized email
        campaigns and pass subscriber data to your marketing toolkit.
      </p>
      </div>
    </div>
  );
};
export default CreateWorkflowHeader;
