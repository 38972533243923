import axios from 'axios';
const { REACT_APP_BASE_URL } = process.env;

axios.defaults.baseURL = REACT_APP_BASE_URL;
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.timeout = 100000;
axios.defaults.withCredentials = true;

axios.interceptors.response.use(
  (value) => {
    const { result,error } = value.data;
    if (result === null && error) {
      if (error === 'NO_AUTH') {
        return Promise.resolve(value);
      }
      return Promise.reject(value);
    }
    return Promise.resolve(value);
  },
  (error) => {
    let errorMessage = null;
    if (!error.response) {
      errorMessage = { error: 'Not Available', isError: true };
      console.log("first interceptor error :" , errorMessage)
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    // console.log("interceptor response : " , response)
    return response;
  },
  (error) => {
    // console.log("interceptor error : " , error)
    if(error?.response?.status === 401){
      // showLoginModal();
    }
    return Promise.reject(error);
  }
);

const http = {
  get: axios.get,
  post: axios.post,
  put: axios.put,
  delete: axios.delete,
};

export interface HttpResponse<T = unknown> {
  data: T;
}

export default http;
