import ErrorBoundary from 'components/ErrorBoundary';
import Header from 'components/List-Segments/Header';
import { Outlet } from 'react-router-dom';
import React from "react";

const ListSegments = () => {

  return (
    <ErrorBoundary>
      <>
        <Header />
        <div className="md:px-6 px-3 py-5 font-inter  dark:bg-[#41464E]  h-full">
          <div className="md:px-1.5  mb-16">
            <Outlet />
          </div>
        </div>
      </>
    </ErrorBoundary>
  );
};
export default ListSegments;
