import { useContext, useEffect, useState } from 'react';
import { WorkflowContext } from 'store/WorkflowContext';
import Modal from 'components/Alert/Modal';
import CopyWorkflowModal from './Modals/CopyWorkflowModal';
import React from 'react';


const HeaderInformation = () => {
  const workflowCtx = useContext(WorkflowContext);
  const [headerData, setHeaderData] = useState({ name: '', isEnabled: false });
  const [showModal, setShowModal] = useState<boolean>(false);

  useEffect(() => {
    setHeaderData({
      name: workflowCtx?.workflow?.name || '',
      isEnabled: workflowCtx?.workflow?.isEnabled || false,
    });
  }, [workflowCtx?.workflow]);

  const onCopyWorkflowHandler = async () => {
    const duplicateWorkflow = {
      ...workflowCtx?.workflow,
      name: 'Copy of ' + workflowCtx?.workflow?.name,
    };
    delete duplicateWorkflow.id;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    workflowCtx?.workflowChangeHandler(duplicateWorkflow);
    workflowCtx?.onCopyWorkflowChange(true)
  };

  return (
    <div className=''>
      <div className="flex justify-between dark:border-[#fff] flex-col sm:flex-row  items-center mt-0.5 relative px-4 border-b border-[#e9ebec] py-3">
        <div className="lg:flex items-center">
          <Modal isOpen={showModal}>
            <CopyWorkflowModal
              onClose={() => setShowModal(false)}
              onSave={() => { 
                onCopyWorkflowHandler(); 
                 setShowModal(false);
              }}
            />
          </Modal>
          <h2 className="text-base text-[#495057] font-medium  dark:text-[#CED4DA]">
            {headerData.name}
          </h2>
          <div
            className={`lg:flex hidden items-center px-1.5 py-1 rounded-[10px] text-xs font-medium leading-3  ml-3 ${headerData.isEnabled
                ? 'text-[#00b894] bg-[#ecf8f6]'
                : 'bg-[#F7E8E7] text-[#B80B00]'
              }`}
          >
            <span
              className={`w-1.5 h-1.5 mr-1.5 rounded-full ${headerData.isEnabled ? 'bg-[#00b894]' : 'bg-[#B80B00]'
                } `}
            ></span>
            {headerData.isEnabled ? 'active' : 'Inactive'}
          </div>
        </div>
        <div className="sm:w-40 ml-2">
          <button
            onClick={() => setShowModal(true)}
            className="sm:mt-0 mt-4 dark:text-white dark:border-white text-13 font-medium leading-4 text-primary hover:text-white flex justify-center items-center border rounded-md px-3 py-3 w-full border-primary hover:bg-primary ease-in-in duration-300 hover:bg-primary hover:scale-105 dark:hover:border-primary h-11"
          >
            Copy Workflow
          </button>
        </div>
      </div>
      <p className="text-13 px-4 py-3 leading-4 text-[#878a99] dark:text-white dark:text-white">
        Use workflows to trigger actions based on your subscriber activities and
        behavior. Build a flow to generate segmented lists, personalized email
        campaigns and pass subscriber data to your marketing toolkit.
      </p>
    </div>
  );
};
export default HeaderInformation;
