import { FC, useContext, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SaveModal from './SaveModal';

import StatusBar from 'components/StatusBar';
import React from 'react';
import { EmailContext } from 'store/EmailContext';

interface Props {
  name: string;
  lastUpdated: string;
  onSaveData: (name: string) => void;
  labels: string;
  loading: boolean;
  view?: string;
  draftUpdate?: string;
  onYes?: () => void;
  onNo?: () => void;
  showEmailModal?: () => void;
}

const Header: FC<Props> = ({
  name,
  lastUpdated,
  onSaveData,
  labels,
  loading,
  view,
  draftUpdate,
  onYes,
  onNo,
}) => {
  const [savedEmail, setSavedEmail] = useState<string>('');
  const { pathname } = useLocation();
  const modalRef = useRef<HTMLDivElement>(null);
  const [draftUpdatedDate, setDraftUpdatedDate] = useState<string>('');
  const emailCtx = useContext(EmailContext);
  const [showSaveModal, setShowSaveModal] = useState<boolean>(false);

  const submitHtmlHandler = () => {
    if (!loading) {
      setShowSaveModal((prev) => !prev);
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.StripoApi.compileEmail((error: string, html: string) =>
        setSavedEmail(html)
      );
    }
  };

  useEffect(() => {
    setDraftUpdatedDate(draftUpdate || '');
  }, [draftUpdate]);
  return (
    <div className="flex items-center relative">
      <div className="w-full relative">
        {view === 'emailSelected' && (
          <>
            <StatusBar completedSteps={['1']} />
            <div className="my-5">
              <h1 className="sm:text-xl text-lg font-medium leading-7">
                <span className="text-757575 dark:text-white">Step 1b:</span>
                <span className="text-primary">HTML version</span>
              </h1>
            </div>
          </>
        )}
        {draftUpdatedDate.length > 0 && view !== 'emailSelected' && (
          <div>
            <p className="px-4 py-2 text-sm font-medium leading-5 text-[#495057] dark:text-[#CED4DA]  mb-3">
              {`We restored your unsaved draft from ${draftUpdatedDate}. Is that okay?`}
            </p>
            <div className="flex sm:flex-row flex-col items-center px-4">
              <button
                onClick={onYes}
                className="text-[13px] h-10 font-medium leading-5 rounded-md py-1.5 px-4 text-white bg-primary hover:bg-primaryHover hover:scale-105 ease-in duration-300 mr-2"
              >
                Yes! Thank You!
              </button>
              <span className="text-sm font-medium leading-5 text-[#495057] dark:text-[#CED4DA]  mr-2">
                or
              </span>
              <div
                onClick={onNo}
                className="text-sm font-medium leading-4 text-primary hover:text-blue-700 ease-in duration-300 text-center cursor-pointer"
              >
                {`No thanks, use the last saved version from ${lastUpdated}`}
              </div>
            </div>
          </div>
        )}
        <div className="mt-2">
          <div className="flex px-6 justify-between items-center ">
            <div>
              {view === 'edit' ||
                (pathname === '/preview-email' && (
                  <div>
                    <h3 className="sm:text-base text-sm font-medium leading-5 dark:text-white">
                      {name}
                    </h3>
                    <p className="text-xs font-medium leading-4 text-[#495057] dark:text-[#CED4DA]  dark:text-white">
                      Last Updated at {lastUpdated}
                    </p>
                  </div>
                ))}
            </div>
            <div className="flex items-center">
              <div className="">
                <div className="flex justify-between items-center my-2">
                  <div>
                    {view === 'edit' ||
                      (pathname === '/preview-email' && (
                        <div>
                          <h3 className="sm:text-base text-sm font-medium leading-5 dark:text-white">
                            {name}
                          </h3>
                          <p className="text-xs font-medium leading-4 text-[#495057] dark:text-[#CED4DA]  dark:text-white">
                            Last Updated at {lastUpdated}
                          </p>
                        </div>
                      ))}
                  </div>
                  {/* <div className="flex items-center">
                                        {view === 'edit' && (
                                            <button
                                                type="button"
                                                className={`${
                                                    loading
                                                        ? 'cursor-not-allowed'
                                                        : 'cursor-pointer'
                                                } flex items-center text-sm text-sm font-medium text-primary hover:text-blue-700 ease-in duration-300 font-medium leading-3 text-red-500 dark:text-white`}
                                                onClick={() =>
                                                    setShowDeleteAlert(true)
                                                }
                                                disabled={loading}
                                            >
                                                <span className="w-4 h-4 mr-1.5">
                                                    <img
                                                        className="w-full h-full dark:invert dark:brightness-0"
                                                        src={Delete}
                                                        alt="Delete"
                                                    />
                                                </span>
                                                Delete
                                            </button>
                                        )}
                                        <Link
                                            // type="button"
                                            className={`${
                                                loading
                                                    ? 'cursor-not-allowed'
                                                    : 'cursor-pointer'
                                            } flex items-center text-sm font-medium leading-3 text-[#3575d5] ml-5 dark:text-white`}
                                            // disabled={loading}
                                            onClick={sendButtonClickHandler}
                                            to={`${campaigns}/${create}/${id}`}
                                        >
                                            <span className="w-4 h-4 mr-1.5">
                                                <img
                                                    className="w-full h-full dark:invert dark:brightness-0"
                                                    src={Send}
                                                />
                                            </span>
                                            Send
                                        </Link>
                                        <div ref={modalRef}>
                                            <button
                                                className={`${
                                                    loading
                                                        ? 'cursor-not-allowed'
                                                        : 'cursor-pointer'
                                                } flex items-center text-sm font-medium leading-3 text-[#3575d5] ml-5 dark:text-white cursor-pointer`}
                                                disabled={loading}
                                                onClick={() =>
                                                    !loading &&
                                                    setShowModal(
                                                        (prev) => !prev
                                                    )
                                                }
                                            >
                                                <span className="w-4 h-4 mr-1.5">
                                                    <img
                                                        className="w-full h-full dark:invert dark:brightness-0"
                                                        src={Mail}
                                                    />
                                                </span>
                                                Send Test
                                            </button>
                                            <div>
                                                {showModal && (
                                                    <div>
                                                        <SendTestModal
                                                            onClose={() =>
                                                                setShowModal(
                                                                    false
                                                                )
                                                            }
                                                        />
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        {/* {view === 'edit' && (
                  <button
                    type="button"
                    className={`${
                      loading ? 'cursor-not-allowed' : 'cursor-pointer'
                    } flex items-center text-13 font-medium leading-3 text-gray-700 ml-5 dark:text-white`}
                    disabled={loading}
                  >
                    <span className="w-4 h-4 mr-1.5">
                      <img
                        className="w-full h-full dark:invert dark:brightness-0"
                        src={Edit}
                      />
                    </span>
                    Edit
                  </button>
                )} */}

                  {/* </div> */}
                  <div className="flex items-center">
                    <div className="flex items-center">
                      <button
                        id="codeEditor"
                        className={`${
                          loading
                            ? ' border border-[#ced4da] bg-gray-200 bg:bg-gray-300 text-[#495057] dark:text-[#CED4DA] '
                            : 'text-primary underline'
                        } text-sm px-2`}
                        disabled={loading}
                      >
                        Code editor
                      </button>
                      <div ref={modalRef} className="relative">
                        <button
                          id="template"
                          onClick={submitHtmlHandler}
                          className={` ${
                            loading
                              ? 'border border-[#ced4da] bg-gray-200 bg:bg-gray-300 text-[#495057] dark:text-[#CED4DA] '
                              : 'text-primary'
                          } text-sm underline pl-2`}
                        >
                          Save Html
                        </button>
                        {showSaveModal && (
                          <div>
                            <SaveModal
                              onClose={() => setShowSaveModal(false)}
                              email={savedEmail}
                              onSaveData={(name: string) => {
                                // setName(name);
                                // setView('edit');
                                onSaveData(name);
                              }}
                              name={name}
                              labels={labels}
                              view={view}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-wrap justify-between">
                  <div>
                    {emailCtx?.templateInfo?.name && view === 'edit' && (
                      <p className="text-[13px] font-medium leading-5 my-3 text-757575 dark:text-white">
                        You are currently editing the
                        <b className="text-616161 font-semibold mr-1 ml-1 dark:text-white">
                          {emailCtx.templateInfo?.name}
                        </b>
                        template.
                        {emailCtx.templateInfo.type && (
                          <b>under {emailCtx.templateInfo.type} category</b>
                        )}
                      </p>
                    )}
                  </div>

                  {name.length > 0 && (
                    <p className="text-sm font-medium leading-5 my-3 text-757575 dark:text-white">
                      Saved as
                      <b className="text-616161 font-semibold"> {name} </b>
                    </p>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Header;
