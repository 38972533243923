import React, { FC, Dispatch, SetStateAction, useState, useRef, useContext } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { calendar, campaigns, emailEditor } from 'services/constant/routes';
import { sidebar } from './index.types';
import { useOutsideClick } from 'app/hooks/useOutsideClick';
import 'assets/styles/common.css';
import { ThemeContext } from 'store/ThemeContext';


interface Props {
  sidebarItem: sidebar;
  setDropdownMenu: Dispatch<SetStateAction<string>>;
  dropdownMenu: string;
  showFullSidebar?: boolean;
}
const SidebarDropdown: FC<Props> = ({
  sidebarItem,
  setDropdownMenu,
  dropdownMenu,
  showFullSidebar,
}) => {
  const themeCtx = useContext(ThemeContext);
  const theme = themeCtx?.theme;
  const [sideBarHover, setSideBarHover] = useState(false);
  const { pathname, search } = useLocation();
  const tabs = location.pathname.split('/');
  const dropdownRef = useRef<HTMLDivElement>(null);


  useOutsideClick(dropdownRef, () => {
    setSideBarHover(false);
  });


  const checkActiveTab = (link: string) => {

    //TO-FIX code optimization
    if (sidebarItem.link === campaigns) {
      if (pathname === link) {
        return true;
      } else if (tabs[2] === calendar && link.includes(tabs[2])) {
        return true;
      } else if (
        tabs[2] !== calendar &&
        link === campaigns &&
        pathname.includes(link)
      ) {
        return true;
      }
    } else if (sidebarItem.link === emailEditor) {
      if (pathname + search === link) {
        return true;
      } else if (
        tabs[2] &&
        pathname.includes(emailEditor) &&
        !link.includes('insight')
      ) {
        return true;
      }
    } else if ((pathname + search).includes(link)) {
      return true;
    } else {
      return false;
    }
  };
  const toggleDropdown = (index: string) => {

    setDropdownMenu((prevData: string) => (prevData === index ? '' : index));
    if (!showFullSidebar) {
      setSideBarHover(true)
    }
  };
  return (
    <div className='relative' >
      <button
        className="2xl:mt-3 mt-1 w-full flex justify-between items-center relative z-[9999]"
      >
        <div
          onClick={() => toggleDropdown(sidebarItem.id)}
          onMouseEnter={() => { if (!showFullSidebar) { toggleDropdown(sidebarItem.id) }}}
          className={`w-full flex justify-between items-center rounded 2xl:py-2.5 py-1.5 pl-6 pr-2.5 group hover:bg-primaryHover dark:hover:bg-white group cursor-pointer`}
        >
          <div className="flex items-center " >
            <span>
              <img
                className={`brightness-0 invert w-5 h-5 group-hover:invert group-hover:brightness-0 dark:brightness-0 dark:invert dark:group-hover:invert-1 dark:group-hover:brightness-100`}
                src={sidebarItem.image}
                alt="Box"
              />
            </span>
            <span
              className={`text-foreground dark:text-white ${showFullSidebar ? 'lg:block sm:hidden' : 'hidden'
                } text-15 font-medium leading-3 dark:text-[#A4AAC7] dark:group-hover:text-black-400 pl-3 sidebar-links group-hover:text-white`}
            >
              {sidebarItem.text}
            </span>
          </div>

          <p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className={`${showFullSidebar ? 'lg:block sm:hidden' : 'hidden'
                } w-4 h-4 text-foreground dark:group-hover:brightness-0 indent-1`}
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M19.5 8.25l-7.5 7.5-7.5-7.5"
              />
            </svg>
          </p>

        </div>
      </button>

      {showFullSidebar ? (
        <div
          className={`
            ${dropdownMenu === sidebarItem.id ? 'sideBarOpen' : 'sideBarClosed'}
          `}
        >
          {sidebarItem.dropdown?.map((dropdown) => {
            return (
              <ul key={dropdown.title} >
                <li>
                  <Link to={dropdown.link} >
                    <div
                      className={` ${checkActiveTab(dropdown.link) ? 'bg-primaryHover ' : ''
                        } flex justify-between items-center rounded py-2.5 pl-6 pr-2.5 group  dark:hover:bg-white group cursor-pointer`}
                    >
                      <div className="flex items-center group" >
                        <span className="w-5 text-center text-white text-13 dark:group-hover:text-black-400">
                          -
                        </span>
                        <span
                          className={`text-foreground  text-13 font-medium hover:opacity-100 opacity-80 dark:group-hover:text-black-400 dark:text-white pl-3 sidebar-links`}
                        >
                          {dropdown.title}
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
              </ul>
            );
          })}
        </div>) : (
        <div ref={dropdownRef}
          className={`
            ${dropdownMenu === sidebarItem.id ? 'sideBarOpen' : 'sideBarClosed'}
            ${!showFullSidebar && "absolute    rounded w-full left-[70px] min-w-[230px] top-[2px]"}
            
            ${theme === 'dark' ? 'bg-[#41464E]' : 'sideBarBackground'}

              `}
          style={{ zIndex: '9999999' }}
          onMouseLeave={() => { setSideBarHover(false) }}
        >
          {sideBarHover && sidebarItem.dropdown?.map((dropdown) => {
            return (
              <ul key={dropdown.title} >
                <li>
                  <Link to={dropdown.link} onClick={() => { setSideBarHover(false) }}>
                    <div
                      className={` ${checkActiveTab(dropdown.link) ? 'bg-primaryHover ' : ''
                        } flex justify-between items-center rounded py-2.5 pl-6 pr-2.5 group  dark:hover:bg-white group cursor-pointer`}
                    >
                      <div className="flex items-center group" >
                        <span className="w-5 text-center text-white text-13 dark:group-hover:text-black-400">
                          -
                        </span>
                        <span
                          className={`text-foreground dark:text-white text-13 font-medium hover:opacity-100 opacity-80 dark:group-hover:text-black-400 dark:text-white pl-3 sidebar-links`}
                        >
                          {dropdown.title}
                        </span>
                      </div>
                    </div>
                  </Link>
                </li>
              </ul>
            );
          })}
        </div>)}


    </div>
  );
};
export default SidebarDropdown;
