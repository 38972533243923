import {
  RefObject,
  forwardRef,
  ForwardRefRenderFunction,
} from 'react';
import React from 'react';

interface Props {
  status: boolean;
  deliveryRef: RefObject<HTMLDivElement>;
  onChangeStatus: (value: boolean) => void;
}

const DeliveryOptions: ForwardRefRenderFunction<HTMLDivElement, Props> = (
  { status, deliveryRef, onChangeStatus },
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ref
) => {
  return (
    <div ref={deliveryRef}>
      <div className="flex items-center mt-4">
        <input
          className="cursor-pointer w-[14px] h-[14px] text-primary bg-gray-100 border-gray-300  dark:focus:ring-blue-600 dark:ring-offset-gray-800  dark:bg-gray-700 dark:border-gray-600 mr-2"
          type="checkbox"
          id="unsubscriber"
          name="Unsubscriber"
          checked={status}
          onChange={(e) => onChangeStatus(e.target.checked)}
        />
        <label className="text-sm font-normal leading-4 text-[#495057] dark:text-[#CED4DA] ">
          Ignore Unsubscribe Status
        </label>
      </div>
    </div>
  );
};
export default forwardRef(DeliveryOptions);
