import SubscriberList from './SubscriberList';
import FilterList from './FilterList';
import ErrorBoundary from 'components/ErrorBoundary';
import { Outlet } from 'react-router-dom';
import React from 'react';
import BreadcrumbsWrapper from 'components/Wrapper/BreadcrumbsWrapper';

const AddList = () => {
  
  return (
    <ErrorBoundary>
      <BreadcrumbsWrapper/>
      <div>
        <div className='flex justify-between items-center px-5 border-b dark:border-[#fff] border-[#e9ebec] py-[18px]'>
        <h1 className="text-base text-[#495057] font-medium  dark:text-[#CED4DA]">
          Add List Members
        </h1>
        </div>
        <div className="md:flex justify-between mt-4 min-h-[67vh]">
          <SubscriberList />
          <FilterList  />
        </div>
        <Outlet />
      </div>
    </ErrorBoundary>
  );
};
export default AddList;
