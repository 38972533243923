import ErrorBoundary from 'components/ErrorBoundary';
import BreadcrumbsWrapper from 'components/Wrapper/BreadcrumbsWrapper';
import { Outlet } from 'react-router-dom';
import React from "react";

const Subscribers = () => {

  return (
    <ErrorBoundary>
      <>
        <BreadcrumbsWrapper />
        <Outlet />
      </>
    </ErrorBoundary>
  );
};
export default Subscribers;
