import Breadcrumbs from 'components/Breadcrumbs/Breadcrumbs';
// import Header from 'components/EmailTemplates/PopularModal';
// import Tabs from 'components/Tabs/Tabs';
import {
    // createSearchParams,
    useNavigate,
    useSearchParams,
} from 'react-router-dom';
import { useEffect, useState } from 'react';
import Body from 'components/EmailTemplates/Body';
import ErrorBoundary from 'components/ErrorBoundary';
import Plus from 'assets/Images/svg-images/plus.svg';
import { z } from 'zod';
import { create, emailEditor } from 'services/constant/routes';
import React from 'react';
import { createNewEmail } from 'services/apiHandlers/Campaigns/Emails';
import { Toastify } from 'App';

// export enum TabsEnum {
//     EMAIL = 'email',
//     INSIGHT = 'insight_templates',
// }

// export const TabsData = [
//     { title: 'Emails', searchQuery: TabsEnum.EMAIL },
//     { title: 'Insight Templates', searchQuery: TabsEnum.INSIGHT },
// ];

const emailDataSchema = z.object({
    account_id: z.number(),
    agreed_to_canspam_address_for_html: z.number().nullable(),
    agreed_to_canspam_address_for_text: z.number().nullable(),
    content_type: z.string(),
    created_at: z.string(),
    creator_id: z.number(),
    creator_type: z.string(),
    css: z.string(),
    description: z.string().nullable(),
    html_part: z.string(),
    id: z.number(),
    last_saved: z.string(),
    name: z.string(),
    process: z.string(),
    recovered_draft: z.string(),
    save_type: z.string(),
    size_in_bytes: z.number(),
    source_email_id: z.number(),
    subject: z.string().nullable(),
    tag_list: z.union([z.array(z.string()), z.tuple([])]),
    template_id: z.number(),
    template_name: z.string(),
    template_type: z.string(),
    template_category_name: z.string(),
    text_part: z.string(),
    updated_at: z.string(),
    widgetization_error: z.string().nullable(),
});

const getEmailDataSchema = z.object({
    data: z.object({
        draft: z.object({ email: emailDataSchema }),
        recovered_draft: z.string().optional(),
        redirect_to: z.string(),
    }),
    status: z.number(),
});
export type EmailDataResponse = z.infer<typeof getEmailDataSchema>;

const EmailTemplates = () => {
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    // const [filterValue, setFilterValue] = useState<string>('');
    // const [label, setLabel] = useState<string | undefined>('');
    // const onTabChangeHandler = (tabQuery: string) => {
    //   setSearchParams(createSearchParams({ tab: tabQuery }));
    // };

    const onCreateEmail = async () => {
        setLoading(true);
        try {
            const getNewMailDataResponse = (await createNewEmail(
                false
            )) as EmailDataResponse;

            if (getNewMailDataResponse.status === 200) {
                if (getNewMailDataResponse.data.draft?.email) {
                    navigate(emailEditor);
                } else {
                    navigate(`${emailEditor}/${create}`);
                }
            }
        } catch (error) {
            Toastify('Something Went Wrong', 'error', 'email1');
        }
        setLoading(false);
    };

    useEffect(() => {
        // if (!searchParams.get('tab')) {
        //     setSearchParams(createSearchParams({ tab: TabsEnum.EMAIL }));
        // }
        if (searchParams.get('id') || searchParams.get('create')) {
            searchParams.delete('id');
            searchParams.delete('create');
            setSearchParams(searchParams);
        }
    }, [searchParams]);

    // const activeTab = searchParams.get('tab') || TabsEnum.EMAIL;
    return (
        <ErrorBoundary>
            <div>
                <div className="bg-white dark:bg-transparent flex-wrap md:px-6 px-3 py-2 font-inter border-b shadow-0 shadow flex justify-between items-center dark:border-[#fff]">
                    <div className="md:px-1.5">
                        <div>
                            <Breadcrumbs />
                        </div>
                    </div>
                    <div>
                        <button
                            disabled={loading}
                            onClick={() => onCreateEmail()}
                            className="sm:mt-0 mt-5 relative  right-0 lg:w-full"
                        >
                            <div
                                className={` ${
                                    loading
                                        ? 'border border-[#ced4da] bg-gray-200 text-white hover:bg-gray-200'
                                        : ' text-white font-inter bg-primary hover:bg-primary'
                                } sm:text-sm text-xs font-medium flex items-center ml-auto rounded-md py-3 sm:pl-4 px-2 sm:pr-3.5`}
                            >
                                <img
                                    className="sm:mr-3 mr-1"
                                    src={Plus}
                                    alt="Plus"
                                />
                                Create New
                            </div>
                        </button>
                    </div>
                </div>

                {/* <Header setFilterValue={setFilterValue} onLabelClick={setLabel} /> */}

                <Body />
            </div>
        </ErrorBoundary>
    );
};
export default EmailTemplates;
