import React,{ useEffect, useState } from 'react';
import {
  getFrequencyLimit,
  updateWorkflowFrequency,
} from 'services/apiHandlers/Integration/Miscellaneous';
import { useFormik } from 'formik';
import { z } from 'zod';
import * as Yup from 'yup';
import { Toastify } from 'App';
import ErrorBoundary from 'components/ErrorBoundary';
import { excludedValues } from 'components/NewCampaign/ScheduleTime';
import Wrapper from 'components/Wrapper';

const WorkflowActions = () => {
  const [frequencyLimit, setFrequencyLimit] = useState<number>();

  const responseSchema = z.object({
    data: z.object({ frequencyLimit: z.number() }),
    status: z.number(),
  });
  type response = z.infer<typeof responseSchema>;

  const updateFrequencyLimitResponseSchema = z.object({ status: z.number() });
  type updateFrequencyLimitResponse = z.infer<
    typeof updateFrequencyLimitResponseSchema
  >;

  const getData = async () => {
    const getWorkflowActionsresponse = (await getFrequencyLimit()) as response;
    if (getWorkflowActionsresponse.status === 200) {
      setFrequencyLimit(getWorkflowActionsresponse.data.frequencyLimit);
    }
  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      frequencyLimit: frequencyLimit,
    },
    validationSchema: Yup.object({
      frequencyLimit: Yup.number().min(0, `Can't be Negative`).required('Required'),
    }),
    onSubmit: async (values) => {
      try {
        const updateFrequencyLimitResponse = (await updateWorkflowFrequency(
          values
        )) as updateFrequencyLimitResponse;
        if (updateFrequencyLimitResponse?.status === 200) {
          Toastify(
            'Workflow Actions Updated Successfully',
            'success',
            'workflow1'
          );
        }
      } catch (error) {
        Toastify('Updating Workflow Actions Failed', 'error', 'workflow2');
      }
    },
  });
  useEffect(() => {
    getData();
  }, []);

  const { handleSubmit, handleChange, errors, values } = formik
  const { frequencyLimit: frequencyLimitValue } = values
  const { frequencyLimit: frequencyLimitError } = errors;
  return (
    <ErrorBoundary>
      <div className=' relative '>
        <Wrapper>
          <form
            onSubmit={handleSubmit}
            className='p-4'
          >
            <h3 className="text-13 font-medium leading-4  text-[#495057] dark:text-white mb-2">
              Workflow Action Frequency Limits
            </h3>
            <p className="text-13 leading-4 text-[#878a99] dark:text-white">
              Number of hours between executing the same workflow action for the
              same person. Delays are never skipped. Set to 0 (Zero) if youd like to
              disable this.
            </p>
            <p className="text-13 leading-4 text-[#878a99] dark:text-white mt-2.5">
              Skip a workflow action if that person has done that action within:
            </p>
            <div className="flex items-center mt-3">
              <input
                className="text-13 focus:border-primary dark:focus:border-white focus:outline-none leading-3 text-[#212529] dark:text-white py-2 rounded-md px-4 bg-white border border-[#ced4da] dark:border-[#fff] md:w-7/12 sm:h-[38px] h-9  dark:bg-[#41464E]  "
                type="number"
                name="frequencyLimit"
                data-pw="frequencyLimit"
                value={frequencyLimitValue}
                min='0'
                onKeyDown={(e) => {
                  if (excludedValues.includes(e.key)) {
                    e.preventDefault()
                  }
                }}
                onChange={handleChange}
              />
              <span className="text-13 font-normal leading-4 text-[#878A99] dark:text-white ml-2.5">
                hour(s)
              </span>
            </div>
            {frequencyLimitError ? (
              <div className="text-xs leading-4 ml-2 text-red-400 mt-1.5">
                {frequencyLimitError}
              </div>
            ) : null}
            <div className="flex items-center mt-4 justify-end">
              <button
                type="submit"
                className="text-13 font-medium leading-5 rounded-md py-2 px-4 text-white bg-primary ease-in duration-300 hover:scale-105"
                data-pw="update-workflow-actions"
              >
                Update
              </button>

            </div>
          </form>
        </Wrapper>
      </div>
    </ErrorBoundary>
  );
};
export default WorkflowActions;
