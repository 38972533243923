import CustomSkeleton from 'components/Skeleton/Skeleton';
import React, { FC, useEffect, useState } from 'react';
import { getSubscriberTags } from 'services/apiHandlers/List-Segments/Subscriber';
import z from 'zod';

const getSubscriberTagsResponseSchema = z.object({
  data: z.array(z.string()),
  status: z.number(),
});

type GetSubscriberTagsResponse = z.infer<typeof getSubscriberTagsResponseSchema>;


interface Props {
  email: string
}

const SubscriberTags: FC<Props> = ({ email }) => {
  const [loading, setLoading] = useState<boolean>(true);
  const [tags, setTags] = useState<Array<string>>([]);

  const getTags = async () => {
    setLoading(true);
    if (email.length > 1) {
      try {
        const getTagsResponse = await getSubscriberTags(email) as GetSubscriberTagsResponse;
        if (getTagsResponse.status === 200) {
          setTags(getTagsResponse.data);
        }
      } catch (error) {
        console.log('error is : ', error);
      }
    }
    setLoading(false);
  };

  useEffect(() => {
    getTags();
  }, [email]);

  return (
    <div className="md:px-6 px-3 py-4 mt-4">
      <div className="">
        <label className="dark:text-[#CED4DA] text-xs font-semibold leading-3 text-[#212529] tracking-wide uppercase mb-3 block">
          Tags
        </label>
        {loading || email.length < 1 ? (<CustomSkeleton />) :
          <div>
            {tags.length > 0 ? (tags.map((tag) => {
              return <ul key={tag}>
                {tag}
              </ul>
            })) : (<div className='dark:text-white'>No Tags available</div>
            )}
          </div>
        }

      </div>
    </div>
  );
};
export default SubscriberTags;
