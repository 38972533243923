import ListSegmentHeader from './ListSegmentHeader';
import { TableColumn } from 'react-data-table-component';
import Table from 'components/Table';
import {
  getLists,
  getFilterList,
} from 'services/apiHandlers/List-Segments/SegmentsList';
import React, { useEffect, useMemo, useState } from 'react';
import ListActions from './ListActions';
import { z } from 'zod';
import { tableSort } from 'utils/common';
import ErrorBoundary from 'components/ErrorBoundary';
import { Link } from 'react-router-dom';
import { listId, subscribersProfile } from 'services/constant/routes';
import Wrapper from 'components/Wrapper';
import SearchImage from 'assets/Images/Common/search.png';

const collectionSchema = z.object({
  active_people_count: z.number(),
  audience_id: z.number(),
  channel_list: z.boolean(),
  checked_by_default: z.boolean().nullable(),
  created_at: z.string(),
  deleted_at: z.string().nullable(),
  description: z.string(),
  id: z.number(),
  inactive_people_count: z.number(),
  mutable: z.boolean(),
  name: z.string(),
  pending_people_count: z.number(),
  pretty_status: z.string(),
  public: z.string(),
  status: z.string(),
  trigger_name: z.string(),
  unsubscribed_people_count: z.number(),
  updated_at: z.string(),
});

const ListResponseSchema = z.object({
  data: z.object({
    current_page: z.number(),
    per_page: z.number(),
    total_count: z.number(),
    collection: z.object({ lists: z.array(collectionSchema) }),
  }),
  status: z.number(),
});
const AllListResponseSchema = z.object({
  data: z.object({
    lists: z.array(collectionSchema),
  }),
  status: z.number(),
});
export type ListResponse = z.infer<typeof ListResponseSchema>;
export type AllListResponse = z.infer<typeof AllListResponseSchema>
export type List = z.infer<typeof collectionSchema>;

const ListSegment = () => {
  const [loading, setLoading] = useState<boolean>(true);
  const [list, setList] = useState<Array<List>>([]);
  const [filteredList, setFilteredList] = useState<Array<List>>(list);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [filteredEntries, setFilteredEntries] = useState<number>(0);
  const [filterValue, setFilterValue] = useState<string>('');

  const getData = async (preventLoading?: boolean) => {
    if (!preventLoading) {
      setLoading(true);
    }
    try {
      const getListsResponse = (await getLists(currentPage)) as ListResponse;
      if (getListsResponse.status === 200) {
        setList(getListsResponse?.data?.collection?.lists);
      }
    } catch (error) {
      setLoading(false);
    }
    setLoading(false);
  };

  const getFilterData = async (preventLoading?: boolean) => {
    if (!preventLoading) {
      setLoading(true);
    }
    try {
      const getFilterListsResponse = (await getFilterList(
        filterValue,
        currentPage
      )) as ListResponse;
      if (getFilterListsResponse.status === 200) {
        setFilteredList(getFilterListsResponse?.data?.collection?.lists);
        setFilteredEntries(getFilterListsResponse?.data?.total_count);
      }
    } catch (error) {
      setLoading(false);
    }
    setLoading(false);
  };

  const listSort = (rowA: List, rowB: List) => {
    return tableSort(rowA.name, rowB.name);
  };

  const listMemberSort = (rowA: List, rowB: List) => {
    return tableSort(
      rowA.active_people_count +
      rowA.inactive_people_count +
      rowA.pending_people_count +
      rowA.unsubscribed_people_count,
      rowB.active_people_count +
      rowB.inactive_people_count +
      rowB.pending_people_count +
      rowB.unsubscribed_people_count
    );
  };

  const columns: TableColumn<List>[] = useMemo(
    () => [
      {
        name: <div className='px-3'>List Name</div>,
        cell: (row, index) => {
          return (
            <div className="dark:text-white">
              <Link
                data-pw={`${index === 0 ? 'list-name' : ''}`}
                className="cursor-pointer px-3 font-medium text-[#495057] mt-1 flex gap-3 dark:text-white"
                to={`/${subscribersProfile}?${listId}=${row.id}`}
              >
                {row.name}
              </Link>
            </div>
          );
        },
        sortable: true,
        sortFunction: listSort,
      },
      {
        name: 'Description',
        cell: (row, index) => {
          return (
            <div
              data-pw={`${index === 0 ? 'list-description' : ''}`}
              className="my-0.5  dark:text-[#fff]"
            >
              {row.description}
            </div>
          );
        },
      },
      {
        name: 'Members',
        cell: (row) => {
          return (
            <div className="dark:text-white">
              <div>
                {row.active_people_count +
                  row.inactive_people_count +
                  row.pending_people_count +
                  row.unsubscribed_people_count +
                  ' '}
                Members
              </div>
            </div>
          );
        },
        sortFunction: listMemberSort,
      },
      {
        name: 'Actions',
        cell: (row, index) => {
          return (
            <ListActions
              rowData={row}
              index={index}
              getData={() => getData(true)}
            />
          );
        },
      },
    ],
    []
  );

  useEffect(() => {
    getData(false);
  }, []);


  useEffect(() => {
    const timeoutId = setTimeout(() => {
      getFilterData(false);
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [filterValue]);

  useEffect(() => {
    getData();
    getFilterData();
  }, [currentPage]);

  return (
    <ErrorBoundary>
      <ListSegmentHeader />
      <div className="relative mx-4 my-8 min-h-[72vh]">
        <Wrapper>
          <>
            <div className="flex justify-between items-center px-5 border-b dark:border-[#fff] border-[#ced4da]  py-[18px]">
              <p className='text-base text-[#495057] font-medium  dark:text-[#CED4DA]'>Lists</p>
              {/* <div className="flex">
            <div className="relative" >
              <div className='flex items-center cursor-pointer'>
              <p className='text-xs font-semibold text-[#212529] dark:text-[#CED4DA]'>Sort by:</p>
              <p className='text-[#878A99] ml-1 text-sm'>Current Week</p>
              <img src={DropdownIcon} alt='Dropdown Icon' className='ml-1' />
              </div>
            </div>
        </div> */}
              <div className="relative md:w-96 sm:w-80 flex items-center py-2 px-4 rounded-md border-gray-200 bg-[#F3F3F9] dark:bg-[#41464E]">
                <input
                  className="text-11 bg-[#F3F3F9] font-medium placeholder:text-gray-500 leading-3 text-gray-500 w-full pl-6 h-6 focus:outline-none dark:text-white dark:placeholder:text-white dark:bg-[#41464E]"
                  type="text"
                  name="search"
                  placeholder="Search"
                  value={filterValue}
                  onChange={(e) => setFilterValue(e.target.value)}
                />
                <img
                  className="absolute dark:invert dark:brightness-0"
                  src={SearchImage}
                  alt="Search"
                />
              </div>
            </div>
            {
              <Table
                className="scrollbar-hide"
                data={filteredList}
                columns={columns}
                progressPending={loading}
                pagination
                paginationServer
                paginationTotalRows={filteredEntries}
                onChangePage={setCurrentPage}
              // customStyles={customStyles}
              />
            }
          </>
        </Wrapper>
      </div>
    </ErrorBoundary>
  );
};
export default ListSegment;
