import { FC, useEffect } from 'react';
import { FormikValues, useFormik } from 'formik';
import * as Yup from 'yup';
import { createSender } from 'services/apiHandlers/Campaigns/CampaignInformation';
import { Toastify } from 'App';
import { z } from 'zod';
import ErrorBoundary from 'components/ErrorBoundary';
import React from 'react';
import SearchDropdown from 'components/Dropdown/SearchDropdown';
import { SenderType } from './Types';

const responseStatusSchema = z.object({ status: z.number() });
export type ResponseStatus = z.infer<typeof responseStatusSchema>;

interface Props {
  hideForm: () => void;
  getData: () => void;
  editSenderData: SenderType | undefined;
  countries: string[];
}

const senderForm = [
  { placeholder: 'Full Name', name: 'from_name', type: 'text' },
  { placeholder: 'Email Address', name: 'from_email_address', type: 'email' },
  { placeholder: 'Address1', name: 'street_address_1', type: 'text' },
  { placeholder: 'Address2', name: 'street_address_2', type: 'text' },
  { placeholder: 'City', name: 'city', type: 'text' },
  { placeholder: 'State', name: 'state', type: 'text' },
  { placeholder: 'Zip/Postal', name: 'postal_code', type: 'text' },
];

const SenderForm: FC<Props> = ({
  hideForm,
  getData,
  editSenderData,
  countries,
}) => {
  const formik: FormikValues = useFormik({
    initialValues: {
      from_name: '',
      from_email_address: '',
      street_address_1: '',
      street_address_2: '',
      city: '',
      state: '',
      postal_code: '',
      country: 'Select a Country',
    },
    validationSchema: Yup.object({
      from_name: Yup.string().required('Name cannot be empty'),
      from_email_address: Yup.string()
        .email('Invalid email')
        .required('Email Required'),
      street_address_1: Yup.string().required('Address 1 cannot be empty'),
      street_address_2: Yup.string().required('Address 2 cannot be empty'),
      city: Yup.string().required('City cannot be empty'),
      postal_code: Yup.string().required('Zip cannot be empty'),
      country: Yup.string().test(
        'is-selected',
        'Country cannot be empty',
        (value) => value !== 'Select a Country'
      ),
    }),
    onSubmit: async (value) => {
      try {
        const createSenderResponse = (await createSender(
          value
        )) as ResponseStatus;
        if (createSenderResponse.status === 201) {
          Toastify('Sender Created Successfully', 'success', 'senderform1');
          hideForm();
          getData();
        }
      } catch (error) {
        Toastify('Sender Creation Failed', 'error', 'senderform2');
      }
    },
  });
  useEffect(() => {
    editSenderData && formik.setValues(editSenderData);
  }, [editSenderData]);

  return (
    <ErrorBoundary>
      <form onSubmit={formik.handleSubmit}>
        <div className="text-13 font-semibold leading-4 text-black-700 dark:text-white mb-2">
          Add New Sender (all fields required, except State)
        </div>
        <div className="text-xs leading-4 text-[#495057] dark:text-[#CED4DA]  dark:text-white dark:text-white">
          We need a sender for your email. Save a sender for the future email
          blasts!
        </div>
        {senderForm.map((field) => (
          <div key={field.name}>
            <div>
              <input
                className="w-full text-13 focus:border-primary dark:focus:border-white opacity-1 placeholder:text-gray-500 focus:outline-none font-medium leading-3 text-gray-700 dark:text-white py-2 rounded-md px-4 bg-white  dark:bg-[#41464E]  border border-[#ced4da] dark:border-[#fff] sm:h-11 h-9 mt-4 dark:placeholder:text-white"
                type={field.type}
                placeholder={field.placeholder}
                name={field.name}
                value={formik.values[field.name]}
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
              />
              {(formik.errors[field.name] && formik.touched[field.name]) ||
              formik.touched[field.name] ? (
                <div className="text-xs leading-4 text-red-400 mt-1.5">
                  {formik.errors[field.name]}
                </div>
              ) : null}
            </div>
          </div>
        ))}

        <div className="p-4 pb-10 mt-4 bg-gray-150  dark:bg-[#41464E]  rounded-md border border-[#ced4da] dark:border-[#fff]">
          <SearchDropdown
            value={formik.values.country}
            options={countries}
            onSelect={(value) => formik.setFieldValue('country', value)}
          />
        </div>
        {formik.errors.country && formik.touched.country ? (
          <div className="text-xs leading-4 text-red-400 mt-1.5">
            {formik.errors.country}
          </div>
        ) : null}
        <div className="flex justify-end items-center my-4">
          <button
            type="button"
            className="text-center sm:text-13 text-xs py-2.5 px-4 h-11 flex justify-center items-center w-28 font-medium leading-5 mr-2  rounded-md bg-[#f3f6f9] hover:bg-[#cfd1d4] transition-bg text-[#212529]"
            onClick={hideForm}
          >
            Cancel
          </button>
          <button
            type="submit"
            className={` ${
              formik.dirty && formik.isValid
                ? 'bg-primary text-white '
                : 'bg-gray-800 text-[#495057] dark:text-[#CED4DA]  dark:text-white font-nomral '
            }text-13 font-medium leading-5 rounded py-2 px-4 dark:hover:bg-white dark:hover:text-[#495057] dark:text-[#CED4DA]  dark:text-white h-10`}
            disabled={!formik.isValid}
          >
            Save
          </button>
        </div>
      </form>
    </ErrorBoundary>
  );
};
export default SenderForm;
