import httpService from 'services/core/HttpService';
import axios from 'services/core/HttpService';
import {
  GET_LIST,
  GET_ALL_LIST,
  CREATE_LIST,
  UPDATE_LIST,
  DELETE_LIST,
  GET_ALL_SEGMENTS,
  GET_SINGLE_LIST,
  ADD_MEMBERS_USING_FILTER,
  GET_FILTER_LIST,
  GET_ALL_LISTS,
} from 'services/constant/apiRoutes';
import { z } from 'zod';

const UpdateSegmentSchema = z.object({
  name: z.string(),
  description: z.string(),
  id: z.number().optional(),
});
type UpdateSegment = z.infer<typeof UpdateSegmentSchema>;

const createListSchema = z.object({
  list: z.object({ name: z.string(), description: z.string().optional() }),
});
type createListType = z.infer<typeof createListSchema>;

const SegmentSchema = z.object({
  segment_json: z.string(),
  elastic_json: z.string(),
  segment_name: z.string(),
  subscriber_count: z.number(),
  status: z.string(),
});
type Segment = z.infer<typeof SegmentSchema>;

export const getAllSegments = async (): Promise<Array<Segment>> => {
  try {
    const response = await httpService.get<Array<Segment>>(GET_ALL_SEGMENTS);

    return Promise.resolve(response.data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getLists = (page: number) => {
  return new Promise((resolve, reject) => {
    axios
      .get(GET_LIST + page.toString())
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAllList = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(GET_ALL_LIST)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getAllLists = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(GET_ALL_LISTS)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getFilterList = (filter: string,page: number) => {
  return new Promise ((resolve, reject) => {
    axios 
      .get(GET_FILTER_LIST + `filter_value=${filter}&page=${page.toString()}&per_page=10`)
      .then ((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const createList = (listData: createListType) => {
  return new Promise((resolve, reject) => {
    axios
      .post(CREATE_LIST, listData)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const updateList = (data: UpdateSegment) => {
  const config = {
    headers: { 'Content-Type': 'text/xml' },
  };
  const updatedData = `<list>
  <name>${data.name}</name>
  <description>${data.description}</description>
</list>`;
  return new Promise((resolve, reject) => {
    axios
      .put(UPDATE_LIST + `${data.id}.xml`, updatedData, config)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const deleteList = (id?: number) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(DELETE_LIST + `${id}.xml`)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const getSingleList = (id: string | undefined) => {
  const getList = GET_SINGLE_LIST + `${id}.xml`;
  return new Promise((resolve, reject) => {
    axios
      .get(getList)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const addMemberUsingFilter = (
  id: string,
  listId: string | undefined
) => {
  const listIdUrl = ADD_MEMBERS_USING_FILTER.replace(
    '{{listId}}',
    listId ? listId : ''
  );

  const addMemberUrl = listIdUrl + `${id}`;

  return new Promise((resolve, reject) => {
    axios
      .post(addMemberUrl)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
