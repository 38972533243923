import { FC, useContext, useEffect, useReducer, useState } from "react";
import { IUser } from "../user";
import FormRow from "../FormRow";
import { editUser } from "services/apiHandlers/Account/User"
import { UserContext } from 'store/UserContext';
import { useNavigate, useParams } from 'react-router-dom';
import Loader from 'components/Loader';
import { Toastify } from 'App';
import React from "react";


interface Props {
    user: IUser,
    user_id?: undefined | string
}

type Action = {
    field: string,
    payload: string;
}


const FIELDS = [
    {heading: "Email Address", acronym: "email" },
    {heading: "Title", acronym: "title"},
    {heading: "Company Name", acronym: "companyName"},
    {heading: "First Name", acronym: "firstName" },
    {heading: "Last Name", acronym: "lastName"}
];

const reducer = (state: IUser, action: Action) => {
    return { ...state, [action.field] : action.payload };
}

const EditUser: FC<Props> = ({ user }) => {
        const navigate = useNavigate();
        const { id } = useParams();
        const [userInfo, dispatch] = useReducer(reducer, user);
        const userCtx = useContext(UserContext);
        const [loading, setLoading] = useState(false);

        const updateUser = async () => {
            try {
                setLoading(true)
                await editUser(id, userInfo)
                Toastify('User detail updated Sucessfully', 'success', 'user_update');
                navigate("/users" );
            } catch(e) {
                console.log("Error is: ", e)
                Toastify('Something went wrong', 'error', 'user_update');
            } finally {
                setLoading(false);
            }
            
        }

        useEffect(() => {
            if(userCtx?.currentUserData && !userCtx?.allowedToEdit(id ? +id : -1)) navigate("/users/" + id);
        }, [userCtx?.currentUserData]);

     
        return (
            <>
                {FIELDS.map(field => (
                    <FormRow key={field.acronym} title={field.heading}>
                        <input 
                            className="w-full px-4 py-2 text-13 leading-3  dark:bg-[#41464E]  border border-[#ced4da] dark:border-[#fff] rounded mt-2 text-[#212529] dark:text-white sm:h-[38px]"
                            type="text" 
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            value={(userInfo as any)[field.acronym] || ''} 
                            onChange={e => dispatch({ field: field.acronym, payload: e.target.value })}
                        />
                    </FormRow>
                ))}

                <button
                    type="button"
                    className="sm:text-13 text-xs font-medium leading-5 text-white py-2.5 px-4 h-11 w-28 rounded-md bg-primary ease-in-in duration-300 hover:bg-primary hover:scale-105"
                    onClick={updateUser}
                  >
                    {loading ? <Loader /> : "Save" }
                  </button>
            </>
        );    
    
}


export default EditUser;