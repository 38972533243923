import Editor from 'components/Email/Editor';
import Header from 'components/Email/Header';
import Delete from 'assets/Images/Common/delete-red.png';
import Send from 'assets/Images/Common/send.png';
import Mail from 'assets/Images/svg-images/mail.svg';
import SendTestModal from './SendTestModal';
import DeleteModal from 'components/Alert/DeleteModal';
import { useNavigate, useParams } from 'react-router-dom';
import { useContext, useEffect, useState, useRef } from 'react';
import { z } from 'zod';
import {
  getEmail,
  autosaveEmail,
  createNewEmail,
} from 'services/apiHandlers/Campaigns/Emails';
import { useOutsideClick } from 'app/hooks/useOutsideClick';
import { initPlugin } from 'utils/stripo';
import CreateHtmlHeader from 'components/Email/CreateHtmlHeader';
import Modal from 'components/Alert/Modal';
import DiscardModal from 'components/Alert/DiscardAlert';
import { EmailContext } from 'store/EmailContext';
import SubmitEmailAlert from 'components/Alert/SubmitEmailAlert';
import React from 'react';
import { create, emailEditor, emailTemplates } from 'services/constant/routes';
import moment from 'moment';
import Wrapper from 'components/Wrapper';
import { Toastify } from 'App';
import { deleteEmail } from 'services/apiHandlers/Campaigns/Emails';

export const emailDataSchema = z.object({
  account_id: z.number(),
  agreed_to_canspam_address_for_html: z.number().nullable(),
  agreed_to_canspam_address_for_text: z.number().nullable(),
  content_type: z.string(),
  created_at: z.string(),
  creator_id: z.number(),
  creator_type: z.string(),
  css: z.string(),
  description: z.string().nullable(),
  html_part: z.string(),
  id: z.number(),
  last_saved: z.string(),
  name: z.string(),
  process: z.string(),
  recovered_draft: z.string(),
  save_type: z.string(),
  size_in_bytes: z.number(),
  source_email_id: z.number(),
  subject: z.string().nullable(),
  tag_list: z.union([z.array(z.string()), z.tuple([])]),
  template_id: z.number(),
  template_name: z.string(),
  template_type: z.string(),
  template_category_name: z.string(),
  text_part: z.string(),
  updated_at: z.string(),
  widgetization_error: z.string().nullable(),
});
export type EmailData = z.infer<typeof emailDataSchema>;

const emaiLResponseSchema = z.object({
  data: z.object({ email: emailDataSchema }),
  status: z.number(),
});
type EmailResponse = z.infer<typeof emaiLResponseSchema>;

const getEmailDataSchema = z.object({
  data: z.object({
    draft: z.object({ email: emailDataSchema }),
    recovered_draft: z.string().optional(),
    redirect_to: z.string(),
  }),
  status: z.number(),
});
export type EmailDataResponse = z.infer<typeof getEmailDataSchema>;

const deleteEmailResponseSchema = z.object({ status: z.number() });
type DeleteEmailResponse = z.infer<typeof deleteEmailResponseSchema>;

const EmailEditor = () => {
  const [emailData, setEmailData] = useState<EmailData>();
  const [name, setName] = useState<string>('');
  const [emailId, setEmailId] = useState<string>('');
  const [labels, setLabels] = useState<string>('');
  const [lastUpdated, setLastUpdated] = useState<string>('');
  const [draftUpdate, setDraftUpdate] = useState<string>('');
  const [editorLoading, setEditorLoading] = useState<boolean>(true);
  const [showDiscardModal, setShowDiscardModal] = useState<boolean>(false);
  const [createEmailSubmitted, setCreateEmailSubmitted] =
    useState<boolean>(false);

  const emailCtx = useContext(EmailContext);
  const { id } = useParams();
  const [showEmailModal, setShowEmailModal] = useState<boolean>(false);
  const [view, setView] = useState<string>('');

  const sendRef = useRef<HTMLDivElement>(null);
  const [showDraftHeader, setShowDraftHeader] = useState<boolean>(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  useOutsideClick(sendRef, () => setShowModal(false));
  const navigate = useNavigate();

  const codeChanged = async () => {
    const saveDraftEmail = setTimeout(() => {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.StripoApi.getTemplate(async (email: string) => {
        const data = {
          draft: {
            html_part: email,
            updated_at: moment().format('DD-MM-YYYY HH:mm:ss'),
          },
        };

        try {
          await autosaveEmail(data);
        } catch (error) {
          console.log('error is : ', error);
        }
      });
    }, 5000);
    return () => clearTimeout(saveDraftEmail);
  };

  const getData = async (id: string, force?: boolean) => {
    try {
      const getEmailResponse = (await getEmail(id, force)) as EmailResponse;
      if (getEmailResponse.status === 200) {
        emailCtx?.templateInfoHandler({
          name: getEmailResponse.data.email.template_name,
          type: getEmailResponse.data.email.template_category_name,
        });

        setEmailData(getEmailResponse.data.email);
        if (getEmailResponse.data.email.recovered_draft) {
          setDraftUpdate(getEmailResponse.data.email.recovered_draft);
        }
      }
    } catch (error) {
      console.log('error is : ', error);
      setEditorLoading(false);
    }
  };

  const getNewEmailData = async (newMail: boolean) => {
    try {
      const getNewMailDataResponse = (await createNewEmail(
        newMail
      )) as EmailDataResponse;

      if (getNewMailDataResponse.status === 200) {
        if (newMail) {
          if (getNewMailDataResponse.data.redirect_to === 'template') {
            navigate(`${emailEditor}/${create}`);
          }
        } else {
          if (getNewMailDataResponse.data.draft?.email) {
            setShowDraftHeader(true);
            // setView('create');
            initPlugin(
              getNewMailDataResponse.data.draft.email.html_part,
              codeChanged,
              false,
              () => setEditorLoading(false)
            );
            setName(getNewMailDataResponse.data.draft.email.name);
            setDraftUpdate(getNewMailDataResponse.data.recovered_draft || '');
          } else {
            navigate(`${emailEditor}/${create}`);
          }
        }
      }
    } catch (error) {
      console.log('error is: ', error);
    }
  };

  const deleteEmailHandler = async (id: string) => {
    try {
      const deleteEmailResponse = (await deleteEmail(
        id
      )) as DeleteEmailResponse;
      if (deleteEmailResponse.status === 200) {
        Toastify('Email Deleted Successfully', 'success', 'actions1');
        navigate(emailTemplates);
      }
    } catch (error) {
      Toastify('Deleting Email Failed', 'error', 'actions2');
    }
  };

  const mobileViewHandler = () => {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    window.StripoApi.compileEmail(function (
      error: string,
      html: string,
      ampHtml: string
    ) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      window.ExternalPreviewPopup.openPreviewPopup(html, ampHtml);

      console.log('html is : ', html);
    });
  };

  useEffect(() => {
    if (emailCtx && emailCtx?.htmlSubmitted?.length > 0) {
      initPlugin(emailCtx.htmlSubmitted, codeChanged, false, () =>
        setEditorLoading(false)
      );
      setView('create');
    } else if (id) {
      setView('edit');
      const editEmailId = id;
      if (editEmailId) {
        getData(editEmailId);
        setEmailId(editEmailId);
      }
    } else {
      getNewEmailData(false);
    }
  }, [createEmailSubmitted]);

  useEffect(() => {
    if (emailData) {
      const email = emailData.html_part;
      setName(emailData.name);
      setLastUpdated(emailData.last_saved);
      setLabels(emailData.tag_list.join(','));
      initPlugin(email, codeChanged, false, () => setEditorLoading(false));
    }
  }, [emailData]);

  return (
    <div className="xl:px-6 px-3">
      <Wrapper>
        <>
          <div className="flex items-center">
            {showDeleteAlert && (
              <div
                onClick={() => setShowDeleteAlert(false)}
                className="fixed left-0 top-0 w-full h-full z-10 bg-black-200 duration-300"
              >
                <DeleteModal
                  onDelete={() => deleteEmailHandler(emailId)}
                  onCancel={() => setShowDeleteAlert(false)}
                />
              </div>
            )}
          </div>

          <div className=" font-inter ">
            <div className="flex-wrap flex justify-between items-center px-5 border-b dark:border-b-0 border-[#e9ebec] py-[18px]">
              {view !== 'edit' && (
                <p className="text-base text-[#495057] font-medium  dark:text-[#CED4DA]">
                  Create Email
                </p>
              )}
              {view === 'edit' && (
                <h1 className="text-base text-[#495057] font-medium  dark:text-[#CED4DA]">
                  Edit Email
                </h1>
              )}
              <div className="flex items-center">
                {view === 'edit' && (
                  <button
                    type="button"
                    className={`${
                      editorLoading ? 'cursor-not-allowed' : 'cursor-pointer'
                    } flex items-center text-sm  hover:text-blue-700 ease-in duration-300 font-medium leading-3 text-red-500 dark:text-white`}
                    onClick={() => setShowDeleteAlert(true)}
                    disabled={editorLoading}
                  >
                    <span className="w-4 h-4 mr-1.5">
                      <img
                        className="w-full h-full dark:invert dark:brightness-0"
                        src={Delete}
                        alt="Delete"
                      />
                    </span>
                    Delete
                  </button>
                )}
                <button
                  type="button"
                  className="flex cursor-not-allowed items-center text-sm font-medium  leading-3 text-gray-500 ml-5 "
                  disabled={true}
                >
                  <span className="w-4 h-4 mr-1.5">
                    <img
                      className="w-full h-full dark:invert dark:brightness-0"
                      src={Send}
                    />
                  </span>
                  <span className="text-gray-500 dark:text-white">Send</span>
                </button>
                <div ref={sendRef}>
                  <button
                    className={`${
                      editorLoading ? 'cursor-not-allowed' : 'cursor-pointer'
                    } flex items-center text-sm font-medium leading-3 text-gray-500 ml-5 dark:text-white cursor-pointer`}
                    disabled={editorLoading}
                    onClick={() =>
                      !editorLoading && setShowModal((prev) => !prev)
                    }
                  >
                    <span className="w-4 h-4 mr-1.5">
                      <img
                        className="w-full h-full dark:invert dark:brightness-0"
                        src={Mail}
                      />
                    </span>
                    Send Test
                  </button>
                  <div>
                    {showModal && (
                      <div>
                        <SendTestModal onClose={() => setShowModal(false)} />
                      </div>
                    )}
                  </div>
                </div>
                <button
                  onClick={mobileViewHandler}
                  className={`${
                    editorLoading
                      ? 'border border-[#ced4da] bg-gray-200 text-[#495057] dark:text-[#CED4DA] '
                      : 'text-white bg-primary ease-in-in duration-300 hover:bg-primary hover:scale-105'
                  } ml-4 text-sm font-medium leading-5 rounded-md py-2 px-4 `}
                  disabled={editorLoading}
                >
                  Mobile View
                </button>
              </div>
            </div>
              <div>
                {showDraftHeader ? (
                  <CreateHtmlHeader
                    draftUpdate={draftUpdate}
                    onYes={() => {
                      setDraftUpdate('');
                      setName('');
                      setView('emailSelected');
                      setShowDraftHeader(false);
                    }}
                    onNo={() => {
                      getNewEmailData(true);
                      navigate(`${emailEditor}/${create}`);
                    }}
                  />
                ) : (
                  <Header
                    name={name}
                    lastUpdated={lastUpdated}
                    labels={labels}
                    onSaveData={(name: string) => {
                      setName(name);
                      setView('edit');
                    }}
                    loading={editorLoading}
                    view={view}
                    draftUpdate={draftUpdate}
                    onYes={() => setDraftUpdate('')}
                    onNo={() => {
                      getData(emailId, true);
                      setDraftUpdate('');
                    }}
                    showEmailModal={() => setShowEmailModal(true)}
                  />
                )}
              </div>
          </div>
          <Editor
            draft={draftUpdate}
            onSaveData={(name: string) => {
              setName(name);
              setCreateEmailSubmitted(true);
            }}
            name={name}
            labels={labels}
            loading={editorLoading}
            onBackToCreation={() => {
              setShowDiscardModal(true);
            }}
            view={view}
            createEmailSubmitted={createEmailSubmitted}
            showEmailModal={() => setShowEmailModal(true)}
          />

          <Modal isOpen={showDiscardModal || showEmailModal}>
            {showDiscardModal ? (
              <DiscardModal
                onClose={() => setShowDiscardModal(false)}
                onOk={() => {
                  navigate(`${emailEditor}/${create}`);
                }}
              />
            ) : showEmailModal ? (
              <SubmitEmailAlert
                onClose={() => setShowEmailModal(false)}
                onOk={() => setShowEmailModal(false)}
              />
            ) : (
              <></>
            )}
          </Modal>
        </>
      </Wrapper>
    </div>
  );
};
export default EmailEditor;
