import React, { FC } from 'react';
import SortByDropdown from 'components/Dropdown/SortByDropdown';

interface Props {
    selectedFilter: string;
    onFilterSelect: (filter:string)=> void;
}

const WorkflowTableHeader: FC<Props> = ({ selectedFilter,onFilterSelect }) => {

    return (
        <div className={`relative flex justify-between p-4 border-b items-center min-h-[71px] dark:border-[#fff] border-[#ced4da]`}>
            <p className="text-base font-medium text-darkText dark:text-[#878A99]">
                All Workflows
            </p>
            <div className='absolute right-3'>
                <SortByDropdown
                    options={['Revenue', 'Status','AOV','Orders']}
                    value={selectedFilter}
                    onSelect={onFilterSelect}
                />
            </div>
        </div>
    );
};

export default WorkflowTableHeader;
