import { useEffect, useState, FC } from 'react';
import {
  createIntegration,
  validateIntegration,
} from 'services/apiHandlers/Integration/CartIntegration';
import * as Yup from 'yup';
import Description from './Description';
import { useFormik, FormikValues } from 'formik';
import uuid from 'react-uuid';
import { Toastify } from 'App';
import { z } from 'zod';
import React from "react";

const validateMagentoSchema = z.object({
  apiKey: z.string(),
  id: z.string(),
  shouldGrabOrderHistory: z.boolean(),
  storeUrl: z.string(),
  uniqueIdConstraint: z.string(),
  userName: z.string(),
  _type: z.string(),
});
type validateMagento = z.infer<typeof validateMagentoSchema>;

const validateResponseSchema = z.object({
  status: z.number(),
});
type validateResponse = z.infer<typeof validateResponseSchema>;
interface Props {
  removeMagento: () => void;
  getData: () => void;
  data?: validateMagento | undefined;
}

const Magento1Form = [
  { name: 'storeUrl', placeholder: 'Store Url' },
  { name: 'userName', placeholder: 'Username' },
  { name: 'apiKey', placeholder: 'ApiKey' },
];

const Magneto1Form: FC<Props> = ({ removeMagento, data, getData }) => {
  const [invalidCredentials, setInvalidCredentials] = useState<boolean>(false);
  const [isValidData, setIsValidData] = useState<boolean>(false);

  const formik: FormikValues = useFormik({
    enableReinitialize: true,
    initialValues: {
      storeUrl: '',
      userName: '',
      apiKey: '',
    },
    validationSchema: Yup.object({
      storeUrl: Yup.string().required('Required'),
      userName: Yup.string().required('Required'),
      apiKey: Yup.string().required('Required'),
    }),
    onSubmit: async (values, { resetForm }) => {
      const data = {
        ...values,
        id: uuid(),
        shouldGrabOrderHistory: true,
        uniqueIdConstraint: 'composite',
        _type: 'magento1',
      };
      try {
        const createMagentoResponse = (await createIntegration(
          data
        )) as validateResponse;
        if (createMagentoResponse.status === 200) {
          setIsValidData(false);
          setInvalidCredentials(false);
          getData();
          Toastify(
            'Magento 1 Created Successfullly',
            'success',
            'magentoForm1'
          );
          resetForm();
        }
      } catch (error) {
        Toastify('Magento 1 Created Successfullly', 'error', 'magentoForm1');
      }
    },
  });

  const checkValidation = async () => {
    if (
      formik.values.apiKey.length > 0 &&
      formik.values.storeUrl.length > 0 &&
      formik.values.userName.length > 0
    ) {
      const data = {
        ...formik.values,
        id: '8b9a9f48-a298-434e-9e77-90c6b6da6e0d',
        shouldGrabOrderHistory: true,
        uniqueIdConstraint: 'composite',
        _type: 'magento1',
      };
      try {
        const validateMagentoResponse = (await validateIntegration(
          data
        )) as validateResponse;
        if (validateMagentoResponse.status === 200) {
          setIsValidData(true);
        } else {
          setInvalidCredentials(true);
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    const checkInputValidation = setTimeout(() => {
      checkValidation();
    }, 3000);
    return () => clearTimeout(checkInputValidation);
  }, [formik.values]);

  useEffect(() => {
    data ? formik.setValues(data) : formik.setValues(formik.initialValues);
  }, []);

  return (
    <div>
      <form
        onSubmit={formik.handleSubmit}
        className="bg-white mt-6 p-4 bg-[#f5f5f5] border dark:border-[#fff] rounded  dark:bg-[#41464E] "
      >
        <h4 className="text-13 font-semibold leading-4 text-black-700 dark:text-white mb-3">
          Magento 1
        </h4>
        <Description title="Magento 1" />
        {Magento1Form.map((field) => (
          <div key={field.name}>
            <input
              className="w-full focus:border-primary placeholder:text-gray-500 dark:focus:border-white focus:outline-none text-13 font-medium leading-3 text-gray-700 dark:text-white py-2 rounded-md px-4 bg-white  dark:bg-[#41464E]  dark:placeholder:text-white border border-[#ced4da] dark:border-[#fff] sm:h-11 h-9 mt-4 focus:outline-none"
              type="text"
              placeholder={field.placeholder}
              name={field.name}
              value={formik.values[field.name]}
              onChange={formik.handleChange}
            />
          </div>
        ))}

        <div className="flex flex-wrap items-center mt-4">
          <button
            type="submit"
            className={` ${
              !isValidData
                ? 'bg-gray-800 text-[#495057] dark:text-[#CED4DA]  dark:text-white font-nomral '
                : 'bg-primary text-white '
            }text-13 font-medium leading-5 rounded py-2 px-4 dark:hover:bg-white dark:hover:text-[#495057] dark:text-[#CED4DA]  dark:text-white mr-4 mt-4`}
            disabled={!isValidData}
          >
            Save
          </button>
          <button
            type="button"
            className="text-13 font-medium leading-5 rounded py-2 px-4 text-white bg-[#B31E33] dark:hover:bg-white dark:hover:text-[#b31e33] mr-4 mt-4"
            onClick={() => removeMagento()}
          >
            Remove
          </button>

          <div className="text-sm leading-4 text-[#cb5b57] dark:text-white mt-4">
            {invalidCredentials
              ? 'invalid Credentials'
              : !isValidData
              ? 'All fields are required'
              : ''}
          </div>
        </div>
      </form>
    </div>
  );
};
export default Magneto1Form;
