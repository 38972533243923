import { useFormik } from 'formik';
import ScheduleDays from './ScheduleDays';
import ScheduleDate from './ScheduleDate';
import ScheduleTime from './ScheduleTime';
import { useContext, useEffect } from 'react';
import { CampaignContext } from 'store/CampaignContext';
import React,{FC} from 'react';
import moment from 'moment';

interface Props {
  showTriggerError: boolean;
  setShowTriggerError: (show:boolean) => void;
}
const Schedule:FC<Props> = ({showTriggerError,setShowTriggerError}) => {
    const campaignCtx = useContext(CampaignContext);
    const defaultSchedule = {
        day: (campaignCtx?.campaignData?.schedule_later && campaignCtx?.campaignData?.schedule_later['day'] ) || moment().format('D'),
        hour: (campaignCtx?.campaignData?.schedule_later && campaignCtx?.campaignData?.schedule_later['hour'] )|| '15',
        meridian: 'AM',
        minute: (campaignCtx?.campaignData?.schedule_later && campaignCtx?.campaignData?.schedule_later['minute'] )||'0',
        month:(campaignCtx?.campaignData?.schedule_later && campaignCtx?.campaignData?.schedule_later['month'] )|| moment().format('M'),
        year:(campaignCtx?.campaignData?.schedule_later && campaignCtx?.campaignData?.schedule_later['year'] )|| moment().format('YYYY'),
        frequency: (campaignCtx?.campaignData?.schedule_later && campaignCtx?.campaignData?.schedule_later['frequency'] )||'does not repeat',
        interval: (campaignCtx?.campaignData?.schedule_later && campaignCtx?.campaignData?.schedule_later['interval'] )||'1',
        days_of_week: (campaignCtx?.campaignData?.schedule_later && campaignCtx?.campaignData?.schedule_later['days_of_week'] )||[],
    };
    const defaultSubsriber = {
        relativity: (campaignCtx?.campaignData?.subscriberField && campaignCtx?.campaignData?.subscriberField['relativity'] )||'after',
        id: (campaignCtx?.campaignData?.subscriberField && campaignCtx?.campaignData?.subscriberField['id'] )||-1,
        frequency: (campaignCtx?.campaignData?.subscriberField && campaignCtx?.campaignData?.subscriberField['frequency'] )||'does not repeat',
        offset: (campaignCtx?.campaignData?.subscriberField && campaignCtx?.campaignData?.subscriberField['offset'] )||0,
    };
    // const [showError, setShowError] = useState<boolean>(false);
    const formik = useFormik({
        initialValues: { schedule: campaignCtx?.campaignData?.schedule_type || 'now', trigger: campaignCtx?.campaignData?.triggerName || '' },
        onSubmit: () => console.log(),
    });
    const scheduleHandler = (event: string)=>{
      formik.setFieldValue('schedule',event);
    }
    useEffect(() => {
        if (campaignCtx?.campaignData) {
            campaignCtx?.onUpdateCampaignData({
                ...campaignCtx.campaignData,
                schedule_type: formik.values.schedule,
                ...(formik.values.schedule !== 'now'
                    ? {
                          schedule_later: defaultSchedule,
                          subscriberField: defaultSubsriber,
                      }
                    : {}),
                triggerName: formik.values.trigger,
            });
            if (
                campaignCtx?.campaignFormErrors.triggerError &&
                formik.touched.trigger &&
                formik.values.trigger.length === 0
            ) {
              setShowTriggerError(true);
            } else {
              setShowTriggerError(false);
            }
        }
    }, [formik.values]);
    useEffect(() => {
        if (campaignCtx?.campaignFormErrors.triggerError) {
          setShowTriggerError(true);
        } else {
          setShowTriggerError(false);
        }
    }, [campaignCtx?.campaignFormErrors]);
    return (
        <>
            <div className="w-full mt-6">
                <h1 className=" text-lg leading-5 text-[#495057] font-medium dark:text-white">
                    Schedule based on
                </h1>
                <div className="py-4">
                    <div>
                        <label className="customRadio">
                            <input
                                type="radio"
                                name="schedule"
                                // value="now"
                                checked={formik.values.schedule === 'now'}
                                onChange={()=>{scheduleHandler('now')}}
                            />
                            <span
                                className={`${
                                    formik.values.schedule === 'now'
                                        ? 'before:border-unset'
                                        : 'before:border-gray-600'
                                } before:border-[0.5px] mb-2 text-[#212529] dark:text-white leading-5 text-sm font-medium `}
                            >
                                Now
                            </span>
                        </label>
                        <label className="customRadio">
                            <input
                                type="radio"
                                name="schedule"
                                // value="subscriber"
                                checked={
                                    formik.values.schedule === 'subscriber'
                                }
                                onChange={()=>{scheduleHandler('subscriber')}}
                            />
                            <span
                                className={`${
                                    formik.values.schedule === 'subscriber'
                                        ? 'before:border-unset'
                                        : 'before:border-gray-600'
                                } before:border-[0.5px] mb-2 text-[#212529] dark:text-white leading-5 text-sm font-medium `}
                            >
                                Subscribers Field
                            </span>
                        </label>
                        <label className="customRadio">
                            <input
                                type="radio"
                                name="schedule"
                                // value="later"
                                checked={formik.values.schedule === 'later'}
                                onChange={()=>{scheduleHandler('later')}}
                            />
                            <span
                                className={`${
                                    formik.values.schedule === 'later'
                                        ? 'before:border-unset'
                                        : 'before:border-gray-600'
                                } before:border-[0.5px] mb-2 text-[#212529] dark:text-white leading-5 text-sm font-medium `}
                            >
                                Specific Date
                            </span>
                        </label>
                    </div>
                </div>
                <div className=" w-full mt-4">
                    <div className="">
                        {formik.values.schedule !== 'now' && (
                            <label className="block relative text-13 font-normal leading-3 text-[#495057] dark:text-[#CED4DA]  dark:text-white mb-4 items-center">
                                <p className="text-sm font-medium leading-3 tracking-wide text-[#212529] dark:text-white mb-2">
                                    Trigger Name
                                </p>
                                <input
                                    className="w-1/2 h-[38px] focus:border-primary dark:focus:border-white focus:outline-none py-2 px-4 text-13 font-medium leading-3 border border-[#CED4DA] placeholder:text-gray-500 dark:placeholder:text-white dark:border-[#fff]  dark:bg-[#41464E]  placeholder:font-normal rounded text-[#495057] dark:text-[#CED4DA]  dark:text-white"
                                    type="text"
                                    name="trigger"
                                    value={formik.values.trigger}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                />
                                {showTriggerError && (
                                    <div className="text-xs leading-4 text-red-400 mt-1.5">
                                        Select a Trigger to continue
                                    </div>
                                )}
                            </label>
                        )}
                        {formik.values.schedule === 'subscriber' && <ScheduleDays />}
                        {formik.values.schedule === 'later' && <ScheduleDate />}
                    </div>
                    {formik.values.schedule !== 'now' && <ScheduleTime />}
                </div>
            </div>
        </>
    );
};
export default Schedule;