import Workflows from './Workflows';
import 'react-date-range/dist/styles.css';
// import { DateRange } from 'react-date-range';
import 'react-date-range/dist/theme/default.css';
import React, { useContext, useState, useEffect } from 'react';
import { getAllWorkflows } from 'services/apiHandlers/Dashboard/Analytics';
import { WorkflowResponse } from 'store/UserContext';
import { WorkflowType } from '../Body/Workflows/index.type';
import { UserContext } from 'store/UserContext';
import Wrapper from 'components/Wrapper';
// import DropdownIcon from 'assets/Images/Common/down-arrow.png';


const Body = () => {
  const [workflows, setWorkflows] = useState<Array<WorkflowType>>([]);
  const [filteredWorkflows, setFilteredWorkflows] = useState<
    Array<WorkflowType>
  >([]);
  const [loading,setLoading] = useState<boolean>(true);
  const userCtx = useContext(UserContext);
  
  // TO_FIX why are we calling this function here as we are only passing it to other component
  const getWorkflows = async () => {
    setLoading(true)
    try {
      const getWorkflowResponse = (await getAllWorkflows()) as WorkflowResponse;
      if (getWorkflowResponse.status === 200) {
        setWorkflows(getWorkflowResponse.data.triggers);
        setFilteredWorkflows(getWorkflowResponse.data.triggers)
      }
    } catch (error) {
      console.log('error is : ', error);
    }
    setLoading(false)
  };

  useEffect(() => {
    getWorkflows();
  }, []);

  useEffect(() => {
    const checkFilterChange = setTimeout(() => {
      if (userCtx?.filteredValue && userCtx?.filteredValue.length > 0) {
        const tempWorkflows = workflows?.filter(
          (workflow) =>
            workflow.name &&
            workflow.name
              .toLowerCase()
              .includes(userCtx.filteredValue.toLowerCase())
        );

        setFilteredWorkflows(tempWorkflows);
      } else {
        setFilteredWorkflows(workflows);
      }
    }, 1000);
    return () => clearTimeout(checkFilterChange);
  }, [userCtx?.filteredValue, workflows]);

  return (
    <>
      <div className="md:px-4 px-3 py-8 font-inter  h-full min-h-[75vh]">
        <Wrapper>
          <>
            <div className="flex flex-wrap justify-between items-center px-5 border-b dark:border-[#fff] border-[#ced4da] py-[18px]">
              <p className="text-base text-[#495057] font-medium  dark:text-[#CED4DA]">All Workflows</p>
              {/* <div className="flex">
                <div className="relative">
                  <div className="flex items-center cursor-pointer">
                    <p className="text-xs font-semibold text-[#212529] dark:text-[#CED4DA]">
                      Sort by:
                    </p>
                    <p className="text-[#878A99] ml-1 text-sm">Current Week</p>
                    <img
                      src={DropdownIcon}
                      alt="Dropdown Icon"
                      className="ml-1"
                    />
                  </div>
                </div>
              </div> */}
            </div>
            <Workflows
              workflows={filteredWorkflows}
              setWorkflows={setFilteredWorkflows}
              getWorkflows={getWorkflows}
              loading={loading}
            />
          </>
        </Wrapper>
      </div>
    </>
  );
};
export default Body;
