import Wrapper from 'components/Wrapper';
import React, { FC, useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Dropdown from 'components/Dropdown/MultiElementsDropdown';
import {
  SendCampaignReport,
  sendCampaignReportEmail,
} from 'services/apiHandlers/Campaigns/Emails';
import z from 'zod';

const usersDataSchema = z.object({
  administrator: z.boolean(),
  company_name: z.string(),
  created_at: z.string(),
  email_address: z.string(),
  facebook_user_id: z.string(),
  first_name: z.string(),
  id: z.number(),
  last_name: z.string(),
  may_export: z.boolean(),
  need_password_reset: z.string(),
  phone_number_fax: z.string(),
  phone_number_home: z.string(),
  phone_number_mobile: z.string(),
  phone_number_office: z.string(),
  phone_number_office_ext: z.string(),
  previous_password: z.string(),
  title: z.string(),
  updated_at: z.string(),
});

const getUsersResponseSchema = z.object({
  status: z.number(),
  data: z.object({ users: z.array(usersDataSchema) }),
});

const testResultSchema = z.object({ message: z.string(), type: z.string() });
type TestResult = z.infer<typeof testResultSchema>;
type UsersResponse = z.infer<typeof getUsersResponseSchema>;
const optionsSchema = z.object({ name: z.string(), value: z.string() });
type Options = z.infer<typeof optionsSchema>;

interface Props {
  onClose: () => void;
  options: Options[];
}
const SendReportDropdown: FC<Props> = ({ onClose, options }) => {
  const { id } = useParams();
  const [report, setReport] = useState<SendCampaignReport>({
    email_addresses: '',
    user_ids: [0],
    link_reports: false,
  });
  const [selectedRecipients, setSelectedRecipients] = useState<Array<string>>(
    []
  );
  const [recipientOptions, setRecipientOptions] = useState<Array<Options>>([]);
  const [formIsValid, setFormIsValid] = useState<boolean>(false);
  const [testResult, setTestResult] = useState<TestResult>({
    message: '',
    type: '',
  });
  const [loading, setLoading] = useState<boolean>(false);

  const optionChangeHandler = (recepeints: string[]) => {
    setSelectedRecipients(recepeints);
    const tempRecepients = recepeints.map((recep) => {
      return parseInt(recep);
    });
    if (tempRecepients.length > 0) {
      setFormIsValid(true);
    } else {
      setFormIsValid(false);
    }
    setReport({ ...report, user_ids: tempRecepients });
  };

  const additionalRecepientsChangeHandler = (value: string) => {
    const emails = value.split(',');
    emails.map((email) => {
      if (!email.includes('@') || !email.includes('.')) {
        setFormIsValid(false);
      } else if (report.user_ids.length > 0) {
        setFormIsValid(true);
      }
    });

    setReport({ ...report, email_addresses: value });
  };

  const linkReportsChangeHandler = (check: boolean) => {
    setReport({ ...report, link_reports: check });
  };

  const sendReportEmail = async () => {
    setLoading(true);
    try {
      if (!formIsValid || selectedRecipients.length===0) {
        setTestResult({
          message: 'Please either Select a user or Enter correct Emails..',
          type: 'error',
        });
      } else {
        const sendTestResponse = (await sendCampaignReportEmail(
          id || '',
          report
        )) as UsersResponse;
        if (sendTestResponse.status === 200) {
          setTestResult({
            message: 'Send Succesfull. Your email(s) were sent successfully!',
            type: 'success',
          });
        }
      }
    } catch (error) {
      setTestResult({
        message: 'Send Failed There was a problem sending your email(s)!',
        type: 'error',
      });
      console.log('error is: ', error);
    }
    setLoading(false);
  };

  useEffect(() => {
    setRecipientOptions(options);
  }, [options]);

  return (
    <div className="bg-white shadow-md p-4 absolute rounded top-16 right-[14px] z-50 w-[450px]">
      <Wrapper parentClass="" childClass="">
        <>
          <p className="text-base text-[#495057] font-medium p-4 border-b border-[#e9ebec] ">
            Email Report
          </p>
          <div className="p-4">
            <p className="text-sm text-[#878A99] mb-3">
              Please Spacify who you&apos;d like to email this blast report to:
            </p>
            <Dropdown
              options={recipientOptions}
              selectedList={selectedRecipients}
              onOptionChange={(value) => {
                optionChangeHandler(value);
              }}
            />
            <p className="mt-6 text-[#212529] font-semibold ">
              Additional Recipient
            </p>
            <p className="text-sm text-[#878A99]">
              Enter up to 5 comma separated email address
            </p>
            <input
              className="w-full px-4 py-2 text-13 leading-3 dark:bg-slate-800 border dark:border-[#fff] border-[#ced4da] rounded mt-2 text-[#212529] dark:text-white sm:h-[38px]"
              type="text"
              data-pw="merge-field"
              name="mail"
              placeholder=" email1@expample.com ,email2@expample.com  "
              onChange={(event) =>
                additionalRecepientsChangeHandler(event.target.value)
              }
            />
            <div className="flex items-center mt-4">
              <input
                className="cursor-pointer "
                type="checkbox"
                checked={report.link_reports}
                onChange={(event) => {
                  linkReportsChangeHandler(event.target.checked);
                }}
              />
              <label className="checkBox relative cursor-pointer pl-2">
                <span className="text-13 font-normal leading-3 text-black-700 dark:text-white">
                  Inclue links to unique clicks and views?
                </span>
              </label>
            </div>
            <div className="flex justify-between items-center">
              <button
                type="button"
                // disabled={!formIsValid}
                className="bg-primary text-white font-medium text-sm rounded p-2 mt-4"
                onClick={() => sendReportEmail()}
              >
                Send Report
              </button>
              <button
                type="button"
                className="bg-primary text-white font-medium text-sm rounded p-2 mt-4"
                onClick={() => onClose()}
              >
                Cancel
              </button>
            </div>
          </div>
          {testResult.message.length > 0 && !loading && (
            <div
              className={`${
                testResult.type === 'error'
                  ? 'bg-[#f7e8e7]'
                  : testResult.type === 'success'
                  ? 'bg-green-100'
                  : ''
              } ml-2 mr-2 mb-2 px-6 py-3  rounded mt-4`}
            >
              <p
                className={` ${
                  testResult.type === 'error'
                    ? 'text-[#CB5A5A]'
                    : testResult.type === 'success'
                    ? 'text-green-600'
                    : ''
                } text-sm font-normal leading-4 `}
              >
                {testResult.message}
              </p>
            </div>
          )}
        </>
      </Wrapper>
    </div>
  );
};
export default SendReportDropdown;
