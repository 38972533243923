import React,{ FC, SetStateAction, useEffect, useState,useContext } from 'react';
import Dropdown from 'components/Dropdown/Dropdown';
import SearchDropdown from 'components/Dropdown/SearchDropdown';
import { InitialEventConditionData, InitialEventData } from '../index.type';
import {
  getEventAttributes,
  getEventNames,
} from 'services/apiHandlers/Workflows/Workflow';
import { WorkflowContext } from 'store/WorkflowContext';
import { z } from 'zod';
import InitialEventConditions from '../WorkflowConditions';
import ModalWrapper from 'components/Workflow/ModalWrapper';
import { eventExtraOptions } from 'staticdata/EventLogs/Data';
import { formatString } from 'utils/common';

interface Props {
  onClose: () => void;
  eventData?: InitialEventData | undefined;
  onTagSelect: () => void;
  onSave: (data: InitialEventData) => void;
}

interface AttributeTypes {
  [key: string]: string[];
  insights_email_send: string[];
  insights_email_view: string[];
  insights_email_click: string[];
  ss_email_sent: string[];
  ss_added_to_list: string[];
  ss_unsubscribed: string[];
  ss_email_viewed: string[];
  ss_email_link_clicked: string[];
  ss_form_signup: string[];
}

const attributeType: AttributeTypes = {
  insights_email_send: [
    'actionDescription',
    'eventId',
    'messageId',
    'nodeId',
    'personIdentifier',
    'triggerId',
  ],
  insights_email_view: [
    'eventId',
    'personIdentifier',
    'messageId',
    'triggerId',
  ],
  insights_email_click: [
    'eventId',
    'personIdentifier',
    'messageId',
    'triggerId',
    'url',
  ],
  ss_email_sent: ['anonymousId', 'blast_id', 'owner_id', 'personIdentifier'],
  ss_added_to_list: [
    'anonymousId',
    'blast_id',
    'owner_id',
    'personIdentifier',
    'list_id',
  ],
  ss_unsubscribed: ['blast_id', 'personIdentifier'],
  ss_email_viewed: [
    'anonymousId',
    'blast_id',
    'ip',
    'ip_country',
    'ip_city',
    'owner_id',
    'personIdentifier',
    'referrer',
    'user_agent',
  ],
  ss_email_link_clicked: [
    'anonymousId',
    'blast_id',
    'hostname',
    'insightsTags',
    'ip',
    'ip_country',
    'ip_city',
    'owner_id',
    'path',
    'personIdentifier',
    'referrer',
    'referrerHostname',
    'title',
    'url',
    'userAgent',
  ],
  ss_form_signup: [
    'anonymousId',
    'hostname',
    'insightsTags',
    'ip',
    'ip_country',
    'ip_city',
    'path',
    'personIdentifier',
    'referrer',
    'referrerHostname',
    'title',
    'url',
    'userAgent',
  ],
};

const responseSchema = z.object({
  status: z.number(),
  data: z.array(z.string()),
});

type Response = z.infer<typeof responseSchema>;

const tagOptions = ['Event Has Occured', 'Subscriber was tagged'];

const InitialEvent: FC<Props> = ({
  onClose,
  eventData,
  onTagSelect,
  onSave,
}) => {
  const workflowCtx = useContext(WorkflowContext);
  const [selectedEvent, setSelectedEvent] = useState<string>(workflowCtx?.workflow?.initialConditionGroup.eventName || 'Select an item');
  const [eventOptions, setEventOptions] = useState<Array<string>>([]);
  const [attributes, setAttributes] = useState<Array<string>>([]);
  const [eventConditions, setEventCondiitons] = useState<Array<InitialEventConditionData>>([]);
  const [formIsValid, setFormIsValid] = useState<boolean>(true);

  const eventChangeHandler = async (value: string , reset?:boolean) => {
    setSelectedEvent(value);
    reset && setEventCondiitons([]);
    try {
      const getEventAttributesResponse = (await getEventAttributes(
        formatString(value)
      )) as Response;
      if (getEventAttributesResponse.status === 200) {
        if (getEventAttributesResponse.data.length > 0) {
          setAttributes(getEventAttributesResponse.data);
        } else {
          setAttributes(attributeType[value]);
        }
      }
    } catch (error) {
      console.log('error is : ', error);
    }
  };

  const getData = async () => {
    try {
      const response = (await getEventNames()) as Response;
      if (response.status === 200) {
        setEventOptions([...response.data,...eventExtraOptions ]);
        eventData && eventChangeHandler(response.data[0])
      }
    } catch (error) {
      console.log("error is : " , error)
    }
  };

  const saveDataHandler = () => {
    let valid = true;

    eventConditions.map((condition) => {
      if (condition.attributeName === 'Select an item') {
        valid = false;
      }
    });
    
    setFormIsValid(false)

    if (valid) {
      const savedData = {
        conditions: eventConditions,
        _type: 'EventInitialConditionGroup',
        eventName: selectedEvent,
      };
      onSave(savedData);
      onClose();
    }
  };

  useEffect(() => {
    getData();
    if (eventData) {
      setSelectedEvent(eventData?.eventName);
      setEventCondiitons(
        eventData?.conditions as SetStateAction<
          Array<InitialEventConditionData>
        >
      );
    }
  }, []);
  return (
    <ModalWrapper
      onClose={onClose}
      disabled={!formIsValid || selectedEvent === 'Select an item'}
      onSave={saveDataHandler}
    >
      <>
        <h2 className="sm:text-xl font-semibold text-base sm:leading-5 leading-3 text-[#495057] dark:text-[#CED4DA]  mb-8 dark:text-white">
          Initial Condition
        </h2>
        <label className="text-xs font-semibold leading-3 tracking-wide uppercase text-[#495057] dark:text-[#CED4DA]  block my-4 dark:text-white">
          Execute trigger when
        </label>
        <div className="flex items-center gap-4 sm:flex-row flex-col">
          <div className="z-10  dropdown-show-hide relative sm:w-6/12 w-full">
            <Dropdown
              options={tagOptions}
              value={'Event Has Occured'}
              onSelect={(value) => {
                if (value === 'Subscriber was tagged') {
                  onTagSelect();
                }
              }}
            />
          </div>
          <div className="z-10 dropdown-show-hide relative sm:w-6/12 w-full">
            <SearchDropdown
              options={eventOptions}
              value={selectedEvent}
              onSelect={(value) => eventChangeHandler(value,true)}
            />
          </div>
        </div>
        <InitialEventConditions
          conditions={eventConditions}
          attributes={attributes}
          onChange={(conditions) => {
            setEventCondiitons(conditions);
          }}
          isValid={formIsValid}
          onValidForm={(valid) => setFormIsValid(valid)}
          hideButton={selectedEvent === 'Select an item'}
        />
      </>
    </ModalWrapper>
  );
};
export default InitialEvent;
