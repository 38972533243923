import React, { FC, useEffect } from 'react';
import { z } from 'zod';

interface Props {
  week: boolean;
  onWeekDayChange: (week: boolean) => void;
  checkedDays: CheckDay[];
  onDaysChange: (days: CheckDay[]) => void;
  error: boolean;
  setError: (error: boolean) => void;
}

const checkDaySchema = z.object({
  day: z.string(),
  checked: z.boolean(),
});
type CheckDay = z.infer<typeof checkDaySchema>;

const DelayDay: FC<Props> = ({
  week,
  onWeekDayChange,
  checkedDays,
  onDaysChange,
  error,
  setError,
}) => {
  const dayChangeHandler = (value: boolean, index: number) => {
    const tempCheckDays = [...checkedDays];
    tempCheckDays[index].checked = value;
    setError(!tempCheckDays.every((item) => !item.checked));
    onDaysChange(tempCheckDays);
  };

  useEffect(() => {
    // const hasCheckedDays = checkedDays.some((day) => day.checked);
    // if (hasCheckedDays) {
    //   setError(false);
    // } else {
    //   setError(true);
    // }
  }, [week, checkedDays]);

  return (
    <>
      <div className="flex items-center mt-6">
        <input
          className="cursor-pointer w-[14px] h-[14px] text-primary bg-gray-100 border-gray-300  dark:focus:ring-blue-600 dark:ring-offset-gray-800  dark:bg-gray-700 dark:border-gray-600 mr-2"
          type="checkbox"
          id="day-resume"
          name="day-resume"
          checked={week}
          onChange={(e) => onWeekDayChange(e.target.checked)}
        />
        <label
          htmlFor="day-resume"
          className="text-sm font-normal leading-4 text-[#495057]  dark:text-white"
        >
          Resume on a specific day
        </label>
      </div>
      {error && week && (
        <div className="px-6 py-3 bg-[#f7e8e7] rounded mt-4">
          <p className="text-sm font-normal leading-4 text-[#CB5A5A]">
            Choose atleast one day
          </p>
        </div>
      )}
      {week && (
        <div className="flex flex-wrap items-center gap-3">
          {checkedDays.map((day, index) => (
            <div key={day.day} className="flex items-center mt-4">
              <input
                className="cursor-pointer w-[14px] h-[14px] text-primary bg-gray-100 border-gray-300  dark:focus:ring-blue-600 dark:ring-offset-gray-800  dark:bg-gray-700 dark:border-gray-600 mr-2"
                type="checkbox"
                id={day.day}
                name="resume-mon"
                checked={day.checked === true}
                onChange={(e) => dayChangeHandler(e.target.checked, index)}
              />
              <label
                htmlFor={day.day}
                className="text-sm font-normal leading-4 text-[#495057] dark:text-[#CED4DA]  "
              >
                {day.day}
              </label>
            </div>
          ))}
        </div>
      )}
    </>
  );
};
export default DelayDay;
