import { FC, useEffect, useRef, useState } from 'react';
import { getAllList } from 'services/apiHandlers/List-Segments/SegmentsList';
import { ListConditionData, ListActionData } from '../index.type';
import Dropdown from 'components/Dropdown/Dropdown';
import Lists from 'components/List';
import ModalWrapper from 'components/Workflow/ModalWrapper';
import { useOutsideClick } from 'app/hooks/useOutsideClick';
import { z } from 'zod';
import React from 'react';

interface Props {
  onClose: () => void;
  data: ListConditionData | ListActionData | undefined;
  action?: boolean;
  onSave: (data: ListConditionData | ListActionData) => void;
  isRemove?: boolean; 
}

interface SubscriberType {
  [key: string]: string;
  IsNotInLists: string;
  IsInLists: string;
}

const subscriberOptions = [
  'Subscriber is in any selected list',
  'Subscriber is not in any selected list',
];

const subscriberType: SubscriberType = {
  IsNotInLists: 'Subscriber is not in any selected list',
  IsInLists: 'Subscriber is in any selected list',
};

const getListResponseDataSchema = z.object({
  id: z.number(),
  name: z.string(),
  audience_id: z.number(),
});
type ListType = z.infer<typeof getListResponseDataSchema>;

const getListResponseSchema = z.object({
  data: z.object({
    lists: z.array(getListResponseDataSchema),
  }),
  status: z.number(),
});
type GetListResponse = z.infer<typeof getListResponseSchema>;

const selectedListSchema = z.object({ id: z.number(), label: z.string() });
type SelectedList = z.infer<typeof selectedListSchema>;

const List: FC<Props> = ({ onClose, data, action, onSave,isRemove }) => {
  const [selectedLists, setSelectedLists] = useState<Array<number>>([]);
  const [searchedList, setSearchedList] = useState<string>();
  const [showList, setShowList] = useState<boolean>(false);
  const [selectedSubscriber, setSelectedSubscriber] = useState<string>(
    'Subscriber is in any selected list'
  );
  const [showError, setShowError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [list, setList] = useState<Array<ListType>>([]);
  const [listItems, setListItems] = useState<Array<SelectedList>>([]);

  useOutsideClick(dropdownRef, () => setShowList(false));

  const listChangeHandler = (list_id: number) => {
    const updatedList = [...selectedLists];
    const idIndex = updatedList.indexOf(list_id);
    if (idIndex === -1) {
      updatedList.push(list_id);
    } else {
      updatedList.splice(idIndex, 1);
    }
    setSelectedLists(updatedList);
  };

  const getListData = async (createList?: boolean) => {
    try {
      const getListsResponse = (await getAllList()) as GetListResponse;
      if (getListsResponse.status === 200) {
        const tempLists = getListsResponse?.data?.lists?.map((list) => {
          return { id: list.id, label: list.name };
        });
        if (createList) {
          const maxId = Math.max(
            ...tempLists.map((obj: SelectedList) => obj.id)
          );
          listChangeHandler(maxId);
        }
        setListItems(tempLists);
        setList(getListsResponse?.data?.lists);
      }
    } catch (error) {
      console.log('error is : ', error);
    }
    setLoading(false);
  };
  const saveDataHandler = () => {
    if (selectedLists.length === 0) {
      setShowError(true);
    } else {
      const data = action
        ? { listIds: selectedLists, _type: '' }
        : {
            _type: 'ListMembershipCondition',
            listCondition: {
              _type: Object.keys(subscriberType).find(
                (key) => subscriberType[key] === selectedSubscriber
              ) as string,
            },
            listIds: selectedLists,
          };
      onSave(data);
      onClose();
    }
  };

  useEffect(() => {
    if (data) {
      if ('listCondition' in data) {
        setSelectedSubscriber(subscriberType[data.listCondition._type]);
      }
      setSelectedLists(data?.listIds || []);
    }
    getListData();
  }, []);

  useEffect(() => {
    if (selectedLists.length > 0) {
      setShowError(false);
    }
  }, [selectedLists]);

  return (
    <ModalWrapper
      onClose={onClose}
      disabled={showError}
      onSave={saveDataHandler}
      customStyles='min-h-[340px] flex flex-col justify-between '
      footerBorder='hidden'
    >
      <div>
        <div className="bg-white dark:bg-transparent ">
          <h2 className="sm:text-xl text-base sm:leading-5 leading-3 text-black-700 font-medium mb-8 dark:text-white">
            Condition
          </h2>
          <div className="flex items-center gap-3 flex-wrap">
            {!action && (
              <div className="z-1 dropdown-show-hide relative sm:[38px] h-9 w-6/12">
                <Dropdown
                  options={subscriberOptions}
                  value={selectedSubscriber}
                  onSelect={(value) => {
                    if (selectedLists.length === 0) {
                      setShowError(true);
                    }
                    setSelectedSubscriber(value);
                  }}
                />
              </div>
            )}
            <div
              ref={dropdownRef}
              className="z-10 w-full dark:bg-[#41464E]  dark:border-[#fff] dark:border rounded  dropdown-show-hide relative sm:[38px] h-9 "
            >
              <div
                onClick={() => setShowList((prev) => !prev)}
                className=" bg-transparentPrimary text-sm font-medium leading-4 text-primary dark:text-white  flex items-center justify-between sm:[38px] py-2 px-4 focus:outline-none border-0 rounded-md  dark:bg-[#41464E]  dark:border-[#fff] cursor-pointer"
              >
                {selectedLists.length} items selected
              </div>
              {showList && (
                <div data-pw="email-list" className="w-full overflow-auto">
                  <div className="flex items-center">
                    <input
                      className="border focus:border-primaengry dark:focus:border-white focus:outline-none border-gray-800 dark:border-[#fff]  dark:placeholder:text-white sm:[38px] h-9 p-3 text-13 font-medium leading-3 text-gray-700 placeholder:text-gray-500 dark:text-white  dark:bg-[#41464E]  w-full"
                      type="search"
                      name="Search"
                      value={searchedList}
                      onChange={(e) => setSearchedList(e.target.value)}
                      placeholder="Filter Items"
                    />
                  </div>
                  <div className="rounded-b border border-t-0 border-gray-800 dark:border-[#fff] overflow-y-auto scrollbar-hide">
                    <Lists
                      list={list}
                      listItems={listItems}
                      onListChange={listChangeHandler}
                      selectedLists={selectedLists}
                      loading={loading}
                      getList={getListData}
                      filteredList={searchedList}
                      workflow={true}
                      setDeletedList={(list) => {
                        setShowError(list.length === 0);
                        setSelectedLists(list);
                      }}
                      showCreateList={action}
                      isRemove={isRemove}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        {showError && (
          <div className="px-6 py-3 bg-[#f7e8e7] rounded mt-4">
            <p className="text-sm font-normal leading-4 text-[#CB5A5A]">
              Please correct the errors above
            </p>
          </div>
        )}
      </div>
    </ModalWrapper>
  );
};
export default List;
