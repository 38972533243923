import { FC, useEffect, useState, useContext } from 'react';
import Email from './Email';
// import Insights from './Insights';
import { getcampaignEmails } from 'services/apiHandlers/Campaigns/Emails';
import { z } from 'zod';
import PopularModal from '../PopularModal';
import { UserContext } from 'store/UserContext';
import React from 'react';
import Modal from 'components/Alert/Modal';
import Wrapper from 'components/Wrapper';

const emailTriggerSchema = z.object({ name: z.string(), id: z.number() });

const campaignEmailSchema = z.object({
  id: z.number(),
  name: z.string(),
  created_at: z.string(),
  html_part_present: z.boolean(),
  html_part_blank: z.boolean(),
  include_event: z.boolean(),
  needs_widgetization: z.boolean(),
  widgetization_error: z.boolean(),
  new_editor_compatible: z.boolean(),
  text_part_present: z.boolean(),
  text_part_blank: z.boolean(),
  email_triggers: z.array(emailTriggerSchema),
});

const campaignResponseSchema = z.object({
  data: z.object({
    emails: z.array(campaignEmailSchema),
    readonly_mode: z.boolean(),
    total_entries: z.number(),
  }),
  status: z.number(),
});

const pageDataSchema = z.object({ page: z.number(), perPage: z.number() });

type PageData = z.infer<typeof pageDataSchema>;
type SinglecampaignEmail = z.infer<typeof campaignEmailSchema>;
type campaignResponse = z.infer<typeof campaignResponseSchema>;

const Body: FC = () => {
  const userCtx = useContext(UserContext);
  const [emails, setEmails] = useState<Array<SinglecampaignEmail>>([]);
  const [readonly, setReadonly] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [paginationData, setPaginationData] = useState<PageData>({
    page: 1,
    perPage: 20,
  });
  const [filterValue, setFilterValue] = useState<string>('');
  const [label, setLabel] = useState<string | undefined>('');
  const [totalRecords, setTotalRecords] = useState<number>(0);
  const [showPopularLabels, setShowPopularLabels] = useState<boolean>(false);
  const [selectedFilter, setSelectedFilter] = useState<string>('Select a Filter');

  const getData = async (
    searchedValue?: string,
    preventLoading?: boolean,
    label?: string,
    page?: number
  ) => {
    const tempEmails: SinglecampaignEmail[] = [];
    try {
      if (!preventLoading) {
        setLoading(true);
      }
      const getEmailresponse = (await getcampaignEmails(
        { page: page || paginationData.page, perPage: paginationData.perPage },
        searchedValue,
        label
      )) as campaignResponse;
      if (getEmailresponse.status === 200) {
        getEmailresponse?.data?.emails?.map((data) => {
          if (data.html_part_present) {
            tempEmails.push(data);
          }
        });
        setTotalRecords(getEmailresponse.data.total_entries);
        setEmails(tempEmails);
        setReadonly(getEmailresponse.data.readonly_mode);
      }
    } catch (error) {
      console.log('error is: ', error);
      setLoading(false);
    }
    setLoading(false);
  };

  const labelClickHandler = (label: string | undefined) => {
    setLabel(label);
    if (label) {
      getData(undefined, false, label);
      ('');
    }
  };

  const paginationChangeHandler = (page: number) => {
    setPaginationData({ page: page, perPage: 20 });
    if(page){
      getData(filterValue, false, label, page);
    }
  };

  useEffect(() => {
    const checkFilterChange = setTimeout(() => {
      if (userCtx?.filteredValue && userCtx?.filteredValue.length > 0) {
        setLabel('');
        getData(userCtx?.filteredValue);
      } else {
        getData();
        ('');
      }
    }, 1000);
    return () => clearTimeout(checkFilterChange);
  }, [userCtx?.filteredValue]);

  return (
    <>
      <div className="md:px-4 px-3 py-5 font-inter h-full min-h-[76vh]">
        <Wrapper>
         <Email
              emails={emails}
              readonly={readonly}
              loading={loading}
              total={totalRecords}
              onDelete={() => getData('', true)}
              onPageChange={(page) => paginationChangeHandler(page)}
              setShowPopularLabels={setShowPopularLabels}
              label={label}
              selectedFilter={selectedFilter}
              setSelectedFilter={setSelectedFilter}
            />
        </Wrapper>
      </div>

      {/* <div className="relative">
          <div className="after:left-[-3px] after:bottom-[-2px] after:border-b-[#e9ebec] after:border-l-[#e9ebec] after:border-t-transparent after:border-r-transparent after:content-[''] after:h-[13px] after:w-[13px] after:absolute after:border-[3px] before:right-[-2px] before:top-[-3px] before:border-b-transparent before:border-l-transparent before:border-t-[#e9ebec] before:border-r-[#e9ebec] before:content-[''] before:h-[13px] before:w-[13px] before:absolute before:border-[3px]  border border-[rgba(0, 0, 0, 0.125)] rounded dark:shadow-none dark:bg-transparent dark:border-[#fff] mb-16">
             */}

      <Modal isOpen={showPopularLabels}>
        <PopularModal
          setFilterValue={setFilterValue}
          onLabelClick={(label) => labelClickHandler(label)}
          onClose={setShowPopularLabels}
        />
      </Modal>
    </>
  );
};
export default Body;